@import '../../../../sass-utils/variables';

.create-default-okr {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			@include justify-content(flex-start);
			.back-to-main {
				padding-bottom: 0;
			}
		}
	}
	/**default okr css start here**/
	.default-okr-card {
		@include flex;
		@include justify-content(space-between);
		.drawer-date-field {
			width: 32%;
			.cycle-days {
				margin-top: 5px;
			}
			input.form-control {
				width: 100%;
			}
		}
	}
}
/**default okr assign role**/
.default-assign {
	width: 50%;
	.default-assign-role {
		@include flex;
		@include align-items(center);
		.no-assign-role {
			@include flex;
			@include align-items(center);
		}
		.chip-placeholder {
			background: $color-hover;
			width: 46px;
			height: 16px;
			border: 1.4px dashed rgba($color-primary2, 0.15);
			@include border-radius(100px);
			margin-right: 5px;
		}
		.helperText-bottom {
			.MuiTypography-body1 {
				min-width: 188px;
				line-height: 18px;
				top: 40px;
				bottom: auto;
				margin-left: -94px;
				left: 50%;
			}
			.error-btn {
				background: $color-text-info !important;
				border-color: $color-text-info !important;
			}
		}
	}
	.list-assign-role {
		@include flex;
		@include align-items(center);
		flex-wrap: wrap;
		gap: 5px;

		.default-chip-avatar {
			max-width: 105px;
			flex: 1;
			//min-width: 105px;
		}
		&.all-emp {
			.default-chip {
				min-width: auto;
			}
		}
	}
}
.deleted-chip-wrap {
	@include flex;
	@include align-items(flex-start);
	height: 100%;
}
.default-okr-popper {
	.user-info {
		@include align-items(initial);
		.user-info-details {
			@include flex;
			@include align-items(center);
		}
	}
	.deleted-chip-wrap {
		@include flex;
		@include align-items(flex-start);
		height: 100%;
	}
	.deleted-user-row {
		background: $color-bg-teamstag;
		.user-info {
			.user-img {
				.MuiAvatar-root {
					background: $color-avatar-disable;
					color: rgba($color-primary2, 0.5);
					img {
						filter: grayscale(100%);
						-webkit-filter: grayscale(100%);
						opacity: 0.7;
					}
				}
			}
			.user-info-details {
				.MuiTypography-body1,
				.MuiTypography-h4 {
					opacity: 0.5;
				}
				.delete-chip {
					height: auto;
					padding: 0 7px;
					background: $color-okr-highlighter;
					border: 1px solid #f3c3c3;
					margin-top: 10px;
					min-width: 60px;
					.MuiChip-label {
						color: $color-tags-new;
						line-height: $line-height5;
					}
				}
			}
		}
	}
}

/**default obj role drawer css start here**/
.drawer-field-space {
	position: relative;
	padding: 0 3.75em 15px;
}
.default-obj-role-drawer {
	.select-role-fields {
		padding: 0 3.75em 20px;
		width: 100%;
		.MuiFormControl-root {
			width: 100%;
		}
		.textfield-action-btn {
			@include justify-content(flex-end);
		}
		&.selected {
			.select-search-dropdown {
				.react-select__control {
					@include border-radius(6px 6px 0 6px);
				}
			}
		}
	}
	.user-role-tab {
		.select-search-dropdown.select-search-icon {
			.react-select__control {
				.react-select__indicators {
					display: none;
				}
			}
		}
		.default-role-list {
			.users-listing {
				.user-col1 {
					width: 40% !important;
					min-width: 40% !important;
				}
				.user-col2 {
					width: 50% !important;
					min-width: 50% !important;
					.MuiTypography-body1 {
						word-break: break-word;
					}
				}
				.users-list-row {
					ul {
						li {
							.MuiFormControlLabel-root {
								margin-top: -4px;
							}
							.user-info {
								.user-info-details {
									@include align-items(center);
								}
							}
						}
					}
				}
			}
		}
	}
	.select-search-dropdown.select-search-icon {
		.react-select__control {
			max-height: 65px;

			.react-select__indicators {
				max-height: 40px;
			}
			.secondary-text {
				display: none;
			}
		}
		.react-select__menu {
			.selected-chip {
				@include flex;
				.primary-text {
					width: 60%;
					padding-right: 20px;
					font-size: $font-size-base;
					line-height: $line-height2;
					color: $color-primary2;
					font-weight: $font-weight-regular;
				}
				.secondary-text {
					width: 40%;
					font-size: $font-size-base;
					line-height: $line-height2;
					color: $color-primary2;
					font-weight: $font-weight-regular;
				}
			}
		}
		&.react-select--is-disabled {
			.react-select__control {
				.react-select__value-container {
					.react-select__placeholder {
						background: url(/static/media/search-sprite.8e257541.svg) no-repeat 0 0;
						background-position: 0px 2px;
						padding-left: 26px;
						margin-left: 0px;
					}
				}
			}
		}
	}
	.role-tab-selection {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
	}
	.okr-tab-button.okr-tab-white-button {
		min-height: auto !important;
		.MuiTab-root {
			font-size: $font-size-h5;
			line-height: $line-height4;
			min-width: auto;
		}
	}

	.default-role-list {
		padding: 30px 0;
		.user-no-record {
			//padding-top: 0px;
			.MuiTypography-body1 {
				margin-top: 5px;
			}
		}
		.title-head {
			margin-bottom: 10px;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			.MuiTypography-h6 {
				text-transform: uppercase;
			}
			.user-search {
				.MuiInputBase-root {
					border-color: rgba($color-input-field, 0.5);
					background: $color-white;
					.MuiInputBase-input {
						padding: 6px 0;
					}
					&.Mui-focused {
						border-color: $color-primary1;
					}
				}
				.user-search-chip {
					z-index: 9;
				}
			}
		}
		&.all-emp-list {
			.users-listing {
				.user-col2,
				.user-col1 {
					width: 50% !important;
					min-width: 50% !important;
				}
			}
		}
		.users-listing {
			margin: 0 -10px;
			width: calc(100% + 20px);
			.okr-list-panel-head {
				padding: 0px 10px 5px;
				ul {
					li {
						&.user-col3 {
							&::before {
								display: none;
							}
						}
					}
				}
			}
			.user-col1 {
				width: 65% !important;
				min-width: 65% !important;
				padding-right: 10px !important;
				.MuiFormControlLabel-root {
					margin-right: 5px !important;
				}
			}
			.user-col2 {
				width: 25% !important;
				min-width: 25% !important;
			}
			.user-col3 {
				width: 10% !important;
				min-width: 10% !important;
				@include justify-content(flex-end);
			}

			.users-list-row {
				padding: 10px;
				cursor: default;
				ul {
					li {
						.MuiFormControlLabel-root {
							margin-top: -6px;
						}
						.user-info {
							@include align-items(initial);
							.user-img {
								.MuiAvatar-root {
									margin-top: 0;
								}
							}
							.user-info-details {
								@include flex;
								@include align-items(flex-start);
							}
						}
					}
				}
				&.deleted-user-row {
					background: $color-bg-teamstag;
					.user-info {
						.user-img {
							.MuiAvatar-root {
								background: $color-avatar-disable;
								color: rgba($color-primary2, 0.5);
								img {
									filter: grayscale(100%);
									-webkit-filter: grayscale(100%);
									opacity: 0.7;
								}
							}
						}
						.user-info-details {
							.MuiTypography-body1 {
								opacity: 0.5;
							}
							.delete-chip {
								height: auto;
								padding: 0 7px;
								background: $color-okr-highlighter;
								border: 1px solid #f3c3c3;
								margin-top: 10px;
								min-width: 60px;
								.MuiChip-label {
									color: $color-tags-new;
									line-height: $line-height5;
								}
							}
						}
					}
				}
			}
		}
	}
}
.default-okr-bulk {
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 862px;
	z-index: 9999;
	.bulk-user-edit-bar {
		position: absolute;
	}
}
