@import '../../../sass-utils/variables';

.performance-development-area {
	.performance-development-settings {
		.performance-development-card {
			.pd-card-header {
				margin-bottom: 20px;
				@include flex;
				@include align-items(flex-start);
				@include justify-content(space-between);
				.btn-link {
					margin-right: 0px;
					height: auto;
					font-size: $font-size-h5;
					line-height: $line-height4;
				}
			}
			.pd-card-switch {
				.MuiFormControlLabel-root {
					margin-left: 0;
				}
			}
			.pd-card-textfield {
				margin-top: 15px;
				margin-bottom: 15px;
				.resolution-text-field {
					.MuiInputBase-root {
						width: 400px;
					}
					.MuiFormHelperText-root {
						z-index: 1;
						bottom: -36px;
					}
				}
			}
			&.pip-email-settings {
				.pd-card-header {
					flex-direction: column;
				}
				.pip-email-content {
					padding: 20px;
					background: $color-bg-form;
					@include border-radius(6px);
					.pip-email-subject-field {
						padding-bottom: 15px;
						@include flex;
						@include align-items(center);
						.resolution-text-field {
							margin-left: 15px;
							.MuiInputBase-root {
								width: auto;
								padding: 0;
								border: none;
								@include border-radius(0);
								border-bottom: 1.4px solid rgba($color-primary2, 0.2);
								box-shadow: none;
							}
						}
						.pip-email-subject-in-edit {
							@include flex;
							@include align-items(center);
							.resolution-text-field {
								min-width: 600px;
							}
						}
						.edit-btn,
						.cancel-btn {
							padding: 0;
							width: 24px;
							height: 24px;
							margin-left: 5px;
							@include border-radius(30px);
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					.pip-email-body-part1,
					.pip-email-body-part2 {
						padding-bottom: 15px;
					}
					.view-plan-btn {
						margin-top: 15px;
						margin-left: 0;
						font-size: $font-size-base;
					}
					table {
						tr {
							td {
								padding-left: 0 !important;
								padding-right: 0 !important;
								font-size: $font-size-base !important;
								a {
									font-size: $font-size-base !important;
									pointer-events: none;
								}
							}
						}
					}
				}
			}
			&.pip-rights-settings,
			&.feedback-rights-settings {
				.pd-card-switch {
					margin-bottom: 10px;
				}
			}
			.pd-setting-card-actions {
				//padding: 0 20px;
				margin-top: 20px;
				@include flex;
				@include justify-content(space-between);
			}
			&.pip-send-update-settings {
				.pd-card-header {
					@include flex-direction(column);
				}
				.select-search-dropdown {
					.react-select__menu {
						.react-select__menu-list {
							.react-select__option {
								.search-user-result {
									@include align-items(center);
									.MuiAvatar-root {
										margin-top: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.resolution-listing-area {
	.okr-list-panel-head,
	.resolution-listing-content {
		ul {
			li {
				&.resolution-col1 {
					width: 40%;
				}
				&.resolution-col2 {
					width: 25%;
				}
				&.resolution-col3 {
					width: 35%;
				}
			}
		}
	}
	.resolution-listing-content {
		@include flex;
		@include justify-content(center);
		@include flex-direction(column);
		min-height: 80px;
		position: relative;
		padding: 20px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			flex: 1;
			padding: 0;
			li {
				padding: 0 20px;
				flex: auto;
				&.resolution-col1 {
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.resolution-text-field {
						.MuiInputBase-root {
							max-width: 360px;
						}
					}
					.resolution-listing-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					.edit-resolution-actions {
						position: absolute;
						left: 314px;
						top: 100%;
						z-index: 1;
						button {
							padding-top: 4px;
							padding-bottom: 4px;
							min-height: 26px;
							&:hover {
								background-color: transparent;
							}
							svg {
								width: 12px;
								height: 12px;
							}
							&.resolution-save-btn {
								padding-left: 12px;
								padding-right: 12px;
								@include border-radius(0 0 6px 6px);
								background-color: $color-primary1;
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-white;
									}
								}
							}
							&.resolution-cancel-btn {
								svg {
									path {
										fill: $color-primary1;
										stroke: $color-primary1;
									}
								}
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					&.resolution-edit-col {
						margin-bottom: 20px;
						.resolution-text-field {
							.MuiInputBase-root {
								@include border-radius(6px 6px 0px 6px);
							}
						}
					}
				}
				&.resolution-col2 {
					.resolution-cat-chip {
						.MuiChip-root {
							.MuiChip-label {
								line-height: 12px;
							}
						}
					}
				}
				&.resolution-col3 {
					.status-switch {
						.MuiFormControlLabel-root {
							margin-left: 0;
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			li {
				&.resolution-col1 {
					.resolution-listing-actions {
						button {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
/* Feedback Types UI*/
.feedback-types-area {
	.okr-list-panel-head,
	.feedback-types-listing-content {
		ul {
			li {
				&.feedback-types-col1 {
					width: 40%;
				}
				&.feedback-types-col2 {
					width: 25%;
				}
				&.feedback-types-col3 {
					width: 35%;
				}
			}
		}
	}
	.feedback-types-listing-content {
		@include flex;
		@include justify-content(center);
		@include flex-direction(column);
		min-height: 80px;
		position: relative;
		padding: 20px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			flex: 1;
			padding: 0;
			li {
				padding: 0 20px;
				flex: auto;
				&.feedback-types-col1 {
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.feedback-types-text-field {
						.MuiInputBase-root {
							max-width: 360px;
						}
					}
					.feedback-types-listing-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					.edit-feedback-types-actions {
						position: absolute;
						left: 314px;
						top: 100%;
						z-index: 1;
						button {
							padding-top: 4px;
							padding-bottom: 4px;
							min-height: 26px;
							&:hover {
								background-color: transparent;
							}
							svg {
								width: 12px;
								height: 12px;
							}
							&.feedback-types-save-btn {
								padding-left: 12px;
								padding-right: 12px;
								@include border-radius(0 0 6px 6px);
								background-color: $color-primary1;
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-white;
									}
								}
							}
							&.feedback-types-cancel-btn {
								svg {
									path {
										fill: $color-primary1;
										stroke: $color-primary1;
									}
								}
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					&.feedback-types-edit-col {
						margin-bottom: 20px;
						.feedback-types-text-field {
							.MuiInputBase-root {
								@include border-radius(6px 6px 0px 6px);
							}
						}
					}
				}
				&.feedback-types-col2 {
					.feedback-types-chip {
						.MuiChip-root {
							.MuiChip-label {
								line-height: 12px;
							}
						}
					}
				}
				&.feedback-types-col3 {
					.status-switch {
						.MuiFormControlLabel-root {
							margin-left: 0;
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			li {
				&.feedback-types-col1 {
					.feedback-types-listing-actions {
						button {
							opacity: 1;
						}
					}
				}
			}
		}
		.MuiAccordion-root {
			box-shadow: none;
			.MuiAccordionSummary-root {
				min-height: 40px;
				&.Mui-focusVisible {
					background-color: transparent;
				}
			}
			.MuiAccordionSummary-content {
				margin: 0;
				@include flex-direction(column);
			}
			.MuiAccordionSummary-expandIcon {
				position: absolute;
				left: 12px;
				top: 13px;
				padding: 0;
				svg {
					width: 14px;
					height: 14px;
				}
				&.Mui-expanded {
					transform: rotate(90deg);
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
			.MuiAccordionDetails-root {
				@include flex-direction(column);
			}
		}
	}
	/* Feedback category UI*/
	.feedback-category-area {
		padding-top: 10px;
		margin-top: 10px;
		background-color: $color-bg-form;
		@include border-radius(6px);
		.okr-list-panel-head,
		.feedback-category-listing-content {
			ul {
				li {
					padding: 0 15px;
					&.feedback-category-col1 {
						width: 39.5%;
						@include flex;
						@include justify-content(space-between);
					}
					&.feedback-category-col2 {
						width: 26%;
					}
					&.feedback-category-col3 {
						width: 34.5%;
					}
					// &.feedback-category-col4 {
					// 	width: 14%;
					// }
					// &.feedback-category-col5 {
					// 	width: 14%;
					// }
					// &.feedback-category-col6 {
					// 	width: 10%;
					// }
					// &.feedback-category-col7 {
					// 	width: 18%;
					// }
				}
			}
		}
		.feedback-category-listing-content {
			@include flex;
			@include justify-content(center);
			@include flex-direction(column);
			min-height: 80px;
			position: relative;
			padding: 20px 0;
			margin-bottom: -1px;
			transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			background-color: $color-bg-form;
			border: 1.4px solid $color-bg-form;
			@include border-radius(6px);
			ul {
				@include flex;
				@include align-items(center);
				flex: 1;
				padding: 0;
				li {
					flex: auto;
					&.feedback-category-col1 {
						.feedback-category-listing-actions {
							@include flex;
							@include align-items(center);
							button {
								padding: 0;
								min-width: auto;
								width: 24px;
								height: 24px;
								@include border-radius(100%);
								margin-left: 0.5em;
								@include transition(0.25s);
								opacity: 0;
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
					&.feedback-category-col2 {
						.feedback-category-chip {
							.MuiChip-root {
								.MuiChip-label {
									line-height: 12px;
								}
							}
						}
					}
					&.feedback-category-col3 {
						.status-switch {
							.MuiFormControlLabel-root {
								margin-left: 0;
							}
						}
					}
				}
			}
			&:hover {
				li {
					&.feedback-category-col1 {
						.feedback-category-listing-actions {
							button {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.no-record-message {
			padding: 30px 0;
			text-align: center;
			.MuiTypography-h2 {
				padding-bottom: 10px;
				text-transform: capitalize;
			}
		}
	}
}
.feedback-type-menu {
	ul {
		li {
			svg {
				width: 12px;
				height: 12px;
				path {
					fill: $color-primary2;
				}
			}
		}
	}
}
