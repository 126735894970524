@import '../../../styles/sass-utils/variables';
.clone-role-dialog {
	z-index: 9991 !important;
	.MuiDialog-paper {
		@include panel-shadow;
		@include border-radius(6px);
		min-width: 564px;
		max-width: 564px;
		overflow: hidden;
	}
	.MuiDialogContent-root {
		&:first-child {
			padding: 2.8125em 1.75em 1.75em 2.5em;
			@include custom-scrollbar;
		}
		.clone-dialog-inner {
			@include flex;
			@include justify-content(space-between);
			@include align-items(flex-start);
			padding-top: 0px;
			min-height: 156px;
			.clone-checkbox {
				@include flex;
				@include flex-direction(column);
				padding-top: 1em;
				.MuiFormControlLabel-label {
					//font-weight: $font-weight-bold;
					margin-left: 4px;
				}
				.MuiCheckbox-root {
					padding: 0.5em 0.5625em;
				}
			}
		}
	}
	.MuiDialogActions-root {
		padding: 1.25em 1.25em 1.25em 2.5em;
		border-top: 1.4px solid $color-hover;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.MuiGrid-container {
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
				vertical-align: middle;
				path {
					stroke: $color-primary2;
				}
			}
			.MuiGrid-item {
				font-size: $font-size-h5;
				line-height: $line-height4;
				font-weight: $font-weight-regular;
				color: $color-primary2;
				@include flex;
				@include align-items(center);
			}
		}
	}
	&.clone-okr-dialog {
		.MuiDialog-paper {
			min-width: 612px;
			max-width: 612px;
		}
		.MuiDialogActions-root {
			@include justify-content(flex-end);
		}
		.clone-image {
			margin-top: -8px;
		}
	}
}
