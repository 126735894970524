@import '../../../styles/sass-utils/variables';

.pip-area {
	padding-top: 30px;
	.pip-header-area {
		padding-bottom: 15px;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.pip-header-left,
		.pip-header-right {
			@include flex;
			@include align-items(center);
			.user-menu-btn {
				width: 24px;
				height: 24px;
				@include border-radius(100%);
				svg {
					width: 14px;
					height: 14px;
					min-width: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
		}
		.back-to-main {
			padding-bottom: 0;
		}
		> .MuiTypography-body2 {
			color: rgba($color-primary2, 0.5);
		}
		.pip-header-left {
			.back-to-main {
				margin-right: 10px;
			}
			.user-info {
				@include align-items(center);
			}
			.MuiTypography-body2 {
				color: rgba($color-primary2, 0.5);
			}
		}
		.pip-header-right {
			.pip-header-right-actions {
				@include flex;
				@include align-items(center);
				.range-selector-calender {
					margin: 0 15px;
					position: relative;
					display: flex;
					align-items: center;
					.form-control {
						width: 207px;
						cursor: pointer;
						height: 32px;
						padding: 0 7px 0 30px;
						font-size: $font-size-h5;
						@include transition(0.25s);
						border: 1.4px solid $color-border-tile;
						@include border-radius(30px);
						background-color: $color-bg-filterbtn;
						background-image: url(../../../images/calendar-small-black.svg);
						background-repeat: no-repeat;
						background-position: 10px center;
						background-size: 13px;
						&::-webkit-input-placeholder {
							opacity: 1;
						}
						&:hover,
						&.active {
							background-color: $color-white;
							@include default-shadow;
						}
					}
				}
				.goal-okr-filter {
					.filter-chip {
						ul {
							li {
								&:first-child {
									margin-left: 0;
								}
								&.clear-all {
									margin-right: 5px;
								}
							}
						}
					}
				}
				.list-filter-btn,
				.task-sorting-btn {
					padding: 6px 8px 6px 16px;
					font-size: $font-size-h5;
					background: $color-bg-filterbtn;
					border: 1.4px solid $color-border-tile;
					@include border-radius(43px);
					order: 2;
					margin-right: 15px;
					svg {
						width: 12px;
						height: 12px;
						margin-right: 10px;
					}
					&:hover,
					&.active {
						background: $color-white;
						@include default-shadow;
					}
					.filter-txt {
						@include flex;
						@include align-items(center);
						svg {
							margin-left: 10px;
						}
					}
				}
				.user-search {
					.MuiInputBase-root {
						max-width: 185px;
						height: 31px;
						border: 1px solid rgba($color-input-field, 0.5);
					}
					.user-search-chip {
						position: static;
					}
				}
			}
			.add-btn {
				margin-left: 15px;
			}
		}
	}
	.pip-content-area {
		.pip-details-area {
			width: 100%;
			margin-top: 20px;
		}
		.pip-improvements-area {
			width: 100%;
		}
		.pip-tasks-area {
			width: 100%;
			margin-top: 20px;
			.welcome-content {
				margin-top: 20px;
			}
			.create-task-block {
				.MuiInputBase-root {
					background: $color-white;
				}
			}
			.task-list-area {
				.task-list-panel-area {
					margin-top: 25px;
					&.full-rectangular-card {
						padding: 0;
						border: none;
					}
					.task-list-panel-head {
						padding-left: 0;
					}
				}
			}
		}
		.pip-details-area {
			margin-top: 20px;
			@include flex;
			@include justify-content(space-between);
			.pip-details-left {
				width: calc(100% - 380px);
				.error-field {
					z-index: 8;
				}
				#pip_nominee_container {
					.MuiFormHelperText-root {
						top: 34px;
					}
				}
			}
			.pip-details-right {
				width: 350px;
			}
			.pip-input-field {
				margin-bottom: 30px;
				.MuiFormLabel-root {
					svg {
						margin-right: 5px;
						width: 14px;
						height: 14px;
					}
				}
				.MuiTextField-root {
					width: 100%;
					margin-bottom: 3px;
				}
				.review-date-area {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(calc(33% - 10px), 1fr));
					grid-gap: 10px;
				}
				.review-date-box {
					position: relative;
					padding: 20px;
					background: $color-bg-form;
					@include border-radius(6px);
					.review-date-action {
						position: absolute;
						right: 10px;
						top: 10px;
						@include flex;
						@include align-items(center);
						button {
							margin-left: 15px;
							padding: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					.set-timeline-area {
						margin-bottom: 20px;
						margin-left: 0;
						max-width: 100%;
						.timeline-field {
							.calendar-btn {
								button {
									.MuiButton-label {
										line-height: 1;
										min-width: auto;
									}
								}
							}
						}
					}
					&.more-dates-box {
						padding: 0;
						@include flex;
						@include align-items(center);
						background: transparent;
						.more-dates-btn {
							padding: 0;
							font-size: $font-size-h6;
							color: $color-primary1;
							font-weight: $font-weight-bold;
							.icon {
								@include flex;
								@include align-items(center);
								@include justify-content(center);
								margin-right: 5px;
								width: 14px;
								height: 14px;
								background: $color-primary1;
								@include border-radius(14px);
								@include transition(all ease-in-out 0.25s);
								@include button-shadow;
								@include animation(pulse 2s infinite);
								svg {
									width: 7px;
									height: 7px;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
				}
				#date-range-inputfield {
					width: 355px;
					padding: 4px 10px;
					font-size: $font-size-h5;
					line-height: $line-height2;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(6px);
					font-family: $font-family-lato;
				}
				.pip-reviewers-notes {
					margin-top: 10px;
				}
				&.review-dates-field {
					margin-bottom: 0;
					.error-field {
						width: 222px;
						left: -20px;
						top: calc(100% + 10px);
						bottom: auto;
					}
				}
			}
			.form-details-right {
				padding: 20px;
				background: $color-bg-form;
				@include border-radius(6px);
			}
		}
		.pip-accordion-panel {
			@include box-shadow(none);
			&:before {
				display: none;
			}
			.MuiAccordionSummary-root {
				padding: 0;
				min-height: auto;
				.MuiAccordionSummary-content {
					margin: 0;
				}
				.MuiAccordionSummary-expandIcon {
					margin-right: 0;
					margin-left: -12px;
					order: -1;
					svg {
						path {
							fill: $color-primary2;
							stroke: $color-primary2;
						}
					}
					&.Mui-expanded {
						transform: rotate(90deg);
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			.MuiCollapse-root {
				.MuiAccordionDetails-root {
					padding: 0;
				}
			}
		}
		.pip-search-field {
			width: 355px;
			.react-select__dropdown-indicator {
				display: none;
			}
		}
		.pip-form-action {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			.btn-link {
				height: auto;
				line-height: 22px;
			}
		}
		.pip-others-header {
			padding-top: 40px;
			padding-bottom: 15px;
		}
		.pip-input-field-two-col {
			@include flex;
			.pip-input-field {
				width: calc(100% - 30px);
				margin-right: 30px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.pip-improvements-area {
			.error-field {
				left: 0;
				top: auto;
				bottom: 100%;
				width: 230px;
				&::before,
				&::after {
					margin-right: -3px;
					right: 50%;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					@include transform(rotate(0deg));
				}
				&::before {
					top: calc(100% - 1.4px);
					border-top: 8px solid $color-avatar-disable;
				}
				&::after {
					top: 100%;
					border-top: 8px solid $color-border-info;
				}
			}
			.ck {
				.ck-placeholder {
					&:before {
						color: rgba($color-primary2, 0.5);
					}
				}
			}
		}
		.pip-section-panel {
			@include box-shadow(none);
			&:before {
				display: none;
			}
			.MuiAccordionSummary-root {
				padding: 0;
				min-height: auto;
				.MuiAccordionSummary-content {
					margin: 0;
				}
				.MuiAccordionSummary-expandIcon {
					margin-right: 0;
					margin-left: -12px;
					order: -1;
					svg {
						path {
							fill: $color-primary2;
							stroke: $color-primary2;
						}
					}
					&.Mui-expanded {
						transform: rotate(90deg);
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			.MuiCollapse-root {
				.MuiAccordionDetails-root {
					padding: 0;
				}
			}
		}
		.create-pip-tabs {
			.create-pip-tab-head {
				padding: 20px 0 10px;
				.okr-tab-button {
					min-height: auto !important;
					.MuiTab-root {
						min-width: auto;
						&.arrow-tab {
							.MuiBadge-dot {
								top: -8px;
							}
							.arrowIcon {
								margin-left: 5px;
							}
						}
						&.Mui-selected {
							svg {
								circle,
								rect {
									fill: $color-primary1;
								}
							}
						}
					}
				}
			}
			.create-pip-tab-content {
				.pip-editor-panel {
					margin-bottom: 20px;
					.long-text {
						padding: 10px;
						@include border-radius(6px);
						border: 1.4px solid rgba($color-input-field, 0.5);
					}
					&:last-child {
						margin-bottom: 0;
					}
					.MuiFormLabel-root {
						text-transform: capitalize;
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
						}
					}
					.helperText-bottom {
						.error-field {
							top: 0;
						}
					}
				}
				.pip-tasks-area {
					margin-top: 0;
					.task-list-area {
						.task-list-panel-area {
							padding: 20px;
						}
					}
				}
			}
		}
	}
}
.pip-welcome {
	object {
		margin-top: 30px;
		margin-bottom: 0;
	}
	&.pip-no-permission {
		margin-top: 60px;
	}
}

/**global component of pip css start here**/
.pip-reviewers-list {
	@include flex;
	@include align-items(center);
	.review-initiator {
		margin-right: 5px;
		.MuiAvatar-root {
			width: 40px;
			height: 40px;
		}
	}
	.review-others {
		@include flex;
		@include align-items(center);
		.review-others-placeholder {
			@include flex;
			svg {
				margin-right: 5px;
			}
		}
		.contributors {
			margin-right: 5px;
			.contributors-avatar-group {
				> .MuiAvatar-colorDefault {
					margin-right: 0;
				}
				.MuiAvatar-colorDefault {
					&.contributor-owner {
						margin-right: 7px;
					}
				}
			}
		}
		.add-btn2 {
			width: 20px;
			height: 20px;
			svg {
				width: 10px;
				height: 10px;
			}
		}
		.contributors {
			.contributor-list {
				margin-right: 0;
				.MuiAvatarGroup-avatar {
					margin-right: 3px;
					&.MuiAvatar-colorDefault,
					&.MuiAvatarGroup-avatar {
						&:nth-child(3) {
							z-index: 2 !important;
						}
						&:nth-child(4) {
							z-index: 3 !important;
						}
						&:nth-child(5) {
							margin-right: 0;
							z-index: 4 !important;
							width: 18px;
							height: 18px;
							background-color: $color-primary1;
							color: $color-white;
							font-size: $font-size-subtitle2;
							font-weight: $font-weight-bold;
							border: 0px;
						}
						&.removeBlue:nth-child(5) {
							background: $color-bg-info;
							color: $color-tags;
							font-size: $font-size-h6;
							width: 30px;
							height: 30px;
							@include border-radius(50%!important);
							border: 1.4px solid $color-bg-teamstag !important;
						}
					}
				}
			}
		}
	}
}
.pip-status {
	.MuiChip-root {
		height: auto;
		padding: 0px;
		.MuiChip-label {
			font-size: $font-size-subtitle2 !important;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
			text-transform: uppercase;
			padding: 0 7px !important;
		}
		&.draft-chip {
			margin-right: 3px;
			background: $color-okr-highlighter;
			border: 1px solid $color-border-info;
			.MuiChip-label {
				color: $color-tags-new;
			}
		}
		&.lagging-chip {
			background: $color-lagging;
			border: 1px solid $color-lagging;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.on-track-chip {
			background: $color-on-track;
			border: 1px solid $color-on-track;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.review-chip {
			background: $color-secondary2;
			border: 1px solid $color-secondary2;
			.MuiChip-label {
				color: $color-secondary1;
			}
		}
		&.grey-chip {
			background: $color-not-started;
			border: 1px solid $color-not-started;
			.MuiChip-label {
				color: rgba($color-primary2, 0.7);
			}
		}
		&.at-risk-chip {
			background: $color-text-info;
			border: 1px solid $color-text-info;
			.MuiChip-label {
				color: $color-white;
			}
		}
	}
}
.pip-feedback-list {
	ul {
		li {
			padding: 0;
			display: block;
			.MuiTypography-h4 {
				padding-bottom: 10px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
}
.feedback-date-range {
	@include flex;
	@include align-items(center);

	.MuiTypography-h6 {
		@include flex;
		@include align-items(center);
		color: $color-hint-help-text;
		font-weight: normal;
		margin-right: 15px;
		&:last-child {
			margin-right: 0px;
		}
		&.alert-field {
			color: $color-text-info;
			.alert-icon {
				@include flex;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-white;
					}
				}
			}
			svg {
				path {
					fill: $color-text-info;
				}
			}
		}
	}
	svg {
		margin-right: 5px;
		width: 14px;
		height: 14px;
		path {
			fill: $color-hint-help-text;
		}
	}
	.MuiChip-root {
		height: auto;
		padding: 0px;
		.MuiChip-label {
			font-size: $font-size-subtitle2 !important;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
			text-transform: uppercase;
			padding: 0 7px !important;
		}
		&.grey-chip {
			background: $color-not-started;
			border: 1px solid $color-not-started;
			.MuiChip-label {
				color: rgba($color-primary2, 0.7);
			}
		}
	}
}
.upload-attachments-list {
	ul {
		padding: 0;
		li {
			padding: 10px 0;
			border-bottom: 1.4px solid $color-hover;
			.count {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				width: 18px;
				min-width: 18px;
				height: 18px;
				margin-right: 10px;
				text-align: center;
				font-size: $font-size-subtitle2;
				line-height: $line-height4;
				font-weight: $font-weight-bold;
				color: $color-secondary1;
				border: 1px solid $color-secondary1;
				@include border-radius(100%);
			}
			.file-name {
				width: calc(100% - 30px);
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.MuiTypography-body1 {
					color: $color-primary1;
					//cursor: pointer;
					max-width: calc(100% - 60px);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.attachment-action {
				.btn {
					padding: 0;
					svg {
						width: 14px;
						height: 14px;
						path {
							fill: $color-primary1;
						}
					}
					&:hover {
						background: transparent;
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			&:last-child {
				padding-bottom: 0;
				border-bottom: 0px;
			}
		}
	}
}
#page-perform-dev-subheader {
	.okr-tabs {
		.MuiTab-root {
			.badge-icon {
				margin-top: -18px;
				margin-left: 3px;
			}
		}
	}
}
/* Reveiwers list popover*/
.contributor-details-popover {
	&.reviewers-details-popover {
		min-width: 332px;
		max-width: 332px;
		margin-top: -45px;
		margin-left: -12px;
		ul {
			&.contributor-details-list {
				&:before {
					display: none;
				}
				li {
					cursor: default;
					.contributordetails-right {
						.remove-reviewer-btn {
							padding: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					.contributordetails-left {
						.MuiListItemAvatar-root {
							&.parent-avatar {
								min-width: auto;
								width: 36px;
								height: 36px;
								@include flex;
								@include align-items(center);
								border: 2px solid $color-primary1;
								@include border-radius(100%);
							}
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
		}
	}
}
.link-feedback-popup {
	.MuiDialog-container {
		.MuiDialogContent-root {
			background: $color-bg-form;
		}
	}
}
.performance-dev-popper-menu {
	margin-right: 10px !important;
	.MuiPaper-elevation1 {
		.MuiList-root {
			.MuiListItem-root {
				cursor: pointer;
			}
		}
	}
}
