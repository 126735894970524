@import '../sass-utils/variables';
.MuiButton-root {
	&.global-task-btn {
		padding: 0px;
		margin-right: 10px;
		svg {
			width: 14px;
			height: 14px;
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: none;
		}
	}
}
.global-create-task {
	@include flex;
	//@include align-items(center);
	//margin-top: 13px;
	margin-top: 2px;
	padding-bottom: 30px;
	.set-timeline-area {
		margin-top: 10px;
		margin-left: 30px;
		.timeline-field {
			.calendar-btn {
				button {
					.MuiButton-label {
						@include justify-content(flex-start);
					}
					&.Mui-disabled {
						.MuiButton-label {
							color: rgba($color-primary1, 0.7);
							.icon {
								svg {
									path {
										fill: rgba($color-primary1, 0.7);
									}
								}
							}
						}
					}
				}
			}
		}
		&.no-cal-icon {
			button {
				&.Mui-disabled {
					.MuiButton-label {
						color: rgba($color-primary1, 1) !important;
						.icon {
							opacity: 0;
						}
					}
				}
			}
		}
	}
	&.task-head-row {
		.ans-box {
			width: 100%;
			//flex: initial;
			.set-timeline-area {
				position: absolute;
				right: 0px;
				top: 9px;
				margin: 0px;
				.error-field {
					min-width: 173px;
					left: -45px;
					bottom: -42px;
				}
				.calender-date {
					&.calender-date-error {
						.calendar-btn {
							button {
								.MuiButton-label {
									color: $color-text-info;
									svg {
										path {
											fill: $color-text-info;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.ans-box {
		position: relative;
		flex: 1 1;
		.MuiFormControl-root {
			min-width: 100%;
		}
		.inactive {
			textarea {
				background: $color-hover !important;
			}
		}
		.MuiInputBase-root {
			padding: 0;
			//border: 1.4px solid transparent;
			@include border-radius(6px);
			@include transition(0.25s);
			&:before,
			&:after {
				display: none;
			}
			.MuiInputBase-input {
				@include transition(0.25s);
				height: auto;
				padding: 7px 12px;
				font-size: $font-size-h5;
				line-height: 1.25;
				@include border-radius(6px);
			}
			&.Mui-focused {
				textarea {
					@include default-shadow;
				}
			}
		}
		.MuiTypography-body2 {
			font-size: $font-size-h5;
			line-height: 19px;
			color: $color-primary2;
			font-weight: $font-weight-regular;
		}
		.accomplish-answer {
			.MuiInputBase-root {
				position: relative;
				background-color: transparent;
				padding-right: 104px;
				.MuiInputBase-input {
					box-sizing: border-box;
					background-color: transparent;
					padding-left: 8px;
				}
				&.Mui-focused {
					.MuiInputBase-input {
						box-shadow: none;
					}
				}
				.MuiInputAdornment-root {
					margin-right: 0;
					margin-left: 8px;
					//margin-top: 16px;
					svg {
						width: 10px;
						height: 10px;
						path {
							fill: $color-primary1;
						}
					}
					.MuiTypography-body1 {
						font-size: $font-size-h5;
						color: rgba($color-primary2, 0.5);
					}
				}
			}
			.MuiInputBase-root {
				&.Mui-disabled {
					opacity: 1;
				}
			}
			.MuiInputBase-input {
				&.Mui-disabled {
					color: rgba($color-primary2, 0.6);
					opacity: 1;
				}
			}
			&.accomplish-ans-edit {
				.MuiInputBase-root {
					border: 1.4px solid $color-primary1 !important;
					@include border-radius(6px 6px 0 6px);
				}
			}
		}

		.accomplish-task-actions {
			position: absolute;
			right: 0;
			top: 100%;
			z-index: 1;
			@include flex;
			@include align-items(flex-start);
			button {
				padding: 4px 5px;
				&:hover {
					background-color: transparent;
				}
				&.acc-task-save-btn {
					@include border-radius(0 0 6px 6px);
					background-color: $color-primary1;
					svg {
						width: 14px;
						height: 14px;
						path {
							fill: $color-white;
						}
					}
				}
				&.acc-task-cancel-btn {
					//background: $color-bg-form;
					margin-right: 3px;
					svg {
						width: 12px;
						height: 14px;
						path {
							fill: $color-primary1;
							stroke: $color-primary1;
						}
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
}

/** global task head row css start here**/
.global-task-row-head {
	//padding-top: 30px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);

	.global-task-head-right {
		@include flex;

		.user-search {
			margin-left: 15px;
			.MuiInputBase-root {
				border-color: rgba($color-input-field, 0.5);
				background: $color-white;
				max-width: 190px;
				min-width: 190px;
				&.Mui-focused {
					border-color: $color-primary1;
				}
			}
		}
		.user-search {
			.MuiInputBase-root {
				.MuiInputBase-input {
					padding: 5px 0;
				}
			}
		}
		.list-filter-btn {
			padding: 0.375em 0.5em 0.375em 1em;
			font-size: $font-size-h5;
			background: $color-bg-form;
			border: 1.4px solid $color-border-tile;
			@include border-radius(43px);
			order: 2;
			margin-left: 10px;
			svg {
				margin-right: 10px;
			}
			&:hover,
			&.active {
				background: $color-white;
				@include default-shadow;
			}
			.filter-txt {
				@include flex;
				@include align-items(center);
				svg {
					margin-left: 10px;
				}
			}
		}
	}
}

.global-tasks-list {
	padding: 20px 0;
	.okr-list-panel-head {
		ul {
			li {
				padding: 0 16px;
			}
		}
	}
	.okr-list-panel-head,
	.global-tasks-list-content {
		padding-right: 24px;
		ul {
			li {
				&.task-col1 {
					padding-left: 34px;
					width: 47%;
				}
				&.task-col2 {
					width: 15%;
				}
				&.task-col21 {
					width: 18%;
				}
				&.task-col3 {
					width: 20%;
					.set-timeline-area {
						margin-left: 0;
						.timeline-field {
							.calendar-btn {
								button {
									.MuiButton-label {
										@include justify-content(flex-start);
									}
									&.no-edit-date {
										cursor: default;
										//margin-left: 19px;
										.MuiButton-label {
											//min-width: 82px;
										}
									}
									&.select-date {
										//cursor: default;
										//margin-left: 19px;
										.MuiButton-label {
											//min-width: 82px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.global-tasks-list-content {
		position: relative;
		ul {
			@include flex;
			@include align-items(flex-start);
			border-bottom: 1px solid $color-hover;
			li {
				&.task-col1 {
					position: relative;
					@include flex-direction(column);
					@include align-items(flex-start);
					.task-name-box {
						width: 100%;
						@include flex;
						@include align-items(flex-start);
						.task-name {
							width: auto;
							&.edit-mode-on {
								width: 100%;
							}
						}
						.task-after-edit {
							.MuiTypography-body1 {
								font-size: 14px;
							}
						}
						.task-checkbox {
							position: absolute;
							left: 0;
							top: 4px;
						}
					}
					.task-link-box {
						//min-height: 26px;
						.assigned-by-box {
							margin-top: 3px;
							p {
								color: rgba($color-primary2, 0.5);
							}
						}
					}
					.task-link {
						margin-top: 8px;
						.task-link-name {
							@include flex;
							@include align-items(center);
							padding: 0 7px;
							color: $color-secondary1;
							background: $color-bg-form;
							font-size: $font-size-subtitle2;
							line-height: $line-height5;
							font-weight: $font-weight-bold;
							@include border-radius(20px);
							text-transform: uppercase;
							border: 1.4px solid $color-border-tile;
							svg {
								margin-right: 5px;
								path {
									fill: $color-secondary1;
									stroke: $color-secondary1;
								}
							}
						}
						.task-1-on-1-list {
							@include flex;
							@include align-items(center);
							padding: 0 7px;
							color: $color-secondary1;
							background: $color-bg-form;
							font-size: $font-size-subtitle2;
							line-height: $line-height5;
							font-weight: $font-weight-bold;
							@include border-radius(20px);
							text-transform: uppercase;
							border: 1.4px solid $color-border-tile;
							.requested-to {
								padding-right: 13px;
								position: relative;
								&:after {
									content: '';
									position: absolute;
									right: 5px;
									top: 7px;
									width: 3px;
									height: 3px;
									background: rgba($color-secondary1, 0.5);
									@include border-radius(3px);
								}
							}
							.request-arrow {
								margin: 0 5px;
								width: 14px;
								height: 14px;
								@include border-radius(100%);
								background: $color-border-tile;
								@include flex;
								@include align-items(center);
								@include justify-content(center);
								svg {
									width: 10px;
									height: 10px;
									path {
										stroke: $color-primary2;
										stroke-width: 1px;
									}
								}
								&.arrow-left {
									@include transform(rotate(180deg));
								}
							}
						}
					}
					.edit-task-field {
						position: relative;
						@include animation(fade-in 1.1s);
						.MuiInput-underline:before,
						.MuiInput-underline:after {
							display: none;
						}
						.MuiInputBase-root {
							padding: 0.25em 0.5em;
							border: 1px solid $color-input-field;
							@include border-radius(6px 6px 0);
							box-shadow: none;
						}
						.edit-task-actions {
							position: relative;
							right: 0;
							top: 100%;
							text-align: right;
							button {
								padding: 0.2em;
								min-width: auto;
								border-radius: 0px 0px 4px 4px;
								vertical-align: top;
								svg {
									width: 0.875em;
									height: 0.875em;
								}
								&.edit-task-cancel {
									margin-right: 0.25em;
									svg {
										width: 1em;
										path {
											fill: $color-input-field;
										}
									}
									&:hover {
										background: transparent;
									}
								}
								&.edit-task-save {
									background: $color-input-field;
									svg {
										path {
											fill: $color-white;
										}
									}
									&:hover {
										background: $color-input-field;
									}
								}
							}
						}
					}
				}
				&.task-col2 {
					.source-name {
						display: inline-block;
						padding: 0 7px;
						color: $color-secondary1;
						font-size: $font-size-subtitle2;
						line-height: $line-height5;
						font-weight: $font-weight-bold;
						text-transform: uppercase;
						background: $color-secondary2;
						border: 1.4px solid $color-secondary2;
						@include border-radius(20px);
					}
				}
			}
		}
		&:hover {
			ul {
				background: rgba($color-hover, 0.5);
			}
			.delete-icon {
				opacity: 1 !important;
			}
		}
		.delete-icon {
			position: absolute;
			right: -5px;
			top: 5px;
			padding: 2px;
			width: 24px;
			height: 24px;
			@include border-radius(50%);
			opacity: 0;
			margin-left: 5px;
			svg {
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
			&.Mui-disabled {
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
					}
				}
			}
		}
	}
	&.global-tasks-by-sources {
		padding: 10px 0;
		.okr-list-panel-head,
		.global-tasks-list-content {
			ul {
				li {
					&.task-col1 {
						padding-left: 34px;
						width: 62%;
					}
					&.task-col3 {
						width: 20%;
					}
				}
			}
		}
	}
	.load-more-link {
		margin: 30px 0 10px 0;
		.MuiTypography-body1 {
			color: $color-primary1;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.okr-filter {
	&.task-filter-popup {
		z-index: 99;
		ul {
			li {
				padding: 0px;
			}
		}
		.okr-progress-filter {
			ul {
				li {
					&:last-child {
						border-bottom: 0px;
					}
				}
			}
		}
		.alignmentFilterWrap {
			max-height: 218px;
		}
	}
}

.global-task-subheader {
	position: relative;
	.task-date-selector {
		position: absolute;
		right: 0px;
		top: 16px;
		padding-right: 4em;
		@include flex;
	}
	.range-selector-calender {
		margin: 0 10px;
		position: relative;
		display: flex;
		align-items: center;
		.form-control {
			width: 195px;
			cursor: pointer;
			height: 30px;
			padding: 0 10px 0 28px;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			border: 1.4px solid rgba($color-border-tile, 1);
			@include border-radius(30px);
			background-color: rgba($color-bg-form, 1);
			background-image: url(../../images/calendarSmall.svg);
			background-repeat: no-repeat;
			background-position: 10px center;
			background-size: 14px;
		}
	}
	.reset-btn {
		padding: 0;
		width: auto;
		//height: auto;
		&:hover {
			background-color: transparent;
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
}
.sources-collapsible {
	padding: 1.5em 0;
	.sources-collapsible-body {
		margin-bottom: 5px;
		.MuiCheckbox-root {
			svg {
				path {
					fill: $color-white;
				}
			}
			&.Mui-checked {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
		}
		.sources-collapsible-head {
			padding: 16px 0 16px 24px;
			position: relative;
			@include flex;
			@include justify-content(space-between);
			@include align-items(flex-start);
			cursor: pointer;
			&:after {
				content: '';
				width: 100%;
				height: 1px;
				position: absolute;
				left: 0;
				top: 26px;
				border-bottom: 1px solid $color-hover;
			}
			.rotating-arrow {
				z-index: 1;
				cursor: pointer;
				position: absolute;
				width: 24px;
				height: 100%;
				left: 0;
				top: 0;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				@include transition(0.5s);
				background-color: $color-white;
				svg {
					@include transition(0.5s);
				}
			}
			.sources-collapsible-head-content {
				padding: 0 8px;
				background: $color-white;
				@include flex;
				z-index: 1;
				.MuiTypography-body1 {
					sup {
						line-height: 8px;
						display: inline-block;
						vertical-align: top;
					}
				}
			}
			&:hover {
				background: $color-hover;
				.rotating-arrow {
					background: $color-bg-teamstag;
				}
				.sources-collapsible-head-content {
					background: $color-hover;
				}
			}
		}
		.sources-collapsible-content {
			padding: 0 0px 0 20px;
		}
		&.expand-row {
			.sources-collapsible-head {
				background: $color-hover;
			}
			.rotating-arrow {
				background: $color-bg-teamstag;
				svg {
					@include transform(rotate(90deg));
				}
			}
			.sources-collapsible-head-content {
				background: $color-hover;
			}
		}
	}
}

.space-loading {
	margin-top: 50px;
}
