@import '../sass-utils/variables';
.user-snapshot-content-panel {
	padding-top: 0px !important;
	.welcome-content {
		margin-top: 0px;
		width: 100%;
	}
	.information-message-user {
		position: static;
		.no-sentence-case {
			@include align-items(flex-start);
		}
	}
}
.drawer-horizontal-space {
	padding-left: 3.75em !important;
	padding-right: 3.75em !important;
}
.user-snapshot-drawer-content {
	.snapshot-user-info {
		padding-top: 20px;
		padding-bottom: 20px;
		.snapshot-user-details {
			@include flex;
			@include align-items(center);
			.user-img {
				margin-right: 10px;
				margin-top: 0;
				.MuiAvatar-root {
					margin-top: 0;
					width: 60px;
					height: 60px;
					font-size: $font-size-h2;
				}
			}
		}
	}
	.progress-chart-section {
		@include flex;
		margin: 30px 0 0;
		.progress-chart-tile {
			flex: 1 1;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			padding: 20px;
			margin-right: 20px;
			cursor: default;
			&.checkin-coverage {
				cursor: pointer;
			}
			&.active {
				border: 1.4px solid $color-primary1;
				@include box-shadow(2px 2px 10px rgba($color-primary2, 0.12));
			}
			&:last-child {
				margin-right: 0px;
			}
			.progress-chart-content {
				text-align: center;
				margin-top: 10px;
				.pie-chart {
					width: 135px;
					height: 90px;
					margin: 0 auto;
				}
				.MuiTypography-h6 {
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					margin-top: -1px;
					position: relative;
				}
				.info-link {
					svg {
						width: 14px;
						height: 14px;
						margin-left: 5px;
						display: block;
						path {
							fill: $color-primary1;
						}
					}
					.MuiButton-root {
						padding: 0px;
						&:hover {
							background: none;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
			}
			.progress-bottom {
				border-top: 1.4px solid $color-hover;
				padding-top: 10px;
				margin-top: 10px;

				.priority-text {
					@include flex;
					margin-top: 15px;
					.col {
						flex: 1 1;
						margin-right: 5px;
						@include flex;
						@include align-items(center);
						&:nth-child(2) {
							@include justify-content(center);
						}
						&:last-child {
							margin-right: 0px;
							@include justify-content(flex-end);
						}
					}
					.MuiTypography-body1 {
						font-weight: $font-weight-bold;
						color: $color-primary2 !important;
						@include flex;
						@include align-items(center);
						line-height: $line-height5;
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
					}
				}
			}
			.default-gray-chip {
				background: $color-secondary2;
				.MuiChip-label {
					color: $color-secondary1;
				}
			}
			.duration-text {
				margin-top: 15px;
				@include flex;
				@include justify-content(space-between);
				.MuiTypography-body2 {
					@include flex;
					@include align-items(center);
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
				.btn-small-text {
					text-transform: initial !important;
					line-height: $line-height5;
				}
			}
			.progress-chart-top {
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 1.4px solid $color-hover;
				.duration-text {
					margin-top: 10px;
				}
				&:last-child {
					padding-bottom: 0px;
					border: 0px;
				}
				.text-with-icon {
					@include flex;
					@include align-items(center);
					margin-top: 10px;
					padding-bottom: 5px;
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
					&.red-color {
						color: $color-text-info;
						svg {
							path {
								fill: $color-text-info;
							}
						}
					}
				}
			}
			.progress-chart-bottom {
				.duration-text {
					margin-top: 10px;
				}
			}
		}
	}
	.drawer-blue-bg-full {
		padding-top: 30px !important;
		padding-bottom: 30px !important;
	}
}

/**progress-reflection-popup css start here**/
.progress-reflection-popup {
	width: 100%;
	background: $color-white;
	border: 1.4px solid $color-primary1;
	@include border-radius(6px);
	@include box-shadow(2px 0px 10px 0px rgba($color-primary2, 0.12));
	margin-top: 20px;
	.popup-head {
		background: $color-okr-highlighter;
		padding: 20px 20px 5px 20px;
		@include border-radius(6px 6px 0 0);
		.popup-title {
			@include flex;
			@include justify-content(space-between);
			.cross-btn {
				padding: 0px;
				svg {
					width: 12px;
					height: 12px;
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
					}
				}
				&:hover {
					background: transparent;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
		.tab-scroller {
			display: block;
			background: none;
			width: 100%;
			max-width: 100%;
			margin-top: 10px;
			.MuiTabs-root {
				margin: 0 -5px;
				padding: 0px;
				width: calc(100% + 10px);
				min-height: 48px;
			}
		}
	}
	.popup-content {
		padding: 20px;
		.not-found {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			object {
				margin-right: 20px;
			}
		}
		.MuiTypography-body1 {
			font-size: $font-size-h4;
			line-height: $line-height2;
		}
	}
}
.role-description-popup.white-tooltip {
	margin-right: 0;
	margin-left: -58px;
	margin-top: 11px;
	padding: 10px;
	@include box-shadow(0px 5px 30px rgba($color-primary2, 0.2));
	&::before {
		right: auto;
		left: 59px;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -10px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		min-width: auto;
		width: auto;
		height: auto;
		padding-right: 0px;
		@include custom-scrollbar-small-width;
		resize: none;
		.MuiTypography-body1 {
			font-size: $font-size-h6;
			line-height: $line-height5;
		}
	}
}
/**feedback state css here**/
.feedback-snapshot-area {
	@include flex;
	@include align-items(center);
	margin: 30px 0;
	flex-wrap: wrap;
	gap: 30px 20px;
	.feedback-states-area {
		margin: 0;
		flex: 1 1 169px;
		max-width: 169px;
		.feedback-state-box {
			width: 100%;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			padding: 20px 15px;
			margin-right: 0px;
			cursor: default;
			.MuiTypography-h5 {
				margin-bottom: 10px;
			}
			.title-box {
				margin-bottom: 30px;
				@include flex;
				@include justify-content(space-between);
			}
			.feedback-state-title {
				@include flex;
				@include align-items(center);
				font-size: $font-size-h1;
				line-height: $line-height1;
				font-weight: $font-weight-black;
				margin: 0px;
				svg {
					margin-right: 5px;
					width: 16px;
					height: 16px;
				}
				sup {
					font-size: $font-size-base;
					line-height: $line-height5;
					margin-top: -12px;
					margin-left: 3px;
				}
			}
			&:hover {
				border: 1.4px solid $color-border-tile;
				box-shadow: none;
				opacity: 1;
			}
			&.active {
				border: 1.4px solid rgba($color-primary1, 0.5);
				@include default-shadow;
			}
			.assign-users {
				.help-text {
					margin-top: 0px;
				}
				.assign-users-inner {
					.user-avatar-group {
						margin-right: 0px;
					}
				}
			}
		}
	}
}
/**okr progress chart css start here**/
.okr-progress-chart {
	@include flex;
	.okr-tile-head {
		margin-bottom: 18px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		min-height: 31px;
		.okr-team-dropdown {
			button {
				padding: 7px 10px;
				font-size: $font-size-h5;
				background: $color-bg-form;
				border: 1.4px solid $color-border-tile;
				@include border-radius(43px);
				order: 2;
				margin-right: 0px;
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
					&:last-child {
						margin-right: 0px;
						width: 10px;
						height: 10px;
					}
				}
			}
			.team-dropdown {
				min-width: 213px;
				max-width: 213px;
				z-index: 99;
				.MuiListItem-root {
					&:first-child {
						@include border-radius(6px 6px 0 0);
					}
					&:last-child {
						border-bottom: 0px;
						@include border-radius(0 0 6px 6px);
					}
					svg {
						width: 14px;
						height: 14px;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.okr-progress-graph {
		width: 65.8%;
		margin-right: 20px;
		background: $color-secondary1;
		@include border-radius(6px);
		padding: 15px 20px 10px;
		.okr-tile-head {
			.MuiTypography-h5 {
				color: $color-white;
			}
		}
	}
	.status-progress-graph {
		width: 31.59%;
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		padding: 10px 20px;
	}
}
