@import '../../sass-utils/variables';

.insight-wrapper {
	.global-header {
		min-height: auto;
	}
}

.reports-graph-container {
	.avatar-chart {
		width: 40px;
		height: 40px;
		@include flex;
		overflow: hidden;
		position: relative;
		font-size: 1em;
		@include align-items(center);
		flex-shrink: 0;
		line-height: 1;
		user-select: none;
		border-radius: 50%;
		@include justify-content(center);
		background: $color-bg-info;
		color: $color-tags;
		font-weight: $font-weight-regular;
		margin-right: 0.5em;
	}
	.graph-full-container,
	.graph-container {
		width: 48%;
		margin-bottom: 3em;

		.graph-title {
			height: 4em;
			@include flex;
			@include align-items(center);
			.MuiTypography-h4 {
				svg {
					margin-left: 0.25em;
				}
			}
		}
		.graph-chart {
			background: $color-white;
			padding: 2em 15px 2em 17px;
			@include border-radius(0.5em);

			.graph-actions {
				@include flex;
				@include justify-content(flex-end);
				.fit-to-screen-icon {
					min-width: auto;
					max-width: 2em;
					width: 2em;
					height: 2em;
					background: $color-white;
					border-radius: 100%;
					border: 1px solid rgba(161, 171, 185, 0.4);
					@include flex;
					@include align-items(center);
					padding: 0.5em;
				}
			}
			.min-threshold-content {
				p {
					position: relative;
					padding: 0 0 0 3em;
					margin-right: 1em;
					&:before {
						position: absolute;
						left: 0;
						top: 50%;
						content: '';
						width: 2.5em;
						height: 1px;
						background: #000;
					}
					&:first-child {
						color: $color-keyword-highlighter;
						&:before {
							background: $color-keyword-highlighter;
						}
					}
					&:last-child {
						color: #bf3002;
						&:before {
							background: #bf3002;
						}
					}
				}
			}
			svg {
				.highcharts-legend,
				.highcharts-axis-labels,
				.highcharts-axis,
				.highcharts-axis-title {
					text {
						fill: $color-primary2 !important;
						font-weight: $font-weight-thin;
						font-size: $font-size-h6 !important;
					}
					.highcharts-legend-box {
						stroke: none;
					}
				}
			}
			&.graph-okr-chart {
				border-radius: 0 0 6px 6px;
				padding-top: 0;
				position: relative;
				.highcharts-label,
				.highcharts-series {
					cursor: pointer;
				}
				.bubble-wrapper {
					transition: none;
					&.active {
						width: 83px;
						left: -1px;
						border: 6px solid $color-primary1;
						top: 0;
						height: 83px;
						line-height: 32;
						@include flex;
						border-radius: 50%;
						background: $color-bubble;
						.bubble-label-1 {
							transition: none;
							line-height: 4.5rem;
						}
					}
					&.bubble-wrapper-firstlvl {
						.bubble-label-2 {
							font-size: 16px;
							font-weight: $font-weight-bold;
							display: none;
							&:before {
								content: '';
								width: 19px;
								height: 18px;
								background: url(../../../images/contributer-icon.svg) no-repeat 0 center;
								display: inline-block;
								margin-right: 4px;
								vertical-align: middle;
								background-size: 100%;
							}
							span {
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
	.graph-full-container {
		width: 100%;
	}
	.graph-chart-wrap {
		background: $color-white;
		@include border-radius(6px 6px 0 0);
	}
	.okr-report-header-wrap {
		padding: 18px 25px 15px;
		position: relative;
		z-index: 1;
	}
	.okr-report-header {
		@include flex;
		@include justify-content(space-between);
		padding-bottom: 10px;
		position: relative;
		.MuiTypography-h4 {
			margin-top: 10px;
		}
		.okr-filter-group {
			background: $color-white;
			button {
				margin: 0;
				padding: 0.2em 0.9em;
				border: 1px solid $color-input-field;
				color: $color-input-field;
				min-width: 102px;
				&:first-child {
					@include border-radius(6px 0 0 6px);
				}
				&:last-child {
					border-radius: 0 6px 6px 0;
				}
				&.contained-button {
					background: $color-primary1;
					color: $color-white;
					border: 1px solid $color-primary1;
				}
				&:not(:last-child) {
					border-right-color: transparent;
				}
			}
		}
	}
	.graph-header {
		position: relative;
		z-index: 1;
	}
	.okr-listing-content {
		background-color: $color-white;
		@include border-radius(6px);
		position: relative;
		cursor: default;
		ul {
			padding: 0;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			li {
				display: block;
				padding: 0;
				&.okr-col1 {
					width: 50%;
					.graph-description {
						padding-right: 10px;
					}
				}
				&.okr-col2 {
					width: 18%;
				}
				&.okr-col3 {
					width: 12%;
					.okr-date {
						letter-spacing: 0px;
						text-align: left;
					}
				}
				&.okr-col4 {
					width: 23%;
					@include flex;
					@include align-items(center);
					@include justify-content(flex-end);
					.MuiBox-root {
						max-width: 15em;
					}
					.okr-update {
						letter-spacing: 0px;
						text-align: left;
					}
					.MuiTypography-root.MuiTypography-body1 {
						margin-bottom: -5px;
					}
				}
			}
		}
		.back-btn {
			position: absolute;
			top: -3.5em;
			@include flex;
			cursor: pointer;
			left: -2px;
			p {
				color: $color-primary1;
			}
		}
	}
}

.custom-node-card {
	.MuiCardHeader-root {
		padding-bottom: 0;
	}
	.MuiCardHeader-action {
		.more-action-btn {
			padding: 0.25em;
			svg {
				path {
					stroke: none;
				}
			}
		}
	}
	.okr-progress {
		padding-bottom: 0.5em;
	}
}

.chart-contributors-list {
	.MuiPaper-root {
		position: relative;
		width: 20em;
		padding: 1.5em 0.75em 1em 1.5em;
		box-shadow: 0px 4px 12px rgba(174, 174, 174, 0.2);
		@include border-radius(6px);
		.chart-contributors-list-inner {
			padding-right: 0.75em;
			min-height: 230px;
			max-height: 230px;
			overflow-y: auto;
			overflow-x: hidden;
			@include flex;
			@include flex-direction(column);
			@include justify-content(space-between);
			@include custom-scrollbar;
			.contributor-pagination-wrapper {
				width: 238px;
			}
			.objective-name {
				display: block;
			}
			&.weekly-kr-card-wrap {
				min-height: 0;
			}
		}
		.MuiLink-root {
			color: $color-primary2;
			text-decoration: underline;
			&:hover {
				color: $color-primary1;
			}
		}
		.MuiList-root {
			padding-bottom: 0;
			.MuiListItem-root {
				padding: 0.35em 0;
			}
			&.kr-contributors-list {
				margin-right: -1em;
				padding-right: 10px;
				.MuiListItem-container {
					padding-right: 2.6em;
					.MuiListItemSecondaryAction-root {
						right: 0;
					}
					.MuiListItemAvatar-root {
						min-width: 50px;
					}
				}
				.MuiListItemText-root {
					.MuiListItemText-primary {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
					a {
						text-transform: capitalize;
					}
				}
			}
		}
	}
}
.inner-wrapper {
	.reports-graph-container {
		.okr-progress {
			width: 100%;
		}
	}
}
.bubble-wrapper {
	position: relative;
	@include flex;
	@include flex-direction(column);
	@include align-items(center);
	.bubble-label-1,
	.bubble-label-2 {
		font-weight: $font-weight-regular;
	}
	.bubble-label-1 {
		font-size: $font-size-h4;
		margin-bottom: 2px;
		line-height: 3.1rem;
	}
	.bubble-label-2 {
		font-size: 10px;
	}
	.contributor-details {
		position: absolute;
		right: 100%;
		top: 0;
		width: 220px;
		max-height: 180px;
		z-index: 3;
		box-shadow: 0px 10px 30px rgba(98, 98, 98, 0.1);
		border-radius: 0.5em;
		overflow-y: auto;
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translateY(1em);
		transform: translateY(1em);
		transition: 0.5s all cubic-bezier(0.785, 0.135, 0.15, 0.86);
		ul {
			display: block;
			padding: 0;
			li {
				background: $color-white;
				@include flex;
				padding: 0.5em 1em;
				color: $color-primary2;
				@include align-items(center);
				font-weight: $font-weight-regular;
			}
		}
	}
	&:hover {
		.contributor-details {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}
.contributor-pagination-arrow {
	svg {
		width: 12px;
		height: 12px;
	}
	&.disabled {
		opacity: 0.5;
		cursor: default !important;
	}
}

.fit-to-screen-icon {
	svg {
		width: 20px;
		height: 20px;
	}
}
.help-text {
	text-align: center;
}
