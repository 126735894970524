@import '../../../styles/sass-utils/variables';
.user-assessment-box-area {
	@include flex;
	padding: 30px 0;

	.user-assessment-box-area-inner {
		@include flex;
		position: relative;
	}
	.score-info {
		min-width: 430px;
		position: absolute;
		left: 0px;
		bottom: -22px;
		@include flex;
		@include justify-content(flex-start);
		@include align-items(center);
		h6 {
			position: relative;
			margin-right: 0.5em;
			padding-right: 0.8em;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 7px;
				width: 3px;
				height: 3px;
				@include border-radius(3px);
				background-color: $color-bg-dot;
				opacity: 0.5;
			}
			&:last-child {
				padding: 0;
				margin: 0;
				&::after {
					display: none;
				}
			}
		}
	}
	.user-assessment-box-area-left {
		@include flex;
		.assessment-box {
			flex: 1;
			margin-right: 2px;
			padding: 20px 15px;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			max-width: 311px;
			background-color: rgba($color-white, 0.5) !important;
			cursor: default;
			min-width: 194px;
			@include flex;
			@include justify-content(space-between);
			@include flex-direction(column);
			&:last-child {
				margin-right: 0px;
			}

			&.assessment-box-type-2 {
				background-color: rgba($color-white, 0.5);
				position: relative;
				&.open-final-rating {
					.box-head {
						//padding-bottom: 16px;
					}
					&.default-final-rating {
						opacity: 0;
					}
				}
				.expand-final-rating {
					position: absolute;
					left: -1.4px;
					top: -1.4px;
					padding: 20px 15px;
					background: $color-white;
					border: 1.4px solid $color-primary1;
					@include default-shadow;
					@include border-radius(6px);
					width: calc(100% + 2.8px);
					z-index: 100;
					&:hover,
					&.active {
						border: 1.4px solid $color-primary1;
						@include default-shadow;
					}
					.final-assessment-selection-box {
						.custom-type-dropdown-top {
							margin-bottom: 10px;
							.ques-type-dropdown {
								.MuiList-root {
									max-height: 156px;
									.MuiListItem-root {
										padding-left: 10px;
										padding-right: 10px;
									}
								}
							}
							.dropdown-button {
								padding: 4px 10px;
							}
						}
						.final-rating-action {
							margin-top: 20px;
							@include flex;
							@include align-items(center);
							@include justify-content(flex-end);
							.btn-link {
								height: auto;
								line-height: $line-height4;
								margin: 0px;
							}
							.btn-primary {
								margin-left: 8px;
							}
						}
					}
				}
			}
			.box-outer-head {
				min-height: 40px;
				margin-bottom: 10px;
			}
			.box-head {
				@include flex;

				//@include align-items(baseline);
				.box-head-right {
					@include flex;
					@include flex-direction(column);
					@include justify-content(center);
					.btn-link {
						.MuiTypography-h2 {
							color: $color-primary1;
						}
					}
				}
				.MuiTypography-h2 {
					font-size: $font-size-tile-head;
					line-height: 28px;
					font-weight: $font-weight-bold;
					&.blank-text {
						opacity: 0.3;
						//margin-top: -18px;
					}
				}
				svg {
					margin-right: 10px;
					width: 24px;
					height: 24px;
				}
				.overall-txt {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-regular;

					display: block;
				}
				sub.final-rating-text {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-bold;
					color: $color-secondary1;
					margin-left: 7px;
				}
				.btn-link {
					height: auto;
					line-height: 1;
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					margin: 0px;
					display: block;
					text-decoration: none;
				}
			}
			.box-setting-des {
				min-height: 32px;
				margin-bottom: 10px;
			}
			.box-content {
				//margin-top: 10px;
				//.MuiTypography-h5,
				.MuiTypography-h6 {
					font-weight: $font-weight-regular;
					min-height: 16px;
				}
				.MuiTypography-h5 {
					padding-bottom: 5px;
				}
				&.alert-box-content {
					.MuiTypography-h5,
					.MuiTypography-h6 {
						color: $color-text-info;
						@include flex;
						@include align-items(center);
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
					}
				}
			}

			&.active {
				background-color: $color-white !important;
				border: 1.4px solid $color-primary1;
				@include default-shadow;
				cursor: pointer;
			}
			&.enable-box {
				background-color: $color-white !important;
				cursor: pointer;
				&:hover {
					border: 1.4px solid $color-primary1;
					@include default-shadow;
				}
			}
		}
	}
	.user-assessment-box-area-right {
		@include flex;
		flex-flow: row wrap;
		max-width: 162px;
		margin-left: 8px;
		&.only-one-tile {
			.assessment-right-box {
				@include align-items(flex-start);
				max-width: 115px;
				//max-height: 137px;
				.box-content {
					flex-direction: column;
					@include justify-content(flex-start);
					min-width: 68px;
					@include align-items(flex-start);
					.request-1on1-icon {
						margin: 0 0 25px;
					}
					.MuiTypography-h5 {
						margin-top: 20px;
						font-weight: $font-weight-bold;
					}
				}
			}
		}
		.assessment-right-box {
			@include flex;
			@include align-items(center);
			width: 100%;
			margin-bottom: 8px;
			cursor: pointer;
			padding: 20px 15px;
			background-color: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			max-width: 162px;
			.box-content {
				@include flex;
				@include align-items(center);
				.icon {
					margin-right: 15px;
					@include flex;
					padding: 8px;
					@include border-radius(6px);
					&.performance-icon {
						background: rgba($color-completed, 0.2);
					}
					&.request-1on1-icon {
						background: rgba($color-bg-filterbtn, 0.7);
					}
					svg {
						width: 16px;
						height: 16px;
					}
				}
				.MuiTypography-h5 {
					//font-weight: $font-weight-regular;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.active,
			&:hover {
				border: 1.4px solid $color-primary1;
				@include default-shadow;
			}
		}
	}
}

.mid-year-review-info {
	margin-top: -20px;
	min-width: 430px;
	@include flex;
	@include justify-content(flex-start);
	@include align-items(center);
	.MuiTypography-h6 {
		position: relative;
		margin-right: 10px;
		padding-right: 10px;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 7px;
			width: 3px;
			height: 3px;
			@include border-radius(3px);
			background-color: $color-bg-dot;
			opacity: 0.5;
		}
		&:last-child {
			padding: 0;
			margin: 0;
			&::after {
				display: none;
			}
		}
	}
}
