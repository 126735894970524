@import '../../../../sass-utils/variables';
.default-copy-obj-drawer {
	.src-okr-head {
		display: block;
		//margin-bottom: 15px;
		.search-action-field {
			width: 100%;
			margin-bottom: 15px;
		}
		.cycle-dropdown {
			@include flex;
			@include justify-content(flex-end);
			margin-right: -1.125em;
		}
	}
	.drawer-form-fields {
		.drawer-blue-bg-full {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	.import-okr-list {
		background: $color-white;

		.import-obj-row {
			.import-obj-col {
				width: 67%;
				max-width: 67%;
				min-width: 67%;
				margin-right: 0;
				padding-right: 20px;
			}
			.import-obj-progress-col {
				.user-count {
					width: 100%;
					.MuiTypography-h6 {
						text-transform: uppercase;
					}
				}
				.link-toggle {
					margin-right: 16px;
					label {
						margin: 2px 0 0;
					}
				}
			}
			.import-obj-progress-col {
				width: 30%;
				max-width: 30%;
				min-width: 30%;
			}
		}

		.import-kr-row {
			.import-kr-col {
				width: 100%;
				max-width: 100%;
				margin-right: 0;
				padding-right: 0px;
			}
			.import-kr-progress-col {
				width: 44px;
			}
		}

		.link-toggle {
			width: 24px;
			margin-left: 20px;
			label {
				margin: 7px 0 0;
				vertical-align: top;
			}
		}
	}
}
