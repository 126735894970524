/**tooltip css**/
body {
	.MuiTooltip-popper {
		z-index: 99999 !important;
		.MuiTooltip-tooltip {
			background: $color-primary2;
			@include tooltip-shadow;
			color: $color-white;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
			padding: 6px 10px;
			max-width: 166px;
		}
		.MuiTooltip-arrow {
			color: $color-primary2;
		}
		&.MuiTooltip-popperArrow[x-placement*='top'] {
			.MuiTooltip-arrow {
				bottom: 1px !important;
			}
		}
		&.MuiTooltip-popperArrow[x-placement*='bottom'] {
			.MuiTooltip-arrow {
				margin-top: -0.68em;
			}
		}
		&.MuiTooltip-popperArrow[x-placement*='right'] {
			.MuiTooltip-arrow {
				left: 1px !important;
			}
		}
		&.MuiTooltip-popperArrow[x-placement*='left'] {
			.MuiTooltip-arrow {
				right: 1px !important;
			}
		}
		.MuiTypography-root {
			color: $color-white;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
		}
		em {
			font-weight: $font-weight-regular;
			margin-top: 0.625em;
			display: block;
		}
		&.tooltip-layout-with-icon {
			.MuiTooltip-tooltip {
				max-width: 200px;
				margin: 13px 0 0 0;
			}
			.tooltip-text {
				@include flex;
				@include align-items(flex-start);
				svg {
					height: 14px;
					margin-right: 10px;
				}
			}
			&.MuiTooltip-popperArrow[x-placement*='top'] {
				.MuiTooltip-arrow {
					left: 42px !important;
				}
			}

			&.MuiTooltip-popperArrow[x-placement*='bottom'] {
				.MuiTooltip-arrow {
					left: 38px !important;
				}
			}
		}
		&.custom-tooltip-icon {
			.MuiTooltip-tooltip {
				max-width: 192px;
				margin: 0 0 10px 0;
			}
			.tooltip-text {
				@include flex;
				@include align-items(flex-start);
				svg {
					height: 16px;
					min-width: 16px;
					margin-right: 13px;
					margin-top: 5px;
				}
			}
			&.custom-tooltip-btn {
				.MuiTooltip-tooltip {
					margin-left: 20px;
				}
			}
		}
		&.tooltip-layout1 {
			.MuiTooltip-tooltip {
				max-width: 130px;
				min-width: 130px;
			}
		}
		&.tooltip-layout2 {
			.MuiTooltip-tooltip {
				max-width: 156px;
				min-width: 156px;
			}
		}
		&.tooltip-layout3 {
			.MuiTooltip-tooltip {
				max-width: 190px;
				min-width: 190px;
			}
		}
		&.tooltip-layout4 {
			.MuiTooltip-tooltip {
				max-width: 200px;
				min-width: 200px;
			}
		}
		&.tooltip-layout5 {
			.MuiTooltip-tooltip {
				max-width: 250px;
				min-width: 250px;
			}
		}
		&.tooltip-layout6 {
			.MuiTooltip-tooltip {
				max-width: 260px;
				min-width: 260px;
			}
		}
		&.tooltip-layout7,
		&.conf-medium {
			.MuiTooltip-tooltip {
				max-width: 225px;
				min-width: 225px;
			}
		}
		&.tooltip-layout8,
		&.conf-low {
			.MuiTooltip-tooltip {
				max-width: 210px;
				min-width: 210px;
			}
		}
		&.tooltip-layout9 {
			.MuiTooltip-tooltip {
				max-width: 175px;
				min-width: 175px;
			}
		}
		&.tooltip-layout10 {
			.MuiTooltip-tooltip {
				max-width: 185px;
				min-width: 185px;
			}
		}
		&.tooltip-layout11 {
			.MuiTooltip-tooltip {
				max-width: 320px;
				min-width: 320px;
			}
		}
		&.tooltip-layout12 {
			.MuiTooltip-tooltip {
				max-width: 280px;
				//min-width: 320px;
			}
		}
		&.future-week-tooltip {
			.MuiTooltip-tooltip {
				margin-top: 5px;
			}
		}
		&.add-another-tooltip {
			.MuiTooltip-tooltip {
				margin: 0px 0 0px -46px;
				max-width: 190px;
				min-width: 190px;
			}
		}
		&.tooltip-layout-margin {
			.MuiTooltip-tooltip {
				margin: 5px 0 !important;
			}
		}
		&.tooltip-alert {
			.MuiTooltip-tooltip {
				background: $color-alert-tooltip;
			}
			.MuiTooltip-arrow {
				color: $color-alert-tooltip;
			}
			ul {
				padding: 0;
				margin-left: 12px;
				li {
					margin: 0;
					padding: 0;
					position: relative;
					display: list-item;
					list-style-type: disc;
				}
			}
		}
		&.timeframe-tooltip {
			ul {
				margin-left: 0;
				li {
					@include flex;
				}
			}
		}
		&.due-date-history-tooltip {
			.due-date-title {
				padding-bottom: 5px;
			}
			ul {
				li {
					span {
						min-width: 80px;
						&:nth-child(2) {
							min-width: 80px;
						}
					}
				}
			}
		}
		&.add-button-tooltip {
			&.MuiTooltip-popperArrow[x-placement*='top'] {
				.MuiTooltip-arrow {
					bottom: 1px !important;
					left: 109px !important;
					margin-left: 0;
				}
			}
		}
		&.checkins-future-week-tooltip {
			margin-left: 4px;
			.MuiTooltip-tooltip {
				max-width: 379px;
				margin: 0 0 25px 0;
				padding: 6px 10px 6px 5px;
			}
			.tooltip-text {
				@include flex;
				@include align-items(flex-start);
				svg {
					height: 16px;
					min-width: 16px;
					margin-right: 5px;
					margin-top: 5px;
				}
			}
		}
		&.tooltip-error-new {
			.MuiTooltip-tooltip {
				background: $color-text-info;
			}
			.MuiTooltip-arrow {
				color: $color-text-info;
			}
		}
		&.tooltip-pipfeedbacktitle {
			.MuiTooltip-tooltip {
				max-width: 100% !important;
				margin-top: -6px;
			}
			&[x-placement~='top-start'] {
				margin-top: 0px;
				margin-bottom: -8px;
			}
			.MuiTooltip-arrow {
				left: 50% !important;
				margin-left: -6px;
			}
		}
		&.tooltip-layout-auto {
			.MuiTooltip-tooltip {
				min-width: auto;
				max-width: 500px;
			}
		}
		&.tooltip-layout-trainings {
			.MuiTooltip-tooltip {
				margin: 5px 0;
				max-width: 350px !important;
				p {
					color: $color-white;
				}
			}
		}
	}

	/**tooltip html css start here**/
	.tooltip-html {
		.MuiTooltip-tooltip {
			ul {
				li {
					padding: 0 0 0.25em;
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					line-height: $line-height5;
				}
			}
		}
	}
}
