@import '../sass-utils/variables';

.primary-search-field {
	z-index: 1;
	width: calc(100% - 95px);
	.rbt-menu {
		@include box-shadow(0px 5px 30px 8px rgba($color-primary2, 0.1));
		border-top: 0px;
		@include border-radius(6px);
		margin-top: 5px;
		width: 396px !important;
	}
	.rbt-input-main {
		padding: 0 2em 0 2.5em;
		height: 30px;
		font-weight: $font-weight-regular;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary2;
		width: 100%;
		border: none;
		background-color: rgba($color-white, 0.5);
		background-image: url(../../images/search-sprite.svg);
		background-repeat: no-repeat;
		background-position: 10px 8px;
		font-family: $font-family-lato;
		@include border-radius(30px);
		&:focus {
			background-position: 10px -106px;
		}
	}
	.select-search-dropdown {
		.react-select__control {
			min-width: 316px;
			min-height: 36px;
			max-height: 36px;
			border: 1.4px solid rgba($color-white, 0.5);
			@include border-radius(20px);
			background: rgba($color-white, 0.5);
			.react-select__value-container {
				padding-top: 0;
				padding-bottom: 0;
			}
			.react-select__indicators {
				display: none;
			}
			&:hover {
				border: 1.4px solid rgba($color-white, 0.5);
			}
			&.react-select__control--is-focused {
				border: 1.4px solid rgba($color-white, 0.5);
				box-shadow: none;
			}
		}
		.react-select__menu {
			margin-top: 2px;
			top: 100%;
			border: 1.4px solid rgba($color-white, 0.5);
			@include border-radius(6px);
			@include box-shadow(0px 5px 30px 8px rgba($color-primary2, 0.1));
			.react-select__menu-list {
				border-top: 1.4px solid transparent;
			}
		}
	}
}
.rbt-input-hint {
	display: none;
}
.rbt-menu {
	background-color: $color-white;
	@include box-shadow(0px 25px 30px rgba(0, 0, 0, 0.1));
	max-height: 330px !important;
	border-radius: 0 0 6px 6px;
	border-top: 1.4px solid $color-hover;
	padding: 0;
	margin: 0;
	z-index: 1;
	width: 28em !important;
	a,
	.dropdown-item {
		color: $color-input-field;
		display: block;
		height: auto;
		overflow: unset;

		&.disabled {
			text-align: center;
			height: 100%;
			padding-bottom: 1em;
			padding-top: 1em;
			cursor: default;
		}

		.search-no-record {
			height: 100%;
			padding: 0.625em;
			img,
			object {
				margin-top: 1.5625em;
				width: 57%;
			}
		}
		.user-info {
			padding: 0.625em 0.875em;
			.user-info-details {
				@include flex;
				@include justify-content(space-between);
				flex: 1;
				@include align-items(center);
			}
			&:hover {
				background: $color-hover;
			}
		}

		&.rbt-menu-pagination-option {
			padding-top: 1em;
			color: $color-primary1;
			padding-bottom: 1em;
		}
	}
	> .dropdown-item {
		&.active {
			background-color: $color-hover;
		}
	}
}

.rbt-loader {
	display: none !important;
}

.rbt-menu > .dropdown-item.active {
	background-color: $color-hover;
}
