@import '../../sass-utils/variables';

.profile-user-info {
	@include flex;
	@include flex-direction(row);

	.profile-image-wrapper {
		position: relative;
		// .profile-avatar {
		// 	width: 100px;
		// 	height: 100px;
		// 	font-size: 2em;
		// }

		.upload-image-file-input {
			display: none;
		}
	}
	.user-description {
		margin-left: 20px;
		@include flex;
		@include flex-direction(column);
		@include justify-content(space-around);
		.user-role-subtitle {
			//font-weight: 300;
			padding-bottom: 15px;
		}
		.chip-wrapper {
			:first-child {
				margin-right: 5px;
			}

			:last-child {
				margin-left: 5px;
			}
		}
	}
}
.title-wrapper {
	width: 100%;
	//margin-bottom: 1.75em;
}

.details-wrapper {
	width: 100%;
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	//margin-bottom: 2em;

	.edit-details-wrapper {
		@include flex;
		@include align-items(center);
		cursor: pointer;
		svg {
			margin-right: 0.25em;
		}
		span {
			color: $color-primary1;
			text-decoration: underline;
		}
	}
}

// .password-wrapper {
// 	height: 100%;
// 	.valid.MuiInputBase-root:before {
// 		border-bottom: 2px solid #04c000;
// 	}

// 	.invalid.MuiInputBase-root:before {
// 		border-bottom: 2px solid rgba(255, 0, 0, 0.5);
// 	}

// 	.MuiInput-underline:after {
// 		border-bottom: 2px solid $color-primary1;
// 	}

// 	.MuiInputLabel-root {
// 		margin-top: 2.625em;
// 		margin-bottom: 0.4375em;
// 	}

// 	.password-alert {
// 		position: absolute;
// 		left: -50%;
// 		bottom: 30px;
// 	}
// }
.deskphone {
	svg {
		path {
			fill: $color-primary1;
		}
	}
}
.phone-input-field {
	.PhoneInput {
		.PhoneInputInput {
			padding: 0.69em 0 0.69em 32px;
			font-weight: $font-weight-regular !important;
			font-size: $font-size-h5;
			color: $color-primary2;
			line-height: $line-height4;
			height: auto;
			@include border-radius(6px);
			@include transition(0.25s);
			border: 1.4px solid rgba($color-input-field, 0.5);
			&:focus {
				border: 1.4px solid $color-primary1;
				@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
			}
			&.Mui-error {
				border: 1.4px solid $color-text-info;
			}
		}
		.PhoneInputCountry {
			width: 30px;
			height: 38px;
			/* padding-left: 11px; */
			margin: 0;
			margin-left: 4px;
			position: absolute;
		}
		.PhoneInputCountryIcon {
			background: url(../../../images/custom-phone-icon.svg) no-repeat center center;
			background-size: 13px;
			margin-top: 2px;
			box-shadow: none;
			.PhoneInputCountryIconImg {
				color: $color-primary1;
				width: 19px;
			}
			svg {
				display: none;
			}
		}
		.PhoneInputCountrySelectArrow {
			position: absolute;
			right: 4px;
		}
		// MuiFormLabel-root {
		// 	opacity: 1;
		// }
	}
	&.disable {
		opacity: 0.8;
		.PhoneInputInput {
			color: rgba($color-primary2, 0.4);
		}
		.PhoneInputCountryIcon {
			opacity: 0.6;
		}
		.MuiFormLabel-root {
			opacity: 0.8;
			color: rgba($color-primary2, 0.5);
		}
	}
}

.image-edit-area {
	height: 480px;
	.upload-action {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 1.5em 1.5em 1em;
		.upload-button {
			label {
				width: 10em;
				height: 2.75em;
				line-height: 2.75em;
				text-align: center;
				@include border-radius(3em);
				border: 1px solid $color-white;
				color: $color-white;
				display: block;
				cursor: pointer;
			}
			.upload-image-file-input {
				display: none;
			}
		}
	}
	.MuiTypography-body1 {
		color: $color-white;
	}
	.reset-btn {
		svg {
			path {
				fill: $color-white;
			}
		}
	}
	.MuiSlider-root {
		color: $color-primary1;
		.MuiSlider-rail {
			background-color: $color-not-started;
		}
	}
}
.image-edit-action {
	padding: 1.5em 3.75em 2em;
	.okr-form-action {
		@include flex;
		@include justify-content(space-between);
	}
}

.image-edit-area {
	.profile-avatar {
		width: 320px;
		height: 320px;
		@include border-radius(100%);
		overflow: hidden;
		canvas {
			width: 320px !important;
			height: 320px !important;
			&.image-section {
				background: $color-white url(../../../images/default-profile-avatar.svg) center center no-repeat;
			}
			&.image-section-white-bg {
				background: $color-white;
			}
		}
	}
}

.reset-password-forminfo {
	h3 {
		font-size: 1.375em !important;
	}
	label {
		font-size: 1em;
		color: rgba($color-primary2, 0.5);
	}
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 0px solid rgba(161, 171, 185, 0.5);
	}
	.MuiInput-root {
		// margin-bottom: 2.813em;
		//padding: 0px;
		.MuiInputBase-input {
			border-radius: 0;
		}
	}
	button {
		&:hover {
			background: transparent;
		}
		span {
			a {
				color: $color-primary1;
				font-size: $font-size-h4;
				font-weight: $font-weight-regular;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.first-lbl {
		margin-top: 0;
	}
	p {
		font-style: italic;
		margin-top: 0.5em;
	}
	.password-btn-action {
		&.okr-form-action {
			padding-top: 3.5em !important;
		}
		.save-btn {
			padding: 0 2.1875em !important;
		}
	}

	.MuiInput-underline:before {
		border-bottom: 0px solid rgba(161, 171, 185, 0.5);
	}
	.MuiInputAdornment-positionStart {
		svg {
			width: 18px;
			height: 18px;
		}
	}
}
.alert-info-text {
	margin-top: 0.8em;
}
.edit-details-wrapper {
	svg {
		path {
			fill: $color-primary1;
		}
	}
}
