/**badge css**/
.MuiBadge-root {
	.MuiBadge-badge {
		&:after {
			width: 4px;
			height: 4px;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));
			content: '';
			background-color: $color-white;
			@include border-radius(4px);
		}
		&.MuiBadge-dot {
			background-color: $color-text-info;
			color: $color-white;
		}
	}
	/**badge icon **/
	&.badge-icon {
		.MuiBadge-badge {
			svg {
				width: 14px;
				height: 14px;
				margin: 0;
				path {
					fill: $color-white !important;
				}
			}
			&::after {
				display: none;
			}
		}
	}
	&.okr-badge-icon {
		position: absolute;
		right: -10px;
		top: -6px;
		padding: 0px;
		.MuiBadge-badge {
			position: static;
			transform: none;
			width: 16px;
			height: 16px;
			padding: 0;
		}
		svg {
			width: 14px;
			height: 14px;
		}
	}
}
/**alignment map card badge css**/
.alignment-card {
	.MuiBadge-anchorOriginTopRightRectangle {
		top: 3px;
		right: 7px;
	}
}
.ultra-compact-view {
	.MuiBadge-anchorOriginTopRightRectangle {
		right: -3px;
	}
}
.alignment-kr-card {
	.MuiBadge-anchorOriginTopRightRectangle {
		top: 2px;
		right: 4px;
	}
}

/**Recognize badge box css start here**/
.badge-list {
	@include flex;
	//@include align-items(center);
	padding: 0px;
}
.recognize-badge-box {
	width: 85px;
	min-height: 107px;
	padding: 7px;
	background: $color-bg-form;
	@include border-radius(6px);
	text-align: center;
	cursor: pointer;
	margin-right: 10px;
	position: relative;
	&:last-child {
		margin-right: 0px;
	}
	.recognize-badge-icon {
		margin-bottom: 5px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		svg,
		img {
			width: 50px;
			height: 50px;
			max-width: 50px;
			max-height: 50px;
			@include border-radius(100%);
		}
		.MuiBadge-badge {
			color: $color-white;
			font-size: $font-size-subtitle2;
			line-height: $line-height4;
			background: $color-primary1;
			bottom: 6px;
			right: 3px;
			min-width: 18px;
			height: 18px;
			padding: 2px 4px;
			@include border-radius(10px);
			border: 1.4px solid $color-white;
			&::after {
				display: none;
			}
		}
	}
	&.recognize-badge-view-all {
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		.btn-link {
			margin: 0px;
		}
		.recognize-badge-icon {
			width: 30px;
			height: 30px;
			@include border-radius(6px);
			background: rgba($color-completed, 0.2);
			padding: 8px;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
		}
		.recognize-badge-inner {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			@include flex-direction(column);
		}
		.MuiTypography-h6 {
			color: $color-primary1;
			padding: 0 2px;
		}
		&:hover {
			box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
		}
	}
	&.zero-count {
		cursor: default;
	}
	&.no-badge-box {
		cursor: default;
		.MuiSkeleton-root {
			background: rgba($color-input-field, 0.1);
		}
		h6 {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			margin-top: 2px;
		}
	}
}

/**recognize badge dropdown css start here**/
.recognize-badge-dropdown {
	transform: none !important;
	top: 62px !important;
	right: -4px !important;
	left: auto !important;
	z-index: 9;
	padding-top: 12px;
}
.wall-of-fame-dropdown {
	z-index: 9;
	margin-top: -30px;
}
.recognize-badge-dropdown,
.wall-of-fame-dropdown {
	.MuiPaper-root {
		width: 365px;
		min-width: 365px;
		background: $color-white;
		padding: 0;
		@include default-shadow;
		@include border-radius(6px);
		position: relative;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			right: -6px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
		ul {
			padding: 0px;
			max-height: 280px;
			overflow: auto;
			@include custom-scrollbar;
			@include border-radius(6px);
			li {
				padding: 7px 20px;
				@include justify-content(space-between);
				background-color: $color-white;
				cursor: default;
				.user-info {
					margin-right: 15px;
				}
				&:hover {
					background-color: $color-hover;
				}
				&.active {
					background-color: $color-secondary2;
				}
				.badge-count-details {
					@include flex;
					@include align-items(center);
					.user-team-info {
						margin-right: 5px;
					}
				}
				.recognize-badge-count {
					min-width: 22px;
					height: 22px;
					background: $color-bg-form;
					@include border-radius(22px);
					font-size: $font-size-h6;
					line-height: $line-height2;
					font-weight: $font-weight-bold;
					text-align: center;
				}
			}
		}
	}
}
