@import '../../sass-utils/variables';

.pf-filter-actions {
	padding-bottom: 0.5em;
	@include flex;
	@include align-items(flex-end);
	@include justify-content(space-between);
	.search-btn {
		border: none;
		border-radius: unset;
		background: transparent;
		.MuiInputBase-root {
			padding-left: 3em;
			padding-right: 1.3em;
			input {
				border-radius: 0px;
			}
		}
		.search-icon {
			width: 1.5em;
			height: 14px;
			cursor: pointer;
			top: 14px;
			left: 12px;
			svg {
				width: 14px;
			}
		}
		input {
			width: 20em !important;
			padding-left: 0px !important;
			height: auto !important;
		}
		.Mui-focused + .search-icon {
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.sorting-action {
		a {
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			color: $color-input-field;
			padding: 0 0.5em;
			border-right: 1px solid $color-input-field;
			line-height: $font-size-h6;
			&:last-child {
				padding-right: 0;
				border: none;
			}
			&.active {
				color: $color-primary2;
			}
		}
	}
}
.feedback-comments-area {
	.feedback-message {
		margin-top: 0;
		margin-bottom: 0.5em !important;
		.MuiFormControl-root {
			margin-bottom: 0 !important;
		}
	}
	.personal-feed-cols {
		padding-bottom: 1em;
	}
	.personal-feed-col1 {
		width: 41%;
		.MuiAvatar-root {
			margin-right: 0.5em;
		}
	}
	.personal-feed-col2 {
		width: 18%;
		text-align: center;
	}
	.personal-feed-col3 {
		width: 41%;
		button {
			margin: 0 1em;
			min-width: auto;
			width: 2.25em;
			height: 2.25em;
			border-radius: 50%;
			border: 1px solid $color-input-field;
			svg {
				path {
					@include transition-default;
				}
			}
			&:hover {
				background: $color-input-field;
				svg {
					path {
						fill: $color-white;
					}
				}
			}
		}
		.MuiTypography-body1 {
			margin: 0 1em;
			&.cancelled {
				color: $color-text-info;
			}
			&.received {
				color: $color-on-track;
			}
		}
	}
	.requested-expand-mssg {
		padding-left: 2.84375em;
		padding-bottom: 1em;
	}
}
.feedback-scale {
	.MuiFormLabel-root {
		padding-bottom: 0px;
	}
	.MuiSwitch-track {
		border: none;
	}
	.MuiSwitch-switchBase {
		color: $color-white;
		padding: 3px;
		.MuiSwitch-thumb {
			width: 10px;
			height: 10px;
		}
		+ .MuiSwitch-track {
			background: $color-primary1;
		}
		&.Mui-checked {
			+ .MuiSwitch-track {
				background: #f98888;
			}
		}
	}
}
.personal-feedback-form {
	.feedback-message,
	.feedback-ratings-area {
		.MuiFormControl-root {
			//padding-bottom: 0.875em;
		}
	}
	.user-quick-search-type2 {
		//margin: 0 -0.625em;
		z-index: 2;
	}
}

.praise-area {
	padding: 0.65625em 1.5em;
	@include flex;
	flex-wrap: wrap;
	.praise-rating {
		position: relative;
		flex: 0 0 50%;
		padding-bottom: 1.2em;
		.Mui-disabled {
			opacity: 1;
		}
		.MuiRating-label {
			padding-right: 10px;
		}
		.MuiTypography-caption {
			display: block;
		}
		.MuiTypography-body2 {
			color: rgba($color-primary2, 0.5);
			position: absolute;
			left: 0;
			bottom: 0.8em;
		}
		.error-field {
			bottom: -5px;
			@include flex;
		}
	}
}
.growth-opportuniy-area {
	padding: 0.75em 1.5em 1.75em 1.5em;
	.growth-ratings {
		margin-bottom: 2em;
		@include align-items(center);
		.error-field {
			bottom: -19px;
		}
		.growth-ratings-label {
			width: 45%;
		}
		.growth-ratings-slider {
			width: 55%;
			.MuiSlider-root {
				height: 6px;
				padding: 8px 0;
				margin-bottom: 0;
				.MuiSlider-rail {
					height: 6px;
					opacity: 1;
					background: $color-not-started;
					@include border-radius(6px);
				}
				.MuiSlider-track {
					height: 6px;
					background: $color-primary1;
					@include border-radius(6px);
				}
				.MuiSlider-markLabel {
					top: auto;
					bottom: 85%;
					font-size: $font-size-h6;
					font-weight: 300;
					color: rgba($color-primary2, 0.5);
				}
			}
			.MuiSlider-thumb {
				margin-top: -4px;
				width: 1em;
				height: 1em;
				border: 1px solid $color-input-field;
				background: $color-white;
				&.Mui-disabled {
					display: none;
				}
			}
			.MuiSlider-mark {
				display: none;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.okr-drawer-form-area {
	.feedback-comments-area {
		.MuiFormLabel-root {
			position: static;
			font-size: 14px;
			transform: unset;
			color: $color-primary2;
			padding-bottom: 5px;
		}
		.MuiFormControl-root {
			//margin-bottom: 1.875em;
		}
	}
}
.comments-sub-list {
	.feedback-ratings-area {
		width: 100%;
		//padding-top: 1em;
		.praise-area {
			.praise-rating {
				padding-bottom: 1.875em;
				.MuiTypography-body2 {
					bottom: 1em;
				}
			}
		}
	}
	.okr-form-fields {
		.pf-conversation {
			padding-top: 1em;
		}
	}
}
.feedback-comments-area {
	&.pf-comments-area {
		.comments-zoom-out {
			padding-right: 1.53em;
		}
		.feedback-message {
			.MuiTypography-body1:first-child {
				margin-bottom: 8px;
			}
			&.requested-mssg {
				padding-bottom: 1em;
			}
		}
		ul.comments-list {
			padding-bottom: 0.75em;
		}
		.okr-form-action-wrap {
			border-top: 1px solid rgba(161, 171, 185, 0.2);
			margin-top: 15px;
			.okr-form-action {
				width: 100%;
				@include flex;
				@include justify-content(flex-end);
				padding-top: 1.8em !important;
				.MuiButton-root {
					&:last-child {
						margin: 0px;
					}
				}
			}
		}
		.okr-form-fields {
			.okr-form-action {
				border-top: 1px solid rgba(161, 171, 185, 0.2);
				width: 100%;
				@include justify-content(flex-end);
				padding-top: 1.8em !important;
				@include flex;
			}
		}
	}
	&.newly-highlighted {
		.feedback-comments {
			background-color: $color-okr-highlighter !important;
		}
	}
	.already-provide-feed {
		width: 100%;
		height: 240px;
		text-align: center;
		.MuiTypography-h4 {
			font-size: 1.5em;
			color: rgba($color-primary2, 0.5);
		}
		.MuiButton-root {
			font-size: 100%;
			padding: 0;
			color: $color-primary1;
			text-decoration: underline;
			&:hover {
				background: transparent;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}

.rbt-input-main-personal-feedback,
.rbt-input-single-icon {
	.rbt-input-main {
		// background-image: url(../../../images/user-icon.svg) !important;
		// background-repeat: no-repeat;
		// background-size: 18px !important;
	}
}

.MuiRating-iconEmpty {
	color: $color-primary2 !important;
}
