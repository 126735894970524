@import '../../sass-utils/variables';
.tree-view-popover {
	width: 400px;
	background: $color-white;
	box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
	@include border-radius(6px);
	min-width: 470px;
	z-index: 99999;
	max-height: 360px;
	overflow-y: auto;
	max-width: 627px;
	@include custom-scrollbar;
	.disp-none {
		display: none;
	}
	.user-team-wrap {
		margin-top: 0;
		.user-team-info {
			width: 100%;
			.user-info-details {
				width: calc(100% - 50px);
				h4 {
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}
	.teamsRow {
		padding: 10px 24px;
		cursor: pointer;
		&:hover {
			background: $color-hover;
		}
		&.e-active {
			background: $color-secondary2;
		}
		.team-text-span {
			padding: 0 0.625em;
			background: #e3e5e5;
			@include border-radius(3px);
			letter-spacing: 0.1px;
			font-size: $font-size-subtitle2;
			display: inline-flex;
		}
	}

	.search-btn {
		input {
			width: 100%;
			height: 54px;
		}
		.search-icon {
			height: 54px !important;
			padding: 0 20px;
			width: auto;
		}
		.search-area .Mui-focused {
			height: 48px;
		}
	}
	.search-btn .Mui-focused input {
		width: 100%;
	}
}
/*tree structure */
.k-treeview {
	padding: 0px 0px;
	border-width: 0;
	background: none;
	box-sizing: border-box;
	outline: 0;
	display: block;
	cursor: default;
	max-height: 360px;
	min-width: 400px;
	overflow: auto;
	white-space: nowrap;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	p.team-tag-popover {
		@include flex;
		@include flex-direction(row);
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		color: $color-primary2;
		padding: 0em 0em 0.5em 2em;
		&:after {
			content: '';
			flex: 1 1;
			border-bottom: 2px solid $color-hover;
			margin: auto;
			margin-left: 0.5em;
		}
	}

	&.matListTeams {
		.MuiListItem-gutters {
			padding: 0 10px;
			.triggerIcon {
				line-height: 2.5;
				cursor: pointer;
			}
			.expand_icon {
				-webkit-transform: rotate(-90deg);
				cursor: pointer;
			}
		}
		.tree-child {
			padding-left: 3rem;
		}
	}
	ul.k-treeview-lines.k-group {
		margin: 0;
		padding: 0;
		background: none;
		list-style: none;
		position: relative;
		li.k-item.k-treeview-item {
			outline-style: none;
			margin: 0;
			padding: 0 0 0 16px;
			border-width: 0;
			display: block;
			.k-mid {
				@include flex;
				@include flex-direction(row);
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-line-pack: center;
				align-content: center;
				.k-checkbox-wrapper {
					margin-right: 4px;
				}
			}
			.k-animation-container.k-animation-container-relative {
				border-left: 1px solid $color-primary2;
				margin-left: 0.35em;
			}
		}
	}
}

.teams-section-kr.contributor-details-popover {
	width: 100%;
}
