@import '../../sass-utils/variables';
.upload-documents {
	max-width: 310px;
	.MuiFormLabel-root {
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
	}
	.upload-file {
		background-image: url('../../../images/upload-document-bg.png');
		background-repeat: no-repeat;
		background-color: $color-white;
		//background-size: cover;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		padding: 10px;
		min-width: 309px;
		min-height: 114px;
		cursor: pointer;
		.upload-file-inner {
			@include flex;
			@include justify-content(center);
			@include align-items(center);
			img {
				max-width: 119px;
			}
			.upload-right {
				padding-left: 25px;
				h5 {
					font-weight: normal;
					a {
						margin-left: 4px;
						color: $color-primary1;
					}
				}
			}
		}
	}
	.file-des {
		margin-top: 5px;
	}
	.upload-attachments-list {
		margin-top: 20px;
		ul {
			padding: 0;
			li {
				border-bottom: 1px solid $color-white;

				&:last-child {
					border-bottom: 0px;
				}
				.attachment-action {
					.btn {
						margin-right: 5px;
						&:last-child {
							margin-right: 0px;
						}
					}
				}
			}
		}
	}
}

.link-feedback-dropdown {
	margin-bottom: 30px;
	.MuiFormLabel-root {
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
	}
	.ques-type-selection.custom-type-dropdown-top {
		z-index: 1;
		.dropdown-button {
			position: relative;
			z-index: 1;
			padding-right: 20px;
			cursor: pointer;
			.placeholder-txt {
				color: rgba($color-primary2, 0.5);
			}
			&.active {
				@include border-radius(6px 6px 0 0);
				border: 1.4px solid $color-primary1;
				@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
				border-bottom: 0px;
				z-index: 1;
				.dropdown-icon {
					@include transform(rotate(180deg));
				}
			}
			.default-red-chip {
				.MuiChip-label {
					max-width: 200px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
			.btn-count {
				display: inline-block;
			}
			.dropdown-icon {
				position: absolute;
				right: 10px;
				top: 0;
				height: 100%;
				display: flex;
				align-items: center;
				@include transition-default;
				svg {
					width: 10px;
					height: 10px;
					path {
						stroke: $color-primary2;
					}
				}
			}
			&.disabled-feedback-dropdown {
				pointer-events: none;
			}
		}
		.ques-type-dropdown {
			top: auto;
			box-shadow: 0px 3px 6px -1px rgba($color-primary1, 0.5);
			left: 0px;
			width: 100%;
			padding-top: 0px;
			@include border-radius(0 0 6px 6px);
			border-top: 0;
			&::before {
				border-top: 1px solid $color-hover;
				content: '';
				width: 100%;
				height: 1px;
				position: absolute;
				top: 0;
				left: 0;
			}
			.MuiList-root {
				max-height: 215px;
				.MuiListItem-root {
					@include flex;
					@include align-items(flex-start);
					cursor: default;
					.feedback-details {
						@include flex;
						@include align-items(flex-start);
						.MuiTypography-h6 {
							@include flex;
							@include align-items(flex-start);
							margin-top: 10px;
							svg {
								width: 14px;
								height: 14px;
								margin-right: 5px;
								path {
									fill: $color-secondary1;
								}
							}
						}
					}
					.MuiListItemIcon-root {
						margin-top: 14px;
						margin-left: -11px;
						margin-right: 5px;
					}
				}
			}
		}
	}
	.linked-feedback-des {
		margin-top: 5px;
	}
}
