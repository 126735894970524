@import '../../../styles/sass-utils/variables';

.my-obj-listing {
	ul {
		padding: 0 0 0 40px;
		@include flex;
		@include justify-content(space-between);
		@include align-items(initial);
		li {
			display: block;
			//padding: 0 0.75em 0 1.25em;
			padding: 0;
			&:first-child {
				padding: 0;
			}
			&.okr-col1 {
				width: 45%;
				min-width: 45%;
				@include flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				.okr-col1-left {
					width: 90%;
					.okr-status-info {
						@include flex;
						@include align-items(center);
						position: absolute;
						bottom: calc(100% - 7px);
						z-index: 1;
						// background: $color-white;
						// @include border-radius(16px);
						.generate-okr-btn {
							left: -56px;
							top: 12px;
						}
						.draft-chip {
							margin-right: 3px;
							height: auto;
							padding: 0px;
							background: $color-okr-highlighter;
							border: 1px solid $color-border-info;
							.MuiChip-label {
								font-size: $font-size-subtitle2 !important;
								font-weight: $font-weight-bold;
								color: $color-tags;
								line-height: $line-height5;
								text-transform: uppercase;
								padding: 0 7px !important;
							}
						}
						.okr-nature-type-tag {
							.MuiTypography-subtitle2 {
								line-height: 16px;
								padding: 0 7px;
								@include border-radius(16px);
							}
						}
					}
				}
			}
			&.okr-col2 {
				width: 55%;
				min-width: 55%;
			}
			.okr-objective {
				max-width: 100%;
				//min-height: 2em;
				position: relative;
				// .okr-after-edit {
				// 	display: inline-block !important;
				// 	overflow: inherit !important;
				// }
				.okr-after-edit {
					margin-left: -10px;
					padding: 5px 10px;
					border: 1.4px solid transparent;
					@include border-radius(6px);
					@include flex;
					width: 100%;
					cursor: pointer;
					&:hover {
						border: 1.4px solid rgba($color-input-field, 0.5);
					}
					.MuiTypography-h4 {
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						overflow-wrap: break-word;
					}
				}
				.edit-okr-field {
					position: relative;
					@include animation(fade-in 1.1s);
					.MuiInput-underline:before,
					.MuiInput-underline:after {
						display: none;
					}
					.MuiInputBase-root {
						margin-left: -10px;
						padding: 5px 10px;
						border: 1.4px solid $color-primary1;
						@include border-radius(6px 6px 0);
						textarea {
							padding: 0;
							font-size: $font-size-h4;
							line-height: $line-height2;
							font-weight: $font-weight-bold !important;
						}
						&.Mui-focused {
							border: 1.4px solid $color-primary1;
							@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
						}
					}
					.textfield-action-btn {
						margin-right: 10px;
						position: absolute;
						right: 0;
					}
					.edit-okr-actions {
						position: absolute;
						right: 0;
						top: 100%;
						button {
							padding: 0.2em;
							min-width: auto;
							border-radius: 0px 0px 4px 4px;
							vertical-align: top;
							svg {
								width: 0.875em;
								height: 0.875em;
							}
							&.edit-okr-cancel {
								margin-right: 0.25em;
								svg {
									width: 1em;
									path {
										fill: $color-input-field;
									}
								}
								&:hover {
									background: transparent;
								}
							}
							&.edit-okr-save {
								background: $color-input-field;
								svg {
									path {
										fill: $color-white;
									}
								}
								&:hover {
									background: $color-input-field;
								}
							}
						}
					}
				}
			}
			&.okr-drag-wrap {
				.card-toggle {
					padding-top: 7px;
				}
			}
			.date-settings {
				.MuiInputLabel-root {
					display: none;
				}
				input {
					padding: 0;
					width: 4em;
				}
				.calender-date {
					button {
						padding: 0;
					}
				}
			}
		}
	}
	.okr-listing-wrapper {
		padding: 0;
		margin-bottom: 1em;
		position: relative;
		.generate-okr-btn {
			position: absolute;
			left: -13px;
			top: 12px;
			z-index: 9;
		}
		.okr-listing-wrapper-inner {
			padding: 0;
			position: relative;
			@include transition-default;
			background-color: $color-white;
			@include border-radius(6px);
			border: 1.4px solid $color-border-tile;
			cursor: pointer;
			&:hover,
			&.active-okr-list {
				@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
			}
			&.non-editable-case {
				.okr-objective {
					.okr-after-edit {
						border-color: transparent;
						cursor: default;
					}
				}
				.card-toggle {
					.set-timeline-area {
						cursor: default;
						.timeline-field {
							.calendar-btn {
								button {
									cursor: default;
								}
							}
						}
					}
				}
			}
			.kebab-icon {
				min-width: auto;
			}
			.okr-head {
				padding-top: 18px;
				padding-bottom: 20px;
				padding-right: 10px;
				position: relative;
				> svg,
				.drag-icon {
					position: absolute;
					top: 50%;
					left: 0.5em;
					transform: translateY(-50%);
					z-index: 9;
					display: block;
					height: 20px;
				}
				.card-toggle {
					.btn-blue-chip {
						color: $color-primary1;
						background: $color-border-tile;
						@include border-radius(100px);
					}
					&.okr-actions-active {
						.okr-field-actions {
							opacity: 1;
						}
					}
				}
				&.okr-head-expand {
					.card-toggle {
						.okr-field-actions {
							opacity: 1;
						}
					}
				}
				&:hover {
					.card-toggle {
						.okr-field-actions {
							opacity: 1;
						}
					}
					.action-message-wrap {
						.orphen-action-btn {
							opacity: 1;
						}

						.action-message {
							opacity: 0;
							@include transition(all ease-in-out 0.6s);
						}
					}
				}
				.okr-list-progress-head {
					padding: 0px 35px 0;
				}
			}
			.okr-keys-listing {
				.MuiCollapse-wrapperInner {
					padding: 0 10px 20px 40px;
					.okr-listing-wrapper-inner {
						padding: 0px;
						box-shadow: none;
						ul {
							min-height: 100px;
							li {
								&.okr-col1 {
								}
							}
							&:hover {
								@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
							}
						}
						&:hover {
							.card-toggle {
								.okr-field-actions {
									opacity: 1;
								}
							}
						}
						.okr-objective {
							max-width: 100%;
							svg {
								left: 0.4375em;
							}
						}
						.card-toggle {
							&.listing-add-kr {
								padding: 0;
							}
							&.okr-actions-active {
								.okr-field-actions {
									opacity: 1;
								}
							}
						}
					}
					.kr-panel-expanded {
						.okr-listing-wrapper-inner {
							ul {
								@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
							}
						}
					}
				}
				.obj-progress {
					@include flex;
					@include justify-content(center);
					@include flex-direction(column);
					.MuiSlider-root {
						height: 6px;
						.MuiSlider-rail,
						.MuiSlider-track {
							height: 6px;
						}
					}
				}
			}
			&.obj-title-edited {
				.okr-head {
					.okr-status-info,
					.drag-icon {
						display: none;
					}
					.card-toggle {
						.btn-blue-chip,
						.teams-chips {
							display: none;
						}
					}
				}
			}
		}

		&.kr-listing-content {
			.okr-listing-wrapper-inner {
				cursor: default;
				// padding: 2px;
				box-shadow: none;
				border: 1.4px solid transparent;
				.okr-col1-left {
					padding-left: 30px;
					padding-top: 12px;
					padding-bottom: 12px;
					.okr-objective {
						.okr-after-edit {
							.MuiTypography-h4 {
								font-weight: $font-weight-regular;
							}
						}
						.edit-okr-field {
							.MuiInputBase-root {
								textarea {
									font-weight: $font-weight-regular !important;
								}
							}
						}
					}
				}
				ul {
					background-color: $color-bg-teamstag;
					@include border-radius(6px);
					@include transition-default;
					padding: 2px;
					.okr-col2 {
						padding-right: 0px;
					}
					.alignment-btn {
						font-size: $font-size-h5;
						color: $color-primary1;
						padding: 0;
						-webkit-animation: fade-in 1.1s;
						animation: fade-in 1.1s;
						@include transition(all ease-in-out 0.6s);
						svg {
							width: 16px;
							height: 16px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				.okr-list-progressbar {
					.okr-list-progressbar-inner {
						min-height: 80px;
					}
				}
				&:hover {
					.okr-list-progressbar {
						.okr-list-progressbar-inner {
							background: $color-white;
							@include border-radius(6px 4px 4px 6px);
						}
					}
				}
			}
			&.openKrAdd {
				ul {
					@include box-shadow(0 10px 15px rgba($color-primary2, 0.3));
				}
			}
			&.show-highlighted {
				scroll-margin: 60px;
				.okr-listing-wrapper-inner,
				ul {
					background-color: $color-okr-highlighter !important;
				}
				ul {
					box-shadow: 0px 10px 15px rgba($color-primary2, 0.3) !important;
				}
			}
			&.kr-title-edited {
				.card-toggle {
					.btn-weight,
					.teams-chips {
						display: none;
					}
				}
			}
			&.new-kr-title-creation {
				.okr-listing-wrapper-inner {
					.card-toggle {
						.okr-field-actions {
							opacity: 1;
						}
					}
				}
			}
		}
		&.kr-panel-expanded {
			.okr-listing-wrapper-inner {
				.card-toggle {
					.okr-field-actions {
						opacity: 1;
					}
				}
			}
		}
		.drafted-okr-actions {
			@include flex;
			@include justify-content(flex-end);
			@include align-items(center);
			padding: 0 40px;
			p {
				margin-bottom: 20px;
				margin-right: 1em;
				font-size: $font-size-h5;
				font-style: italic;
				color: $color-input-field;
				text-transform: lowercase;
			}
			svg {
				width: 1em;
				height: 1em;
				margin-right: 0.625em;
			}
			button {
				@include transition-default;
				margin-bottom: 20px;
				&.draft-btn {
					margin-right: 2em;
				}
				svg {
					vertical-align: bottom;
				}
				&.draft-btn {
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
				&.draft-delete-btn {
					padding: 0;
					color: $color-primary2;
					@include transition-default;
					opacity: 0.5;
					svg {
						path {
							fill: $color-primary2;
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}
					&:hover {
						background-color: transparent;
						opacity: 1;
					}
				}
			}
		}
		.card-toggle {
			@include inline-flex;
			@include align-items(center);
			.btn-blue-chip {
				margin-right: 15px;
				svg {
					@include transition-rotate;
					margin-right: 4px;
					width: 10px;
					height: 10px;
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
					}
				}
				&:hover {
					@include box-shadow(6px 9px 26px $color-border-tile);
				}
			}
			.okr-field-actions {
				opacity: 0;
				@include transition(all ease-in-out 0.6s);
				button {
					@include flex;
					&.conversation-btn {
						margin-right: 0;
					}
				}
				.conversation-btn-wrap {
					margin-right: 10px;
				}
			}
			.btn-gray-chip {
				box-shadow: none;
				cursor: text;
			}
			.teams-chips {
				margin: 0;
				.MuiChip-root {
					height: 20px;
					margin: 0 15px 0 0;
					padding: 0;
					background: #e5eabe;
					color: #798902;
					@include border-radius(12px);
					border: 1.4px solid $color-white;
					.MuiChip-label {
						padding-left: 7px;
						padding-right: 7px;
						font-size: $font-size-subtitle2;
						line-height: $line-height3;
					}
				}
				.MuiChip-icon {
					margin-left: 7px;
					margin-right: -5px;
					width: 7px;
					height: 7px;
				}
			}
			.set-timeline-area {
				margin: 0 15px 0 0;
				.timeline-field {
					.calendar-btn {
						button {
							line-height: 1;
						}
					}
				}
			}
			.btn-weight {
				margin-right: 15px;
			}
		}
		&.highlighted-okr {
			scroll-margin: 20px;
			.okr-listing-wrapper-inner {
				background-color: $color-okr-highlighter !important;
				border: 2px solid $color-border-info;
			}
		}
		.okr-field-actions {
			.MuiButton-text {
				font-size: $font-size-h5;
				color: $color-primary1;
				svg {
					width: 16px;
					height: 16px;
				}
				&.kebab-icon {
					width: auto;
					height: auto;
					margin-top: 0;
					&:hover {
						background: transparent;
					}
				}
			}
			.settings-btn {
				margin-right: 15px;
			}
		}
		&.kr-level-error {
			.card-toggle {
				.okr-field-actions {
					opacity: 1;
				}
			}
		}
	}
	.no-record-message {
		padding-top: 2em;
	}
	.my-obj-listing-content {
		&.no-okr-listing {
			//opacity: 0.3;
			.okr-listing-head {
				padding-bottom: 20px;
				opacity: 0.3;
				.list-filter-btn {
					pointer-events: none;
				}
			}
			.my-obj-listing-inner {
				background: rgba($color-white, 0.3);
				padding: 22px 40px;
				cursor: default;
				box-shadow: none;
				@include border-radius(6px);
				&:hover {
					box-shadow: unset;
				}
				ul {
					padding: 0;
				}
				.MuiSkeleton-root {
					background: $color-secondary2;
				}
				.okr-objective {
					width: 100%;
					.MuiSkeleton-root {
						height: 12px;
						&.MuiSkeleton-text {
							@include border-radius(50px);
							transform: none;
							margin-top: 12px;
							&:first-child {
								margin-top: 0px;
							}
						}
					}
					.okr-objective-bottom {
						@include flex;
						@include align-items(center);
						margin-top: 22px;
						.MuiSkeleton-root {
							&.MuiSkeleton-text {
								margin: 0px 8px 0 0;
							}
						}
					}
				}
				.okr-list-progressbar {
					.okr-list-progressbar-inner {
						min-height: 0px;
						cursor: default;
					}
					.okr-list-progress-head {
						padding-top: 0;
						padding-bottom: 0px;
						padding-right: 22px;
						.progress-bar-section {
							@include flex;
							@include align-items(center);
							.progress-slider {
								width: calc(100% - 32px);
								margin-right: 10px;
								.progress-timestamp-info {
									.MuiTypography-h2 {
										font-weight: $font-weight-black;
									}
								}
							}
							.progress-time {
								width: 22px;
								height: 22px;
							}
							.MuiSkeleton-root {
								height: 22px;
								&.MuiSkeleton-text {
									@include border-radius(50px);
									transform: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**progress bar section css start here **/
.okr-list-progressbar {
	height: 100%;
	.okr-list-progressbar-inner {
		@include flex;
		height: 100%;
		width: 100%;
		cursor: pointer;
		position: relative;
		&.progressbar-active {
			background: $color-white;
			@include border-radius(6px 4px 4px 6px);
			.progress-accordion-arrow {
				background: url('../../../images/progress-connector-1.svg') no-repeat 0 0;
				z-index: 99;
				.accordion-icon {
					@include transform(rotate(-89deg));
					margin-right: 0px;
				}
			}
			.kr-progress-bottom {
				//@include justify-content(flex-end);
			}
		}
	}
	.okr-list-progress-head {
		width: 100%;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 18px 35px;
		.contributor-avatar-section {
			.MuiAvatar-root {
				width: 40px;
				height: 40px;
				font-size: $font-size-h5;
			}
			.MuiBadge-badge {
				min-width: 16px;
				min-height: 16px;
				background-color: $color-secondary1;
				border: none;
				top: 21px;
				padding: 0 3px;
				@include border-radius(20px);
				height: auto;
				.MuiTypography-root {
					color: $color-white;
					font-size: $font-size-subtitle2;
					line-height: $line-height4;
					font-weight: $font-weight-bold;
				}
				&::after {
					display: none;
				}
			}
		}
	}
	.progress-accordion-arrow {
		width: 60px;
		text-align: right;
		padding-top: 4px;
		position: absolute;
		right: 18px;
		bottom: 3px;
		.accordion-icon {
			width: 20px;
			height: 20px;
			background: $color-secondary2;
			border: 2px solid $color-white;
			@include border-radius(100%);
			@include transition-default;
			padding: 0;
			margin-right: -7px;
			svg {
				width: 8px;
				height: 8px;
				path {
					fill: $color-secondary1;
					stroke: $color-secondary1;
					stroke-width: 2px;
				}
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}
.people-view-wrapper {
	.progress-bar-section {
		.obj-progress {
			padding-top: 0px;
		}
	}
}
.progress-bar-section {
	width: calc(100% - 125px);
	position: relative;
	.progress-timestamp-info {
		margin-left: 10px !important;
		margin-right: 0px !important;
		@include justify-content(flex-start!important);
	}
	.obj-progress {
		max-width: 100% !important;
		min-height: auto !important;
		padding-top: 18px;
		.kr-progress-bottom {
			@include justify-content(flex-start);
		}
	}
	.kr-settings-value {
		padding: 0 5px;
	}
	.kr-progress-bottom {
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		max-width: calc(100% - 53px);
		padding: 0 5px;
		min-height: 16px;
		.MuiTypography-h6 {
			@include transition-default;
			margin-left: 0px !important;
			&.kr-progress-action {
				flex: 1;
			}
		}
		.kr-settings-value {
			padding: 0px;
		}
	}
}

/**popper progress bar css start here**/
.popper-okr-progress-bar {
	z-index: 9;
	//	transform: none !important;
	//padding-top: 75px;
	width: calc(100% + 2px);
	@include border-radius(0px);
	margin-top: -6px;
	margin-left: 3px;
	.MuiPaper-root {
		background-color: $color-bg-form;
		@include border-radius(0px 0px 6px 6px);
		@include box-shadow(0px 10px 15px 0px rgba($color-primary2, 0.3));
		max-height: 290px;
		overflow-y: overlay;
		@include custom-scrollbar;
		.popup-progress-box {
			@include flex;
			@include align-items(flex-end);
			> .MuiTypography-h6 {
				width: 36px;
				color: $color-threshold;
				margin-left: 15px;
			}
			.popup-progress-track {
				width: calc(100% - 51px);
				button {
					padding: 0;
					border: none;
					background: transparent;
					width: 100%;
					text-align: left;
				}
				.MuiSlider-root {
					padding: 0;
					height: 6px;
					display: block;
					margin: 6px 0;
					.MuiSlider-rail {
						height: 6px;
						opacity: 1;
						background: $color-not-started;
						@include border-radius(6px);
					}
					.MuiSlider-track {
						height: 6px;
						@include border-radius(6px);
						//background: $color-text-info; // commented for color coding slider
					}
					.Mui-disabled {
						display: none;
					}
					.MuiSlider-thumb {
						width: 32px;
						height: 23px;
						margin-left: -16px;
						margin-top: -12px;
						top: 50%;
						border: 4px solid $color-white;
						@include box-shadow(0px 0px 10px rgba($color-primary2, 0.2));
						@include border-radius(20px);
						background-color: $color-threshold;
						background-image: url(../../../images/drag-bubble.svg);
						background-repeat: no-repeat;
						background-position: center;
					}
					&.show-blue-slider {
						.MuiSlider-track {
							background-color: $color-threshold;
						}
					}
				}

				.MuiSlider-mark {
					height: 6px;
					top: 4px;
					width: 1px;
					background-color: rgba($color-input-field, 0.5);
					&.MuiSlider-markActive {
						background-color: rgba($color-input-field, 0.5);
						opacity: 1;
					}
				}
				.popup-progress-value {
					@include flex;
					@include justify-content(space-between);
					//margin-bottom: 5px;
					padding: 0 5px;
					.MuiTypography-h6 {
						@include flex;
						@include align-items(center);
					}
					svg {
						width: 10px;
						height: 10px;
						path {
							fill: $color-secondary1;
							stroke: $color-secondary1;
						}
					}
				}
			}
		}
		.progress-input-area {
			position: relative;
			@include flex;
			.progress-link-icon {
				width: 26px;
				height: 10px;
				background: url(../../../images/progress-value-connector-contri.svg) 0 0 no-repeat;
				position: absolute;
				right: 22px;
				top: 0;
			}
			.progress-input-box {
				margin-top: 8px;
				width: calc(100% - 62px);
				margin-right: 10px;
				text-align: right;
				.textfield-action-btn {
					@include justify-content(flex-end);
					.save-btn {
						&.Mui-disabled {
							opacity: 0.7;
							svg {
								path {
									fill: $color-white;
								}
							}
						}
					}
				}
				.MuiFormControl-root {
					width: 100%;
					max-width: 400px;
				}
				.MuiInputBase-root {
					background: $color-white;
					.MuiTypography-body1 {
						font-size: $font-size-h5;
					}
				}
				.MuiInputAdornment-root {
					margin-right: 4px;
					svg {
						width: 12px;
						height: 12px;
					}
				}
				&.action-btn-show {
					.MuiInputBase-root {
						@include border-radius(6px 6px 0px 6px);
					}
				}
				.progress-input-unsaved {
					.MuiInputBase-root {
						border-color: $color-text-info;
					}
				}
			}
			.popup-progress-value-bottom {
				margin-top: 0px;
				width: calc(100% - 51px);
				margin-right: 0px;
				@include flex;
				@include justify-content(flex-end);
				padding: 0 5px;
				min-height: 16px;
				.MuiTypography-h6 {
					@include flex;
					@include align-items(center);
				}
				svg {
					width: 10px;
					height: 10px;
					path {
						fill: $color-secondary1;
						stroke: $color-secondary1;
					}
				}
			}
		}
		.progress-contributor-info {
			width: 42%;
			min-width: 42%;
			@media screen and (min-width: 1600px) {
				width: 32%;
				min-width: 32%;
			}
			&.deleted-user {
				.user-info {
					.user-info-details {
						.user-name {
							color: rgba($color-primary2, 0.5);
						}
					}
					.user-img {
						.MuiAvatar-root {
							background: $color-avatar-disable;
							color: rgba($color-primary2, 0.5);
							img {
								filter: grayscale(100%);
								-webkit-filter: grayscale(100%);
								opacity: 0.5;
							}
						}
					}
				}
			}
			.user-info {
				padding-top: 8px;
				@include align-items(center);
				.user-img {
					position: relative;
					padding-left: 15px;
					.arrow-icon {
						position: absolute;
						left: 0px;
						top: 50%;
						margin-top: -5px;
						height: 10px;
						width: 10px;
						svg {
							width: 8px;
							height: 8px;
							display: block;
							path {
								fill: $color-secondary1;
							}
						}
						&.assign-arrow-icon {
							@include transform(rotate(190deg));
						}
					}
					.MuiAvatar-root {
						margin-top: 0px;
					}
					.confidence-icon {
						position: absolute;
						width: 20px;
						height: 20px;
						right: -8px;
						bottom: -4px;
						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
				.user-info-details {
					@include flex;
					@include align-items(center);
					width: calc(100% - 56px);
					.user-name {
						white-space: nowrap;
						text-overflow: ellipsis;
						max-width: 100%;
						overflow: hidden;
					}

					.kr-clap-icon {
						margin-left: 10px;
						@include flex;
						button {
							padding: 0px !important;
							background: none !important;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
							object {
								width: 26px;
								height: 26px;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
				}
			}
		}
		.progress-kebab-icon {
			width: 42px;
			padding: 0 9px;
			.more-btn {
				width: 24px;
				height: 24px;
				@include border-radius(100%);
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&:hover {
					//background-color: $color-secondary2;
				}
			}
		}
		.progress-contributor-list {
			cursor: default;
			position: relative;
			.progress-contributor-row {
				@include flex;
				&:hover {
					.progress-contributor-left {
						background: $color-bg-teamstag;
					}
				}
				.progress-contributor-left {
					@include flex;
					width: calc(100% - 67px);
					padding: 18px 0px 20px 20px;

					.progress-contributor-progress {
						width: 58%;
						min-width: 58%;
						padding: 0 0 0 15px;
						@include flex;
						@include align-items(center);
						@media screen and (min-width: 1600px) {
							width: 68%;
							min-width: 68%;
						}
						&.deleted-user {
							.progress-kebab-icon {
								.more-btn {
									cursor: default;
									pointer-events: none;
									svg {
										path {
											fill: rgba($color-primary2, 0.5);
										}
									}
								}
							}
						}
						.progress-contributor-progress-inner {
							width: calc(100% - 42px);
						}
					}
				}
				.progress-contributor-right {
					min-width: 67px;
					max-width: 100px;
					padding: 18px 5px 20px;
					background-color: rgba($color-secondary2, 1);
					text-align: center;
					.MuiTypography-h5 {
						color: $color-secondary1;
						padding-top: 15px;
					}
				}
				&.progress-parent-row {
					.progress-input-area {
						position: relative;
						.progress-link-icon {
							width: 62px;
							height: 26px;
							background: url(../../../images/progress-value-connector-parent.svg) 0 0 no-repeat;
							position: static;
						}
					}
					.kr-clap-icon {
						margin-right: 8px !important;
						margin-left: 0 !important;
					}
					.progress-contributor-progress {
						@include align-items(flex-start);
						.progress-kebab-icon {
							.more-btn {
								margin-top: 10px;
							}
						}
					}
					&.parent-row-space {
						.progress-contributor-left {
							padding-top: 35px;
						}
						.progress-contributor-right {
							padding-top: 35px;
						}
					}
				}
				&.contributor-list-info {
					.progress-contributor-left {
						@include align-items(center);
						padding-top: 11px;
						padding-bottom: 11px;
						.user-info {
							padding-top: 0px;
						}
					}
					.progress-contributor-right {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						padding-top: 11px;
						padding-bottom: 11px;
						h5 {
							padding-top: 0px;
						}
					}
					.popup-progress-box {
						.popup-progress-track {
							.MuiSlider-root {
								height: 3px;
								cursor: default;
								.MuiSlider-thumb {
									display: none;
								}
								.MuiSlider-rail {
									height: 3px;
								}
								.MuiSlider-track {
									height: 3px;
								}
							}
						}
					}
					&.parent-row-space:nth-child(2) {
						.progress-contributor-left {
							padding-top: 35px;
						}
						.progress-contributor-right {
							padding-top: 35px;
						}
					}
				}
				&.progress-not-set {
					.progress-contributor-left {
						@include align-items(center);
						padding: 18px 0px 20px 20px;
						.user-info {
							padding-top: 0px;
						}
					}
					.progress-contributor-right {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						padding: 18px 5px 20px;
						h5 {
							padding-top: 0px;
							color: rgba($color-primary2, 0.5);
						}
					}
					.popup-progress-value-bottom {
						@include justify-content(flex-start);
					}
					.popup-progress-box {
						> h6 {
							color: rgba($color-primary2, 0.5);
						}
						.popup-progress-track {
							//padding-top: 16px;
							.MuiSlider-root {
								height: 7px;
								cursor: default;
								.MuiSlider-thumb {
									display: none;
								}
								.MuiSlider-rail {
									height: 7px;
								}
								.MuiSlider-track {
									height: 7px;
								}
							}
							.popup-progress-value {
								min-height: 16px;
							}
						}
					}
				}
			}
		}
	}

	&.progress-popper-unsaved {
		animation: mymove 0.75s 4;
	}
	&.popper-progress-new-kr {
		.MuiPaper-root {
			.popup-progress-box {
				> .MuiTypography-h6 {
					width: auto;
				}
				.popup-progress-track {
					width: calc(100% - 24px);
				}
			}
			.progress-contributor-list {
				.progress-contributor-row {
					&.contributor-list-info {
						.progress-contributor-left {
							padding-top: 4px;
							padding-bottom: 4px;
							.progress-contributor-progress {
								.progress-contributor-progress-inner {
									width: calc(100% - 32px);
								}
							}
						}
						.progress-contributor-right {
							padding-top: 4px;
							padding-bottom: 4px;
						}
						&.contributor-list-info-first {
							.progress-contributor-left {
								padding-top: 14px;
								padding-bottom: 14px;
							}
							.progress-contributor-right {
								padding-top: 14px;
								padding-bottom: 14px;
							}
						}
					}
					&.progress-not-set {
						.progress-contributor-left {
							padding-top: 18px;
							padding-bottom: 20px;
						}
						.progress-contributor-right {
							padding-top: 18px;
							padding-bottom: 20px;
						}
					}
				}
			}
			.progress-kebab-icon {
				width: auto;
				padding: 0px;
			}
		}
	}
}

/**progress-popper-menu css start here**/
.progress-popper-menu {
	margin-right: 0 !important;
	.MuiPaper-elevation1 {
		ul {
			li {
				padding: 12px 20px !important;
				cursor: pointer;
				svg {
					width: 14px;
					height: 14px;
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
					line-height: $line-height4;
				}
			}
		}
	}
}
/**parent chip css start here**/
.MuiTypography-body1.parent-chip {
	padding: 0 7px;
	background: $color-secondary2;
	color: $color-secondary1;
	font-size: $font-size-subtitle2;
	font-weight: $font-weight-bold;
	line-height: $line-height5;
	text-transform: uppercase;
	width: 54px;
	text-align: center;
	@include border-radius(23px);
	margin-left: 8px;
	margin-right: 8px;
	& + .delete-chip {
		margin-left: 2px;
	}
}
/**assign align user info css start here**/
.assign-align-user-info,
body.admin-body-class .assign-align-user-info {
	position: absolute;
	left: 35px;
	top: 0px;
	@include flex;
	@include align-items(center);
	.MuiChip-root.chip-avatar {
		padding: 0px 7px 0 0 !important;
		border-radius: 100px;
		max-width: 180px;
		background: $color-bg-info !important;
		height: 20px !important;
		.MuiChip-avatar,
		.MuiAvatar-root {
			margin: 0 7px 0 0;
			border: 1.4px solid $color-white;
			color: $color-tags !important;
			background: $color-bg-info !important;
			font-weight: 700;
			font-size: $font-size-subtitle2;
			line-height: $line-height5;
			width: 20px;
			height: 20px;
			.MuiAvatar-img {
				@include border-radius(100%);
			}
		}
		.MuiChip-label {
			font-size: $font-size-h6;
			line-height: $line-height5;
			color: $color-tags !important;
			margin: 0;
			padding: 0px;
			text-transform: initial;
		}
	}
}

/**group progress block css start here**/
.group-progress-block {
	cursor: default;
	.group-progress-top {
		padding: 18px 40px 20px 20px;
		@include flex;
		background: $color-secondary2;
		&.no-editable {
			.popup-progress-track {
				.MuiSlider-root {
					cursor: default;
					.MuiSlider-thumb {
						display: none;
					}
				}
			}
			.progress-input-area {
				.progress-link-icon {
					width: 26px;
					height: 10px;
					background: url(../../../images/progress-value-connector-contri.svg) 0 0 no-repeat;
					position: absolute;
					right: 22px;
					top: 0;
				}
			}
		}
		.group-progress-left {
			width: 33%;
			padding-right: 15px;
			padding-left: 15px;
			.MuiTypography-body1 {
				padding-top: 8px;
			}
		}
		.group-progress-right {
			width: 66%;
		}

		.progress-input-area {
			.progress-input-box {
				.MuiFormControl-root {
					max-width: 255px;
				}
			}
			.progress-link-icon {
				width: 62px;
				height: 26px;
				background: url(../../../images/progress-value-connector-parent.svg) 0 0 no-repeat;
				position: static;
			}
		}

		&:hover {
			background: $color-bg-teamstag;
		}
	}
	.group-progress-bottom {
		//margin-top: 10px;
		.group-progress-contributor-list {
			display: -ms-grid;
			display: grid;
			-ms-grid-columns: repeat(auto-fill, minmax(274px, 1fr));
			grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));
			grid-gap: 0px;
			.group-progress-contributor-row {
				padding: 10px 20px;
				.progress-contributor-info {
					width: 100%;
					min-width: 100%;
					.user-info {
						padding-top: 0;
					}
					.progress-kebab-icon {
						width: auto;
						padding-right: 0px;
					}
					&.deleted-user {
						.progress-kebab-icon {
							.more-btn {
								cursor: default;
								pointer-events: none;
								svg {
									path {
										fill: rgba($color-primary2, 0.5);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.popper-menu-list {
	min-width: 214px;
	.MuiPaper-elevation1 {
		ul {
			li {
				padding: 10px 20px !important;
				min-width: 120px !important;
			}
		}
	}
	ul li svg {
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}
	&.okr-list-popper-menu {
		margin-right: 0;
		margin-top: 4px;
		.MuiListItemIcon-root {
			min-width: 30px;
		}
	}
	&.org-menu-list {
		li {
			padding-left: 32px;
		}
	}
}
.contributor-details-popover {
	&.obj-contributors-list {
		margin-top: -54px;
		margin-left: -12px;
		min-width: 332px;
		max-width: 332px;
		@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
		max-height: 272px;
		overflow-y: auto;
		@include custom-scrollbar;
		.contributor-details-list {
			padding-top: 10px;
			li {
				padding: 5px 20px 5px 15px;
				h4 {
					font-weight: $font-weight-regular;
				}
				.contributordetails-left {
					padding: 0;
					.MuiListItemAvatar-root {
						min-width: 36px;
						margin-right: 7px;
						&.owner-avatar {
							width: 36px;
							height: 36px;
						}
					}
					.contributor-name {
						.MuiListItemText-secondary {
							margin-left: 10px;
							padding: 0 7px;
							color: $color-secondary1;
							background: $color-secondary2;
							@include border-radius(23px);
							svg {
								display: none;
							}
							.MuiTypography-root {
								text-transform: uppercase;
							}
						}
					}
				}
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
}

/**add text field css **/
.add-kr-textfield {
	width: 90%;
	padding-left: 30px;
	padding-top: 12px;
	padding-bottom: 12px;
	@include border-radius(6px);
	@include transition-default;
	@include animation(slide-down 1s);

	&:hover {
		//background-color: $color-bg-tag;
	}
	.kr-field {
		.add-kr {
			width: 100%;
			min-height: 2em;
			.MuiInputBase-root {
				margin-left: -10px;
				padding: 5px 10px;
				border: 1.4px solid transparent;
				@include border-radius(6px);
				textarea {
					padding: 0;
					font-size: $font-size-h4;
					line-height: $line-height2;
					font-weight: $font-weight-regular;
				}
				&:hover {
					border: 1.4px solid rgba($color-input-field, 0.5);
				}
				&.Mui-focused {
					border: 1.4px solid $color-primary1;
					@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
				}
			}
		}
		.delete-btn {
			min-width: auto;
			padding: 0;
			.MuiTouchRipple-root {
				display: none;
			}
			&:hover {
				background-color: transparent;
			}
		}
	}
}
/** draft okr progress css **/
.new-kr {
	&.add-shadow {
		.okr-listing-wrapper-inner {
			ul {
				@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
			}
		}
	}
}
.my-obj-listing {
	.draft-okr,
	.new-kr {
		.okr-head {
			.kr-progress {
				.MuiSlider-root {
					height: 22px;
					border-radius: 22px;
					.MuiSlider-rail {
						height: 22px;
					}
				}
				.kr-progress-slider {
					@include align-items(center);
				}
			}
		}
		.kr-progress {
			.kr-progress-slider {
				.progress-timestamp-info {
					.MuiTypography-h2 {
						color: rgba($color-primary2, 0.5) !important;
					}
				}
			}
		}
	}
}
.delete-chip {
	&.MuiChip-root {
		height: auto;
		padding: 0px;
		background: $color-okr-highlighter;
		border: 1px solid $color-border-info;
		margin-left: 10px;
		.MuiChip-label {
			font-size: $font-size-subtitle2 !important;
			font-weight: $font-weight-bold;
			color: $color-tags;
			line-height: $line-height5;
			text-transform: uppercase;
			padding: 0 7px;
		}
	}
}
