@import '../../../../sass-utils/variables';

.create-cycle-form {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			@include justify-content(flex-start);
			.back-to-main {
				margin-right: 15px;
				padding-bottom: 0;
			}
			.MuiTypography-h2 {
				text-transform: capitalize;
			}
		}
	}
	.create-cycle-form-card {
		.add-cycle-form-wrapper {
			@include flex;
			@include justify-content(space-between);
			.MuiFormLabel-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
			.add-cycle-form-fields {
				width: calc(60% - 15px);
				.add-cycle-name-field {
					margin-bottom: 30px;
					width: 50%;
				}
				.calendar-fields {
					@include flex;
					@include justify-content(space-between);
					.calendar-start-end-fields {
						@include flex;
						@include justify-content(space-between);
					}
					.add-timeframe-field {
						width: calc(50% - 15px);
						input {
							&.form-control {
								width: calc(50% - 10px);
								padding: 4px 10px;
								font-size: $font-size-h5;
								line-height: $line-height2;
								border: 1.4px solid rgba($color-input-field, 0.5);
								@include border-radius(6px);
								font-family: $font-family-lato;
							}
						}
						.error-field {
							width: 220px;
						}
					}
				}
			}
			.default-assign {
				width: calc(40% - 15px);
				padding: 24px 36px;
				background: $color-bg-form;
				@include border-radius(6px);
			}
		}
		.goal-cycle-notes {
			margin-top: 20px;
		}
	}
	.create-goal-cycle-actions {
		margin-bottom: 20px;
		visibility: visible;
		opacity: 1;
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		.btn-primary {
			padding: 6px 23px;
			margin-left: 0;
		}
		.btn-link {
			margin-right: 30px;
		}
	}
}

/* Goal cycles listing CSS*/
.goal-cycles-listing {
	.okr-list-panel-head,
	.goal-cycles-list-content {
		ul {
			li {
				&.goal-cycles-col1 {
					width: 23%;
				}
				&.goal-cycles-col2 {
					width: 25%;
				}
				&.goal-cycles-col3 {
					width: 25%;
				}
				&.goal-cycles-col4 {
					width: 15%;
				}
				&.goal-cycles-col5 {
					width: 12%;
				}
			}
		}
	}
	.goal-cycles-list-content {
		ul {
			li {
				&.goal-cycles-col4 {
					.MuiAvatar-root {
						&.avatar-default-grey {
							cursor: default;
						}
					}
				}
			}
		}
		.goal-cycles-chip {
			&.MuiChip-root {
				padding: 0 7px;
				color: $color-primary2;
				line-height: 1;
				border: 1px solid $color-bg-tag;
				&.draft-chip {
					color: $color-tags;
					border: 1px solid $color-tags;
					background-color: $color-okr-highlighter;
				}
			}
		}
		&.heighlight-row {
			background-color: $color-okr-highlighter !important;
		}
	}
	.goal-cycles-list-content {
		min-height: 65px;
		position: relative;
		padding: 15px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			padding: 0;
			li {
				padding: 0 12px 0 20px;
				flex: auto;
				&.goal-cycles-col1 {
					@include flex;
					@include justify-content(space-between);
					.goal-cycles-list-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				&.goal-cycles-col2,
				&.goal-cycles-col3 {
					.MuiTypography-body2 {
						color: $color-secondary1;
						font-weight: $font-weight-regular;
						line-height: $line-height2;
					}
					svg {
						path {
							stroke: $color-primary2;
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			li {
				&.goal-cycles-col1 {
					.goal-cycles-list-actions {
						button {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
/*Assign users CSS*/
.assign-users-card {
	.assign-users-header {
		padding-bottom: 20px;
		&.helperText-bottom {
			.MuiTypography-h4 {
				color: $color-text-info;
			}
			.error-field {
				z-index: 1;
				left: -20px;
				top: 30px;
			}
		}
	}
	.assign-users-tab-section {
		margin-bottom: 15px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.okr-tab-button {
			min-height: 34px !important;
			.MuiTab-root {
				text-transform: capitalize !important;
			}
		}
		.switch-button {
			@include flex;
			svg {
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
			.MuiButton-root {
				margin-left: 7px;
				padding: 0;
				&:hover {
					background: transparent;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
	.assign-users-tab-content {
		.select-role-fields {
			width: 100%;
			.MuiFormControl-root {
				width: 100%;
			}
			.textfield-action-btn {
				@include justify-content(flex-end);
			}
			&.selected {
				.select-search-dropdown {
					.react-select__control {
						@include border-radius(6px 6px 0 6px);
					}
				}
			}
			.react-select__indicators {
				display: none;
			}
		}
		.select-search-dropdown.select-search-icon {
			.react-select__control {
				max-height: 65px;

				.react-select__indicators {
					max-height: 40px;
				}
				.secondary-text {
					display: none;
				}
			}
			.react-select__menu {
				.selected-chip {
					@include flex;
					.primary-text {
						width: 60%;
						padding-right: 20px;
						font-size: $font-size-base;
						line-height: $line-height2;
						color: $color-primary2;
						font-weight: $font-weight-regular;
					}
					.secondary-text {
						width: 40%;
						font-size: $font-size-base;
						line-height: $line-height2;
						color: $color-primary2;
						font-weight: $font-weight-regular;
					}
				}
			}
			&.react-select--is-disabled {
				.react-select__control {
					.react-select__value-container {
						.react-select__placeholder {
							background: url(/static/media/search-sprite.8e257541.svg) no-repeat 0 0;
							background-position: 0px 2px;
							padding-left: 26px;
							margin-left: 0px;
						}
					}
				}
			}
		}
		.upload-error-result {
			padding-bottom: 20px;
			margin-bottom: 20px;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			border-bottom: 1.4px solid $color-border-tile;
			.upload-error-mssg {
				.upload-error-mssg-title {
					@include inline-flex;
				}
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
				.MuiTypography-h6 {
					@include flex;
					@include align-items(center);
				}
			}
		}
		.select-doj-fields {
			.MuiFormGroup-root {
				margin-bottom: 5px;
			}
			.select-doj-range {
				width: 210px;
				input {
					&.form-control {
						width: 100%;
						padding: 4px 10px;
						font-size: $font-size-h5;
						line-height: $line-height2;
						border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						font-family: $font-family-lato;
					}
				}
				.textfield-action-btn {
					@include justify-content(flex-end);
				}
				&.date-range-selected {
					input {
						&.form-control {
							@include border-radius(6px 6px 0 6px);
						}
					}
				}
			}
		}
		.assign-users-bulk-field {
			.bulk-user-content {
				padding: 0;
				.uploading-bar {
					.bar {
						margin: 0;
						background: transparent;
						.success {
							background-color: rgba($color-secondary2, 0.5);
						}
						.inner-bar {
							border-color: transparent;
							.MuiTypography-root {
								color: $color-primary1;
							}
							svg {
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					.textfield-action-btn {
						@include justify-content(flex-end);
					}
				}
			}
		}
	}
}
/*Assigned users list*/
.assigned-users-list-area-outer {
	margin-top: 20px;
	padding: 20px;
	background: $color-bg-form;
	@include border-radius(6px);
}
.assigned-users-list-area {
	.title-head {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		margin-bottom: 20px;
		.assigned-user-title {
			@include flex;
		}
	}
	.assigned-users-listing {
		.okr-list-panel-head,
		.assigned-users-list-content {
			ul {
				li {
					height: 30px;
					margin: 5px 0;
					&.assigned-user-col1 {
						width: 27%;
						padding-left: 5px;
					}

					&.assigned-user-col2 {
						width: 32%;
					}
					&.assigned-user-col3 {
						width: 27%;
					}
					&.assigned-user-col4 {
						width: 14%;
					}
				}
			}
		}
		.assigned-users-list-content {
			ul {
				@include flex;
				@include align-items(center);
				li {
					&.assigned-user-col4 {
						.MuiTypography-body1 {
							min-width: 82px;
						}
					}
				}
			}
		}
	}
	.user-search {
		.MuiInputBase-root {
			background: $color-white;
		}
	}
}
.report-header-tooltip {
	&.all-users-info-tooltip {
		&:before {
			left: auto;
			right: 37px;
		}
		.textarea-expand {
			min-width: 365px;
			width: 365px;
			height: 60px;
			resize: none;
		}
	}
}
.assign-user-error-tooltip {
	.MuiTooltip-tooltip {
		margin: 0 0 0 10px !important;
		max-width: 250px !important;
		.tooltip-text {
			@include flex;
			svg {
				margin-right: 5px;
				min-width: 16px;
				width: 16px;
				height: 16px;
			}
		}
	}
}
