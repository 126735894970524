.okr-select-dropdown {
	z-index: 9;
	width: 350px;
	.okr-select__control {
		padding: 0.5em 3.75em 0.5em 1.3125em;
		min-height: 32px;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		cursor: pointer;
		&:hover {
			border: 1.4px solid rgba($color-input-field, 0.5);
		}
		&.okr-select__control--is-focused {
			border: 1.4px solid $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		&.okr-select__control--menu-is-open {
			z-index: 9;
			@include border-radius(6px 6px 0 0);
			border-bottom: 0px;
			box-shadow: none;
			.okr-select__dropdown-indicator {
				@include transform(rotate(180deg));
			}
		}
		.okr-select__placeholder {
			color: rgba($color-primary2, 0.5);
			font-weight: $font-weight-regular;
		}
		.okr-select__indicators {
			.okr-select__indicator-separator,
			.okr-select__clear-indicator {
				display: none;
			}
			.okr-select__dropdown-indicator {
				background: url(../../images/down-arrow.svg) no-repeat center center;
				width: 14px;
				height: 14px;
				margin-right: 0.375em;
				@include transition(0.3s);
				position: absolute;
				right: 10px;
				svg {
					display: none;
				}
			}
		}
		.okr-select__value-container {
			padding: 0px;
			.okr-select__single-value {
				margin: 0px;
			}
		}
		.MuiTypography-body2 {
			color: $color-secondary1;
			font-weight: $font-weight-bold;
			white-space: normal;
		}
	}

	.okr-select__menu {
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));

		@include border-radius(6px);
		border: 1.4px solid $color-primary1;
		border-top: 0px;
		background: $color-white;
		width: 100%;
		margin: 0 0 0 0;
		padding-top: 0;
		overflow: hidden;
		//z-index: 99;
		top: 0px;
		padding-top: 50px;
		> div {
			@include custom-scrollbar;
		}
		.okr-select__menu-list {
			padding: 0;
			margin: 0;
			max-height: 215px;
			.okr-select__option {
				border-bottom: 1.4px solid $color-hover;
				padding: 0.625em 4.0625em 0.625em 1.25em;
				@include flex;
				@include align-items(center);
				min-height: 40px;
				cursor: pointer;
				&:last-child {
					border-bottom: 0px;
				}
				.MuiTypography-body2 {
					color: $color-secondary1;
					font-weight: $font-weight-bold;
					white-space: normal;
				}
				&:hover {
					background: $color-hover !important;
				}
				&.okr-select__option--is-selected {
					background: $color-secondary2 !important;
					color: $color-primary1;
					&:hover {
						background: $color-secondary2 !important;
					}
					.MuiTypography-h4 {
						color: $color-primary1;
					}
				}
				&.okr-select__option--is-focused {
					background: none;
				}
			}
		}
	}
}
