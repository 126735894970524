@import '../../../styles/sass-utils/variables';

.feedback-reviewers-notes {
	margin-top: 10px;
}
.okr-team-dropdown {
	&.feedback-type-dropdown {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		.dropdown-button {
			width: 100%;
			background: $color-white;
			&:hover {
				background: $color-white;
			}
		}
		&.active {
			.dropdown-button {
				background: transparent;
			}
		}
	}
}
.main-drawer-panel {
	&.main-drawer-titlepanel {
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-content-panel {
						&.create-feedback-drawer {
							.drawer-input-field {
								.feedback-search-field {
									width: 365px;
									.react-select__dropdown-indicator {
										display: none;
									}
								}
								#date-range-inputfield {
									width: 100%;
									padding: 4px 10px;
									font-size: $font-size-h5;
									line-height: $line-height2;
									border: 1.4px solid rgba($color-input-field, 0.5);
									@include border-radius(6px);
									font-family: $font-family-lato;
								}
								.upload-documents {
									max-width: 680px;
									@include flex;
									@include justify-content(space-between);
									.upload-documents-field {
										width: 310px;
									}
									.upload-attachments-list {
										width: 340px;
										margin-top: 27px;
										ul {
											li {
												margin-bottom: 1px;
												padding: 10px;
												background: $color-bg-form;
											}
										}
									}
								}
								.feedback-start-end-date {
									padding-bottom: 0;
									@include flex;
									@include justify-content(space-between);
									.helperText-bottom {
										.error-field {
											z-index: 1;
											bottom: auto;
										}
									}
								}
							}
							.drawer-form-fields-group {
								padding-top: 30px;
								padding-bottom: 15px;
							}
							.extend-edit-date {
								margin-top: 28px;
								.MuiFormControlLabel-root {
									margin-left: 0;
									margin-right: 5px;
								}
								.info-icon-btn {
									padding: 0;
									svg {
										width: 14px;
										height: 14px;
										path {
											fill: $color-primary1;
										}
									}
									.MuiTouchRipple-root {
										display: none;
									}
								}
							}
							#feedback_nominee_container {
								.helperText-bottom {
									.MuiFormHelperText-root {
										top: 34px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.extend-feedback-info-tooltip {
	margin: 0;
	&:before {
		left: 50%;
		right: auto;
		top: -6px;
		margin-left: -6px;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid $color-white;
	}
	&[x-placement~='top'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -6px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		padding-right: 0;
		min-width: 140px;
		width: 140px;
		height: 40px;
		resize: none;
	}
}
