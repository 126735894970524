@import '../../sass-utils/variables';
.eng-1on1-container {
	.no-records-area {
		min-height: calc(100vh - 69px);
		@include align-items(center);
		margin-top: -40px;
	}
	.no-record-message {
		.no-record-head {
			text-align: center;
			margin-bottom: 30px;
			.MuiTypography-h2 {
				text-transform: capitalize;
			}
		}
		.create-align-link-area {
			@include flex-direction(column!important);
			margin-top: 0px !important;
		}
	}
	.user-info {
		.user-img {
			position: relative;
			.request-arrow {
				width: 14px;
				height: 14px;
				@include border-radius(100%);
				background: $color-border-tile;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				position: absolute;
				bottom: 0px;
				left: -6px;
				svg {
					width: 10px;
					height: 10px;
					path {
						stroke: $color-primary2;
						stroke-width: 1px;
					}
				}
				&.arrow-left {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}
.request-sub-head {
	padding: 20px 0;
	@include flex;
	//@include align-items(flex-start);
	@include justify-content(space-between);
	.meeting-sub-head {
		@include flex;
		@include align-items(center);
		.back-to-main {
			padding-bottom: 0px;
			margin-right: 10px;
			// button {
			// 	@include flex;
			// 	@include align-items(center);
			// 	padding: 0;
			// 	font-size: $font-size-h6;
			// 	color: $color-primary1;
			// 	font-weight: $font-weight-bold;
			// 	svg {
			// 		width: 16px;
			// 		height: 16px;
			// 		margin-right: 3px;
			// 		path {
			// 			fill: $color-primary1;
			// 		}
			// 	}
			// 	&:hover {
			// 		background-color: transparent;
			// 	}
			// 	.MuiTouchRipple-root {
			// 		display: none;
			// 	}
			// }
		}
	}
	.request-sub-head-right {
		@include flex;
		.btn-circle-white,
		.download-btn {
			margin-right: 10px;
			margin-top: 5px;
			cursor: pointer;
		}
		.download-btn {
			@include flex;
			@include justify-content(center);
			@include align-items(center);
			padding: 0;
			width: 40px;
			height: 40px;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(100%);
			svg {
				width: 16px;
				height: 16px;
				margin: 0px;
				path {
					fill: $color-primary2;
				}
			}
			&:hover,
			&.Mui-selected {
				background-color: $color-expanded-tiles;
				border-color: $color-primary1;
				svg {
					path {
						fill: $color-primary1;
					}
					circle {
						stroke: $color-primary1;
					}
				}
			}
		}
	}
}
.request-filter-box {
	@include flex;
	.box-rectangle-info {
		min-width: 356px;
		margin-right: 10px;
	}
}

/**request meeting listing css start here**/
.request-meeting {
	@include flex;
	//@include align-items(flex-start);
	margin-top: 20px;
	.col-left {
		width: calc(70% - 20px);
		margin-right: 20px;
	}
	.col-right {
		width: 30%;
	}
	.meeting-head {
		@include flex;
		@include justify-content(space-between);
		margin-bottom: 30px;
		.meeting-head-right {
			@include flex;
		}
	}
}

/**meeting list css start here**/
.meeting-listing {
	.okr-list-panel-head {
		ul {
			li {
				padding: 0 10px 0 20px;
				.sortable-label {
					.txt-wrap {
						span {
							text-transform: initial;
						}
					}
				}
			}
		}
	}
	.okr-list-panel-head,
	.meeting-listing-content {
		ul {
			li {
				&.meeting-col1 {
					width: 24%;
				}
				&.meeting-col2 {
					width: 24%;
				}
				&.meeting-col2-link {
					@include justify-content(space-between);
				}
				&.meeting-col3 {
					width: 21%;
				}
				&.meeting-col4 {
					width: 31%;
				}
			}
		}
	}
}
.meeting-listing-content {
	@include flex;
	@include align-items(center);
	min-height: 92px;
	position: relative;
	padding: 14px 0;
	margin-bottom: -1px;
	transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	ul {
		@include flex;
		@include align-items(center);
		width: 100%;
		padding: 0;
		li {
			padding: 0 10px 0 20px;
			flex: auto;
			.user-info {
				width: 100%;
				.user-info-details {
					width: calc(100% - 40px);
					div {
						@include flex;
					}
					.MuiTypography-h4,
					.MuiTypography-subtitle2 {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 100%;
						display: inline-block;
					}
				}
			}
			.title-name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}
			button.user-menu-btn {
				padding: 0;
				min-width: auto;
				width: 24px;
				height: 24px;
				@include border-radius(100%);
				margin-left: 5px;
				@include transition(0.25s);
				opacity: 0;
				@include transition(0.3s);
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&.active {
					opacity: 1;
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
				&.disabled-button {
					opacity: 0 !important;
					pointer-events: none;
				}
			}
			.btn-link {
				height: auto;
				line-height: 20px;
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				//min-width: 58px;
				@include flex;
				@include align-items(center);
				@include justify-content(flex-start);
				margin-right: 10px;
				&.btn-edit-link {
					width: 24px;
					height: 24px;
					@include align-items(center);
					@include justify-content(center);
					@include border-radius(100%);
					margin-right: 10px;
					&:hover {
						background-color: rgba(0, 0, 0, 0.04);
					}
					svg {
						margin: 0px;
					}
				}
				&.download-btn {
					margin: 0px 5px 0 0px !important;
					width: 24px;
					height: 24px;
					@include align-items(center);
					@include justify-content(center);
					@include border-radius(100%);
					.MuiTouchRipple-root {
						display: none;
					}
					&:hover {
						background-color: rgba(0, 0, 0, 0.04);
					}
					svg {
						width: 14px;
						height: 14px;
						margin: 0px;
						path {
							fill: $color-primary1;
						}
					}
				}
			}
			&.meeting-col2 {
				padding-right: 0;
				.meeting-title-details {
					width: calc(100% - 15px);
				}
				.meeting-btn {
					padding: 0;
					width: 14px;
					height: 14px;
					margin: 0 !important;
				}
			}
			&.meeting-col3 {
				@include flex-direction(column);
				@include align-items(flex-start);
				.meeting-schedule-date {
					@include flex;
					@include align-items(center);
					.schedule-time {
						color: $color-secondary1;
						margin-left: 3px;
					}
					&.meeting-schedule-single-date {
						@include flex-direction(column);
						.schedule-time {
							margin-left: 0;
						}
					}
				}
			}
			&.meeting-col4 {
				@include justify-content(space-between);
				.user-actions {
					@include flex;
					@include justify-content(space-between);
				}
			}
		}
	}
	&:hover {
		@include default-shadow;
		z-index: 1;
		ul {
			li {
				button.user-menu-btn {
					opacity: 1;
				}
			}
		}
	}
}

/**launch template css start here**/
.launch-template {
	.launch-template-row {
		margin-bottom: 40px;
		.btn-toggle {
			@include flex;
			@include transition(0.15s);
			svg {
				@include transition(0.15s);
			}
		}
		&.first-row {
			margin-bottom: 20px;
		}
		&:last-child {
			margin-bottom: 0px;
		}
		.MuiFormLabel-root {
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
				vertical-align: middle;
			}
		}
		.disabled-title {
			opacity: 0.7 !important;
			.MuiInputBase-root {
				border: 1.4px solid rgba($color-input-field, 0.5) !important;
				box-shadow: none !important;
			}
		}
		.MuiInputBase-root {
			background: $color-white;
		}
		.user-head {
			@include flex;
			@include align-items(flex-start);
			// .user-col {
			// 	width: 50%;
			// 	padding: 0px 20px 10px;
			// 	&.other-col {
			// 		.no-cal-icon {
			// 			button {
			// 				.MuiButton-label {
			// 					color: rgba($color-primary1, 0.5) !important;
			// 					.icon {
			// 						opacity: 0;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			.owner-chip {
				background: $color-bg-tag;
				@include border-radius(100px);
				color: $color-primary2 !important;
				text-transform: uppercase;
				margin: 0 0px 0 5px;
			}
		}
		.user-content {
			.user-content-row {
				background: $color-bg-form;
				padding: 20px;
				margin-bottom: 20px;
				@include border-radius(6px);
				border: 1.4px solid $color-border-tile;
				h4 {
					margin-bottom: 15px;
				}
				&:last-child {
					margin-bottom: 0px;
				}
				// &.task-row-scroll {
				// 	padding-left: 0px;
				// 	padding-right: 0px;
				// 	> h4,
				// 	.task-head-row,
				// 	.count-head {
				// 		padding-left: 15px;
				// 		padding-right: 15px;
				// 	}
				// 	.task-list-wrap {
				// 		padding-left: 15px;
				// 		padding-right: 15px;
				// 		&.scroll-active {
				// 			overflow-y: auto;
				// 			overflow-y: overlay;
				// 			@include custom-scrollbar;
				// 		}
				// 	}
				// }
				.collapsed-private-notes {
					background: none;
					padding: 15px 0 0;
					width: 100%;
					.btn-toggle {
						@include flex;
					}
				}
			}
			// .contributor-notes {
			// 	background: $color-white;
			// 	padding: 20px;
			// 	border: 1.4px solid rgba($color-input-field, 0.5);
			// 	@include border-radius(6px);
			// 	opacity: 0.7 !important;
			// 	max-height: 400px;
			// 	overflow: auto;
			// 	@include custom-scrollbar;
			// }
		}
		.toggle-wrap-field {
			.ck-editor-box {
				margin-top: 0.625em;
			}
		}
		.ck-editor-box {
			.ck {
				&.ck-editor {
					box-shadow: none;
				}
			}
		}
		.meeting-date-and-time {
			@include flex;
			@include align-items(center);
			input {
				&.form-control {
					width: 120px;
					padding: 4px 10px;
					margin-right: 5px;
					font-size: $font-size-h5;
					line-height: $line-height2;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(6px);
					font-family: $font-family-lato;
				}
			}
			.meeting-date-from {
				@include flex;
				margin-right: 10px;
			}
			.meeting-date-to {
				@include flex;
				margin: 0 10px;
			}
			.meeting-duration {
				.MuiTypography-body2 {
					font-weight: $font-weight-bold;
					color: $color-secondary1;
				}
			}
		}
	}
	.no-record-meeting {
		text-align: center;
		padding: 60px 30px;
	}
	.ck.ck-editor {
		p {
			font-size: $font-size-h5;
		}
	}
}
.meeting-schedule-details {
	.meeting-date-and-time {
		.meeting-time-field {
			min-width: 110px;
			.MuiAutocomplete-endAdornment {
				.MuiButtonBase-root {
					margin: 0;
					svg {
						width: 14px;
						height: 14px;
					}
					&:hover {
						background: transparent;
					}
				}
			}
			/* Remove default icon for webkit browsers (Chrome, Safari) */
			input[type='time']::-webkit-calendar-picker-indicator {
				display: none;
			}
			/* Remove default icon for Firefox */
			input[type='time'] {
				-moz-appearance: textfield;
			}
		}
	}
	&.helperText-bottom {
		.MuiFormLabel-root {
			color: $color-text-info;
			svg {
				path {
					fill: $color-text-info;
				}
			}
		}
		.error-field {
			left: 115px;
			top: 23px;
			&::after,
			&::before {
				@include transform(rotate(0deg));
				left: auto;
				top: auto;
			}
			&::before {
				right: 100%;
				top: 11px;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-right: 10px solid #ffeded;
			}
			&::after {
				right: 100%;
				top: 9.5px;
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-right: 12px solid #f3c3c3;
			}
		}
		.meeting-date-and-time {
			.form-control,
			.meeting-time-dropdown {
				border-color: $color-text-info !important;
			}
		}
		.meeting-date-and-time {
			.MuiInputBase-root {
				border: 1.4px solid $color-text-info;
			}
		}
	}
}
/**request 1-on-1 panel css start here**/
.drawer-request-panel {
	.drawer-form-fields {
		.drawer-blue-bg-full {
			padding-top: 40px;
			padding-bottom: 10px;
		}
		#box-requestOneOnOneAgenda {
			.error-field {
				top: calc(100% - 30px);
			}
		}
	}
	.helperText-bottom.select-error {
		.select-search-icon {
			& + .MuiFormHelperText-root {
				bottom: auto !important;
				//z-index: 9;
			}
		}
	}
	.select-search-dropdown {
		.react-select__control {
			&.react-select__control--menu-is-open {
				z-index: initial;
			}
		}
	}
	.one-on-one-field {
		sup {
			margin-left: 2.5px;
		}
	}
	.meeting-date-and-time {
		@include flex;
		@include align-items(center);
		input {
			&.form-control {
				width: 110px;
				padding: 4px 10px;
				margin-right: 5px;
				font-size: $font-size-h5;
				line-height: $line-height2;
				border: 1.4px solid rgba($color-input-field, 0.5);
				@include border-radius(6px);
				font-family: $font-family-lato;
			}
		}
		.meeting-date-from {
			@include flex;
			margin-right: 10px;
		}
		.meeting-date-to {
			@include flex;
			margin: 0 10px;
		}
		.meeting-duration {
			.MuiTypography-body2 {
				font-weight: $font-weight-bold;
				color: $color-secondary1;
			}
		}
		.meeting-time-field {
			min-width: 110px;
			.MuiAutocomplete-endAdornment {
				top: calc(50% - 12px);
				.MuiButtonBase-root {
					margin: 0;
					svg {
						width: 16px;
						height: 16px;
					}
					&:hover {
						background: transparent;
					}
				}
			}
			/* Remove default icon for webkit browsers (Chrome, Safari) */
			input[type='time']::-webkit-calendar-picker-indicator {
				display: none;
			}
			/* Remove default icon for Firefox */
			input[type='time'] {
				-moz-appearance: textfield;
			}
		}
	}
}

.launch-template-btn-action {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	margin-top: 20px;
	background: $color-white;
	position: fixed;
	bottom: 0px;
	width: calc(100% - 105px);
	left: 85px;
	padding: 10px 16px;
	z-index: 99;
	border-top: 1.4px solid $color-border-tile;
	@include border-radius(6px 6px 0px 0px);
	@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
}

/**team list right panel css start here**/
.white-box.team-member-box {
	padding: 20px 15px;
	left: 0;
	top: 80px;
	position: sticky;
	position: -webkit-sticky;
	.no-record {
		margin-top: 2.25em;
		text-align: center;
		padding: 0 30px;
		object {
			margin-bottom: 15px;
		}
		h2 {
			margin-bottom: 5px;
		}
	}
}
.team-list-right-panel {
	.team-box-head {
		@include flex;
		@include align-items(center);
		//@include justify-content(space-between);
		.custom-dropdown {
			width: calc(100% - 90px);
			margin-left: 10px;
			.dropdown-button {
				.team-name-txt {
					text-overflow: ellipsis;
					max-width: 88%;
					overflow: hidden;
					white-space: nowrap;
					display: inline-block;
				}
			}
		}
	}
	.team-member-list {
		margin-top: 25px;
		.team-list-head {
			padding-bottom: 10px;
		}
		.team-list-area {
			max-height: 350px;
			overflow: auto;
			@include custom-scrollbar;
		}
		.team-list-row {
			padding: 10px 5px;
			@include border-radius(3px);
			cursor: pointer;
			&:hover {
				background: $color-hover;
				.icon-btn {
					opacity: 1;
				}
			}
			&.active {
				background: $color-secondary2;
			}
			.icon-btn {
				opacity: 0;
				@include transition(0.3s);
			}
			.user-info {
				width: 100%;
				.user-info-details {
					width: calc(100% - 40px);
					div {
						@include flex;
					}
					.MuiTypography-h4,
					.MuiTypography-subtitle2 {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 100%;
						display: inline-block;
					}
				}
			}
		}
		ul {
			padding: 0;
			@include flex;
			@include justify-content(space-between);
			@include align-items(flex-start);
			width: 100%;
			li {
				padding: 0 5px;
				width: 75%;
				&:first-child {
					padding-left: 0px;
				}
				&.team-col2 {
					padding-right: 0px;
					text-align: right;
					width: 22%;
					@include justify-content(flex-end);
					h6 {
						margin-left: 8px;
					}
					button {
						margin: 0 3px;
					}
				}
			}
		}
		.last-meeting-date {
			display: inline-block;
			padding: 0 7px;
			color: $color-secondary1;
			background: $color-bg-form;
			font-size: $font-size-subtitle2;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			@include border-radius(20px);
			margin-left: 0px;
			//text-transform: uppercase;
			border: 1.4px solid $color-border-tile;
			margin-top: 2px;
		}
	}
}

/**Request 1-on-1 css start here**/
// .one-on-one-task {
// 	.one-on-one-task-row {
// 		@include flex;
// 		@include flex-direction(column);
// 		//@include align-items(center);
// 		//margin-top: 13px;
// 		margin-top: 4px;
// 		.set-timeline-area {
// 			margin-top: 10px;
// 			margin-left: 30px;
// 			.timeline-field {
// 				.calendar-btn {
// 					button {
// 						.MuiButton-label {
// 							@include justify-content(flex-start);
// 						}
// 						&.Mui-disabled {
// 							.MuiButton-label {
// 								color: rgba($color-primary1, 0.7);
// 								.icon {
// 									svg {
// 										path {
// 											fill: rgba($color-primary1, 0.7);
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 			&.no-cal-icon {
// 				button {
// 					&.Mui-disabled {
// 						.MuiButton-label {
// 							color: rgba($color-primary1, 1) !important;
// 							.icon {
// 								opacity: 0;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		&.task-head-row {
// 			.ans-box {
// 				width: 100%;
// 				//flex: initial;
// 				.set-timeline-area {
// 					position: absolute;
// 					right: 0px;
// 					top: 9px;
// 					margin: 0px;
// 					.error-field {
// 						min-width: 173px;
// 						left: -45px;
// 						bottom: -42px;
// 					}
// 					.calender-date {
// 						&.calender-date-error {
// 							.calendar-btn {
// 								button {
// 									.MuiButton-label {
// 										color: $color-text-info;
// 										svg {
// 											path {
// 												fill: $color-text-info;
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		.assigned-by-box {
// 			padding-left: 37px;
// 			p {
// 				color: rgba($color-primary2, 0.5);
// 			}
// 		}
// 	}
// 	.ans-box {
// 		position: relative;
// 		flex: 1 1;
// 		.MuiFormControl-root {
// 			min-width: 100%;
// 		}
// 		.inactive {
// 			textarea {
// 				background: $color-hover !important;
// 			}
// 		}
// 		.MuiInputBase-root {
// 			padding: 0;
// 			//border: 1.4px solid transparent;
// 			@include border-radius(6px);
// 			@include transition(0.25s);
// 			&:before,
// 			&:after {
// 				display: none;
// 			}
// 			.MuiInputBase-input {
// 				@include transition(0.25s);
// 				height: auto;
// 				padding: 7px 12px;
// 				font-size: $font-size-h5;
// 				line-height: 1.25;
// 				//border: 1.4px solid rgba($color-input-field, 0.5);
// 				@include border-radius(6px);
// 				//background: $color-white;
// 			}
// 			&.Mui-focused {
// 				textarea {
// 					@include box-shadow(2px 2px 10px rgba($color-primary2, 0.12));
// 				}
// 			}
// 		}
// 		.MuiTypography-body2 {
// 			font-size: $font-size-h5;
// 			line-height: 19px;
// 			color: $color-primary2;
// 			font-weight: $font-weight-regular;
// 		}
// 		.accomplish-answer {
// 			.MuiInputBase-root {
// 				position: relative;
// 				background-color: transparent;
// 				padding-right: 104px;
// 				@include align-items(flex-start);
// 				.MuiInputBase-input {
// 					box-sizing: border-box;
// 					background-color: transparent;
// 					padding-left: 8px;
// 				}
// 				&.Mui-focused {
// 					.MuiInputBase-input {
// 						box-shadow: none;
// 					}
// 				}
// 				.MuiInputAdornment-root {
// 					margin-right: 0;
// 					margin-left: 8px;
// 					margin-top: 16px;
// 					svg {
// 						width: 10px;
// 						height: 10px;
// 						path {
// 							fill: $color-primary1;
// 						}
// 					}
// 					.MuiTypography-body1 {
// 						font-size: $font-size-h5;
// 						color: rgba($color-primary2, 0.5);
// 					}
// 				}
// 			}
// 			.MuiInputBase-root {
// 				&.Mui-disabled {
// 					opacity: 1;
// 				}
// 			}
// 			.MuiInputBase-input {
// 				&.Mui-disabled {
// 					color: rgba($color-primary2, 0.6);
// 					opacity: 1;
// 				}
// 			}
// 			&.accomplish-ans-edit {
// 				.MuiInputBase-root {
// 					border: 1.4px solid $color-primary1 !important;
// 					@include border-radius(6px 6px 0 6px);
// 				}
// 			}
// 		}

// 		.accomplish-task-actions {
// 			position: absolute;
// 			right: 0;
// 			top: 100%;
// 			z-index: 1;
// 			@include flex;
// 			@include align-items(flex-start);
// 			background: $color-bg-form;
// 			button {
// 				padding: 4px 5px;
// 				&:hover {
// 					background-color: transparent;
// 				}
// 				&.acc-task-save-btn {
// 					@include border-radius(0 0 6px 6px);
// 					background-color: $color-primary1;
// 					svg {
// 						width: 14px;
// 						height: 14px;
// 						path {
// 							fill: $color-white;
// 						}
// 					}
// 				}
// 				&.acc-task-cancel-btn {
// 					//background: $color-bg-form;
// 					margin-right: 3px;
// 					svg {
// 						width: 12px;
// 						height: 14px;
// 						path {
// 							fill: $color-primary1;
// 							stroke: $color-primary1;
// 						}
// 					}
// 				}
// 				.MuiTouchRipple-root {
// 					display: none;
// 				}
// 			}
// 		}
// 	}

// 	.count-head {
// 		.count-head-text {
// 			padding: 15px 0 8px;
// 		}
// 		.count-head-no-task {
// 			padding: 18px 0 0;
// 			@include flex;
// 			@include justify-content(center);
// 			@include align-items(center);
// 			.no-task-img {
// 				margin-right: 15px;
// 			}
// 			.no-task-content {
// 				h6 {
// 					&:first-child {
// 						color: $color-primary2;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.accomplish-task {
// 		.ans-box {
// 			.accomplish-ans-box {
// 				@include flex;
// 				@include align-items(center);
// 				&:hover {
// 					.MuiInputBase-root {
// 						border: 1.4px solid rgba($color-input-field, 0.5);
// 						&.Mui-focused {
// 							border: 1.4px solid $color-primary1;
// 						}
// 					}
// 					.delete-btn {
// 						opacity: 1;
// 					}
// 				}
// 				&.not-editable {
// 					&:hover {
// 						.MuiInputBase-root {
// 							border: 1.4px solid transparent;
// 						}
// 					}
// 					.set-timeline-area {
// 						.timeline-field {
// 							.calendar-btn {
// 								button {
// 									.MuiButton-label {
// 										color: rgba($color-primary1, 0.7) !important;
// 									}
// 								}
// 							}
// 						}
// 					}
// 					.MuiInputBase-root {
// 						opacity: 1 !important;
// 					}
// 					.MuiInputBase-input {
// 						color: rgba($color-primary2, 0.6) !important;
// 						opacity: 1 !important;
// 					}
// 				}
// 				.MuiInputBase-root {
// 					border: 1.4px solid transparent;
// 					padding-right: 104px;
// 					.MuiInputBase-input {
// 						padding-left: 35px;
// 					}
// 					&.Mui-focused {
// 						border: 1.4px solid $color-primary1;
// 					}
// 				}
// 				.MuiCheckbox-root {
// 					position: absolute;
// 					left: 0;
// 					top: 0;
// 					z-index: 1;
// 					&:hover {
// 						background-color: transparent !important;
// 					}
// 					.MuiTouchRipple-root {
// 						display: none;
// 					}
// 				}
// 				.accomplish-task-box {
// 					position: relative;
// 					flex: 1;
// 					&.completed-task {
// 						//text-decoration: line-through;
// 						.MuiInputBase-root {
// 							opacity: 1;
// 						}
// 						.MuiInputBase-input {
// 							color: rgba($color-primary2, 1);
// 							opacity: 1;
// 						}
// 					}
// 				}

// 				.delete-btn {
// 					position: absolute;
// 					left: 100%;
// 					top: 5px;
// 					@include transition(0.25s);
// 					opacity: 0;
// 					padding: 5px;
// 					svg {
// 						path {
// 							fill: $color-primary1;
// 						}
// 					}
// 					&:hover {
// 						background-color: transparent;
// 					}
// 					.MuiTouchRipple-root {
// 						display: none;
// 					}
// 				}
// 				.set-timeline-area {
// 					position: absolute;
// 					right: 0px;
// 					top: 9px;
// 					margin: 0px;
// 				}
// 			}
// 		}
// 		&.edit-mode {
// 			.accomplish-task-box {
// 				&.completed-task {
// 					//text-decoration: line-through;
// 					.MuiInputBase-root.Mui-disabled {
// 						opacity: 1;
// 					}
// 					.MuiInputBase-input.Mui-disabled {
// 						color: rgba($color-primary2, 0.6);
// 						opacity: 1;
// 					}
// 				}
// 			}
// 			.set-timeline-area {
// 				.timeline-field {
// 					.calendar-btn {
// 						button {
// 							&.Mui-disabled {
// 								.MuiButton-label {
// 									color: rgba($color-primary1, 0.7) !important;
// 									.icon {
// 										svg {
// 											path {
// 												fill: rgba($color-primary1, 0.7);
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}

// 	/**task-list-self assigned user css start here**/
// 	.task-list-self,
// 	&.task-left-col {
// 		.one-on-one-task-row,
// 		&.assigned-user-task {
// 			width: 89%;
// 			.assigned-user-box {
// 				position: absolute;
// 				right: -34px;
// 				top: 6px;
// 				cursor: pointer;
// 				.user-avatar {
// 					position: relative;
// 					.MuiAvatar-root {
// 						width: 24px;
// 						height: 24px;
// 						font-size: 10px;
// 					}
// 					.hover-action {
// 						background: rgba($color-primary2, 0.5);
// 						width: 24px;
// 						height: 24px;
// 						@include border-radius(50%);
// 						@include flex;
// 						@include align-items(center);
// 						@include justify-content(center);
// 						position: absolute;
// 						top: 0px;
// 						left: 0px;
// 						svg {
// 							width: 10px;
// 							height: 10px;
// 							path {
// 								fill: $color-white;
// 								stroke: $color-white;
// 							}
// 						}
// 					}
// 					&.assigned-user {
// 						.hover-action {
// 							opacity: 0;
// 						}
// 						&:hover {
// 							.hover-action {
// 								opacity: 1;
// 							}
// 						}
// 					}
// 				}
// 				&.disabled {
// 					.user-avatar {
// 						cursor: default;
// 					}
// 				}
// 			}

// 			.ans-box {
// 				.accomplish-ans-box {
// 					.delete-btn {
// 						left: auto;
// 						right: -65px;
// 					}
// 				}
// 			}
// 		}
// 		&.assigned-user-task {
// 			width: 89.5%;
// 		}
// 	}
// }
.launch-agenda-content {
	background: $color-white;
	padding: 8px 1em;
	border: 1.4px solid rgba($color-input-field, 0.5);
	@include border-radius(6px);
	opacity: 0.7 !important;
	margin-top: 0.625em;
	max-height: 126px;
	overflow: auto;
	@include custom-scrollbar;
}
.disabled-label {
	opacity: 0.7 !important;
}

/**mom drawer panel css start here**/
.drawer-mom-meeting-panel {
	.search-no-record-global {
		padding-top: 50px;
	}
	.scroll-to-top {
		width: 40px;
		height: 40px;
		background: $color-primary1;
		@include border-radius(50%);
		position: fixed;
		right: 10px;
		bottom: 5px;
		font-size: $font-size-subtitle2;
		font-weight: bold;
		text-transform: uppercase !important;
		color: $color-white !important;
		z-index: 9;
		&:hover {
			background: $color-primary1;
		}
		svg {
			path {
				fill: $color-white;
			}
		}
		.MuiButton-label {
			@include flex;
			@include flex-direction(column);
		}
		.icon {
			margin-bottom: 2px;
		}
	}
	.mom-head {
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		.user-search {
			margin-left: 15px;
			.MuiInputBase-root {
				border-color: rgba($color-input-field, 0.5);
				background: $color-white;
				max-width: 170px;
				min-width: 170px;
				&.Mui-focused {
					border-color: $color-primary1;
				}
				.MuiInputBase-input {
					padding: 5px 12px 5px 0;
				}
			}
			.editIcon {
				position: absolute;
				right: 5px;
				margin: 0px;
			}
		}

		.range-selector-calender {
			margin: 0;
			position: relative;
			display: flex;
			align-items: center;
			.form-control {
				width: 195px;
				cursor: pointer;
				height: 30px;
				padding: 0 10px 0 28px;
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				border: 1.4px solid rgba($color-border-tile, 1);
				@include border-radius(30px);
				background-color: rgba($color-bg-form, 1);
				background-image: url(../../../images/calendarSmall.svg);
				background-repeat: no-repeat;
				background-position: 10px center;
				background-size: 14px;
			}
		}

		.mom-head-right {
			@include flex;
			@include align-items(center);
		}
	}
	.mom-meeting-list {
		padding: 0px 0 0;
		.load-more-area {
			padding: 0 50px;
		}
		.MuiCollapse-wrapperInner {
			padding: 0 60px;
		}
		.mom-meeting-head {
			margin-bottom: 10px;
		}
		.mom-meeting-list-row {
			margin-bottom: 10px;
			background: $color-bg-form;
			.mom-meeting-list-head {
				//border: 1.4px solid $color-border-tile;
				//@include border-radius(0.375em);
				position: relative;
				// background-color: $color-white;
				// @include border-radius(0.375em);
				// transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
				// transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
				// transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;

				&.expand-head {
					.mom-meeting-list-head-inner {
						//@include box-shadow(2px 0 20px rgba(41, 41, 41, 0.12));
					}
				}
			}
			.mom-meeting-list-head-inner {
				position: relative;
				padding: 22px 60px;
				cursor: pointer;

				.rotating-arrow {
					cursor: pointer;
					position: absolute;
					width: 24px;
					height: 24px;
					left: 34px;
					top: 22px;
					opacity: 1;
					@include border-radius(50%);
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					@include transition(0.5s);
					svg {
						@include transition(0.5s);
						width: 10px;
						height: 10px;
						path {
							fill: $color-primary2;
							stroke: $color-primary2;
						}
					}
					&.expand {
						svg {
							@include transform(rotate(90deg));
						}
					}
					&:hover {
						background: rgba($color-secondary2, 1);
					}
				}
				.mom-meeting-title {
					.MuiTypography-body1 {
						margin-bottom: 8px;
						font-weight: $font-weight-bold;
						&.meeting-to-txt {
							margin: 0 5px;
						}
					}
					.meeting-schedule-calender {
						@include flex;
						.meeting-schedule-date {
							@include flex;
							@include align-items(center);
							.schedule-time {
								color: $color-secondary1;
							}
						}
						&.meeting-schedule-calender-single {
							.meeting-schedule-date {
								.schedule-date {
									margin-right: 5px;
								}
							}
						}
					}
				}
			}
			.meeting-participant-info {
				padding-top: 30px;
				padding-bottom: 30px;
				border-bottom: 1.4px solid $color-border-tile;
				&:first-child {
					padding-top: 3px;
				}
				&:last-child {
					border-bottom: 0px;
				}
				.participants-user-head {
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					margin-bottom: 10px;
					.owner-chip {
						background: $color-bg-tag;
						@include border-radius(100px);
						color: $color-threshold !important;
						text-transform: uppercase;
						margin: 0 10px 0 5px;
					}
				}
				.participants-private-notes-wrap {
					background: $color-okr-highlighter;
					padding: 20px;
					margin-top: 30px;
					@include border-radius(6px);
				}
				.user-notes-head {
					@include flex;
					@include justify-content(space-between);
					padding: 0px 0 12px;
				}
				.participants-user-notes {
					.user-notes-head {
						.MuiTypography-h6 {
							text-transform: uppercase;
						}
					}
				}
				.participants-task-section {
					padding-top: 30px;
					.participants-task-list {
						margin-left: -10px;
					}
					.task-count-head {
						padding-bottom: 6px;
						.MuiTypography-h6 {
							text-transform: uppercase;
						}
					}
					.task-row {
						padding: 15px 10px 15px 0;
						@include flex;
						position: relative;
						@include border-radius(6px);
						&:hover {
							background: $color-bg-teamstag;
						}
						&:last-child {
							&::after {
								display: none;
							}
						}
						&::after {
							content: '';
							background: $color-border-tile;
							width: calc(100% - 35px);
							height: 1px;
							position: absolute;
							bottom: 0;
							left: 35px;
						}
						.col-1 {
							width: calc(100% - 135px);
							padding-right: 30px;
						}
						.col-2 {
							width: 110px;
							padding-right: 32px;
						}
						.col-3 {
							width: 25px;
							//padding-right: 10px;
						}
						.task-title {
							@include flex;
							@include align-items(flex-start);
							padding-left: 36px;
							position: relative;

							.MuiCheckbox-root {
								position: absolute;
								left: 10px;
								top: 3px;
								padding: 0;
							}
							.MuiTypography-subtitle1 {
								padding-top: 0px;
								font-weight: normal;
								color: $color-primary2;
								word-break: break-word;
							}
							.MuiCheckbox-root {
								.MuiTouchRipple-root {
									display: none;
								}
								&:hover {
									background-color: initial !important;
								}
							}
						}
						.quick-links-btn {
							margin-top: 14px;
							padding-left: 35px;
							min-height: 0px;
							.created-btn {
								&.btn-link-type1 {
									color: $color-secondary1;
									cursor: default;
									svg {
										path {
											fill: $color-secondary1;
										}
									}
									.alert-due-on {
										svg {
											path {
												fill: $color-white;
											}
										}
									}
								}
							}
							.priority-links {
								.blue-btn {
									cursor: default;
								}
							}
							.kr-linking-name {
								cursor: default;
								h6 {
									max-width: calc(150px - 20px);
								}
							}
						}
						.task-due-date {
							margin-top: 14px;
							margin-left: 35px;
							.MuiTypography-h6 {
								@include flex;
								@include align-items(center);
								svg {
									width: 14px;
									height: 14px;
									margin-right: 5px;
									path {
										fill: $color-secondary1;
									}
								}
							}
						}
						.task-copy {
							.MuiFormControlLabel-root {
								margin: 0px;
							}
						}
						.task-assigned-user {
							.user-avatar-group {
								.task-aligned-users {
									@include flex;
									@include align-items(center);
									.MuiAvatar-root {
										width: 40px;
										height: 40px;
										margin-left: 5px;
									}
									.task-assigned-by {
										position: relative;
										.align-arrow {
											position: absolute;
											right: -7px;
											bottom: 0;
											margin: 0 5px;
											width: 14px;
											height: 14px;
											@include border-radius(100%);
											background: $color-border-tile;
											@include flex;
											@include align-items(center);
											@include justify-content(center);
											@include transform(rotate(180deg));
											svg {
												width: 10px;
												height: 10px;
												path {
													fill: $color-secondary1;
												}
											}
										}
									}
									.task-assigned-to {
										.MuiAvatar-root {
											width: 30px;
											height: 30px;
										}
										.blue-btn {
											width: 16px;
											height: 16px;
											margin-left: 5px;
											svg {
												width: 16px;
												height: 16px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.participants-no-data {
		//padding: 18px 0 0;
		@include flex;
		@include justify-content(center);
		@include align-items(center);
		.no-task-img {
			margin-right: 15px;
		}
		.no-task-content {
			h6 {
				&:first-child {
					color: $color-primary2;
				}
			}
		}
	}
}
.no-task-create {
	margin-top: 15px !important;
}
/**edit meeting popup link css start here**/

.meeting-link-row {
	position: relative;
	.copied-text {
		position: absolute;
		right: 5px;
		top: 72px;
	}
	.launch-meeting-link {
		.join-btn {
			box-shadow: none;
			@include border-radius(0 6px 6px 0px);
			margin-right: -12px;
			min-width: 80px;
			min-height: 32px;
			padding: 4px 10px;
		}
	}
}
.meeting-btn {
	width: 24px;
	height: 24px;
	margin-left: 5px !important;
	margin-right: -5px !important;
	@include border-radius(50%!important);
	cursor: default !important;
	svg {
		width: 14px;
		height: 14px;
	}
	.MuiTouchRipple-root {
		display: none !important;
	}
}
.user-copy-btn {
	@include flex;
	@include align-items(center);
	.btn-link-type1 {
		margin-left: 10px;
	}
}
// .one-on-one-task {
// 	.set-timeline-area {
// 		.timeline-field {
// 			.calendar-btn {
// 				&.show-red {
// 					button,
// 					.MuiButton-label {
// 						color: $color-text-info !important;
// 						svg {
// 							path {
// 								fill: $color-text-info !important;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
.eng-calender-date-error {
	position: relative;
	.error-field {
		width: 175px !important;
		z-index: 9 !important;
		left: -45px !important;
		bottom: auto !important;
		top: 24px !important;
	}
}
.assigned-tooltip {
	.MuiAvatar-root {
		cursor: default;
	}
}

/**one on one task new design css start here**/
.one-on-one-task {
	.create-task-block {
		padding: 0px;
		background: none;
		border: 0px;
		border-radius: 0px;
		.textfield-wrap {
			.MuiInputBase-root {
				background: transparent;
			}
		}
	}

	/**not found screen css start here**/
	.not-found-screen {
		text-align: center;
		h4 {
			margin-bottom: 0px !important;
		}
		h5 {
			font-weight: normal;
		}
		.blank-bg {
			width: 100%;
			height: 100px;
			@include border-radius(6px);
			background: $color-white;
			margin-top: 25px;
		}
	}
}
.time-field-popper {
	.MuiAutocomplete-listbox {
		@include custom-scrollbar;
	}
	.MuiAutocomplete-option {
		padding: 6px 10px;
	}
}
