@import '../../../styles/sass-utils/variables';

.goals-review-list-area {
	.user-goals-section-content {
		.user-goals-section-list-panel {
			.user-goals-actions {
				.user-goals-hvr-actions {
					button {
						opacity: 1;
					}
				}
			}
		}
	}
}
.team-review-status {
	margin-right: 10px;
	&:last-child {
		margin-right: 0;
	}
	.MuiChip-root {
		height: auto;
		padding: 0 7px;
		color: $color-primary2;
		line-height: 1;
		.MuiChip-label {
			padding: 0;
			font-size: $font-size-subtitle2;
			line-height: $line-height5;
			text-transform: uppercase;
		}
		&.draft-chip {
			color: $color-tags;
			border: 1px solid $color-tags;
			background-color: $color-okr-highlighter;
		}
		&.lagging-chip {
			background: $color-lagging;
			border: 1px solid $color-lagging;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.on-track-chip {
			background: $color-on-track;
			border: 1px solid $color-on-track;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.at-risk-chip {
			background: $color-text-info;
			border: 1px solid $color-text-info;
			.MuiChip-label {
				color: $color-white;
			}
		}
	}
}
.goals-review-stats-area {
	.goals-review-stats-box-area {
		&.team-review-stats-box-area {
			margin-top: 30px;
		}
		@include flex;
		.self-review-box {
			@include flex;
			padding: 20px 0;
			max-width: 450px;
			min-width: 450px;
			background-color: rgba($color-white, 0.5);
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			cursor: default;
			.goals-review-stats-box {
				padding: 0 20px;
				margin: 0;
				background: transparent;
				border: none;
				@include border-radius(0px);
				&:first-child {
					border-right: 1.4px solid $color-border-tile;
				}
			}
			&.active {
				background-color: $color-white !important;
				border: 1.4px solid $color-primary1;
				@include default-shadow;
				cursor: pointer;
			}
		}
		.goals-review-stats-box {
			@include flex;
			@include justify-content(space-between);
			@include flex-direction(column);
			flex: 1;
			margin-left: 10px;
			padding: 20px;
			max-width: 225px;
			min-width: 194px;
			background-color: rgba($color-white, 0.5);
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			cursor: default;
			&:last-child {
				margin-right: 0px;
			}
			.box-head {
				@include flex;
				.box-head-right {
					.box-head-right-inner {
						@include flex;
						//@include justify-content(center);
						.btn-link {
							.MuiTypography-h2 {
								color: $color-primary1;
							}
						}
						.goal-review-status {
							margin-left: 5px;
						}
					}
				}
				.MuiTypography-h2 {
					font-size: $font-size-tile-head;
					line-height: 28px;
					font-weight: $font-weight-bold;
					&.blank-text {
						opacity: 0.3;
						//margin-top: -18px;
					}
				}
				svg {
					margin-right: 10px;
					width: 24px;
					height: 24px;
				}
				.overall-txt {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-regular;

					display: block;
				}
				sub.final-rating-text {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-bold;
					color: $color-secondary1;
					margin-left: 7px;
				}
				.btn-link {
					height: auto;
					line-height: 1;
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					margin: 0px;
					display: block;
					text-decoration: none;
				}
			}
			.box-setting-des {
				padding-left: 34px;
			}
			.box-content {
				margin-top: 15px;
				.MuiTypography-h6 {
					font-weight: $font-weight-regular;
					line-height: $line-height5;
				}
			}
			&.active {
				background-color: $color-white !important;
				border: 1.4px solid $color-primary1;
				@include default-shadow;
				cursor: pointer;
			}
			&.enable-box {
				background-color: $color-white;
				cursor: pointer;
				&:hover {
					border: 1.4px solid $color-primary1;
					@include default-shadow;
				}
			}
		}
		.team-review-box {
			max-width: 190px;
			margin-right: 5px;
			padding: 15px;
			cursor: pointer;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.team-review-box-head {
				margin-bottom: 25px;
				@include flex;
				@include align-items(center);
				.team-review-status {
					.MuiChip-root {
						margin-top: 10px;
					}
				}
			}
			&:hover,
			&.active {
				border: 1.4px solid $color-primary1;
				@include default-shadow;
			}
		}
	}
	.goals-review-stats-notes {
		margin-top: 15px;
		margin-bottom: 15px;
		@include flex;
		@include align-items(center);
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
		.MuiTypography-body2 {
			color: $color-secondary1;
		}
		&.goal-review-error-notes {
			.review-days-remaining {
				margin-right: 7px;
			}
			.MuiTypography-body2 {
				color: $color-text-info;
			}
		}
	}
}
/* Goal Self review CSS */
.goal-self-review-area {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1.4px solid $color-hover;
	.goal-self-review-title {
		padding-bottom: 15px;
		@include flex;
		@include align-items(center);
		svg {
			margin-right: 5px;
			width: 14px;
			height: 14px;
		}
		&.helperText-bottom {
			.error-field {
				left: -35px;
				top: auto;
				bottom: calc(100% + 5px);
				width: 150px;

				&::before {
					top: calc(100% - 1.4px);
					border-top: 8px solid $color-avatar-disable;
				}
				&::after {
					top: 100%;
					border-top: 8px solid $color-border-info;
				}

				&::before,
				&::after {
					@include transform(rotate(0deg));
					margin-right: -3px;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					right: 70%;
				}
				.MuiTypography-body2 {
					color: $color-text-info;
				}
			}
		}
	}
	.goal-review-rating-box {
		@include flex;
		.goal-review-star-rating {
			width: 110px;
			.goal-rating-level {
				.MuiTypography-root {
					font-size: $font-size-h6;
					line-height: $line-height5;
					color: $color-secondary1;
				}
			}
		}
		.MuiRating-root {
			.MuiRating-label {
				padding-bottom: 15px;
				width: 110px;
				@include flex;
				@include flex-direction(column);
				@include align-items(center);
				.MuiRating-icon {
					@include transition(transform 0.2s);
					&.MuiRating-iconHover,
					&.MuiRating-iconActive {
						transform: scale(1);
					}
				}
				span {
					&.MuiRating-visuallyhidden {
						// position: relative;
						// text-align: center;
						// top: 5px;
						// width: 110px;
						// height: auto;
						// clip: unset;
						// font-size: $font-size-h6;
						// line-height: $line-height5;
						// color: $color-secondary1;
						// opacity: 0;
						// @include transition(0.25s);
						display: none;
					}
				}
			}
			&.Mui-disabled {
				opacity: 1;
				.MuiRating-label {
					span {
						&.MuiRating-visuallyhidden {
							color: $color-primary2;
						}
					}
				}
			}
		}
		&.goal-review-rating-box-error {
			.MuiRating-root {
				.MuiRating-label {
					.MuiRating-icon {
						svg {
							path:nth-child(2) {
								fill: $color-text-info;
								fill-opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	.goal-review-comment-box {
		margin-top: 10px;
		.goal-review-comments-btn {
			text-transform: unset !important;
			.MuiButton-label {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				line-height: 1;
			}
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
			i {
				font-weight: $font-weight-bold;
			}
		}
		.goal-review-comments-field {
			.MuiCollapse-wrapper {
				margin-top: 10px;
				.MuiCollapse-wrapperInner {
					padding: 0;
				}
			}
		}
		&.goal-review-comment-box-error {
			.goal-review-comments-field {
				.ck.ck-toolbar {
					border-color: $color-text-info !important;
				}
				.ck.ck-editor__main {
					> .ck-content {
						border: 1.4px solid $color-text-info;
						border-top: 1.4px solid rgba($color-primary2, 0.2);
					}
				}
			}
		}
	}
	.goal-review-rating-box,
	.goal-review-comment-box {
		position: relative;
		.error-field {
			left: -35px;
			top: auto;
			bottom: calc(100% + 5px);
			width: 180px;
			&::before {
				top: calc(100% - 1.4px);
				border-top: 8px solid $color-avatar-disable;
			}
			&::after {
				top: 100%;
				border-top: 8px solid $color-border-info;
			}

			&::before,
			&::after {
				margin-right: -3px;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				right: 70%;
			}
			.MuiTypography-body2 {
				color: $color-text-info;
			}
		}
	}
	.goal-review-rating-box {
		.error-field {
			&::before,
			&::after {
				right: 48%;
			}
		}
	}
	.goal-review-radio-box {
		.MuiFormControlLabel-root {
			margin-left: -8px;

			.MuiFormControlLabel-label {
				font-size: $font-size-h6;
				line-height: $line-height5;
				color: $color-secondary1;
			}

			&.Mui-disabled {
				.Mui-checked + .MuiFormControlLabel-label {
					color: $color-primary2;
				}
			}
		}

		.MuiRadio-root {
			padding: 6px;
			color: $color-new-common4;

			svg {
				width: 16px;
				height: 16px;

				path {
					fill: rgba($color-new-common3, 0.5);
				}
			}

			&:hover {
				background: transparent;

				svg {
					path {
						fill: $color-new-common4;
					}
				}
			}

			&.Mui-checked {
				svg {
					path {
						fill: $color-new-common4;
					}
				}
			}
		}

		&.goal-review-radio-box-error {
			.MuiRadio-root {
				svg {
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}
}
.goals-review-actions {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	.goals-review-action-left {
	}
	.goals-review-action-right {
		@include flex;
		@include align-items(center);
		.MuiTypography-root {
			font-style: italic;
			font-size: $font-size-base;
			text-transform: lowercase;
			color: $color-input-field;
		}
	}
}
.goals-review-welcome {
	margin-top: 50px;
	object {
		margin-top: 30px;
	}
}
/* Goal Team review CSS */
.goal-team-review-area {
	.team-review-listing-header {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		margin-top: 30px;
		margin-bottom: 30px;
		.team-review-listing-header-left {
			.my-actionable-switch {
				.MuiFormControlLabel-root {
					margin-left: 0;
				}
			}
		}
		.team-review-listing-header-right {
			@include flex;
			@include align-items(center);
			.user-search {
				.MuiInputBase-root {
					max-width: 200px;
					background: $color-bg-form;
				}
			}
		}
	}
}

/**team assessment listing css start here**/
.team-review-table-listing {
	box-shadow: none;
	background: none;
	@include custom-scrollbar-small-height;
	padding-bottom: 20px;
	max-height: calc(100vh - 150px);
	max-width: 1221px;
	@media screen and (max-width: 1282px) {
		max-width: 1150px;
	}
	table {
		position: relative;
		border-collapse: separate; /* Don't collapse */
		border-spacing: 0;
		.MuiTableHead-root {
			.MuiTableCell-root {
				&.user-col5 {
					.cell-wrap {
						@include justify-content(flex-start);
						.info-icon-btn {
							padding: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
				}
			}
		}
		.MuiTableCell-root {
			//min-width: 200px;
			padding: 0px;
			border: 0px;
			.clickable-text {
				color: $color-primary1;
			}
			&.user-col1,
			&.user-col2 {
				width: 260px;
				max-width: 260px;
			}
			&.user-col3,
			&.user-col4,
			&.user-col5 {
				width: 190px;
				max-width: 190px;
			}
			&.user-col6 {
				width: 50px;
			}
			&.user-col1 {
				min-width: 260px;
				left: 0;
				position: -webkit-sticky;
				position: sticky;
				z-index: 9;
				width: 260px;
				.user-info {
					flex: 1;
					.user-img {
						.MuiAvatar-root {
							margin-top: 0px;
						}
					}
					.user-info-details {
						width: calc(100% - 40px);
						.MuiTypography-root {
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							max-width: calc(100% - 40px);
						}
					}
				}
			}
			&.user-col2 {
				.MuiTypography-root {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					max-width: 100%;
				}
				.other-teams-chip {
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					min-width: 16px;
					height: 16px;
					margin-left: 5px;
					background-color: $color-primary1;
					border: none;
					padding: 0 3px;
					@include border-radius(20px);
					.MuiTypography-root {
						color: $color-white;
						font-size: $font-size-subtitle2;
						line-height: $line-height5;
						font-weight: $font-weight-bold;
					}
				}
			}
			&.user-col3 {
				.cell-wrap {
					.MuiTypography-body1 {
						margin-right: 15px;
					}
					.MuiTypography-body2 {
						color: $color-secondary1;
					}
				}
			}
			&.user-col4 {
				.cell-wrap {
					.manager-review-status {
						@include flex;
						@include align-items(baseline);
						.MuiTypography-body2 {
							margin-right: 10px;
							color: $color-secondary1;
						}
					}
					.recommended-btn {
						padding: 0;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
					.btn-link-type1 {
						@include justify-content(flex-start);
						min-width: 62px;
						&.Mui-disabled {
							color: rgba($color-primary2, 0.5);
						}
					}
				}
			}
			&.user-col5 {
				.cell-wrap {
					@include justify-content(space-between);
					.MuiTypography-body2 {
						margin-right: 15px;
						color: $color-secondary1;
					}
				}
			}
			&.user-col6 {
				.cell-wrap {
					@include justify-content(center);
				}
				.conversation-btn {
					padding: 0;
					svg {
						width: 14px;
						height: 14px;
						path {
							fill: $color-primary1;
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}

					&:hover {
						background-color: transparent;
					}
					&.Mui-disabled {
						cursor: default;
						svg {
							path {
								fill: rgba($color-input-field, 0.5);
							}
						}
					}
				}
			}
		}
		.cell-wrap {
			@include flex;
			@include align-items(center);
			@include justify-content(flex-start);
			.sort-title {
				.sortable-label {
					@include flex;
					@include align-items(center);
					cursor: pointer;
					> span {
						padding-right: 4px;
					}
					svg {
						width: 10px;
						height: 10px;
						margin-top: 0px;
						@include transition-rotate;
					}
				}
				&.asc {
					.sortable-label {
						svg {
							@include transform(rotate(-180deg));
						}
					}
				}
				&.desc {
					.sortable-label {
						svg {
							@include transform(rotate(0deg));
						}
					}
				}
			}
			.txt-wrap {
				font-weight: $font-weight-bold;
				color: $color-secondary1;
				font-size: $font-size-h6;
				line-height: $line-height3;
				text-transform: uppercase;
			}
			.MuiFormControlLabel-root {
				margin-right: 5px;
				margin-left: -5px;
			}
			.MuiTypography-body1 {
				//font-weight: $font-weight-bold;
				font-size: $font-size-h4;
				line-height: $line-height2;
			}
			.blank-dash {
				opacity: 0.3;
				font-size: $font-size-h4;
				line-height: $line-height2;
				font-weight: $font-weight-bold;
			}
			.icon-btn {
				margin-right: 5px;
				&:last-child {
					margin-right: 0px;
				}
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
			.short-text {
				color: $color-secondary1;
				font-size: $font-size-h6;
				line-height: $line-height5;
				//font-weight: $font-weight-bold;
				margin-left: 5px;
			}
			.btn-link {
				height: auto;
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				&.edit-button {
					margin-left: 10px !important;
					margin-right: 10px !important;
					opacity: 0;
					@include transition(0.3s);
				}
			}
			.inner-wrap,
			.text-wrap-box {
				@include flex;
				width: 100%;
				.btn-wrap-box {
					width: 50%;
					margin-bottom: 0px !important;
				}
			}
			.text-wrap-box {
				.btn-wrap-box {
					width: 40%;
					.btn-link {
						margin-right: 0px;
					}
					&:last-child {
						width: 60%;
					}
					&.full-width {
						width: 100%;
					}
				}
			}
			.btn-txt {
				font-size: $font-size-h4;
				line-height: $line-height2;
				font-weight: normal;
				color: $color-primary1;
				cursor: pointer;
			}
		}
		/**table head  css start here**/
		.MuiTableCell-root.MuiTableCell-head {
			padding: 8px 10px;
			border: 1.4px solid transparent;
			border-bottom: 0px;
			border-left: 0px;
			background: $color-secondary2;
			left: 0;
			top: 0px;
			position: sticky;
			position: -webkit-sticky;
			z-index: 2;
			&:first-child {
				border-top: 0px;
				border-left: 1.4px solid transparent;
				z-index: 99;
			}
		}

		/**table body row css start here**/
		.team-review-table-row {
			.MuiTableCell-root {
				padding: 8px 10px;
				border: 1.4px solid $color-border-tile;
				border-bottom: 0px;
				border-left: 0px;
				min-height: 62px;
				background: $color-white;
				&:first-child {
					@include border-radius(6px 0 0 6px);
					border-left: 1.4px solid $color-border-tile;
				}

				&:last-child {
					@include border-radius(0px 6px 6px 0px);
				}
				&.blue-color-column {
					background: rgba($color-white, 0.5);
				}
			}
			&:nth-last-child(1) {
				.MuiTableCell-root {
					border-bottom: 1.4px solid $color-border-tile;
				}
			}
			&.highlight-column {
				.MuiTableCell-root {
					background-color: $color-okr-highlighter;
				}
			}
			&.disable-row {
				opacity: 0.4;
				.cell-wrap {
					.MuiTypography-body1 {
						color: rgba($color-primary2, 0.6);
					}
					.btn-link {
						color: rgba($color-primary2, 0.6);
					}
					.icon-btn {
						svg {
							path {
								fill: rgba($color-primary2, 0.6);
							}
						}
					}
					.user-info {
						h4 {
							color: rgba($color-primary2, 0.6);
						}
					}
				}
			}
		}
	}
}
.main-drawer-panel {
	&.review-conversation-drawer {
		.drawer-inner-content {
			.user-info {
				position: relative;
				padding-right: 15px;
				margin-right: 15px;
				&:after {
					position: absolute;
					right: 0;
					top: 14px;
					content: '';
					width: 3px;
					height: 3px;
					background: rgba($color-secondary1, 0.5);
					@include border-radius(3px);
				}
			}
			.review-for-info {
				padding: 0 60px;
				@include flex;
				@include align-items(center);
				.review-period {
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
			}
			.drawer-tabs-panel {
				.cfr-tab-inner-head {
					margin-top: 50px;
				}
				.tab-panel-inner {
					padding: 28px 60px;
				}
			}
		}
		.information-message {
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			padding: 10px 60px;
			background: rgba($color-text-info, 0.07);
			@include animation(slideUp 0.8s);
			.MuiTypography-h6 {
				@include inline-flex;
				//@include align-items(center);
				color: $color-text-info;
				text-transform: initial;
				line-height: 14px;
				svg {
					width: 14px;
					height: 14px;
					min-width: 14px;
					margin-right: 5px;
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}
	.information-message {
		svg {
			width: 14px;
			height: 14px;
			min-width: 14px;
			margin-right: 5px;
			path {
				fill: $color-text-info;
			}
		}
	}
}

/**header tooltip css start here**/
.review-status-info-tooltip {
	margin-right: 0;
	margin-left: -58px;
	margin-top: 11px;
	padding: 15px 5px 15px 20px;
	z-index: 9999;
	background: $color-white;
	box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
	@include border-radius(6px);
	&::before {
		content: '';
		width: 0px;
		height: 0px;
		position: absolute;
		top: -7px;
		right: 115px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #ffffff;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -7px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		resize: both;
		overflow: auto;
		min-width: 310px;
		width: 310px;
		height: 202px;
		padding-right: 15px;
		@include custom-scrollbar-small-width;
	}
	.review-approval-sequence-list {
		width: 100%;
		margin: 15px 0 0;
		@include flex;
		@include justify-content(space-between);
		.review-approval-sequence {
			width: 25%;
			position: relative;
			@include border-radius(6px);
			&:after {
				position: absolute;
				left: 0;
				top: 0;
				content: '';
				height: 1.4px;
				width: 100%;
				background: $color-secondary1;
			}
			&:first-child {
				&:after {
					left: 30px;
					width: calc(100% - 30px);
				}
			}
			&:last-child {
				&:after {
					width: 30px;
				}
			}
			.review-approval-sequence-index {
				padding-top: 7px;
				text-align: center;
				position: relative;
				&::before {
					z-index: 1;
					content: '';
					width: 5px;
					height: 5px;
					position: absolute;
					left: 50%;
					bottom: calc(100% - 5px);
					margin-left: -7px;
					background: $color-white;
					border: 2px solid $color-secondary1;
					@include border-radius(9px);
				}
			}
		}
	}
	.review-approval-sequence-content {
		padding: 15px 0;
		margin-top: 15px;
		border-top: 1.4px solid $color-hover;
		.MuiTypography-body2 {
			padding-bottom: 10px;
			color: $color-secondary1;
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

.user-tabpanel-inner {
	.user-tabpanel-head {
		padding: 0 0 20px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		min-height: 64px;
		.MuiTypography-h2 {
			font-weight: $font-weight-black;
		}
		.user-tabpanel-head-left {
			@include flex;
			@include align-items(baseline);
			.MuiTabs-root {
				min-height: auto;
				.MuiTab-root {
					max-width: unset;
				}
			}
		}
		.user-tabpanel-head-right {
			@include flex;
		}
	}
}
.personalized-growth-card {
	.personalized-growth-head {
		padding-bottom: 15px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.personalized-growth-head-left {
			&.helperText-bottom {
				.MuiTypography-h4,
				.subtitle3 {
					color: $color-text-info;
				}
				.error-field {
					top: 45px;
				}
			}
		}
	}
	.course-card-panel-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
		grid-gap: 10px;
		.course-card-panel {
			cursor: pointer;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.courses-img {
				position: relative;
				@include border-radius(6px 6px 0 0);
				overflow: hidden;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				height: 162px;
				img {
					@include transition-default;
				}
				&:hover {
					img {
						transform: scale(1.25);
					}
				}
				.MuiSkeleton-text {
					background: rgba($color-secondary2, 0.5);
					@include border-radius(6px 0px 0 6px);
					height: 100%;
					transform: none;
				}
				&.no-course-img {
					height: 150px;
					background: rgba($color-secondary2, 0.5);
					@include border-radius(6px);
					.no-img-content {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						@include flex-direction(column);
						height: 100%;
						h6 {
							margin-top: 4px;
						}
						svg {
							max-width: 40px;
							min-width: 40px;
						}
					}
				}
				.training-checkbox {
					position: absolute;
					left: 5px;
					top: 5px;
					.MuiFormControlLabel-root {
						margin-left: 0;
						margin-right: 0;
					}
					.MuiCheckbox-root {
						background-color: rgba($color-primary2, 0.8) !important;
						@include border-radius(6px);
						svg {
							path {
								fill: $color-white;
							}
						}
						&:hover {
							background-color: rgba($color-primary2, 0.8) !important;
						}
						&.Mui-checked {
							svg {
								path {
									&:last-child {
										fill: $color-primary1;
									}
								}
							}
						}
					}
				}
				.btn-cross {
					position: absolute;
					top: 5px;
					right: 5px;
					background-color: rgba($color-primary2, 0.8);
					@include border-radius(6px);
					svg {
						path {
							fill: $color-white;
						}
					}
				}
			}
			.courses-content-section {
				padding: 15px;
				.course-title {
					height: 44px;
					@include flex;
					@include align-items(flex-start);
					h4 {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}

				.course-session {
					padding: 15px 0;
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.course-published,
					.courses-duration {
						@include flex;
						@include align-items(center);
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
							path {
								fill: $color-hint-help-text;
							}
						}
						.MuiTypography-h6 {
							color: $color-hint-help-text;
						}
					}
				}
			}
			.course-description {
				height: 64px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
			}
			.MuiSkeleton-text {
				background: rgba($color-secondary2, 0.5);
				@include border-radius(9px);
			}
		}
	}
	.load-more-box {
		margin-top: 5px;
		.load-more-btn {
			padding: 0 20px;
			height: 32px;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			border: 1.4px solid $color-border-tile;
			background: $color-expanded-tiles;
			@include border-radius(20px);
			@include transition(0.25s);
			.MuiButton-label {
				.icon {
					margin-left: 5px;
					display: none;
					order: 1;
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			&:hover {
				padding-right: 15px;
				.MuiButton-label {
					.icon {
						display: flex;
					}
				}
			}
		}
	}
	.other-trainings-comment-box {
		margin-top: 25px;
		.other-trainings-label {
			@include flex;
			@include align-items(center);
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
			}
		}
		.other-trainings-comment-field {
			margin-top: 3px;
			.view-only-comment {
				border-top: 1.4px solid $color-secondary2;
				padding-top: 10px;
				margin-top: 10px;
			}
		}
	}
}
.final-ratings-card {
	.final-ratings-head {
		margin-bottom: 20px;
		@include flex;
		@include justify-content(space-between);
		.final-ratings-head-right {
			@include flex;
			@include align-items(center);
			ul {
				@include flex;
				@include align-items(center);
				li {
					padding: 0;
					padding-right: 13px;
					margin-right: 10px;
					position: relative;
					width: auto;
					.MuiButton-root {
						padding: 0;
						font-size: $font-size-h6;
						color: $color-primary1;
						font-weight: $font-weight-bold;
						.MuiButton-label {
							@include flex;
							@include align-items(center);
							text-transform: capitalize;
						}
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							background-color: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
					&:after {
						content: '';
						width: 3px;
						height: 3px;
						position: absolute;
						right: 0;
						background: rgba($color-secondary1, 0.5);
						@include border-radius(6px);
					}
					&:last-child {
						padding: 0;
						margin: 0;
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	.final-rating-label {
		margin-bottom: 3px;
		@include flex;
		@include align-items(center);
		svg {
			margin-right: 5px;
			width: 14px;
			height: 14px;
		}
	}
	.user-final-review-info-box {
		padding: 20px;
		background: $color-bg-form;
		@include border-radius(6px);
		.user-info {
			margin-bottom: 15px;
		}
		.user-final-review-info-row {
			display: -ms-grid;
			display: grid;
			grid-gap: 30px;
			-ms-grid-columns: 1fr 3fr;
			grid-template-columns: 1fr 3fr;
			.user-final-review-info-col {
				.select-rating-box {
					.MuiTypography-body1 {
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
						}
					}

					.final-rating-select-box {
						width: 100%;
						margin-top: 3px;
						.MuiTypography-body2 {
							margin-top: 2px;
							color: $color-threshold;
						}
					}
				}
				.final-rating-one-on-one-field {
					.form-label-required {
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
						}
					}
					.MuiTypography-body2 {
						margin-top: 2px;
						color: $color-threshold;
					}
					input {
						&.form-control {
							width: 100%;
							padding: 4px 10px;
							font-size: $font-size-h5;
							line-height: $line-height2;
							border: 1.4px solid rgba($color-input-field, 0.5);
							@include border-radius(6px);
							font-family: $font-family-lato;
						}
					}
				}
				.setting-box-list {
					display: -ms-grid;
					display: grid;
					grid-gap: 10px;
					-ms-grid-columns: repeat(2, 1fr);
					grid-template-columns: repeat(2, 1fr);
					.setting-box {
						// margin-right: 10px;
						// width: 120px;
						// max-width: 120px;
						min-height: 100px;
						border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						font-size: $font-size-h5;
						font-weight: $font-weight-regular;
						color: $color-primary2;
						background-color: $color-white;
						@include transition-default;
						&.Mui-disabled {
							color: rgba($color-primary2, 0.5);
						}
						.MuiButton-label {
							@include flex;
							@include flex-direction(column);
							svg {
								width: 20px;
								height: 20px;
								margin: 5px 0 10px;
							}
						}
						&:hover {
							background-color: $color-hover;
							border: 1.4px solid rgba($color-input-field, 0.5);
							box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
						}
						&.active {
							background-color: $color-secondary2;
							border: 1.4px solid $color-primary1;
							color: $color-primary1;
							box-shadow: none;
							svg {
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				.final-rating-eligible-box {
					margin-top: 25px;
					.setting-box-list {
						margin-top: 5px;
					}
					.setting-box-field {
						margin-top: 10px;
						.MuiTextField-root {
							width: 100%;
							.MuiInputBase-root {
								background: $color-white;
							}
						}
					}
				}
				.helperText-bottom {
					.error-field {
						top: auto;
					}
					.final-rating-label,
					.form-label-required {
						color: $color-text-info;
						svg {
							path {
								fill: $color-text-info;
							}
						}
						.MuiTypography-root {
							color: $color-text-info;
						}
					}
					.final-rating-select-box {
						.dropdown-button {
							border: 1.4px solid $color-text-info;
						}
					}
					input {
						&.form-control {
							border: 1.4px solid $color-text-info;
						}
					}
					.MuiInputBase-root {
						border: 1.4px solid $color-text-info;
					}
				}
				.pie-chart {
					.highcharts-container {
						@include border-radius(6px);
						.highcharts-background {
							fill: $color-secondary1;
						}
						.highcharts-text-outline {
							stroke: transparent;
							fill: transparent;
						}
						.highcharts-data-labels {
							margin-top: 30px;
						}
					}
				}
			}
			.user-final-review-info-col2 {
				display: -ms-grid;
				display: grid;
				grid-gap: 30px;
				-ms-grid-columns: repeat(2, 1fr);
				grid-template-columns: repeat(2, 1fr);
			}
			.final-rating-justify-comment {
				margin-top: 25px;
				.justification-comment-field {
					width: 100%;
				}
			}
		}
	}
	.final-rating-comment-box {
		margin-top: 20px;
		svg {
			margin-right: 5px;
			width: 14px;
			height: 14px;
		}
		.final-rating-comment-field {
			margin-top: 3px;
			.view-only-comment {
				border-top: 1.4px solid $color-secondary2;
				padding-top: 10px;
				margin-top: 10px;
			}
		}
		&.helperText-bottom {
			.error-field {
				top: 0px;
			}
			.final-rating-label {
				color: $color-text-info;
				svg {
					path {
						fill: $color-text-info;
					}
				}
				.MuiTypography-root {
					color: $color-text-info;
				}
			}
			.final-rating-comment-field {
				.ck.ck-toolbar {
					border-color: $color-text-info !important;
				}
				.ck.ck-editor__main > .ck-content {
					border-bottom-color: $color-text-info;
					border-right-color: $color-text-info;
					border-left-color: $color-text-info;
				}
			}
		}
	}
}
.recommend-popper {
	z-index: 99;
	margin-top: 10px;
	&::before {
		content: '';
		width: 0px;
		height: 0px;
		position: absolute;
		top: -7px;
		left: 50%;
		margin-left: -8px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #ffffff;
	}
	&[x-placement~='top'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -7px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.MuiPaper-root {
		width: 400px;
		padding: 10px 15px;
		box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
	}
	.recommend-popup-content {
		ul {
			li {
				padding: 0 0 10px 0;
				display: block;
				font-size: $font-size-h6;
				color: $color-secondary1;
				line-height: $line-height5;
				span {
					margin-right: 5px;
					font-weight: $font-weight-bold;
					color: $color-primary2;
				}
				&:last-child {
					padding: 0;
					p {
						font-size: $font-size-h6;
						color: $color-secondary1;
						line-height: $line-height5;
					}
				}
			}
		}
	}
}
.add-new-program-area {
	position: relative;
	.program-cancel-btn {
		position: absolute;
		right: 5px;
		top: 5px;
		.btn-cross {
			width: 24px;
			height: 24px;
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}
	.add-new-program-row {
		display: -ms-grid;
		display: grid;
		grid-gap: 40px;
		-ms-grid-columns: repeat(2, 1fr);
		grid-template-columns: repeat(2, 1fr);
		.add-new-program-col {
			.select-role-fields {
				.select-search-dropdown {
					.react-select__control {
						min-height: 33px;
						max-height: 33px;
						&.react-select__control--menu-is-open {
							z-index: 8;
						}
					}
					.react-select__menu {
						.react-select__menu-list {
							.react-select__option {
								.search-user-result {
									@include align-items(center);
									.form-name {
										.MuiTypography-body1 {
											text-overflow: ellipsis;
											white-space: nowrap;
											overflow: hidden;
										}
									}
									.section-count {
										margin-right: 25px;
									}
									.section-count,
									.questions-count {
										@include flex;
										@include align-items(center);
										min-width: calc(50% - 25px);
										.MuiTypography-body1 {
											margin-right: 5px;
										}
										.MuiTypography-h6 {
											font-weight: normal;
										}
									}
									.preview {
										@include flex;
										@include justify-content(flex-end);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.final-rating-label {
		margin-bottom: 3px;
		@include flex;
		@include align-items(center);
		svg {
			margin-right: 5px;
			width: 14px;
			height: 14px;
		}
	}
}
