@import '../styles/sass-utils/variables';

//cfr editior
.editior-pane {
	width: 100%;
	#rteSection {
		height: auto;
		.e-richtexteditor.e-rte-tb-expand {
			border: 1.4px solid rgba($color-input-field, 0.5);
			@include default-shadow;
			@include border-radius(6px 6px 0px 6px);
			padding: 0.714em 0.714em;
			background: $color-white;
			.e-toolbar {
				@include border-radius(90px!important);
				background: $color-editor-bar;
				position: static !important;
				width: 100% !important;
				.e-toolbar-items {
					@include border-radius(90px!important);
					background: $color-editor-bar;
				}
				.e-icons {
					font-family: 'e-icons' !important;
				}
			}
			.e-rte-content {
				@include border-radius(0px 0px 6px 6px);
				border: 0;
				.e-content {
					// font-size: $font-size-h5;
					font-weight: $font-weight-regular;
					color: $color-primary2;
					//line-height: $line-height2;
					max-height: 130px;
					@include custom-scrollbar;
					h1 {
						color: $color-primary2;
					}
					.tagged-name {
						color: $color-primary1;
						cursor: pointer;
					}
					a {
						color: $color-primary1;
					}
					em {
						font-style: italic;
					}
				}
				.rte-placeholder {
					//padding: 0.714em 0 !important;
					//font-size: $font-size-h5;
					font-weight: $font-weight-regular;
					color: $color-primary2;
					line-height: $line-height2;
				}
				.e-rte-srctextarea {
					height: 130px;
					@include custom-scrollbar;
				}
			}
			.e-dialog {
				.e-img-content {
					.e-file-container {
						.e-file-name,
						.e-file-type {
							display: none;
						}
					}
					.e-linkheader,
					.imgUrl {
						visibility: hidden;
						height: 5px;
						padding: 0;
						height: 14px;
						opacity: 0;
					}
				}
				.e-btn.e-primary {
					min-width: 6em;
					@include border-radius(30px);
					color: $color-white;
					background: $color-primary1;
					padding: 0.375em 1.09375em;
					@include transition-default;
					border: 0px;
					margin-right: 5px;
					font-weight: $font-weight-regular;
					&:hover {
						background: $color-primary1;
						color: $color-white;
						@include box-shadow(6px 9px 26px rgba($color-primary1, 0.5));
					}
					&[disabled] {
						background-color: $color-hover;
						color: $color-input-field;
						&:hover {
							box-shadow: none;
						}
					}
				}

				.e-cancel,
				.e-browsebtn,
				.e-footer-content button:nth-child(2) {
					min-width: auto;
					height: 35px;
					line-height: 35px;
					color: $color-primary1;
					padding: 0 5px;
					border: 0px;
					background: none;
					@include transition-default;
					font-weight: $font-weight-regular;
					&:hover {
						background: none;
						text-decoration: underline;
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
				.e-linkheader,
				.e-dlg-header,
				.e-img-uploadwrap.e-droparea .e-rte-upload-text {
					font-weight: $font-weight-regular;
				}

				.e-input {
					border-color: rgba($color-input-field, 0.5);
					font-weight: $font-weight-regular;
					color: $color-primary2;
					&:focus {
						border-color: $color-primary1;
					}
				}

				.e-rte-label {
					label {
						font-weight: $font-weight-regular;
					}
				}
				.e-checkbox-wrapper {
					display: none;
					label {
						@include flex;
						@include align-items(center);
						.e-label {
							margin-left: 5px;
						}
					}
				}
			}
		}
		.e-dlg-overlay {
			background-color: transparent;
		}
		#customTbarDlg {
			max-height: 300px !important;
		}
	}
	.e-rte-custom-tbar-section {
		.char_block,
		#special_char {
			width: 30px;
			height: 30px;
			line-height: 30px;
			margin: 0 5px 5px 0;
			text-align: center;
			vertical-align: middle;
			border: 1px solid #dddddd;
			font-size: 20px;
			cursor: pointer;
			user-select: none;
			display: inline-block;
		}
	}
	&.ans-box {
		#rteSection {
			.e-richtexteditor {
				&.e-rte-tb-expand {
					@include border-radius(6px);
				}
			}
		}
	}
}

//tagged list
.tribute-container {
	position: absolute;
	top: 0;
	left: 0;
	height: auto;
	max-height: 222px;
	max-width: 305px;
	min-width: 305px;
	overflow: auto;
	display: block;
	z-index: 999999;
	background: $color-white;
	@include default-shadow;
	@include custom-scrollbar;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			padding: 0.625em 0.8125em;
			cursor: pointer;
			font-size: $font-size-h4;
			font-weight: $font-weight-regular;
			line-height: $line-height2;
			@include flex;
			.avatar-default {
				width: 30px;
				height: 30px;
				background: $color-bg-info;
				color: $color-tags;
				font-size: $font-size-h5;
				line-height: 30px;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				@include border-radius(50%);
				margin-right: 0.8125em;
				margin-top: 3px;
				img {
					color: transparent;
					width: 100%;
					height: 100%;
					object-fit: cover;
					text-align: center;
					@include border-radius(50%);
				}
			}
			.search-user-info {
				width: calc(100% - 50px);
				.email-text {
					color: $color-secondary1;
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-regular;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			&.highlight {
				background: $color-hover;
			}
			span {
				font-weight: $font-weight-bold;
			}
			&.no-match {
				cursor: default;
			}
			.menu-highlighted {
				font-weight: $font-weight-bold;
			}
		}
	}
}

/**ck editor tagging list css start here**/
.ck.ck-mentions {
	height: auto;
	max-height: 230px;
	max-width: 305px;
	min-width: 305px;
	overflow: auto;
	display: block;
	z-index: 9999;
	background: $color-white;
	@include custom-scrollbar;
	li {
		cursor: pointer !important;
	}
	li .ck-button {
		padding: 0.625em 0.8125em;
		cursor: pointer;
		font-size: $font-size-h4;
		font-weight: $font-weight-regular;
		line-height: $line-height2;
		@include flex;
		&:hover {
			background: $color-hover !important;
		}
		&.ck-on {
			background: $color-hover !important;
		}
		.avatar-default {
			width: 30px;
			height: 30px;
			background: $color-bg-info;
			color: $color-tags;
			font-size: $font-size-h6;
			line-height: 30px;
			font-weight: $font-weight-bold;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			@include border-radius(50%);
			margin-right: 0.8125em;
			margin-top: 3px;
			cursor: pointer;
			font-family: $font-family-lato;
			img {
				color: transparent;
				width: 100%;
				height: 100%;
				object-fit: cover;
				text-align: center;
				@include border-radius(50%);
			}
		}

		.search-user-info {
			width: calc(100% - 50px);
			cursor: pointer;
			p {
				font-size: $font-size-h4;
				//font-weight: $font-weight-bold;
				line-height: $line-height2;
				color: $color-primary2;
				font-family: $font-family-lato;
				padding: 0;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				width: 100%;
				display: block;
				cursor: pointer;
			}
			.email-text {
				color: $color-secondary1;
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-regular;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				width: 100%;
				display: block;
				cursor: pointer;
			}
		}

		&.no-match {
			cursor: default;
		}
		.menu-highlighted {
			font-weight: $font-weight-bold;
		}
	}
}

/**editor design css start here**/
.ck {
	&.ck-editor {
		//@include default-shadow;
		@include border-radius(6px);
	}
	&.ck-toolbar {
		border-width: 1.4px !important;
		border-color: rgba($color-input-field, 0.5) !important;
		border-bottom: 0px !important;
		@include border-radius(6px 6px 0 0!important);
	}
	&.ck-editor__main {
		> .ck-content {
			border: 1.4px solid rgba($color-input-field, 0.5);
			max-height: 150px;
			min-height: 130px;
			@include custom-scrollbar;
			@include border-radius(0px 0px 6px 6px!important);

			ul,
			ol {
				margin-left: 40px;
			}
			i {
				font-style: italic;
			}
			.mention {
				background: none;
				color: $color-primary1;
			}
			a {
				color: $color-primary1;
			}
		}
		.ck.ck-content.ck-focused {
			border: 1.4px solid rgba($color-primary1, 1);
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		.ck-placeholder {
			font-size: $font-size-h5;
			color: rgba($color-primary2, 0.5);
		}
	}

	&.ck-button.ck-on {
		color: $color-primary1 !important;
		background: $color-secondary2 !important;
		box-shadow: none !important;
	}
	&.ck-balloon-panel {
		@include box-shadow(2px 2px 10px rgba($color-primary2, 0.2) !important);
		border-color: rgba($color-input-field, 0.4) !important;
		z-index: 9999 !important;
		.ck-button.ck-link-actions__preview {
			.ck-button__label {
				color: $color-primary1 !important;
			}
		}
	}
	.ck-special-characters-navigation {
		.ck-dropdown {
			display: none;
		}
	}
}

/**editor line css **/
.editor-fifteen-line {
	.ck {
		&.ck-editor__main {
			> .ck-content {
				max-height: 362px !important;
			}
		}
	}
}
.editor-five-line {
	.ck {
		&.ck-editor__main {
			> .ck-content {
				max-height: 138px !important;
			}
		}
	}
}
