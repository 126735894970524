@import '../../../../sass-utils/variables';

.default-okr-list-head {
	margin-bottom: 10px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	.default-okr-list-head-left,
	.default-okr-list-head-right {
		@include flex;
		@include align-items(center);
	}
	.default-okr-list-head-left {
		.MuiTypography-h6 {
			text-transform: uppercase;
			margin-right: 20px;
		}
		.default-okrs-info-mssg {
			@include flex;
			@include align-items(center);
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
			.MuiTypography-body2 {
				color: $color-text-info;
			}
		}
		.expand-collapse-all {
			margin-left: -24px;
			.expand-collapse-btn {
				padding: 5px;
				color: $color-bg-filterbtn;
				@include border-radius(24px);
				svg {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
}
.default-okr-listing {
	ul {
		@include flex;
		@include justify-content(space-between);
		@include align-items(initial);
		li {
			display: block;
			padding: 0;
			&.default-okr-col1 {
				width: 55%;
				min-width: 55%;
			}
			&.default-okr-col2 {
				width: 45%;
				min-width: 45%;
				.default-assign {
					width: 100%;
					.list-assign-role {
						.default-chip-avatar {
							max-width: 95px;
							//min-width: 95px;
						}
					}
				}
			}
		}
	}
	.default-okr-list-panel {
		margin-bottom: 10px;
		position: relative;
		.default-okr-list-panel-inner {
			position: relative;
			@include transition-default;
			background-color: $color-white;
			@include border-radius(6px);
			@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
			border: 1.4px solid $color-border-tile;
			.default-obj-list-panel {
				padding: 6px 20px 18px 20px;
				position: relative;
				.default-okr-col1 {
					.default-okr-name {
						padding-right: 60px;
						margin-bottom: 12px;
						.MuiTypography-body1 {
							font-weight: $font-weight-bold;
						}
					}
				}
			}
			.default-kr-list-panel {
				padding: 0 10px 10px 20px;
				@include transition-default;
				@include animation(slide-down 1s);
				position: relative;
				.default-kr-list-inner {
					min-height: 88px;
					padding-top: 7px;
					padding-bottom: 13px;
					padding-left: 20px;
					padding-right: 20px;
					margin-bottom: 10px;
					background: $color-bg-form;
					@include border-radius(6px);
					.default-kr-name {
						margin-bottom: 10px;
					}
					.set-timeline-area {
						margin-left: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.default-okr-status-info {
			@include flex;
			@include align-items(center);
			.MuiChip-root {
				margin-right: 3px;
				height: auto;
				padding: 0 7px;
				.MuiChip-label {
					font-size: $font-size-subtitle2 !important;
					font-weight: $font-weight-bold;
					color: $color-tags;
					line-height: $line-height5;
					text-transform: uppercase;
				}
				&.published-chip {
					background: $color-bg-tag;
					border: 1px solid $color-bg-tag;
					.MuiChip-label {
						color: rgba($color-primary2, 0.7);
					}
				}
				&.published-updated-chip,
				&.unpublished-chip {
					background: $color-okr-highlighter;
					border: 1px solid $color-border-info;
					.MuiChip-label {
						color: $color-tags;
					}
				}
			}

			.okr-nature-type-tag {
				.MuiTypography-subtitle2 {
					line-height: 16px;
					padding: 0 4px;
					@include border-radius(16px);
				}
			}
		}
		.default-okr-actions {
			@include inline-flex;
			@include align-items(center);
			button {
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&.btn-blue-chip {
					color: $color-primary1;
					background: $color-border-tile;
					@include border-radius(30px);
					svg {
						@include transition-rotate;
						margin-right: 4px;
						width: 10px;
						height: 10px;
						path {
							fill: $color-primary1;
							stroke: $color-primary1;
						}
					}
					&:hover {
						@include box-shadow(6px 9px 26px $color-border-tile);
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
			.default-okr-field-actions {
				@include flex;
				@include align-items(center);
				button {
					padding: 0;
					margin-left: 15px;
					&:hover {
						background: transparent;
					}
				}
			}
		}
	}

	.no-record-message {
		padding-top: 2em;
	}
	&.highlight-default-row {
		.default-okr-list-panel-inner {
			background-color: $color-okr-highlighter !important;
			border: 2px solid $color-border-info;
		}
	}
}
