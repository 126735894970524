@import '../../../../sass-utils/variables';

//create new Default OKR form css
.create-default-okr-card {
	.create-default-okr-card-inner {
		margin-bottom: 20px;
		position: relative;
		@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
		@include border-radius(6px);
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		.create-default-obj {
			min-height: 80px;
			padding: 10px 10px 10px 20px;
			position: relative;
		}
		.create-default-kr {
			padding: 0 10px 10px 20px;
			@include transition-default;
			@include animation(slide-down 1s);
			position: relative;
			.create-default-kr-inner {
				min-height: 88px;
				padding: 13px 20px 13px 32px;
				background: $color-bg-form;
				@include border-radius(6px);
				.create-default-kr-head {
					position: relative;
					.kr-expand-collapse-btn {
						padding: 0;
						position: absolute;
						left: -24px;
						top: 14px;
						svg {
							width: 10px;
							height: 10px;
							path {
								stroke: $color-primary2;
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
						&.active {
							svg {
								@include transform(rotate(90deg));
							}
						}
					}
				}
				.create-default-kr-content {
					margin-top: 20px;
					padding-top: 20px;
					border-top: 1.4px solid $color-border-tile;
					.assign-role-settings {
						.assign-role-settings-head {
							@include flex;
							@include justify-content(space-between);
							.MuiTypography-h6 {
								text-transform: uppercase;
							}
							.user-search {
								.MuiInputBase-root {
									background-color: $color-white;
									.MuiInputBase-input {
										padding: 6px 0;
									}
								}
								.user-search-chip {
									z-index: 99;
								}
							}
						}
					}
				}
			}
		}
		.add-objective,
		.add-kr {
			width: 100%;
			.MuiInputBase-root {
				margin-left: -10px;
				padding: 5px 10px;
				border: 1.4px solid transparent;
				textarea {
					padding: 0;
					font-size: $font-size-base;
					line-height: $line-height2;
					font-weight: $font-weight-bold !important;
				}
				&:hover {
					border: 1.4px solid rgba($color-input-field, 0.5);
				}
				&.Mui-focused {
					border: 1.4px solid $color-primary1;
					@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
				}
				&.Mui-error {
					border: 1.4px solid $color-text-info;
				}
			}
			&.helperText-bottom {
				.MuiFormHelperText-root {
					&.Mui-error {
						top: 34px;
					}
				}
			}
		}
		.add-objective {
			padding-right: 20px;
		}
		.add-kr {
			.MuiInputBase-root {
				textarea {
					font-weight: $font-weight-regular !important;
				}
			}
		}
		.default-okr-field-actions {
			position: relative;
			@include flex;
			@include align-items(center);
			@include transition-default;
			margin-top: 10px;
			button {
				padding: 0;
				min-width: auto;
				@include animation(fade-in 1.1s);
				margin-right: 15px;
				svg {
					width: 14px;
					height: 14px;
					path,
					circle,
					rect {
						fill: $color-primary1;
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					background-color: transparent;
				}
			}
			.MuiButton-text {
				font-size: $font-size-h6;
				color: $color-primary1;
				font-weight: $font-weight-bold;
				line-height: 14px;
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
			.MuiButton-text.settings-btn-disable {
				color: $color-input-field;
				cursor: default;
				svg {
					path {
						fill: $color-input-field;
					}
				}
			}
			.MuiButton-text.settings-btn-error {
				color: $color-text-info;
				.icon {
					@include animation(rotation 2s linear infinite);
					//animation-iteration-count: infinite;
					margin-right: 5px;
					svg {
						margin-right: 0px;
						path {
							fill: $color-text-info;
						}
					}
				}
			}
			.set-timeline-area {
				margin-left: 0;
				margin-right: 15px;
				.timeline-field {
					.calendar-btn {
						button {
							margin-right: 0;
							line-height: 1;
						}
					}
				}
			}
		}
	}
	.create-deafult-okr-actions {
		margin-bottom: 20px;
		visibility: visible;
		opacity: 1;
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		.btn-primary {
			padding: 6px 23px;
			margin-left: 0;
		}
		.btn-link {
			margin-right: 30px;
		}
	}
}
/*Default KR Attributes panel CSS*/
.default-kr-attribute-panel {
	.drawer-area {
		.drawer-content {
			.drawer-inner-content {
				.drawer-tabs-panel {
					padding: 0 !important;
					.drawer-form-fields {
						.drawer-input-field {
							input {
								&.form-control {
									width: 305px;
								}
							}
							.cycle-and-remaining-days {
								max-width: 305px;
							}
							&.default-kr-measure-unit {
								padding-top: 30px;
								padding-bottom: 30px;
								background: $color-bg-form;
							}
							&.allow-users-switch {
								@include flex;
								@include align-items(center);
								padding-top: 30px;
								padding-bottom: 30px;
								.allow-users-info-btn {
									padding: 0;
									margin-left: 10px;
									svg {
										width: 14px;
										height: 14px;
										path {
											fill: $color-primary1;
										}
									}
									.MuiTouchRipple-root {
										display: none;
									}
								}
							}
						}
					}
					&.default-kr-attributes-tabs-panel {
						max-height: calc(100vh - 300px);
						min-height: calc(100vh - 300px);
					}
				}
			}
		}
	}
	.default-kr-name-field {
		margin-bottom: 7px;
		@include flex;
		@include align-items(flex-start);
		svg {
			margin-right: 15px;
		}
		.MuiInputBase-root {
			padding: 5px 7px;
			margin-left: -7px;
			margin-top: -7px;
			border: 1.4px solid transparent;
			.MuiInputBase-input {
				font-size: $font-size-base;
				font-weight: $font-weight-bold;
				line-height: $line-height2;
			}
			&:hover {
				border: 1.4px solid rgba($color-input-field, 0.5);
			}
			&.Mui-focused {
				border: 1.4px solid $color-primary1;
			}
		}
	}
	.default-kr-panel-head {
		padding: 0 60px;
		position: relative;
		margin-bottom: 40px;
		&::after {
			content: '';
			position: absolute;
			left: 0px;
			bottom: 0px;
			height: 1.4px;
			background: rgba($color-primary2, 0.2);
			opacity: 0.5;
			width: 100%;
		}
	}
}
.okr-list-panel-head,
.assign-role-panel-content {
	ul {
		li {
			&.assign-role-col1 {
				width: 30%;
				padding-left: 0;
			}
			&.assign-role-col2 {
				width: 26%;
			}
			&.assign-role-col3 {
				width: 16%;
			}
			&.assign-role-col4 {
				width: 28%;
			}
		}
	}
}
.assign-role-panel-content {
	ul {
		@include flex;
		li {
			padding-top: 0;
			padding-bottom: 0;
			&.assign-role-col1 {
				.user-info-tab {
					@include align-items(initial);
				}
				.user-img {
					margin-right: 5px;
					.MuiAvatar-root {
						margin-top: 0;
					}
				}
				.user-info-details {
					@include flex;
					@include align-items(center);
				}
				&.deleted-user-row {
					//background: $color-bg-teamstag;
					.user-info {
						.user-img {
							.MuiAvatar-root {
								background: $color-avatar-disable;
								color: rgba($color-primary2, 0.5);
								img {
									filter: grayscale(100%);
									-webkit-filter: grayscale(100%);
									opacity: 0.7;
								}
							}
						}
						.user-info-details {
							.MuiTypography-body1,
							.MuiTypography-h4 {
								opacity: 0.5;
							}
							.delete-chip {
								height: auto;
								padding: 0 7px;
								background: $color-okr-highlighter;
								border: 1px solid #f3c3c3;
								margin-top: 7px;
								min-width: 60px;
								.MuiChip-label {
									color: $color-tags-new;
									line-height: $line-height5;
								}
							}
						}
					}
				}
			}
			&.assign-role-col3 {
				.weight-field {
					position: relative;
					width: 38px;
					min-height: 45px;
					@include flex;
					@include align-items(center);
					.weight-chip {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						min-width: 38px;
						height: 24px;
						margin: 0;
						font-size: $font-size-h5;
						line-height: $line-height3;
						color: $color-primary1;
						border: 1.4px solid $color-primary1;
						@include border-radius(100px);
						text-align: center;
						background-color: $color-secondary2;
						&.Mui-disabled {
							background: $color-bg-teamstag;
							border-color: rgba($color-input-field, 0.5);
							color: rgba($color-primary2, 0.5);
							box-shadow: none;
						}
					}
				}
				.switch-button {
					margin-bottom: 15px;
				}
			}
			&.assign-role-col4 {
				.kr-metrics-fileds {
					.kr-metrics-fileds-inner {
						min-height: 45px;
						@include align-items(center);
						.matrics-field-seprator {
							margin: 0 7px;
						}
						.MuiFormHelperText-root {
							&.Mui-error {
								min-width: 175px;
								z-index: 9;
								bottom: auto;
								top: 100%;
							}
						}
					}
					.MuiInputBase-root {
						background: $color-white;
						.MuiInput-input {
							color: $color-primary2;
							background: $color-white;
							&.Mui-disabled {
								background: $color-bg-teamstag;
								border-color: rgba($color-input-field, 0.5);
								color: rgba($color-primary2, 0.5);
								box-shadow: none;
							}
						}
						&.Mui-disabled {
							background: $color-bg-teamstag;
							svg {
								path {
									fill: rgba($color-primary2, 0.5);
								}
							}
						}
					}
				}
				.switch-button {
					margin-bottom: 15px;
				}
			}
		}
	}
}
.align-setting-tab-button {
	.MuiTab-root {
		max-width: 100%;
		text-transform: capitalize !important;
	}
}
.default-okr-tabs-dropdown {
	margin: 0px !important;
	max-width: 280px !important;
	.MuiPaper-root {
		margin-top: 0px;
		&::before,
		&::after {
			right: 1px !important;
		}
	}
	.cycle-dropdown {
		width: 100%;
		.tenure-list {
			position: static;
			width: 100%;
			box-shadow: none;
			background: none;
		}
	}
	.past-cycle-link {
		padding: 0 14px;
		@include flex;
		align-items: center;
		min-height: 48px;
		cursor: pointer;
		font-size: $font-size-h5;
		line-height: $line-height4;

		svg {
			max-width: 16px;
			margin-right: 9px;
		}
		&:hover {
			background: $color-hover;
			@include transition(all ease-in-out 0.25s);
		}
	}
	.tenure-list {
		.back-to-main {
			padding-left: 14px;
			padding-right: 14px;
		}
	}
}

.weightage-dropdown {
	position: relative;
	margin-right: 0 !important;
	padding: 0px;
	min-width: 52px;
	max-width: 52px;
	@include border-radius(6px);
	.MuiPaper-root {
		margin-top: 12px;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 38px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
			@include border-radius(3px);
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
	}

	.MuiList-root {
		.MuiListItem-root {
			padding-top: 7px;
			padding-bottom: 7px;
			border-bottom: 1.4px solid $color-hover;
			cursor: pointer;
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				line-height: $line-height3;
				text-align: center;
			}
			&:hover {
				background-color: $color-hover;
			}
			&.active {
				background-color: $color-secondary2;
			}
		}
	}
}
