@import '../../../../sass-utils/variables';

.create-goal-form {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			@include justify-content(flex-start);
			.back-to-main {
				margin-right: 15px;
				padding-bottom: 0;
			}
			.MuiTypography-h2 {
				text-transform: capitalize;
			}
		}
	}
	/* Create Goal Form Card */
	.create-goal-form-card {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.add-goal-form-wrapper {
			width: 449px;
			position: relative;
			.add-goal-form-field-edit {
				padding: 4px 10px;
				border: 1.4px solid transparent;
				@include border-radius(6px);
				@include flex;
				width: 100%;
				cursor: pointer;
				&:hover {
					border: 1.4px solid rgba($color-input-field, 0.5);
				}
			}
			.add-goal-form-field {
				position: relative;
				@include animation(fade-in 1.1s);
				.MuiInputBase-root {
					.MuiInputBase-input {
						font-size: $font-size-h4;
						font-weight: $font-weight-bold;
					}
					&.Mui-focused {
						@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
					}
				}
				.add-goal-form-actions {
					@include flex;
					@include justify-content(flex-end);
					button {
						padding-top: 4px;
						padding-bottom: 4px;
						min-height: 26px;
						&:hover {
							background-color: transparent;
						}
						svg {
							width: 14px;
							height: 14px;
						}
						&.add-goal-save-btn {
							padding-left: 12px;
							padding-right: 12px;
							@include border-radius(0 0 6px 6px);
							background-color: $color-primary1;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-white;
								}
							}
						}
						&.add-goal-cancel-btn {
							svg {
								path {
									fill: $color-primary1;
									stroke: $color-primary1;
								}
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				&.helperText-bottom {
					.MuiFormHelperText-root {
						top: 32px;
					}
				}
			}
		}
		.weightage-toggle-wrapper {
			@include flex;
			margin-right: 10px;
			.weightage-switch {
				margin-left: 30px;
			}
		}
	}
	/* Create category and goal accordion  */
	.create-cat-goal-list-row {
		padding: 0;
		position: relative;
		&.expand-row {
			@include box-shadow(0px 10px 15px 0px rgba($color-primary2, 0.3));
			.create-category-head {
				.rotating-arrow {
					svg {
						@include transform(rotate(90deg));
					}
				}
			}
		}
		&.show-error {
			border-color: $color-text-info;
		}
		.create-category-head {
			position: relative;
			padding: 20px 20px 20px;
			cursor: pointer;
			@include flex;
			@include justify-content(space-between);
			.rotating-arrow {
				position: absolute;
				left: 5px;
				top: 20px;
				width: 10px;
				height: 10px;
				@include transition(0.5s);
				svg {
					@include transition(0.5s);
					width: 10px;
					height: 10px;
					display: block;
					path {
						fill: $color-primary2;
						stroke: $color-primary2;
					}
				}
			}
			.section-count {
				line-height: 10px;
				color: rgba($color-threshold, 0.7);
				&.disable-state {
					opacity: 0.5;
				}
			}
		}
		.create-category-title {
			width: 48%;
			.section-title-wrap {
				@include flex;
				@include justify-content(space-between);
				.title-read-mode {
					width: 100%;
				}
				.MuiButtonBase-root {
					margin-top: 4px;
				}
				.error-top-pos {
					.MuiFormHelperText-root {
						min-width: 190px;
					}
				}
				.input-label-pad {
					margin-top: 5px;
				}
				.helperText-bottom {
					.MuiFormHelperText-root {
						top: 32px;
					}
				}
			}
			.title-edit-mode {
				width: 100%;
				.MuiTextField-root {
					width: 100%;
				}
				.MuiInput-input {
					font-weight: normal;
				}
			}
		}
		.weightage-wrapper {
			padding-right: 20px;
			width: calc(33.333% - 8px);
			@include flex;
			@include justify-content(space-between);
			.weightage-field {
				.helperText-bottom {
					.MuiFormHelperText-root {
						z-index: 1;
					}
				}
			}
			.editIcon {
				margin-top: 28px;
			}
		}
		.weightage-field {
			width: calc(100% - 45px);
			.MuiFormLabel-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
			&.custom-error-field {
				.MuiFormHelperText-root {
					bottom: auto;
					top: 55px;
				}
			}
		}
		.create-goal-content {
			.create-goal-content-inner {
				padding: 0px 20px 20px;
				&.disable-state {
					.create-goal-row {
						opacity: 0.5;
						mix-blend-mode: luminosity;
						pointer-events: none;
						.goal-info {
							.editIcon {
								svg {
									path {
										fill: rgba($color-primary2, 0.7);
									}
								}
							}
						}
					}
				}
			}
		}
		.section-actions {
			margin-top: 20px;
			.btn-text {
				margin-left: 14px;
				color: $color-primary1;
				font-size: $font-size-base;
			}
			&.disable-state {
				opacity: 0.5;
				mix-blend-mode: luminosity;
				.btn-add-key {
					color: rgba($color-primary2, 0.8);
					pointer-events: none;
					.MuiButton-label {
						span {
							background: rgba($color-primary2, 0.8);
						}
					}
				}
				.btn-text {
					pointer-events: none;
					color: rgba($color-primary2, 0.8);
				}
			}
		}

		/**Goal row css start here**/
		.create-goal-row {
			background: $color-bg-form;
			padding: 20px;
			margin-bottom: 10px;
			position: relative;
			@include border-radius(6px);
			&:last-child {
				margin-bottom: 0px;
			}
			.MuiFormLabel-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
					vertical-align: middle;
				}
			}
			.goal-info {
				@include flex;
				@include justify-content(space-between);

				.col-1 {
					width: calc(66.666% - 15px);
				}
				.col-2 {
					width: calc(33.333% - 15px);
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.editIcon {
						margin-top: 28px;
					}
				}
				.MuiFormControl-root {
					width: 100%;
				}
				.MuiInputBase-root {
					width: 100%;
					background: $color-white;
				}
				.error-text-field {
					.MuiFormLabel-root {
						color: $color-text-info;
						svg {
							path {
								fill: $color-text-info;
							}
						}
					}
					.okr-team-dropdown {
						.dropdown-button {
							border-color: $color-text-info;
						}
					}
				}
			}
			.goal-response-type {
				margin-top: 20px;
			}
			.goal-reponse-description {
				margin-top: 20px;
				.MuiFormLabel-root {
					&.Mui-disabled {
						svg {
							path {
								fill: $color-primary2;
							}
						}
					}
				}
			}
			.response-action {
				margin-top: 20px;
				@include flex;
				.col {
					margin-right: 30px;
					.MuiGrid-container {
						@include align-items(center);
						.MuiGrid-item {
							font-size: $font-size-h5;
							line-height: $line-height4;
							&:last-child {
								padding-left: 5px;
							}
						}
					}
					.MuiSwitch-root {
						.MuiSwitch-switchBase {
							padding: 3px;
							.MuiSwitch-thumb {
								width: 8px;
								height: 8px;
							}
							&.Mui-checked + .MuiSwitch-track {
								background: $color-completed;
							}
						}
						.MuiSwitch-track {
							background: rgba($color-primary2, 0.5);
						}
					}
					&.disable-radio {
						.MuiGrid-item {
							opacity: 0.5;
						}
						.MuiSwitch-root {
							.MuiSwitch-track {
								background: $color-primary2;
							}
						}
					}
				}
			}
		}
	}
	.create-goal-form-actions {
		margin-bottom: 20px;
		visibility: visible;
		opacity: 1;
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		.btn-primary {
			padding: 6px 23px;
			margin-left: 0;
		}
		.btn-link {
			margin-right: 30px;
		}
	}
}
.okr-team-dropdown.response-type-dropdown {
	min-width: 297px;
	max-width: 297px;
	.dropdown-button {
		width: 297px;
		padding: 4px 10px;
		line-height: $line-height2;
		background: $color-white;
		span.MuiButton-label {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
			display: inline-block;
			padding-right: 12px;
			text-align: left;
			.icon {
				position: absolute;
				right: 7px;
				top: 50%;
				margin-top: -3px;
			}
		}
	}
	.MuiList-root {
		border-top: 1.4px solid $color-hover;
		.MuiListItem-root {
			border-bottom: 1px solid rgba($color-input-field, 0.2);
			padding: 8px 7px;
			max-height: 215px;
			svg {
				width: 14px;
				height: 14px;
				margin-right: 10px;
			}
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				line-height: $line-height4;
			}
		}
	}
	.team-dropdown {
		padding-top: 30px;
	}
	&.visibility-type-dropdown {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		.dropdown-button {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
			padding: 4px 10px;
			line-height: $line-height2;
			.text-content {
				display: inline-block;
				text-align: left;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 100%;
			}
		}
		.team-dropdown {
			padding-top: 38px;
			.MuiList-root {
				.MuiListItem-root {
					padding: 10px 14px;
				}
			}
		}
	}
}

/* Goal forms listing CSS*/
.goal-forms-listing {
	.okr-list-panel-head,
	.goal-forms-list-content {
		ul {
			li {
				&.goal-forms-col1 {
					width: 28%;
				}
				&.goal-forms-col2 {
					width: 12%;
				}
				&.goal-forms-col3 {
					width: 15%;
				}
				&.goal-forms-col4 {
					width: 15%;
				}
				&.goal-forms-col5 {
					width: 15%;
				}
				&.goal-forms-col6 {
					width: 15%;
				}
			}
		}
	}
	.goal-forms-list-content {
		.goal-forms-chip {
			&.MuiChip-root {
				padding: 0 7px;
				color: $color-primary2;
				line-height: 1;
				border: 1px solid $color-bg-tag;
				&.draft-chip {
					color: $color-tags;
					border: 1px solid $color-tags;
					background-color: $color-okr-highlighter;
				}
			}
		}
		&.heighlight-row {
			background-color: $color-okr-highlighter !important;
		}
	}
	.goal-forms-list-content {
		min-height: 65px;
		position: relative;
		padding: 15px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			padding: 0;
			li {
				padding: 0 12px 0 20px;
				flex: auto;
				&.goal-forms-col1 {
					@include flex;
					@include justify-content(space-between);
					.goal-forms-list-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				&.goal-forms-col5,
				&.goal-forms-col6 {
					.MuiTypography-h4 {
						font-weight: $font-weight-regular;
					}
					.MuiTypography-body2 {
						color: $color-secondary1;
						font-weight: $font-weight-regular;
						line-height: $line-height2;
					}
					svg {
						path {
							stroke: $color-primary2;
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			li {
				&.goal-forms-col1 {
					.goal-forms-list-actions {
						button {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
.goal-form-ticker {
	margin-bottom: 20px;
	padding: 10px 25px;
	width: 100%;
	@include flex;
	@include align-items(flex-start);
	@include border-radius(6px);
	border: 1.4px solid $color-border-tile;
	background: $color-bg-form;
	svg {
		width: 14px;
		height: 14px;
		min-width: 14px;
		margin-right: 10px;
		margin-top: 3px;
		path {
			fill: $color-white;
		}
	}
	.MuiTypography-body1 {
		font-size: $font-size-h5;
		line-height: $line-height3;
	}
	ul {
		li {
			display: block;
			position: relative;
			padding: 0 0 10px 15px;
			&:after {
				position: absolute;
				left: 0;
				top: 8px;
				content: '';
				width: 4px;
				height: 4px;
				@include border-radius(4px);
				background: $color-primary2;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
