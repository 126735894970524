@import '../../sass-utils/variables';
.contributors-list-panel {
	.group-target-switch {
		padding: 0px 15px 10px 28px;
	}
	.kr-contributor-listing {
		.kr-contributor-list-head {
			.MuiList-root {
				.user-col1 {
					width: 40%;
					padding: 0 0 0 1em;
				}
				.user-col2 {
					width: 40%;
				}
				.user-col3 {
					width: 20%;
				}
				.user-col4 {
					.kebab-icon {
						padding: 0;
						width: $font-size-h5;
						height: $font-size-h5;
						margin: 0 0 0 0.375em;
						svg {
							width: 100%;
							height: 100%;
						}
						.MuiTouchRipple-root {
							display: none;
						}
						&:hover {
							background: none;
						}
					}
				}
			}
		}
		.kr-contributor-list-row {
			@include transition-default;
			.MuiAvatar-root {
				box-shadow: none;
			}
			.MuiList-root {
				.user-col1 {
					width: 40%;
					padding: 0 0 0 1em;
					.MuiBadge-root.okr-badge-icon {
						right: auto;
						left: 33px;
					}
				}
				.user-col2 {
					width: 40%;
				}
				.user-col3 {
					width: 20%;
					@include justify-content(space-between!important);
					.delete-btn {
						@include border-radius(100%);
						padding: 0px;
						opacity: 0;
						margin-top: 4px;
						@include transition-default;
						svg {
							width: 16px;
							height: 16px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							background: none;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
			}
			.contributor-expand-collapse-view {
				.user-info {
					overflow: hidden;
					.MuiTypography-h4 {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.btn-wrap {
					@include transition-default;
					opacity: 0;
					@include flex;
					@include flex-direction(column);
					button {
						padding: 0;
						width: auto;
						height: auto;
						&.edit-btn {
							margin-bottom: 1.25em;
						}
						&:hover {
							background: none;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				.contributors {
					.contributors-avatar-group {
						cursor: default;
					}
				}
				&.disabled {
					.user-info,
					.date {
						.MuiTypography-h4,
						.MuiTypography-subtitle2,
						p,
						h6,
						.MuiTypography-h6,
						.MuiTypography-body1 {
							color: rgba($color-primary2, 0.6);
						}
						.teams-chips {
							opacity: 0.6;
						}
					}
					.matrics-field-seprator {
						color: rgba($color-primary2, 0.6);
					}
					.MuiAvatar-root {
						background: $color-border-krscore;
						color: rgba($color-primary2, 0.9);
						&::before {
							content: '';
							position: absolute;
							top: 0px;
							left: 0px;
							background: rgba($color-border-krscore, 0.5);
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			&:hover {
				.contributor-expand-collapse-view {
					.btn-wrap {
						opacity: 1;
						svg {
							path {
								fill: $color-primary1;
							}
						}
					}
				}
				.user-col3 {
					.delete-btn {
						opacity: 1;
					}
				}
			}
		}

		.manage-contributor-metric-field {
			.kr-metrics-fileds {
				.MuiInputBase-root {
					padding-left: 0.5em !important;
					padding-right: 0.5em !important;
				}
				.matrics-field-seprator {
					margin: 0 0.5em;
				}
				.kr-metrics-fileds-inner {
					.MuiFormHelperText-root {
						&.Mui-error {
							padding: 0.417em 0.5em !important;
							min-width: 220px;
							top: 100%;
							bottom: auto;
							margin-top: -5px;
							left: -55px;
							line-height: 14px;
							z-index: 9;
							svg {
								path {
									fill: $color-text-info !important;
									stroke: none !important;
								}
							}
						}
					}
				}
			}
		}
		&.group-contributor-listing {
			.kr-contributor-list-head,
			.kr-contributor-list-content {
				.MuiList-root {
					.user-col1 {
						width: 80%;
					}
					.user-col3 {
						width: 20%;
					}
				}
			}
		}
	}
}
/**drawer okr info list **/
.okr-info-list {
	form {
		padding: 0 1.25em;
		.field-row {
			padding-bottom: 1.875em;
		}
	}
}

/**target-assignment-msg**/
.target-assignment-msg {
	text-align: right;
	margin-bottom: 10px;
	margin-top: -10px;
	.under-assigned {
		color: $color-text-info;
	}
	.over-assigned {
		color: $color-completed;
	}
}

/**lets assign contributor **/
.contributor-my-self {
	@include flex;
	@include align-items(center);
	.owner-tag {
		@include flex;
		@include align-items(center);
		margin-left: 5px;
	}
}
