@import '../../../../sass-utils/variables';

.create-goal-review-area {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			@include justify-content(flex-start);
			.back-to-main {
				margin-right: 15px;
				padding-bottom: 0;
			}
			.MuiTypography-h2 {
				text-transform: capitalize;
			}
		}
	}
	.create-review-form-card {
		.settings-card-row {
			margin-bottom: 20px;
			width: 100%;
			@include flex;
			@include justify-content(space-between);
			&.settings-card-row-3 {
				.settings-card-col {
					width: calc(33.333% - 15px);
					max-width: calc(33.333% - 15px);
					.MuiFormLabel-root {
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
					}
					.setting-duration-field {
						.calendar-start-end-fields {
							@include flex;
							@include justify-content(space-between);
							input {
								&.form-control {
									width: calc(50% - 10px);
									padding: 4px 10px;
									font-size: $font-size-h5;
									line-height: $line-height2;
									border: 1.4px solid rgba($color-input-field, 0.5);
									@include border-radius(6px);
									font-family: $font-family-lato;
								}
								&[disabled] {
									opacity: 0.5;
									cursor: default;
									background: rgba($color-primary2, 0.1);
								}
							}
							.error-field {
								width: 220px;
							}
						}
					}
					.review-score-radio {
						.review-score-group-box {
							@include flex;
							@include flex-direction(row);
							@include align-items(center);
							.MuiFormLabel-root {
								padding: 0;
							}
							.MuiFormGroup-root {
								@include flex-direction(row);
							}
						}
						label {
							margin: 0 10px 0 0;
							.MuiButtonBase-root {
								display: none;
								&.Mui-checked {
									& + .MuiFormControlLabel-label {
										background: $color-secondary2;
										border-color: $color-primary1;
										color: $color-primary1;
									}
								}
							}
							.MuiFormControlLabel-label {
								font-size: $font-size-h6;
								line-height: $line-height5;
								color: $color-primary2;
								font-weight: $font-weight-bold;
								border: 1.4px solid rgba($color-input-field, 0.5);
								@include border-radius(100px);
								min-width: 38px;
								padding: 3px 16px;
								margin: 0;
								text-align: center;
								background-color: $color-white;
								&.Mui-disabled {
									border-color: rgba($color-input-field, 0.5);
									color: rgba($color-primary2, 0.5);
									box-shadow: none;
								}
							}
							&.Mui-disabled {
								opacity: 1 !important;
							}
						}
						.other-score {
							.MuiFormControl-root {
								.MuiInputBase-root {
									max-width: 73px;
									padding: 6px 10px;
								}
							}
							.MuiFormHelperText-root {
								top: 0px !important;
								min-width: 274px;
							}
						}
						&.custom-error-field {
							.MuiFormControlLabel-label {
								border: 1.4px solid $color-text-info;
							}
							.other-score {
								.MuiFormControl-root {
									.MuiInputBase-root {
										border: 1.4px solid $color-text-info;
									}
								}
							}
						}
						.info-icon-btn {
							margin-left: 10px;
							padding: 0;
							svg {
								width: 14px;
								height: 14px;

								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					.okr-team-dropdown {
						&.review-workflow-dropdown {
							min-width: 100%;
							max-width: 100%;
							.dropdown-button {
								width: 100%;
							}
							&.disabled {
								.dropdown-button {
									opacity: 0.5;
									cursor: default;
									background: rgba($color-primary2, 0.1);
								}
							}
						}
					}
					.error-field {
						z-index: 1;
					}
					.review-workflow-group-field {
						&.custom-error-field {
							.dropdown-button {
								border: 1.4px solid $color-text-info;
							}
						}
					}
				}
			}
			&.goal-cycle-select-field {
				width: calc(66.666% - 10px);
				.settings-card-col {
					width: 100%;
					.MuiFormLabel-root {
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
					}
					&.custom-error-field {
						.select-search-dropdown {
							.react-select__control {
								border: 1.4px solid $color-text-info;
							}
						}
						.dropdown-button {
							border: 1.4px solid $color-text-info;
						}
					}
				}
			}
		}
	}
	.create-goal-cycle-actions {
		margin-bottom: 20px;
		visibility: visible;
		opacity: 1;
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		.btn-primary {
			padding: 6px 23px;
			margin-left: 0;
		}
		.btn-link {
			margin-right: 30px;
		}
	}
}

/*Assign users CSS*/
.assign-users-card {
	.assign-users-header {
		padding-bottom: 20px;
		&.helperText-bottom {
			.MuiTypography-h4 {
				color: $color-text-info;
			}
			.error-field {
				z-index: 1;
				left: -20px;
				top: 30px;
			}
		}
	}
	.assign-users-tab-section {
		margin-bottom: 15px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.okr-tab-button {
			min-height: 34px !important;
			.MuiTab-root {
				text-transform: capitalize !important;
			}
		}
		.switch-button {
			@include flex;
			svg {
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
			.MuiButton-root {
				margin-left: 7px;
				padding: 0;
				&:hover {
					background: transparent;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
	.assign-users-tab-content {
		.select-role-fields {
			width: 100%;
			.MuiFormControl-root {
				width: 100%;
			}
			.textfield-action-btn {
				@include justify-content(flex-end);
			}
			&.selected {
				.select-search-dropdown {
					.react-select__control {
						@include border-radius(6px 6px 0 6px);
					}
				}
			}
			.react-select__indicators {
				display: none;
			}
		}
		.select-search-dropdown.select-search-icon {
			.react-select__control {
				max-height: 65px;

				.react-select__indicators {
					max-height: 40px;
				}
				.secondary-text {
					display: none;
				}
			}
			.react-select__menu {
				.selected-chip {
					@include flex;
					.primary-text {
						width: 60%;
						padding-right: 20px;
						font-size: $font-size-base;
						line-height: $line-height2;
						color: $color-primary2;
						font-weight: $font-weight-regular;
					}
					.secondary-text {
						width: 40%;
						font-size: $font-size-base;
						line-height: $line-height2;
						color: $color-primary2;
						font-weight: $font-weight-regular;
					}
				}
			}
			&.react-select--is-disabled {
				.react-select__control {
					.react-select__value-container {
						.react-select__placeholder {
							background: url(/static/media/search-sprite.8e257541.svg) no-repeat 0 0;
							background-position: 0px 2px;
							padding-left: 26px;
							margin-left: 0px;
						}
					}
				}
			}
		}
		.upload-error-result {
			padding-bottom: 20px;
			margin-bottom: 20px;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			border-bottom: 1.4px solid $color-border-tile;
			.upload-error-mssg {
				.upload-error-mssg-title {
					@include inline-flex;
				}
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
				.MuiTypography-h6 {
					@include flex;
					@include align-items(center);
				}
			}
		}
		.select-doj-fields {
			.MuiFormGroup-root {
				margin-bottom: 5px;
			}
			.select-doj-range {
				width: 210px;
				input {
					&.form-control {
						width: 100%;
						padding: 4px 10px;
						font-size: $font-size-h5;
						line-height: $line-height2;
						border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						font-family: $font-family-lato;
					}
				}
				.textfield-action-btn {
					@include justify-content(flex-end);
				}
				&.date-range-selected {
					input {
						&.form-control {
							@include border-radius(6px 6px 0 6px);
						}
					}
				}
			}
		}
		.assign-users-bulk-field {
			.bulk-user-content {
				padding: 0;
				.uploading-bar {
					.bar {
						margin: 0;
						background: transparent;
						.success {
							background-color: rgba($color-secondary2, 0.5);
						}
						.inner-bar {
							border-color: transparent;
							.MuiTypography-root {
								color: $color-primary1;
							}
							svg {
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					.textfield-action-btn {
						@include justify-content(flex-end);
					}
				}
			}
		}
		.users-goal-review-cycle-notes {
			margin-top: 3px;
		}
	}
}
/**Goal cycle select form css start here**/
.goal-cycle-select-dropdown {
	.react-select__indicators {
		display: none;
	}

	.react-select__control {
		.react-select__value-container {
			// background: url(../../../../images/search-sprite.svg) no-repeat 0 0;
			// background-position: 10px 12px;
			//padding-left: 30px;
			padding-left: 20px;
			padding-right: 20px;
			min-height: 32px;
			.react-select__multi-value {
				padding-right: 0;
				width: 99%;
				@include border-radius(0px!important);
				background: none !important;
				margin: 0px !important;
				.react-select__multi-value__label {
					width: 100%;
					.search-user-result {
						padding-left: 0px;
					}
				}
				& + .react-select__input-container {
					padding-left: 0px !important;
					position: absolute;
					opacity: 0;
				}
			}
			.react-select__multi-value__remove {
				position: absolute;
				right: 0;
				width: 14px;
				padding: 0;
				margin: 0 5px;
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
			.react-select__placeholder {
				margin-left: 12px !important;
			}
			.react-select__input-container {
				margin-left: -11px;
				padding-left: 22px !important;
			}
		}
	}
	.react-select__menu {
		.react-select__menu-list {
			.react-select__option {
				padding: 9px 20px;
				border-bottom: 1.4px solid $color-hover;
			}
		}
	}
	.search-user-result {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.form-name {
			.MuiTypography-body1 {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
		.section-count {
			margin-right: 25px;
		}
		.section-count,
		.questions-count {
			@include flex;
			@include align-items(center);
			min-width: calc(50% - 25px);
			.MuiTypography-body1 {
				margin-right: 5px;
			}
			.MuiTypography-h6 {
				font-weight: normal;
			}
		}
		.preview {
			@include flex;
			@include justify-content(flex-end);
		}
	}
}

/*Assigned users list*/
.assigned-users-list-area-outer {
	margin-top: 20px;
	padding: 20px;
	background: $color-bg-form;
	@include border-radius(6px);
}
.assigned-users-list-area {
	.title-head {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		margin-bottom: 20px;
		.assigned-user-title {
			@include flex;
		}
	}
	.assigned-users-listing {
		.okr-list-panel-head,
		.assigned-users-list-content {
			ul {
				li {
					height: 30px;
					margin: 5px 0;
					&.assigned-user-col1 {
						width: 27%;
						padding-left: 5px;
					}

					&.assigned-user-col2 {
						width: 32%;
					}
					&.assigned-user-col3 {
						width: 27%;
					}
					&.assigned-user-col4 {
						width: 14%;
					}
				}
			}
		}
		.assigned-users-list-content {
			ul {
				@include flex;
				@include align-items(center);
				li {
					&.assigned-user-col4 {
						.MuiTypography-body1 {
							min-width: 82px;
						}
					}
				}
			}
		}
	}
	.user-search {
		.MuiInputBase-root {
			background: $color-white;
		}
	}
}
.report-header-tooltip {
	&.all-users-info-tooltip {
		&:before {
			left: auto;
			right: 37px;
		}
		.textarea-expand {
			min-width: 365px;
			width: 365px;
			height: 60px;
			resize: none;
		}
	}
	&.score-info-tooltip {
		margin-left: 0;
		margin-right: -52px;
		&:before {
			left: auto;
			right: 50px;
		}
		.textarea-expand {
			min-width: 365px;
			width: 365px;
			height: 60px;
			resize: none;
		}
	}
}
.assign-user-error-tooltip {
	.MuiTooltip-tooltip {
		margin: 0 0 0 10px !important;
		max-width: 250px !important;
		.tooltip-text {
			@include flex;
			svg {
				margin-right: 5px;
				min-width: 16px;
				width: 16px;
				height: 16px;
			}
		}
	}
}
.goal-review-listing {
	.okr-list-panel-head,
	.goal-review-list-content {
		ul {
			li {
				&.goal-review-col1 {
					width: 32%;
				}
				&.goal-review-col2 {
					width: 24%;
				}
				&.goal-review-col3 {
					width: 22%;
				}
				&.goal-review-col4 {
					width: 10%;
				}
				&.goal-review-col5 {
					width: 12%;
				}
			}
		}
	}
	.okr-list-panel-head {
		ul {
			li {
				svg {
					width: 10px;
					height: 10px;
				}
			}
		}
	}
	.goal-review-list-content {
		min-height: 65px;
		position: relative;
		padding: 15px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			padding: 0;
			li {
				padding: 0 12px 0 20px;
				flex: auto;
				&.goal-review-col1 {
					@include flex;
					@include justify-content(space-between);
					.goal-review-list-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				&.goal-review-col4 {
					.MuiTypography-h4 {
						font-weight: $font-weight-regular;
					}
					.MuiTypography-body2 {
						color: $color-secondary1;
						font-weight: $font-weight-regular;
						line-height: $line-height2;
					}
					svg {
						path {
							stroke: $color-primary2;
						}
					}
				}
				&.goal-review-col5 {
					.goal-review-status {
						margin-left: 0;
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			li {
				&.goal-review-col1 {
					.goal-review-list-actions {
						button {
							opacity: 1;
						}
					}
				}
			}
		}
		&.heighlight-row {
			background-color: $color-okr-highlighter !important;
		}
	}
}
.goal-review-status {
	margin-left: 10px;
	.MuiChip-root {
		&.draft-chip {
			color: $color-tags;
			border: 1px solid $color-tags;
			background-color: $color-okr-highlighter;
		}
		&.lagging-chip {
			background: $color-lagging;
			border: 1px solid $color-lagging;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.on-track-chip {
			background: $color-on-track;
			border: 1px solid $color-on-track;
			.MuiChip-label {
				color: $color-white;
			}
		}
	}
}

.goal-cycle-select-dropdown {
	.react-select__control {
		.search-user-result {
			margin-top: 2px;
			ul {
				width: calc(100% + 60px);
				@include flex;
				@include justify-content(space-between);
				@include align-items(center);
				li {
					padding: 0 10px 0 15px;
					&.col-1 {
						padding-left: 0;
						width: 30%;
					}
					&.col-2 {
						width: 29%;
					}
					&.col-3 {
						width: 29%;
					}
					&.col-4 {
						padding: 0 10px;
						width: 12%;
					}
				}
			}
		}
	}
	.react-select__menu {
		.react-select__menu-list {
			padding-top: 5px;
			.okr-list-panel-head,
			.search-user-result {
				ul {
					width: 100%;
					@include flex;
					@include justify-content(space-between);
					li {
						padding: 0 10px 0 15px;
						&.col-1 {
							width: 30%;
						}
						&.col-2 {
							width: 29%;
						}
						&.col-3 {
							width: 29%;
						}
						&.col-4 {
							padding: 0 10px;
							width: 12%;
						}
					}
				}
			}
			.react-select__option {
				padding: 10px 0;
			}
		}
	}
	.no-record-message {
		.MuiTypography-body1 {
			display: none;
		}
	}
}
