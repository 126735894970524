.okr-list-panel-head {
	> ul {
		padding: 0;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		> li {
			@include flex;
			@include align-items(center);
			height: 36px;
			text-transform: uppercase;
			padding: 0 0.75em 0 1.25em;
			flex: auto;
			&:before {
				content: '';
				width: 2px;
				height: 13px;
				background: linear-gradient(to bottom, rgba(41, 41, 41, 0.2) 0%, #292929 100%);
				opacity: 0.2;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			&:first-child {
				&:before {
					display: none;
				}
			}
		}
	}
	.sort-title {
		.sortable-label {
			@include flex;
			@include align-items(center);
			cursor: pointer;
			> span {
				padding-right: 4px;
			}
			svg {
				width: 10px;
				height: 10px;
				margin-top: 0px;
				@include transition-rotate;
			}
		}
		&.asc {
			.sortable-label {
				svg {
					@include transform(rotate(-180deg));
				}
			}
		}
		&.desc {
			.sortable-label {
				svg {
					@include transform(rotate(0deg));
				}
			}
		}
	}
	.txt-wrap {
		font-weight: $font-weight-bold;
		color: $color-secondary1;
	}
}
.okr-listing-head {
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	padding-bottom: 10px;
	padding-top: 10px;
	&.no-okr-head {
		@include justify-content(flex-end);
		padding-bottom: 20px;
		padding-top: 0px;
		margin-top: -30px;
	}
	.list-filter-btn {
		padding: 0.375em 0.5em 0.375em 1em;
		font-size: $font-size-h5;
		background: $color-bg-filterbtn;
		border: 1.4px solid $color-border-tile;
		@include border-radius(43px);
		svg {
			margin-right: 10px;
		}
		&:hover,
		&.active {
			background: $color-white;
			@include default-shadow;
		}
		.filter-txt {
			@include flex;
			@include align-items(center);
			svg {
				margin-left: 10px;
			}
		}
	}
	// .expand-all {
	// 	@include flex;
	// 	@include align-items(center);
	// 	margin-left: -2.25em;
	// 	svg {
	// 		width: 14px;
	// 		height: 14px;
	// 	}
	// 	button{
	// 		margin-right: 5px;
	// 		padding: 8px;
	// 		@include border-radius(30px);
	// 	}
	// 	.expand-txt {
	// 		opacity: 0;
	// 		font-size: 0px;
	// 		text-transform: capitalize;
	// 	}
	// 	.expand-all-inner {
	// 		@include flex;
	// 		@include align-items(center);
	// 		padding: 5px 1.0625em 5px 0.625em;
	// 		@include border-radius(1em);
	// 		cursor: pointer;
	// 		@include transition-default;
	// 		&.collapse-active {
	// 			svg {
	// 				@include transform(rotate(90deg));
	// 			}
	// 		}
	// 	}
	// 	&:hover {
	// 		.expand-all-inner {
	// 			background: $color-white;
	// 			.expand-txt {
	// 				opacity: 1;
	// 				font-size: 12px;
	// 			}
	// 		}
	// 		svg {
	// 			@include transition-rotate;
	// 		}
	// 	}
	// }
	.expand-all {
		@include flex;
		@include align-items(center);
		svg {
			max-width: 13px;
		}
		.expand-txt {
			opacity: 0;
			font-size: 0px;
			text-transform: capitalize;
		}
		.expand-all-inner {
			width: 30px;
			height: 30px;
			margin-right: 0.25em;
			@include flex;
			@include align-items(center);
			padding: 0;
			@include border-radius(1em);
			cursor: pointer;
			@include transition-default;
			background-color: transparent;
			border: 1.4px solid transparent;
			&.collapse-active {
				background: $color-white;
				border: 1.4px solid $color-border-tile;
				svg {
					@include transform(rotate(90deg));
				}
			}
			&:hover {
				background: $color-white;
				border: 1.4px solid $color-border-tile;
				svg {
					@include transition-rotate;
				}
			}
		}
	}
	.okr-listing-head-right {
		@include flex;
		@include align-items(center);
		.add-btn {
			margin-left: 12px;
		}
		.goal-okr-filter {
			.list-filter-btn {
				order: 2;
				margin-left: 10px;
			}
			.filter-chip {
				&.dashboard-filter-chip {
					position: static;
					ul {
						@include justify-content(flex-end);
					}
				}
			}
		}
		.download-btn {
			margin-left: 12px;
		}
	}
}
