@import '../../../styles/sass-utils/variables';

.alignment-map-main {
	.page-container {
		padding-bottom: 0px !important;
		min-height: 0px;
	}
	.wrapper {
		padding-bottom: 0px;
	}
	.wrapper-container {
		padding-bottom: 10px;
	}
	.back-to-main {
		&.cycle-back {
			width: auto;
			top: 130px;
			position: absolute;
			padding-top: 0px;
		}
	}
}
/**alignment chart head css start here**/
.alignment-chart-head {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	position: relative;
	min-height: 50px;
	&::after {
		content: '';
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: 0;
		border-bottom: 1.4px solid rgba($color-input-field, 0.5);
		opacity: 0.5;
	}
	&.alignment-future-head {
		margin-top: 14px;
	}
	.col-left {
		.okr-tabs {
			min-height: 50px !important;
			.MuiTab-root,
			.tabs-team-btn {
				font-size: $font-size-h5;
				line-height: $line-height3;
				font-weight: normal;
				min-height: 50px;
				padding: 6px 0;
				&:first-child {
					margin-left: 0px;
				}
				.arrowIcon {
					padding: 0 5px;
					svg {
						width: 8px;
						height: 8px;
						margin: 0px;
						path {
							stroke-width: 2px;
							stroke: $color-primary2;
						}
					}
				}
			}
			.tabs-team-btn {
				margin: 0 0.5em;
				position: relative;
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					background: none;
				}
				&.active {
					color: $color-primary1;
					&::after {
						content: '';
						width: 100%;
						height: 3px;
						position: absolute;
						left: 0;
						bottom: 0;
						border-bottom: 3px solid $color-primary1;
					}
					.arrowIcon {
						svg {
							path {
								stroke: $color-primary1;
							}
						}
					}
				}
			}
		}
	}
	.col-right {
		@include flex;
		@include align-items(center);
		.user-search {
			margin-left: 10px;
			margin-right: 10px;
			position: relative;
			.alignment-map-src {
				.rbt-input-main {
					padding: 0 2em 0 2.5em;
					height: 30px;
					font-weight: $font-weight-regular;
					font-size: $font-size-h5;
					line-height: $line-height4;
					color: $color-primary2;
					width: 100%;
					border: 1.4px solid #cee3ff;
					background-color: $color-bg-filterbtn;
					background-image: url(../../../images/search-sprite.svg);
					background-repeat: no-repeat;
					background-position: 10px 8px;
					font-family: $font-family-lato;
					@include border-radius(30px);
					max-width: 170px;
					&:focus {
						background-position: 10px -106px;
						border-color: $color-primary1;
						@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
					}
				}
				.rbt-menu {
					right: 0px !important;
					left: auto !important;
					width: 396px !important;
					margin-top: 5px;
					border: 0px;
					@include border-radius(6px);
					max-height: 310px !important;
				}
			}
			.MuiInputBase-root {
				background: $color-bg-filterbtn;
				min-width: 170px;
				.MuiInputBase-input {
					font-size: $font-size-h5;
					line-height: $line-height3;
				}
			}
		}
	}
	.visible-link-filter {
		@include flex;
		@include align-items(center);
		@include border-radius(44px);
		padding: 0px 20px;
		background: $color-bg-filterbtn;
		border: 1.4px solid $color-border-tile;
		.label-txt {
			margin-right: 5px;
			@include flex;
			@include align-items(center);
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
		}
		.MuiTypography-body1 {
			font-size: $font-size-h5;
			line-height: $line-height2;
		}
		.MuiFormControlLabel-root {
			margin-left: 0px;
			margin-right: 5px;
			&:last-child {
				margin-right: 0px;
			}
			.MuiButtonBase-root {
				padding: 6px;
			}
		}
	}
	.recent-src-dropdown {
		position: absolute;
		top: 28px;
		right: 0px;
		@include box-shadow(0px 5px 30px 8px rgba($color-primary2, 0.1));
		border-top: 0px;
		@include border-radius(6px);
		margin-top: 5px;
		width: 396px !important;
		overflow: hidden;
		ul {
			padding: 0px;
			max-height: 310px !important;
			@include custom-scrollbar;
			overflow: auto;
			li {
				padding: 0.625em 0.875em;
				cursor: pointer;
				&:hover {
					background: $color-hover;
				}
				&.active {
					background-color: $color-hover;
				}
			}
		}
		.search-find {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			padding: 1em 0;
			p {
				color: rgba($color-secondary1, 0.5);
			}
		}
	}
}
.alignment-chart-content {
	margin-top: 25px;
	.welcome-content {
		padding-top: 10px;
		.align-center {
			text-align: center;
		}
	}

	.alignment-logged-in-profile {
		text-align: center;
		margin-bottom: 15px;
		position: relative;
		.view-all-okr {
			background: rgba($color-white, 0.5);
			border: 1.4px solid $color-border-tile;
			font-size: $font-size-h6;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			color: $color-primary1;
			@include border-radius(100px);
			position: absolute;
			top: -40px;
			left: 50%;
			margin-left: -50px;
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
				display: block;
				margin-top: 2px;
				path {
					fill: $color-primary1;
				}
			}
			&:hover {
				background: rgba($color-white, 1);
			}
		}
		.MuiAvatar-root {
			width: 40px;
			height: 40px;
			margin: 0 auto;
			font-size: $font-size-h5;
			&.team-avatar-small {
				width: 48px;
				height: 40px;
			}
		}
		.selected-user-box {
			position: relative;
			width: 40px;
			height: 40px;
			margin: 0 auto;
			.back-alignment-map {
				margin-bottom: 0px;
				position: absolute;
				right: -7px;
				top: -12px;
				z-index: 9;
				.blue-filled-btn {
					width: 18px;
					height: 18px;
					svg {
						width: 9px;
						height: 9px;
					}
				}
			}
		}
	}

	/**alignment chart css start here**/
	.node-button-div {
		@include align-items(center);
	}
	.card-connector-icon {
		width: 24px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		@include flex-direction(column);
		margin-top: 0px !important;
		.circle-icon {
			border: 1.4px solid $color-border-tile;
			background: $color-white;
			@include border-radius(50%);
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			width: 12px;
			height: 12px;

			span {
				border: 1.4px solid $color-primary1;
				@include border-radius(50%);
				width: 5px;
				height: 5px;
				display: block;
			}
		}
		.virtual-icon {
			border: 1.4px dashed $color-primary1;
			background: $color-white;
			@include border-radius(50%);
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			width: 24px;
			height: 24px;
			svg {
				width: 10px;
				height: 10px;
				path {
					fill: $color-primary1;
				}
			}
		}
	}
}

/**alignment okr card css start here**/
foreignObject[class*='alignment-card-wrap_'] {
	@include border-radius(6px);
	overflow: initial;
	&:hover {
		.alignment-okr-card {
			@include box-shadow(0px 0px 10px rgba($color-primary2, 0.12));
		}
	}
}
.alignment-okr-card {
	border: 1.4px solid $color-border-tile;
	background: $color-white;
	@include border-radius(6px);
	position: relative;
	.virtual-icon {
		border: 1.4px dashed $color-primary1;
		background: $color-white;
		@include border-radius(50%);
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		width: 24px;
		height: 24px;
		position: absolute;
		top: 50%;
		right: -13px;
		margin-top: -12px;
		z-index: 9;
		svg {
			width: 10px;
			height: 10px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.card-top {
		padding: 18px 20px;
		position: relative;
		.default-gray-chip {
			position: absolute;
			top: 2px;
			left: 20px;
		}
	}
	.card-middle {
		@include flex;
		@include align-items(center);
		.teams-avatars {
			margin-right: 10px;
		}
		.virtual-avatar {
			margin-bottom: 15px;
			.user-info {
				@include align-items(center);
				.user-img {
					margin-right: 5px;
					.MuiAvatar-root {
						margin-top: 0px;
						width: 24px;
						height: 24px;
						font-size: $font-size-subtitle2;
						line-height: 24px;
					}
				}
			}
		}
	}
	.card-bottom {
		padding: 0px 5px 5px 5px;
	}
	.alignment-info {
		padding: 5px 11px 0;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.MuiTypography-subtitle2 {
			margin-right: 10px;
		}
		.kr-align-status {
			@include flex;
			@include align-items(center);
		}
		.kr-info {
			.MuiTypography-subtitle2 {
				margin-right: 0px;
			}
		}
	}
	.okr-title {
		margin-bottom: 15px;
		position: relative;
		.align-icon {
			position: absolute;
			top: -1px;
			left: -15px;
			svg {
				width: 10px;
				height: 10px;
				path {
					fill: $color-secondary1;
				}
			}
		}
	}

	/**team avatar start here**/
	.teams-avatars {
		@include flex;
		margin-bottom: 15px;
		&.teams-chips {
			margin-top: 0;
			.MuiChip-root {
				height: 23px;
				padding: 0;
				@include border-radius(12px);
				border: 1.4px solid $color-bg-teamstag;
				color: $color-tags;
				background: $color-bg-info;
				margin-right: 5px;
				min-width: 28px;
				.MuiChip-label {
					padding: 0px;
				}
				.icon-svg {
					width: 8px;
					height: 8px;
					margin-left: 5px;
					margin-right: 0px;
				}
				&:last-child {
					margin-right: 0px;
				}
			}

			.MuiChip-icon {
				margin-left: 6px;
				margin-right: -1px;
				width: 8px;
				height: 8px;
			}
			.team-avatar-smaller {
				border: 0px;
				width: 24px;
				height: 20px;
			}
		}
	}
	/** contributors css start here **/
	.contributors {
		.contributors-avatar-group {
			.MuiBadge-badge {
				min-width: 16px;
				min-height: 16px;
				background-color: $color-secondary1;
				border: none;
				top: 16px;
				padding: 0 3px;
				@include border-radius(20px);
				height: auto;
				.MuiTypography-root {
					color: $color-white;
					font-size: $font-size-subtitle2;
					line-height: $line-height4;
					font-weight: $font-weight-bold;
				}
				&::after {
					display: none;
				}
			}
		}
	}

	/**progress css start here**/
	.alignment-card-progress {
		.kr-progress-slider {
			@include flex;
			@include align-items(baseline);
			.MuiSlider-thumb {
				display: none;
			}
			.progress-timestamp-info {
				width: 45px;
				@include flex;
				@include justify-content(flex-start);
				margin: 3px 0px 0 10px;
				order: 2;
				h5 {
					color: $color-threshold;
					font-weight: $font-weight-black;
					sup {
						font-size: $font-size-subtitle2;
						line-height: $font-size-subtitle2;
						vertical-align: middle;
						display: inline-block;
						margin-top: -6px;
						margin-left: 1px;
					}
				}
			}

			.MuiSlider-root {
				padding: 0;
				width: calc(100% - 45px);
				min-width: 152px;
				height: 7px;
				display: block;
				margin: 3px 0;
				@include border-radius(7px);
				overflow: hidden;
				order: 2;
				.MuiSlider-rail {
					height: 7px;
					opacity: 1;
					background: $color-not-started;
					@include border-radius(7px);
				}
				.MuiSlider-track {
					height: 7px;
					@include border-radius(7px);
				}
				.Mui-disabled {
					display: none;
				}
			}
		}
	}

	/**Kr List css start here**/
	.kr-list {
		@include flex;
		@include flex-direction(column);

		.kr-list-item {
			margin-top: 5px;
			background: $color-bg-tag;
			@include border-radius(6px);
			padding: 2px 3px 2px 8px;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			position: relative;
			min-height: 25px;
			border: 1.4px solid $color-bg-tag;
			&:hover {
				@include box-shadow(0px 14px 28px rgba($color-primary2, 0.2));
			}
			&.active {
				border-color: $color-primary1;
			}
			.kr-col2,
			.kr-col3 {
				@include flex;
				@include align-items(center);
			}
			.kr-col1 {
				max-width: calc(100% - 170px);
				min-width: calc(100% - 170px);
				position: relative;
				.align-icon {
					position: absolute;
					top: 50%;
					left: -9px;
					width: 8px;
					margin-top: -4px;
					@include flex;
					@include align-items(center);
					svg {
						width: 8px;
						height: 8px;
						path {
							fill: $color-secondary1;
						}
					}
				}
				.align-kr-name {
					@include flex;
					p {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
				.user-info-details {
					overflow: hidden;
					p {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
			.kr-col2 {
				max-width: 110px;
				min-width: 110px;
				margin: 0 5px;
			}
			.kr-col3 {
				max-width: 60px;
				min-width: 60px;
				//@include justify-content(flex-end);
				.alignment-card-confidence {
					margin-right: 5px;
					@include flex;
					svg {
						width: 20px;
						height: 20px;
					}
				}
			}
			.user-info {
				@include align-items(center);
				.user-img {
					margin-right: 5px;
					.MuiAvatar-root {
						margin-top: 0px;
						width: 24px;
						height: 24px;
						font-size: $font-size-subtitle2;
						line-height: 24px;
					}
				}
			}
			.blue-count-circle {
				width: 16px;
				height: 16px;
				@include border-radius(50%);
				background: $color-primary1;
				color: $color-white;
				font-weight: normal;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				margin-left: 5px;
			}
			.progress-value {
				min-width: 50px;
				height: 24px;
				background: $color-bg-krscore;
				border: 1px solid $color-border-krscore;
				box-sizing: border-box;
				@include border-radius(6px);
				color: $color-lagging;
				font-weight: $font-weight-bold;
				padding: 0.125em 0.4375em;
				margin: 0;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
			}
			.teams-chips {
				margin-bottom: 0px;
				@include align-items(center);
			}
			&.no-team-con {
				.kr-col1 {
					max-width: calc(100% - 60px);
					min-width: calc(100% - 60px);
				}
				.kr-col2 {
					display: none;
					margin: 0;
				}
			}
		}
	}
}

/**alignment scroll css start here**/
.scroll-drag-info {
	&.alignment-map-scroll-info {
		background: url('../../../images/click.svg') no-repeat right center;
		background-size: 22px;
		padding-right: 2em;
	}
}

/**alignment direct reportees drawer css start here **/
.main-drawer-panel.alignment-dr-drawer {
	.drawer-area {
		.drawer-head {
			padding: 10px 25px;
			min-height: 52px;
			.drawer-title {
				.MuiTypography-root {
					font-size: $font-size-h4;
					line-height: $line-height2;
				}
			}
		}
		.alignment-dr-wrapper {
			padding: 0 25px;
			.user-quick-search-type2 {
				min-width: 348px;
				.rbt-menu#async-pagination {
					min-width: 348px !important;
				}
			}
			.reportees-members {
				margin-top: 0px;
				.no-record {
					margin-top: 2em;
				}
			}
			/**other member list css start here**/
			.other-member-section {
				margin-top: 10px;
				.other-member-head {
					position: relative;
					span {
						background: $color-white;
						padding-right: 4px;
						z-index: 9;
						position: relative;
					}
					&::after {
						content: '';
						border-bottom: 2px solid $color-hover;
						width: 100%;
						position: absolute;
						top: 50%;
						left: 0px;
						margin-top: 1px;
					}
				}
			}
		}
	}
}

// .main-drawer-panel.drawer-no-footer .drawer-area .drawer-head
