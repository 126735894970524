@import '../../sass-utils/variables';
.perform-assess-head {
	padding: 20px 0;
	@include flex;
	@include justify-content(space-between);
	.perform-assess-head-left {
		@include flex;
		@include align-items(flex-end);
		.MuiTypography-h2 {
			margin-right: 15px;
		}
		.btn-link {
			height: auto;
			font-size: $font-size-h5;
			line-height: $line-height2;
			font-weight: $font-weight-bold;
		}
	}
	.perform-assess-head-right {
		.help-doc {
			color: $color-primary1;
			font-size: $font-size-h5;
			line-height: $line-height2;
			font-weight: $font-weight-bold;
		}
	}
}
.perform-assess-content-area {
	.assessment-ques-list-area {
		padding: 25px 30px;
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		.MuiTableContainer-root {
			max-height: calc(100vh - 250px);
			overflow-y: auto;
			@include custom-scrollbar-small-height;
		}
		.assessment-ques-list {
			position: relative;
			> .MuiTableCell-root {
				padding-bottom: 40px;
			}
		}
		table {
			border-collapse: separate;
			.MuiTableCell-root {
				padding: 0;
				border: 1px solid $color-white;
				&.row-space {
					padding: 3px;
				}
			}
			.assessment-table-head {
				.MuiTableCell-root {
					min-width: 260px;
					padding: 0 30px 15px;
					background-color: $color-white;
					z-index: 99;
					&:first-child {
						min-width: 50px;
						width: 50px;
						left: 0;
						position: -webkit-sticky !important;
						position: sticky !important;
						z-index: 100;
						background: $color-white;
						padding-left: 0px;
						padding-right: 10px;
						.cell-wrap {
							@include flex;
							@include align-items(flex-start);
						}
					}
					&:nth-child(2) {
						min-width: 580px;
						width: 580px;
						left: 50px;
						position: -webkit-sticky !important;
						position: sticky !important;
						z-index: 100;
						.cell-wrap {
							@include flex;
							@include align-items(flex-start);
						}
					}
					.user-info {
						@include align-items(center);
						.user-img {
							.MuiAvatar-root {
								margin-top: 0;
							}
						}
						.user-info-details {
							@include flex;
							@include align-items(center);
							margin-right: 5px;
							.myself-txt {
								@include flex;
								@include align-items(center);
								padding: 0 7px;
								color: $color-secondary1;
								background: $color-bg-form;
								font-size: 10px;
								line-height: 16px;
								font-weight: $font-weight-bold;
								border-radius: 20px;
								margin-left: 5px;
								text-transform: uppercase;
								border: 1.4px solid $color-border-tile;
							}
						}
						button {
							padding: 0;
							svg {
								width: 14px;
								height: 14px;
							}
							&:hover {
								background-color: transparent;
								text-decoration: underline;
							}
							.MuiTouchRipple-root {
								display: none;
							}
							&.edit-btn {
								margin-left: 10px;
								padding: 0;
								color: $color-primary1;
								svg {
									margin-right: 4px;
									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
				}
			}
			.assessment-ques-header {
				> .MuiTableCell-root {
					padding-bottom: 10px;
				}
				.MuiTableCell-root {
					.MuiTableRow-root {
						.MuiTableCell-root {
							&:first-child {
								min-width: 580px;
								width: 580px;
								left: 0;
								position: -webkit-sticky !important;
								position: sticky !important;
								z-index: 9;
								background: $color-white;
								padding-left: 50px;
								padding-right: 0px;
							}
							// &:nth-child(2) {
							// 	min-width: 580px;
							// 	width: 580px;
							// 	left: 80;
							// 	position: -webkit-sticky !important;
							// 	position: sticky !important;
							// 	z-index: 9;
							// }
						}
					}
				}
			}
			.assessment-ques-row {
				.MuiTableCell-root {
					min-width: 260px;
					background: $color-bg-form;
					padding: 22px 30px;
					.cell-wrap {
						.MuiTypography-root {
							font-weight: $font-weight-bold;
						}
						.assessment-preview-index {
							margin-right: 10px;
							min-width: 30px;
							width: 30px;
							height: 30px;
							z-index: 1;
							background: $color-white;
							border: 1.4px solid $color-primary1;
							@include border-radius(100%);
							@include flex;
							@include align-items(center);
							@include justify-content(center);
							color: $color-primary1;
							font-size: $font-size-h5;
							font-weight: $font-weight-regular;
						}
					}
					&:first-child {
						@include border-radius(6px 0 0 6px);
						min-width: 50px;
						width: 50px;
						left: 0;
						position: -webkit-sticky !important;
						position: sticky !important;
						z-index: 9;
						background: $color-white;
						padding-left: 0px;
						padding-right: 10px;
						.cell-wrap {
							@include flex;
							@include align-items(flex-start);
						}
					}
					&:nth-child(2) {
						@include border-radius(6px 0 0 6px);
						min-width: 580px;
						width: 580px;
						left: 50px;
						position: -webkit-sticky !important;
						position: sticky !important;
						z-index: 9;
						.cell-wrap {
							@include flex;
							@include align-items(flex-start);
						}
					}
					&:last-child {
						@include border-radius(0 6px 6px 0);
					}
					.assessment-rating-box {
						max-width: 230px;
						position: relative;
						@include flex;
						.MuiRating-root {
							.MuiRating-label {
								width: 45px;
								&[for='assessment-rating-5'] {
									margin-right: 0;
								}
							}
						}
						.MuiTypography-h5 {
							font-weight: $font-weight-regular;
						}
						.MuiTypography-h6 {
							position: absolute;
							left: auto;
							right: auto;
							top: 100%;
						}
					}
					.response-input-field {
						width: 100%;
						padding: 10px 20px;
						background: $color-white;
						border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
					}
				}
			}
		}
		&.self-assessment-list {
			.MuiTableContainer-root {
				max-height: unset;
			}
		}
	}
}
.assessment-no-record {
	padding-top: 100px;
	@include flex;
	@include justify-content(center);
	text-align: center;
	.request1on1-messg {
		padding: 25px 0;
		@include flex;
		button {
			margin-right: 5px;
			padding: 0;
			color: $color-primary1;
			line-height: $line-height2;
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
			&:hover {
				background-color: transparent;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}
