@import '../../sass-utils/variables';

.people-progress-panel-area {
	margin-top: 15px;
	.welcome-content {
		padding-top: 50px;
		object {
			margin-top: 30px;
		}
	}
}
.people-progress-listing-area {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(33% - 15px), 1fr));
	grid-gap: 15px;
}
.people-progress-actions {
	@include flex;
	@include justify-content(space-between);
	.pp-sort-search-area {
		@include flex;
		@include align-items(center);
		.sorting-dropdown {
			@include flex;
			@include align-items(center);
			min-width: auto;
			max-width: none;
			.MuiTypography-h5 {
				color: rgba($color-primary2, 0.7);
			}
			.dropdown-button {
				padding: 0 0 0 5px;
				width: auto;
				border: none;
				.icon {
					margin-top: 5px;
					margin-left: 5px;
				}
			}
			.task-sorting-list {
				min-width: 248px;
				max-width: 248px;
				position: absolute;
				top: 35px;
				left: 0px;
				z-index: 9;
				@include border-radius(6px);
				background: $color-white;
				@include box-shadow(2px 0 10px rgba($color-primary2, 0.12));
				border: 0px;
				&::after,
				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					margin-left: 0;
					bottom: 0;
					top: 0px;
					left: 90px;
					box-sizing: border-box;
					transform-origin: 0 0;
					@include transform(rotate(133deg));
				}
				&::after {
					z-index: -1;
					border: 6px solid $color-white;
					border-color: transparent transparent $color-white $color-white;
				}
				&:before {
					z-index: 10;
					border: 6px solid rgba($color-primary2, 0.12);
					border-color: transparent transparent $color-white $color-white;
				}
				.group-by-dropdown-list-inner {
					background: $color-white;
					@include border-radius(6px);
				}
				.MuiList-root {
					@include border-radius(0 0 6px 6px);
					overflow-y: auto;
					max-height: 242px;
					@include custom-scrollbar;
					.MuiListItem-root {
						border-bottom: 1px solid rgba($color-input-field, 0.2);
						cursor: pointer;
						&:hover {
							background-color: $color-hover;
						}
						&.active {
							background-color: $color-secondary2;
						}
					}
				}
			}
			.task-sorting-list {
				top: 41px;
				&::after,
				&:before {
					left: 70px;
				}
				svg {
					width: 14px;
					height: 14px;
				}
			}
			.task-sorting-btn {
				padding: 5px 5px 5px 15px;
				font-size: $font-size-h5;
				line-height: $line-height3;
				background: $color-bg-form;
				border: 1.4px solid $color-border-tile;
				@include border-radius(32px);
				order: 2;
				margin-right: 15px;
				svg {
					width: 12px;
					height: 12px;
					margin-right: 10px;
				}
				&:hover,
				&.active {
					background: $color-white;
					@include default-shadow;
				}
				.filter-txt {
					@include flex;
					@include align-items(center);
					svg {
						margin-left: 10px;
					}
				}
			}
		}
		.user-search {
			.user-search-chip {
				.MuiChip-root {
					padding: 0 0 0 7px;
				}
			}
		}
	}
}
.people-progress-type {
	position: relative;
	.dropdown-button {
		padding: 0;
		font-size: $font-size-h2;
		line-height: $line-height2;
		font-weight: $font-weight-bold;
		.MuiButton-label {
			@include align-items(center);
			.dropdown-btn-txt {
				font-size: $font-size-h2;
				line-height: $line-height2;
				font-weight: $font-weight-bold;
				text-transform: capitalize;
			}
			.icon {
				margin: 5px 0 0 10px;
				order: 2;
				@include transition-default;
				svg {
					width: 10px;
					height: 10px;
					path {
						stroke: $color-primary2;
					}
				}
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: none;
		}
	}
	.people-progress-dropdown-list {
		min-width: 180px;
		max-width: 180px;
		position: absolute;
		top: 35px;
		left: 0px;
		z-index: 9;
		@include border-radius(6px);
		background: $color-white;
		@include box-shadow(2px 0 10px rgba($color-primary2, 0.12));
		border: 0px;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 90px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 6px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 6px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
		.people-progress-dropdown-list-inner {
			background: $color-white;
			@include border-radius(6px);
		}
		.MuiList-root {
			@include border-radius(0 0 6px 6px);
			overflow-y: auto;
			max-height: 242px;
			@include custom-scrollbar;
			.MuiListItem-root {
				border-bottom: 1px solid rgba($color-input-field, 0.2);
				cursor: pointer;
				&:hover {
					background-color: $color-hover;
				}
				&.active {
					background-color: $color-secondary2;
				}
			}
		}
	}
	&.active {
		.dropdown-button {
			border-color: transparent;
			@include border-radius(4px);
			z-index: 99;
			.icon {
				@include transform(rotate(180deg));
			}
		}
	}
}
.people-progress-card {
	padding: 20px;
	@include border-radius(6px);
	min-height: 157px;
	background: $color-white;
	@include flex;
	@include flex-direction(column);
	@include justify-content(space-between);
	border: 1.4px solid $color-border-tile;
	cursor: pointer;
	.people-progress-card-top {
		@include flex;
		@include justify-content(space-between);
		.user-info-details {
			a {
				color: $color-primary1;
				text-decoration: underline;
			}
			.contribution-text {
				padding: 0.25em 0 1em;
				font-weight: $font-weight-bold;
			}
		}
		.people-progress-card-actions {
			@include flex;
			.kebab-icon {
				color: $color-primary1;
				margin-top: -12px;
				margin-right: -12px;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
			.progressChart {
				.CircularProgressbar {
					.CircularProgressbar-text {
						font-size: 20px !important;
						font-weight: bold;
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
	.people-progress-mid {
		padding: 20px 0;
		.people-progress-slider {
			.kr-progress-slider {
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.MuiSlider-thumb {
					display: none;
				}
				.progress-timestamp-info {
					width: 40px;
					@include flex;
					@include justify-content(flex-start);
					margin: 0 0 0 5px;
					order: 2;
					h5 {
						color: $color-threshold;
						font-weight: $font-weight-black;
						sup {
							font-size: $font-size-subtitle2;
							line-height: $font-size-subtitle2;
							vertical-align: middle;
							display: inline-block;
							margin-top: -6px;
							margin-left: 1px;
						}
					}
				}

				.MuiSlider-root {
					padding: 0;
					width: calc(100% - 40px);
					min-width: calc(100% - 40px);
					height: 7px;
					display: block;
					margin: 3px 0;
					@include border-radius(7px);
					overflow: hidden;
					order: 2;
					.MuiSlider-rail {
						height: 7px;
						opacity: 1;
						background: $color-not-started;
						@include border-radius(7px);
					}
					.MuiSlider-track {
						height: 7px;
						@include border-radius(7px);
					}
					.Mui-disabled {
						display: none;
					}
				}
			}
			.progress-update-txt {
				margin-top: -4px;
			}
		}
	}
	.people-progress-bottom {
		padding: 20px 0 0;
		border-top: 1.4px solid $color-hover;
		@include flex;
		@include justify-content(space-between);
		.people-okr-count {
			@include flex;
			@include align-items(center);
			.people-obj-count {
				margin-right: 10px;
			}
			.people-obj-count,
			.people-kr-count {
				@include flex;
				svg {
					margin-right: 5px;
					width: 14px;
					height: 14px;
				}
			}
		}
		.user-team-lists {
			@include flex;
			@include align-items(center);
			.user-team-chip {
				@include flex;
				position: relative;
				padding: 0 8px 0 27px;
				@include border-radius(12px);
				font-size: $font-size-subtitle2;
				min-height: 16px;
				.MuiAvatar-root {
					position: absolute;
					left: 0;
					top: -1.4px;
					margin-top: 0;
					margin-right: 7px;
					width: 24px;
					height: 18.8px;
					margin-left: 0;
					@include border-radius(12px);
					border: 1.4px solid $color-white;
					font-size: $font-size-subtitle2;
					img {
						@include border-radius(12px);
					}
				}
				.MuiChip-label {
					font-size: $font-size-subtitle2;
					line-height: $line-height5;
				}
			}
			.other-user-team-chip {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				min-width: 16px;
				height: 16px;
				margin-left: 5px;
				background-color: $color-primary1;
				border: none;
				padding: 0 3px;
				@include border-radius(20px);
				.MuiTypography-root {
					color: $color-white;
					font-size: $font-size-subtitle2;
					line-height: $line-height5;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
	&:hover {
		border: 1.4px solid $color-primary1;
		@include box-shadow(2px 0px 10px 0px rgba($color-primary2, 0.12));
	}
}
.main-drawer-panel {
	&.other-teams-drawer-panel {
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-tabs-panel {
						background: $color-bg-form;
					}
				}
			}
		}
	}
}
.users-progress-listing {
	.users-progress-list-notes {
		padding: 0 60px;
	}
	.users-progress-listing-top {
		padding: 0 60px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.user-search {
			.MuiInputBase-root {
				background: $color-white;
			}
		}
	}
	.okr-list-panel-head,
	.users-progress-list-content {
		padding: 0 50px;
		ul {
			li {
				height: 30px;
				margin: 5px 0;
				&.users-progress-col1 {
					width: 32%;
					padding-left: 5px;
					.MuiFormControlLabel-root {
						margin-left: -14px;
						margin-right: 5px;
					}
					.MuiTypography-body1 {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}

				&.users-progress-col2 {
					width: 31%;
					.MuiTypography-body1 {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
				&.users-progress-col3 {
					width: 19%;
				}
				&.users-progress-col4 {
					width: 18%;
				}
			}
		}
	}
	.okr-list-panel-head {
		ul {
			padding: 0 10px;
			li {
				.sort-title {
					.sortable-label {
						svg {
							width: 10px;
							height: 10px;
						}
					}
					&.desc {
						.sortable-label {
							svg {
								@include transform(rotate(-180deg));
							}
						}
					}
				}
			}
		}
	}
	.users-progress-list-content {
		ul {
			@include flex;
			@include align-items(center);
			li {
				&.users-progress-col4 {
					h6 {
						sup {
							font-size: $font-size-subtitle2;
							line-height: $font-size-subtitle2;
							vertical-align: middle;
							display: inline-block;
							margin-top: -6px;
							margin-left: 1px;
						}
					}
				}
			}
		}
		.users-list-row {
			padding: 0 10px;
			@include border-radius(6px);
			&:hover {
				background: $color-secondary2;
			}
		}
		.view-more-teams {
			margin: 10px 0 0;
			padding: 0 10px;
			.load-more-btn {
				padding: 0;
			}
		}
	}
}
@media screen and (min-width: $desktop-breakpoint3) {
	.people-progress-listing-area {
		grid-template-columns: repeat(auto-fill, minmax(calc(25% - 15px), 1fr));
	}
}
