/**confidence selection css start here**/
.confidence-selection {
	.confidence-row {
		margin-top: 0.625em;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.btn-link {
			font-size: $font-size-h6;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			margin: 0;
		}
	}
	.MuiList-root {
		@include flex;

		.MuiListItem-root {
			width: auto;
			padding: 0;
			margin-right: 1.25em;
			button {
				min-width: 6.5625em;
				min-height: 2.75em;
				padding: 0.625em;
				border: 1.4px solid rgba($color-input-field, 0.5);
				@include border-radius(6px);
				color: $color-secondary1;
				svg {
					margin-right: 0.714em;
					width: 24px;
					height: 24px;
				}
				&:hover {
					background-color: $color-hover;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
				}
				&.active {
					background: $color-secondary2;
					border: 1.4px solid $color-primary1;
					color: $color-primary1;
					box-shadow: none;
				}
			}
		}
	}
}

/**okr listing css start here**/
.confidence-btn-wrap {
	//min-width: 80px;
	@include flex;
	@include align-items(center);
	height: 100%;
	h6 {
		@include flex;
		align-items: center;
		cursor: pointer;

		.confi-text {
			opacity: 0;
			@include transition-default;
			color: $color-primary1;

			&.disabled-text {
				color: rgba($color-primary2, 0.4) !important;
			}
		}
		svg {
			margin-right: 5px;
			width: 30px;
			height: 30px;
		}
		// &.not-set {
		// 	.confi-text {
		// 		color: rgba($color-primary2, 0.4);
		// 	}
		// }
	}
	.confi-action-btn {
		padding-right: 15px;
		position: relative;
		.confi-label {
			width: 30px;
			position: relative;
			.confi-text {
				position: absolute;
				left: 35px;
				top: auto;
				min-width: 48px;
			}
			svg {
				margin-right: 0px;
			}
		}
		.confidence-alert {
			position: absolute;
			left: -20px;
			top: 50%;
			margin-top: -8px;
			height: 17px;
			&.alert-okr {
				position: static;
				margin: 0;
				width: 30px;
				text-align: center;
			}
		}
		&.people-confi-btn {
			h6 {
				.confi-text {
					color: $color-secondary1;
				}
			}
		}
	}
}
/**enable confidence btn on kr hover css start here**/
.my-obj-listing {
	.my-obj-listing-content {
		.okr-keys-listing {
			.MuiCollapse-wrapperInner {
				.my-obj-listing-inner {
					ul {
						&:hover {
							.confi-action-btn {
								.confi-text {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**contributor list popover css start here**/
.confidence-list-popper {
	background: $color-white;
	@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
	@include border-radius(6px);
	z-index: 99;
	max-width: 297px;
	min-width: 297px;
	padding: 0.625em 0.75em;
	margin-top: -15px;
	margin-left: -17px;
	transform: none !important;

	&.conf-medium,
	&.conf-notset {
		margin-left: -70px;
	}
	&.conf-low {
		margin-left: -123px;
	}
	&.confidence-no-trend {
		//max-width: initial;
		min-width: 170px;
		//margin-left: -50px;
	}
	&.confidence-people-popper {
		min-width: 198px !important;
		margin-left: -17px !important;
		.MuiList-root {
			.MuiListItem-root {
				button {
					cursor: default;
				}
			}
		}
	}
	.confidence-selection {
		.confidence-row {
			margin: 0px;
		}
		.MuiList-root {
			.MuiListItem-root {
				margin: 0px 0.75em 0 0px;
				button {
					border-color: $color-white;
					min-width: auto;
					min-height: auto;
					padding: 7px;
					@include border-radius(100%);
					svg {
						margin: 0px;
					}
					&.active {
						border-color: $color-primary1;
					}
					&:hover {
						box-shadow: none;
						//background: none;
					}
				}
				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
}
