@import '../../sass-utils/variables';

//create new OKR form css
.create-okr-card {
	margin-top: 28px;
	margin-bottom: 28px;
	position: relative;
	@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
	@include border-radius(6px);
	background: $color-white;
	border: 1.4px solid $color-border-tile;
	.generate-okr-btn {
		position: absolute;
		left: -15px;
		top: 19px;
		z-index: 9;
		border: 1.4px solid $color-white;
	}

	.okr-col1 {
		width: 45%;
		min-width: 45%;
		@include flex;
		//@include justify-content(space-between);
		//@include align-items(flex-start);
		@include flex-direction(column);
	}
	.okr-col2 {
		width: 55%;
		min-width: 55%;
	}
	.create-obj {
		min-height: 98px;
		padding: 18px 10px 18px 40px;
		position: relative;
		.create-obj-col {
			@include flex;
			@include justify-content(space-between);
			.okr-list-progressbar {
				.okr-list-progressbar-inner {
					min-height: auto;
					cursor: default;
				}
				.okr-list-progress-head {
					padding-top: 0px;
					padding-bottom: 0px;
					.progress-bar-section {
						.obj-progress {
							padding-top: 0;
							.obj-progress-slider {
								.progress-timestamp-info {
									.MuiTypography-h2 {
										color: rgba($color-primary2, 0.5) !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.create-kr {
		padding: 0 10px 10px 40px;
		@include transition-default;
		@include animation(slide-down 1s);
		position: relative;
		&.add-shadow {
			.create-obj-col {
				@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
			}
		}
		.generate-okr-btn {
			left: 25px;
			top: 10px;
		}
		.okr-col1 {
			@include justify-content(center);
		}
		.kr-progress {
			.kr-progress-slider {
				.progress-timestamp-info {
					.MuiTypography-h2 {
						color: rgba($color-primary2, 0.5) !important;
					}
				}
			}
		}
		.create-obj-col {
			padding: 2px;
			@include flex;
			@include justify-content(space-between);
			background: $color-bg-teamstag;
			@include border-radius(6px);
			.okr-col1-inner {
				padding-left: 30px;
				min-height: 94px;
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
		&:hover {
			.okr-list-progressbar {
				.okr-list-progressbar-inner {
					background: $color-white;
				}
			}
		}
	}
	.add-objective,
	.add-kr {
		width: 85%;
		.MuiInputBase-root {
			margin-left: -10px;
			padding: 5px 10px;
			border: 1.4px solid transparent;
			textarea {
				padding: 0;
				font-size: $font-size-h4;
				line-height: $line-height2;
				font-weight: $font-weight-bold !important;
			}
			&:hover {
				border: 1.4px solid rgba($color-input-field, 0.5);
			}
			&.Mui-focused {
				border: 1.4px solid $color-primary1;
				@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
			}
		}
		&.helperText-bottom {
			.MuiFormHelperText-root {
				&.Mui-error {
					top: 34px;
				}
			}
		}
	}
	.add-kr {
		.MuiInputBase-root {
			textarea {
				font-weight: $font-weight-regular !important;
			}
		}
	}
	.okr-field-actions {
		margin-top: 12px;
		.MuiButton-text {
			font-size: $font-size-h6;
			color: $color-primary1;
			font-weight: $font-weight-bold;
			line-height: 15px;
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
		}
		.MuiButton-text.settings-btn-disable {
			color: $color-input-field;
			cursor: default;
			svg {
				path {
					fill: $color-input-field;
				}
			}
		}
		.MuiButton-text.settings-btn-error {
			color: $color-text-info;
			.icon {
				@include animation(rotation 2s linear infinite);
				//animation-iteration-count: infinite;
				margin-right: 5px;
				svg {
					margin-right: 0px;
					path {
						fill: $color-text-info;
					}
				}
			}
		}
		.set-timeline-area {
			margin-left: 0;
			margin-right: 15px;
			.timeline-field {
				.calendar-btn {
					button {
						margin-right: 0;
						line-height: 1;
					}
				}
			}
		}
	}
	.create-okr-actions {
		margin: 15px 10px 30px 0;
		visibility: visible;
		opacity: 1;
		@include flex;
		@include justify-content(flex-end);
		@include align-items(center);
		p {
			font-style: italic;
			font-size: $font-size-h5;
			text-transform: lowercase;
			color: $color-input-field;
		}
		&.hidden-actions {
			pointer-events: none;
			button,
			p {
				visibility: hidden;
				opacity: 0;
				transition: visibility 0.3s linear, opacity 0.3s linear;
			}
		}
		.draft-btn {
			svg {
				width: 1em;
				height: 1em;
				margin-right: 0.625em;
				path {
					fill: $color-primary1;
				}
			}
		}
		.btn-primary {
			padding: 0.625em 1.25em;
		}
		.create-add-another {
			height: 40px;
			line-height: 40px;
			svg {
				width: 0.6875em;
				margin-right: 0.625em;
				path {
					fill: $color-primary1;
					stroke: $color-primary1;
				}
			}
			&.Mui-disabled {
				svg {
					path {
						fill: rgba(0, 0, 0, 0.26);
						stroke: rgba(0, 0, 0, 0.26);
					}
				}
			}
		}
	}
}
