@import '../../sass-utils/variables';
.strategic-obj-wrap {
	min-height: calc(100vh - 70px);
	background: url(../../../images/bg-with-mission-vision.svg) -55px -45px no-repeat;
	.back-to-main {
		padding-top: 30px;
		padding-bottom: 0px;
	}
	.so-mv-area {
		padding-top: 40px;
		min-height: 460px;
		.so-mv-tile {
			margin-left: 60px;
			margin-bottom: 10px;
			width: 486px;
			padding: 20px;
			background-color: rgba($color-white, 0.7);
			@include border-radius(6px);
			.so-mv-tile-head {
				margin-bottom: 10px;
				@include flex;
				@include align-items(center);
				.so-mv-icon {
					margin-right: 10px;
				}
				.MuiTypography-body1 {
					font-weight: $font-weight-bold;
				}
			}
			.so-mv-tile-content {
				max-height: 90px;
				overflow-y: auto;
				@include custom-scrollbar;
				*,
				.MuiTypography-body1 {
					color: $color-secondary1;
					font-size: $font-size-h5;
				}
			}
		}
	}
	&.no-mission-vision-wrap {
		background-size: calc(100% + 100px);
		.so-mv-area {
			min-height: 260px;
		}
	}
	&.so-mv-fullscreen-area {
		background-position: left -55px bottom -330px;
		.so-mv-area {
			padding-top: 0;
			padding-bottom: 120px;
			min-height: calc(100vh - 120px);
			@include flex;
			@include align-items(center);
		}
	}
	.so-obj-list-head {
		.add-btn {
			@include flex;
		}
		.okr-listing-head-left {
			@include flex;
			@include align-items(center);
		}
	}
	.so-obj-listing {
		.so-okr-card-list {
			width: 100%;
			display: -ms-grid;
			display: grid;
			-ms-grid-columns: repeat(auto-fill, minmax(400px, 1fr));
			grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
			grid-gap: 10px 20px;
			@media screen and (max-width: 1400px) {
				-ms-grid-columns: repeat(auto-fill, minmax(300px, 1fr));
				grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			}
			@media screen and (max-width: 1600px) {
				-ms-grid-columns: repeat(auto-fill, minmax(350px, 1fr));
				grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
			}
			.so-okr-card {
				margin-top: 10px;
				min-height: 150px;
				@include flex;
				@include flex-direction(column);
				@include justify-content(space-between);
				border: 1.4px solid $color-border-tile;
				@include border-radius(6px);
				background: $color-white;
				.so-okr-card-top {
					position: relative;
					padding: 20px;
					.so-okr-card-top-inner {
						@include flex;
						@include justify-content(space-between);
						@include align-items(flex-start);
					}
					.okr-status-info {
						position: absolute;
						left: 56px;
						top: 8px;
						@include flex;
						@include align-items(center);
						.draft-chip {
							margin-right: 7px;
							height: auto;
							padding: 0px;
							background: $color-okr-highlighter;
							border: 1px solid $color-border-info;
							.MuiChip-label {
								font-size: $font-size-subtitle2 !important;
								font-weight: $font-weight-bold;
								color: $color-tags;
								line-height: $line-height5;
								text-transform: uppercase;
								padding: 0 7px;
							}
						}
					}
					.so-okr-card-info {
						@include flex;
						@include align-items(flex-start);
						padding-right: 15px;
						.so-okr-icon {
							margin-right: 10px;
							position: relative;
							svg {
								position: relative;
								z-index: 1;
							}
							&:after {
								content: '';
								position: absolute;
								left: 0;
								bottom: 0;
								width: 20px;
								height: 20px;
								@include border-radius(20px);
								background: #e9e7e7;
							}
						}
						.so-okr-title {
							padding-top: 7px;
						}
					}
					.so-progress-chart {
						min-width: 56px;
						max-width: 56px;
						.circular-progress-chart {
							max-width: 56px;
							.CircularProgressbar {
								.CircularProgressbar-text {
									font-weight: $font-weight-bold;
								}
							}
						}
					}
					.so-okr-card-actions {
						opacity: 0;
						position: absolute;
						right: 5px;
						top: 10px;
						button {
							padding: 0;
							width: 20px;
							height: 20px;
							@include flex;
							@include align-items(center);
							@include justify-content(center);
							@include border-radius(30px);
							cursor: pointer;
							.MuiTouchRipple-root {
								display: none;
							}
							&:hover {
								background-color: transparent;
							}
							svg {
								width: 15px;
								height: 15px;
								path {
									fill: $color-primary1;
									stroke: none;
								}
							}
						}
					}
				}
				.so-okr-card-mid {
					opacity: 0;
					padding: 0 20px;
					position: relative;
					@include flex;
					@include align-items(center);
					@include transition-default;
					button {
						padding: 0;
						min-width: auto;
						@include animation(fade-in 1.1s);
						margin-right: 1em;
						svg {
							width: 1em;
							height: 1em;
							path,
							circle,
							rect {
								fill: $color-primary1;
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
						&:hover {
							background-color: transparent;
						}
					}
				}
				.so-okr-card-bottom {
					padding: 10px 20px 20px;
					.so-okr-tags {
						@include flex;
						@include align-items(center);
						.MuiChip-root {
							height: 24px;
							margin-right: 5px;
							font-size: $font-size-subtitle2;
							text-transform: uppercase;
							background-color: $color-bg-tag;
						}
						.no-of-tags {
							@include flex;
							@include align-items(center);
							@include justify-content(center);
							// width: 16px;
							// height: 16px;
							color: $color-white;
							font-size: $font-size-subtitle2;
							font-weight: $font-weight-bold;
							// @include border-radius(16px);
							// background: $color-primary1;
						}
					}
				}
				&.so-okr-not-created {
					background: rgba($color-white, 0.3);
					.MuiSkeleton-root {
						background: $color-secondary2;
					}
					.so-okr-card-top {
						.so-okr-card-info {
							.so-okr-icon {
								&:after {
									display: none;
								}
							}
							.so-okr-title {
								padding: 0;
								.MuiSkeleton-root {
									margin-bottom: 8px;
									@include border-radius(10px);
									transform: scale(1);
								}
							}
						}
					}
					.so-okr-card-bottom {
						.so-okr-tags {
							.MuiSkeleton-root {
								margin-right: 5px;
								@include border-radius(15px);
								transform: scale(1);
							}
						}
					}
				}
				&:hover {
					.so-okr-card-mid,
					.so-okr-card-actions {
						opacity: 1;
					}
				}
			}
		}
	}
}

/**so attributes drawer css start here**/
.so-attributes {
	.drawer-date-field {
		#date-range-inputfield {
			min-width: 268px;
			max-width: 268px;
		}
	}
	// .disable-label {
	// 	opacity: 0.8;
	// 	color: rgba($color-primary2, 0.5);
	// 	svg {
	// 		path {
	// 			fill: rgba($color-primary2, 0.5);
	// 		}
	// 	}
	// }

	.drawer-input-field {
		.so-name-obj {
			.MuiInputLabel-root.Mui-disabled {
				opacity: 1 !important;
				color: rgba($color-primary2, 1) !important;
				svg {
					opacity: 1 !important;
					path {
						fill: rgba($color-primary2, 1) !important;
					}
				}
			}
		}
	}
}

/**search so tab drawer css start here**/
.search-so-tab {
	.okr-oval-tabs {
		padding-top: 0px;
	}
	.searched-people {
		.editIcon {
			@include flex;
			@include align-items(center);
			svg {
				width: 12px;
				height: 12px;
			}
		}
	}
	.import-src-people {
		margin-bottom: 5px;
	}
}
/**So objective list drawer css start here**/
.so-objective-list {
	.src-okr-head-wrap {
		padding-bottom: 25px !important;
	}
	.src-okr-head {
		@include flex;
		@include justify-content(space-between);
		.cycle-dropdown {
			.tenure-list {
				max-height: 239px;
			}
		}
	}
	.import-myokr-list {
		.okr-status-info {
			padding-left: 0px;
		}
		.import-okr-actions {
			margin-left: 0px;
		}
		.import-progress {
			margin-right: 10px;
			&.import-kr-progress {
				margin-right: 10px;
			}
		}
		.link-toggle {
			margin-right: 15px;
			label {
				margin: 7px 0 0 0;
				vertical-align: initial;
			}
		}
		.import-obj-row {
			.import-obj-col {
				@include flex-direction(row);
				max-width: 422px;
				.obj-title-section {
					width: calc(100% - 10px);
					@include flex;
					@include flex-direction(column);
				}
			}
		}
		.import-kr-row {
			.import-kr-col {
				@include flex;
				width: 60%;
				max-width: 408px;
				.kr-title-section {
					width: calc(100% - 10px);
				}
			}
			&.import-kr-row-linked {
				.import-kr-col {
					max-width: 423px;
					width: 60.23%;
				}
			}
		}

		.import-obj-col,
		.import-kr-col {
			.user-info {
				margin-left: 10px;
				.user-img {
					margin: 0px 0 0 0px;
					.MuiAvatar-root {
						margin: 0px;
						width: 40px;
						height: 40px;
						min-width: 40px;
						min-height: 40px;
					}
				}
			}
		}
		.import-kr-progress-col {
			.link-toggle {
				margin-right: 0px;
				label {
					margin-top: 19px;
				}
			}
		}
		.import-okr-actions {
			.cycle-info {
				margin-left: 7px;
			}
		}
	}
	.selected-obj-count {
		@include flex;
		@include align-items(center);
		@include justify-content(flex-end);
	}
	.so-link-kr-group {
		.import-okr-list {
			padding: 0px;
			box-shadow: none;
			.import-kr-list {
				padding: 0px;
				margin-bottom: 10px;
			}
		}
	}
}

.so-create-drawer {
	.drawer-area {
		.drawer-content {
			.drawer-inner-content {
				.drawer-tabs-panel {
					padding-top: 0px !important;
					.so-attributes,
					.search-so-tab,
					.so-objective-list {
						padding-top: 1.875em;
					}
					/**information message css start here**/
					.information-message {
						position: sticky;
						position: -webkit-sticky;
						z-index: 99;
						width: 100%;
						left: 0;
						top: 0;
						padding: 3px 3.75em;
						background: $color-bg-info;
						@include animation(slideUp 0.8s);
						.MuiTypography-h6 {
							@include inline-flex;
							@include align-items(center);
							color: $color-text-info;
							text-transform: initial;
							line-height: 14px;
							svg {
								margin-right: 0.25em;
								path {
									fill: $color-text-info;
								}
							}
						}
					}
				}
			}
		}
	}
}
