@import '../../../../styles/sass-utils/variables.scss';
.admin-tabpanel-head {
	.admin-tabpanel-head-left {
		.setting-header-actions {
			//padding-top: 15px;
			//margin-left: 20px;
			button {
				font-size: $font-size-h5 !important;
				&.btn-link {
					height: auto;
					line-height: 1;
				}
			}
		}
		.okr-tab-button {
			min-height: auto !important;
			.MuiTab-root {
				background: $color-white;
				&.Mui-selected {
					background: $color-secondary2;
				}
			}
		}
	}
	.admin-tabpanel-head-right {
	}
}
.performance-assessment-settings {
	.admin-tabpanel-head {
		.admin-tabpanel-head-left {
			@include align-items(center!important);
		}
	}
}
/** Performance cycle and Assessment form css **/
.assessment-list-content,
.performance-listing-content {
	min-height: 80px;
	position: relative;
	padding: 1em 0;
	margin-bottom: -1px;
	transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	ul {
		@include flex;
		@include align-items(center);
		padding: 0;
		li {
			padding: 0 12px 0 20px;
			flex: auto;
			&.perform-col1,
			&.assessment-col1 {
				@include flex;
				@include justify-content(space-between);
				.perform-review-name {
					width: 75%;
				}
				.perform-review-actions {
					@include flex;
					@include align-items(center);
					button {
						padding: 0;
						min-width: auto;
						width: 24px;
						height: 24px;
						@include border-radius(100%);
						margin-left: 0.5em;
						@include transition(0.25s);
						opacity: 0;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
					}
				}
			}
			&.perform-col2,
			&.assessment-col2 {
				.performance-review-status {
					@include flex;
					@include align-items(center);
					.status {
						width: 14px;
						height: 14px;
						min-width: 14px;
						min-height: 14px;
						border-radius: 100px;
						border: 1.4px solid $color-primary1;
						background: $color-white;
						margin-right: 5px;
						&.published {
							background: $color-completed;
							border-color: $color-completed;
						}
						&.no-status {
							background: transparent;
							border-color: transparent;
						}
					}
					.MuiChip-root {
						padding: 0 7px;
						color: $color-primary2;
						line-height: 1;
						border: 1px solid $color-bg-tag;
						&.draft-chip {
							color: $color-tags;
							border: 1px solid $color-tags;
							background-color: $color-okr-highlighter;
						}
					}
				}
			}
			&.perform-col3,
			&.perform-col4 {
				.MuiTypography-body1 {
					font-size: $font-size-h5;
				}
			}
			&.perform-col5,
			&.perform-col6,
			&.perform-col7,
			&.assessment-col5,
			&.assessment-col6 {
				.MuiTypography-h4 {
					font-weight: $font-weight-regular;
				}
				.MuiTypography-body2 {
					color: $color-secondary1;
					font-weight: $font-weight-regular;
					line-height: $line-height2;
				}
				svg {
					path {
						stroke: $color-primary2;
					}
				}
			}
		}
	}
	&:hover {
		@include default-shadow;
		z-index: 1;
		li {
			&.perform-col1,
			&.assessment-col1 {
				.perform-review-actions {
					button {
						opacity: 1;
					}
				}
			}
		}
	}
}
.okr-list-panel-head,
.performance-listing-content {
	ul {
		li {
			&.perform-col1 {
				width: 28%;
			}
			&.perform-col2 {
				width: 12%;
			}
			&.perform-col3 {
				width: 18%;
			}
			&.perform-col4 {
				width: 18%;
			}
			&.perform-col5 {
				width: 11%;
			}
			&.perform-col6 {
				width: 12%;
			}
			&.perform-col7 {
				width: 12%;
			}
		}
	}
}
.assessment-forms-listing {
	.okr-list-panel-head,
	.assessment-list-content {
		ul {
			li {
				&.assessment-col1 {
					width: 28%;
				}
				&.assessment-col2 {
					width: 12%;
				}
				&.assessment-col3 {
					width: 15%;
				}
				&.assessment-col4 {
					width: 15%;
				}
				&.assessment-col5 {
					width: 15%;
				}
				&.assessment-col6 {
					width: 15%;
				}
			}
		}
	}
}
/** manage scale drawer listing css **/
.manage-scale-form {
	.add-user-field {
		max-width: 116px;
		cursor: pointer;
	}
	.manage-scale-listing {
		padding: 30px 50px;
		background: $color-bg-form;
		.okr-list-panel-head,
		.manage-scale-list-content {
			ul {
				li {
					padding: 0 10px;
					&.manage-scale-col1 {
						width: 37%;
					}
					&.manage-scale-col2,
					&.manage-scale-col3,
					&.manage-scale-col4 {
						width: 21%;
					}
				}
			}
		}
		.okr-list-panel-head {
			ul {
				li {
					&:before {
						bottom: auto;
						top: 11px;
					}
				}
			}
		}
		.manage-scale-list-content {
			padding: 20px 0;
			//cursor: pointer;
			@include transition(0.3s);
			border-bottom: 1.4px solid $color-border-tile;
			ul {
				@include flex;
				@include align-items(center);
				padding: 0;
				li {
					flex: auto;
					&.manage-scale-col1 {
						padding: 0 10px;
					}
					&.manage-scale-col2 {
						svg {
							width: 14px;
							height: 14px;
							margin-right: 7px;
							rect {
								stroke: $color-primary2;
							}
						}
					}
					&.manage-scale-col3,
					&.manage-scale-col4 {
						.MuiTypography-body2 {
							color: $color-secondary1;
							font-weight: $font-weight-regular;
							line-height: $line-height2;
							min-height: 22px;
						}
					}
					&.manage-scale-col3 {
						.MuiChip-root {
							padding: 0 7px;
							color: $color-primary2;
							line-height: 1;
							border: 1px solid $color-bg-tag;
							&.draft-chip {
								color: $color-tags;
								border: 1px solid $color-tags;
								background-color: $color-okr-highlighter;
							}
						}
					}
					&.manage-scale-col4 {
						@include flex;
						@include justify-content(space-between);
						.status-switch {
							.MuiFormControlLabel-root {
								margin-left: 0;
								.MuiSwitch-root {
									.MuiSwitch-switchBase {
										padding: 3px;
										.MuiSwitch-thumb {
											width: 8px;
											height: 8px;
										}
										&.Mui-checked + .MuiSwitch-track {
											background: $color-completed;
										}
									}
									.MuiSwitch-track {
										background: rgba($color-primary2, 0.5);
									}
								}
							}
						}
						.assessment-actions {
							@include flex;
							@include align-items(center);
							button {
								padding: 0;
								min-width: auto;
								width: 24px;
								height: 24px;
								@include border-radius(100%);
								margin-left: 5px;
								@include transition(0.25s);
								color: $color-primary1;
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
							}
							&.visible {
								opacity: 1;
							}
						}
					}
				}
			}
			&:hover {
				background: rgba($color-hover, 0.5);
				li {
					&.manage-scale-col4 {
						.assessment-actions {
							button {
								opacity: 1;
							}
						}
					}
				}
			}
			&.highlight-row {
				background-color: $color-okr-highlighter;
			}
		}
	}
}
/**assessment manage form**/
// .assessment-manage-form {
// 	padding: 0 3.75em 1.875em;
// 	.drawer-input-field {
// 		padding: 0 0 1.875em !important;
// 	}
// 	.assessment-forms-listing {
// 		.okr-list-panel-head,
// 		.assessment-list-content {
// 			ul {
// 				li {
// 					padding: 0 10px;
// 					&.assessment-col1 {
// 						width: 32.5%;
// 						padding: 0 10px 0 0;
// 					}
// 					&.assessment-col2 {
// 						width: 17.5%;
// 					}
// 					&.assessment-col3 {
// 						width: 17.5%;
// 					}
// 					&.assessment-col4 {
// 						width: 32.5%;
// 					}
// 					&.assessment-col4 {
// 						width: 32.5%;
// 					}
// 				}
// 			}
// 		}
// 		.okr-list-panel-head {
// 			ul {
// 				li {
// 					&:before {
// 						bottom: auto;
// 						top: 11px;
// 					}
// 				}
// 			}
// 		}
// 		.assessment-list-content {
// 			padding: 0.6875em 0;
// 			cursor: pointer;
// 			@include transition(0.3s);
// 			border-bottom: 1px solid $color-hover;
// 			ul {
// 				@include flex;
// 				@include align-items(center);
// 				padding: 0;
// 				li {
// 					flex: auto;
// 					&.assessment-col1 {
// 						padding: 0 10px;
// 					}
// 					&.assessment-col2 {
// 						.MuiChip-root {
// 							padding: 0 7px;
// 							color: $color-primary2;
// 							line-height: 1;
// 							border: 1px solid $color-bg-tag;
// 							&.draft-chip {
// 								color: $color-tags;
// 								border: 1px solid $color-tags;
// 								background-color: $color-okr-highlighter;
// 							}
// 						}
// 					}
// 					&.assessment-col3,
// 					&.assessment-col4 {
// 						.MuiTypography-body2 {
// 							color: $color-secondary1;
// 							font-weight: $font-weight-regular;
// 							line-height: $line-height2;
// 							min-height: 22px;
// 						}
// 					}
// 					&.assessment-col4 {
// 						@include flex;
// 						@include justify-content(space-between);
// 						.assessment-actions {
// 							@include flex;
// 							@include align-items(center);
// 							button {
// 								padding: 0;
// 								min-width: auto;
// 								width: 24px;
// 								height: 24px;
// 								@include border-radius(100%);
// 								margin-left: 5px;
// 								@include transition(0.25s);
// 								opacity: 0;
// 								color: $color-primary1;
// 								svg {
// 									width: 14px;
// 									height: 14px;
// 									path {
// 										fill: $color-primary1;
// 									}
// 								}
// 							}
// 							&.visible {
// 								opacity: 1;
// 							}
// 						}
// 					}
// 				}
// 			}
// 			&:hover {
// 				background: rgba($color-hover, 0.5);
// 				li {
// 					&.assessment-col4 {
// 						.assessment-actions {
// 							button {
// 								opacity: 1;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 		&.assessment-template-listing {
// 			.okr-list-panel-head,
// 			.assessment-list-content {
// 				ul {
// 					li {
// 						padding: 0 10px;
// 						&.assessment-col1 {
// 							width: 40%;
// 							padding: 0 10px 0 0;
// 						}
// 						&.assessment-col4 {
// 							width: 25%;
// 						}
// 					}
// 				}
// 			}
// 			.assessment-list-content {
// 				ul {
// 					li {
// 						&.assessment-col1 {
// 							padding: 0 10px;
// 							.apply-btn {
// 								margin-right: 10px;
// 								width: 30px;
// 								height: 30px;
// 								background-color: $color-white;
// 								border: 1.4px solid rgba($color-input-field, 0.5);
// 								@include border-radius(100px);
// 								color: $color-primary1;
// 								svg {
// 									width: 14px;
// 									height: 14px;
// 									path {
// 										fill: rgba($color-input-field, 0.5);
// 									}
// 								}
// 								&:hover,
// 								&.active {
// 									border: 1.4px solid $color-primary1;
// 									svg {
// 										path {
// 											fill: $color-primary1;
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.user-list-pagination {
// 		@include justify-content(flex-end);
// 		margin: 0px;
// 		flex: 1;
// 	}
// 	.sub-head-button {
// 		@include flex;
// 		@include align-items(center);
// 		@include justify-content(space-between);
// 	}
// }
.manage-form-popper-menu {
	z-index: 9999;
	width: 203px;
}
.assessment-preview-popup {
	z-index: 9999 !important;
	.close-assessment-popup {
		position: absolute;
		right: 20px;
		top: 18px;
		padding: 0px;
		width: 30px;
		height: 30px;
		@include border-radius(100%);
		z-index: 9;
		svg {
			width: 14px;
			height: 14px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.MuiDialog-container {
		.MuiDialog-paper {
			min-width: 90%;
			background-color: $color-white;
			@include custom-scrollbar;
		}
	}
}

/*assessment preview listing*/
.assessment-preview-area {
	padding: 25px 30px;
	.assessment-preview-head {
		.MuiTypography-h2 {
			padding-bottom: 7px;
		}
		.MuiTypography-body2 {
			color: $color-secondary1;
		}
	}
	.assessment-preview-list {
		padding: 20px 0 16px 42px;
		.assessment-preview-list-inner {
			min-height: 70px;
			position: relative;
			margin-bottom: 10px;
			@include flex-direction(column);
			align-items: unset;
			background: $color-bg-form;
			@include border-radius(6px);
			.assessment-preview-index {
				margin-right: 16px;
				position: absolute;
				right: 100%;
				top: 26px;
				width: 30px;
				height: 30px;
				z-index: 1;
				background: $color-white;
				border: 1.4px solid $color-primary1;
				@include border-radius(100%);
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				color: $color-primary1;
				font-size: $font-size-h5;
			}
			&:after {
				margin-right: 28px;
				position: absolute;
				right: 100%;
				top: 0;
				content: '';
				width: 1.4px;
				height: calc(100% + 10px);
				background: $color-hover;
			}
			&:first-child {
				&:after {
					top: 56px;
					height: calc(100% - 46px);
				}
			}
			&:last-child {
				&:after {
					height: 26px;
				}
			}
			.assessment-preview-row {
				> ul {
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					> li {
						padding: 22px 30px;
						@include flex;
						flex: auto;
					}
				}
			}
		}
	}
	.assessment-rating-area {
		.MuiRating-root {
			.MuiRating-label {
				width: 45px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.response-input-field {
		width: 100%;
		padding: 10px 20px;
		background: $color-white;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
	}
}
/**custom scale css start here**/
.okr-team-dropdown.scale-type-dropdown {
	min-width: 297px;
	max-width: 297px;
	.dropdown-button {
		width: 297px;
		padding: 4px 10px;
		line-height: $line-height2;
		span.MuiButton-label {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
			display: inline-block;
			padding-right: 12px;
			text-align: left;
			.icon {
				position: absolute;
				right: 7px;
				top: 50%;
				margin-top: -3px;
			}
		}
	}
	.MuiList-root {
		border-top: 1.4px solid $color-hover;
		.MuiListItem-root {
			border-bottom: 1px solid rgba($color-input-field, 0.2);
			padding: 10px 7px;
			max-height: 215px;
			svg {
				width: 14px;
				height: 14px;
				margin-right: 10px;
			}
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				line-height: $line-height4;
			}
		}
	}
	.team-dropdown {
		padding-top: 30px;
	}
	&.visibility-type-dropdown {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		.dropdown-button {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
			padding: 7px 10px;
			line-height: $line-height2;
			.text-content {
				display: inline-block;
				text-align: left;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 100%;
			}
		}
		.team-dropdown {
			padding-top: 38px;
			.MuiList-root {
				.MuiListItem-root {
					padding: 10px 14px;
				}
			}
		}
	}
}
.custom-scale-form {
	.custom-scale-top {
		.drawer-input-field {
			&:first-child {
				padding-bottom: 15px !important;
			}
		}
	}
	.scale-name-field {
		padding-bottom: 15px;
		.drawer-input-field {
			@include flex;
			@include justify-content(flex-start);
			@include align-items(center);
			.field-col2 {
				max-width: 297px;
			}
			.role-status-field {
				padding-top: 30px;
				margin-left: 30px;
				.MuiGrid-container {
					@include align-items(center);
					.MuiGrid-item {
						font-size: $font-size-h5;
						line-height: $line-height4;
						&:last-child {
							padding-left: 10px;
						}
					}
				}
				.MuiSwitch-root {
					.MuiSwitch-switchBase {
						padding: 3px;
						.MuiSwitch-thumb {
							width: 8px;
							height: 8px;
						}
						&.Mui-checked + .MuiSwitch-track {
							background: $color-completed;
						}
						&.Mui-disabled + .MuiSwitch-track {
							opacity: 1;
						}
					}
					.MuiSwitch-track {
						background: rgba($color-primary2, 0.5);
					}
				}
				&.disable-radio {
					opacity: 0.5;
				}
			}
			.MuiInputBase-root {
				padding: 4px 7px;
				.MuiInputBase-input {
					line-height: 22px;
				}
			}
		}
	}
	.drawer-blue-bg-full {
		margin-bottom: 10px !important;
	}
	.note-text {
		padding: 0px 3.75em 32px !important;
		.MuiTypography-h6 {
			font-weight: normal;
			max-width: 320px;
		}
	}
	.rating-type-listing {
		.rating-row {
			.col-1 {
				width: calc(100% - 178px);
			}
			.col-2 {
				width: 148px;
			}
		}
	}
}
.rating-type-listing {
	padding: 30px 0;
	.rating-type-head {
		.weightage-head {
			@include flex;
			@include align-items(center);
			margin-bottom: 3px;
			.MuiTypography-h5 {
				font-weight: normal;
				@include flex;
				@include align-items(center);
			}
			.MuiTypography-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
			.filter-info-box {
				margin-left: 10px;
				@include flex;
				.info-icon {
					padding: 0;
					background: none;
					margin-top: 0px;
					margin-left: 0px;
					svg {
						width: 16px;
						height: 16px;
						path {
							fill: $color-primary1;
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
		}
	}
	.rating-row {
		@include flex;
		@include justify-content(space-between);
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0px;
		}
		.MuiFormLabel-root {
			padding: 0px;
		}
		.MuiInputBase-root {
			padding: 4px 7px;
			background: $color-white;
			&.Mui-disabled {
				background: $color-bg-teamstag;
			}
			.MuiInput-input {
				line-height: 22px;
			}
		}
		.col-1 {
			width: calc(100% - 139px);
		}
		.col-2 {
			width: 109px;
		}
		.question-text {
			@include flex;
			@include align-items(flex-start);
			@include justify-content(space-between);
			position: relative;
			.question-label {
				position: relative;
				min-height: 33px;
				@include flex;
				@include align-items(center);
				width: 60px;
				> .drag-icon {
					position: absolute;
					left: -22px;
					top: 50%;
					width: 14px;
					height: 14px;
					margin-top: -7px;
					@include flex;
					svg {
						width: 14px;
						height: 14px;
					}
				}
				.MuiTypography-root {
					@include flex;
					@include align-items(center);
					font-weight: $font-weight-regular;
					span {
						margin-right: 5px;
						font-size: $font-size-h5;
						line-height: $line-height3;
						&.slide-txt {
							margin-left: 5px;
						}
					}
					svg {
						margin-left: 5px;
					}
				}
			}
			.textbox-wrap {
				width: calc(100% - 90px);
				position: relative;
				.MuiFormControl-root {
					width: 100%;
				}
			}
		}
		.weightage-wrap {
			@include flex;
			@include align-items(center);
			.MuiFormHelperText-root {
				min-width: 188px;
				margin-left: -30px;
			}
			.MuiTextField-root {
				max-width: 109px;
			}
			.MuiButton-root {
				margin-left: 15px !important;
				min-width: 24px;
				min-height: 24px;
			}
		}
		&.rating-row-option {
			.MuiTypography-h5 {
				opacity: 0.5;
			}
		}
	}
}
/** point header tooltip css start here*/
.point-tooltip {
	margin-top: 12px;
	margin-right: -40px;
	padding: 15px 5px 15px 20px;
	@include box-shadow(0 0px 30px rgba($color-primary2, 0.2));
	&::before {
		right: 37px;
		left: auto;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -10px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		min-width: 310px;
		width: 310px;
		height: auto;
		max-height: 95px;
		padding-right: 15px;
		resize: none;
		@include custom-scrollbar-small-width;
	}
	// &.point-tooltip-page {
	// 	margin-top: 7px;
	// 	margin-left: 0px;
	// 	&::before {
	// 		right: 54px;
	// 		left: auto;
	// 	}
	// }
}
/**assessment setting drawer css start here**/
.assessment-setting-drawer {
	&.drawer-form-fields {
		.drawer-blue-bg-full {
			margin-bottom: 5px;
		}
		.user-no-record {
			.MuiTypography-h4 {
				margin-bottom: 5px;
				text-transform: capitalize;
			}
			.no-sentence-case {
				font-weight: normal;
				text-transform: none;
			}
		}
	}

	.note-text {
		padding-top: 0px !important;
		.MuiTypography-h6 {
			font-weight: normal;
		}
	}
}
/**form setting parameter css start here**/
.form-setting-parameter {
	padding: 22px 0;
	.score-status-field {
		.MuiGrid-container {
			@include align-items(center);
			.MuiGrid-item {
				font-size: $font-size-h5;
				line-height: $line-height4;
				&:last-child {
					padding-left: 10px;
				}
			}
		}
		.MuiSwitch-root {
			.MuiSwitch-switchBase {
				padding: 3px;
				.MuiSwitch-thumb {
					width: 8px;
					height: 8px;
				}
				&.Mui-checked + .MuiSwitch-track {
					background: $color-completed;
				}
			}
			.MuiSwitch-track {
				background: rgba($color-primary2, 0.5);
			}
		}
	}

	.score-list-field {
		.score-row {
			@include flex;
			@include align-items(center);
			//@include justify-content(space-between);
			.col-1 {
				width: 280px;
				padding-right: 30px;
			}
			.col-2 {
				width: 162px;
			}
		}
	}
	.score-list-field {
		padding: 30px 0;
		.MuiTypography-h5 {
			font-weight: normal;
		}
		.score-type-head {
			.weightage-head {
				@include flex;
				@include align-items(center);
				margin-bottom: 3px;
				.MuiTypography-h5 {
					font-weight: normal;
					@include flex;
					@include align-items(center);
				}
				.MuiTypography-root {
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
			}
		}

		.score-row {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0px;
			}
			.MuiFormLabel-root {
				padding: 0px;
			}
			.MuiInputBase-root {
				padding: 4px 7px;
				background: $color-white;
				max-width: 132px;
				&.Mui-disabled {
					background: $color-bg-teamstag;
				}
				.MuiInput-input {
					line-height: 22px;
				}
			}
			.weightage-wrap {
				.MuiFormHelperText-root {
					min-width: 295px;
					top: 50%;
					margin-top: -25px;
					&::before {
						top: 50%;
						margin-top: -5px;
					}
					&::after {
						top: 50%;
						margin-top: -6px;
					}
				}
			}
			.total-weightage {
				.MuiFormHelperText-root {
					min-width: 180px;
					left: 50%;
					margin-left: -90px;
					bottom: auto;
					top: 100%;
					margin-top: 0px;
					&::before,
					&::after {
						top: auto;
						margin-top: 0px;
					}
				}
			}
			&.trap-row {
				.col-2 {
					@include flex;
					@include align-items(center);
				}
			}
		}
		.filter-info-box {
			margin-left: 10px;
			@include flex;
			.info-icon {
				padding: 0;
				background: none;
				margin-top: 0px;
				margin-left: 0px;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $color-primary1;
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
}

/**assessment select form css start here**/
.assessment-form-select {
	.react-select__indicators {
		display: none;
	}

	.react-select__control {
		.react-select__value-container {
			// background: url(../../../../images/search-sprite.svg) no-repeat 0 0;
			// background-position: 10px 12px;
			//padding-left: 30px;
			padding-left: 20px;
			padding-right: 20px;
			min-height: 32px;
			.react-select__multi-value {
				padding-right: 0;
				width: 99%;
				@include border-radius(0px!important);
				background: none !important;
				margin: 0px !important;
				.react-select__multi-value__label {
					width: 100%;
					.search-user-result {
						padding-left: 0px;
					}
				}
				& + .react-select__input-container {
					padding-left: 0px !important;
					position: absolute;
					opacity: 0;
				}
			}
			.react-select__multi-value__remove {
				position: absolute;
				right: 0;
				width: 14px;
				padding: 0;
				margin: 0 5px;
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
			.react-select__placeholder {
				margin-left: 12px !important;
			}
			.react-select__input-container {
				margin-left: -11px;
				padding-left: 22px !important;
			}
		}
	}
	.react-select__menu {
		.react-select__menu-list {
			.react-select__option {
				padding: 9px 20px;
				border-bottom: 1.4px solid $color-hover;
			}
		}
	}
	.search-user-result {
		@include flex;
		@include align-items(center);
		.col-1 {
			width: calc(60% - 50px);
			min-width: calc(60% - 50px);
			margin-right: 50px;
		}
		.col-2 {
			width: calc(35% - 50px);
			min-width: calc(35% - 50px);
			margin-right: 50px;
			@include flex;
			@include align-items(center);
		}
		.col-3 {
			width: 5%;
			min-width: 5%;
		}
		.form-name {
			.MuiTypography-body1 {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
		.section-count {
			margin-right: 25px;
		}
		.section-count,
		.questions-count {
			@include flex;
			@include align-items(center);
			min-width: calc(50% - 25px);
			.MuiTypography-body1 {
				margin-right: 5px;
			}
			.MuiTypography-h6 {
				font-weight: normal;
			}
		}
		.preview {
			@include flex;
			@include justify-content(flex-end);
		}
	}
}
/**assessment visibility css start here**/
.assessment-visibility {
	.assessment-form-role-settings {
		.assessment-form-role-listing {
			.assessment-form-role-item {
				ul {
					@include align-items(center);
				}
			}
		}
		.okr-list-panel-head {
			> ul {
				> li {
					padding: 0 20px;
				}
			}
		}
	}
}
.required-field {
	sup {
		margin-left: 2px;
	}
}
.assessment-list-content {
	.assessment-chip {
		&.MuiChip-root {
			padding: 0 7px;
			color: $color-primary2;
			line-height: 1;
			border: 1px solid $color-bg-tag;
			&.draft-chip {
				color: $color-tags;
				border: 1px solid $color-tags;
				background-color: $color-okr-highlighter;
			}
		}
	}
	&.heighlight-row {
		background-color: $color-okr-highlighter !important;
	}
}
