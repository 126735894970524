.admin-tabpanel-inner {
	.admin-tabpanel-head {
		padding: 0 0 20px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		min-height: 64px;
		.MuiTypography-h2 {
			font-weight: $font-weight-black;
		}
		.admin-tabpanel-head-left {
			@include flex;
			@include align-items(baseline);
		}
		.admin-tabpanel-head-right {
			@include flex;
			&.align-goals-head-right {
				@include align-items(center);
				.align-goals-download {
					margin-left: 10px;
				}
			}
		}
	}
}
.manage-org-area {
	padding: 0 25px;
}

/**user licenses details css start here**/
.user-licenses-details {
	margin-left: 8px;
	@include flex;
	@include align-items(flex-end);
	.MuiTypography-root {
		margin-right: 2px;
		line-height: 15px !important;
		&.green-color {
			color: $color-completed;
		}
		&.red-color {
			color: $color-text-info;
		}
	}
}

/**search btn css start here**/
.search-field {
	position: relative;
	background: $color-white;
	@include border-radius(40px);
	border: 1.4px solid $color-border-tile;

	.MuiInputBase-root {
		padding: 0px !important;
		border-bottom: 0px !important;
		.MuiInputBase-input {
			width: 40px;
			height: 40px !important;
			box-sizing: border-box;
			padding-left: 40px;
			@include transition(0.3s);
		}
		&.Mui-focused {
			.MuiInputBase-input {
				width: 15em;
				transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				color: $color-primary2;
			}
		}
	}
	.search-icon {
		width: 40px;
		height: 40px;
		position: absolute;
		left: 0;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		cursor: pointer;
		svg {
			width: $font-size-h5;
		}
	}
}

/** user search field css start here**/
.user-search {
	@include flex;
	@include align-items(flex-end);
	@include flex-direction(column);
	position: relative;
	.MuiInputBase-root {
		padding: 0 1em !important;
		font-weight: $font-weight-regular !important;
		font-size: $font-size-h4;
		color: $color-primary2;
		line-height: $line-height2;
		height: auto;
		@include border-radius(40px);
		@include transition(0.25s);
		background: $color-bg-form;
		max-width: 150px;
		border: 1.4px solid $color-border-tile;
		&.Mui-focused {
			border: 1.4px solid $color-primary1;
			box-shadow: 0px 0px 5px rgba($color-primary1, 0.5);
			.MuiInputAdornment-root {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
		}
		&.Mui-error {
			border: 1.4px solid $color-text-info;
		}
		.MuiInputBase-input {
			padding: 4px 0;
			//font-weight: $font-weight-regular;
			//font-size: $font-size-base;
		}
		&:before,
		&:after {
			display: none;
		}
		.MuiInputAdornment-root {
			margin-left: -5px;
			svg {
				max-width: 14px;
				max-height: 14px;
				path {
					fill: $color-primary2;
				}
			}
		}
	}
	.user-search-chip {
		margin-top: 3px;
		position: absolute;
		top: 100%;
		@include flex;
		@include justify-content(flex-end);
		ul {
			@include flex;
			@include align-items(center);
			@include justify-content(flex-end);
			padding: 0;
			.MuiListItem-gutters {
				padding: 0;
				margin-left: 0.5em;
				&.search-more {
					.MuiTypography-body1 {
						background: $color-white;
						width: 24px;
						height: 24px;
						border-radius: 50%;
						border: 1.4px solid $color-border-tile;
						line-height: 20px;
						text-align: center;
						font-size: 10px;
						font-weight: $font-weight-bold;
					}
				}
				&.clear-all {
					.MuiButton-root {
						min-width: 50px;
						margin: 0;
						height: auto;
						line-height: initial;
						font-size: $font-size-h6;
						line-height: $line-height5;
						font-weight: $font-weight-bold;
					}
				}
			}
		}
		.MuiChip-root {
			@include border-radius(24px);
			background: $color-bg-tag;
			min-height: 16px;
			min-width: 3.0625em;
			padding: 2px 2px 2px 7px;
			height: auto;
			color: $color-primary2;
			.MuiChip-label {
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				line-height: $line-height3;
				padding: 0;
				display: block;
				text-align: center;
				text-transform: uppercase;
			}
		}
		svg {
			width: 10px;
			height: 10px;
			path {
				stroke: $color-primary2;
				fill: $color-primary2;
			}
		}
		.MuiChip-deleteIcon {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			margin: 0 0 0 5px;
			width: 20px;
			height: 20px;
			&:hover {
				svg {
					path {
						stroke: $color-text-info;
						fill: $color-text-info;
					}
				}
			}
		}
	}
}

/**search list popover css start here**/
.user-search-list-popover {
	background: $color-white;
	@include box-shadow(0px 0px 30px rgba($color-primary2, 0.1));
	@include border-radius(6px);
	min-width: 280px;
	max-width: 280px;
	z-index: 99999;
	margin-right: -10px !important;
	ul.search-details-list {
		padding: 0px 0;
		position: relative;
		max-height: 255px;
		overflow-y: auto;
		width: 100%;
		@include border-radius(6px);
		@include custom-scrollbar;
		li {
			@include flex;
			@include justify-content(space-between);
			padding: 10px 1.25em;
			cursor: initial;
			border-bottom: 1px solid $color-hover;
			@include align-items(flex-start);
			.MuiListItemText-root {
				max-width: 90%;
				cursor: text;
				.MuiTypography-root {
					word-break: break-all;
					display: inline-block;
					max-width: 100%;
					width: 100%;
					white-space: initial;
				}
			}
			.MuiButtonBase-root {
				padding: 2px;
				background: none;
				width: 24px;
				height: 24px;
				margin-top: 0px;
				margin-right: -5px;
				.MuiTouchRipple-root {
					display: none;
				}
				svg {
					width: 10px;
					height: 10px;
					path {
						stroke: $color-primary2;
						fill: $color-primary2;
					}
				}
			}
			&:last-child {
				border-bottom: 0px;
			}
			&:hover {
				background-color: $color-hover;
			}
		}
	}
}
