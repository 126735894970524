@import '../sass-utils/variables';

.chat-bot-modal {
	z-index: 9999 !important;
	.MuiPaper-root {
		max-width: calc(100vw - 100px);
		width: calc(100vw - 100px);
		//max-height: calc(100vh - 64px);
		height: calc(100% - 64px);
		background: url(../../images/chatBot-bg.jpg) 0 0 / cover no-repeat;
		@include border-radius(8px);
		.btn-link {
			margin-bottom: 8px;
			height: auto;
			line-height: $line-height5;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			svg {
				width: 14px;
				height: auto;
				margin-right: 3px;
			}
			&:hover {
				text-decoration: none;
			}
		}
		.MuiDialogContent-root {
			padding: 40px 50px;
			.chat-bot-modal-content {
				height: 100%;
				@include flex;
				@include justify-content(space-between);
				border: 1.4px solid rgba($color-white, 0.5);
				background: rgba($color-new-common1, 0.6);
				backdrop-filter: blur(4px);
				@include border-radius(6px);
				.chat-bot-modal-content-left {
					padding: 20px;
					width: 80%;
					height: 100%;
				}
				.chat-bot-modal-content-right {
					padding: 20px;
					width: 20%;
					height: 100%;
					background: rgba($color-secondary1, 0.7);
					@include border-radius(0 6px 6px 0);
				}
				.chat-bot-response-area {
					margin-bottom: 15px;
					padding: 0 10px;
					min-height: calc(100% - 54px);
					max-height: calc(100% - 54px);
					overflow: auto;
					@include custom-scrollbar-small-width;
					.welcome-content {
						margin-top: 20px !important;
						height: 160px;

						.welcome-content-top {
							@include flex;
							@include flex-direction(column);
							@include align-items(center);
							object {
								margin-bottom: 20px;
							}
							.MuiTypography-h2 {
								padding-bottom: 25px;
							}
						}
						.welcome-content-bottom {
							@include flex;
							@include justify-content(center);
							.hint-box {
								cursor: pointer;
								margin-right: 15px;
								padding: 15px;
								max-width: 225px;
								border: 1.4px solid rgba($color-white, 0.2);
								@include border-radius(6px);
								background: rgba($color-white, 0.1);
							}
						}
					}
				}

				.asked-query-info {
					margin-bottom: 15px;
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.asked-query-content {
						width: calc(100% - 40px);
						padding: 10px;
						background: $color-white;
						@include border-radius(6px);
					}
					.asked-user-avatar {
					}
				}
				.generated-response-box {
					margin-bottom: 15px;
					@include flex;
					@include justify-content(space-between);
					@include align-items(flex-start);
					.chat-bot-img {
						margin-top: 10px;
						margin-right: 10px;
						width: 30px;
						height: 30px;
						padding: 4px;
						background: $color-new-common2;
						@include border-radius(30px);
					}
					.generated-response-box-inner {
						width: calc(100% - 40px);
					}
					.generated-response-content {
						width: 100%;
						padding: 10px;
						background: rgba($color-new-common1, 0.6);
						@include border-radius(6px);
						border: 1.4px solid rgba($color-white, 0.5);
					}
					.generated-response-reference {
						.MuiTypography-h6 {
							margin-top: 10px;
							padding-bottom: 10px;
						}
						ul {
							li {
								cursor: pointer;
								max-width: 60%;
								padding: 10px;
								border: 1.4px solid rgba($color-white, 0.2);
								@include border-radius(6px);
								background: rgba(251, 237, 239, 0.5);
								.download-reference {
									width: 100%;
									@include flex;
									@include align-items(center);
									@include justify-content(space-between);
									&:hover {
										text-decoration: none;
									}
								}
								span {
									@include flex;
									color: $color-primary1;
									&.ref-num {
										margin-right: 8px;
										@include flex;
										@include align-items(center);
										@include justify-content(center);
										min-width: 22px;
										max-width: 22px;
										height: 22px;
										font-size: $font-size-h6;
										font-weight: $font-weight-bold;
										border: 1.4px solid $color-primary1;
										@include border-radius(30px);
									}
									&.ref-file-name {
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
										display: -webkit-box;
										overflow: hidden;
										text-overflow: ellipsis;
										overflow-wrap: break-word;
									}
								}
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
					.response-loader-img {
						width: calc(100% - 40px);
						object {
							margin-top: 10px;
						}
					}
				}
				.chat-bot-query-sec {
					padding: 0 10px;
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					.user-chat-field {
						width: calc(100% - 40px);
						.MuiInputBase-root {
							padding: 6px 10px;
							background: $color-white;
							.MuiInputBase-input {
								line-height: $line-height3;
							}
						}
					}
					.submit-btn {
						width: 32px;
						height: 32px;
						@include border-radius(6px);
						background: $color-primary1;
						@include box-shadow(5px 8px 24px 0px rgba(6, 100, 177, 0.5));
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-white;
							}
						}
						&.disabled {
							cursor: default;
							background: $color-white;
							@include box-shadow(none);
							svg {
								path {
									fill: rgba($color-primary2, 0.5);
								}
							}
						}
					}
				}
				.chat-bot-history-sec {
					height: 100%;
					.chat-bot-history-sec-head {
						margin-bottom: 15px;
						@include flex;
						@include align-items(center);
						@include justify-content(space-between);
						.btn-small-text {
							color: $color-white;
							text-transform: unset !important;
							svg {
								margin-right: 5px;
								width: 10px;
								height: 10px;
								path {
									stroke: $color-white;
								}
							}
						}
					}
					.MuiTypography-h2,
					.MuiTypography-h5 {
						color: $color-white;
					}
					.chat-bot-history-block {
						min-height: calc(100% - 64px);
						max-height: calc(100% - 64px);
						overflow: auto;
						@include custom-scrollbar-small-width;
						.chat-bot-history-list {
							margin-bottom: 15px;
							.MuiTypography-h5 {
								margin-bottom: 5px;
							}
							ul {
								li {
									padding: 6px 10px;

									cursor: pointer;
									border: 1.4px solid transparent;
									@include border-radius(6px);
									.MuiListItemText-root {
										.MuiTypography-root {
											color: $color-white;
											-webkit-line-clamp: 1;
											-webkit-box-orient: vertical;
											display: -webkit-box;
											overflow: hidden;
											text-overflow: ellipsis;
											overflow-wrap: break-word;
										}
									}

									svg {
										margin-right: 9px;
										width: 14px;
										height: 14px;
										path {
											fill: $color-white;
										}
									}
									&:hover,
									&.active {
										border: 1.4px solid rgba($color-white, 0.3);
										background: rgba(65, 99, 144, 0.5);
									}
								}
							}
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
				.no-history-area {
					margin-top: 100px;
					@include flex;
					@include flex-direction(column);
					@include align-items(center);
					.MuiTypography-root {
						text-align: center;
						color: $color-white;
					}
					.MuiTypography-body2 {
						margin-bottom: 15px;
						font-weight: $font-weight-regular;
					}
					.MuiTypography-h2 {
						margin-bottom: 5px;
					}
				}
			}
		}
		.MuiDialogActions-root {
			padding: 0;
			position: absolute;
			right: 15px;
			top: 15px;
			button {
				padding: 0;
				svg {
					width: 14px;
					height: 14px;
				}
				&:hover {
					background: transparent;
				}
			}
		}
	}
}
