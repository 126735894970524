@import '../pages/drawer/krMetricsFileds.scss';
@import '../pages/drawer/messageTrail.scss';
@import '../pages/drawer/treeViewPopover.scss';
@import '../pages/drawer/drawerButtonGroup.scss';
@import '../pages/drawer/teamChip.scss';
@import '../pages/drawer/linkingObj.scss';

.drawer-head-common {
	position: relative;
	&::after {
		content: '';
		border-bottom: 1px solid rgba($color-primary2, 0.2);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	&.drawer-head-tabs {
		padding-top: 0;
		padding-bottom: 0;
		.drawer-tabs {
			padding: 0;
			&::after {
				display: none;
			}
		}
		.drawer-okr-links {
			padding: 0 1em;
		}
		.MuiSelect-select {
			padding: 8px 1em 8px 0;
			width: auto;
			font-size: $font-size-h4;
			background: none;
		}
		.MuiSelect-icon {
			width: 25px;
			height: 25px;
		}
	}
}
.main-drawer-panel {
	z-index: 9990 !important;
	.highcharts-legend {
		.highcharts-graph {
			display: none !important;
		}
	}
	.MuiDrawer-paper {
		overflow: inherit;
		width: 860px;
		@include panel-shadow;
	}
	.drawer-close-btn {
		width: 24px;
		height: 24px;
		position: absolute;
		left: -12px;
		top: 47px;
		background: $color-white;
		z-index: 2;
		@include box-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
		font-size: $font-size-h4;
		padding: 8px;
		@include border-radius(50%);
		svg {
			width: 10px;
			min-width: 10px;
			path {
				fill: $color-primary2;
			}
		}
		&:hover {
			background: $color-white;
		}
	}
	.drawer-area {
		.drawer-head {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			padding: 16px 60px 16px;
			min-height: 60px;
			svg {
				width: 14px;
				height: 14px;
			}
			.import-okr-count {
				margin-left: 8px;
				font-size: $font-size-h6;
				color: $color-secondary1;
				font-weight: $font-weight-bold;
				line-height: $line-height5;
			}
			.drawer-head-left {
				.MuiTypography-h3 {
					text-transform: capitalize;
				}
			}
		}
		.drawer-content {
			position: relative;
			.drawer-inner-content {
				.drawer-tabs-panel {
					max-height: calc(100vh - 215px);
					min-height: calc(100vh - 215px);
					overflow-y: auto;
					@include custom-scrollbar;
					padding: 30px 0;
					margin: 0;
					position: relative;
					&.import-okr-tabpanel {
						padding: 0;
						max-height: 100%;
						min-height: 270px;
						overflow: initial;
					}
					&.drawer-mom-meeting-panel {
						overflow-y: auto;
						overflow-y: overlay;
					}
				}
			}
		}
		.drawer-footer-actions {
			min-height: 93px;
			padding: 26px 60px;
			@include box-shadow(0px 0px 17px rgba(0, 0, 0, 0.08));
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			.sep-or {
				font-style: italic;
				font-size: $font-size-h5;
				color: $color-input-field;
			}
		}
	}

	/**main drawer title panel  css start here**/
	&.main-drawer-titlepanel {
		.drawer-area {
			.drawer-head {
				@extend .drawer-head-common;
			}
			.drawer-content {
				.drawer-inner-content {
					.drawer-content-panel {
						max-height: calc(100vh - 153px);
						min-height: calc(100vh - 153px);
						overflow-y: auto;
						@include custom-scrollbar;
						padding: 30px 0;
						margin: 0;
						position: relative;
					}
				}
			}
		}
	}
	/**main drawer panel with no footer css start here**/
	&.drawer-no-footer {
		.drawer-area {
			.drawer-head {
				@extend .drawer-head-common;
			}
			.drawer-content {
				padding: 0;
				.drawer-inner-content {
					.drawer-content-panel {
						max-height: calc(100vh - 60px);
						min-height: calc(100vh - 60px);
						overflow-y: auto;
						@include custom-scrollbar;
						padding: 30px 0;
						margin: 0;
						position: relative;
						&.okr-drawer-form-area {
							padding-left: 60px;
							padding-right: 60px;
						}
						&.kr-weekly-trends-area {
							padding-top: 32px;
						}
					}
				}
			}

			.drawer-footer-actions {
				display: none;
			}
		}
	}

	/**admin main drawer no footer css start here**/
	&.main-drawer-no-footer {
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-tabs-panel {
						max-height: calc(100vh - 120px);
						min-height: calc(100vh - 120px);
					}
				}
			}
		}
	}
	&.category-settings-drawer {
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-tabs-panel {
						max-height: calc(100vh - 215px);
						min-height: calc(100vh - 215px);
					}
				}
			}
		}
	}
	&.review-conversation-drawer {
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-tabs-panel {
						padding: 0;
						max-height: calc(100vh - 160px);
						min-height: calc(100vh - 160px);
					}
				}
			}
		}
	}

	/**main drawer sub panel css start here**/
	&.main-drawer-subpanel {
		.MuiDrawer-paper {
			overflow: inherit;
			width: 800px;
		}
		.drawer-area {
			.drawer-content {
				padding-top: 0px;
				.drawer-inner-content {
					.drawer-tabs-panel {
						max-height: calc(100vh - 153px);
						min-height: calc(100vh - 153px);
						padding: 30px 60px;
						&.drawer-upload-image-panel {
							padding: 0;
							max-height: calc(100vh - 89px);
							min-height: calc(100vh - 89px);
						}
					}
					.drawer-content-subpanel {
						@extend .drawer-tabs-panel;
						padding: 30px 60px;
					}
				}
			}
			.drawer-head {
				@extend .drawer-head-common;
			}
		}
		.drawer-form-fields {
			.drawer-input-field {
				padding: 0 0 30px;
			}
		}
	}

	/**alignment map dr drawer css start here**/
	&.alignment-dr-drawer {
		.MuiDrawer-paper {
			width: 400px;
		}
		.drawer-area {
			.drawer-content {
				.drawer-inner-content {
					.drawer-content-panel {
						max-height: calc(100vh - 54px);
						min-height: calc(100vh - 54px);
					}
				}
			}
		}
	}

	/**main drawer panel space css start here**/
	&.main-drawer-panel-space {
		.drawer-area {
			.drawer-content {
				.drawer-tabs-panel {
					padding-top: 0;
					.drawer-form-fields {
						padding-top: 30px;
					}
				}
			}
		}
	}
}
/**drawer field row**/
.drawer-field-bottom {
	margin-bottom: 30px !important;
}
.drawer-field-row {
	padding: 0 60px 30px;
}
.key-result-head {
	@include flex;
	padding-bottom: 0px !important;
}
.user-team-wrap {
	@include flex;
	align-items: center;
	margin-top: 1em;
	.action-btn {
		padding: 0px;
		background: none;
		@include border-radius(100%);
		margin-left: 16px;
		svg {
			width: 18px;
			height: 18px;
			path {
				fill: $color-primary1;
			}
		}
		&:hover {
			background: none;
		}
	}
}

/**date field**/
.drawer-date-field {
	.MuiFormControl-root {
		&:first-child {
			margin-right: 30px;
		}
	}
	.form-label {
		padding-bottom: 10px;
		position: static;
		transform: unset;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary2;
		@include flex;
		@include align-items(center);
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			vertical-align: middle;
		}
	}
	input {
		&.form-control {
			width: 240px;
			padding: 4px 10px;
			font-size: $font-size-h5;
			line-height: $line-height2;
			border: 1.4px solid rgba($color-input-field, 0.5);
			@include border-radius(6px);
			font-family: $font-family-lato;
		}
	}
	.cycle-and-remaining-days {
		margin-top: 8px;
		max-width: 240px;
		@include flex;
		@include justify-content(space-between);
	}
}
/**virtuallink drawer top head**/
.objectiveName {
	.objectiveDetails {
		@include flex;
		@include align-items(center);
		img {
			margin-right: 8px;
		}
	}
	margin-bottom: 1em;
	.tags {
		margin-top: 8px;
		margin-left: 28px;
		button {
			margin-right: 10px;
		}
	}
}

.copy-okr-tab {
	.import-okr-count {
		//margin-left: 8px;
		font-size: $font-size-h6;
		color: $color-secondary1;
		font-weight: $font-weight-bold;
		line-height: $line-height5;
		@include flex;
		@include align-items(center);
	}
}
