@import '../../sass-utils/variables';

.weekly-check-ins-wrap {
	margin-top: 0.5em;
	/**checkin head css start here**/
	.check-ins-head {
		@include flex;
		@include align-items(baseline);
		@include justify-content(space-between);
		padding: 0.8em 0 0.8em 0.625em;
		h5,
		h6 {
			color: $color-primary2;
			opacity: 0.5;
			//margin-left: 0.625em;
			&.red {
				color: $color-text-info;
				opacity: 1;
			}
		}
		.check-ins-head-left {
			@include flex;
			@include align-items(baseline);
			.MuiTypography-h2 {
				margin-right: 20px;
			}
			.check-ins-info {
				@include flex;
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
			}
		}
		.check-ins-head-right {
			@include flex;
			@include align-items(center);
			h5 {
				margin-right: 10px;
				opacity: 1;
			}
			.okr-select-dropdown {
				z-index: 99;
			}
		}
	}
	/**checkin content css start here**/
	.check-ins-content {
		padding: 0.625em 0 25px;
		background: $color-white;
		@include border-radius(6px);
		@include flex;
		@include justify-content('space-between');
		position: relative;
		overflow: hidden;
		.col-one {
			width: 100%;
			padding: 0 30px;
		}
		.col-one-third {
			width: 410px;
			padding: 0 20px;
			border-left: 1px solid $color-hover;
		}
		.col-two-third {
			width: calc(100% - 410px);
			padding: 0 1.875em;
		}
		.check-ins-content-head {
			padding: 40px 0 20px;
			@include flex;
			position: relative;
			.user-avatar {
				width: 80px;
				height: 80px;
				@include border-radius(100%);
				font-size: 2em;
				margin-top: 0px;
			}
			.user-info-details {
				position: relative;
				flex: 1 1;
				width: calc(100% - 95px);
			}
			.user-info {
				margin-right: 60px;
				max-width: 205px;
				@include align-items(center);
			}
		}
		.back-to-main {
			padding-bottom: 0.625em;
			position: absolute;
			left: 0;
			top: 10px;
		}

		.user-quick-search-type2 {
			margin-top: 1.25em;
			min-width: 348px !important;
			.rbt-menu#async-pagination {
				min-width: 350px !important;
			}
		}

		/** check-ins right side bar css start here**/
		.action-btn {
			width: 30px;
			height: 30px;
			@include border-radius(100%);
			margin-top: 16px;
			margin-left: -18px;
			padding: 0px;
			z-index: 9;
			border: 1.4px solid transparent;
			.MuiTouchRipple-root {
				display: none;
			}
			&:hover {
				background: none;
			}
			&.right-icon {
				border: 1.4px solid $color-primary1;
				background: $color-primary1;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $color-white;
						//stroke: $color-white;
						//stroke-width: 2px;
					}
				}
			}
			&.cancel-icon {
				//z-index: -9;
				// svg {
				// 	width: 20px;
				// 	height: 20px;
				// }
				// &:hover {
				// 	background: $color-secondary2;
				// }
			}
		}
		.drawer-close-btn {
			left: -413px;
			top: 24px;
			z-index: 99;
		}
		.check-ins-right-sidebar {
			padding-left: 0px;
			border-left: 1px solid $color-hover;
			background: $color-bg-form;
			@include border-radius(0px 6px 6px 0px);
			position: absolute;
			right: 0;
			top: 0px;
			height: 100%;
			width: 30px;

			.check-ins-direct-reportees {
				width: 431px;
				padding: 25px 30px 45px;
				border-left: 1px solid $color-hover;
				background: $color-bg-form;
				@include border-radius(0px 6px 6px 0px);
				position: absolute;
				right: -401px;
				top: 0px;
				height: 100%;
				@include transition(0.1s);
				&.active {
					right: 0px;
					@include box-shadow(-3px 0px 10px rgba($color-primary2, 0.12));
					z-index: 9;
				}
				&:hover {
					// right: 0px;
					// @include box-shadow(-3px 0px 10px rgba($color-primary2, 0.12));
					// z-index: 9;
					// &.active {
					// 	z-index: 0;
					// }
				}
				// &.redirect-active {
				// 	right: -400px;
				// 	box-shadow: none;
				// }
				.member-list-wrap {
					height: 100%;
				}
				.checkins-head {
					margin-bottom: 20px;
					@include flex;
				}
			}
		}
		.loading-center {
			min-height: 300px;
		}
	}
}

/**checkins search css start here**/
.check-in-search {
	margin-top: 1.25em;
	max-width: 390px;
	> div {
		background: $color-white;
		padding: 0 0.625em;
		z-index: 2;
	}
	.rbt-aux {
		display: none;
	}
}

/**member list css start here**/
.reportees-members {
	margin-top: 2.25em;
	height: 100%;
	> h5 {
		//padding: 0 0.625em !important;
	}
	.reportees-members-list {
		margin-top: 0.625em;

		li {
			padding: 10px 0.625em;
			@include align-items(center);
			margin-bottom: 8px;
			@include border-radius(6px);
			cursor: pointer;
			&:hover {
				background: $color-hover;
			}
			.user-info {
				flex: 1 1;
			}
			.member-status {
				@include flex;
				margin-top: 3px;
				.week-status {
					margin: 0 0.4375em;
					&:last-child {
						margin-right: 0px;
					}
				}
			}
			&.selected {
				background: $color-secondary2;
			}
		}
	}
	.no-record {
		margin-top: 2.25em;
		text-align: center;
		padding: 0 30px;
		object {
			margin-bottom: 15px;
		}
		h2 {
			margin-bottom: 5px;
		}
	}
	.reportees-members-inner {
		max-height: 90%;
		overflow: auto;
		@include custom-scrollbar;
	}
}
/* Weekly Checkins Questions Answers */
.ques-ans-area {
	padding-right: 30px;
	padding-bottom: 20px;
	min-height: 300px;
	.ques-ans-head {
		@include flex;
		@include justify-content(flex-end);
		.cont-style {
			@include flex;
			@include flex-direction(column);
			@include justify-content(flex-start);
			@include align-items(flex-end);
		}
		.text-style {
			margin-right: 15px;
			color: $color-primary2;
			opacity: 0.5;
		}
		.btn-link {
			height: auto;
			line-height: $line-height2;
			svg {
				margin-right: 5px;
				path {
					fill: $color-primary1;
				}
			}
			&.Mui-disabled {
				svg {
					path {
						fill: rgba(0, 0, 0, 0.26);
					}
				}
			}
		}
	}
	.ques-ans-list {
		// padding: 1.25em 0 1em 2.625em;
		padding: 1.25em 0 0 0;
		.ques-head {
			margin-bottom: 13px;
		}
		.ques-ans-list-inner {
			position: relative;
			padding: 30px 30px 35px;
			margin-bottom: 10px;
			@include flex-direction(column);
			align-items: unset;
			background: $color-bg-form;
			@include border-radius(6px);
			.editor-active {
				.ck.ck-editor__main > .ck-content {
					@include border-radius(0px 0px 0px 6px !important);
				}
			}
			.accomplish-ques-title {
				@include flex;
				@include justify-content(space-between);
				margin-bottom: 11px;
				.acc-task-import-btn {
					padding: 0;
					max-width: 240px;
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					line-height: $line-height5;
					color: $color-primary1;
					svg {
						margin-right: 10px;
						width: 16px;
						height: 16px;
						path,
						rect {
							fill: $color-primary1;
						}
					}
					&:hover {
						background-color: transparent;
					}
				}
			}
			.qa-list-index {
				display: none;
				// margin-right: 1em;
				// position: absolute;
				// right: 100%;
				// top: 26px;
				// width: 30px;
				// height: 30px;
				// z-index: 1;
				// background: $color-white;
				// border: 1.4px solid $color-primary1;
				// @include border-radius(100%);
				// @include flex;
				// @include align-items(center);
				// @include justify-content(center);
				// color: $color-primary1;
				// font-size: $font-size-h5;
			}
			.ans-box {
				position: relative;
				//margin-top: 13px;
				margin-top: 2px;
				.MuiFormControl-root {
					min-width: 100%;
				}
				.inactive {
					textarea {
						background: $color-hover !important;
					}
				}
				.MuiInputBase-root {
					padding: 0;
					//border: 1.4px solid transparent;
					@include border-radius(6px);
					@include transition(0.25s);
					&:before,
					&:after {
						display: none;
					}
					.MuiInputBase-input {
						@include transition(0.25s);
						height: auto;
						padding: 7px 12px;
						font-size: $font-size-h5;
						line-height: 1.25;
						//border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						background: $color-white;
					}
					&.Mui-focused {
						textarea {
							@include default-shadow;
						}
					}
				}
				.MuiTypography-body2 {
					font-size: $font-size-h5;
					line-height: 19px;
					color: $color-primary2;
					font-weight: $font-weight-regular;
				}
				.accomplish-answer {
					.MuiInputBase-root {
						position: relative;
						.MuiInputBase-input {
							box-sizing: border-box;
							width: calc(100% - 206px);
							background-color: transparent;
						}
						&.Mui-focused {
							.MuiInputBase-input {
								box-shadow: none;
							}
						}
						.MuiInputAdornment-root {
							height: auto;
							position: absolute;
							right: 10px;
							top: 5px;
							.MuiTypography-body1 {
								font-size: $font-size-h5;
								color: rgba($color-primary2, 0.5);
							}
						}
					}
					&.accomplish-ans-edit {
						.MuiInputBase-root {
							border: 1.4px solid $color-primary1 !important;
							@include border-radius(6px 6px 0 6px);
						}
					}
				}
				.linked-kr-chip-box {
					@include flex;
					@include align-items(center);
					@include justify-content(flex-end);
					width: 206px;
					height: 32px;
					position: absolute;
					right: 0;
					top: 1.4px;
					@include border-radius(6px);
					.linked-kr-chip {
						margin: 0 10px;
						@include flex;
						@include align-items(center);
						cursor: pointer;
						padding: 0 10px;
						height: 18px;
						font-size: $font-size-h6;
						color: $color-secondary1;
						font-weight: $font-weight-bold;
						line-height: $line-height4;
						background-color: #f1fbfc;
						border: 1px solid $color-hover;
						@include border-radius(20px);
						svg {
							width: 11px;
							height: 11px;
							margin-right: 5px;
							path {
								fill: $color-secondary1;
								stroke: $color-secondary1;
							}
						}
					}
				}
				.accomplish-task-actions {
					position: absolute;
					right: 0;
					top: 100%;
					z-index: 1;
					button {
						padding-top: 4px;
						padding-bottom: 4px;
						min-height: 26px;
						&:hover {
							background-color: transparent;
						}
						&.acc-task-save-btn {
							padding-left: 12px;
							padding-right: 12px;
							@include border-radius(0 0 6px 6px);
							background-color: $color-primary1;
							svg {
								path {
									fill: $color-white;
								}
							}
						}
						&.acc-task-cancel-btn {
							background: $color-bg-form;
							svg {
								width: 12px;
								height: 12px;
								path {
									fill: $color-primary1;
									stroke: $color-primary1;
								}
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
			}
			.action-btn {
				@include flex;
				width: 100%;
				@include justify-content(flex-end);
				margin-top: -2px;
				padding-right: 5px;
				.ans-edit-actions {
					@include flex;
					button {
						padding: 0.1em;
						min-width: auto;
						border-radius: 0px 0px 4px 4px;
						svg {
							width: 12px;
							height: 12px;
							path {
								fill: $color-primary1;
							}
						}
						&.task-edit-save {
							background: $color-primary1;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-white;
								}
							}
						}
						&.task-edit-cancel {
							margin-right: 2px;
							color: rgba($color-input-field, 0.5);
							svg {
								path {
									fill: rgba($color-input-field, 0.5);
									stroke: rgba($color-input-field, 0.5);
								}
							}
							&:hover {
								background-color: transparent;
								svg {
									path {
										fill: $color-primary2;
										stroke: $color-primary2;
									}
								}
							}
						}
					}
				}
				.saving-changes {
					text-transform: uppercase;
					font-weight: $font-weight-bold;
					color: rgba($color-primary2, 0.5);
					@include flex;
					@include align-items(center);
					padding-top: 5px;
					svg {
						width: 14px;
						margin-right: 7px;
						path {
							fill: $color-primary2;
						}
					}
				}
			}
			// &:after {
			// 	margin-right: 1.75em;
			// 	position: absolute;
			// 	right: 100%;
			// 	top: 0;
			// 	content: '';
			// 	width: 1.4px;
			// 	height: calc(100% + 10px);
			// 	background: $color-hover;
			// }
			// &:first-child {
			// 	&:after {
			// 		top: 56px;
			// 		height: calc(100% - 46px);
			// 	}
			// }
			// &:last-child {
			// 	&:after {
			// 		height: 26px;
			// 	}
			// }
			.count-head {
				.count-head-text {
					padding: 15px 0 8px;
				}
				.count-head-no-task {
					padding: 18px 0 0;
					@include flex;
					@include justify-content(center);
				}
			}
			.accomplish-task {
				.ans-box {
					.accomplish-ans-box {
						@include flex;
						@include align-items(center);
						&:hover {
							.MuiInputBase-root {
								border: 1.4px solid rgba($color-input-field, 0.5);
								&.Mui-focused {
									border: 1.4px solid $color-primary1;
								}
							}
							.delete-btn {
								opacity: 1;
							}
						}
						&.not-editable {
							&:hover {
								.MuiInputBase-root {
									border: 1.4px solid transparent;
								}
							}
						}
						.MuiInputBase-root {
							border: 1.4px solid transparent;
							.MuiInputBase-input {
								padding-left: 35px;
							}
							&.Mui-focused {
								border: 1.4px solid $color-primary1;
							}
						}
						.MuiCheckbox-root {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 1;
							&:hover {
								background-color: transparent !important;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
						.accomplish-task-box {
							position: relative;
							flex: 1;
							&.completed-task {
								//text-decoration: line-through;
								.MuiInputBase-root {
									opacity: 1;
								}
								.MuiInputBase-input {
									color: rgba($color-primary2, 1);
									opacity: 1;
								}
							}
						}
						.delete-btn {
							position: absolute;
							left: 100%;
							top: 5px;
							@include transition(0.25s);
							opacity: 0;
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background-color: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
					.timestamp {
						margin-left: 35px;
						margin-top: 5px;
					}
				}
			}
			.ck {
				&.ck-editor__main {
					> .ck-content {
						min-height: 100px;
					}
				}
			}
			&:last-child {
				margin-bottom: 0px;
				@include border-radius(6px 6px 0 0);
			}
			.checkins-task-area {
				.create-task-block {
					padding: 0px;
					background: none;
					border: 0px;
					border-radius: 0px;
					.textfield-wrap {
						.MuiInputBase-root {
							background: transparent;
						}
					}
				}
				.task-list-area {
					.task-list-head {
						margin-bottom: 20px;
						@include align-items(center);
						.task-list-head-left {
							.is-complted-switch {
								//margin-left: 0;
							}
						}
						.task-list-head-right {
							.list-filter-btn,
							.task-sorting-btn {
								background: $color-bg-filterbtn;
								&.active {
									background: $color-white;
								}
							}
							.user-search {
								.MuiInputBase-root {
									background: $color-white;
								}
							}
						}
					}
					.task-list-panel-area {
						.task-list-panel-head {
							padding-left: 0;
						}
					}
				}
				.welcome-content {
					margin-top: 30px;
				}
			}
			/* Planned and Accomplished New design*/
			// &.planned-accomplished {
			// 	padding: 0;
			// 	background: transparent;
			// 	.accomplish-ques-title {
			// 		margin: 0;
			// 		padding: 30px 30px 12px;
			// 		background: $color-bg-form;
			// 		@include border-radius(6px 6px 0 0);
			// 	}
			// 	.checkins-task-area {
			// 		.create-task-block {
			// 			padding: 0px 30px;
			// 			background: $color-bg-form;
			// 			border: 0px;
			// 			border-radius: 0px;
			// 			.textfield-wrap {
			// 				.MuiInputBase-root {
			// 					background: transparent;
			// 				}
			// 			}
			// 		}
			// 		.task-list-area {
			// 			.task-list-head {
			// 				margin: 0;
			// 				padding: 25px 30px 10px;
			// 				@include align-items(center);
			// 				background: $color-bg-form;
			// 				.task-list-head-left {
			// 					.is-complted-switch {
			// 						margin-left: 0;
			// 					}
			// 				}
			// 				.task-list-head-right {
			// 					.list-filter-btn,
			// 					.task-sorting-btn {
			// 						background: $color-bg-filterbtn;
			// 						&.active {
			// 							background: $color-white;
			// 						}
			// 					}
			// 					.user-search {
			// 						.MuiInputBase-root {
			// 							background: $color-white;
			// 						}
			// 					}
			// 				}
			// 			}
			// 			.full-rectangular-card {
			// 				margin-top: 0;
			// 				background: $color-bg-form;
			// 				border: 0px;
			// 			}
			// 			.task-list-panel {
			// 				&:after {
			// 					background-color: $color-border-tile;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}
	}
	.ques-ans-list-bottom {
		@include flex;
		@include justify-content(space-between);
		//@include align-items(center);
		padding: 0;
		background: $color-bg-form;
		@include border-radius(0 0 6px 6px);
		.ques-ans-notes {
			max-width: 450px;
			padding-top: 11px;
			.short-des-text {
				font-size: $font-size-h6;
				font-weight: $font-weight-regular;
				font-style: normal;
				color: rgba($color-primary2, 0.5);
			}
			// p {
			// 	font-size: $font-size-h5;
			// 	font-style: italic;
			// 	font-weight: $font-weight-bold;
			// 	line-height: $line-height4;
			// 	opacity: 0.7;
			// 	strong {
			// 		font-weight: $font-weight-bold;
			// 	}
			// }
		}
	}
	.checkins-no-data {
		width: 100%;
		min-height: 400px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		.no-record {
			text-align: center;
			position: relative;
			object {
				margin-bottom: 15px;
				position: relative;
			}
			h2 {
				margin-bottom: 5px;
			}
			&::after {
				content: '';
				position: absolute;
				top: 0px;
				left: 0;
				width: 100%;
				height: 210px;
			}
		}
	}
}
.tab-scroller {
	width: auto;
	max-width: 872px;
	margin-top: 0;
	position: relative;
	@include flex;
	background: $color-bg-tabscroller;
	@include border-radius(100px);
	.accomplished-text {
		position: absolute;
		left: 58px;
		top: 50px;
		z-index: 9;
		pointer-events: none;
		font-size: $font-size-h6;
		line-height: 12px;
		font-weight: $font-weight-bold;
		color: rgba($color-primary2, 0.5);
		//text-transform: uppercase;
	}

	.MuiTabs-root {
		min-height: 36px;
		//background: $color-bg-tabscroller;
		padding: 0 5px 0 0;
		//	@include border-radius(100px);
		max-height: 74px;
	}
	.MuiTab-root {
		margin: 0px 5px 0px;
		opacity: 1;
		position: relative;
		overflow: initial;
		padding: 6px 6px;
		@include border-radius(100px);
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		line-height: $line-height5;
		text-transform: none;
		color: $color-primary2;
		border: 1.4px solid $color-white;
		min-width: 145px;
		min-height: 32px;
		background: $color-white;
		@include flex;
		@include align-items(center);
		@include justify-content(center);

		.MuiTouchRipple-root {
			display: none;
		}

		.MuiTab-wrapper {
			@include flex-direction(row);
		}
		&:hover {
			box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
		}
		&.Mui-selected {
			border: 1.4px solid $color-primary1;
			box-shadow: 4px 5px 9px 0px rgba($color-primary1, 0.12);
			color: $color-primary1;
			&.planning-tab {
				// background: $color-secondary1;
				// color: $color-white;
				// border-color: $color-white;
				// @include box-shadow(-3px 5px 9px rgba($color-secondary1, 0.3));
				border: 1.4px solid $color-primary1;
				box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
			}
		}
		&.planning-tab {
			color: $color-primary1;
			border: 1.4px solid $color-primary1;
			.week-status {
				&.checked-in {
					//border-color: $color-white;
				}
				&.not-yet-checked {
					//background-color: transparent;
					//border-color: $color-primary1;
				}
			}
			&::after {
				position: absolute;
				left: 0px;
				top: 100%;
				pointer-events: none;
				width: 100%;
				text-align: center;
				font-size: $font-size-h6;
				line-height: 12px;
				font-weight: $font-weight-bold;
				color: rgba($color-primary2, 0.5);
				//text-transform: uppercase;
				margin-top: 6px;
			}
		}
		&.exc-plan-tab {
			border: 1.4px solid rgba($color-input-field, 0.5);
			&::after {
				content: 'Executing Week';
			}
		}
		&.future-plan-tab {
			border: 1.4px solid rgba($color-input-field, 0.5);
			&::after {
				content: 'Planning Week';
			}
		}
	}
	.MuiTabScrollButton-root {
		width: 36px;
		max-height: 74px;
		opacity: 1;
		.MuiTouchRipple-root {
			display: none;
		}
		&::after {
			// content: '';
			// background: url('../../../images/tab-shadow.png') no-repeat right center #f8f9fb;
			// background-size: 15px 70px;
			// width: 15px;
			// height: 74px;
			// position: absolute;
			// right: 0px;
			// top: 0px;
		}
		svg {
			font-size: 1.5em;
		}
		&.Mui-disabled {
			opacity: 1;
			svg {
				opacity: 0.4 !important;
			}
			&::after {
				display: none;
			}
		}
		&:hover {
			svg {
				color: $color-primary1;
			}
		}
	}

	.MuiTabs-indicator {
		display: none;
	}

	.MuiTabs-scrollable {
		padding: 13px 0 5px !important;
		max-width: 620px;
		& + .MuiTabScrollButton-root {
			margin-left: 5px;
			&::after {
				@include transform(rotate(180deg));
				right: auto;
				left: 0px;
			}
			&.Mui-disabled {
				&::after {
					display: none;
				}
			}
		}
	}
}

.loading-center {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}
.highcharts-tooltip > span {
	background: $color-primary2;
	border: 0px;
	@include border-radius(2px);
	box-shadow: none;
	padding: 8px;
	z-index: 9999 !important;
}
.link-to-kr-list {
	z-index: 9;
	position: absolute;
	left: 20%;
	top: 100%;
	width: 80%;
	max-height: 348px;
	border: 1.4px solid rgba($color-primary1, 0.5);
	@include default-shadow;
	@include border-radius(6px);
	background-color: $color-white;
	max-height: 240px;
	overflow-y: auto;
	overflow-x: hidden;
	@include custom-scrollbar;
	.my-kr-title {
		padding: 10px;
		background-color: $color-secondary2;
		@include border-radius(6px 6px 0 0);
		.MuiTypography-body1 {
			font-size: $font-size-h5;
			color: $color-primary1;
			font-weight: $font-weight-bold;
		}
	}
	.my-kr-list-box {
		padding: 10px 20px;
		.my-kr-list-title {
			@include flex;
			@include justify-content(space-between);
			.my-kr-list-unlink-btn {
				cursor: pointer;
				width: 14px;
				height: 14px;
			}
		}
		.my-kr-info {
			margin-top: 7px;
			@include flex;
			@include align-items(center);
			.teams-chips {
				margin: 0;
				.MuiChip-root {
					height: 20px;
					padding: 0;
					@include border-radius(12px);
					border: 1.4px solid $color-white;
					.MuiChip-label {
						padding-left: 7px;
						padding-right: 7px;
						font-size: 10px;
						line-height: 20px;
					}
				}
			}
		}
		&:hover {
			background-color: $color-hover;
		}
	}
	&.linked-kr-popup {
		overflow: initial;
		width: 670px;
		left: auto;
		right: 0;
		&:after,
		&:before {
			border: solid transparent;
			content: ' ';
			display: block;
			height: 0;
			position: absolute;
			pointer-events: none;
			width: 0;
			bottom: 100%;
		}
		&:after {
			border-color: rgba(255, 255, 255, 0);
			border-bottom-color: $color-secondary2;
			right: 21.4px;
			margin-left: -8px;
			border-width: 8px;
		}
		&:before {
			border-color: rgba(255, 255, 255, 0);
			border-bottom-color: rgba($color-primary1, 0.5);
			right: 20px;
			margin-left: -9.4px;
			border-width: 9.4px;
		}
	}
}
.common-save-loader {
	position: absolute;
	right: 0;
	top: 100%;
	margin-top: 8px;
	@include flex;
	@include justify-content(flex-end);
	@include align-items(center);
	svg {
		margin-right: 2px;
		width: 14px;
		height: 14px;
	}
}

/**checkins visibility settings css start here**/
.checkins-drawer-setting {
	.users-listing {
		padding-top: 30px;
		padding-bottom: 30px;
		.users-list-row {
			margin: 0 -0.75em;
			.MuiList-root {
				@include align-items(center);
			}
			&:hover {
				background: $color-bg-teamstag;
			}
		}
		.okr-list-panel-head {
			padding: 0px 0 15px;
			.txt-wrap {
				font-weight: normal;
				font-size: $font-size-h6;
			}
			> ul {
				> li {
					height: 16px;
				}
			}
		}
		.userCount {
			padding-top: 0px;
		}
		.user-info {
			width: 100%;
			.user-info-details {
				width: calc(100% - 50px);
			}
		}
		.user-col2 {
			.MuiTypography-body1 {
				word-break: break-all;
				max-width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 20px;
			}
			.btn-cross {
				right: 0px !important;
				top: 50% !important;
				margin-top: -9px;
			}
		}
		.user-name {
			@include flex;
			@include align-items(center);
			width: 100%;
			.MuiTypography-body1 {
				word-break: break-all;
				max-width: 100%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.owner-tag {
				@include flex;
				@include align-items(center);
				margin-left: 10px;
				min-width: 125px;
				.owner-chip {
					@include border-radius(23px);
					color: $color-secondary1 !important;
					text-transform: uppercase;
					padding: 0 7px !important;
				}
			}
		}
	}
	.search-field-row {
		.MuiTypography-h6 {
			margin-top: 8px;
			font-weight: normal;
		}
	}
}
.checkins-add-member-sec {
	padding: 1.875em 0;
	.checkins-des {
		margin-right: 0.625em;
	}
	.checkins-add-btn-wrap {
		@include flex;
		.checkins-add-btn {
			@include flex;
			@include align-items(center);
			margin-top: 20px;
			cursor: pointer;
			.MuiTypography-subtitle1 {
				margin-left: 0.625em;
			}
		}
	}
}
.user-quick-search-popover {
	&.check-ins-quick-search {
		.MuiPopover-paper {
			margin-top: -29px;
			margin-left: -15px;
		}
	}
}

/**other member list css start here**/
.other-member-section {
	margin-top: 10px;
	.other-member-head {
		position: relative;
		span {
			background: $color-bg-form;
			padding-right: 4px;
			z-index: 9;
			position: relative;
		}
		&::after {
			content: '';
			border-bottom: 2px solid #efefef;
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0px;
			margin-top: 1px;
		}
	}
}

.timestamp-weekly-task {
	margin-left: 35px;
	margin-bottom: 5px;
	.MuiTypography-h6 {
		color: rgba($color-primary2, 0.5) !important;
	}
}
.select-checkin {
	@include flex;
	@include align-items(center);
	margin-right: 15px;
	.MuiTabs-root {
		min-height: 53px;
	}
	.select-week-btn {
		.calendar-btn {
			@include flex;
			width: 14px;
			height: 14px;
			//margin-left: -28px;
			//margin-top: -10px;
			.MuiButton-root {
				padding: 0;
				height: auto !important;
				margin: -5px 0 0 0 !important;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					background: transparent;
				}
			}
		}
	}

	.selected-cal-chip {
		margin: -21px 0 0 0;
		opacity: 1;
		position: relative;
		overflow: initial;
		padding: 5px 6px;
		@include border-radius(100px);
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		line-height: $line-height5;
		text-transform: none;
		color: $color-primary2;
		border: 1.4px solid $color-primary1;
		min-width: 145px;
		min-height: 32px;
		background: $color-white;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		box-shadow: 4px 5px 9px 0px rgba($color-primary1, 0.3);
		cursor: pointer;
		&::after {
			position: absolute;
			left: 0px;
			top: 100%;
			pointer-events: none;
			width: 100%;
			text-align: center;
			font-size: $font-size-h6;
			line-height: 12px;
			font-weight: $font-weight-bold;
			color: rgba($color-primary2, 0.5);
			//text-transform: uppercase;
			margin-top: 6px;
			content: 'Upcoming Week';
		}
		.editIcon {
			width: auto;
			height: auto;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
			text-transform: none;
			margin-left: 0px !important;
			.MuiTouchRipple-root {
				display: none;
			}
			&:hover {
				background: none;
			}
		}

		&:hover {
			box-shadow: 4px 5px 9px 0px rgba($color-primary1, 0.3);
		}
	}
}
.calender-control-popover {
	&.future-checkins-calender {
		.date-range-header {
			display: none;
		}
	}
}

@media screen and (max-width: 1400px) {
	.weekly-check-ins-wrap {
		.check-ins-content {
			.check-ins-content-head {
				.user-info {
					margin-right: 15px;
				}
			}
		}
	}
}
