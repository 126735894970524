.department-collapsible {
	.department-collapsible-body {
		.department-collapsible-head {
			.department-collapsible-head-content {
				.rotating-arrow {
					padding: 15px 10px;
					@include flex;
					cursor: pointer;
					border-bottom: 1.4px solid $color-hover;
					svg {
						@include transition(0.5s);
						margin-top: 4px;
						margin-right: 12px;
						width: 14px;
						height: 14px;
					}
					&:hover {
						background: $color-hover;
					}
					&.expand {
						border-bottom: 1.4px solid transparent;
						background: $color-hover;
					}
				}
			}
		}
		.department-collapsible-content {
			padding: 10px 0;
		}
		&.expand-row {
			border-bottom: none;
			.rotating-arrow {
				svg {
					@include transform(rotate(90deg));
				}
			}
		}
	}
}
.department-content {
	.department-title {
		position: relative;
		margin: 10px 0;

		.department-name {
			position: relative;
			background: $color-white;
			font-size: $font-size-h6;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			padding-right: 15px;
		}
		&::before {
			content: '';
			width: 100%;
			height: 1.4px;
			background: $color-hover;
			position: absolute;
			left: 0;
			top: 8.5px;
		}
	}
	.linked-okr-grouped-by {
		margin-bottom: 10px;
		@include flex;
		@include align-items(center);
		h4 {
			margin-right: 10px;
		}
		h6 {
			text-transform: uppercase;
		}
	}
	.no-record-txt {
		text-align: center;
		padding: 50px 0;
	}
	&.okr-library-department {
		position: relative;

		.MuiAccordion-root {
			margin-bottom: 5px;
			@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
			@include border-radius(6px);
			&:before {
				display: none;
			}
		}
		.department-title {
			width: 100%;
			padding-left: 18px;
			.MuiTypography-h6 {
				@include flex;
				@include align-items(center);
			}
			&::before {
				width: calc(100% - 34px);
				left: 34px;
				top: 8px;
			}
		}
		.MuiCheckbox-root {
			svg {
				width: 14px;
				height: 14px;
			}
			&.department-checkbox {
				position: absolute;
				left: 22px;
				top: 8px;
				z-index: 1;
			}
		}
		.MuiAccordionSummary-root {
			padding: 0 10px;
			&.Mui-expanded {
				min-height: 48px;
			}
			.MuiAccordionSummary-content {
				margin: 0;
			}
			.MuiAccordionSummary-expandIcon {
				margin-right: 0;
				margin-left: -12px;
				order: -1;
				svg {
					width: 14px;
					height: 14px;
				}
				&.Mui-expanded {
					transform: rotate(90deg);
				}
			}
		}
		.MuiAccordionDetails-root {
			.import-okr-list-outer {
				width: 100%;
			}
		}
	}
}
.import-okr-list {
	padding: 15px 5px 0 16px;
	margin-bottom: 10px;
	@include transition(0.3s);
	@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
	@include border-radius(6px);
	&:last-child {
		margin-bottom: 20px;
	}
	.MuiCheckbox-root {
		margin-top: 3px;
		margin-right: 10px;
		padding: 0;
		&:hover {
			background: transparent !important;
		}

		.MuiTouchRipple-root {
			display: none;
		}
	}
	.import-obj-col {
		padding-right: 15px;
		@include flex;
		@include align-items(flex-start);
		.MuiTypography-body1 {
			flex: 1;
			font-weight: $font-weight-bold;
		}
	}
	.import-okr-actions {
		margin-left: 27px;
		padding: 8px 0 15px;
		.btn-blue-chip {
			font-size: $font-size-subtitle2;
			text-transform: uppercase !important;
			border-radius: 20px;
			color: $color-primary1;
			background: $color-border-tile;
			svg {
				margin-right: 5px;
				width: 7px;
				height: 7px;
				path {
					stroke-width: 3px;
					fill: $color-primary1;
					stroke: $color-primary1;
				}
			}
		}
	}
	.import-kr-list {
		padding: 5px 0;
		@include flex;
		@include align-items(flex-start);
		.MuiCheckbox-root {
			margin-top: 18px;
		}
		.import-kr-content {
			flex: 1;
			padding: 15px;
			@include border-radius(6px);
			background: $color-hover;
		}
	}
	&.expanded-okr {
		@include box-shadow(0px 0px 20px rgba($color-primary2, 0.1));
	}
}

/**myokr list**/
.import-myokr-list {
	.import-obj-row,
	.import-kr-row {
		@include flex;
		@include justify-content(space-between);
		.import-obj-title {
			@include flex;
			@include align-items(flex-start);
		}
		.import-obj-col {
			@include flex-direction(column);
		}
		.import-obj-col,
		.import-kr-col {
			width: 60%;
			margin-right: 20px;
			padding-right: 0px;
			max-width: 427px;
		}
		.import-obj-progress-col,
		.import-kr-progress-col {
			width: 32%;
			@include flex;
			@include justify-content(flex-end);
		}
	}
	.import-kr-row {
		.import-kr-col {
			width: 58.78%;
			max-width: 385px;
		}
	}
	.okr-status-info {
		@include flex;
		@include align-items(center);
		padding-left: 26px;
		margin-top: -7px;
		margin-bottom: 2px;
		.draft-chip {
			margin-right: 7px;
			height: auto;
			padding: 0px;
			background: $color-okr-highlighter;
			border: 1px solid $color-border-info;
			.MuiChip-label {
				font-size: $font-size-subtitle2 !important;
				font-weight: $font-weight-bold;
				color: $color-tags;
				line-height: $line-height5;
				text-transform: uppercase;
				padding: 0 7px;
			}
		}
	}
	.import-okr-actions,
	.import-kr-actions {
		@include flex;
		@include align-items(center);
	}
	.import-kr-actions {
		padding-top: 8px;
	}
	.teams-info-chip {
		@include flex;
		@include align-items(center);
		margin-left: 15px;
		.team-avatar-smaller {
			height: 20px;
			min-width: 26px;
			@include border-radius(100px);
			font-size: $font-size-subtitle2;
			line-height: $line-height3;
			background: $color-bg-info;
			color: $color-tags;
			text-transform: uppercase;
			border: 1.4px solid $color-bg-teamstag;
			margin-right: 8px;
			padding: 0px;
			img {
				@include border-radius(100px);
			}
			.MuiChip-label {
				padding: 0px 7px;
			}
			.MuiChip-icon {
				width: 8px;
				height: 8px;
				margin-right: -3px;
				margin-left: 7px;
			}
		}
	}
}
/**import people search css start here**/
.import-src-people {
	padding-bottom: 15px !important;
	.searched-people {
		@include flex;
		@include align-items(center);
		.user-info {
			margin-right: 15px;
		}
	}
}
/**Import my okr css**/
.src-okr-head {
	@include flex;
	align-items: center;
	.search-action-field {
		width: 78%;
		margin-right: 15px;
		min-width: 570px;
	}
	.cycle-dropdown-btn {
		min-width: 142px;
	}
}
/**kr progress static css start here**/
.import-progress {
	max-width: 212px;
	width: 100%;
	margin-right: 15px;
	.kr-settings-value {
		margin-left: 60px;
		@include flex;
		@include justify-content(space-between);
		h6 {
			@include flex;
			@include align-items(center);
			svg {
				width: 10px;
				height: 10px;
				path {
					fill: $color-secondary1;
					stroke: $color-secondary1;
				}
			}
		}
	}
	.obj-progress-slider,
	.kr-progress-slider {
		@include flex;
		@include align-items(flex-end);
		.MuiSlider-thumb {
			display: none;
		}
		.progress-timestamp-info {
			width: 53px;
			@include flex;
			@include justify-content(flex-end);
			margin: 3px 10px 3px 0;
			h2 {
				color: $color-threshold;
				sup {
					font-size: $font-size-h6;
					line-height: 12px;
					vertical-align: super;
				}
			}
		}
		.MuiSlider-root {
			padding: 0;
			width: calc(100% - 53px);
			min-width: 152px;
			height: 22px;
			display: block;
			margin: 3px 0;
			@include border-radius(22px);
			overflow: hidden;
			order: 2;
			.MuiSlider-rail {
				height: 22px;
				opacity: 1;
				background: $color-not-started;
				@include border-radius(22px);
			}
			.MuiSlider-track {
				height: 22px;
				@include border-radius(22px);
			}
			.Mui-disabled {
				display: none;
			}
		}
	}
	&.import-kr-progress {
		margin-right: 0px;
		.MuiSlider-root {
			height: 16px;
			margin: 3px 0;
			@include border-radius(16px);
			overflow: hidden;
			cursor: default;
			order: 2;
			.MuiSlider-rail {
				height: 16px;
				@include border-radius(16px);
			}
			.MuiSlider-track {
				height: 16px;
				@include border-radius(16px);
			}
		}
		.kr-progress-slider {
			.progress-timestamp-info {
				margin: 0 10px 0 0;
			}
		}
	}
}
/**generate okr tabpanel css start here**/
.generate-okr-tabpanel {
	.okr-oval-tabs {
		padding-top: 0px;
	}
	.okr-tab-button {
		padding: 0 3.75em 1.25em;
		.MuiTab-root.Mui-selected {
			svg {
				circle {
					fill: $color-primary1;
				}
			}
		}
	}
	/** search head css start here**/
	.generate-okr-src-head {
		.drawer-form-fields {
			.drawer-form-fields-group {
				> .drawer-input-field {
					&:first-child {
						padding-bottom: 20px;
					}
				}
			}
		}
		.search-action-field {
			.MuiInputBase-root {
				&.MuiInputBase-adornedStart {
					padding: 0 0 0 10px;
					@include align-items(flex-start);
					.MuiInputAdornment-positionStart {
						margin-top: 19px;
						//margin-left: 0px;
					}
					.MuiInputBase-input {
						padding: 0.69em 0.69em 0.69em 0;
					}
				}
			}
		}
		.generate-okr-action {
			text-align: right;
		}
		.okr-team-dropdown {
			&.dropdown-list-item {
				max-width: 100%;
				min-width: 25%;
				.team-dropdown {
					min-width: 400px;
					padding-top: 0px;
					top: 42px;
					overflow: hidden;
					.team-dropdown-inner {
						@include border-radius(0 0 6px 6px);
						overflow: hidden;
					}
				}
			}
			&.active {
				.dropdown-button {
					border-color: $color-primary1;
					@include border-radius(6px);
					z-index: initial;
					@include box-shadow(0 0 5px rgba($color-primary1, 0.5));
				}
			}
		}
		.advanced-src {
			margin: 5px 0 0;
			.drawer-input-field-col4 {
				padding: 0px;
			}
			.toggle-wrap-field {
				.drawer-input-field-col4 {
					margin-top: 15px;
				}
			}
		}
	}
	/** generate okr css start here**/
	.generate-okr-list {
		padding-top: 12px;
		.generate-okr-row {
			padding: 24px 3.75em 10px;
			//margin-bottom: 24px;
			&:nth-child(odd) {
				background: $color-bg-form;
			}
			&:nth-child(even) {
				background: $color-white;
			}
		}
		.generate-section-head {
			margin-bottom: 14px;
			.user-info {
				.filter-info-box {
					margin-left: 10px;
				}
				.info-icon {
					padding: 3px 0;
					background: none;
					margin-top: -2px;
					margin-left: 0px;
					svg {
						width: 16px;
						height: 16px;
						path {
							fill: $color-primary1;
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			.MuiAvatar-root.recommended-default {
				background: $color-border-tile;
				border: 0px;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
		.generate-section-content {
			padding-left: 40px;
			.gen-more-okr {
				margin-bottom: 10px;
				@include flex;
				@include align-items(center);
				width: 100%;
				.user-list-pagination {
					.MuiTablePagination-toolbar {
						margin-top: 0px;
						padding-left: 20px;
					}
				}
			}
		}
		.import-okr-list {
			background: $color-white;
			.import-okr-actions {
				margin-left: 0px;
			}
			.link-toggle {
				margin-right: 15px;
				label {
					margin: 7px 0 0 0;
					vertical-align: initial;
				}
			}
			.import-obj-progress-col {
				width: 40px;
			}
			.import-obj-row {
				@include flex;
				@include justify-content(space-between);
				width: 100%;
				.import-obj-col {
					width: calc(100% - 75px);
					max-width: calc(100% - 75px);
					.obj-title-section {
						width: 100%;
						@include flex;
						@include flex-direction(column);
					}
				}
			}
			.import-kr-row {
				@include flex;
				@include justify-content(space-between);
				.import-kr-col {
					@include flex;
					width: calc(100% - 55px);
					max-width: calc(100% - 55px);
					.kr-title-section {
						width: 100%;
					}
				}
			}

			.import-kr-progress-col {
				width: 40px;
				.link-toggle {
					margin-right: 0px;
					label {
						margin-top: 0px;
					}
				}
			}
		}
		&.generate-kr-list {
			.import-okr-list {
				padding: 0px;
				box-shadow: none;
				background: none;
				margin: 0;
				.import-kr-list {
					padding: 0px 0 5px;
				}
			}
		}
		.search-no-record-global {
			max-width: 368px;
			margin: 0 auto;
		}
		.generate-okr-message {
			@include flex;
			@include justify-content(center);
			.no-record-message {
				text-align: center;
				.MuiTypography-h4 {
					margin-bottom: 20px;
				}
			}
		}
	}
}

.generate-okr-filter-selected {
	.MuiList-root {
		padding: 0px;
		.MuiListItem-root {
			padding: 0px;
			margin-bottom: 6px;
			display: block;
			.MuiTypography-h5 {
				//color: $color-secondary1;
			}
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				margin-left: 0px;
			}
		}
	}
}
.generate-okr-loader {
	text-align: center;
}

/**generate okr title css start here**/
.generate-okr-title {
	padding: 0 3.75em 20px;
	.okr-title-name {
		//border: 1.4px solid rgba(161, 171, 185, 0.5);
		//padding: 9px 14px;
		@include border-radius(6px);
		@include flex;
		@include align-items(flex-start);
		span {
			margin-right: 12px;
		}
		svg {
			width: 16px;
			height: 16px;

			min-width: 16px;
			margin-top: 3px;
		}
	}
}
.selected-tooltip-wrap {
	box-shadow: 0px 3px 30px rgba($color-primary2, 0.25);
}
.generate-okr-note {
	padding: 0 3.75em;
	.MuiTypography-h6 {
		font-style: italic;
	}
}
