/** loader css **/
.MuiLinearProgress-colorPrimary {
	background-color: $color-bg-loader !important;
	.MuiLinearProgress-barColorPrimary {
		background-color: $color-keyword-highlighter;
	}
}
/**loader absoluteposition**/
.loader-wrap {
	position: relative;
	.MuiLinearProgress-root {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}
.loader-wrap-type1 {
	position: relative;
	.MuiLinearProgress-root {
		position: absolute;
		top: 60px;
		left: 0;
		width: 100%;
		z-index: 99;
	}
}
.list-loader-wrap {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	.btn-link {
		height: auto;
	}
}
