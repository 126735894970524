@import '../../../styles/sass-utils/variables';
.goal-okr-filter.alignment-map-filter {
	@include flex;
	@include align-items(center);
	.filter-chip {
		ul {
			li {
				padding: 0.25em 0.625em 0.25em 0.625em;
				margin: 0 0 0 0.35em;
				border: 1.4px solid $color-border-tile;
				min-height: 32px;
				&:before {
					display: none;
				}
				.MuiTypography-body2 {
					max-width: 35px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: $font-weight-bold;
				}
				button {
					margin-left: 0.4375em;
					&:hover {
						background: none;
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
				&.clear-all {
					padding: 0 11px;
					//@include tooltip-shadow;
					a {
						font-size: $font-size-h6;
					}
				}
				&.more-filter-count {
					width: 32px;
					height: 32px;
					@include border-radius(100%);
					padding: 0;
					@include justify-content(center);
					a {
						font-size: $font-size-h5;
						color: $color-primary2;
						text-decoration: none;
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}
}
.alignmentmap-filter-list {
	width: 230px;
	.MuiPaper-elevation1 {
		@include box-shadow(0 0 50px rgba(41, 41, 41, 0.1));
		@include border-radius(6px);
		&:before {
			width: 0.75em;
			height: 0.75em;
			content: '';
			position: absolute;
			left: 0.6em;
			top: -0.3em;
			background: $color-white;
			transform: rotate(45deg);
		}
	}
	&.dashboard-filter-list {
		.MuiPaper-elevation1 {
			&:before {
				left: auto;
				right: 1em;
			}
		}
		&[x-placement~='top-end'] {
			margin-top: 0;
			.MuiPaper-elevation1 {
				&:before {
					display: none;
				}
				&:after {
					width: 0.75em;
					height: 0.75em;
					content: '';
					position: absolute;
					right: 1em;
					bottom: -0.4em;
					background: $color-white;
					transform: rotate(45deg);
				}
			}
		}
	}
	.okrDashboard,
	.alignmentFilterWrap {
		max-height: 300px;
		overflow-y: auto;
		@include custom-scrollbar;
	}
	.alignmentFilterDirectWrap {
		max-height: 315px;
		overflow-y: auto;
		@include custom-scrollbar;
	}
	&.deltaKr {
		@include box-shadow(0 10px 30px rgba($color-primary2, 0.5));
		width: 19.8125em;
		// .MuiPaper-elevation1{
		// }
		.recentContrib {
			line-height: 1.375em;
			font-size: $font-size-h4;
			padding: 1.25em 5em 0 1.25em;
		}
		.MuiPaper-elevation1 {
			&:before {
				left: auto;
				right: 0.6em;
			}
		}
		li {
			padding: 0 16px 0 20px;
			border-bottom: 0;
		}
		.usersList {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			padding-bottom: 11px;
			padding-top: 11px;
			width: 100%;
			.userDetails {
				@include flex;
				.userImage {
					max-height: 30px;
					max-width: 30px;
				}
				.employeeDetail {
					margin-left: 9px;
					.detail {
						line-height: 1;
						font-weight: $font-weight-bold;
					}
					.name {
						font-size: 14px;
						color: $color-primary2;
						padding-bottom: 3px;
					}
					.designaton {
						color: $color-primary2;
						font-size: 10px;
					}
				}
			}
			.scoreCount {
				font-size: $font-size-h6;
				color: $color-primary1;
			}
		}
	}
	&.okr-filter {
		ul {
			li {
				padding: 0px;
			}
		}
	}
	.MuiTypography-body2 {
		padding-bottom: 0em;
		//font-weight: $font-weight-bold;
	}
	ul {
		li {
			padding: 0;
			.MuiFormControlLabel-root {
				.MuiTypography-body1 {
					//font-size: $font-size-h5;
				}
			}
			label {
				width: 100%;
				margin: 0;
				padding: 0 10px 0 8px;
				position: relative;
				z-index: 9;
			}
		}
	}
	.okr-progress-filter {
		ul {
			li {
				@include flex;
				@include justify-content(space-between);
				.color-circle {
					width: 15px;
					height: 15px;
					@include border-radius(50px);
					background: $color-input-field;
					margin-right: 4px;
					position: absolute;
					right: 1.125em;
					&.not-started-circle {
						background: $color-input-field;
					}
					&.at-risk-circle {
						background: $color-text-info;
					}
					&.in-progress-circle {
						background: $color-lagging;
					}
					&.on-track-circle {
						background: $color-on-track;
					}
				}
			}
		}
	}
	.private-public-filter {
		ul {
			li {
				@include justify-content(space-between);
				//padding-top: 0.6875em;
				//padding-bottom: 0.6875em;
				padding: 0.6875em 1.125em !important;
				.MuiTypography-body2 {
					padding: 0;
				}
			}
		}
	}
	.okr-sortby-filter {
		ul {
			li {
				display: block;
			}
		}
		.since-days-radio {
			padding-bottom: 0.75em;
			padding-left: 2.93em;
			margin-top: -0.1875em;
			.MuiTypography-body2 {
				padding: 0px 0 0.416em;
			}
			ul {
				@include flex;
				li {
					display: inline-block;
					width: auto;
					padding: 0 10px 0 0;
					border: 0px;
					.MuiFormControlLabel-root {
						margin: 0;
						padding: 0;
					}
					.MuiIconButton-root {
						display: none;
					}
					.MuiFormControlLabel-label {
						padding: 0px 0.4375em;
						border: 1px solid rgba(161, 171, 185, 0.5);
						@include border-radius(29px);
						min-width: 40px;
						min-height: 20px;
						text-align: center;
						background: $color-white;
						color: $color-primary2;
						font-size: $font-size-h6;
						font-weight: $font-weight-bold;
						line-height: 18px;
					}
					.Mui-checked + .MuiFormControlLabel-label {
						background: $color-secondary2;
						border: 1px solid $color-primary1;
					}
				}
			}
		}
	}
}
