@import '../../sass-utils/variables';
/**feedback states css start here**/
.feedback-states-area {
	@include flex;
	@include align-items(center);
	margin: 25px 0;
	.feedback-state-box {
		width: 265px;
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		padding: 24px 24px 12px 24px;
		margin-right: 20px;
		cursor: pointer;
		.feedback-state-title {
			@include flex;
			@include align-items(center);
			margin-bottom: 16px;
			font-size: $font-size-h1;
			line-height: $line-height1;
			font-weight: $font-weight-black;
			svg {
				margin-right: 10px;
				width: 24px;
				height: 24px;
			}
		}
		&.active,
		&:hover {
			border: 1.4px solid $color-primary1;
			@include default-shadow;
		}
	}
}
.feedback-list-head {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	padding-top: 20px;
	padding-bottom: 20px;
}
.feedback-listing-area {
	padding: 30px;
	background: $color-white;
	@include border-radius(6px);
	.MuiInputBase-root {
		border: 1.4px solid $color-border-tile;
	}
	.feedback-comments-area {
		.feedback-comments {
			border: 1.4px solid $color-border-tile;
		}
	}
	&.requested-feedback-list {
		.feedback-comments-area {
			.requested-expand-mssg {
				padding-left: 35px;
			}
		}
	}
	.remark-link {
		color: $color-primary1;
	}
	.feedback-comments-area {
		.comments-sub-list {
			li {
				padding-left: 40px;
			}
		}
	}
	.praise-area {
		max-width: 70%;
	}
	.load-more-link {
		.load-more-btn {
			cursor: pointer;
			color: $color-primary1;
			text-decoration: underline;
			&:hover {
				background-color: transparent;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}
