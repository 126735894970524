@import '../../../styles/sass-utils/variables';

.additional-permissions-area {
	.additional-permissions-box {
		.additional-permissions-box-divider {
			margin-top: 30px;
			padding-top: 30px;
			border-top: 1.4px solid $color-border-tile;
		}
		.permission-section-title {
			@include flex;
			@include justify-content(space-between);
			padding-bottom: 20px;
			.permisssions-count-txt {
				padding-bottom: 2px;
			}
			.MuiTypography-h4 {
				padding-bottom: 5px;
			}
			.delete-btn {
				width: 24px;
				height: 24px;
				margin-left: 5px !important;
				padding: 0px !important;
				@include border-radius(100%);
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
		}
		.permissions-section-fields {
			.col-full {
				width: 100%;
				@include flex;
				@include justify-content(space-between);
				.col-half {
					width: calc(50% - 20px);
				}
			}
			.permission-form-field {
				.permissions-form-field-top {
					padding-bottom: 5px;
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					.permissions-form-field-title {
						@include flex;
						@include align-items(center);
						.MuiFormLabel-root {
							padding-bottom: 0;
						}
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
						}
						.info-icon-btn {
							margin-left: 10px;
							padding: 0;
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
				}
				.performance-search-box {
					.select-search-dropdown {
						.react-select__menu {
							.react-select__menu-list {
								.react-select__option {
									.search-user-result {
										@include align-items(center);
									}
								}
							}
						}
					}
					&.helperText-bottom {
						.MuiFormHelperText-root {
							z-index: 1;
							top: 36px;
						}
					}
				}
			}
		}
		.everyone-switch {
			.MuiFormControlLabel-root {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.next-row {
			padding-top: 30px;
			.switch-col {
				.everyone-switch {
					.MuiFormControlLabel-root {
						margin-top: 36px;
					}
				}
			}
		}
	}
	.permission-actions {
		margin-top: 20px;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
	}
}
.report-header-tooltip {
	&.permission-info-tooltip {
		.textarea-expand {
			min-width: 235px;
			width: 235px;
			height: 148px;
			resize: none;
		}
	}
}
