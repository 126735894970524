.admin-wrapper {
	/**user and org sticky existing css fixes**/
	.navbar {
		position: static;
		padding-top: 0;
		&.scrolled {
			padding: 0em 3% 1.5em;
		}
	}

	/*Global Header CSS*/
	@mixin global-header {
		@include justify-content(flex-end);
		@include align-items(flex-start);
	}
	.no-data-header {
		@include flex;
		@include global-header;
		min-height: 5em;
		.add-btn {
			&::after {
				content: 'Create Objectives';
				position: absolute;
				right: 15px;
				top: 100%;
				width: 140px;
				background-image: url(../../../images/arrow.svg);
				background-position: right top;
				background-repeat: no-repeat;
				font-size: 1em;
				color: $color-primary2;
				padding: 52px 20px 0 0;
				margin-top: 6px;
			}
		}
	}
	.global-header {
		@include flex;
		@include global-header;
		@include justify-content(space-between);
		min-height: 5em;
	}
	.global-head-info {
		flex: 1;
		.MuiTypography-h2 {
			text-transform: capitalize;
			padding-bottom: 0.45em;
		}
		.global-head-title {
			@include flex;
			@include align-items(baseline);
			// ul {
			// 	padding: 0;
			// 	@include flex;
			// 	padding-left: 2.2em;
			// 	li {
			// 		width: auto;
			// 		flex: 0 0 auto;
			// 		padding: 0;
			// 		padding-right: 1.5em;
			// 		.MuiTypography-body1 {
			// 			@include flex;
			// 			@include align-items(center);
			// 			span {
			// 				width: 1.75em;
			// 				height: 1.75em;
			// 				line-height: 1.75em;
			// 				border-radius: 100%;
			// 				background: #ffdff8;
			// 				color: #ff00c7;
			// 				font-size: $font-size-h6;
			// 				text-align: center;
			// 				margin-left: 0.5em;
			// 			}
			// 		}
			// 	}
			// }
		}
		.connections-btn {
			background: $color-primary1;
			@include border-radius(6px);
			color: $color-white;
			border-color: $color-primary1;
			margin-top: 1em;
			width: 100%;
			padding: 0.3em 1.3em;
			@include flex;
			justify-content: left;
			&:hover {
				background: $color-primary1;
			}
			&.Mui-disabled {
				color: $color-input-field;
			}
		}
	}
	.global-head-action {
		@include flex;
		@include align-items(center);
		@include justify-content(flex-end);
		flex: 1;
		button {
			margin-left: 0.75em;
		}
		.search-btn {
			.MuiInputBase-root {
				height: 40px;
				border: 0px;
				box-shadow: none;
			}
		}
	}
}
