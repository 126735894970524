@import '../../sass-utils/variables';

/**landing subheader css start here**/
.landing-subheader {
	padding: 0 0 25px 0;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	.MuiTypography-h2 {
		font-size: $font-size-page-head;
		line-height: 38px;
		font-weight: $font-weight-black;
	}
	.landing-subheader-left {
		padding-right: 15px;
	}
	.landing-subheader-right {
		.recognize-badge-box {
			padding-top: 10px;
			.recognize-badge-new {
				position: absolute;
				right: 4px;
				top: -3px;
				width: 28px;
				height: 10px;
			}
		}
	}
}
/** landing box css start here**/
.landing-page-box {
	width: 100%;
	.landing-progress-section {
		background: $color-secondary1;
		//width: 75%;
		// width: calc(70% - 10px);
		// background: $color-white;
		// border: 1.4px solid $color-border-tile;
		// @include border-radius(6px);
		//margin-right: 10px;
		// margin-bottom: 0px;
		@include flex;
		@include flex-item(1);
		padding-bottom: 5px !important;
		width: 100%;
		.my-progress-left {
			@include flex;
			width: 50%;
			//padding-right: 20px;
			cursor: pointer;
			.col {
				width: 220px;
				&:first-child {
					margin-right: 10px;
				}
				&:last-child {
					width: calc(100% - 245px);
					margin-right: 15px;
				}
			}
			.highcharts-tooltip {
				path {
					@include transform(translate(0.14px, -1px));
				}
			}
			div.highcharts-tooltip {
				margin-left: 0.14px !important;
			}
			.highcharts-tooltip > span {
				//margin-top: 8px !important;
				min-width: 125px;
			}
		}
		.my-progress-right {
			@include flex-item(1 1);
			width: 50%;
			cursor: pointer;
			.org-loading {
				min-height: 210px;
				.MuiTypography-body1 {
					color: $color-white;
					margin-top: -35px;
				}
			}
		}
		h6 {
			margin-bottom: 15px;
			color: $color-white;
			//text-transform: uppercase;
		}
		.radial-chart {
			.radial-chart-area {
				width: 180px;
				margin: 0 auto;
				.highcharts-container {
					//margin-top: 10px;
				}
				.radial-chart-ct-image {
					background: rgba($color-white, 0.1);
					color: $color-white;
					width: 80px;
					height: 80px;
				}
			}
			.org-okr-count {
				margin-top: 25px;
				@include justify-content(flex-start);
				ul {
					@include justify-content(flex-start);
					flex-wrap: nowrap;
					li {
						margin-right: 4px;
						width: auto;
						@include justify-content(center);
						svg {
							width: 18px;
							height: 18px;
						}
						&.member-count {
							display: none;
						}
						.MuiTypography-body2 {
							text-transform: initial;
							color: $color-white;
						}
						&:nth-child(2) {
							margin-right: 0px;
						}
					}
				}
			}
		}
		.okr-progress-status {
			ul {
				li {
					border-bottom: 1px solid rgba($color-white, 0.2);
					padding: 8px 0;
					margin: 0px;
					.count {
						background: rgba($color-primary2, 0.1);
						color: $color-white;
					}
					&:last-child {
						border-bottom: 0px;
					}
					.chip-text {
						cursor: pointer;
					}
				}
			}
		}
	}
	.landing-task-section {
		//width: calc(24.87% - 6px);
		// width: 30%;
		// margin-left: 10px;
	}
}
.landing-page-col-layout {
	@include flex;
	.landing-page-col-left {
		width: calc(70% - 10px);
		margin-right: 10px;
	}
	.landing-page-col-right {
		width: 30%;
	}
}

.landing-page-row {
	margin-bottom: 10px;
	@include flex;
	.white-box {
		padding: 15px 15px 0;
		margin-bottom: 0px;
	}
	.recommended-head {
		// padding-left: 10px;
		// padding-right: 10px;
		@include flex;
		@include justify-content(space-between);
		.recommended-head-right {
			position: relative;
			@include flex;
			@include align-items(center);
			.dot-sep {
				width: 3px;
				height: 3px;
				@include border-radius(3px);
				background: $color-bg-dot;
				opacity: 0.5;
				margin-right: 9px;
			}
		}
	}
	.col-left {
		width: calc(70% - 10px);
		margin-right: 10px;
	}
	.col-right {
		width: 30%;
	}
	.box-rectangle-info {
		padding: 15px;
	}
	.landing-page-middle-left {
		width: 100%;
	}
}

.landing-page-row-2 {
	.middle-widget-wrap {
		display: flex;
		flex-wrap: wrap;
		margin: -10px 0 0 -10px;
		width: calc(100% + 10px);
		&.no-direct-report {
			.box-rectangle-info {
				&:nth-child(2),
				&:nth-child(3) {
					max-width: calc(25% - 10px);
					width: calc(25% - 10px);
					min-width: calc(25% - 10px);
				}
			}
		}
		&.no-self-report {
			.box-rectangle-info {
				&:nth-child(2),
				&:nth-child(3) {
					max-width: calc(25% - 10px);
					width: calc(25% - 10px);
					min-width: calc(25% - 10px);
				}
			}
		}
	}
	.box-rectangle-info {
		@include flex-item(1 1);
		max-width: calc(50% - 10px);
		width: calc(50% - 10px);
		min-width: calc(50% - 10px);
		margin: 10px 0 0 10px;
		height: auto;
		min-height: 136px;
		background: $color-white;
		padding: 15px 14px;
		.final-rating-text {
			padding-left: 29px;
		}

		&:last-child {
			margin-right: 0px;
		}
		.box-content {
			h6 {
				min-height: 16px;
			}
		}
		&.half-box-rectangle-info {
			max-width: calc(25% - 10px);
			width: calc(25% - 10px);
			min-width: calc(25% - 10px);
		}
	}
}

.widgetLoading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

/**kr-confidence css start here**/
.kr-confidence-list {
	h6 {
		margin-bottom: 10px !important;
	}
	ul {
		padding: 0px;
		li {
			padding: 2px 0;
			@include justify-content(space-between);
			border-bottom: 1.4px solid rgba($color-input-field, 0.3);
			&:first-child {
				padding-top: 0px;
			}
			&:last-child {
				border: 0px;
				padding-bottom: 0px;
			}
			.confidence-label {
				@include flex;
				@include align-items(center);
				svg {
					margin-right: 10px;
					width: 16px;
					height: 16px;
				}
			}
			.count {
				min-width: 22px;
				height: 22px;
				padding: 0 2px;
				background: $color-bg-form;
				@include border-radius(22px);
				line-height: $line-height2;
				font-weight: $font-weight-bold;
				color: $color-primary1;
				text-align: center;
			}
		}
	}
}
/**course list css start here**/
.course-list {
	margin-top: 15px;
	.view-course-btn {
		//margin-top: 5px;
		height: auto;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		line-height: $line-height3;
		svg {
			@include transform(rotate(180deg));
			path {
				stroke-width: 0.5px;
				stroke: $color-primary1;
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
	.courses-img {
		width: calc(35% - 15px);
		margin-right: 15px;
		position: relative;

		img,
		svg {
			width: 100%;
			max-width: 100%;
			@include border-radius(6px 0px 0 6px);
		}
	}
	.courses-content {
		width: 65%;
		padding: 5px 0;
		@include flex;
		@include justify-content(space-between);
		@include flex-direction(column);
		.courses-status {
			//@include flex;
			//margin-top: 5px;
			h6 {
				margin-right: 20px;
				@include flex;
				@include align-items(center);
				&:last-child {
					margin-right: 0px;
				}
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
					path {
						fill: $color-secondary1;
					}
				}
			}
		}
	}
	ul {
		li {
			padding: 0px;
			//@include justify-content(space-between);
			@include align-items(initial);
			background: $color-editor-bar;
			@include border-radius(6px);
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
	.content-bottom {
		margin-top: 5px;
	}
}
/**to do courses css start here**/
.to-do-courses {
	position: relative;
	//width: 30%;
	&.white-box {
		padding: 15px 8px 0;
	}
	> .MuiTypography-h6 {
		padding: 0 7px;
	}
	.view-all-courses-btn {
		width: calc(100% + 1.4px);
		margin: 0px;
		@include border-radius(0 0 6px 6px);
		box-shadow: none;
		position: absolute;
		bottom: -1px;
		left: 0px;
	}
	.course-list {
		position: relative;
		height: calc(100% - 31px);
		.courses-img {
			max-width: 84px;
			.MuiSkeleton-text {
				background: rgba($color-secondary2, 0.5);
				@include border-radius(6px 0px 0 6px);
				height: 100%;
				transform: none;
			}
		}
		ul {
			max-height: 100%;
			overflow: auto;
			position: absolute;
			width: 100%;
			left: 0px;
			top: 0px;
			@include custom-scrollbar-small-width;
			padding: 0 7px;
			li {
				margin-bottom: 7px;
			}
			.courses-content {
				h4 {
					max-width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
		.MuiSkeleton-text {
			background: rgba($color-secondary2, 0.5);
			@include border-radius(9px);
		}
	}
	.user-no-record {
		padding: 19px 0;
		.MuiTypography-h4 {
			margin-bottom: 5px;
			text-transform: capitalize;
		}
		object {
			margin-top: 15px;
		}
	}
}
.recommended-courses {
	margin-right: 10px;
	max-width: calc(70% - 10px);
	//width: calc(70% - 10px);
	//padding: 15px 5px !important;
	// .slick-slider {
	// 	&::before,
	// 	&::after {
	// 		content: '';
	// 		width: 8px;
	// 		height: 100%;
	// 		background-color: $color-white;
	// 		position: absolute;
	// 		top: 0px;
	// 		left: 0px;
	// 		z-index: 9;
	// 	}
	// 	&::after {
	// 		left: auto;
	// 		right: 0;
	// 	}
	// }
	.slick-arrow {
		position: absolute;
		right: 114px;
		width: 18px;
		height: 18px;
		@include border-radius(100%);
		background: transparent;
		left: auto;
		top: -22px;
		padding: 0px;
		svg {
			width: 8px;
			height: 11px;
			path {
				fill: $color-primary1;
				stroke-width: 2px;
				stroke: $color-primary1;
			}
		}
		&.slick-next {
			right: 92px;
		}
		&.slick-prev {
			svg {
				@include transform(rotate(180deg));
			}
		}
		&::after,
		&::before {
			display: none;
		}
		&.slick-disabled {
			opacity: 0.5;
			cursor: default;
			&:hover {
				background: none;
			}
			svg {
				path {
					fill: $color-primary2;
					stroke: $color-primary2;
				}
			}
		}
	}
	.slick-track {
		@include flex;
	}
	.slick-slide {
		height: inherit;
		> div {
			height: 100%;
		}
		.slider-row {
			//padding: 0 10px;
			height: 100%;
			.slider-row-inner {
				height: 100%;
			}
		}
	}
	.course-list {
		.courses-img {
			width: 100%;
			margin: 0px 0 10px;
			position: relative;
			height: 130px;

			img {
				max-width: 100%;
				width: 100%;
				@include border-radius(6px);
				position: absolute;
				height: 100%;
				object-fit: cover;
				top: 0px;
				left: 0px;
			}
		}
		.courses-content {
			width: 100%;
			height: calc(100% - 140px);
			padding: 0px;
			h4 {
				margin-bottom: 10px;
			}
		}

		.content-bottom {
			margin-top: 15px;
		}
		ul {
			display: flex;
			height: 100%;
			li {
				//flex: 50%;
				//width: calc(50% - 20px);
				background: none;
				margin-right: 20px;
				&:last-child {
					margin-right: 0px;
				}
			}
		}
		.coming-soon-row {
			.courses-img {
				&.no-course-img {
					background: rgba($color-secondary2, 0.5);
					@include border-radius(6px);
					.no-img-content {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						@include flex-direction(column);
						height: 100%;
						h6 {
							margin-top: 4px;
						}
						svg {
							max-width: 40px;
							min-width: 40px;
						}
					}
				}
			}
			.MuiSkeleton-text {
				background: rgba($color-secondary2, 0.5);
				@include border-radius(9px);
			}
			.last-content {
				margin-top: 20px;
			}
		}
		.slick-dots {
			height: auto;
			bottom: auto;
			top: -35px;
			width: auto;
			right: 139px;
			li {
				position: relative;
				display: none;
				height: 20px;
				margin: 0 5px;
				padding: 0;
				cursor: pointer;
				font-size: $font-size-h6;
				line-height: $line-height2;
				color: $color-secondary1;
				font-weight: $font-weight-bold;
				&.slick-active {
					display: inline-block;
					width: auto;
					margin: 0px;
				}
			}
		}
	}
	.user-no-record {
		padding: 19px 0;
		.MuiTypography-h4 {
			margin-bottom: 5px;
			text-transform: capitalize;
		}
		object {
			margin-top: 15px;
		}
	}
}
.not-started-text {
	color: $color-not-started !important;
}
.at-risk-text {
	color: $color-text-info !important;
}
.in-lagging-text {
	color: $color-lagging !important;
}
.on-track-text {
	color: $color-on-track !important;
}

@media screen and (max-width: 1400px) {
	// .to-do-courses {
	// 	.course-list {
	// 		height: calc(100% - 72px);
	// 	}
	// }
}
.dashboard-widget-head {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	margin-bottom: 10px;
	.btn-link-type1 {
		svg {
			width: 10px;
			height: 10px;
			path {
				stroke: $color-primary1;
			}
		}
	}
}
/**dashboard task widget css start here**/
.dashboard-task-widget {
	height: 100%;
	position: relative;
	//padding-bottom: 50px !important;
	&.white-box {
		padding: 15px 8px 0;
	}
	.dashboard-widget-head {
		padding-left: 7px;
		padding-right: 7px;
	}
	> h6 {
		//text-transform: uppercase;
		margin-bottom: 8px;
	}
	.task-widget-footer {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		.btn-primary {
			margin: 0px;
			width: 100%;
			@include border-radius(0px 0px 6px 6px);
			box-shadow: none;
		}
	}

	.task-list {
		padding: 0px;
		li {
			padding: 5px 8px 12px;
			@include flex;
			@include flex-direction(column);
			@include align-items(flex-start);
			margin-bottom: 0px;
			position: relative;
			@include border-radius(6px);
			&:hover {
				background: $color-bg-teamstag;
			}
			&::after {
				content: '';
				height: 1px;
				background-color: $color-hover;
				width: calc(100% - 28px);
				left: 28px;
				bottom: 0;
				position: absolute;
			}
			&:last-child {
				margin-bottom: 0px;
				&::after {
					display: none;
				}
			}
			.task-box {
				padding: 0;
				position: relative;
				p {
					width: 100%;
					padding: 5px 0 5px 28px;
					@include transition-default;
					height: auto;
					font-size: $font-size-h5;
					line-height: $line-height3;
					//border: 1.4px solid rgba($color-input-field, 0.5);
					//@include border-radius(6px);
					word-break: break-word;
				}
				.task-checkbox {
					position: absolute;
					left: 0px;
					top: 8px;
					padding: 0;
					&:hover {
						background: none !important;
					}
				}
			}
			.due-date {
				padding-left: 28px;
			}
			&.completed-task {
				.task-box {
					.MuiInputBase-root {
						input {
							color: rgba($color-primary2, 0.5);
							text-decoration: line-through;
						}
					}
				}
			}
		}
	}
	.task-widget-list {
		// max-height: 217px;
		// overflow-y: auto;
		// @include custom-scrollbar-small-width;
		&.no-task {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			height: 88%;
			min-height: 217px;
		}
	}
	.no-todo-task {
		text-align: center;
		padding: 10px 0;
		.no-task-img {
			margin-top: 15px;
			margin-bottom: 15px;
		}
		.MuiTypography-h4 {
			text-transform: capitalize;
		}
	}
	.dashboard-task-action {
		margin-top: 10px;
		padding-left: 28px;
		width: 100%;
		.quick-links-btn {
			min-height: auto;
			margin: 0px;
			button {
				cursor: default;
			}
		}
	}
}

.landing-col-row {
	@include flex;
	.col-left {
		width: calc(50% - 5px);
		margin-right: 10px;
	}
	.col-right {
		width: calc(50% - 5px);
	}
}
.dashboard-praise-wall-widget {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
	&.white-box {
		padding: 15px 8px 0 !important;
	}
	.dashboard-widget-head {
		padding-left: 7px;
		padding-right: 7px;
	}
	.welcome-content {
		padding-top: 15px;
		img {
			margin-top: 20px;
			margin-bottom: 30px;
		}
	}
}
.praise-wall-widget-content {
	// max-height: 420px;
	// overflow-y: auto;
	// @include custom-scrollbar-small-width;
	// min-height: 200px;
	padding-left: 7px;
	padding-right: 7px;
	.recog-posts-list-area {
		margin-top: 15px;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1.4px solid $color-hover;
		.recog-post-list {
			@include flex;
			.recog-user-info {
				margin-top: 5px;
				.common-avatar {
					background: rgba($color-secondary1, 0.2);
					border: 1.4px solid transparent;
					img {
						width: 16px;
						height: 16px;
						@include border-radius(unset);
					}
				}
			}
			.recog-post-box {
				flex: 1;
				background: $color-bg-form;
				.recog-post-box-inner {
					padding: 0 10px;
					background: $color-white;
					@include border-radius(6px);
					&.highlight {
						background: $color-okr-highlighter !important;
					}
					.recog-post-head {
						margin-bottom: 15px;
						@include flex;
						@include justify-content(space-between);
						.recog-post-head-left {
							.recog-post-head-left-top {
								@include flex;
								.recog-post-users {
									@include flex;
									.MuiListItem-root {
										margin-right: 5px;
										width: auto;
										flex-grow: 1;
										padding: 0 3px 0 0;
										&:after {
											right: 0;
											position: absolute;
											content: ',';
										}
										&:last-child {
											&:after {
												display: none;
											}
										}
									}
								}
								.others-lists {
									.MuiTypography-body1 {
										cursor: pointer;
										color: $color-primary1;
										padding: 0;
									}
								}
							}
							.recog-post-head-left-bottom {
								//@include flex;
								.MuiTypography-h6 {
									//padding-right: 13px;
									//margin-right: 10px;
									position: relative;
									&::first-letter {
										text-transform: capitalize;
									}
									&:after {
										position: absolute;
										top: 8px;
										right: 0;
										content: '';
										width: 3px;
										height: 3px;
										background: rgba($color-secondary1, 0.5);
										@include border-radius(100%);
										display: none;
									}
									&:last-child {
										&:after {
											display: none;
										}
									}
								}
							}
						}
						.recog-post-head-right {
							button {
								padding: 0;
								min-width: auto;
								width: 24px;
								height: 24px;
								@include border-radius(100%);
								@include transition(0.25s);
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
					.recog-post-content {
						// @include flex;
						// @include align-items(flex-start);
						padding-bottom: 25px;
						&:after {
							content: '';
							display: table;
							clear: both;
						}
						.recognize-badge-box {
							padding: 8px 5px 5px;
							width: 80px;
							min-width: 80px;
							max-width: 80px;
							min-height: 87px;
							float: left;
							cursor: default;
						}
						.praised-content {
							figure {
								display: inline-block;
							}
							ul,
							ol {
								list-style-position: inside;
								margin: 0;
							}
						}
						// .post-with-badge {
						// 	margin-right: 12px;
						// 	width: 50px;
						// 	height: 50px;
						// 	@include border-radius(100%);
						// }
					}
					.recog-post-footer {
						@include flex;
						@include justify-content(space-between);
						@include align-items(center);
						padding-top: 15px;
						border-top: 1.4px solid $color-hover;
						margin-left: -40px;
						width: calc(100% + 50px);
						.recog-post-actions-left {
							@include flex;
							button {
								font-size: $font-size-h6;
								font-weight: $font-weight-bold;
								padding: 5px 10px;
								color: $color-primary1;
								background: $color-bg-form;
								border: 1.4px solid rgba($color-input-field, 0.5);
								@include border-radius(20px);
								min-height: 29px;
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
								&.comment-btn {
									margin-left: 10px;
									svg {
										margin-right: 8px;
									}
								}
							}
							.like-filled-btn {
								position: relative;
								.like-animation-img {
									position: absolute;
									bottom: -8px;
									left: -15px;
								}
								.like-btn {
									position: relative;
									&::after {
										content: '';
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
									}
									&.btn-anim {
										.icon {
											svg {
												@include animation(like-anim 1s);
											}
										}
									}
								}
							}
						}
						.recog-post-actions-right {
							> .MuiList-root {
								@include flex;
								> .MuiListItem-root {
									width: auto;
									padding: 0;
									margin: 0 10px;

									.MuiListItemText-root {
										> .MuiTypography-body1 {
											font-size: $font-size-h6;
											color: $color-secondary1;
										}
										&.like-popup {
											> .MuiTypography-body1 {
												cursor: pointer;
												color: $color-primary1;
											}
										}
									}
									&:after {
										margin-right: -12px;
										position: absolute;
										right: 0;
										content: '';
										width: 3px;
										height: 3px;
										background: rgba($color-secondary1, 0.5);
										@include border-radius(100%);
									}
									&:last-child {
										margin: 0 0 0 10px;
										&:after {
											display: none;
										}
									}
									button {
										padding: 0;
										font-size: $font-size-h6;
										color: $color-primary1;
										background: transparent;
										cursor: pointer;
										&:hover {
											background: transparent;
										}
										.MuiTouchRipple-root {
											display: none;
										}
									}
								}
							}
						}
					}
				}
				.recog-post-note {
					padding: 5px 25px;
				}
				.recog-post-comments-trail {
					.MuiCollapse-wrapper {
						padding: 15px 30px 15px 25px;
						background: $color-white;
						@include border-radius(0 0 6px 6px);
						.recog-post-comment-list {
							margin-bottom: 10px;
							@include flex;
							.recog-user-info {
								margin-top: 5px;
							}
							.add-comment-field {
								svg {
									path {
										fill: $color-primary1;
									}
								}
							}
							.recog-post-box {
								border: none;
								.recog-post-box-inner {
									background: $color-bg-form;
								}
							}
						}
					}
					.btn-small-text {
						text-transform: unset !important;
					}
				}
				&.recog-post-editor-box {
					background-color: transparent;
					.recog-add-post-action {
						margin-top: 10px;
						@include flex;
						@include justify-content(flex-end);
					}
				}
			}
		}
		.recog-dashboard-comment {
			margin-top: 10px;
			padding-right: 10px;
			@include flex;
			@include justify-content(space-between);
			.recog-loggedin-user {
				width: 40px;
			}
			.recog-comment-box {
				width: calc(100% - 40px);
				@include flex;
				@include align-items(flex-end);
				@include flex-direction(column);
				.recog-comment {
					.MuiInputBase-root {
						@include border-radius(6px 6px 0 6px);
					}
				}
				.recog-comment-actions {
					width: 66px;
					button {
						padding-top: 4px;
						padding-bottom: 4px;
						min-height: 26px;
						&:hover {
							background-color: transparent;
						}
						svg {
							width: 12px;
							height: 12px;
						}
						&.comment-save-btn {
							padding-left: 12px;
							padding-right: 12px;
							@include border-radius(0 0 6px 6px);
							background-color: $color-primary1;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-white;
								}
							}
						}
						&.comment-cancel-btn {
							svg {
								path {
									fill: $color-primary1;
									stroke: $color-primary1;
								}
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
			}
		}
	}
}

/**dashboard-ticker css start here**/
.dashboard-ticker {
	background: #cb6228;
	padding: 10px 25px;
	position: static;
	left: -25px;
	top: 0;
	width: 100%;
	@include flex;
	@include align-items(flex-start);
	svg {
		width: 14px;
		height: 14px;
		min-width: 14px;
		margin-right: 15px;
		margin-top: 3px;
		path {
			fill: $color-white;
		}
	}
	.MuiTypography-body1 {
		color: $color-white;
		font-size: $font-size-h5;
		line-height: $line-height3;
	}
}
/* User goals dashboard */
.goals-dashboard-area {
	.goal-dashboard-top {
		display: -ms-grid;
		display: grid;
		grid-gap: 10px;
		&.goal-dashboard-top-col3 {
			-ms-grid-columns: repeat(3, 1fr);
			grid-template-columns: repeat(3, 1fr);
		}
		&.goal-dashboard-top-col4 {
			-ms-grid-columns: repeat(4, 1fr);
			grid-template-columns: repeat(4, 1fr);
		}
		&.goal-dashboard-top-col5 {
			-ms-grid-columns: repeat(5, 1fr);
			grid-template-columns: repeat(5, 1fr);
		}
	}
	.box-rectangle-info {
		padding: 12px;
		margin: 0 0 10px 0;
		.box-head {
			h2 {
				font-size: $font-size-tile-head;
				line-height: $line-height1;
			}
		}
		&.checkin-widget,
		&.goals-widget-box {
			background: $color-white;
		}
		&.goals-widget-box {
			.box-head {
				@include flex;
				@include flex-direction(unset);
				.cat-title {
					margin-right: 30px;
				}
			}
		}
		&.checkin-widget {
			.box-content {
				ul {
					li {
						width: 100%;
					}
				}
			}
		}
		.performance-goals-details {
			margin-bottom: 4px;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			.performance-goals-status {
				@include flex;
				@include align-items(center);
				.status-alert {
					@include flex;
				}
				svg {
					margin-right: 5px;
					width: 14px;
					height: 14px;
				}
			}
		}
	}
}
