@import '../../../styles/sass-utils/variables';
.wrapper-container {
	&.sticky-list-shadow,
	&.sticky-obj-shadow {
		&:before {
			content: '';
			width: 100%;
			position: fixed;
			top: 4em;
			left: 0;
			z-index: 9;
			height: 6.9em;
			background: -moz-linear-gradient(180deg, rgba(229, 240, 255, 1) 89.6%, rgba(229, 240, 255, 0) 100%);
			background: -webkit-linear-gradient(180deg, rgba(229, 240, 255, 1) 89.6%, rgba(229, 240, 255, 0) 100%);
			background: linear-gradient(180deg, rgba(229, 240, 255, 1) 89.6%, rgba(229, 240, 255, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E5F0FF', endColorstr='#b3eef5ff',GradientType=0 );
			display: none;
		}
	}

	&.create-form-display {
		.okr-listing-top-wrap {
			position: static;
			background: none !important;
		}
		&.sticky-obj-shadow {
			&::before {
				height: 5.2em;
			}
		}
		&.sticky-list-shadow {
			&::before {
				display: none;
			}
		}
		.add-btn {
			animation: none;
		}
	}
}

.okr-listing-top-wrap {
	//margin-top: 0.375em;
	padding: 0.75em 0.3125em 0;
	position: sticky;
	background: $color-secondary2;
	top: 3.755em;
	left: 0;
	width: 100%;
	z-index: 9;
	transition: all 0.3s ease-in-out;
}
.okr-list-panel-head {
	ul {
		li {
			&.okr-col1 {
				width: 50%;
				min-width: 50%;
				padding-left: 0;
			}
			&.okr-col2 {
				width: 17%;
				min-width: 17%;
			}
			&.okr-col3 {
				width: 13%;
				min-width: 13%;
			}
			&.okr-col4 {
				width: 20%;
				min-width: 20%;
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
			}
		}
	}
	.expand-all {
		margin-left: -2.5625em;
		@include flex;
		@include align-items(center);
		svg {
			max-width: 13px;
		}
		.expand-txt {
			opacity: 0;
			font-size: 0px;
			text-transform: capitalize;
		}
		.expand-all-inner {
			@include flex;
			@include align-items(center);
			padding: 5px 1.0625em 5px 0.625em;
			@include border-radius(1em);
			cursor: pointer;
			@include transition-default;
			&:hover {
				background: $color-white;
				.expand-txt {
					opacity: 1;
					font-size: 12px;
				}
				+ .sort-obj-title {
					opacity: 0;
				}
				svg {
					margin-right: 0.3em;
					@include transition-rotate;
				}
			}
			&.collapse-active {
				svg {
					@include transform(rotate(90deg));
				}
			}
		}
	}
}
.my-goals-area {
	.okr-list-panel-head {
		ul {
			padding-left: 2.5625em;
		}
	}
}

.my-obj-listing {
	ul {
		padding: 0.25em 0 0.75em 2.5625em;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		li {
			display: block;
			padding: 0 0.75em 0 1.25em;
			&:first-child {
				padding: 0;
			}
			&.okr-col1 {
				width: 46%;
				min-width: 46%;
				@include flex;
				@include justify-content(space-between);
				.okr-col1-left {
					width: 90%;
					.okr-status-info {
						@include flex;
						@include align-items(center);
						position: relative;
						.generate-okr-btn {
							left: -37px;
							top: -1px;
						}
						.draft-chip {
							//margin-right: 7px;
							height: auto;
							padding: 0px;
							background: $color-okr-highlighter;
							border: 1px solid $color-border-info;
							.MuiChip-label {
								font-size: $font-size-subtitle2 !important;
								font-weight: $font-weight-bold;
								color: $color-tags;
								line-height: $line-height5;
								text-transform: uppercase;
							}
						}
					}
				}
			}
			&.okr-col2 {
				width: 17%;
				min-width: 17%;
			}
			&.okr-col3 {
				padding-right: 3em;
				width: 17%;
				min-width: 17%;
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.conversation-listing-action {
					.conversation-btn {
						padding: 0;
						svg {
							width: 24px;
							height: 24px;
							path {
								fill: $color-primary1;
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}

						&:hover {
							background-color: transparent;
						}
						&.Mui-disabled {
							cursor: default;
							svg {
								path {
									fill: rgba($color-input-field, 0.5);
								}
							}
						}
					}
				}
			}
			&.okr-col4 {
				width: 20%;
				min-width: 20%;
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.feedback-menu {
					padding-right: 0.5em;
				}

				.kr-progress {
					width: 100%;
					max-width: calc(100% - 39px);
					&.not-accepted-progress,
					&.orphan-kr-progress {
						padding: 0;
					}
				}
			}
			.okr-objective {
				max-width: 100%;
				min-height: 2em;
				position: relative;
				// .okr-after-edit {
				// 	display: inline-block !important;
				// 	overflow: inherit !important;
				// }
				.edit-okr-field {
					position: relative;
					@include animation(fade-in 1.1s);
					.MuiInput-underline:before,
					.MuiInput-underline:after {
						display: none;
					}
					.MuiInputBase-root {
						padding: 0.25em 0.5em;
						border: 1px solid $color-input-field;
						@include border-radius(6px 6px 0);
						box-shadow: none;
					}
					.edit-okr-actions {
						position: absolute;
						right: 0;
						top: 100%;
						button {
							padding: 0.2em;
							min-width: auto;
							border-radius: 0px 0px 4px 4px;
							vertical-align: top;
							svg {
								width: 0.875em;
								height: 0.875em;
							}
							&.edit-okr-cancel {
								margin-right: 0.25em;
								svg {
									width: 1em;
									path {
										fill: $color-input-field;
									}
								}
								&:hover {
									background: transparent;
								}
							}
							&.edit-okr-save {
								background: $color-input-field;
								svg {
									path {
										fill: $color-white;
									}
								}
								&:hover {
									background: $color-input-field;
								}
							}
						}
					}
				}
			}
			&.okr-drag-wrap {
				.card-toggle {
					//padding-left: 2.5625em;
					padding-top: 0.1875em;
				}
			}
			.date-settings {
				.MuiInputLabel-root {
					display: none;
				}
				input {
					padding: 0;
					width: 4em;
				}
				.calender-date {
					button {
						padding: 0;
					}
				}
			}
			.delta-settings {
				position: absolute;
				@include flex;
				@include align-items(center);
				left: -30px;
				p {
					padding-left: 3px;
					color: $color-primary1;
					sup {
						vertical-align: super;
						font-size: smaller;
					}
				}
			}
		}
	}
	.my-obj-listing-content {
		padding: 0;
		margin-bottom: 1em;
		position: relative;
		.generate-okr-btn {
			position: absolute;
			left: -13px;
			top: 5px;
			z-index: 9;
		}
		.my-obj-listing-inner {
			padding: 0.3125em;
			position: relative;
			@include transition-default;
			background-color: $color-white;
			@include border-radius(0.375em);
			@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
			border: 2px solid transparent;
			cursor: pointer;
			&:hover,
			&.active-okr-list {
				@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			}
			.kebab-icon {
				min-width: auto;
			}
			.okr-head {
				position: relative;
				> svg {
					position: absolute;
					top: 50%;
					left: 0.5em;
					transform: translateY(-50%);
				}
				.card-toggle {
					.btn-blue-chip {
						color: $color-primary1;
						background: $color-border-tile;
						@include border-radius(100px);
					}
				}
				&:hover {
					.card-toggle {
						.okr-field-actions {
							opacity: 1;
						}
					}
					.action-message-wrap {
						.orphen-action-btn {
							opacity: 1;
						}

						.action-message {
							opacity: 0;
							@include transition(all ease-in-out 0.6s);
						}
					}
				}
				ul {
					li {
						&.okr-col4 {
							align-items: unset;
						}
					}
				}
			}
			.okr-keys-listing {
				.MuiCollapse-wrapperInner {
					padding-left: 2.5625em;
					padding-top: 4px;
					.my-obj-listing-inner {
						padding: 0px;
						box-shadow: none;
						ul {
							min-height: 100px;
							li {
								&.okr-col1 {
								}
							}
							&:hover {
								@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
							}
						}
						&:hover {
							.card-toggle {
								.okr-field-actions {
									opacity: 1;
								}
							}
						}
						.okr-objective {
							max-width: 100%;
							padding-left: 2.5625em;
							svg {
								left: 0.4375em;
							}
						}
						.card-toggle {
							padding-left: 2.5625em;
							&.listing-add-kr {
								padding: 0;
							}
						}
					}
				}
				.obj-progress {
					@include flex;
					@include justify-content(center);
					@include flex-direction(column);
					.MuiSlider-root {
						height: 6px;
						.MuiSlider-rail,
						.MuiSlider-track {
							height: 6px;
						}
					}
				}
			}
		}
		// &.no-okr-listing {
		// 	opacity: 0.3;
		// 	.my-obj-listing-inner {
		// 		&:hover {
		// 			box-shadow: unset;
		// 		}
		// 		ul {
		// 			padding: 0.75em 0;
		// 		}
		// 	}
		// }
		&.kr-listing-content {
			.my-obj-listing-inner {
				cursor: default;
				ul {
					background-color: $color-bg-tag;
					@include border-radius(6px);
					@include transition-default;
					padding-left: 0px;
					.alignment-btn {
						margin-left: 1em;
						opacity: 0;
						font-size: $font-size-h5;
						color: $color-primary1;
						padding: 0;
						-webkit-animation: fade-in 1.1s;
						animation: fade-in 1.1s;
						@include transition(all ease-in-out 0.6s);
						svg {
							width: 16px;
							height: 16px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
					&:hover {
						.alignment-btn {
							opacity: 1;
						}
					}
				}
			}
			&.show-highlighted {
				scroll-margin: 60px;
				.my-obj-listing-inner,
				ul {
					background-color: $color-okr-highlighter !important;
				}
				ul {
					box-shadow: 0px 10px 30px rgba(41, 41, 41, 0.2) !important;
				}
			}
		}
		.drafted-okr-actions {
			@include flex;
			@include justify-content(flex-end);
			@include align-items(center);
			padding: 0 2.5625em 0.5em;
			p {
				margin-right: 1em;
				font-size: $font-size-h5;
				font-style: italic;
				color: $color-input-field;
				text-transform: lowercase;
			}
			svg {
				width: 1em;
				height: 1em;
				margin-right: 0.625em;
			}
			button {
				@include transition-default;
				margin-bottom: 20px;
				&.draft-btn {
					margin-right: 2em;
				}
				svg {
					vertical-align: bottom;
				}
				&.draft-btn {
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
				&.draft-delete-btn {
					padding: 0;
					color: $color-primary2;
					@include transition-default;
					opacity: 0.5;
					svg {
						path {
							fill: $color-primary2;
						}
					}
					.MuiTouchRipple-root {
						display: none;
					}
					&:hover {
						background-color: transparent;
						opacity: 1;
					}
					&.Mui-disabled {
						pointer-events: unset;
						&:hover {
							background: $color-hover;
						}
					}
				}
			}
		}

		.card-toggle {
			@include inline-flex;
			@include align-items(center);
			.btn-blue-chip {
				svg {
					@include transition-rotate;
					margin-right: 0.25em;
					width: 10px;
					height: 10px;
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
					}
				}
				&:hover {
					@include box-shadow(6px 9px 26px $color-border-tile);
				}
			}
			.okr-field-actions {
				opacity: 0;
				@include transition(all ease-in-out 0.6s);
				margin-left: 1em;
			}
			.action-message-wrap {
				position: relative;
				.action-message {
					@include flex;
					@include align-items(center);
					margin-left: 1em;
					.MuiTypography-body2 {
						margin-left: 0.3125em;
					}
				}
			}
			.btn-gray-chip {
				box-shadow: none;
				cursor: text;
			}
			.teams-chips {
				margin: 0;
				.MuiChip-root {
					height: 20px;
					margin: 0 0 0 12px;
					padding: 0;
					background: #e5eabe;
					color: #798902;
					@include border-radius(12px);
					border: 1.4px solid $color-white;
					.MuiChip-label {
						padding-left: 7px;
						padding-right: 7px;
						font-size: $font-size-subtitle2;
						line-height: $line-height3;
					}
				}
				.MuiChip-icon {
					margin-left: 7px;
					margin-right: -5px;
					width: 7px;
					height: 7px;
				}
			}
		}
		&.newly-highlighted {
			scroll-margin: 20px;
			.my-obj-listing-inner {
				background-color: $color-okr-highlighter !important;
				border: 2px solid $color-border-info;
			}
		}
		.okr-field-actions {
			.MuiButton-text {
				font-size: $font-size-h5;
				color: $color-primary1;
				svg {
					width: 16px;
					height: 16px;
					margin-right: 5px;
				}
				&.delete-btn {
					color: $color-primary2;
					svg {
						path {
							fill: $color-primary2;
						}
					}
				}
			}
		}
	}
	.no-record-message {
		padding-top: 2em;
	}
}

.newKrList {
	-webkit-animation: fade-in 1.1s;
	animation: fade-in 1.1s;
}

.my-obj-listing {
	&.newly-highlighted-kr {
		.my-obj-listing-inner,
		ul {
			background-color: $color-okr-highlighter !important;
		}
	}
}
// .key-result-action {
// 	padding-bottom: 0.3125em;
// }

// .my-obj-title {
// 	@include flex;
// 	@include align-items(center);
// 	@include justify-content(space-between);
// 	//margin: 1em 0 0.5em;
// 	position: sticky;
// 	top: 0em;
// 	left: 0;
// 	width: 100%;
// 	z-index: 9;
// 	transition: all 0.3s ease-in-out;
// 	background: rgba(229, 240, 255, 1);
// 	&:after {
// 		width: calc(100% - 6%);
// 		height: 1px;
// 		content: '';
// 		position: absolute;
// 		top: 100%;
// 		left: 3%;
// 		background: rgba($color-input-field, 0.5);
// 		opacity: 0.5;
// 	}

// 	// * {
// 	// 	transition: all 0.3s ease-in-out;
// 	// }
// 	&.sticky {
// 		.my-obj-title-right {
// 			opacity: 1;
// 			pointer-events: auto;
// 		}
// 	}
// 	.home-title {
// 		margin-right: 0.9375em;
// 	}
// 	.goal-okr-filter {
// 		@include flex;
// 		@include align-items(center);
// 	}
// 	.obj-title-wrap {
// 		@include flex;
// 		@include align-items(center);
// 	}
// 	.my-obj-title-right {
// 		// opacity: 0;
// 		@include flex;
// 		@include align-items(center);
// 		// pointer-events: none;
// 		button {
// 			margin-left: 1em;
// 		}
// 	}
// 	.okr-type-select {
// 		padding: 0;
// 		border: none;
// 		&:before,
// 		&:after {
// 			display: none;
// 		}
// 		.MuiSelect-root {
// 			padding: 6px 24px 7px 0;
// 			font-size: $font-size-h2;
// 			font-weight: $font-weight-bold !important;
// 			line-height: $line-height2;
// 			&:focus {
// 				background-color: transparent;
// 			}
// 		}
// 		&.Mui-focused {
// 			border: none;
// 			box-shadow: none;
// 		}
// 	}
// }

.add-objective-dropdown {
	.add-objective-select {
		width: 100%;
	}
	.add-objective-short-des {
		padding-top: 0.3125em;
		font-size: $font-size-h5;
		font-style: italic;
		color: rgba($color-primary2, 0.5);
		font-weight: $font-weight-bold;
	}
}
.kr-add-objective,
.add-objective-select {
	padding-bottom: 0px !important;
	.MuiTouchRipple-root {
		display: none !important;
	}
	.MuiListItem-gutters {
		padding: 1em 0.625em;
		border-bottom: 1px solid $color-hover;
		@include transition-default;
		@include justify-content(space-between);
		cursor: pointer;
		&.active {
			background: $color-secondary2;
		}
		&.MuiListItem-root.Mui-selected {
			background: $color-secondary2 !important;
		}
		&.add-objective-select-list {
			padding: 0em 0.375em 0.375em 0.375em;
			border-bottom: 1.4px solid rgba($color-input-field, 0.5);
			position: relative;
			background: none !important;
			&:after {
				content: '';
				background: url(../../../images/arrow-down-fill.svg);
				width: 10px;
				height: 10px;
				background-repeat: no-repeat;
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -5px;
			}
		}
		&.choose-txt {
			color: rgba($color-primary2, 0.5);
			background: none !important;
			line-height: 28px !important;
			border-bottom: 1.4px solid rgba($color-input-field, 0.5) !important;
			padding: 0 0 0.375em !important;
			position: relative;
			.MuiTypography-root {
				color: rgba($color-primary2, 0.5) !important;
			}
			&:after {
				content: '';
				background: url(../../../images/arrow-down-fill.svg);
				width: 10px;
				height: 10px;
				background-repeat: no-repeat;
				position: absolute;
				right: 9px;
				top: 50%;
				margin-top: -9px;
			}
		}
	}
	.kr-add-objective-left {
		width: 60%;
		.obj-head-title {
			display: inline-block;
		}
		.MuiTypography-h4 {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: normal;
		}
		.kr-short-info {
			@include flex;
			@include align-items(center);
		}
	}
	.kr-add-objective-right {
		padding-left: 1em;
	}
	.btn-grey-border {
		border: 1px solid $color-primary2;
		color: $color-primary2;
		margin-right: 1em;
		padding: 0 0.7em;
		height: auto;
		border-radius: 3px;
		font-size: $font-size-h6;
		line-height: 18px;
		min-width: auto;
	}

	&.kr-add-existing-objective {
		max-width: 636px;
		min-width: 636px;
		margin-right: 0px;
		margin-top: 0px;
		padding: 0px;
		background: none !important;
		display: block;
		.MuiListItem-gutters {
			padding: 1em 1.25em;
			&:hover {
				background: $color-hover;
			}
		}

		.MuiList-root {
			padding: 0px;
		}
		.kr-add-objective-wrap-list {
			.kr-add-objective-left {
				width: 65%;
			}
		}
		.kr-add-objective-wrap-list {
			max-height: 325px;
			overflow-y: auto;
			@include custom-scrollbar;
		}
	}
}
.create-new-object {
	padding-top: 1.25em;
	.MuiButton-root.create-obj-btn {
		font-weight: $font-weight-bold;
		font-size: $font-size-h5;
		color: rgba($color-primary2, 0.5);
		font-style: italic;
		padding: 0px;
		max-width: 219px;
		text-align: left;
		white-space: normal;
		line-height: 1.125em;
		.MuiButton-label {
			span {
				min-width: 40px;
				height: 40px;
				background: $color-primary1;
				@include border-radius(100%);
				@include flex;
				@include justify-content(center);
				@include align-items(center);
				margin-right: 0.4375em;
			}
		}
		&:hover {
			background: none;
		}
	}
	&.select-create-new-object {
		padding: 35px 1.25em 28px !important;
		border: 0px;
		background: none !important;
		.MuiButton-root.create-obj-btn {
			.MuiButton-label {
				span {
					margin-right: 0.5625em;
				}
			}
		}
	}
}

.MuiMenu-paper {
	@include custom-scrollbar;
	.select-default {
		display: none;
	}
}

.popper-progress-bar {
	z-index: 9990;
	@include border-radius(0.375em);
	box-shadow: 2px 0 10px rgba($color-primary2, 0.12);
	background-color: $color-white;
	min-width: 310px;
	max-width: 310px;
	min-height: 102px;
	&:before {
		content: '';
		width: 0px;
		height: 0px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $color-white;
		position: absolute;
		top: -10px;
		left: 50%;
		margin-left: -11px;
	}
	&[x-placement~='top'] {
		&:before {
			display: none;
		}
		&:after {
			content: '';
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $color-bg-teamstag;
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -11px;
		}
	}
	.MuiPaper-elevation1 {
		box-shadow: none;
		@include border-radius(0.375em);
	}
	.popper-progress-title {
		font-weight: $font-weight-regular;
	}
	.percentage-score-title.MuiTypography-body1 {
		color: $color-primary1;
	}
	.currency-updated-field {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		position: relative;
		border-bottom: 2px solid rgba($color-input-field, 0.5);
		&:hover {
			border-bottom: 2px solid $color-primary1;
		}
		.MuiInputAdornment-positionStart {
			position: absolute;
			left: 0px;
			top: 17px;
			svg {
				max-height: 14px;
				max-width: 14px;
			}
		}
		.MuiTextField-root {
			max-width: 50%;
			.MuiInputBase-root {
				border: none;
				box-shadow: none;
				padding: 0 0 0 1em;
				&::before,
				&::after {
					display: none;
				}
				.MuiInputBase-input {
					line-height: 17px;
					padding-bottom: 4px;
					padding-top: 8px;
				}
			}
			&:hover {
				.MuiInputBase-root {
					border-bottom: none;
				}
				.MuiInputAdornment-positionStart {
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
			}
		}
		.current-traget-value {
			max-width: 50%;
			text-align: right;
			.MuiTypography-root {
				font-size: 14px;
				color: rgba($color-primary2, 0.5);
			}
		}
	}

	.progress-save-loader {
		position: absolute;
		left: 0;
		bottom: 5px;
		line-height: 12px;
	}

	.boolean-popover-bar {
		padding-bottom: 20px;
	}

	.boolean-matric {
		.MuiSwitch-track {
			border: none;
		}
		.MuiGrid-spacing-xs-1 > .MuiGrid-item {
			padding: 0 16px 0 4px;
			color: $color-primary2;
		}
	}
	.percentage-progress-bar {
		button {
			padding: 0;
			border: none;
			background: transparent;
			width: 100%;
			text-align: left;
		}
		.MuiSlider-root {
			padding: 0;
			height: 6px;
			display: block;
			margin: 0;
			.MuiSlider-rail {
				height: 2px;
				opacity: 1;
				background: $color-not-started;
				@include border-radius(6px);
				top: 50%;
				margin-top: -1px;
			}
			.MuiSlider-track {
				height: 6px;
				@include border-radius(6px);
				background: $color-primary1;
			}
			.Mui-disabled {
				display: none;
			}
			.MuiSlider-thumb {
				width: 14px;
				height: 14px;
				margin-top: -7px;
				margin-left: -5px;
				top: 50%;
				background: rgba(255, 255, 255, 0.5);
				border: 1.4px solid $color-primary1;
				opacity: 1;
			}
		}

		.MuiSlider-mark {
			height: 6px;
			top: 4px;
			width: 1px;
			background-color: rgba($color-input-field, 0.5);
			&.MuiSlider-markActive {
				background-color: rgba($color-input-field, 0.5);
				opacity: 1;
			}
		}
	}

	.no-unit-progress {
		button {
			padding: 0;
			border: none;
			background: transparent;
			width: 100%;
			text-align: left;
		}
		.MuiSlider-root {
			padding: 0;
			height: 6px;
			display: block;
			margin: 0.25em 0;
			.MuiSlider-rail {
				height: 6px;
				opacity: 1;
				background: $color-not-started;
				@include border-radius(6px);
			}
			.MuiSlider-track {
				height: 6px;
				@include border-radius(6px);
				//background: $color-text-info; // commented for color coding slider
			}
			.Mui-disabled {
				display: none;
			}
			.MuiSlider-thumb {
				width: 14px;
				height: 14px;
				margin-top: -7px;
				margin-left: -5px;
				top: 50%;
				background: $color-white;
				border: 1px solid $color-input-field;
				opacity: 0.9;
			}
		}
	}
	.progress-non-actionable {
		.non-action-text {
			margin-bottom: 0.75em;
		}
		.addOn {
			margin-bottom: 10px;
			font-size: $font-size-h4;
		}
		.non-action-matric {
			@include flex;
			.MuiBox-root {
				width: 40%;
			}
			.non-action-left {
				margin-right: 1em;
			}
			.MuiFormLabel-root {
				margin-bottom: 0.1875em;
			}
		}
	}
	.progress-note-box {
		position: relative;
		margin-top: 0.5em;
		.MuiTypography-h5 {
			margin-bottom: 10px;
		}
		.ck-editor-box {
			.ck-editor {
				.ck-reset_all {
					display: none;
				}
			}
		}
		.ck {
			&.ck-editor {
				box-shadow: none;
			}
			&.ck-editor__main {
				> .ck-content {
					@include border-radius(6px!important);
					min-height: 100px !important;
					max-height: 130px !important;
					cursor: text;
				}
			}
		}
		.MuiTextField-root {
			width: 100%;
		}
		.MuiInput-root {
			@include align-items(flex-start);
			padding: 0.25em 0.5em;
			min-height: 90px;
			border: 1.4px solid rgba($color-input-field, 0.5);
			@include border-radius(6px 6px 0 6px);
			&:before,
			&:after {
				display: none;
			}
			input {
				padding: 0;
				width: 100%;
				font-size: $font-size-h6;
				line-height: 18px;
			}
			.MuiInputAdornment-root {
				height: 18px;
				margin: 0;
				svg {
					width: 10px;
					height: 10px;
					margin-right: 2px;
					path {
						fill: $color-primary1;
					}
				}
			}
		}

		#rteSection.rte-control-section {
			.e-toolbar-wrapper {
				display: none;
			}
			.e-richtexteditor.e-rte-tb-expand {
				@include border-radius(6px);
				@include transition(0.25s);
				.e-rte-content {
					border: 0px;
					@include border-radius(6px);
					.tagged-name {
						color: $color-primary1;
					}
					.rte-placeholder {
						padding: 8px !important;
						color: $color-primary2;
					}
					.e-content {
						padding: 8px !important;
						min-height: 64px;
						max-height: 64px;
						@include custom-scrollbar;
					}
				}
				&.e-focused {
					border: 1.4px solid $color-primary1;
					@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
				}
			}
		}
	}
	.popup-progress-top {
		padding: 15px 20px;
		background: $color-white;
		@include border-radius(0.375em 0.375em 0 0);
		.popup-progress-box {
			@include flex;
			@include align-items(flex-end);
			> .MuiTypography-h6 {
				width: 42px;
				color: $color-threshold;
			}
			.popup-progress-track {
				width: calc(100% - 42px);
				button {
					padding: 0;
					border: none;
					background: transparent;
					width: 100%;
					text-align: left;
				}
				.MuiSlider-root {
					padding: 0;
					height: 6px;
					display: block;
					margin: 0.25em 0;
					.MuiSlider-rail {
						height: 6px;
						opacity: 1;
						background: $color-not-started;
						@include border-radius(6px);
					}
					.MuiSlider-track {
						height: 6px;
						@include border-radius(6px);
						//background: $color-text-info; // commented for color coding slider
					}
					.Mui-disabled {
						display: none;
					}
					.MuiSlider-thumb {
						// width: 14px;
						// height: 14px;
						// margin-top: -7px;
						// margin-left: -5px;
						// top: 50%;
						// background: $color-white;
						// border: 1px solid $color-input-field;
						// opacity: 0.9;
						width: 32px;
						height: 23px;
						margin-left: -16px;
						margin-top: -12px;
						top: 50%;
						border: 4px solid $color-white;
						@include box-shadow(0px 0px 10px rgba($color-primary2, 0.2));
						@include border-radius(20px);
						background-color: $color-primary1;
						background-image: url(../../../images/drag-bubble.svg);
						background-repeat: no-repeat;
						background-position: center;
					}
					&.show-blue-slider {
						.MuiSlider-track {
							background-color: $color-primary1;
						}
					}
				}

				.MuiSlider-mark {
					height: 6px;
					top: 4px;
					width: 1px;
					background-color: rgba($color-input-field, 0.5);
					&.MuiSlider-markActive {
						background-color: rgba($color-input-field, 0.5);
						opacity: 1;
					}
				}
				.popup-progress-value {
					@include flex;
					@include justify-content(space-between);
					margin-bottom: 5px;
					.MuiTypography-subtitle2 {
						@include flex;
						@include align-items(center);
					}
					svg {
						width: 10px;
						height: 10px;
						path {
							fill: $color-secondary1;
							stroke: $color-secondary1;
						}
					}
				}
			}
		}
		.progress-input-area {
			//margin-bottom: 1.5em;
			margin-bottom: 15px;
			@include flex;
			.progress-link-icon {
				width: 42px;
				height: 38px;
				background: url(../../../images/progress-value-connector.svg) 12px 2px no-repeat;
			}
			.progress-input-box {
				margin-top: 12px;
				width: calc(100% - 42px);
				.MuiInputBase-root {
					background: $color-white;
					.MuiTypography-body1 {
						font-size: $font-size-h5;
					}
				}
				.MuiInputAdornment-root {
					margin-right: 4px;
					svg {
						width: 12px;
						height: 12px;
					}
				}
			}
			.progress-owner-percent {
				@include flex;
				@include align-items(center);
				@include justify-content(flex-end);
				margin-top: 12px;
				margin-right: 10px;
				width: 30px;
			}
		}
		.progress-owner-notes {
			margin-bottom: 10px;
			margin-left: 40px;
		}
		.btn-link-type1 {
			svg {
				width: 10px;
				height: 10px;
				path {
					stroke: $color-primary1;
				}
			}
		}
		.progress-confidence-area {
			.progress-confidence-box {
				.confidence-selection {
					.MuiList-root {
						.MuiListItem-root {
							margin-right: 7px;
							button {
								padding: 5px;
								min-width: 82px;
								font-size: $font-size-h5;
								svg {
									margin-right: 5px;
								}
							}
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	.progress-popup-actions {
		padding: 7px 20px;
		@include flex;
		@include justify-content(flex-end);
		background-color: $color-bg-teamstag;
		border-top: 1.5px solid $color-hover;
		border-radius: 0 0 0.375em 0.375em;
		button {
			width: 35px !important;
			height: 20px !important;
			padding: 0.2em !important;
			@include border-radius(20px);
			vertical-align: top;
			svg {
				width: 0.875em;
				height: 0.875em;
				path {
					fill: $color-white;
				}
			}
			&.progress-cancel {
				margin-right: 2px;
				color: $color-primary1;
				svg {
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
					}
				}
				&:hover {
					background-color: transparent;
				}
			}
			&.progress-save {
				background: $color-primary1;
				&.btn-disabled {
					background: rgba($color-input-field, 0.5);
					cursor: default;
				}
			}
		}
	}
	&.progress-popper-unsaved {
		animation: mymove 0.75s 4;
	}
	&.popper-progress-bar-bottom {
		margin-left: -5px;
	}
}
.welcome-wrap-content {
	&:before {
		display: none;
	}
	&.create-form-display {
		.my-obj-no-content {
			opacity: 1;
			pointer-events: auto;
		}
		&:before {
			display: block;
		}
	}
}
.my-obj-no-content {
	opacity: 0;
	pointer-events: none;
	@include flex;
	@include justify-content(space-between);
	width: 100%;
	@include align-items(center);
}

.my-obj-listing {
	ul {
		li {
			.okr-after-edit {
				.MuiTypography-h4 {
					max-width: 100%;
					max-height: 44px;
					position: relative;
					&.with-tooltip {
						@include flex;
						@include align-items(flex-end);
						span {
							&.obj-tooltip-txt {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
						}
					}
					&.without-tooltip {
						display: inline-block;
					}
				}
			}
		}
	}
}
.people-view-wrapper,
.team-view-wrapper {
	.my-obj-listing {
		ul {
			li {
				.okr-after-edit {
					.des-without-tooltip,
					.description-arrow {
						display: none;
					}
				}
			}
		}
		.my-obj-listing-content {
			.my-obj-listing-inner {
				.okr-head {
					> svg {
						display: none;
					}
				}
			}
		}
	}
}

.teamOkrListing {
	.my-obj-listing {
		.my-obj-listing-content {
			.my-obj-listing-inner {
				.okr-head > svg {
					display: none;
				}
			}
		}
	}
}

.okr-description-popup {
	z-index: 9999;
	background: $color-white;
	@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
	@include border-radius(0.375em);
	padding: 0.625em;
	margin-top: 10px;
	margin-left: -55px;
	&::before {
		content: '';
		width: 0px;
		height: 0px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $color-white;
		position: absolute;
		top: -10px;
		left: 67px;
		margin-left: -11px;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $color-white;
			position: absolute;
			bottom: -10px;
			left: 64px;
			margin-left: -11px;
		}
	}
	.textarea-expand {
		// -moz-appearance: textfield-multiline;
		// -webkit-appearance: textarea;
		resize: both;
		overflow: auto;
		min-width: 495px;
		width: 495px;
		height: 85px;
		@include custom-scrollbar;
		strong {
			font-weight: $font-weight-bold;
		}
		a {
			color: $color-primary1;
			text-decoration: underline;
		}
		&::after {
			/*content: '';
			background: url(../../../../images/grab-icon.svg) no-repeat center 4px;
			z-index: 1;
			position: absolute;
			right: 5px;
			bottom: 8px;
			pointer-events: none;
			width: 19px;
			background-color: $color-white;
			height: 20px;*/
		}
	}
	.tasks-expand {
		// -moz-appearance: textfield-multiline;
		// -webkit-appearance: textarea;
		resize: both;
		overflow: auto;
		min-width: 495px;
		width: 495px;
		height: 160px;
		@include custom-scrollbar;
	}
	::-webkit-resizer {
		position: absolute;
		top: 0px;
		background: url(../../../images/grab-icon.svg) no-repeat center 0px;
		left: 0px;
	}
	.task-lists {
		li {
			.task-box {
				padding: 0;
				p {
					width: 100%;
					padding: 0.25em 1.142em 0.3em 2.285em;
					@include transition-default;
					height: auto;
					font-size: $font-size-h5;
					line-height: 1.25;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(6px);
				}
			}
			&.completed-task {
				.task-box {
					.MuiInputBase-root {
						input {
							color: rgba($color-primary2, 0.5);
							text-decoration: line-through;
						}
					}
				}
			}
		}
	}
}

.text-editor {
	border: 0px !important;
	background: none;
	border-radius: 0px !important;
	border-bottom: 1.4px solid rgba($color-input-field, 0.5) !important;
	padding: 0 0 0.375em;
	font-weight: $font-weight-regular !important;
	font-size: 1em;
	color: $color-primary2;
	line-height: 22px;
	max-height: 98px;
	overflow: auto;
	.text-editor-toolbar {
		padding: 0px;
		border: 0px;
	}
	.text-editor-textarea {
		.DraftEditor-editorContainer {
			border: 0px;
			.public-DraftEditor-content {
				padding: 0px;
			}
		}
	}
	.public-DraftEditorPlaceholder-root {
		padding: 0px !important;
	}
}

.contributor-details-popover .search-btn {
	border: 0;
}
.search-area {
	width: 100%;
}

.teams-list .MuiCollapse-wrapperInner li {
	position: relative;
}
.teams-list {
	ul li {
		ul.MuiTreeItem-group {
			margin: 0em;
			li {
				.MuiTreeItem-content {
					padding-left: 2em;
				}
			}
		}
	}
	.e-treeview {
		.e-list-item .e-ul {
			padding: 0 0 0 38px;
		}
		.e-list-item div.e-icons::before {
			font-family: 'e-icons';
			content: '\e324';
		}
		& > .e-ul {
			overflow: inherit;
		}
		.e-checkbox-wrapper + .e-list-text {
			padding-right: 0;
		}
		.e-text-content {
			.e-checkbox-wrapper {
				margin-top: 18px;
				vertical-align: top;
			}
			.e-list-text {
				width: 100%;
			}
			&.e-ripple {
				overflow: visible;
			}
		}
		.e-list-item div.e-icons.interaction {
			vertical-align: top;
			margin-top: 15px;
		}
		.e-list-item div.e-icons {
			vertical-align: top;
			margin-top: 15px;
		}
	}
}

.teams-list .MuiCollapse-wrapperInner li::before {
	content: '';
	content: '';
	position: absolute;
	top: 36px;
	left: 22px;
	width: 15px;
	height: 1px;
	background: $color-input-field;
	display: none;
}
.teams-list ul li.Mui-expanded::before {
	content: '';
	position: absolute;
	top: 40px;
	left: 21px;
	width: 1px;
	height: calc(100% - 75px);
	background: $color-input-field;
	z-index: 9999;
}
.teams-list ul li.Mui-expanded {
	position: relative;
}
.teams-list .MuiTreeItem-content:hover {
	background: $color-hover;
}
.teams-list .MuiTreeItem-label:hover {
	background: none;
}
.teams-list .MuiTreeItem-content {
	padding-left: 1em;
}
.teams-list .k-checkbox-wrapper {
	@include flex;
}

.kr-assign-contributor {
	.contributor-details-popover {
		max-height: 570px;
	}
}
.assigned-btn .MuiButton-label span {
	padding-right: 0.5em;
}
.teams-list .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label,
.teams-list .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
.teams-list .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
	background-color: unset;
}

.checkall {
	color: blue;
}
.teams-data-list {
	.k-checkbox-wrapper {
		width: 100%;
	}

	.k-in {
		width: 100%;
		.contributor-info {
			width: 65%;
		}
		.checkall {
			color: $color-primary1;
			font-weight: $font-weight-regular;
		}
	}
}

.teams-list ul li.Mui-selected {
	background: unset;
}
.teams-list .e-fullrow {
	padding: 2.2em;
}
.owner-detail {
	padding-bottom: 1.875em;
}
.teams-section-kr {
	.teams-list {
		.MuiListItemIcon-root {
			min-width: 45px;
		}
	}
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
	background-color: unset !important;
}
.teams-okr-btns {
	@include flex;
	@include justify-content(flex-end);
	padding: 1em;
	background-color: $color-hover;
	&.contributorPopup {
		@include justify-content(space-between);
		.btn-link {
			font-weight: $font-weight-bold;
			font-size: 12px;
			color: $color-primary1;
			svg {
				color: $color-primary2;
			}
		}
	}
}
.select-all .e-checkbox-wrapper:hover .e-label,
.select-all .e-css.e-checkbox-wrapper:hover .e-label {
	color: $color-primary1;
}
.teams-loader {
	min-height: 110px;
	text-align: center;
	width: 100%;
	height: 100%;
	display: table;
	text-align: center;
	span {
		display: table-cell;
		vertical-align: middle;
	}
}

.teams-list .e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check,
.teams-list .e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
	background-color: $color-primary1 !important;
	border-color: transparent;
	color: $color-white;
}

.select-all {
	padding-left: 1.9em;
	padding-top: 1em;
	padding-bottom: 0.5em;
	.e-check {
		background-color: $color-primary1 !important;
	}
	span.e-label {
		color: $color-primary1;
		font-size: 14px;
	}
}
.teams-chips {
	.MuiChip-deleteIcon,
	.delete-icon {
		color: $color-primary2;
		width: 18px;
		cursor: pointer;
		height: 18px;
		margin: -2px;
	}
}
.small-avatar.MuiAvatar-root {
	height: 25px;
	width: 25px;
	font-size: 12px;
	margin-right: 5px;
}
.selected-t1-menu {
	width: 100%;
	display: -webkit-flex;
	@include flex;
	padding: 0.7em;
	min-width: 200px;
	border-bottom: 1px solid $color-hover;
	.delete-icon {
		cursor: pointer;
	}
}
.selected-t1-menu:last-child {
	border: 0px;
}
.assigned-list .MuiList-padding {
	padding: 0px;
	width: 240px;
}
.assigned-list {
	.MuiPaper-rounded {
		@include box-shadow(0px 0px 30px rgba($color-primary2, 0.1));
		opacity: 0.1;
		@include border-radius(6px);
		margin-right: -1em;
	}
}

span.userText {
	@include flex;
	flex: 1;
	padding-right: 1em;
}
span.text-name {
	padding-left: 0.3rem;
	font-size: 14px;
}

.plus-more-icon {
	background: #e3e5e5;
	@include border-radius(3px);
	padding: 2px 4px 2px 4px;
	font-size: 12px;
	vertical-align: bottom;
	cursor: pointer;
	color: $color-primary2;
	font-weight: $font-weight-bold;
}
.edit-tree {
	margin-left: 1em;
	cursor: pointer;
}

.teams-list .e-text-content {
	padding: 0px !important;
}

.teams-list .e-treeview .e-list-item.e-active > .e-text-content {
	@include flex;
	.e-checkbox-wrapper {
		margin-top: 1.5em;
	}
}
.teams-list .e-accordion .e-acrdn-item .e-acrdn-panel {
	background-color: #e5f0ff;
}

.MuiChip-root.selected-t1.no-width-class {
	max-width: unset;
}

.kr-tree {
	max-height: 635px;
}

.e-ripple-element {
	display: none;
}
.error-span {
	display: block;
	color: $color-text-info;
	margin: 0;
	font-size: 0.75rem;
	margin-top: 3px;
	text-align: left;
	font-weight: $font-weight-regular;
	line-height: 1.66;
	position: absolute;
}
.grey-out-section {
	opacity: 0.5;
}
.error-metric {
	display: block;
	color: $color-text-info;
	margin: 0;
	font-size: 0.75rem;
	margin-top: 3px;
	text-align: left;
	font-weight: $font-weight-regular;
	line-height: 1.66;
}

.assignee-data {
	.add-more-contri {
		@include flex;
		.kr-attribute-avatar-list {
			padding-left: 0.6em;
		}
	}
	.assign-contributor-message {
		margin-top: 1.5em;
		//border-bottom: 1.4px solid rgba($color-input-field, 0.5);
		padding: 0 0 0.75em;
		&:hover {
			//border-bottom: 1.4px solid $color-primary1;
			.MuiFormLabel-root {
				//color: $color-primary1 !important;
			}
		}
		&.Mui-error {
			border-bottom: 1.4px solid $color-text-info;
			.MuiFormLabel-root {
				color: $color-text-info !important;
			}
			&:hover {
				border-bottom: 1.4px solid $color-text-info;
			}
		}
	}
}

.pos-rel {
	position: relative;
}
.pos-abs {
	position: absolute;
}
.top0 {
	top: 0;
}

.kr-assign-contributor .assign-contributor-label .no-pointer {
	cursor: default;
}

.tree-child {
	padding-left: 2rem;
}

.delta-settings span.red-text,
.scoreCount span.red-text {
	color: $color-text-info;
}

.deltaKr.recent-contribution {
	.userDetails {
		max-width: 11em;
	}
	.scoreCount {
		position: relative;
		svg {
			position: absolute;
			left: -12px;
			top: 6px;
		}
	}
	.name span {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.designaton {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.popper-contributor-progress {
	.MuiPopover-paper {
		min-width: 442px;
		max-width: 442px;
		padding: 1em 1.25em 1.25em;
		margin-top: 1em;
		min-height: 102px;
		@include border-radius(0.375em);
		@include box-shadow(0px 0.625em 1.875em rgba($color-primary2, 0.2));
		background-color: $color-white;
		overflow: unset;
		&:before {
			content: '';
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid $color-white;
			position: absolute;
			top: -10px;
			left: 50%;
			margin-left: -11px;
		}
	}
}
.contributors-chip {
	.no-background {
		background-color: $color-white !important;
	}
}
.okr-filter.deltaKr {
	ul li:hover {
		background: $color-white;
	}
	.employeeDetail {
		margin-top: 5px;
	}
}

.accept-section-assignment {
	margin-top: 2em !important;
	width: 100%;
	.assign-contributor-label {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		padding-bottom: 0.9375em;

		.label-contributor-add {
			margin-left: 0.3em;
			padding: 0px !important;
			color: $color-primary2;
		}
	}
	.kr-no-contributors-text {
		max-width: 65%;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: rgba($color-primary2, 0.7);
		font-style: italic;
		font-weight: $font-weight-bold;
	}
}
span.external-user {
	@include flex;
	@include flex-direction(row);
	font-size: $font-size-h6;
	font-weight: $font-weight-bold;
	color: $color-primary2;
	padding: 0.7em 0em 0.5em 1em;
	svg {
		margin-right: 0.6em;
		margin-top: 0.3em;
	}
}
span.external-user::after {
	content: '';
	flex: 1 1;
	border-bottom: 2px solid #efefef;
	margin: auto;
	margin-left: 0.5em;
}

span.external-link {
	margin-right: 0.4em;
}
body .MuiPopover-root {
	z-index: 99999999 !important;
}
.popper-menu-list {
	&.acceptance-menu-list {
		min-width: 201px;
		margin-right: 0.5em;
	}
}

.error-txt-validation-message {
	display: block;
	color: $color-text-info;
	margin: 0;
	font-size: 0.75rem;
	margin-top: 3px;
	text-align: left;
	font-weight: $font-weight-regular;
	line-height: 1.66;
}

.become-contributor-assignment {
	.kr-btn-group {
		margin-top: 1em;
	}
}
// .teams-kr-tag {
// 	@include flex;
// 	padding-left: 0px !important;
// 	.team-avatar {
// 		float: left;
// 		padding-left: 2px;
// 		padding-right: 10px;
// 		.MuiAvatar-root {
// 			width: 40px !important;
// 			height: 40px !important;
// 		}
// 	}
// }
.add-kr-textfield,
.okr-drag-wrap {
	.okr-field-actions {
		.MuiButton-text.settings-btn-error {
			color: $color-text-info;
			.icon {
				@include animation(rotation 2s linear infinite);
				//animation-iteration-count: infinite;
				//margin-right: 5px;
				svg {
					margin-right: 0px;
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}
}
.overlayForRedirection {
	position: fixed;
	top: 4px;
	left: 0;
	width: 100%;
	height: calc(100% + 56px);
	background-color: #fff;
	opacity: 0.5;
	z-index: 9999;
	pointer-events: none;
}
.progress-blanket {
	z-index: 9999;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.confetti {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 2px;
	width: 100%;
	left: 0;
	overflow: hidden;
	height: 101px;
}

.org-chart-card .orphan-kr-progress {
	width: 100%;
	.kr-progress-slider {
		display: flex;
	}
}
