@import '../../../styles/sass-utils/variables';
.recog-area {
	padding: 30px 0;
	.recog-user-info {
		margin-right: 10px;
	}
	.recog-people-info {
		@include flex;
		@include align-items(center);
		padding-bottom: 20px;
		.back-to-main {
			margin-right: 15px;
			padding: 0;
		}
		.user-info {
			@include align-items(center);
		}
		.team-details {
			@include align-items(center);
			.team-avatar {
				margin-right: 7px;
			}
			.team-info-details {
				.team-info-details-inner {
					margin-top: 2px;
					@include flex;
					@include align-items(center);
					.MuiTypography-h6 {
						margin-right: 10px;
						@include flex;
						@include align-items(center);
						position: relative;
						padding-right: 12px;
						text-transform: capitalize;
						svg {
							width: 12px;
							height: 12px;
							margin-right: 4px;
							path {
								fill: $color-secondary1;
							}
						}
						&:after {
							position: absolute;
							top: 8px;
							right: 0;
							content: '';
							width: 3px;
							height: 3px;
							background: rgba($color-secondary1, 0.5);
							border-radius: 100%;
						}
						&:last-child {
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.recog-post-area {
		@include flex;
		@include justify-content(space-between);
		.recog-post-left {
			width: calc(100% - 460px);
			max-width: calc(100% - 460px);
			min-width: calc(100% - 460px);
			.recog-create-post-wall {
				//@include flex;
				margin-bottom: 20px;
				padding: 30px;
				background: $color-bg-form;
				border: 1.4px solid $color-border-tile;
				@include border-radius(6px);
				.recog-create-post-search {
					@include flex;
					@include align-items(center);
					margin-bottom: 15px;
					.recog-user-search {
						flex: 1;

						.select-search-icon {
							.react-select__control {
								max-height: 96px;
								.react-select__indicators {
									.react-select__dropdown-indicator {
										display: none;
									}
								}
							}
							&.error {
								.react-select__control {
									border: 1.4px solid $color-text-info;
									&.react-select__control--is-focused {
										@include box-shadow(0 0 5px rgba($color-text-info, 0.5));
									}
								}
							}
						}
					}
				}
				.recog-create-post-box {
					flex: 1;
					.recog-add-post-action {
						margin-top: 10px;
						@include flex;
						@include justify-content(flex-end);
					}
					.select-search-dropdown {
						.react-select__control {
							overflow-y: hidden;
						}
					}
					// .recog-create-post {
					// 	@include flex;
					// 	@include align-items(flex-start);
					// 	.recognize-badge-box {
					// 		margin-right: 10px;
					// 		padding: 5px;
					// 		min-height: 100px;
					// 		@include flex;
					// 		@include align-items(center);
					// 		@include flex-direction(column);
					// 		background: $color-secondary2;
					// 		position: relative;
					// 		.cancel-btn {
					// 			padding: 4px;
					// 			position: absolute;
					// 			right: -5px;
					// 			top: -5px;
					// 			z-index: 1;
					// 			cursor: pointer;
					// 			background: $color-primary1;
					// 			@include border-radius(20px);
					// 			svg {
					// 				width: 10px;
					// 				height: 10px;
					// 				path {
					// 					fill: $color-white;
					// 				}
					// 			}
					// 			&:hover {
					// 				background: $color-primary1;
					// 			}
					// 			.MuiTouchRipple-root {
					// 				display: none;
					// 			}
					// 		}
					// 		.MuiSkeleton-root {
					// 			flex: 1;
					// 			background: rgba($color-input-field, 0.15);
					// 			.MuiSkeleton-text {
					// 				text-align: center;
					// 			}
					// 		}
					// 	}
					// }
				}
			}
			.recog-post-badges {
				@include flex;
				@include align-items(center);
				margin-bottom: 15px;
				.badges-filter-dropdown {
					margin-right: 10px;
					button {
						padding: 0;
						&:hover {
							background-color: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				.recog-badges-btns {
					position: relative;
					margin: 0 10px;
					.badge-btn {
						padding: 5px 35px 5px 15px;
						color: $color-secondary1;
						font-size: $font-size-h6;
						font-weight: $font-weight-bold;
						background: $color-white;
						border: 1.4px solid rgba($color-secondary1, 0.3);
						@include border-radius(40px);
						img {
							margin-right: 10px;
							width: 27px;
							height: 27px;
							@include border-radius(100%);
						}
						&:hover {
							box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
						}
						&.active {
							border: 1.4px solid $color-primary1;
							box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
						}
						&.no-badge-btn {
							padding: 5px 15px;
							.MuiSkeleton-root {
								background-color: rgba(0, 0, 0, 0.05);
								&.MuiSkeleton-text {
									margin-left: 5px;
									.MuiTypography-body1 {
										line-height: 1;
									}
								}
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
					.cancel-btn {
						padding: 0;
						position: absolute;
						right: 15px;
						top: 15px;
						z-index: 1;
						cursor: pointer;
						//background: $color-primary1;
						@include border-radius(20px);
						svg {
							width: 10px;
							height: 10px;
							path {
								fill: $color-primary1;
								stroke: $color-primary1;
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				.recog-post-badges-left {
					@include flex;
					@include align-items(center);
				}
			}
			.my-post-filter-actions {
				position: relative;
				@include flex;
				@include justify-content(flex-end);
				&:after {
					content: '';
					width: 100%;
					height: 1px;
					position: absolute;
					left: 0;
					top: 12px;
					border-bottom: 1px solid $color-expanded-tiles;
				}
				.my-post-switch {
					padding-left: 20px;
					position: relative;
					z-index: 1;
					background: $color-secondary2;
				}
			}
			.recog-posts-list-area {
				margin-top: 15px;
				.recog-post-list {
					@include flex;
					margin-bottom: 15px;
					.recog-user-info {
						margin-top: 25px;
						.common-avatar {
							background: rgba($color-secondary1, 0.2);
							border: 1.4px solid transparent;
							img {
								width: 16px;
								height: 16px;
								@include border-radius(unset);
							}
						}
					}
					.recog-post-box {
						flex: 1;
						background: $color-bg-form;
						border: 1.4px solid $color-border-tile;
						@include border-radius(6px);
						.recog-post-box-inner {
							padding: 25px 25px 15px;
							background: $color-white;
							@include border-radius(6px);
							&.highlight {
								background: $color-okr-highlighter !important;
							}
							.recog-post-head {
								margin-bottom: 15px;
								@include flex;
								@include justify-content(space-between);
								.recog-post-head-left {
									.recog-post-head-left-top {
										@include flex;
										.recog-post-users {
											@include flex;
											.MuiListItem-root {
												margin-right: 5px;
												width: auto;
												flex-grow: 1;
												padding: 0 3px 0 0;
												&:after {
													right: 0;
													position: absolute;
													content: ',';
												}
												&:last-child {
													&:after {
														display: none;
													}
												}
											}
										}
										.others-lists {
											.MuiTypography-body1 {
												cursor: pointer;
												color: $color-primary1;
												padding: 0;
											}
										}
									}
									.recog-post-head-left-bottom {
										@include flex;
										.MuiTypography-h6 {
											padding-right: 13px;
											margin-right: 10px;
											position: relative;
											&::first-letter {
												text-transform: capitalize;
											}
											&:after {
												position: absolute;
												top: 8px;
												right: 0;
												content: '';
												width: 3px;
												height: 3px;
												background: rgba($color-secondary1, 0.5);
												@include border-radius(100%);
											}
											&:last-child {
												&:after {
													display: none;
												}
											}
										}
									}
								}
								.recog-post-head-right {
									button {
										padding: 0;
										min-width: auto;
										width: 24px;
										height: 24px;
										@include border-radius(100%);
										@include transition(0.25s);
										svg {
											width: 14px;
											height: 14px;
											path {
												fill: $color-primary1;
											}
										}
									}
								}
							}
							.recog-post-content {
								@include flex;
								@include align-items(flex-start);
								padding-bottom: 25px;
								.recognize-badge-box {
									padding: 8px 5px;
									width: 80px;
									min-width: 80px;
									max-width: 80px;
									min-height: 90px;
									cursor: default;
								}
								// .post-with-badge {
								// 	margin-right: 12px;
								// 	width: 50px;
								// 	height: 50px;
								// 	@include border-radius(100%);
								// }
							}
							.recog-post-footer {
								@include flex;
								@include justify-content(space-between);
								@include align-items(center);
								padding-top: 15px;
								border-top: 1.4px solid $color-hover;
								.recog-post-actions-left {
									@include flex;
									button {
										font-size: $font-size-h6;
										font-weight: $font-weight-bold;
										padding: 5px 10px;
										color: $color-primary1;
										background: $color-bg-form;
										border: 1.4px solid rgba($color-input-field, 0.5);
										@include border-radius(20px);
										min-height: 29px;
										svg {
											width: 14px;
											height: 14px;
											path {
												fill: $color-primary1;
											}
										}
										&.comment-btn {
											margin-left: 10px;
											svg {
												margin-right: 8px;
											}
										}
									}
									.like-filled-btn {
										position: relative;
										.like-animation-img {
											position: absolute;
											bottom: -8px;
											left: -15px;
										}
										.like-btn {
											position: relative;
											&::after {
												content: '';
												position: absolute;
												top: 0;
												left: 0;
												width: 100%;
												height: 100%;
											}
											&.btn-anim {
												.icon {
													svg {
														@include animation(like-anim 1s);
													}
												}
											}
										}
									}
								}
								.recog-post-actions-right {
									> .MuiList-root {
										@include flex;
										> .MuiListItem-root {
											width: auto;
											padding: 0;
											margin: 0 10px;

											> .MuiListItemText-root {
												> .MuiTypography-body1 {
													font-size: $font-size-h6;
													color: $color-secondary1;
												}
												&.like-popup {
													> .MuiTypography-body1 {
														cursor: pointer;
														color: $color-primary1;
													}
												}
											}
											&:after {
												margin-right: -12px;
												position: absolute;
												right: 0;
												content: '';
												width: 3px;
												height: 3px;
												background: rgba($color-secondary1, 0.5);
												@include border-radius(100%);
											}
											&:last-child {
												margin: 0 0 0 10px;
												&:after {
													display: none;
												}
											}
											button {
												padding: 0;
												font-size: $font-size-h6;
												color: $color-primary1;
												background: transparent;
												cursor: pointer;
												&:hover {
													background: transparent;
												}
												.MuiTouchRipple-root {
													display: none;
												}
											}
										}
									}
								}
							}
						}
						.recog-post-note {
							padding: 5px 25px;
						}
						.recog-post-comments-trail {
							.MuiCollapse-wrapper {
								padding: 15px 30px 15px 25px;
								background: $color-white;
								@include border-radius(0 0 6px 6px);
								.recog-post-comment-list {
									margin-bottom: 10px;
									.recog-comment-top {
										@include flex;
										.recog-user-info {
											margin-top: 5px;
										}
										.add-comment-field {
											svg {
												path {
													fill: $color-primary1;
												}
											}
										}
										.recog-post-box {
											border: none;
											.recog-post-box-inner {
												background: $color-bg-form;
											}
										}
									}
									.recog-comment-btm {
										margin: 5px 0 15px;
										@include flex;
										@include justify-content(space-between);
										.recog-comment-btm-left {
											@include flex;
											@include align-items(center);
											margin-left: 40px;
											.like-filled-btn {
												@include flex;
												.MuiButton-root {
													position: relative;

													.icon {
														position: relative;

														z-index: 0;
													}
													.remove-img {
														position: relative;
														z-index: 1;
													}
												}
											}
											.MuiButton-root {
												padding: 0;
												margin: 0 10px;
												position: relative;
												font-size: $font-size-h6;
												color: $color-primary1;
												font-weight: $font-weight-bold;
												&.like-btn {
													margin-left: 25px;
													.like-animation-img {
														position: absolute;
														bottom: -5px;
														left: -30px;
													}
													&:after {
														margin-right: -12px;
														position: absolute;
														right: 0;
														content: '';
														width: 3px;
														height: 3px;
														background: rgba($color-secondary1, 0.5);
														border-radius: 100%;
													}
												}
												&:hover {
													background: transparent;
												}
												.MuiTouchRipple-root {
													display: none;
												}
											}
										}
										.recog-comment-btm-right {
											> .MuiList-root {
												@include flex;
												> .MuiListItem-root {
													width: auto;
													padding: 0;
													margin: 0 10px;
													&.active {
														cursor: pointer;
														> .MuiListItemText-root {
															> .MuiTypography-body1 {
																color: $color-primary1;
																cursor: pointer;
															}
														}
													}
													> .MuiListItemText-root {
														> .MuiTypography-body1 {
															font-size: $font-size-p-body2;
															color: $color-secondary1;
														}
													}
													&:after {
														margin-right: -12px;
														position: absolute;
														right: 0;
														content: '';
														width: 3px;
														height: 3px;
														background: rgba($color-secondary1, 0.5);
														border-radius: 100%;
													}
													&:last-child {
														margin: 0 0 0 10px;
														&:after {
															display: none;
														}
													}
												}
											}
										}
									}
									.recog-replies-trail {
										padding-left: 40px;
									}
								}
							}
							.btn-small-text {
								text-transform: unset !important;
							}
							.no-of-replies {
								margin-bottom: 15px;
								.no-of-replies-btn {
									@include flex;
									@include align-items(center);
									padding: 0;
									color: $color-primary1;
									font-weight: $font-weight-bold;
									font-size: $font-size-h6;
									svg {
										width: 14px;
										height: 14px;
										margin-right: 5px;
										path {
											fill: $color-primary1;
										}
									}
									&:hover {
										background: transparent;
									}
									.MuiAvatar-root {
										margin-right: 3px;
									}
								}
							}
						}
						&.recog-post-editor-box {
							background-color: transparent;
							.recog-add-post-action {
								margin-top: 10px;
								@include flex;
								@include justify-content(flex-end);
							}
						}
					}
				}
				.load-more-area {
					position: relative;
					@include flex;
					@include justify-content(space-between);
					margin: 25px 0;
					&:after {
						position: absolute;
						top: 13px;
						content: '';
						height: 1.4px;
						width: 100%;
						background: $color-hover;
					}
					.load-more,
					.view-less {
						position: relative;
						z-index: 1;
						background: $color-white;
					}
					.load-more {
						padding-right: 10px;
					}
					.view-less {
						padding-left: 10px;
					}
				}
			}
			.welcome-content {
				img {
					margin-top: 50px;
				}
			}
			.load-more-action {
				@include flex;
				@include justify-content(center);
				button {
					color: $color-primary1;
					&:hover {
						background-color: transparent;
						text-decoration: underline;
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
		}
		.recog-post-right {
			width: 430px;
			max-width: 430px;
			min-width: 430px;
			position: relative;
			.recognize-badge-wrapper {
				max-height: 422px;
			}
			.team-leaderboard-box {
				margin-top: 20px;
				padding: 20px;
				background-color: $color-white;
				border: 1.4px solid $color-border-tile;
				@include border-radius(6px);
				.team-leaderboard-head {
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					margin-bottom: 15px;
					.MuiTypography-h6 {
						//text-transform: uppercase;
						font-size: $font-size-h5;
					}
					.memberwise-by-switch {
						position: relative;
					}
				}
				.no-record {
					margin-top: 2.25em;
					text-align: center;
					padding: 0 30px;
					object {
						margin-bottom: 15px;
					}
					h2 {
						margin-bottom: 5px;
					}
				}
				.team-list-head {
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.MuiList-root {
						@include align-items(flex-end);
						padding: 0 5px;
					}
					.custom-dropdown {
						width: calc(100% - 90px);
						min-width: auto;
					}
				}
				.team-member-list {
					margin-top: 20px;
					.team-list-head {
						padding-bottom: 10px;
					}
					.team-list-area {
						max-height: 312px;
						overflow: auto;
						@include custom-scrollbar;
						overflow-y: overlay;
					}
					.team-list-row {
						padding: 10px 5px;
						@include border-radius(3px);
						cursor: pointer;
						&:hover {
							background: $color-hover;
						}
						&.active {
							background: $color-secondary2;
						}
						.team-details {
							@include flex;
							@include align-items(center);
							.team-avatar {
								margin-right: 7px;
							}
						}
					}
					ul {
						padding: 0;
						@include flex;
						@include justify-content(space-between);
						@include align-items(flex-start);
						width: 100%;
						li {
							padding: 0 5px;
							width: 65px;
							&:first-child {
								padding-left: 0px;
							}
							&.team-col1 {
								width: calc(100% - 195px);
							}
							.recognize-badge-count {
								@include flex;
								@include align-items(center);
								@include justify-content(center);
								padding: 0 5px;
								min-width: 22px;
								height: 22px;
								background: $color-bg-form;
								@include border-radius(22px);
								font-size: $font-size-h6;
								line-height: $line-height2;
								font-weight: $font-weight-bold;
							}
						}
					}
					.team-list-area {
						ul {
							li {
								&.team-col1 {
									width: calc(100% - 195px);
								}
							}
						}
					}
				}
			}
		}
	}
}
/* Badges List wrapper*/
.recognize-badge-wrapper {
	padding: 20px;
	width: 430px;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	max-height: 382px;
	overflow: auto;
	@include custom-scrollbar;
	.wall-of-fame-head {
		margin-bottom: 15px;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.MuiTypography-h6 {
			//text-transform: uppercase;
			font-size: $font-size-h5;
		}
		.given-by-switch {
			position: relative;
		}
	}
	.recognize-badge-wrapper-inner {
		@include flex;
		flex-flow: row wrap;

		.recognize-badge-box {
			margin: 5px;
			flex: 0 1 calc(25% - 10px);
			min-width: calc(25% - 10px);
			max-width: calc(25% - 10px);
			padding-top: 10px;
			.recognize-badge-new {
				position: absolute;
				right: 4px;
				top: -3px;
				width: 28px;
				height: 10px;
			}
		}
	}
	.welcome-content {
		margin-top: 0;
		.no-recognize-badge {
			margin-top: 14px;
			@include flex;
			@include justify-content(center);
			.recognize-badge-box {
				@include flex;
				@include align-items(center);
				flex-direction: column;
			}
			.MuiSkeleton-root {
				background: rgba($color-input-field, 0.1);
			}
		}
	}
}
/* Badges popper */
.badges-list-popper {
	margin: 0 !important;
	.recognize-badge-wrapper {
		.recognize-badge-box {
			border: 1.4px solid $color-bg-form;
			&:hover {
				//border: 1.4px solid $color-border-tile;
				box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
			}
			&.active {
				border: 1.4px solid $color-primary1;
				box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
			}
		}
	}
}
/* Other praised people popup up */
.others-lists-popup {
	z-index: 9;
	min-width: 150px;
	.MuiPaper-root {
		padding: 15px;
		max-height: 216px;
		overflow-y: auto;
		@include custom-scrollbar;
		.MuiTypography-h6 {
			color: $color-primary2;
		}
		.MuiList-root {
			padding: 0;
			.MuiListItem-root {
				padding: 10px 0 0;
				.MuiChip-root {
					position: relative;
					padding: 0 8px 0 38px;
					@include border-radius(12px);
					font-size: $font-size-h6;
					min-height: 24px;
					height: auto;
					.MuiAvatar-root {
						position: absolute;
						left: 0;
						top: -1.4px;
						margin-top: 0;
						margin-right: 0.5em;
						width: 26.8px;
						height: 26.8px;
						margin-left: 0;
						@include border-radius(13px);
						border: 1.4px solid $color-white;
						font-size: $font-size-h6;
						img {
							@include border-radius(12px);
						}
					}
					.MuiChip-label {
						padding: 0;
						font-size: $font-size-h6;
						line-height: $line-height2;
					}
					&.user-team-chip {
						position: relative;
						padding: 0 8px 0 38px;
						@include border-radius(12px);
						font-size: $font-size-h6;
						min-height: 24px;
						height: auto;
						.MuiAvatar-root {
							width: 34px;
						}
					}
				}
			}
		}
	}
}
/* Recognition like popup up */
.recog-liked-by-dropdown {
	transform: none;
	z-index: 9;
	padding-top: 12px;
	.MuiPaper-root {
		width: 295px;
		min-width: 295px;
		@include default-shadow;
		@include border-radius(6px);
		position: relative;
		&::after,
		&:before {
			margin-left: 16px;
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			bottom: 0;
			top: 0px;
			left: 50%;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(135deg));
		}
		&::after {
			border: 8px solid $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			border: 8px solid $color-white;
		}
		ul {
			padding: 15px 0;
			max-height: 280px;
			overflow: auto;
			@include custom-scrollbar;
			@include border-radius(6px);
			background: $color-white;
			z-index: 1;
			li {
				padding: 7px 20px;
				@include justify-content(space-between);
				background-color: $color-white;
				.user-info {
					margin-right: 15px;
				}
				&:hover {
					background-color: $color-hover;
				}
				&.active {
					background-color: $color-secondary2;
				}
			}
		}
	}
	&[x-placement~='top'] {
		padding-bottom: 12px;
		padding-top: 0px;
		.MuiPaper-root {
			&:before,
			&:after {
				top: 100%;
			}
			&:after {
				@include box-shadow(0 0 10px 3px rgba($color-primary2, 0.12));
			}
		}
	}
}
/*cfr like popup*/
.conversation-drawer-like.recog-liked-by-dropdown {
	z-index: 9999;
}
.recog-area {
	.recog-post-editor-box {
		width: calc(100% + 3px);
		.editor-pane {
			//max-width: calc(100% - 43px);
			margin-right: -3px;
			width: 100%;
		}
	}
}
