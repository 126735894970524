body {
	background: $color-secondary2;
	font-family: $font-family-lato;
	font-weight: $font-weight-regular;
	font-size: $font-size-base;
	line-height: $line-height3;
	color: $color-primary2;
	.MuiButton-root,
	.MuiIconButton-root {
		font-weight: $font-weight-regular;
		letter-spacing: unset;
		font-size: $font-size-h4;
		color: $color-primary2;
	}
	.MuiTypography-h1,
	h1 {
		font-size: $font-size-h1;
		font-weight: $font-weight-bold;
		line-height: $line-height1;
		color: $color-primary2;
		padding: 0;
	}
	.MuiTypography-h2,
	h2 {
		font-size: $font-size-h2;
		font-weight: $font-weight-bold;
		line-height: $line-height2;
		color: $color-primary2;
		padding: 0;
		// &.page-title-large {
		// 	font-size: $font-size-h1;
		// 	line-height: $line-height1;
		// }
	}
	.MuiTypography-h3,
	h3 {
		@extend .MuiTypography-h2;
		font-weight: $font-weight-bold;
	}
	.MuiTypography-h4,
	h4 {
		font-size: $font-size-h4;
		font-weight: $font-weight-bold;
		line-height: $line-height2;
		color: $color-primary2;
		padding: 0;
	}
	.MuiTypography-h5,
	h5 {
		font-size: $font-size-h5;
		font-weight: $font-weight-bold;
		line-height: $line-height3;
		color: $color-primary2;
		padding: 0;
	}
	.MuiTypography-h6,
	h6 {
		color: $color-secondary1;
		font-size: $font-size-h6;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		padding: 0;
	}
	.MuiTypography-body1,
	.MuiTypography-button,
	.MuiMenuItem-root,
	p {
		font-size: $font-size-base;
		line-height: $line-height3;
		color: $color-primary2;
		font-weight: $font-weight-regular;
		text-transform: unset;
	}

	.MuiTypography-body2 {
		@extend .MuiTypography-h6;
		color: $color-primary2;
	}
	.MuiTypography-subtitle1 {
		color: $color-secondary1;
		@extend .MuiTypography-h5;
		font-weight: $font-weight-bold;
		padding: 0;
		text-transform: unset;
	}
	.MuiTypography-subtitle2 {
		color: $color-secondary1;
		font-size: $font-size-subtitle2;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		padding: 0;
	}
	.MuiTypography-root {
		&.subtitle3 {
			@extend .MuiTypography-subtitle2;
			line-height: 1.5;
			text-transform: none;
		}
		&.short-des-text {
			@extend .MuiTypography-h6;
		}
		&.font-weight-normal {
			font-weight: 400 !important;
		}
		&.font-weight-bold {
			font-weight: 700 !important;
		}
		&.text-upper-case {
			text-transform: uppercase !important;
		}
	}

	.no-sentence-case {
		@extend .MuiTypography-h6;
		text-transform: uppercase;
	}
	em {
		font-style: italic;
	}
	.extra-bold-font {
		font-weight: $font-weight-black;
	}
	strong {
		font-weight: $font-weight-bold;
	}
}
/**status message**/
.MuiTypography-root {
	&.accepted-text {
		background: rgba(3, 167, 59, 0.2);
		padding: 0.125em 0.625em;
		color: $color-completed !important;
		@include border-radius(3px);
		font-weight: $font-weight-bold !important;
		opacity: 0.7;
	}
	&.declined-text {
		background: rgba(255, 0, 0, 0.2);
		padding: 0.125em 0.625em;
		color: $color-text-info !important;
		@include border-radius(3px);
		font-weight: $font-weight-bold !important;
	}
	&.pending-text {
		background: rgba($color-primary2, 0.1);
		padding: 0.125em 0.625em;
		@include border-radius(3px);
		color: $color-primary2 !important;
		font-weight: $font-weight-bold !important;
	}
}
.text-lowercase {
	text-transform: lowercase !important;
}
