@import '../../../styles/sass-utils/variables';

.user-assessment-section-area {
	position: relative;

	.user-assessment-guide-link {
		@include flex;
		@include align-items(center);
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;

		a {
			margin-left: 10px;
			@include flex;
			@include align-items(center);
			color: $color-primary1;

			span {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				margin-right: 5px;
				width: 30px;
				height: 30px;
				background: rgba($color-white, 0.5);
				@include border-radius(40px);
				border: 1.4px solid $color-border-tile;
				animation-iteration-count: infinite;
				animation-duration: 1s;
				animation-name: pulse;
				transform-origin: center bottom;
				animation-fill-mode: both;
			}

			svg {
				width: 12px;
				height: 12px;
				animation-iteration-count: infinite;
				animation-duration: 1s;
				animation-name: pulse_animation;
				transform-origin: center bottom;
				animation-fill-mode: both;

				path {
					//fill: $color-primary1;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	.user-assessment-ques-area {
		margin-top: 30px;

		.user-assessment-ques-head {
			padding-bottom: 10px;
			position: relative;

			.MuiTypography-body1 {
				color: $color-hint-help-text;
			}

			.rotating-arrow {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				width: 18px;
				height: 18px;
				position: absolute;
				left: -22px;
				top: 1px;
				@include transition(0.15s);
				@include border-radius(24px);
				cursor: pointer;

				svg {
					width: 10px;
					height: 10px;

					path {
						stroke: $color-primary2;
					}
				}

				&:hover {
					background: $color-expanded-tiles;
				}

				&.expand {
					@include transform(rotate(90deg));
				}
			}
		}

		.user-assess-ques-panel {
			padding: 20px 0 20px 0;

			.error-field {
				left: -15px;
				top: auto;
				bottom: calc(100% + 10px);
				width: 225px;

				.helperText-bottom {
					color: $color-text-info;
				}

				&::before,
				&::after {
					margin-right: -3px;
					right: 50%;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
				}

				&::before {
					top: calc(100% - 1.4px);
					border-top: 8px solid $color-avatar-disable;
				}

				&::after {
					top: 100%;
					border-top: 8px solid $color-border-info;
				}
			}

			.user-assess-ques-title {
				margin-bottom: 15px;
				padding: 0 20px;
				position: relative;

				.ques-title {
					@include flex;
					@include align-items(flex-start);

					span {
						margin-right: 3px;
					}
				}
			}

			.user-assess-ques-row {
				padding: 0px 20px 0px;
				.user-assess-ques-right {
					position: relative;
					.pms-chat-bot-btn-box {
						width: 40px;
						height: 34px;
						position: absolute;
						left: -40px;
						top: 75px;
						z-index: 9;
						.chat-bot-btn-layer {
							cursor: pointer;
							position: absolute;
							width: 40px;
							height: 34px;
							@include border-radius(40px 0 0 40px);
							z-index: 3;
						}
						.chat-bot-help-txt-box {
							padding: 8px;
							width: 495px;
							height: 34px;
							position: absolute;
							left: -502px;
							bottom: 0px;
							z-index: 1;
							opacity: 0;
							@include transition(all ease-in-out 0.25s);
							// background: url(../../../images/chat-bot-help-text-bg.svg) no-repeat;
							background-color: rgba(0, 0, 0, 0.75);
							@include border-radius(20px 20px 20px 0);
							.MuiTypography-h6 {
								@include flex;
								@include align-items(center);
								font-weight: $font-weight-regular;
								color: $color-white;
							}
							.blinking-cursor {
								margin-left: 2px;
								display: inline-block;
								vertical-align: baseline;
								width: 1.4px; /* Width of the cursor line */
								height: 12px; /* Height of the cursor line */
								background-color: $color-white; /* Cursor line color */
								@include animation(blink 0.5s infinite);
							}
						}
						&:hover {
							.chat-bot-help-txt-box {
								left: 40px;
								opacity: 1;
							}
						}
					}
					.pms-chat-bot-btn {
						position: absolute;
						z-index: 2;
						padding: 6px 7px;
						width: 40px;
						height: 34px;
						@include justify-content(flex-start);
						border: 1.4px solid $color-white;
						@include border-radius(40px 0 0 40px);
						background: $color-bg-form;
						@include transition(all ease-in-out 0.25s);
						.chat-bot-img {
							cursor: pointer;
							width: 24px;
							height: 24px;
						}
						img {
							width: 24px;
							height: 24px;
						}
					}
				}
			}

			.assessment-rating-box {
				.MuiRating-root {
					.MuiRating-label {
						padding-bottom: 15px;
						width: 110px;
						@include flex;
						@include flex-direction(column);
						@include align-items(center);

						.MuiRating-icon {
							@include transition(transform 0.2s);

							&.MuiRating-iconHover,
							&.MuiRating-iconActive {
								transform: scale(1);
							}
						}

						span {
							&.MuiRating-visuallyhidden {
								position: relative;
								text-align: center;
								top: 5px;
								width: 110px;
								height: auto;
								clip: unset;
								font-size: $font-size-h6;
								line-height: $line-height5;
								color: $color-secondary1;
								opacity: 0;
								@include transition(0.25s);
							}

							&.first-rating + .MuiRating-visuallyhidden,
							&.last-rating + .MuiRating-visuallyhidden {
								opacity: 1;
							}

							&.star-hover-rating + .MuiRating-visuallyhidden,
							&.selected-last-rating + .MuiRating-visuallyhidden {
								opacity: 1;
							}
						}
					}

					// .MuiRating-label {
					// 	&:last-child {
					// 		span {
					// 			&.MuiRating-visuallyhidden {
					// 				opacity: 1;
					// 			}
					// 		}
					// 	}
					// 	&:first-child {
					// 		span {
					// 			&.MuiRating-visuallyhidden {
					// 				opacity: 1;
					// 			}
					// 		}
					// 	}
					// }
					&.Mui-disabled {
						opacity: 1;

						.MuiRating-label {
							span {
								&.MuiRating-visuallyhidden {
									color: $color-primary2;
								}
							}
						}
					}
				}

				&.assessment-rating-box-error {
					.MuiRating-root {
						.MuiRating-label {
							.MuiRating-icon {
								svg {
									path {
										fill: $color-text-info;
										fill-opacity: 1;
									}
								}
							}
						}
					}
				}
			}

			.assessment-radio-box {
				.MuiFormControlLabel-root {
					margin-left: -8px;

					.MuiFormControlLabel-label {
						font-size: $font-size-h6;
						line-height: $line-height5;
						color: $color-secondary1;
					}

					&.Mui-disabled {
						.Mui-checked + .MuiFormControlLabel-label {
							color: $color-primary2;
						}
					}
				}

				.MuiRadio-root {
					padding: 6px;
					color: $color-new-common4;

					svg {
						width: 16px;
						height: 16px;

						path {
							fill: rgba($color-new-common3, 0.5);
						}
					}

					&:hover {
						background: transparent;

						svg {
							path {
								fill: $color-new-common4;
							}
						}
					}

					&.Mui-checked {
						svg {
							path {
								fill: $color-new-common4;
							}
						}
					}
				}

				&.assessment-radio-box-error {
					.MuiRadio-root {
						svg {
							path {
								fill: $color-text-info;
							}
						}
					}
				}
			}

			.assess-comment-box {
				margin-top: 10px;
				position: relative;

				.assess-comments-btn {
					text-transform: unset !important;

					.MuiButton-label {
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						line-height: 1;
					}

					svg {
						margin-right: 5px;
						width: 14px;
						height: 14px;

						path {
							fill: $color-primary1;
						}
					}

					i {
						font-weight: $font-weight-bold;
					}
				}

				.assessment-comments-field {
					.MuiCollapse-wrapper {
						margin-top: 10px;
					}
				}

				.error-field {
					left: -35px;
					bottom: calc(100% + 5px);
					width: 280px;

					&::before,
					&::after {
						right: 70%;
					}
				}

				&.assess-comment-box-error {
					.assessment-comments-field {
						.ck.ck-toolbar {
							border-color: $color-text-info !important;
						}

						.ck.ck-editor__main {
							> .ck-content {
								border: 1.4px solid $color-text-info;
								border-top: 1.4px solid rgba($color-primary2, 0.2);
							}
						}
					}
				}
			}

			.assessment-descriptive-box-error {
				.ck.ck-toolbar {
					border-color: $color-text-info !important;
				}

				.ck.ck-editor__main {
					> .ck-content {
						border: 1.4px solid $color-text-info;
						border-top: 1.4px solid rgba($color-primary2, 0.2);
					}
				}
			}

			.view-only-comment {
				border-top: 1.4px solid $color-secondary2;
				padding-top: 10px;
				margin-top: 10px;
			}
		}
	}

	.user-assessment-notes-txt {
		margin-bottom: 10px;

		.MuiTypography-subtitle2 {
			text-transform: unset;
			font-weight: $font-weight-regular;
			color: $color-hint-help-text;
		}
	}

	.user-assessment-action {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);

		.btn-link {
			height: auto;
			line-height: 22px;
		}
	}
	.align-goals-download {
		margin-top: -10px;
	}
}

.assessment-slider-box {
	padding-bottom: 40px;

	.MuiSlider-root {
		width: calc(100% - 35px);
		padding: 0;
		height: 10px;
		display: block;
		margin: 4px 0;
		color: $color-new-common4;

		.MuiSlider-rail {
			width: calc(100% + 10px);
			height: 10px;
			opacity: 1;
			background: $color-white;
			border: 1.4px solid rgba($color-new-common3, 0.5);
			@include border-radius(10px);
		}

		.MuiSlider-track {
			height: 10px;
			@include border-radius(10px);
			background: $color-new-common4; // commented for color coding slider
		}

		.Mui-disabled {
			display: none;
		}

		.MuiSlider-thumb {
			width: 20px;
			height: 20px;
			margin-left: -10px;
			margin-top: -10px;
			top: 50%;
			color: $color-new-common4;
			background-image: url(../../../images/drag-bubble-2.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;

			&.Mui-focusVisible,
			&:hover {
				@include box-shadow(0px 0px 0px 8px rgba($color-new-common4, 0.2));
			}

			&.Mui-disabled {
				display: block;
			}
		}

		&.show-blue-slider {
			.MuiSlider-track {
				background-color: $color-primary1;
			}
		}

		.MuiSlider-mark {
			width: 4px;
			height: 4px;
			top: 3px;
			background-color: rgba($color-new-common3, 0.5);
			@include border-radius(4px);

			&.MuiSlider-markActive {
				background-color: rgba($color-white, 0.5);
				opacity: 1;
			}

			&[data-index='0'] {
				display: none;
			}
		}

		.show_slider_label,
		.hide_slider_label,
		.show-selected-label-value {
			display: block;
			top: 20px;
			color: $color-secondary1;
			width: 105px;
			text-align: center;
			font-size: $font-size-h6;
			line-height: $line-height5;
			overflow: hidden;
			text-overflow: ellipsis;

			&.MuiSlider-markLabelActive {
				opacity: 1;
			}
		}

		.show_slider_label {
			opacity: 1;
		}

		.hide_slider_label {
			opacity: 0;
		}

		// .MuiSlider-mark{
		// 	&:hover {
		// 		opacity: 1 !important;
		// 	}
		// }
		// .MuiSlider-markLabel {
		// 	top: 20px;
		// 	color: $color-secondary1;
		// 	opacity: 0;
		// 	width: 110px;
		// 	text-align: center;
		// 	font-size: $font-size-h6;
		// 	line-height: $line-height5;
		// 	overflow: hidden;
		// 	text-overflow: ellipsis;
		// 	&.MuiSlider-markLabelActive {
		// 		opacity: 1;
		// 	}
		// 	// &:hover {
		// 	// 	opacity: 1;
		// 	// }
		// }
		.MuiSlider-markLabel {
			top: 18px;
			white-space: unset;
		}

		&.Mui-disabled {
			.MuiSlider-markLabel {
				color: $color-primary2;

				.show-selected-label-value {
					color: $color-primary2;
				}
			}
		}
	}

	&.assessment-slider-box-error {
		.MuiSlider-root {
			.MuiSlider-thumb {
				background-image: url(../../../images/drag-bubble-2-error.svg);
			}
		}
	}
}

/**multiple user assessment css start here**/
.user-assessment-section-area {
	.user-assessment-ques-area {
		.user-assess-ques-panel.multi-user-assess {
			padding-bottom: 5px;

			.user-multiple-row-wrap {
				&:nth-child(even) {
					background: $color-white;
				}

				&:nth-child(odd) {
					background: transparent;
				}
			}

			.user-assess-ques-title {
				margin-bottom: 0px;
			}

			.user-assess-ques-des {
				.user-assess-ques-row {
					//@include flex;
					width: 100%;
					padding-top: 15px;
					padding-bottom: 15px;
				}

				.user-assess-ques-left {
					width: 100%;
					margin-bottom: 15px;

					//margin-right: 20px;
					.user-info {
						@include align-items(center);
						position: relative;

						.user-img {
							.MuiAvatar-root {
								margin-top: 0px;
							}
						}

						.user-info-details {
							@include flex;
							width: calc(100% - 40px);

							.MuiTypography-h4 {
								white-space: nowrap;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.user-menu-btn {
								padding: 0;
								min-width: 24px;
								width: 24px;
								height: 24px;
								@include border-radius(100%);
								margin-left: 10px;

								svg {
									width: 14px;
									height: 14px;

									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
				}

				.user-assess-ques-right {
					width: 100%;
					position: relative;
				}
			}
		}

		.user-assess-ques-panel {
			.user-assess-ques-right {
				.no-response-txt {
					color: rgba($color-primary2, 0.5);
				}
			}
		}
	}
}

.multiple-assess-sub-head {
	@include justify-content(space-between);

	.sub-head-left {
		@include flex;
		@include align-items(center);

		.MuiTypography-h6 {
			color: rgba($color-primary2, 0.5);
		}

		.alert-text {
			margin-left: 10px;
		}
	}
}

.multiple-assessment-action {
	.action-right {
		@include flex;
		@include align-items(center);

		.sliding-btn {
			button {
				position: relative;

				&:last-child {
					margin-right: 0px;

					&::after {
						display: none;
					}
				}

				&::after {
					content: '';
					position: absolute;
					right: -9px;
					width: 3px;
					min-width: 3px;
					height: 3px;
					border-radius: 3px;
					background-color: $color-bg-dot;
					opacity: 0.5;
					top: 11px;
				}
			}

			.prev-link {
				.icon {
					svg {
						@include transform(rotate(180deg));
						margin: 0 5px 0 0px;
					}
				}
			}

			.next-link {
				.icon {
					order: 2;

					svg {
						margin: 0 0 0 5px;
					}
				}
			}
		}
	}
}

/*Final Assessment Rating*/
.assessment-final-rating-area {
	position: relative;
	margin-top: 30px;

	.role-transition-guide-link {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;

		a {
			@include flex;
			@include align-items(center);
			color: $color-primary1;

			span {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				margin-right: 5px;
				width: 30px;
				height: 30px;
				background: rgba($color-white, 0.5);
				@include border-radius(40px);
				border: 1.4px solid $color-border-tile;
				animation-iteration-count: infinite;
				animation-duration: 1s;
				animation-name: pulse;
				transform-origin: center bottom;
				animation-fill-mode: both;
			}

			svg {
				width: 12px;
				height: 12px;
				animation-iteration-count: infinite;
				animation-duration: 1s;
				animation-name: pulse_animation;
				transform-origin: center bottom;
				animation-fill-mode: both;

				path {
					//fill: $color-primary1;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	.final-rating-head {
		.MuiTypography-h4 {
			margin-bottom: 3px;
		}

		.final-rating-head-score {
			@include flex;

			.final-rating-score-data {
				padding-left: 10px;
				@include flex;

				span {
					padding-right: 10px;
					margin-right: 10px;
					position: relative;

					&:after {
						content: '';
						position: absolute;
						right: 0;
						top: 8px;
						width: 3px;
						height: 3px;
						@include border-radius(3px);
						background: rgba($color-secondary1, 0.5);
					}

					&:last-child {
						padding-right: 0;
						margin-right: 0;

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}

	.final-rating-panel {
		margin-top: 10px;

		.select-rating-box {
			margin-bottom: 30px;

			.MuiTypography-body1 {
				svg {
					margin-right: 5px;
					width: 14px;
					height: 14px;
				}

				i {
					margin-left: 3px;
				}
			}

			.final-rating-select-box {
				width: 295px;
				margin-top: 3px;
			}
		}

		.final-rating-comment-box {
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
			}

			.final-rating-comment-field {
				margin-top: 3px;

				.view-only-comment {
					border-top: 1.4px solid $color-secondary2;
					padding-top: 10px;
					margin-top: 10px;
				}
			}
		}

		.final-rating-comment-mssg {
			margin-top: 5px;
		}

		.final-rating-action {
			margin-top: 10px;
			@include flex;
			@include justify-content(flex-end);
		}

		.final-rating-label {
			@include flex;
			@include align-items(center);

			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
			}
		}
	}
}

.chat-container {
	z-index: 999999 !important;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	z-index: -1;
	position: fixed;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);

	.MuiPaper-rounded {
		box-shadow: 0 0 6px #898787;
		max-width: calc(100vw - 100px);
		width: calc(100vw - 100px);
		height: 90%;
		background: #e5f0ff;
		border-radius: 8px;
		padding: 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		overflow: unset;

		.MuiDialogContent-root {
			padding: 8px;
			overflow: unset;
			-webkit-overflow-scrolling: unset;
		}

		iframe {
			border-radius: 8px !important;
		}
	}
	#btn-close {
		position: absolute;
		top: -11px;
		right: -14px;
		width: 30px;
		height: 30px;
		background: #fff;
		z-index: 9999999999;
	}
}

.header-wrapper:after {
	height: 50px;
}

iframe header {
	height: 46px;
}

iframe {
	header {
		height: 46px;
	}

	main {
		margin-top: 51px;
	}
}
