@import '../../../styles/sass-utils/variables';
.assign-users {
	.assign-users-inner {
		@include flex;
		@include align-items(center);
		width: 100%;
		.user-avatar-group {
			margin-right: 0.625em;
			position: relative;
			&.user-total-no {
				.assign-user-list {
					.MuiAvatarGroup-avatar {
						&:nth-child(6) {
							opacity: 0;
						}
						&.removeBlue:nth-child(6) {
							opacity: 1;
						}
					}
				}
			}
			&.two-digit {
				padding-right: 8px;
			}
			&.three-digit {
				padding-right: 14px;
			}
			&.four-digit {
				padding-right: 20px;
			}
			.user-avatar {
				border: none;
				width: 30px;
				height: 30px;
				&.user-avatar-placeholder {
					border: 1.5px dashed $color-primary2;
					text-align: center;
					opacity: 0.5;
					background-color: transparent;
					svg {
						max-width: 14px;
						max-height: 14px;
						path {
							fill: $color-primary2;
						}
					}
				}
			}
			.assign-user-list {
				@include flex;
				@include align-items(center);
				.MuiAvatarGroup-avatar {
					width: 30px;
					height: 30px;
					//box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.1);
					&:first-child {
						z-index: 0 !important;
					}
					&:nth-child(2) {
						z-index: 1 !important;
					}
					&:nth-child(3) {
						z-index: 4 !important;
					}
					&:nth-child(4) {
						z-index: 5 !important;
					}
					&:nth-child(5) {
						z-index: 6 !important;
					}
					&.MuiAvatar-colorDefault:nth-child(6) {
						width: 18px;
						height: 18px;
						background-color: $color-primary1;
						color: $color-white;
						z-index: 8 !important;
						font-size: $font-size-subtitle2;
						line-height: 18px;
						font-weight: $font-weight-bold;
						border: none;
					}

					&.MuiAvatar-colorDefault.removeBlue:nth-child(6) {
						background: $color-bg-info;
						color: $color-tags;
						font-size: $font-size-h5;
						width: 30px;
						height: 30px;
						font-weight: $font-weight-regular;
					}
				}
				&.not-two-digit {
					.MuiAvatarGroup-avatar {
						&.MuiAvatar-colorDefault:nth-child(6) {
							@include border-radius(168px);
							padding: 0 5px;
							width: auto;
							height: 18px;
							margin-top: 1px;
							border: 0px;
						}
					}
				}
			}
			/**total member list**/
			.total-member-count {
				width: 18px;
				height: 18px;
				background-color: $color-primary1;
				color: $color-white;
				z-index: 8 !important;
				font-size: $font-size-subtitle2;
				line-height: 18px;
				font-weight: $font-weight-bold;
				border: none;
				display: inline-block;
				@include border-radius(100%);
				position: absolute;
				left: 110px;
				top: 6px;
				text-align: center;
				&.not-two-digit {
					@include border-radius(168px);
					padding: 0 5px;
					width: auto;
					height: 18px;
					margin-top: 1px;
					border: 0px;
					display: inline-block;
				}
			}
		}
		.MuiTypography-subtitle1 {
			margin-left: 0.625em;
		}
	}
	.help-text {
		width: 100%;
		text-align: left;
		margin-top: 0.625em;
		.MuiTypography-subtitle1 {
			font-weight: $font-weight-regular;
		}
	}
}

/**assigned user popover**/
.assigned-user-popover {
	background: $color-white;
	@include default-shadow;
	@include border-radius(6px);
	width: 410px;
	z-index: 99999;
	max-width: 410px;
	.assignedUser-details-inner {
		.assignedUser-details-head {
			padding: 0.875em 1.25em 8px;
		}
		.assignedUser-details-list {
			max-height: 300px;
			overflow-y: auto;
			@include custom-scrollbar;
			margin-bottom: 0.3125em;
			padding: 0;
			li {
				@include flex;
				@include justify-content(space-between);
				padding: 8px 1.25em;
				cursor: pointer;
				@include align-items(flex-start);
				.assignedUser-details-left {
					@include flex;
					width: 80%;
					@include align-items(flex-start);
					// .MuiListItemAvatar-root {
					// 	min-width: 43px;
					// 	margin-top: 6px;
					// 	.MuiAvatar-root {
					// 		width: 30px;
					// 		height: 30px;
					// 	}
					// }

					.secondary-text {
						line-height: $line-height2;
					}
					.tooltip-text {
						display: block;
						max-width: 248px;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					&.user-name-only {
						@include align-items(center);
						.MuiListItemAvatar-root {
							margin-top: 0;
						}
						.MuiTypography-h4 {
							font-weight: normal;
						}
					}
				}
				.assignedUser-details-right {
					padding-right: 0.625em;
					@include flex;
					@include align-items(flex-start);
					margin-top: 0.5em;
				}
				&:hover {
					background: $color-hover;
				}
			}
		}
	}
}
