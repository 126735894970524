// @import '../../../styles/sass-utils/variables';
@import '../../styles/sass-utils/variables';
.my-progress-content {
	min-height: 5em;
}
.progress-cards {
	@include flex;
	padding: 1.375em 0;
	margin-top: 0.5em;
	.progress-cards-dashboard {
		@include flex;
	}
	.progress-main-card {
		@include flex;
		@include flex-direction(column);
		@include justify-content(space-between);
		min-width: 400px;
		max-width: 400px;
		min-height: 124px;
		width: 100%;
		height: 100%;
		padding: 1em 1.25em 15px;
		margin-right: 1.25em;
		background: $color-threshold;
		@include border-radius(6px);
		border: 1.4px solid $color-border-tile;
		.progress-card-title {
			padding-bottom: 0.5em;
			@include flex;
			@include align-items(baseline);
			@include justify-content(space-between);
			h2 {
				color: $color-white;
			}
			h6 {
				margin-right: 0.5em;
				color: $color-white;
			}
			.p-card-title-left {
				@include flex;
				@include align-items(baseline);
			}
			.p-card-title-right {
				p {
					color: $color-white;
					svg {
						margin-right: 4px;
						path {
							fill: $color-white;
						}
					}
				}
			}
		}
		.user-progress-text {
			.MuiTypography-body2 {
				&.progress-started {
					cursor: pointer;
				}
			}
		}
		.progress-bar {
			.MuiSlider-root {
				.MuiSlider-rail {
					background: rgba($color-white, 0.1);
				}
			}
		}
		.progress-card-bottom {
			.MuiTypography-h6 {
				color: $color-white;
			}
		}
	}
	.progress-bar {
		button {
			padding: 0;
			border: none;
			background: transparent;
			width: 100%;
			text-align: left;
		}
		.MuiSlider-root {
			padding: 0;
			height: 15px;
			display: block;
			margin: 0.25em 0;
			.MuiSlider-rail {
				height: 15px;
				opacity: 1;
				background: rgba($color-primary2, 0.15);
				@include border-radius(15px);
			}
			.MuiSlider-track {
				height: 15px;
				@include border-radius(15px);
			}
			.Mui-disabled {
				display: none;
			}
			.MuiSlider-thumb {
				width: 14px;
				height: 14px;
				margin-top: -7px;
				margin-left: -5px;
				top: 50%;
				background: $color-white;
				border: 1px solid $color-input-field;
				opacity: 0.5;
			}
		}
		.green {
			.MuiSlider-track {
				background-color: $color-on-track;
			}
		}
		.red {
			.MuiSlider-track {
				background-color: $color-text-info;
			}
		}
		.yellow {
			.MuiSlider-track {
				background-color: $color-lagging;
			}
		}
		.grey {
			.MuiSlider-track {
				background-color: $color-not-started;
			}
		}
	}
	.user-progress-text {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.MuiTypography-body2 {
			font-weight: $font-weight-bold;
			&.progress-not-started {
				color: $color-text-info;
			}
			&.progress-started {
				color: $color-primary1;
				@include flex;
				@include align-items(center);
				svg {
					margin-right: 0.25em;
				}
			}
		}
	}
	.progress-card-top {
		.MuiTypography-h2 {
			padding-bottom: 5px;
			color: $color-threshold;
			font-weight: $font-weight-black;
			@include flex;
			sup {
				font-size: $font-size-h6;
				line-height: 12px;
				vertical-align: middle;
				margin-top: 3px;
				margin-left: 1px;
			}
		}
	}
	.progress-card-bottom {
		.MuiTypography-h6 {
			color: $color-secondary1;
		}
		.tabpanel-okr-counts {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			margin-top: 14px;
			.okr-counts-text {
				@include flex;
				.MuiTypography-h6 {
					@include flex;
					@include align-items(center);
					padding-right: 10px;
					margin-right: 10px;
					position: relative;
					color: $color-white;
					svg {
						margin-right: 0.25em;
						width: 20px;
						height: 20px;
					}
				}
			}
			.user-count-txt {
				span {
					@include flex;
					@include align-items(center);
				}
				svg {
					width: 12px;
					margin-right: 0.2em;
					path {
						fill: $color-white;
					}
				}
			}
		}
	}
	.progress-card {
		@include flex;
		@include flex-direction(column);
		@include justify-content(space-between);
		padding: 0.875em 1.25em;
		max-width: 160px;
		min-width: 160px;
		min-height: 124px;
		width: 100%;
		height: 100%;
		cursor: pointer;
		background-color: $color-white;
		margin-right: 1px;
		border: 1.4px solid transparent;
		@include transition(0.25s);
		&:nth-of-type(2) {
			@include border-radius(6px 0 0 6px);
		}
		&:last-child {
			@include border-radius(0 6px 6px 0);
			margin-right: 0;
		}
		.progress-card-bottom {
			@include flex-direction(column);
			align-items: unset;
			padding-bottom: 3px;
			.okrs-count {
				@include flex;
				@include justify-content(space-between);
				padding-top: 3px;
			}
			.progress-bar {
				.MuiSlider-root {
					height: 4px;
					.MuiSlider-rail,
					.MuiSlider-track {
						height: 4px;
					}
				}
			}
		}
		&:hover {
			box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
		}
		&.active {
			border: 1.4px solid $color-primary1;
			box-shadow: 2px 0px 10px rgba($color-primary2, 0.12);
			@include border-radius(6px);
		}
		.progress-card-name {
			span {
				display: inline-block;
				padding: 0 0.7em;
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				line-height: $line-height5;
				text-transform: uppercase;
				@include border-radius(1.6em);
				vertical-align: top;
			}
			&.green {
				span {
					color: rgba($color-white, 0.9);
					background-color: $color-on-track;
				}
			}
			&.yellow {
				span {
					color: rgba($color-white, 0.9);
					background-color: $color-lagging;
				}
			}
			&.red {
				span {
					color: rgba($color-white, 0.9);
					background-color: $color-text-info;
				}
			}
			&.grey {
				span {
					color: rgba($color-primary2, 0.7);
					background-color: $color-not-started;
				}
			}
		}
	}
	&.team-progress-cards {
		.okr-tabpanel-card {
			min-width: 400px;
			min-height: 124px;
			margin-right: 1.25em;
			margin-top: 0;
			background: $color-threshold;
			border: none;
			.okr-tabpanel-card-top {
				padding-bottom: 0.5em;
				padding-top: 15px;
				.user-team-info {
					.MuiTypography-h2 {
						font-weight: $font-weight-black;
						@include flex;
						sup {
							font-size: $font-size-h6;
							line-height: 12px;
							vertical-align: middle;
							margin-top: 3px;
							margin-left: 1px;
						}
					}
					.MuiTypography-h2,
					.MuiTypography-subtitle2 {
						color: $color-white;
					}
					.MuiTypography-subtitle2 {
						color: $color-white;
						opacity: 0.5;
						a {
							color: $color-white;
						}
					}
				}
				.card-team-name {
					@include flex;
					align-items: baseline;
					.MuiTypography-h6 {
						color: $color-white;
						margin-right: 0.5em;
						text-transform: capitalize;
					}
				}
				.okr-tabpanel-card-actions {
					align-items: baseline;
					.kebab-icon {
						margin-top: -3px;
						margin-right: -12px;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-white;
							}
						}
					}
					.progress-started {
						color: $color-white;
						svg {
							margin-right: 4px;
							path {
								fill: $color-white;
							}
						}
					}
				}
			}
			.okr-tabpanel-card-bottom {
				padding: 0 1.25em 15px 1.25em;
				.user-progress-text {
					.MuiTypography-body2,
					.MuiTypography-h2 {
						color: $color-white;
					}
				}
				.user-progress-bar {
					.MuiSlider-root {
						.MuiSlider-rail {
							background: rgba($color-white, 0.1);
						}
					}
				}
				.tabpanel-okr-counts {
					padding-top: 6px;
					.okr-counts-text {
						.MuiTypography-h6 {
							@include flex;
							@include align-items(center);
							color: $color-white;
							margin-right: 10px;
							svg {
								width: 20px;
								height: 20px;
								margin-right: 0.25em;
							}
							&:after {
								display: none;
							}
						}
					}
					.user-count-txt {
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-white;
							}
						}
						.MuiTypography-h6 {
							color: $color-white;
						}
					}
				}
			}
		}
		.progress-card {
			min-height: 124px;
		}
	}
}
