@import '../../../styles/sass-utils/variables';
.assessment-area-top {
	@include flex;
	padding: 20px 0;
	.user-assessment-box-inner {
	}
	.assessment-area-top-left {
		margin-right: 6px;
		@include flex;
		.assessment-box {
			flex: 1;
			margin-right: 2px;
			padding: 20px 15px;
			cursor: pointer;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			max-width: 311px;
			&.assessment-box-type-1 {
				background-color: $color-white;
			}
			&.assessment-box-type-2 {
				background-color: rgba($color-white, 0.5);
				position: relative;
				&.open-final-rating {
					.box-head {
						padding-bottom: 16px;
					}
					&.default-final-rating {
						opacity: 0;
					}
				}
				.expand-final-rating {
					position: absolute;
					left: -1.4px;
					top: -1.4px;
					padding: 20px 15px;
					background: $color-white;
					border: 1.4px solid $color-primary1;
					@include default-shadow;
					@include border-radius(6px);
					width: calc(100% + 2.8px);
					z-index: 100;
					&:hover,
					&.active {
						border: 1.4px solid $color-primary1;
						@include default-shadow;
					}
					.final-assessment-selection-box {
						.custom-type-dropdown-top {
							margin-bottom: 16px;

							.ques-type-dropdown {
								.MuiList-root {
									max-height: 156px;
									.MuiListItem-root {
										padding-left: 10px;
										padding-right: 10px;
									}
								}
							}
							.dropdown-button {
								padding: 4px 10px;
							}
						}
						.final-rating-action {
							margin-top: 30px;
							@include flex;
							@include align-items(center);
							@include justify-content(flex-end);
							.btn-link {
								height: auto;
								line-height: $line-height4;
								margin: 0px;
							}
							.btn-primary {
								margin-left: 8px;
							}
						}
					}
				}
			}
			.box-head {
				padding-bottom: 10px;
				@include flex;
				@include align-items(baseline);
				.MuiTypography-h2 {
					font-size: 32px;
					line-height: 32px;
					font-weight: $font-weight-black;
				}
				svg {
					margin-right: 10px;
					width: 24px;
					height: 24px;
				}
				.overall-txt {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-regular;

					display: block;
				}
				sub.final-rating-text {
					font-size: $font-size-h6;
					line-height: $line-height5;
					font-weight: $font-weight-bold;
					color: $color-secondary1;
					margin-left: 7px;
				}
			}
			.box-setting-des {
				min-height: 40px;
				.btn-link {
					height: auto;
					line-height: 1;
				}
			}
			.box-content {
				margin-top: 10px;
				//.MuiTypography-h5,
				.MuiTypography-h6 {
					font-weight: $font-weight-regular;
				}
				.MuiTypography-h5 {
					padding-bottom: 5px;
				}
			}
			&.active,
			&:hover {
				border: 1.4px solid $color-primary1;
				@include default-shadow;
			}
			&.active {
				background-color: $color-white !important;
			}
			&.enable-box {
				background-color: $color-white !important;
				&:hover {
					border: 1.4px solid $color-primary1;
					@include default-shadow;
				}
			}
			&.disable-box {
				background-color: rgba($color-white, 0.5) !important;
				cursor: default;
				&:hover {
					border: 1.4px solid $color-border-tile;
					box-shadow: none;
				}
			}
		}
	}
	.assessment-area-top-right {
		@include flex;
		flex-flow: row wrap;
		&.only-one-tile {
			.assessment-right-box {
				.box-content {
					flex-direction: column;
					@include justify-content(flex-start);
					min-width: 68px;
					.request-1on1-icon {
						margin: 0 0 25px;
					}
					.MuiTypography-h5 {
						text-align: center;
					}
				}
			}
		}
		.assessment-right-box {
			@include flex;
			@include align-items(center);
			width: 100%;
			margin-bottom: 8px;
			cursor: pointer;
			padding: 20px 15px;
			background-color: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.box-content {
				@include flex;
				@include align-items(center);
				.icon {
					margin-right: 15px;
					@include flex;
					padding: 8px;
					@include border-radius(6px);
					&.performance-icon {
						background: rgba($color-completed, 0.2);
					}
					&.request-1on1-icon {
						background: rgba($color-bg-filterbtn, 0.7);
					}
					svg {
						width: 16px;
						height: 16px;
					}
				}
				.MuiTypography-h5 {
					font-weight: $font-weight-regular;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.active,
			&:hover {
				border: 1.4px solid $color-primary1;
				@include default-shadow;
			}
		}
	}
}
