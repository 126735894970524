@import '../styles/sass-utils/variables';

/**Tab panel okr card**/
.okr-card-main {
	width: 100%;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 1.25em;
	display: grid;
	&.okr-direct-report {
		.okr-tabpanel-card {
			cursor: pointer;
			// @include transition(1s);
			transition: all 1s ease !important;
			&:hover {
				@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			}
		}
	}
}
.okr-tabpanel-card {
	margin-top: 1.375em;
	@include border-radius(6px);
	min-height: 157px;
	background: $color-white;
	@include flex;
	@include flex-direction(column);
	@include justify-content(space-between);
	@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
	border: 1.4px solid $color-border-tile;

	&.feedbackDrawer {
		@include box-shadow(0px 0px 0px rgba($color-primary2, 0.1));
		min-height: auto;
		margin-top: 0;
		.okr-tabpanel-card-top {
			padding: 0;
		}
	}
	.okr-tabpanel-card-top {
		@include flex;
		@include justify-content(space-between);
		padding: 1.25em;

		.user-info-details {
			a {
				color: $color-primary1;
				text-decoration: underline;
			}
			.contribution-text {
				padding: 0.25em 0 1em;
				font-weight: $font-weight-bold;
			}
		}
		.okr-tabpanel-card-actions {
			@include flex;
			.kebab-icon {
				margin-top: -0.75em;
				margin-right: -1.1em;
			}
			.progressChart {
				max-width: 3.625em;
				.CircularProgressbar {
					.CircularProgressbar-text {
						font-size: 20px !important;
						font-weight: bold;
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
	.okr-tabpanel-card-bottom {
		padding: 0.75em 1.25em;
		.user-progress-text {
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			height: 2.25em;
			.MuiTypography-body2 {
				font-weight: $font-weight-bold;
				&.progress-not-started {
					color: $color-text-info;
				}
				&.progress-started {
					color: $color-primary1;
					@include flex;
					@include align-items(center);
					cursor: pointer;
					svg {
						margin-right: 0.25em;
					}
				}
			}
		}
		.user-progress-bar {
			button {
				padding: 0;
				border: none;
				background: transparent;
				width: 100%;
				text-align: left;
			}
			.MuiSlider-root {
				padding: 0;
				height: 15px;
				display: block;
				@include border-radius(15px);
				overflow: hidden;
				.MuiSlider-rail {
					height: 15px;
					opacity: 1;
					background: $color-not-started;
					@include border-radius(15px);
				}
				.MuiSlider-track {
					height: 15px;
					@include border-radius(15px);
				}
				.Mui-disabled {
					display: none;
				}
				.MuiSlider-thumb {
					width: 14px;
					height: 14px;
					margin-top: -7px;
					margin-left: -5px;
					top: 50%;
					background: $color-white;
					border: 1px solid $color-input-field;
					opacity: 0.5;
				}
				&.grey {
					.MuiSlider-track {
						background-color: $color-not-started;
					}
				}
				&.green {
					.MuiSlider-track {
						background-color: $color-on-track;
					}
				}
				&.yellow {
					.MuiSlider-track {
						background-color: $color-lagging;
					}
				}
				&.red {
					.MuiSlider-track {
						background-color: $color-text-info;
					}
				}
			}
		}
		.tabpanel-okr-counts {
			@include flex;
			@include justify-content(space-between);
			padding-top: 0.25em;
		}
	}
	.okr-counts-text {
		@include flex;
		.MuiTypography-h6 {
			padding-right: 10px;
			margin-right: 5px;
			position: relative;
			&:after {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 5px;
				height: 5px;
				content: '';
				background: $color-input-field;
				@include border-radius(10px);
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
	.user-count-txt {
		span {
			@include flex;
			@include align-items(center);
		}
		svg {
			margin-right: 0.2em;
			width: 1em;
			path {
				fill: $color-secondary1;
			}
		}
	}
	.direct-reports-bottom {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 0.75em 1.25em;
		border-top: 1px solid $color-hover;
		.direct-report-team-cont {
			@include flex;
			@include align-items(center);
			padding: 2px;
		}
		.teams-tags {
			@include flex;
			@include align-items(center);

			.team-tag {
				@include flex;
				@include border-radius(3px);
				overflow: hidden;
				margin-right: 0.5em;
				.MuiTypography-body2 {
					padding: 0.2em 0.45em;
					font-weight: $font-weight-bold;
				}
				.team-progress {
					.MuiTypography-body2 {
						color: $color-white;
						min-width: 35px;
						text-align: center;
					}
					&.red {
						background: $color-text-info;
					}
				}
				.team-tag-text {
					max-width: 100px;
				}
			}
		}
	}
	&.okr-tabpanel-compact-card {
		min-height: 7.375em;
		@include justify-content(flex-start);
		.okr-tabpanel-card-top {
			padding-bottom: 0px;
			.user-info-text {
				.contribution-text {
					padding-bottom: 0px;
				}
			}
			.okr-tabpanel-card-actions {
				.progressChart {
					margin-top: 0px;
					padding-top: 0.8125em;
				}
			}
		}
		.okr-tabpanel-card-bottom {
			height: auto;
			padding-top: 0px !important;
			.user-progress-text {
				height: auto;
				margin-top: -0.25em;
			}
			.tabpanel-okr-counts {
				margin-top: -0.25em;
				padding-top: 0px;
			}
		}
	}
	&.okr-tabpanel-ultracompact-card {
		min-height: 4.125em;
		max-width: 25.25em !important;
		min-width: 25.25em !important;
		.okr-tabpanel-card-top {
			padding: 0.6875em 1.25em;
			min-height: 4.125em;
			.user-info-details {
				@include align-items(center);
			}
			.okr-tabpanel-card-actions {
				@include align-items(center);
				.kebab-icon {
					margin-top: 0 !important;
					margin-right: -0.7em !important;
				}
			}
			.user-info-text .contribution-text {
				padding-bottom: 0;
			}
		}
		.progressText {
			padding-right: 0.625em;
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				font-weight: $font-weight-bold;
			}
		}
	}
	.contribution-text {
		cursor: pointer;
		@include flex;
		@include align-items(center);
		svg {
			margin-right: 3px;
		}
	}
}

/**Team Filter Buttons**/
.teams-filter {
	@include flex;
	@include align-items(center);
	.teams-filter-btns {
		button {
			margin-right: 0.5em;
			padding: 0.33em 1.07em;
			font-size: $font-size-h5;
			font-weight: $font-weight-bold;
			@include border-radius(0.375em);
			@include transition-default;
			min-height: 32px;
			&:hover {
				@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			}
			&.all-teams-btn {
				background: $color-white;
				border: 1.4px solid rgba($color-input-field, 0.5);
				&.active {
					@include box-shadow(5px 8px 24px rgba($color-primary2, 0.2));
				}
			}
			&.team-btn {
				padding: 0 0 0 1.07em;
				border: 0;
				//color: $otherColor38;
				//background: $otherColor39;
				&.active {
					@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
				}
				.percentage {
					padding: 7px;
					@include border-radius(0 5px 5px 0);
					color: $color-white;
					margin-left: 10px;
				}
				&:last-child {
					//color: $otherColor40;
					//background: $color-bg-info;
					border: 0;
				}
			}
		}
	}
	.teams-filter-dropdown {
		button {
			width: 30px;
			height: 30px;
			@include border-radius(30px);
		}
	}
	&.team-filter-no-percentage {
		.teams-filter-btns {
			button {
				&.team-btn {
					padding: 0 1.07em 0 1.07em;
				}
			}
		}
	}
	.team-label {
		margin-right: 10px;
		font-weight: 700;
	}
	.team-btn-no-cursor {
		cursor: default;
	}
}

/**OKR Popover(list of OKR & KR)**/
.okr-popover-dropdown {
	//background: $color-white;
	//box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
	//border-radius: 0.375em;
	//min-width: 480px;
	//max-width: 480px;
	z-index: 9999;
	&.scale-popover-dropdown {
		.okr-popover-dropdown-wrap {
			transform-origin: top left;
		}
		&[x-placement='top-start'] {
			.okr-popover-dropdown-wrap {
				transform-origin: bottom left;
			}
		}
	}
	.okr-popover-dropdown-wrap {
		background: $color-white;
		box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
		border-radius: 0.375em;
		min-width: 480px;
		max-width: 480px;
		z-index: 9999;
	}
	.okr-popover-dropdown-inner {
		max-height: 350px;
		overflow-y: auto;
		@include custom-scrollbar;
	}
	ul.okr-popover-okr-list {
		padding: 0px 0;
		width: 100%;
		> li {
			display: block;
			border-bottom: 1px solid $color-hover;
			padding: 10px;
			margin-bottom: 0px;
			cursor: default;
			&:last-child {
				margin-bottom: 0px;
				border-bottom: 0px;
			}
			.okr-popover-okr {
				@include flex;
				@include justify-content(space-between);
				padding: 0 0.625em 0.625em;
				cursor: default;
				.okr-popover-okr-left {
					width: 78%;
					@include flex;
					@include align-items(flex-start);
					padding-top: 0.571em;
					.okr-title {
						margin-left: 0.625em;
						margin-bottom: 0.3125em;
						width: 100%;
						.MuiTypography-h4 {
							overflow: hidden;
							white-space: normal;
							font-size: $font-size-h5;
							line-height: 1.42em;
							&.cursor {
								cursor: pointer;
							}
						}
					}
				}
				.okr-popover-okr-right {
					text-align: right;
					.progressChart {
						max-width: 58px;
						.CircularProgressbar {
							.CircularProgressbar-text {
								font-size: 20px !important;
								font-weight: bold;
								font-weight: $font-weight-regular;
							}
						}
					}
				}
			}
			&:hover {
				background: none;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
	ul.okr-popover-kr-list {
		padding: 0;
		width: 100%;
		li {
			@include flex;
			@include justify-content(space-between);
			padding: 3px 0.625em;
			cursor: default;
			background: $color-bg-tag !important;
			border: 1px solid $color-border-tile;
			@include border-radius(6px);
			margin-bottom: 0.3125em;
			&:last-child {
				margin-bottom: 0px;
			}
			.okr-popover-okr-left {
				width: 78%;
				@include flex;
				.okr-title {
					margin-left: 0.625em;
					margin-bottom: 0;
					width: 100%;
					.MuiTypography-h4 {
						overflow: hidden;
						// text-overflow: ellipsis;
						font-weight: normal;
						font-size: $font-size-h5;
						&.cursor {
							cursor: pointer;
						}
					}
				}
			}
			.okr-popover-okr-right {
				text-align: right;
				.kr-score {
					font-size: $font-size-h5;
					min-width: 51px;
					background: $color-bg-krscore;
					border: 1px solid $color-border-krscore;
					box-sizing: border-box;
					@include border-radius(6px);
					color: $color-lagging;
					font-weight: $font-weight-bold;
					padding: 0;
					margin: 0;
					text-align: center;
					height: 2em;
					line-height: 2em;
				}
			}
		}
	}
	.standalone-krlist {
		padding: 1em 0.625em 1em;
		border-top: 1px solid $color-hover;
		position: relative;
		margin-top: 1.28em;
		.standalone-head {
			position: absolute;
			top: -9px;
			font-size: 10px;
			color: rgba($color-primary2, 0.5);
			//opacity: 0.5;
			padding-right: 0.5em;
			padding-left: 0.5em;
			left: 0.5em;
			background: $color-white;
		}
		ul.okr-popover-kr-list {
			li {
				margin-bottom: 0.6em;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
/**Description arrow**/
.description-arrow {
	background: url(../images/arrow-125.svg) no-repeat center 0px;
	cursor: pointer;
	font-size: 0px;
	width: 15px;
	height: 15px;
	display: inline-block;
	margin-left: 5px;
	svg {
		transform: rotate(-145deg);
		path {
			fill: $color-primary1;
		}
	}
	.MuiTouchRipple-root {
		display: none;
	}
	&.description-arrow-tooltip {
		position: absolute;
		right: 0px;
		bottom: 9px;
	}
}
.alignment-body-class {
	.description-arrow,
	.description-arrow-tooltip {
		display: none;
	}
}

/** Currency icon for popover*/
.currencyWrapper {
	@include flex;
	@include align-items(center);
	@include justify-content(flex-start);
	svg {
		width: 12px;
		height: 12px;
	}
}

.okr-nature-type-tag {
	@include flex;
	background: rgba($color-secondary2, 0.7);
	@include border-radius(20px);
	.MuiTypography-body1 {
		text-transform: uppercase;
		//color: rgba($color-primary2, 0.7);
		font-weight: $font-weight-bold;
		font-size: $font-size-subtitle2;
		line-height: 1.6;
		border-radius: 1em;
		letter-spacing: 0.1px;
	}
	&.kr-nature-type {
		padding: 0 6px;
		margin-bottom: 6px;
		@include flex;
		@include align-items(center);
		svg {
			width: 10px;
			height: 10px;
			margin-right: 3px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.MuiTypography-subtitle2 {
		line-height: $line-height5;
		padding: 0 7px;
		@include border-radius(16px);
	}
}

.not-achieved {
	color: $color-text-info !important;
}
.avatar-decline {
	display: none !important;
}

/** common css class start here**/
.display-flex {
	@include flex;
}
.display-flex-space-between {
	@include flex;
	@include justify-content(space-between);
	flex: 1;
	align-items: center;
}

.no-record-max {
	max-width: 430px;
	margin: 0 auto !important;
}
/** no-record css **/
.no-okr-area {
	text-align: center;
	h2 {
		margin-bottom: 0.5em;
	}
}
.no-records-area {
	@include flex;
	@include justify-content(center);
	padding: 100px 60px;
	min-height: calc(100vh - 165px);
	background-image: url(../images/blank-screen-bg2.svg), url(../images/blank-screen-bg.svg);
	background-position: left bottom, center bottom;
	background-repeat: no-repeat, no-repeat;
	.no-record-message {
		@include flex;
		@include flex-direction(column);
		@include align-items(center);
		.MuiTypography-h2 {
			padding-bottom: 0.25em;
			margin-bottom: 0;
			text-transform: capitalize;
		}
		.get-started-card {
			padding: 20px 13px 13px;
			margin-top: 38px;
			max-width: 450px;
			@include flex;
			@include align-items(center);
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.add-user-img {
				margin: 0 14px -22px -50px;
			}
			.MuiTypography-h4 {
				padding-bottom: 0.375em;
				color: rgba($color-primary2, 0.7);
			}
			.MuiLink-root {
				margin-right: 30px;
				@include flex;
				@include align-items(center);
				text-transform: uppercase;
				color: $color-primary1;
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				text-decoration: none;
				svg {
					margin-right: 5px;
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
			.get-started-card-content {
				padding-bottom: 10px;
			}
			.get-started-card-link {
				@include flex;
				@include align-items(center);
			}
		}
		.or-separator {
			margin: 1.875em 0;
			padding: 0 50px;
			position: relative;
			opacity: 0.5;
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 10px;
				width: 40px;
				height: 2px;
				background: $color-secondary1;
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
		.create-align-link-area {
			margin-top: 30px;
			@include flex;
			.create-align-card {
				max-width: 312px;
				padding: 20px 15px;
				margin: 0 5px;
				@include flex;
				@include justify-content(space-between);
				background: $color-white;
				border: 1.4px solid #cde4ef;
				@include border-radius(6px);
				cursor: pointer;
				flex: 1 1;
				.card-left {
					margin-right: 10px;
					@include flex;
					@include align-items(flex-start);
					svg {
						margin-right: 0.5em;
						margin-top: -3px;
						min-width: 16px;
					}
				}
				.card-right {
					@include flex;
					@include align-items(center);
					//margin-right: -8px;
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
					line-height: $line-height3;
				}
				&:hover {
					@include box-shadow(2px 0px 10px rgba(41, 41, 41, 0.12));
					.add-btn-white {
						background: $color-primary1;
						svg {
							path {
								fill: $color-white;
							}
						}
					}
				}
				&.active {
					border: 1.4px solid $color-primary1;
				}
				&.so-obj-crd {
					.add-btn-white {
						svg {
							@include transform(rotate(-90deg));
						}
					}
				}
				&.create-new-obj-card {
					.card-left {
						.MuiTypography-body1 {
							@media screen and (max-width: 1300px) {
								margin-right: -8px;
							}
						}
					}
				}
			}
		}
	}

	&.request-no-record {
		.no-record-message {
			.create-align-link-area {
				.create-align-card {
					padding: 30px;
					max-width: 385px;
					.card-right {
						margin: 0px;
					}
				}
			}
		}
	}
}
.unlock-me-wrapper {
	.no-records-area {
		//min-height: calc(100vh - 124px);
	}
}
/**back to main link**/
.back-to-main {
	padding-bottom: 10px;
	a {
		color: $color-primary1;
		font-style: normal;
		margin-right: 5px;
		svg {
			display: inline-block;
			vertical-align: middle;
			margin-top: -2px;
			margin-right: 3px;
			width: 16px;
			height: 16px;
			path {
				fill: $color-primary1;
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
	button {
		@include flex;
		@include align-items(center);
		padding: 0;
		font-size: $font-size-h6;
		color: $color-primary1;
		font-weight: $font-weight-bold;
		svg {
			width: 16px;
			height: 16px;
			margin-right: 3px;
			path {
				fill: $color-primary1;
			}
		}
		&:hover {
			background-color: transparent;
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
	p {
		color: rgba($color-primary2, 0.5);
	}
	&.cycle-back {
		padding: 30px 25px 10px;
		margin-top: 0;
		//	position: absolute;
		width: 100%;
		top: 75px;
		//z-index: 99;
		p {
			line-height: 12px;
		}
	}
	&.cycle-back-amaps {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		margin-top: 0;
		position: absolute;
		width: auto;
		top: 22px;
		left: 0px;
		z-index: 99;
	}
}
/**free trial start form css start here**/
.free-trial-create-form {
	padding: 1.25em 3.75em 2.5em;
	max-width: 980px;
	margin: 0 auto;
	.logo-img {
		max-width: 160px;
		margin: 0 auto 1.875em;
		img {
			max-width: 100%;
		}
	}
	form {
		background: $color-white;
		padding: 2.5em 0;
		@include border-radius(6px);
		width: 100%;
	}
	.form-submit {
		padding: 0 3.75em;
		button {
			margin: 1em 0 0;
		}
	}
}
/**free trial auth popup css start here**/
.free-auth-popup {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	z-index: 9999 !important;
	.free-auth-popup-inner {
		position: absolute;
		width: 30em;
		padding: 30px;
		background: $color-white;
		@include border-radius(6px);
		.drawer-form-fields {
			.drawer-input-field {
				padding-left: 0px;
				padding-right: 0px;
				&.form-submit {
					padding-bottom: 0px;
					button {
						margin-left: 0px;
					}
				}
			}
		}
	}
}

.free-trial-msg {
	color: $color-text-info !important;
	margin-top: 30px !important;
}

/**whats new popup css start here**/
.whats-new-popup {
	@include flex;
	@include align-items(flex-end);
	@include justify-content(flex-start);
	z-index: 9999 !important;
	div[aria-hidden='true'] {
		background: rgba($color-primary2, 0.5) !important;
	}
}
.whats-new-popup-wrap {
	padding-left: 65px;
	position: relative;
	bottom: 40px;
	left: 0;
	@include transition(1s);
	height: 203px;
	min-height: 203px;
	@include animation(slide-right 0.5s);
	.popup-inner {
		padding: 36px 16px 40px;
		background-color: $color-primary1;
		background-image: url(../images/hills_City.svg);
		background-repeat: no-repeat;
		background-position: 0 bottom;
		@include box-shadow(0px 0px 20px rgba($color-primary2, 0.2));
		@include border-radius(6px);
		min-width: 350px;
		max-width: 350px;
		min-height: 203px;
		@include flex;
		.close {
			position: absolute;
			right: 6px;
			top: 6px;
			padding: 5px;
			cursor: pointer;
			opacity: 0.7;
			svg {
				path {
					fill: $color-white;
				}
			}
			&:hover {
				opacity: 1;
			}
		}
		p {
			color: $color-white;
		}
		.button-white-bg {
			@include border-radius(30px);
			color: $color-primary1;
			background: $color-white;
			@include transition-default;
			border: 1px solid $color-primary1;
			margin-top: 30px;
			min-width: 126px;
			line-height: 24px;
			&:hover {
				background: $color-white;
				@include box-shadow(6px 9px 26px rgba($color-white, 0.3));
			}
		}
		.popup-des {
			padding-left: 16px;
			padding-top: 7px;
		}
	}
}
/**common icon button here**/
.MuiButton-root.icon-btn {
	padding: 5px !important;
	width: 24px !important;
	height: 24px !important;
	@include border-radius(100%!important);
	svg {
		width: 14px;
		height: 14px;
	}
	&.icon-btn-blue {
		svg {
			path {
				fill: $color-primary1;
			}
		}
	}
}

/**user-note-box css start here**/
.user-note {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	margin: 20px 0;

	position: fixed;
	left: 50%;
	@include transform(translateX(-50%));
	z-index: 9999;
	transition: 1s;
	@include animation(slideIn 0.8s);
	bottom: 10px;
	.note-box {
		background: $color-white;
		@include border-radius(43px);
		@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
		border: 1.4px solid $color-border-tile;
		color: $color-secondary1;
		padding: 5px 15px;
		min-height: 30px;
		@include flex;
		@include align-items(center);
		h6 {
			font-weight: $font-weight-regular;
		}
	}
	&.bulk-active {
		bottom: 85px;
	}
}

/**page sub head css start here**/
.page-sub-head {
	padding: 30px 0;
	@include flex;
	@include align-items(center);
	.back-to-main {
		padding-bottom: 0;
		margin-right: 15px;
	}
	.user-info {
		@include align-items(center);
		.user-img {
			.MuiAvatar-root {
				margin-top: 0px;
			}
		}
	}
	&.no-pad-bottom {
		padding-bottom: 0px;
	}
}

/** white box css start here**/
.white-box {
	background: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	padding: 30px;
	margin-bottom: 10px;
}
/* User List pagination CSS*/
.user-list-pagination {
	overflow: inherit !important;
	@include flex;
	@include align-items(center);
	margin-right: 20px;
	.MuiInputBase-root {
		border-bottom: 0px;
		border: 0;
		margin-right: 5px;
		padding: 0;
		&.Mui-focused {
			border: 0;
			box-shadow: none;
		}
	}
	.MuiTypography-body2 {
		color: $color-secondary1 !important;
		line-height: $line-height2;
		//text-transform: capitalize;
	}
	.MuiSelect-select {
		min-height: 20px;
		padding: 0 23px 0 0px;
		color: $color-secondary1;
		font-size: $font-size-h6;
		line-height: $line-height2;
		font-weight: $font-weight-bold;
		&:focus {
			background-color: transparent;
		}
	}
	.MuiSelect-icon {
		color: $color-primary2;
		&.MuiTablePagination-selectIcon {
			background: url(../images/down-arrow.svg) no-repeat center center;
			path {
				display: none;
			}
		}
	}
	.MuiTablePagination-actions {
		@include flex;
		margin-left: 3px;
		button {
			padding: 0px;
			.MuiIconButton-label {
				width: 18px;
				height: 18px;
			}
			&.Mui-disabled {
				opacity: 0.5;
				.MuiIconButton-label {
					background: url(../images/down-arrow.svg) no-repeat center center;
					@include transform(rotate(90deg));
				}
			}
			svg {
				display: none;
			}
			&:hover {
				background: $color-white;
				@include box-shadow(2px 4px 7px rgba($color-primary2, 0.1));
			}
		}
	}
	button[aria-label='Previous page'] {
		margin-right: 2px;
		color: $color-primary1;
		.MuiIconButton-label {
			background: url(../images/down-arrow-blue.svg) no-repeat 6px center;
			@include transform(rotate(180deg));
		}
	}
	button[aria-label='Next page'] {
		color: $color-primary1;
		.MuiIconButton-label {
			background: url(../images/down-arrow-blue.svg) no-repeat 6px center;
		}
		&.Mui-disabled {
			.MuiIconButton-label {
				@include transform(rotate(-90deg));
			}
		}
	}
	.MuiTablePagination-toolbar {
		min-height: auto;
		margin-top: 0.45em;
	}
}
/**question dropdown css start here**/
.ques-type-selection,
.custom-dropdown {
	position: relative;
	min-width: 238px;
	max-width: 238px;
	.dropdown-button {
		width: 238px;
		padding: 4px 10px;
		font-size: $font-size-h5;
		line-height: $line-height2;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		@include justify-content(flex-start);
		background: $color-white;
		.icon {
			order: 2;
			@include transition-default;
			svg {
				width: 10px;
				height: 10px;
				path {
					stroke: $color-primary2;
				}
			}
		}
		span.MuiButton-label {
			@include justify-content(space-between);
			@include align-items(center);
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: $color-white;
		}
	}
	.ques-type-dropdown {
		background: $color-white;
		@include border-radius(6px);
		border: 1.4px solid $color-primary1;
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		z-index: 9;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		overflow: hidden;
		.MuiList-root {
			@include border-radius(0 0 6px 6px);
			overflow-y: auto;
			max-height: 232px;
			@include custom-scrollbar;
			.MuiListItem-root {
				cursor: pointer;
				display: block;
				border-bottom: 1.4px solid $color-hover;

				.star-rating {
					margin-top: 12px;
					svg {
						margin-right: 8px;
					}
				}
				&:hover {
					background-color: transparent;
				}
				&.active {
					background-color: $color-secondary2;
				}
				&:last-child {
					border-bottom: 0px;
				}
			}
		}
	}
	&.active {
		.dropdown-button {
			.icon {
				@include transform(rotate(180deg));
				z-index: 99;
			}
		}
	}
	&.custom-type-dropdown-bottom {
		&.active {
			.dropdown-button {
				border-color: transparent;
				@include border-radius(0px 0px 6px 6px);
			}
		}
		.dropdown-button {
			z-index: 1;
			transition: none;
			&.button-placeholder {
				color: rgba($color-primary2, 0.5);
			}
		}
		.ques-type-dropdown {
			z-index: initial;
			top: auto;
			bottom: -2px;
			padding-bottom: 41px;
			padding-top: 0px;
			min-width: 242px;
			left: -2px;
			.MuiList-root {
				border-radius: 0px;
				max-height: 198px;
				.MuiListItem-root {
					&:hover {
						background: $color-hover;
					}
					&.active {
						background: $color-secondary2;
					}
					&:last-child {
						border-bottom: 1.4px solid $color-hover;
					}
					p {
						font-weight: normal;
						font-size: $font-size-h5;
					}
				}
			}
		}
	}
	&.custom-type-dropdown-top {
		max-width: 100%;
		min-width: auto;
		width: 100%;
		&.active {
			.dropdown-button {
				border-color: transparent;
				@include border-radius(4px 4px 0px 0px);
				z-index: 99;
				border-bottom: 1.4px solid $color-hover;
			}
		}
		.dropdown-button {
			width: 100%;
			z-index: 1;
			transition: none;
			&.button-placeholder {
				//color: rgba($color-primary2, 0.5);
			}
		}
		.ques-type-dropdown {
			z-index: 9;
			position: absolute;
			top: -1.4px;
			left: -1.4px;
			width: calc(100% + 2.8px);
			padding-top: 40px;
			.MuiList-root {
				border-radius: 0px;
				max-height: 196px;
				.MuiListItem-root {
					&:hover {
						background: $color-hover;
					}
					&.active {
						background: $color-secondary2;
					}
					&:last-child {
						border-bottom: 1.4px solid $color-hover;
					}
					p {
						font-weight: normal;
						font-size: $font-size-h5;
					}
				}
			}
		}
	}
}
/**my self chip css **/
.owner-chip {
	padding: 0 0.625em 0 0.625em;
	color: $color-primary2 !important;
	background: $color-secondary2;
	font-size: $font-size-subtitle2 !important;
	line-height: $line-height5 !important;
	font-weight: $font-weight-bold !important;
	@include border-radius(0.1875em);
	margin-left: 0;
	letter-spacing: 0.1px;
	display: inline-block;
	vertical-align: top;
	min-width: 50px;
	text-align: center;
}
/**box css start here**/
.box-rectangle-info {
	background: rgba($color-white, 0.5);
	border: 1.4px solid $color-border-tile;
	padding: 25px;
	@include flex;
	@include justify-content(space-between);
	@include flex-direction(column);
	@include border-radius(6px);
	cursor: pointer;
	&:hover,
	&.active {
		border: 1.4px solid $color-primary1;
		@include default-shadow;
	}
	.box-head {
		margin-bottom: 25px;
		@include flex;
		//@include align-items(center);
		@include flex-direction(column);
		.box-head-title {
			@include flex;
			@include align-items(center);
			.btn-link {
				height: auto;
				line-height: 1;
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				margin: 0px;
				display: block;
				text-decoration: none;
			}
		}
		svg {
			margin-right: 5px;
			width: 24px;
			height: 24px;
			min-width: 24px;
		}
		h2 {
			font-size: $font-size-page-head;
			line-height: 32px;
			font-weight: $font-weight-black;
			.count-days {
				color: $color-text-info;
				margin-right: 7px;
			}
			&.blank-text {
				opacity: 0.3;
			}
		}
		sub {
			font-size: $font-size-h6;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			margin-left: 7px;
		}
	}
	.box-content {
		ul {
			margin: 15px 0 0 0;
			@include flex;
			flex-wrap: wrap;
			li {
				//flex: 50%;
				display: block;
				padding: 0px 0 12px 0;
				.MuiTypography-body2 {
					@include flex;
					@include align-items(center);
				}
				h6 {
					padding-left: 19px;
				}
				&:last-child {
					padding-bottom: 0px;
				}
			}
		}
	}
	&.white-color {
		background: $color-white;
	}
	&.box-two-col {
		@include flex-direction(row);
		img {
			order: 2;
			margin-right: -15px;
			max-width: 40%;
			width: 100%;
		}
	}
	/**checkin widget css start here**/
	&.checkin-widget {
		//background: url(../images/checkins.svg) right 0 no-repeat;
		//background-color: rgba($color-white, 1);
		//padding: 15px;
		//height: 100%;
		//width: 100%;
		//max-width: 100%;
		h5 {
			margin-top: 8px;
			line-height: $line-height4;
		}
		.box-head {
			display: block;
			//margin-bottom: 15px;
		}
		.box-content {
			> h6 {
				text-transform: uppercase;
				min-height: auto;
			}
			ul {
				li {
					width: 50%;
					padding-bottom: 0px;
					padding-right: 5px;
					h6 {
						min-height: auto;
					}
				}
			}
		}
	}
}

/**redirection on on one-drawer css start here**/
.redirection-on-on-one-drawer {
	.redirection-on-on-one-drawer-inner {
		padding: 0 3.75em;
		.okr-list-panel-head,
		.meeting-listing-content {
			ul {
				li {
					&.meeting-col1 {
						width: 55%;
					}

					&.meeting-col3 {
						width: 25%;
					}
					&.meeting-col4 {
						width: 20%;
					}
				}
			}
		}
		.meeting-listing-content {
			@include flex-direction(column);
			@include align-items(flex-start);
			@include transition(all 0.5s ease-in-out);
			ul {
				li {
					.user-info {
						.user-info-details {
							.MuiTypography-h4,
							.MuiTypography-subtitle2 {
								display: block;
								overflow: initial;
								white-space: normal;
								text-overflow: initial;
							}
						}
					}
				}
			}
			.one-on-one-tile {
				@include align-items(center);
				//cursor: pointer;
				width: 100%;
			}
			&.active {
				@include transform(scale(1.02));
				@include box-shadow(0px 4px 20px rgba($color-primary2, 0.2));
				margin-bottom: 1.5em;
			}
			.expanded-row {
				padding-bottom: 20px;
				&:last-child {
					padding-bottom: 0px;
				}
			}
		}
		.expanded-agenda-box {
			width: 100%;
			padding: 27px 20px 10px;
			ul {
				display: block;
			}
		}
	}
}

/**direct report filter css start here**/
.header-filter {
	&.goal-okr-filter {
		.btn-circle-white {
			position: relative;
			.more-filter-count {
				width: 18px;
				height: 18px;
				@include border-radius(100%);
				background-color: $color-primary1;
				color: $color-white;
				//z-index: 8 !important;
				font-size: $font-size-subtitle2;
				line-height: $line-height4;
				font-weight: $font-weight-bold;
				border: none;
				position: absolute;
				top: -11px;
				right: -7px;
			}
		}
		.filter-chip {
			ul {
				li {
					&.clear-all {
						padding: 0 8px;
						min-height: 30px;
					}
				}
			}
		}
	}
}
.page-subheader-notification {
	.page-subheader-right {
		.MuiButton-root {
			&.global-task-btn {
				margin-left: 10px;
			}
		}
	}
}
.cursor-pointer {
	cursor: pointer;
}
.okr-card-center {
	grid-template-columns: auto;
}
.team-no-record {
	text-align: center;
	margin: 30px 0;
}

body {
	/**default dialog box css start here**/
	.default-dialog {
		/** dialog box css start here**/
		&.delete-dialog-box {
			.MuiDialog-paper {
				@include custom-scrollbar;
				.MuiDialogContent-root {
					max-width: 100%;
					overflow-y: initial;
					.delete-dialog-inner {
						padding-bottom: 2.5em;
						p {
							margin: 0px !important;
							padding-bottom: 0;
							strong {
								font-weight: $font-weight-bold;
							}
							&.Mui-error {
								svg {
									margin-left: 0px !important;
								}
							}
						}
						.delete-confirm-text {
							padding-bottom: 2.5em;
							padding-right: 0.625em;
							.MuiTypography-body1 {
								span {
									cursor: pointer;
									text-transform: uppercase;
									color: $color-primary1;
								}
							}
						}
						.delete-confirm-text-field {
							.MuiTextField-root {
								width: 100%;
								margin-top: 0.75em;
								.MuiInputBase-root {
									padding-left: 10px;
									padding-right: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.popper-so-progress {
	&::before {
		left: 100%;
		margin-left: -38px;
	}
}
/**okr blank popup css start here**/
.okr-blank-wrap {
	.main-wrapper {
		min-height: 0px;
		padding-bottom: 0px;
		.progress-cards {
			display: none;
		}
	}
	.wrapper-container {
		&.blank-wrap-content {
			padding-bottom: 0px;
			.welcome-content.dashboard {
				margin-bottom: 0px;
			}
			.no-records-area {
				padding-top: 0px;
				padding-bottom: 30px;
				min-height: calc(100vh - 117px);
				.no-record-message {
					@include flex-direction(initial);
				}
			}
		}
	}
}
.okr-blank-popup {
	@include box-shadow(0px 10px 60px rgba($color-primary2, 0.2));
	@include border-radius(6px);
	max-width: 1000px;
	min-width: 1000px;
	min-height: 455px;
	padding: 50px;
	background-image: url(../images/blank-popup-screen.svg);
	background-color: linear-gradient(0deg, $color-white 9.97%, #cbe9ff 102.63%);
	background-repeat: no-repeat;
	background-position: 0 bottom;
	margin-top: -16px;
	.okr-blank-head {
		padding-bottom: 20px;
		max-width: 60%;
	}
	.okr-popup-link {
		@include flex;
		.create-align-card {
			@include align-items(center);
			@include flex-direction(column);
			max-width: 150px;
			min-width: 150px;
			flex: auto;
			text-align: center;
			background: $color-white;
			padding: 25px 20px;
			margin: 0 5px;
			@include flex;
			@include justify-content(space-between);
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			cursor: pointer;
			svg {
				margin-bottom: 16px;
			}
			.MuiTypography-h5 {
				min-height: 40px;
			}
			&:hover,
			&.active {
				border: 1.4px solid $color-primary1;
				@include default-shadow;
			}
		}
	}
}
/**global task page css start here**/
.global-task-page {
	padding-top: 30px;
	.welcome-content {
		margin-top: 95px;
		.no-task-image {
			margin-top: 45px;
			width: 323px;
			height: 284px;
		}
	}
}
/**global create task css start here**/
.create-task-block {
	background: $color-bg-form;
	border: 1.4px solid $color-border-tile;
	padding: 30px;
	@include border-radius(6px);
}
.textfield-wrap {
	.MuiInputBase-root {
		@include align-items(flex-start);
		.MuiInput-input {
			padding-right: 10px;
			overflow-y: overlay;
		}
		.MuiInputAdornment-root {
			margin-top: 0.69em;
		}
	}

	.action-btn-wrap {
		@include flex;
		@include align-items(flex-start);
		@include justify-content(space-between);
	}
	.add-text-field {
		.MuiInputAdornment-root {
			svg {
				width: 12px !important;
				height: 12px !important;
				path {
					fill: $color-primary1;
					stroke: $color-primary1;
					stroke-width: 1;
				}
			}
		}
	}
	&.textfield-edit-mode {
		.MuiInputBase-root {
			@include border-radius(6px 6px 0px 6px);
		}
	}
}

/**textfield action btn css start here**/
.textfield-action-btn {
	@include flex;
	@include align-items(center);
	.save-btn {
		width: 42px;
		height: 28px;
		@include border-radius(0px 0px 6px 6px);
		background: $color-primary1;
		margin-left: 5px;
		svg {
			width: 20px;
			height: 20px;
			path {
				fill: $color-white;
			}
		}
		&:hover {
			background: $color-primary1;
		}
		&.Mui-disabled {
			opacity: 0.7;
			svg {
				path {
					fill: $color-white;
				}
			}
		}
	}
	.cancel-btn,
	.cross-btn {
		width: 28px;
		height: 28px;
		svg {
			width: 14px;
			path {
				fill: $color-primary1 !important;
				stroke: $color-primary1 !important;
			}
		}
		&:hover {
			background: transparent;
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
}

/** quick links css start here**/
.quick-links-btn {
	@include flex;
	@include align-items(center);
	margin-top: 4px;
	min-height: 30px;
	.links-col {
		@include flex;
		@include align-items(center);
		margin-right: 15px;
		.alert-due-on {
			width: 14px;
			height: 14px;
			margin-left: 3px;
			@include flex;
			svg {
				width: 14px;
				height: 14px;
				margin: 0px;
				path {
					fill: $color-white;
				}
			}
		}
		button {
			&.disable-priority {
				cursor: default !important;
			}
			&.Mui-disabled {
				opacity: 1 !important;
				color: rgba($color-primary2, 0.5) !important;
				cursor: default;
				svg {
					opacity: 1 !important;
					path {
						fill: rgba($color-primary2, 0.5) !important;
					}
				}
				.alert-due-on {
					svg {
						opacity: 1 !important;
						path {
							fill: $color-white !important;
						}
					}
				}
				&.link-icon-btn {
					svg {
						path {
							//stroke: $color-secondary1 !important;
						}
					}
				}
			}
			&.error-btn {
				svg {
					path {
						fill: $color-text-info;
					}
				}
			}
		}
		.due-timeline-area {
			@include flex;
			.error-field {
				min-width: 174px;
				bottom: auto;
				top: 20px;
				left: 50%;
				margin-left: -86px;
				z-index: 9;
			}
			&.duedate-error {
				button {
					svg {
						path {
							fill: $color-text-info;
						}
					}
				}
				.error-field {
					min-width: 148px;
					line-height: $line-height3;
					margin-left: -74px;
				}
			}
		}
		&.created-on-links {
			.created-btn {
				color: rgba($color-primary2, 0.5);
				cursor: default;
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
					}
				}
			}
		}
		&.task-conversation-action {
			.conversation-btn-wrap {
				@include flex;
			}
			button {
				padding: 0;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&:hover {
					background: transparent;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
	.selected-item {
		position: relative;
		@include flex;
		@include align-items(center);
		.cancel-btn {
			width: 14px;
			height: 14px;
			border: 1.4px solid $color-bg-form;
			background: $color-primary1;
			@include border-radius(100%);
			position: absolute;
			right: -10px;
			top: -6px;
			padding: 0px;
			opacity: 0;
			svg {
				width: 6px;
				height: 6px;
				path {
					fill: $color-white;
					stroke: $color-white;
					stroke-width: 3;
				}
			}
		}
		&.active-popup {
			.cancel-btn {
				opacity: 1;
			}
		}
		&:hover {
			.cancel-btn {
				opacity: 1;
			}
		}
		&.selected-delegate {
			.cancel-btn {
				right: -10px;
				top: 0px;
			}
			.MuiAvatar-root {
				cursor: pointer;
			}
		}
		&.selected-priority {
			.cancel-btn {
				top: -8px;
				right: -10px;
			}
		}
		&.selected-due-on {
			.cancel-btn {
				top: -9px;
				right: -9px;
			}
			.due-date-history {
				position: relative;
			}
		}
	}
	.kr-linking-name,
	.text-linking {
		cursor: pointer;
	}
	.text-linking-wrap {
		@include flex;
		&.disabled {
			cursor: default;
		}
		.disable-chip {
			cursor: pointer !important;
		}
	}
	.kr-linking-name,
	.chip-tag {
		@include flex;
		@include align-items(center);
		padding: 1px 7px;
		color: $color-secondary1;
		background: $color-secondary2;
		font-size: $font-size-h6;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		@include border-radius(20px);
		svg {
			width: 10px;
			height: 10px;
			margin-right: 5px;
			path {
				fill: $color-secondary1;
				//stroke: $color-secondary1;
			}
		}
		h6 {
			max-width: calc(186px - 20px);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		&.disable-chip {
			color: rgba($color-primary2, 0.5);
			cursor: default;
			h6 {
				color: rgba($color-primary2, 0.5);
			}
			svg {
				path {
					fill: rgba($color-primary2, 0.5);
					//stroke: rgba($color-primary2, 0.5);
				}
			}
		}
	}
	.source-tag {
		svg {
			path {
				fill: rgba($color-primary2, 0.5);
			}
		}
		.MuiTypography-h6 {
			color: rgba($color-primary2, 0.5);
			//font-size: $font-size-subtitle2;
			//line-height: $line-height5;
			//font-weight: $font-weight-bold;
		}
		&.no-uppercase {
			.MuiTypography-h6 {
				text-transform: initial;
			}
		}
	}
	.task-1-on-1-list {
		@include flex;
		@include align-items(center);
		padding: 1px 7px;
		color: $color-secondary1;
		background: $color-secondary2;
		font-size: $font-size-h6;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		min-height: 18px;
		@include border-radius(20px);
		.requested-by {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 55px;
		}
		.meeting-title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 140px;
		}
		.requested-to {
			padding-right: 13px;
			position: relative;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 68px;
			&:after {
				content: '';
				position: absolute;
				right: 5px;
				top: 7px;
				width: 3px;
				height: 3px;
				background: rgba($color-secondary1, 0.5);
				@include border-radius(3px);
			}
		}
		.request-arrow {
			margin: 0 5px;
			width: 14px;
			height: 14px;
			@include border-radius(100%);
			background: $color-border-tile;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			svg {
				width: 10px;
				height: 10px;
				path {
					stroke: $color-primary2;
					stroke-width: 1px;
				}
			}
			&.arrow-left {
				@include transform(rotate(180deg));
			}
		}

		&.disable-chip {
			color: rgba($color-primary2, 0.5);
			.request-arrow {
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
						//stroke: rgba($color-primary2, 0.5);
					}
				}
			}
		}
	}
}

/**quick links popper css start here**/
.task-quick-link-popup {
	width: 100%;
	z-index: 9999;
	max-width: 272px;
	margin-left: -31px;
	margin-top: 10px;
	.MuiPaper-root {
		margin-top: 0.3em;
		@include border-radius(6px);
		background: $color-white;
		@include default-shadow;
		position: relative;
		padding: 20px;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 50px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
		h6 {
			text-transform: uppercase;
		}
		.priority-type {
			@include flex;
			@include align-items(center);
			margin-top: 6px;
			button {
				border: 1px solid rgba($color-input-field, 0.5);
				@include border-radius(20px);
				padding: 2px 10px;
				font-size: $font-size-subtitle2;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				text-transform: uppercase !important;
				min-height: 22px;
				@include flex;
				@include align-items(center);
				margin-right: 10px;
				.icon {
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
}

/**task delegate popper css start here**/
.task-delegate-popper {
	margin-top: 14px;
	margin-left: -22px;
	.rbt-menu {
		&.dropdown-menu#async-pagination {
			background-color: $color-white;
			@include box-shadow(2px 8px 10px rgba($color-primary2, 0.12));
			@include border-radius(0 0 6px 6px);
			margin-top: 21px;
			max-height: 252px !important;
			overflow-y: auto;
			position: relative !important;
			transform: none !important;
			.dropdown-item {
				cursor: pointer;
			}
		}
	}
	.MuiPaper-root {
		overflow: initial;
		position: relative;

		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 50px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
		.user-info {
			@include align-items(center);
			.user-img {
				.MuiAvatar-root {
					margin-top: 0px;
				}
			}
		}
		.search-no-record {
			padding: 0;
			object {
				margin-top: 20px;
			}
		}
	}
	&.task-delegate-popper-type1 {
		margin-top: 14px;
		margin-left: -31px;
	}
	&.task-delegate-popper-end {
		margin-left: 0px;
		.MuiPaper-root {
			&::after,
			&:before {
				left: auto;
				right: 50px;
			}
		}
	}
}

/**task-kr-link-popup css start here**/
.task-kr-link-popup {
	width: 100%;
	z-index: 999;
	max-width: 667px;
	margin-left: -31px;
	margin-top: 14px;
	.MuiPaper-root {
		margin-top: 0;
		@include border-radius(6px);
		background: $color-white;
		@include default-shadow;
		position: relative;
		padding: 20px 0 0;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 50px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
		.task-kr-link-popup-inner {
			.search-kr {
				.search-text-field {
					border: 0px;
					margin-top: 4px;
					padding: 0 20px;
					.MuiInputBase-root {
						border: 0px;
						padding-left: 0px;
						padding-right: 0px;
						box-shadow: none !important;
						border-radius: 0px !important;
						&.MuiInputBase-adornedStart {
							.MuiInputAdornment-positionStart {
								margin-right: 19px;
								svg {
									path {
										fill: $color-primary1;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.task-kr-link-popup-type1 {
		margin-left: -23px;
	}
	.search-no-record {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 5px;
		margin-top: 20px;
		text-align: center;
		border-top: 1px solid $color-hover;
		h2 {
			text-transform: capitalize;
		}
		object {
			margin-top: 1.5625em;
			width: 250px;
		}
	}
	&.task-link-text-popup {
		max-width: 500px;
		min-width: 500px;
		//z-index:99999;
		.MuiPaper-root {
			padding: 0;
			.task-link-text-popper {
				.ck.ck-editor__main > .ck-content {
					@include border-radius(0px 0px 0px 6px !important);
				}
			}
			.ck.ck-editor {
				box-shadow: none;
			}
			.ck.ck-icon.ck-icon_inherit-color :not([fill]) {
				fill: currentColor !important;
				stroke-width: 0;
			}
			.textfield-action-btn {
				@include align-items(flex-end);
				@include justify-content(flex-end);
				.cancel-btn {
					position: static;
				}
				.save-btn {
					svg {
						path {
							fill: $color-white !important;
							stroke: $color-white !important;
							stroke-width: 0;
						}
					}
					&.Mui-disabled {
						opacity: 0.7 !important;
						svg {
							path {
								fill: $color-white;
							}
						}
					}
				}
			}
			.task-popper-link {
				padding: 20px 0;
				max-height: 319px;
				overflow: auto;
				@include custom-scrollbar;
				.link-textfield {
					padding: 0 20px;
					.action-btn-wrap {
						@include flex;
						@include justify-content(flex-end);
					}
				}
				.MuiInputBase-root {
					@include border-radius(6px 6px 0px 6px);
				}
				.added-link-list {
					padding-top: 5px;
					.link-row {
						@include flex;
						@include justify-content(space-between);
						padding: 10px 20px;
						border-bottom: 1px solid $color-hover;
						.link-text {
							max-width: 80%;
							a {
								color: $color-primary1;
								word-wrap: break-word;
								max-width: 100%;
								font-size: $font-size-h5;
								line-height: $line-height3;
							}
						}
						.btn-wrap {
							opacity: 1;
							@include transition-default;
						}
						&:hover {
							background: $color-hover;
						}
						&:last-child {
							border-bottom: 0px;
						}
					}
				}
			}
		}
		&.kr-drawer-link-popup {
			max-width: 400px;
			min-width: 400px;
			margin-left: -95px;
			.MuiPaper-root {
				&:before,
				&:after {
					left: 112px;
				}
			}
		}
	}
}

/**task link kr list css start here**/
.task-link-to-kr-list {
	border-top: 1px solid $color-hover;
	margin-top: 23px;

	.my-kr-list {
		max-height: 235px;
		overflow-y: auto;
		@include custom-scrollbar;
	}
	.my-kr-title {
		padding: 15px 20px;
		h6 {
			text-transform: uppercase;
		}
	}
	.my-kr-list-box {
		padding: 10px 20px;
		border-bottom: 1px solid $color-hover;
		cursor: pointer;
		.my-kr-info {
			margin-top: 7px;
			@include flex;
			@include align-items(center);
			.teams-chips {
				margin: 0;
				.MuiChip-root {
					height: 20px;
					padding: 0;
					@include border-radius(12px);
					border: 1.4px solid $color-white;
					background: $color-bg-info;
					color: $color-tags;
					margin-right: 15px;
					.MuiChip-label {
						padding-left: 5px;
						padding-right: 5px;
						font-size: 10px;
						line-height: 20px;
					}
				}
			}
		}
		&:last-child {
			border-bottom: 0px;
		}
		&:hover {
			background-color: $color-hover;
		}
		&.active {
			background-color: $color-secondary2;
		}
	}
}

/**task calender popover css start here**/
.task-calender-control-popover {
	z-index: 9999;
	margin-left: -31px;
	margin-top: 14px;
	.MuiPaper-root {
		margin-top: 0;
		@include border-radius(6px);
		background: $color-white;
		@include default-shadow;
		position: relative;
		padding: 10px 0 0;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 50px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 10;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
	}
}
.disable-button-wrap {
	@include flex;
}

/**default chip css here**/
body {
	.MuiChip-root {
		&.default-chip-avatar,
		&.default-gray-chip {
			padding: 0px 7px 0 20px;
			border-radius: 100px;
			min-width: 60px;
			background: $color-bg-info;
			height: 20px;
			min-height: 20px;
			@include justify-content(flex-start);
			position: relative;
			.MuiChip-avatar,
			.MuiAvatar-root {
				margin: 0 7px 0 0;
				border: 1.4px solid $color-white;
				color: $color-tags;
				background: $color-bg-info;
				font-weight: $font-weight-bold;
				font-size: $font-size-subtitle2;
				line-height: $line-height3;
				width: 20px;
				height: 20px;
				position: absolute;
				left: 0;
				top: 0px;
				.MuiAvatar-img {
					@include border-radius(100%);
				}
			}
			.MuiChip-label {
				font-size: $font-size-h6;
				line-height: $line-height5;
				color: $color-tags;
				margin: 0 0 0 7px;
				display: block;
				text-transform: initial;
				padding: 0px;
				text-transform: initial;
			}
			&.default-chip {
				padding-left: 7px;
				.MuiChip-label {
					margin: 0;
				}
			}
			&.deleted-chip {
				background: $color-avatar-disable;
				.MuiAvatar-root {
					background: $color-avatar-disable;
					color: rgba($color-primary2, 0.5);
					img {
						filter: grayscale(100%);
						-webkit-filter: grayscale(100%);
						opacity: 0.7;
					}
				}
				.MuiChip-label {
					color: rgba($color-primary2, 0.5);
				}
			}
		}
		&.default-gray-chip {
			background: $color-bg-tag;
			padding-left: 7px;
			height: 16px;
			min-height: 16px;
			margin-right: 3px;
			.MuiChip-label {
				margin: 0;
				font-size: $font-size-subtitle2;
				line-height: $line-height5;
				color: $color-primary2;
				text-transform: uppercase;
			}
		}
		&.default-blue-chip {
			background: $color-secondary2;
			color: $color-secondary1;
			.MuiChip-label {
				color: $color-secondary1;
			}
		}
		&.default-red-chip {
			background: $color-bg-info;
			color: $color-tags;
			.MuiChip-label {
				color: $color-tags;
				text-transform: none;
			}
		}
		&.default-orange-chip {
			background: $color-lagging;
			color: $color-white;
			min-width: auto;
			.MuiChip-label {
				color: $color-white;
				text-transform: uppercase;
			}
		}
	}
}
