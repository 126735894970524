@import '../../../sass-utils/variables';
.so-setting-card-row {
	@include flex;
	@include justify-content(space-between);
	.so-setting-card-col {
		width: calc(50% - 20px);
	}
}
.so-setting-card-separator {
	margin: 30px 0;
	height: 1.4px;
	background: $color-hover;
}
.so-setting-card-actions {
	@include flex;
	@include justify-content(space-between);
}
.so-mv-setting-card {
	.so-mv-tile-head {
		padding-bottom: 25px;
		@include flex;
		@include align-items(center);
		svg {
			width: 20px;
			height: 20px;
			margin-right: 7px;
		}
	}
	.so-setting-card-actions {
		margin-top: 20px;
		@include flex;
		@include justify-content(space-between);
	}
}
.so-setting-card {
	.so-setting-head {
		@include flex;
		@include align-items(flex-start);
		@include justify-content(space-between);
		.add-perspective-btn {
		}
	}
	.so-setting-switch {
		padding: 15px 0 30px;
	}
	.so-setting-card-row {
		.so-setting-card-col {
			.MuiTypography-h5 {
				padding-bottom: 20px;
			}
			.creator-info {
				margin-bottom: 20px;
				@include flex;
				@include align-items(center);
				.add-btn2 {
					width: 20px;
					height: 20px;
					svg {
						width: 10px;
						height: 10px;
					}
				}
			}
			.setting-box-list {
				.setting-box {
					margin-right: 10px;
					width: 120px;
					max-width: 120px;
					min-height: 100px;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(6px);
					font-size: $font-size-h5;
					font-weight: $font-weight-regular;
					color: $color-primary2;
					background-color: $color-white;
					@include transition-default;
					&.Mui-disabled {
						color: rgba($color-primary2, 0.5);
					}
					.MuiButton-label {
						@include flex;
						@include flex-direction(column);
						svg {
							width: 20px;
							height: 20px;
							margin: 5px 0 10px;
						}
					}
					&:hover {
						background-color: $color-hover;
						border: 1.4px solid rgba($color-input-field, 0.5);
						box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
					}
					&.active {
						background-color: $color-secondary2;
						border: 1.4px solid $color-primary1;
						color: $color-primary1;
						box-shadow: none;
						svg {
							path {
								fill: $color-primary1;
							}
						}
					}
				}
			}
			.assign-users {
				margin-top: 15px;
				.assign-users-inner {
					.user-avatar-group {
						.user-avatar.user-avatar-placeholder {
							border: none;
							text-align: center;
							opacity: 1;
							background-color: transparent;
							svg {
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
					.add-btn2 {
						width: 20px;
						height: 20px;
						svg {
							width: 10px;
							height: 10px;
						}
					}
				}
			}
		}
	}
	.perspective-listing-area {
		padding-top: 20px;
	}
}
.okr-list-panel-head,
.perspective-listing-content {
	ul {
		li {
			&.perspective-col1 {
				width: 40%;
			}
			&.perspective-col2 {
				width: 25%;
			}
			&.perspective-col3 {
				width: 35%;
			}
		}
	}
}
.perspective-listing-content {
	@include flex;
	@include justify-content(center);
	@include flex-direction(column);
	min-height: 80px;
	position: relative;
	padding: 20px 0;
	margin-bottom: -1px;
	transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	ul {
		@include flex;
		@include align-items(center);
		flex: 1;
		padding: 0;
		li {
			padding: 0 20px;
			flex: auto;
			&.perspective-col1 {
				width: 40%;
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.perspective-text-field {
					.MuiInputBase-root {
						max-width: 360px;
					}
				}
				.perspective-listing-actions {
					@include flex;
					@include align-items(center);
					button {
						padding: 0;
						min-width: auto;
						width: 24px;
						height: 24px;
						@include border-radius(100%);
						margin-left: 0.5em;
						@include transition(0.25s);
						opacity: 0;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
					}
				}
				.edit-perspective-actions {
					position: absolute;
					left: 314px;
					top: 100%;
					z-index: 1;
					button {
						padding-top: 4px;
						padding-bottom: 4px;
						min-height: 26px;
						&:hover {
							background-color: transparent;
						}
						svg {
							width: 12px;
							height: 12px;
						}
						&.perspective-save-btn {
							padding-left: 12px;
							padding-right: 12px;
							@include border-radius(0 0 6px 6px);
							background-color: $color-primary1;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-white;
								}
							}
						}
						&.perspective-cancel-btn {
							svg {
								path {
									fill: $color-primary1;
									stroke: $color-primary1;
								}
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				&.perspective-edit-col {
					margin-bottom: 20px;
					.perspective-text-field {
						.MuiInputBase-root {
							@include border-radius(6px 6px 0px 6px);
						}
					}
				}
			}
			&.perspective-col2 {
				.perspective-cat-chip {
					@include flex;
					@include align-items(center);

					.MuiChip-root {
						padding: 0 7px;
						color: $color-primary2;
						line-height: 1;
						border: 1px solid $color-bg-tag;
						.MuiChip-label {
							line-height: 1.25;
						}
						&.draft-chip {
							color: $color-tags;
							border: 1px solid $color-tags;
							background-color: $color-okr-highlighter;
						}
					}
				}
			}
		}
	}
	&:hover {
		@include default-shadow;
		z-index: 1;
		li {
			&.perspective-col1 {
				.perspective-listing-actions {
					button {
						opacity: 1;
					}
				}
			}
		}
	}
	.so-setting-card-actions {
		padding: 0 20px;
	}
}
.align-setting-card {
	.so-setting-head {
		padding-bottom: 20px;
	}
	.grace-period-notes {
		padding-top: 5px;
	}
	.trigger-for-email {
		@include flex;
		.setting-blue-box {
			&.committed-okr-box {
				.committed-okr-des-box {
					margin-top: 20px;
					&.align-cycle-completion {
						.MuiFormControlLabel-root {
							margin-top: -7px;
							margin-left: -9px;
							margin-right: 0;
						}
						.MuiButtonBase-root {
						}
						.MuiTypography-body1 {
							font-size: $font-size-h6;
							font-weight: $font-weight-bold;
							color: $color-secondary1;
							line-height: $line-height5;
						}
					}
				}
			}
		}
	}
	&.email-and-other-setting-card {
		@include flex;
		@include justify-content(space-between);
		.setting-blue-box {
			min-height: 200px;
		}
		.trigger-for-email-area {
			width: calc(75% - 5px);
			.setting-blue-box {
				width: 33.33%;
				&.cycle-completion-box {
					.committed-okr-des-box {
						margin-top: 0;
						&.align-cycle-completion {
							margin-top: 20px;
						}
					}
				}
			}
		}
		.other-settings-area {
			width: calc(25% - 5px);
			.setting-blue-box {
				width: 100%;
			}
		}
	}
}
.engage-setting-card {
	.so-setting-head {
		padding-bottom: 20px;
	}
	.cadencs-notes-txt {
		padding-top: 5px;
	}
	.engage-setting-switch {
		padding: 0 0 0 11px;
		.MuiFormControlLabel-label {
			margin-left: 10px;
		}
	}
	.dropdown-default-wrapper {
		.dropdown-default__menu {
			width: 100%;
			.dropdown-default__menu-list {
				.dropdown-default__option {
					padding: 8px 20px;
				}
			}
		}
		&.checkin-visibility-dropdown {
			width: 100%;
		}
	}
	&.meeting-setting-card {
		margin-top: 30px;
	}
	.engage-send-email-panel {
		margin-bottom: 20px;
		.full-rectangular-card {
			padding: 0;
			border: none;
			.pd-card-header {
				margin-bottom: 20px;
			}
			.pd-setting-card-actions {
				margin-top: 20px;
				@include flex;
				@include justify-content(space-between);
			}
			.btn-link {
				margin-right: 0px;
				height: auto;
				font-size: $font-size-h5;
				line-height: $line-height4;
			}
		}
	}
}
.align-setting-card,
.engage-setting-card {
	.so-setting-card-row {
		.so-setting-card-col {
			width: 60%;
			.settings-fields {
				@include flex;
				@include justify-content(space-between);
				margin-bottom: 20px;
				.settings-fields-col {
					width: calc(50% - 10px);
					max-width: calc(50% - 10px);
					.MuiFormLabel-root {
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
						&.disable-label {
							color: rgba($color-primary2, 0.5);
							svg {
								path {
									fill: rgba($color-primary2, 0.5);
								}
							}
						}
					}
				}
				&.settings-fields-col3 {
					width: 100%;
					.settings-fields-col {
						width: calc(33.333% - 10px);
						max-width: calc(33.333% - 10px);
					}
				}
			}
			&.engage-setting-card-col-3 {
				width: 100%;
				.settings-fields {
					.settings-fields-col {
						width: calc(33.333% - 10px);
						max-width: calc(33.333% - 10px);
					}
				}
			}
		}
	}
}
.mv-his-listing-drawer {
	.mv-his-listing-drawer-inner {
		padding: 0 60px 30px;
		.mv-his-tile {
			padding-bottom: 25px;
			margin-bottom: 25px;
			@include flex;
			@include align-items(flex-start);
			border-bottom: 1.4px solid $color-expanded-tiles;
			.mv-his-tile-left {
				width: 45px;
			}
			.mv-his-tile-right {
				width: calc(100% - 45px);
				.mv-his-tile-right-top {
					padding-bottom: 12px;
					@include flex;
					.user-info {
						min-width: 265px;
						max-width: calc(100% - 140px);
						@include flex-direction(column);
					}
				}
				.mv-his-tile-right-btm {
					.mission-txt {
						padding-bottom: 15px;
						.MuiTypography-root {
							@include flex;
							strong {
								min-width: 60px;
								margin-right: 5px;
							}
						}
					}
					.vision-txt {
						.MuiTypography-root {
							@include flex;
							strong {
								min-width: 50px;
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}
}
