@import '../../../styles/sass-utils/variables';
@import './circular';
@import './filter';

.reports-wrapper {
	.page-content-wrapper {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.okr-reports-area {
	.col-full {
		width: 100%;
	}
	.col-half {
		width: 50%;
	}
	.col-one-third {
		width: 33.3333%;
	}
	.col-two-thirds {
		width: 66.6666%;
	}
	.reports-head {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		padding: 1em 0 0.75em;
		.reports-head-left {
			@include flex;
			@include align-items(center);
			.MuiButton-root {
				margin: 0.25em 0 0 0.5em;
				padding: 0;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $color-primary1;
					}
				}
				&:hover {
					background: transparent;
				}
			}
		}
		.reports-head-right {
			.report-filter-btn {
				padding: 0.375em 1em;
				font-size: $font-size-h5;
				background: $color-bg-filterbtn;
				border: 1.4px solid $color-border-tile;
				@include border-radius(43px);
				svg {
					margin-right: 0.5em;
				}
				&.active {
					background: $color-white;
					border-color: $color-white;
					@include default-shadow;
				}
			}
		}
	}
	.reports-card {
		padding: 1.5em;
		margin-bottom: 2.25em;
		@include flex;
		@include justify-content('space-between');
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		.MuiTypography-h4 {
			margin-bottom: 1.5625em;
			font-weight: $font-weight-bold;
			span {
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				margin-left: 5px;
				opacity: 0.5;
			}
		}
		.column-chart,
		.spline-chart {
			.chart-legend-default {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
			}
		}
	}
}
/**tabs overwrite css**/
.report-tabs {
	.MuiTab-root {
		//padding-top: 6px;
		padding-left: 0;
		padding-right: 0;
		.MuiTab-wrapper {
			svg {
				width: 14px;
				height: 14px;
			}
		}
		&:nth-child(2) {
			.MuiTab-wrapper {
				padding-left: 5px;
			}
		}
		&#report-tabpanel-1 {
			.MuiTab-wrapper {
				span {
					padding: 0 5px;
					display: block;
				}
				.arrowIcon {
					svg {
						width: 8px;
						height: 8px;
						margin: 3px 0 0 0px;
						path {
							stroke: $color-primary2;
							stroke-width: 2px;
						}
					}
				}
				svg {
					&:nth-child(2) {
						width: 8px;
						height: 8px;
						margin: 3px 0 0 0px;
						path {
							stroke: $color-primary2;
							stroke-width: 2px;
						}
					}
				}
			}
			&.menu-open {
				.MuiTab-wrapper {
					background: $color-white;
					@include border-radius(28px);
					@include box-shadow(0px 0px 10px -2px rgba($color-primary2, 0.12));
				}
			}
			&.Mui-selected {
				.MuiTab-wrapper {
					svg {
						&:last-child {
							path {
								stroke: $color-primary1;
								stroke-width: 2px;
							}
						}
					}
				}
			}
		}
	}
}
/**No Reports css**/
.no-records-area {
	@include flex;
	@include justify-content(center);
	padding: 100px 60px;
	min-height: calc(100vh - 165px);
	background-image: url(../../../images/blank-screen-bg2.svg), url(../../../images/blank-screen-bg.svg);
	background-position: left bottom, center bottom;
	background-repeat: no-repeat, no-repeat;
	.no-record-message {
		@include flex;
		@include flex-direction(column);
		@include align-items(center);
		.MuiTypography-h3 {
			padding-bottom: 0.25em;
			font-weight: $font-weight-black;
		}
	}
	object {
		margin-top: 35px;
	}
}
/**header tooltip css start here**/
.report-header-tooltip {
	margin-right: 0;
	margin-left: -58px;
	margin-top: 11px;
	padding: 15px 5px 15px 20px;
	&::before {
		right: auto;
		left: 56px;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -10px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		min-width: 352px;
		width: 352px;
		height: 132px;
		padding-right: 15px;
		@include custom-scrollbar-small-width;
	}
}

/**loading css start here**/
.org-loading {
	text-align: center;
	width: 100%;
	min-height: 250px;
	@include flex;
	@include align-items(center);
	@include justify-content(center);
}

.title-center {
	text-align: center;
}

#charts {
	overflow: initial !important;
}

/**downloadable reports css start here**/
.downloadable-report-content {
	padding-top: 2em;
	.MuiTypography-root.disabled-text {
		color: rgba($color-primary2, 0.5);
	}
	.download-report-menu {
		padding: 0px;
		@include flex;
		@include flex-direction(column);
		// @include flex-wrap(nowrap);
		@include align-items(flex-start);
		flex-wrap: nowrap;
		> li {
			width: 100%;
			padding: 20px;
			background-color: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			margin-bottom: 10px;
			transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			cursor: default;
			overflow: initial;
			.drawer-date-field {
				.MuiFormLabel-root {
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
						vertical-align: middle;
					}
				}
				.cycle-detail {
					margin-top: 3px;
				}
			}

			.spinner {
				order: 2;
			}
			&:hover {
				@include default-shadow;
			}
			.MuiTouchRipple-root {
				display: none;
			}
			.col {
				flex: auto;
				padding-right: 20px;
				&.col-1 {
					width: 52%;
					p {
						margin-bottom: 3px;
					}
				}
				&.col-2 {
					width: 30%;
				}
				&.col-3 {
					width: 18%;
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					padding-right: 0px;
				}
			}
		}
		.disabled-reports {
			margin-bottom: 20px;
			li {
				margin-bottom: 0;
			}
		}
		.cycle-dropdown {
			&.cycle-dropdown-dl-report {
				.cycle-dropdown-btn {
					margin-left: -16px;
				}
				.tenure-list {
					right: auto;
					left: -16px;
				}
			}
			&.cycle-select-box {
				.cycle-dropdown-btn {
					width: 240px;
					padding: 10px 1em;
					font-size: $font-size-h5;
					line-height: $line-height4;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(6px);
					@include justify-content(flex-start);
					.icon {
						order: 2;
						@include transition-default;
						svg {
							width: 10px;
							height: 10px;
							path {
								stroke: $color-primary2;
							}
						}
					}
					span.MuiButton-label {
						@include justify-content(space-between);
						@include align-items(center);
					}
					.MuiTouchRipple-root {
						display: none;
					}
					&:hover {
						background: none;
					}
					&.open {
						// border-color: transparent;

						// @include border-radius(4px);
						background: none;
						//z-index: 99;
						.icon {
							@include transform(rotate(180deg));
						}
					}
				}
				.tenure-list {
					right: auto;
					left: 0px;
				}
				// .tenure-list {
				// 	background: $color-white;
				// 	@include border-radius(6px);
				// 	border: 1.4px solid $color-primary1;
				// 	@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
				// 	z-index: 9;
				// 	position: absolute;
				// 	top: 0px;
				// 	left: 0px;
				// 	width: 100%;
				// 	padding-top: 38px;
				// 	max-height: initial;
				// 	overflow: initial;
				// 	.cycle-quick-links,
				// 	.cycle-sub-panel {
				// 		border-top: 1px solid rgba($color-input-field, 0.2);
				// 		max-height: 294px;
				// 		overflow: auto;
				// 		@include custom-scrollbar;
				// 	}
				// }
			}
		}
	}
}
