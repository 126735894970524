@import '../../../styles/sass-utils/variables';
.kr-task-drawer {
	&.kr-task-drawer-people {
		.task-list-area {
			.task-list-head {
				margin-top: 0px;
			}
		}
	}
	.welcome-content {
		margin-top: 50px;
		object {
			width: 230px;
			margin-top: 30px;
		}
	}
	.create-task-block {
		background: none;
		padding: 0 60px;
		border: 0px;
	}

	.task-list-area {
		.task-list-head {
			padding: 0 60px;
			margin-top: 35px;
			margin-bottom: 20px;
			.task-list-head-left {
				.is-complted-switch {
					//margin-left: 0px;
				}
			}
			.task-list-head-right {
				.list-filter-btn {
					@include border-radius(100%);
					width: 30px;
					height: 30px;
					padding: 0px;
					text-align: center;
					svg {
						width: 14px;
						height: 14px;
						margin: 0px;
					}
				}
				.user-search {
					.MuiInputBase-root {
						background: $color-white;
						border-color: rgba($color-input-field, 0.5);
						&.Mui-focused {
							border-color: $color-primary1;
						}
					}
				}
			}
		}
		.full-rectangular-card {
			background: $color-bg-form;
			border: 0px;
			border-radius: 0px;
			padding-left: 60px;
			padding-right: 60px;
		}
		.task-list-panel {
			&:after {
				background: $color-border-tile;
			}
			.task-list-panel-left {
				max-width: calc(100% - 130px);
				min-width: calc(100% - 130px);
				.task-name-box {
					.task-checkbox {
						&:hover {
							background: transparent !important;
						}
					}
				}
			}
			.task-list-panel-right {
				min-width: 130px;
			}
		}
		.task-list-panel-head {
			padding-left: 8px;
		}
	}
	.quick-links-btn {
		.links-col {
			margin-right: 12px;
		}
		.kr-linking-name {
			h6 {
				max-width: calc(80px - 20px);
			}
		}
	}
}
