/*Progress bar css*/
.okr-progress-status {
	margin: 0px 0 10px 0px;
	//max-width: 250px;
	ul {
		@include flex;
		flex-wrap: wrap;

		li {
			padding: 0px;
			width: 100%;
			flex: 100%;
			margin-bottom: 8px;
			@include justify-content(space-between);
			h5 {
				order: 2;
			}
			.count {
				min-width: 22px;
				height: 22px;
				padding: 0 2px;
				background: $color-bg-form;
				@include border-radius(22px);
				font-size: $font-size-h6;
				line-height: $line-height2;
				font-weight: $font-weight-bold;
				color: $color-primary1;
				text-align: center;
			}
		}
	}
	.chip-text {
		height: auto;
		min-height: 16px;
		margin-left: 0px;
		.MuiChip-label {
			font-size: 10px;
			line-height: 14px;
			padding: 2px 7px;
			text-transform: uppercase;
		}
		&.not-started {
			background: $color-not-started;
		}
		&.at-risk {
			background: $color-text-info;
			color: $color-white;
		}
		&.lagging {
			background: $color-lagging;
			color: $color-white;
		}
		&.on-track {
			background: $color-on-track;
			color: $color-white;
		}
	}
}
