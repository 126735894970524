@import '../../../styles/sass-utils/variables';

.pip-list-area {
	.pip-card-panel-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(calc(33% - 10px), 1fr));
		grid-gap: 10px;
		.pip-card-panel {
			padding: 20px;
			cursor: pointer;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.pip-card-header {
				@include flex;
				@include justify-content(space-between);
				@include align-items(center);
				.user-info {
					&.terminated-user-info {
						opacity: 0.5;
					}
				}
			}
			.pip-card-content {
				padding: 20px 0;
				margin-bottom: 20px;
				border-bottom: 1.4px solid $color-hover;
				.pip-feedback-list {
					.no-title {
						color: rgba($color-primary2, 0.5);
					}
					.feedback-type-chip {
						margin-bottom: 5px;
					}
				}
			}
			.pip-card-footer {
				.MuiTypography-body2 {
					padding-bottom: 8px;
				}
				.pip-card-footer-content {
					@include flex;
					@include justify-content(space-between);
					@include align-items(center);
					.pip-card-footer-left {
					}
					.pip-card-footer-right {
						flex: 1;
						@include flex;
						@include align-items(center);
						@include justify-content(flex-end);
						.MuiButtonBase-root {
							margin-left: 15px;
							padding: 0;
							color: $color-primary2;
							&:hover {
								background: transparent;
							}
							svg {
								margin-right: 5px;
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary2;
								}
							}
						}
						.comment-badge {
							.dot-bubble {
								.MuiBadge-badge {
									right: 0px;
								}
							}
						}
					}
				}
			}
			&.pip-card-panel-skeleton {
				cursor: default;
				border: 1.4px solid rgba($color-border-tile, 0.3);
				background: rgba($color-white, 0.3);
				.MuiSkeleton-root {
					background: $color-secondary2;
					&.MuiSkeleton-text {
						transform: scale(1);
						@include border-radius(10px);
					}
					&.MuiAvatarGroup-avatar {
						border-color: $color-secondary2;
					}
				}
				.pip-card-content {
					border-color: rgba($color-border-tile, 0.5);
				}
				&:hover {
					border: 1.4px solid rgba($color-border-tile, 0.3);
					@include box-shadow(none);
				}
			}
			&.highlighted-pip-item {
				background: $color-okr-highlighter;
				border: 1.4px solid $color-okr-highlighter;
			}
			&:hover {
				border: 1.4px solid $color-primary1;
				@include box-shadow(2px 0px 10px 0px rgba($color-primary2, 0.12));
			}
		}
	}
	.load-more-box {
		margin-top: 5px;
		.load-more-btn {
			padding: 0 20px;
			height: 32px;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			border: 1.4px solid $color-border-tile;
			background: $color-expanded-tiles;
			@include border-radius(20px);
			@include transition(0.25s);
			.MuiButton-label {
				.icon {
					margin-left: 5px;
					display: none;
					order: 1;
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			&:hover {
				padding-right: 15px;
				.MuiButton-label {
					.icon {
						display: flex;
					}
				}
			}
		}
	}
}
@media screen and (min-width: $desktop-breakpoint3) {
	.pip-list-area {
		.pip-card-panel-list {
			grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
		}
	}
}
