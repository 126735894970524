@import '../../sass-utils/variables';
body {
	.MuiChip-root {
		height: auto;
		padding: 0 7px;
		color: $color-primary2;
		line-height: 1;
		.MuiChip-label {
			padding: 0;
			font-size: $font-size-subtitle2;
			line-height: $line-height5;
			text-transform: uppercase;
		}
		&.draft-chip {
			color: $color-tags;
			border: 1px solid $color-tags;
			background-color: $color-okr-highlighter;
		}
		&.lagging-chip {
			background: $color-lagging;
			border: 1px solid $color-lagging;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.on-track-chip {
			background: $color-on-track;
			border: 1px solid $color-on-track;
			.MuiChip-label {
				color: $color-white;
			}
		}
	}
}
.user-goal-section-head {
	padding-top: 25px;
	padding-bottom: 25px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	.user-goal-section-head-left,
	.user-goal-section-head-right {
		@include flex;
		@include align-items(center);
		.goal-okr-filter {
			.filter-chip {
				ul {
					li {
						&:first-child {
							margin-left: 0;
						}
						&.clear-all {
							margin-right: 5px;
						}
					}
				}
			}
		}
		.list-filter-btn {
			padding: 6px 8px 6px 16px;
			font-size: $font-size-h5;
			background: $color-bg-form;
			border: 1.4px solid $color-border-tile;
			@include border-radius(43px);
			order: 2;
			margin-right: 15px;
			svg {
				width: 12px;
				height: 12px;
				margin-right: 10px;
			}
			&:hover,
			&.active {
				background: $color-white;
				@include default-shadow;
			}
			.filter-txt {
				@include flex;
				@include align-items(center);
				svg {
					margin-left: 10px;
				}
			}
		}
		.task-filter-wrap {
			padding-top: 10px;
		}
	}
	.user-goal-section-head-left {
		.MuiTypography-h2 {
			margin-right: 15px;
		}
		.goals-remaining-days {
			margin-left: 10px;
			@include flex;
			@include align-items(center);
			.MuiTypography-h6 {
				color: $color-tags-new;
			}
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
			}
		}
		.back-to-main {
			padding: 0;
			a {
				margin-right: 10px;
			}
		}
		.user-info {
			margin-right: 15px;
		}
	}
	.user-goals-setting-duration {
		position: relative;
		padding-right: 19px;
		margin-right: 15px;
		&:after {
			position: absolute;
			right: 0;
			content: '';
			width: 4px;
			height: 4px;
			@include border-radius(4px);
			background: rgba($color-secondary1, 0.5);
		}
	}
	.user-goals-setting-duration,
	.user-goal-form-name {
		@include flex;
		@include align-items(center);
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			path {
				fill: $color-secondary1;
			}
		}
	}
}
.user-goals-section-content {
	ul {
		@include flex;
		@include justify-content(space-between);
		@include align-items(initial);
		li {
			display: block;
			padding: 0;
			&.user-goals-col1 {
				width: 100%;
			}
		}
	}
	.user-goals-section-list-panel {
		margin-bottom: 10px;
		position: relative;
		.user-goals-section-list-panel-inner {
			cursor: pointer;
			position: relative;
			@include transition-default;
			background-color: $color-white;
			@include border-radius(6px);
			border: 1.4px solid $color-border-tile;
			.user-goals-section-name-panel {
				padding: 20px;
				position: relative;
				.user-goal-title {
					@include flex;
					.MuiTypography-h4 {
						line-height: $line-height4;
						// -webkit-line-clamp: 2;
						// -webkit-box-orient: vertical;
						// display: -webkit-box;
						// overflow: hidden;
						// text-overflow: ellipsis;
						// overflow-wrap: break-word;
					}
				}
			}
			.user-goals-list-panel {
				position: relative;
				.MuiCollapse-wrapperInner {
					padding: 0 10px 0 20px;
				}
				.user-goals-list-panel-inner {
					@include transition-default;
					min-height: 88px;
					padding: 20px;
					margin-bottom: 10px;
					background: $color-bg-teamstag;
					@include border-radius(6px);
					.user-goal-name {
						@include flex;
						.MuiTypography-h4 {
							line-height: $line-height4;
							// -webkit-line-clamp: 2;
							// -webkit-box-orient: vertical;
							// display: -webkit-box;
							// overflow: hidden;
							// text-overflow: ellipsis;
							// overflow-wrap: break-word;
						}
					}
					.user-goal-description {
						margin-top: 10px;
						&.helperText-bottom {
							.ck.ck-editor__main > .ck-content {
								border: 1.4px solid $color-text-info;
							}
						}
						.ck.ck-editor__main > .ck-content {
							min-height: 68px;
							max-height: 130px;
							ul {
								display: block;
								li {
									display: list-item;
								}
							}
						}
						.add-editor-box {
							.placeholder-text {
								color: $color-primary2;
								opacity: 0.5;
							}
						}
					}
					.user-goal-num-date-saved {
						@include flex;
						.MuiTypography-body1 {
							margin-right: 15px;
							width: 220px;
							margin-top: 10px;
							padding: 6px 10px;
							background: $color-white;
							border: 1.4px solid rgba($color-input-field, 0.5);
							@include border-radius(6px);
							&.Mui-focused {
								border: 1.4px solid $color-primary1;
							}
						}
					}
					.user-goal-number,
					.user-goal-number-date {
						.MuiInputBase-root {
							width: 220px;
							margin-top: 10px;
							padding: 4px 10px;
							background: $color-white;
							border: 1.4px solid rgba($color-input-field, 0.5);
							@include border-radius(6px);
							&.Mui-focused {
								border: 1.4px solid $color-primary1;
							}
						}
					}
					.user-goal-date,
					.user-goal-number-date {
						#date-range-inputfield {
							margin-top: 10px;
							width: 220px;
							padding: 4px 10px;
							font-size: $font-size-h5;
							line-height: $line-height2;
							border: 1.4px solid rgba($color-input-field, 0.5);
							@include border-radius(6px);
							font-family: $font-family-lato;
						}
					}
					.user-goal-number-date {
						@include flex;
						.MuiInputBase-root {
							margin-right: 15px;
						}
					}
					.user-goal-description-saved {
						margin-top: 10px;
						padding: 6px 10px;
						background: $color-white;
						border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						max-height: 112px;
						overflow-y: auto;
						@include custom-scrollbar;
						ul {
							display: block;
							list-style-type: disc;
							margin-left: 20px;
							li {
								display: list-item;
							}
						}
					}
					.user-goals-actions {
						margin-top: 15px;
						.user-goals-hvr-actions {
							button {
								opacity: 1;
							}
						}
					}
					.helperText-bottom {
						.error-field,
						.Mui-error {
							z-index: 1;
						}
					}
					&:hover {
						@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
					}
				}
			}
			&:hover,
			&.active-okr-list {
				@include box-shadow(0px 10px 15px rgba($color-primary2, 0.3));
			}
		}
		.user-goals-section-actions {
			margin-top: 10px;
			@include inline-flex;
			@include align-items(center);
			button {
				margin-left: 15px;
				@include transition-default;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				&.btn-blue-chip {
					margin: 0;
					color: $color-primary1;
					background: $color-border-tile;
					@include border-radius(30px);
					svg {
						@include transition-rotate;
						margin-right: 4px;
						width: 10px;
						height: 10px;
						path {
							fill: $color-primary1;
							stroke: $color-primary1;
						}
					}
					&:hover {
						@include box-shadow(6px 9px 26px $color-border-tile);
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
			.user-goals-sec-hvr-actions {
				button {
					padding: 0;
					opacity: 0;
				}
				&.hover-active {
					button {
						opacity: 1;
					}
				}
			}
		}
		.user-goals-actions {
			margin-top: 10px;
			@include inline-flex;
			@include align-items(center);
			button {
				margin-left: 15px;
				@include transition-default;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
				&.btn-oval-shape {
					margin-left: 0;
				}
			}
			.user-goals-hvr-actions {
				button {
					padding: 0;
				}
			}
		}
		&:hover {
			.user-goals-section-actions {
				.user-goals-sec-hvr-actions {
					button {
						opacity: 1;
					}
				}
			}
		}
	}

	.no-record-message {
		padding-top: 2em;
	}
	&.highlight-goals-row {
		.user-goals-list-panel-inner {
			background-color: $color-okr-highlighter !important;
			border: 2px solid $color-border-info;
		}
	}
}
.performance-goals-action {
	margin-top: 15px;
	@include flex;
	@include justify-content(space-between);
}

/* Category and Goals settings drawer CSS */
.main-drawer-panel {
	&.category-settings-drawer {
		.drawer-head {
			.drawer-title {
				.MuiTypography-h3 {
					@include flex;
					@include align-items(baseline);
					span {
						margin-left: 15px;
						@include flex;
						@include align-items(center);
						color: $color-secondary1;
						font-size: $font-size-h6;
						line-height: $line-height5;
						font-weight: $font-weight-bold;
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
							path {
								fill: $color-secondary1;
							}
						}
					}
				}
			}
		}
		.drawer-inner-content {
			.added-category-name {
				padding: 0 60px;
				svg {
					margin-right: 10px;
					width: 16px;
					height: 16px;
				}
			}
			.drawer-tabs {
				.MuiTab-root {
					color: $color-primary2;
					&.Mui-selected {
						color: $color-primary1;
					}
				}
			}
			.drawer-tabs-panel {
				padding: 0 !important;
				.bulk-user-content {
					padding-top: 30px;
					padding-bottom: 30px;
					// background: $color-bg-form;
					.upload-documents {
						max-width: 100%;
						.upload-file {
							margin: 0 0 10px;
							background-image: url('../../../images/mask-group-upload.svg'),
								url('../../../images/base-rectangle-upload-img.svg');
							background-repeat: no-repeat;
							.upload-file-inner {
								display: block;
								img {
									max-width: 140px;
								}
								.upload-right {
									padding: 0;
									h5 {
										font-weight: $font-weight-bold;
									}
								}
							}
						}

						.upload-attachments-list {
							margin-top: 30px;
							width: 100%;
							ul {
								li {
									margin-bottom: 1.4px;
									padding: 10px;
									background: rgba($color-secondary2, 0.5);
									.count {
										@include flex;
										@include align-items(center);
										@include justify-content(center);
										width: 18px;
										min-width: 18px;
										height: 18px;
										margin-right: 10px;
										text-align: center;
										font-size: $font-size-subtitle2;
										line-height: $line-height4;
										font-weight: $font-weight-bold;
										color: $color-secondary1;
										border: 1px solid $color-secondary1;
										@include border-radius(100%);
									}
									.file-name {
										width: calc(100% - 30px);
										@include flex;
										@include align-items(center);
										@include justify-content(space-between);
										> .MuiTypography-body1 {
											color: $color-primary1;
											//cursor: pointer;
											width: 50%;
											max-width: 50%;
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: nowrap;
											padding-right: 15px;
										}
										.uploded-by {
											width: calc(50% - 65px);
											.MuiTypography-body1 {
												max-width: 100%;
											}
											.MuiTypography-body2 {
												font-size: $font-size-subtitle2;
												color: $color-secondary1;
											}
										}
									}

									.attachment-action {
										.btn {
											padding: 0;
											margin-left: 10px;
											svg {
												width: 14px;
												height: 14px;
												path {
													fill: $color-primary1;
												}
											}
											&:hover {
												background: transparent;
											}
											.MuiTouchRipple-root {
												display: none;
											}

											&.Mui-disabled {
												cursor: default;
												svg {
													path {
														fill: rgba($color-primary2, 0.5);
													}
												}
											}
										}
									}
								}
							}
							.deleted-attachments-list {
								margin: 30px 0;
								.MuiTypography-h6 {
									margin-bottom: 10px;
								}
								ul {
									li {
										.file-name {
											.MuiTypography-body1 {
												width: auto;
												max-width: auto;
												flex: 1;
											}
										}
									}
								}
							}
						}
					}
				}
				.cfr-tab-inner-head {
					margin-top: 40px;
				}
				.tab-panel-inner {
					padding: 28px 60px;
				}
			}
		}
		.information-message {
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			padding: 3px 3.75em;
			background: $color-bg-info;
			@include animation(slideUp 0.8s);
			.MuiTypography-h6 {
				@include inline-flex;
				@include align-items(center);
				color: $color-text-info;
				text-transform: initial;
				line-height: 14px;
				svg {
					margin-right: 0.25em;
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}
}
.performance-goals-welcome {
	object {
		margin-top: 30px;
		margin-bottom: 0;
	}
}
.teams-goals-filter-popup {
	.task-filter-wrap {
		padding-top: 10px;
	}
}
.response-log-area {
	min-height: 100%;
	padding: 30px 60px;
	background: $color-bg-form;
	ul {
		li {
			padding: 30px 0;
			@include align-items(flex-start);
			@include flex-direction(column);
			border-bottom: 1.4px solid $color-border-tile;
			.response-details {
				padding-left: 40px;
				.response-type-box {
					margin-top: 5px;
					@include flex;
					.response-type-box-col {
						min-width: 200px;
						max-width: 250px;
						@include flex;
					}
					.response-type-content {
						padding-left: 5px;
						ul {
							display: block;
							list-style-type: disc;
							margin-left: 20px;
							li {
								display: list-item;
							}
						}
					}
				}
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.welcome-content {
		padding-top: 60px;
		object {
			margin-top: 20px;
		}
	}
}
