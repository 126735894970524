@import '../../../styles/sass-utils/variables';
.admin-body-class {
	.okr-list-panel-head {
		ul {
			li {
				&.user-col5 {
					.team-filter-btn {
						padding: 0.25em;
						padding-top: 0;
						.MuiTouchRipple-root {
							display: none;
						}
						&:hover {
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	.user-listing-content {
		min-height: 92px;
		position: relative;
		padding: 1em 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		.user-details {
			@include flex;
			overflow: hidden;
			.user-details-content {
				overflow: hidden;
				p {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			span {
				font-size: 0.75em;
				font-weight: $font-weight-thin;
			}
		}
		ul {
			@include flex;
			padding: 0;
			li {
				padding: 0 0.75em 0 1.25em;
				flex: auto;

				&.user-col3 {
					@include flex;
				}
				.MuiAvatar-root {
					//margin-right: 0.5em;
					// width: 2.14em;
					// height: 2.14em;
					//margin-top: 0.3em;
				}
				&.user-col1 {
					@include flex;
					@include justify-content(space-between);

					button {
						padding: 0;
						min-width: auto;
						width: 24px;
						height: 24px;
						@include border-radius(100%);
						margin-left: 0.5em;
						@include transition(0.25s);
						svg {
							width: 14px;
							height: 14px;
						}
						&.unlock-purple-btn {
							svg {
								path {
									fill: $color-keyword-highlighter;
								}
							}
						}
						&.user-edit-icon {
							opacity: 0;
							svg {
								path {
									fill: $color-primary1;
								}
							}
						}
						&.user-menu-btn {
							opacity: 0;
							margin-left: 0;
							svg {
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				&.user-col2 {
					.MuiChip-root {
						margin-right: 3px;
						height: auto;
						padding: 0 7px;
						.MuiChip-label {
							font-size: $font-size-subtitle2 !important;
							font-weight: $font-weight-bold;
							color: rgba($color-primary2, 0.7);
							line-height: $line-height5;
							text-transform: uppercase;
							background: $color-bg-tag;
							border: 1px solid $color-bg-tag;
						}
					}
				}
				&.user-col5 {
					.user-team-chip {
						position: relative;
						//max-width: 12em;
						padding: 0 8px 0 38px;
						@include border-radius(12px);
						font-size: $font-size-h6;
						min-height: 22px;
						.MuiAvatar-root {
							position: absolute;
							left: 0;
							top: -1.4px;
							margin-top: 0;
							margin-right: 0.5em;
							width: 34px;
							height: 24.8px;
							margin-left: 0;
							@include border-radius(12px);
							border: 1.4px solid $color-white;
							font-size: $font-size-h6;
							img {
								@include border-radius(12px);
							}
						}
						.MuiChip-label {
							font-size: $font-size-h6;
							line-height: $line-height2;
						}
					}
					.user-team-col {
						margin: -10px 0 0 -5px;
						position: relative;
						max-width: 354px;
						@include flex;
						@include flex-direction(column);
						.team-row {
							display: inline-flex;
							@include align-items(center);
							flex-wrap: wrap;
							//width: 100%;
						}
						.user-team-item {
							@include flex;
							@include align-items(center);
							margin: 10px 0 0 5px;
							.leader-icon {
								width: 20px;
								height: 20px;
								padding: 0px;
								background: $color-secondary2;
								position: relative;
								margin-right: -3px;
								z-index: 9;
								cursor: default;
								svg {
									width: 12px;
									height: 12px;
								}
							}
						}
						&.user-team-multi-col {
							.user-team-chip {
								//max-width: 7.5em;
							}
						}
					}
				}
			}
			&:hover {
				li {
					&.user-col1 {
						button {
							&.user-edit-icon,
							&.user-menu-btn {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
		}
		&.virtualAlignmentList {
			border-bottom: 1px solid rgba($color-input-field, 0.5);
			&:hover {
				@include box-shadow(none);
				transform: none;
			}
			&:last-child {
				border-bottom: 0 none;
			}
		}
	}
	.okr-list-panel-head,
	.user-listing-content {
		ul {
			li {
				&.user-col1 {
					@include flex;
					width: 28%;
					padding-left: 1.5em;
					.user-col1-left {
						min-width: calc(100% - 90px);
						max-width: calc(100% - 90px);
						.user-info {
							@include flex;
							overflow: hidden;
							.user-info-details {
								overflow: hidden;
								p {
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
							span {
								font-size: 0.75em;
								font-weight: $font-weight-thin;
							}
						}
					}
					.MuiFormControlLabel-root {
						margin-right: 0.5em;
					}
				}
				&.user-col2 {
					width: 11%;
				}
				&.user-col3 {
					width: 10%;
				}
				&.user-col4 {
					width: 17%;
				}
				&.user-col5 {
					width: 21%;
				}
				&.user-col6 {
					width: 12%;
				}
			}
		}
	}

	/* User listing license details CSS*/
	.user-list-details {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.licenses-details {
			@include flex;
			padding-top: 16px;
			.MuiTypography-body1 {
				padding-right: 1em;
				margin-right: 1em;
				border-right: 1px solid $color-primary2;
				line-height: 1;
				strong {
					font-weight: $font-weight-bold;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
/*User Teams Search Filter*/
.user-search-filter-popover {
	width: 360px;
	margin-top: 0.5em;
	margin-left: -1em;
	z-index: 99;
	position: absolute;
	left: -3em;
	top: 2em;
	.MuiPaper-elevation1 {
		@include default-shadow;
		@include border-radius(6px);
		&:before {
			width: 0.75em;
			height: 0.75em;
			content: '';
			position: absolute;
			left: 6.8em;
			top: -0.3em;
			background: $color-white;
			transform: rotate(45deg);
		}
		.team-search-bar {
			padding: 5px 0;
			position: relative;
			border-bottom: 1.4px solid rgba($color-primary2, 0.12);
			.MuiInputAdornment-root {
				svg {
					max-width: 14px;
					max-height: 14px;
				}
			}

			.team-search-field {
				width: 100%;
				.MuiInputBase-root {
					border: none;
					box-shadow: none;
					padding-left: 1em;
					&:before,
					&:after {
						display: none;
					}
					.MuiInputBase-input {
						padding: 0;
						font-weight: $font-weight-regular;
						font-size: $font-size-h5;
						line-height: 2;
						border: none;
						background-color: transparent;
						color: $color-primary2;
						box-shadow: none;
					}
					&.Mui-focused {
						svg {
							path {
								fill: $color-primary1;
								//stroke: $color-primary1;
							}
						}
					}
				}
			}
		}
		.team-list-actions {
			padding: 0.5em 1em;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			.MuiCheckbox-root {
				margin-left: -0.5em;
			}
			.search-btn-dummy {
				padding: 0 0.75em;
				font-size: $font-size-subtitle2;
				line-height: $line-height3;
				font-weight: $font-weight-bold;
				color: $color-white;
				background-color: $color-primary1;
				@include border-radius(6px);
				text-transform: uppercase !important;
			}
			.cancle-btn {
				padding: 0;
				color: $color-primary1;
				font-size: $font-size-subtitle2;
				line-height: $line-height3;
				font-weight: $font-weight-bold;
				text-transform: uppercase !important;
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					text-decoration: underline;
					background: transparent;
				}
			}
		}
		.user-team-lists {
			margin-bottom: 0.5em;
			height: 260px;
			.user-team-lists-inner {
				max-height: 252px;
				overflow-y: auto;
				margin-right: 0.25em;
				@include custom-scrollbar;
				.MuiListItem-root {
					padding: 0.425em 1em;
				}
				.MuiListItemIcon-root {
					margin-left: -0.5em;
				}
				.admin-search-no-record {
					object {
						width: 205px !important;
					}
				}
			}
		}
	}
	.sorting-options {
		padding-top: 0.5em;
		border-bottom: 1.4px solid rgba($color-primary2, 0.12);
		.MuiListItem-root {
			@include flex;
			@include align-items(center);
			cursor: pointer;
			.MuiTypography-body1 {
				font-size: $font-size-h5;
			}
			.MuiListItemIcon-root {
				svg {
					width: 16px;
					height: 16px;
					margin-right: 1em;
				}
			}
		}
	}
}
/*manage permission accordian*/
.user-permissions-panel {
	padding: 0 3.75em;
}
.user-permissions-list {
	.user-permissions-head {
		@include flex;
		@include justify-content(space-between);
		padding: 1.875em 0 0;
		border-top: 1.4px solid rgba($color-primary2, 0.1);
		.MuiLink-root {
			@include flex;
			@include align-items(center);
			font-size: $font-size-h5;
			color: $color-primary1;
			svg {
				margin-right: 0.25em;
				width: 14px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.permission-collapsible {
		padding: 1.5em 0;
		.permission-collapsible-body {
			.permission-collapsible-head {
				padding: 1.125em 0 1.125em 1.5em;
				position: relative;
				@include flex;
				@include justify-content(space-between);
				@include align-items(flex-start);
				.rotating-arrow {
					cursor: pointer;
					position: absolute;
					width: 1.375em;
					height: 100%;
					left: 0;
					top: 0;
					background: $color-bg-teamstag;
					opacity: 0;
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					@include transition(0.5s);
					svg {
						@include transition(0.5s);
					}
				}
				.reset-btn {
					opacity: 0;
					svg {
						width: 14px;
						height: 14px;
						path {
							fill: $color-primary1;
						}
					}
				}
				&:hover {
					background: $color-hover;
					.rotating-arrow {
						opacity: 1;
					}
					.reset-btn {
						opacity: 1;
					}
				}
				.permission-collapsible-head-content {
					@include flex;
					.MuiTypography-body1 {
						sup {
							line-height: 8px;
							display: inline-block;
							vertical-align: top;
						}
					}
				}
			}
			.permission-collapsible-content {
				padding: 0 3.5em;
				.MuiList-root {
					grid-template-columns: repeat(2, 1fr);
					grid-column-gap: 0.625em;
					display: grid;
					.MuiListItem-root {
						padding: 0.75em 0.625em;
						background: $color-bg-form;
						@include border-radius(6px);
						margin-bottom: 0.625em;
						.MuiIconButton-root {
							padding: 0;
							margin-right: 0.375em;
						}
						.MuiTypography-body1 {
							font-size: 0.875em;
						}
					}
				}
			}
			&.expand-row {
				.permission-collapsible-head {
					background: $color-hover;
				}
				.rotating-arrow {
					opacity: 1;
					svg {
						@include transform(rotate(90deg));
					}
				}
			}
		}
	}
}
.okr-default-dropdown {
	&.user-number-dropdown {
		.MuiMenu-list {
			.MuiListItem-root {
				padding: 0 0.5em;
				@include justify-content(center);
				height: 2.25em;
			}
		}
	}
}
/**user listing**/
.users-select-fields {
	.MuiFormControl-root {
		width: 100%;
	}
	.action-btn {
		@include flex;
		@include justify-content(flex-end);
		padding-top: 1.25em;
		//padding-bottom: 1.25em;
		.action-btn-count {
			flex: 1;
			margin-top: -12px;
			color: $color-secondary1;
		}
	}
	.css-1s2u09g-control,
	.css-1pahdxg-control {
		min-height: 40px;
		max-height: 62px;
		overflow-y: auto;
		@include custom-scrollbar;
		@include border-radius(6px);
		// max-height: 6em;
		.css-319lph-ValueContainer {
			padding: 0.1em 1.25em;
			font-size: $font-size-h5;
			line-height: $line-height4;
		}
		.primary-text {
			//font-weight: $font-weight-bold;
			line-height: $line-height4;
		}
		.secondary-text {
			line-height: $line-height4;
			display: none;
		}
	}
	.css-1pahdxg-control {
		border: 1.4px solid $color-primary1;
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
	}
	.css-1hb7zxy-IndicatorsContainer {
		display: none;
	}
	.css-26l3qy-menu {
		@include default-shadow;
		> div {
			@include custom-scrollbar;

			div[class*='option'] {
				background: transparent;
				padding: 0.6875em 1.25em;
				cursor: pointer;
				.primary-text {
					font-size: $font-size-h4;
					font-weight: $font-weight-bold;
					line-height: $line-height2;
					padding: 0;
				}
				.secondary-text {
					color: $color-secondary1;
					font-size: $font-size-subtitle2;
					line-height: $line-height3;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
					padding: 0;
				}
				&:hover {
					background: $color-hover;
				}
			}
		}
	}
	.menu-list{
		.selected-chip{
			@include flex;
			.primary-text{
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: $font-size-base;
				line-height: $line-height2;
				color: $color-primary2;
				font-weight: $font-weight-regular;
			}
			.primary-text {
				width: 40%;
				max-width: 40%;
				padding-right: 20px;	
			}
			.secondary-text {
				width: 60%;
				max-width: 60%;
			}
		}
	}
	
}
.users-listing {
	//margin-top: 1.5625em;
	margin-bottom: 1.5625em;
	.userCount {
		padding: 1.25em 0.75em 0;
	}
	.okr-list-panel-head {
		padding: 1em 0.75em 0.25em;
		.MuiList-root {
			.MuiListItem-gutters {
				padding: 0 0 0 1.25em;
				position: relative;
				&.user-col1 {
					width: 50%;
					padding-left: 0;
				}
				&.user-col2 {
					width: 50%;
				}
			}
		}
		ul {
			li {
				&::before {
					top: auto;
					bottom: auto;
				}
			}
		}
	}
	.users-list-content {
		.users-list-row {
			padding: 0.6875em 0.75em;
			cursor: pointer;
			@include transition(0.3s);

			.user-info {
				.MuiTypography-h4 {
					word-break: break-all;
				}
			}
			.MuiList-root {
				@include flex;
				@include align-items(flex-start);
				@include justify-content(space-between);
				.MuiListItem-gutters {
					padding: 0px 0 0 1.25em;
					position: relative;
					@include align-items(flex-start);
					&.user-col1 {
						width: 50%;
						padding-left: 0;
					}
					&.user-col2 {
						width: 50%;

						.MuiTypography-h4 {
							padding-right: 1px;
							white-space: nowrap;
						}
					}

					.btn-cross {
						position: absolute;
						right: -0.625em;
						top: 0px;
						opacity: 0;
						pointer-events: none;
						@include transition(0.5s);
						svg {
							path {
								stroke: $color-primary1;
							}
						}
					}
				}
			}
			&:hover {
				background: $color-hover;
				.MuiList-root {
					.MuiListItem-gutters {
						.btn-cross {
							opacity: 1;
							pointer-events: initial;
						}
					}
				}
			}
		}
	}

	/**user listing 3 column**/
	&.users-listing-three-col {
		.okr-list-panel-head {
			.MuiList-root {
				.MuiListItem-gutters {
					&.user-col1 {
						width: 38%;
						min-width: 38%;
						padding-left: 0;
						&::before {
							display: none;
						}
					}
					&.user-col2 {
						width: 42%;
						min-width: 42%;
					}
					&.user-col3 {
						width: 20%;
					}
					&.user-col4 {
						width: 10px;
						min-width: 10px;
						&::before {
							display: none;
						}
					}
				}
			}
		}
		.users-list-content {
			.users-list-row {
				.MuiList-root {
					.MuiListItem-gutters {
						&.user-col1 {
							width: 38%;
							min-width: 38%;
						}
						&.user-col2 {
							width: 42%;
							min-width: 42%;
						}
						&.user-col3 {
							width: 20%;
						}
						&.user-col4 {
							width: 10px;
							min-width: 10px;
						}
					}
				}
				.chip-group {
					.MuiChip-label {
						max-width: 90px;
					}
				}
			}
		}
	}
}
/**user no record**/
.user-no-record {
	text-align: center;
	padding: 1.875em 0 1.875em;
	.MuiTypography-h2 {
		text-transform: capitalize;
	}
	.MuiTypography-h4 {
		margin-bottom: 10px;
		text-transform: capitalize;
	}
	object {
		margin-top: 1.875em;
	}
}

/** Disable for status*/

.disableStatus {
	cursor: not-allowed;
}

.reporting-to-box {
	width: 100%;
	.subtitle3 {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
