@import '../../../sass-utils/variables';

.recognize-listing-content {
	min-height: 80px;
	position: relative;
	padding: 10px 0;
	margin-bottom: -1px;
	transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	ul {
		@include flex;
		@include align-items(center);
		padding: 0;
		li {
			padding: 0 12px 0 20px;
			flex: auto;
			&.recognize-col1 {
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.recognize-badge-details {
					@include flex;
					@include align-items(center);
				}
				.badge-icon {
					margin-right: 70px;
					img {
						width: 50px;
						height: 50px;
						@include border-radius(100%);
					}
				}
				.recognize-review-name {
					width: 75%;
				}
				.recognize-badge-actions {
					@include flex;
					@include align-items(center);
					button {
						padding: 0;
						min-width: auto;
						width: 24px;
						height: 24px;
						@include border-radius(100%);
						margin-left: 0.5em;
						@include transition(0.25s);
						opacity: 0;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
					}
				}
			}
			&.recognize-col2 {
				.recognize-review-status {
					@include flex;
					@include align-items(center);
					.status {
						width: 14px;
						height: 14px;
						min-width: 14px;
						min-height: 14px;
						border-radius: 100px;
						border: 1.4px solid $color-primary1;
						background: $color-white;
						margin-right: 5px;
						&.published {
							background: $color-completed;
							border-color: $color-completed;
						}
						&.no-status {
							background: transparent;
							border-color: transparent;
						}
					}
					.MuiChip-root {
						padding: 0 7px;
						color: $color-primary2;
						line-height: 1;
						border: 1px solid $color-bg-tag;
						&.draft-chip {
							color: $color-tags;
							border: 1px solid $color-tags;
							background-color: $color-okr-highlighter;
						}
					}
				}
			}
			&.recognize-col3 {
				.MuiTypography-body1 {
					color: $color-secondary1;
					font-size: $font-size-h5;
				}
			}
		}
	}
	&:hover {
		@include default-shadow;
		z-index: 1;
		li {
			&.recognize-col1 {
				.recognize-badge-actions {
					button {
						opacity: 1;
					}
				}
			}
		}
	}
}
.okr-list-panel-head,
.recognize-listing-content {
	ul {
		li {
			&.recognize-col1 {
				width: 28%;
			}
			&.recognize-col2 {
				width: 14%;
			}
			&.recognize-col3 {
				width: 18%;
			}
			&.recognize-col4 {
				width: 10%;
			}
			&.recognize-col5 {
				width: 15%;
			}
			&.recognize-col6 {
				width: 15%;
			}
		}
	}
}
.okr-list-panel-head {
	ul {
		li {
			&.recognize-col1 {
				padding-left: 140px;
			}
		}
	}
}
.okr-tab-button {
	&.settings-grey-tab {
		.MuiTab-root {
			min-width: auto;
			margin-right: 20px;
			padding: 4px 10px;
			color: $color-secondary1;
			background: $color-secondary2;
			border: 1.4px solid $color-secondary1 !important;
			@include border-radius(3px);
			&.Mui-selected {
				background: $color-secondary1;
				color: $color-white;
				@include box-shadow(1px 6px 10px rgba($color-primary2, 0.16));
			}
		}
	}
}

/**Recognize Drawer css start here**/
.drawer-add-badge {
	.recognize-badge-upload {
		.bulk-user-content {
			padding: 0px;
			.upload-file {
				margin-top: 5px;
				margin-bottom: 5px;
			}
			.recognize-badge-box {
				width: 170px;
				height: 170px;
				@include border-radius(100%);
				@include flex;
				@include align-items(center);
				@include flex-direction(column);
				overflow: hidden;
				.MuiSkeleton-text {
					//margin-left: 40px;
				}
				img,
				.upload-canvas {
					padding: 0px;
					@include border-radius(100%);
					cursor: pointer !important;
					pointer-events: none;
					// width: 100%;
					// height: auto;
					// max-width: 150px;
				}
			}
		}
	}
	.recognize-uploaded-image {
		@include justify-content(center);
	}
	.badge-name-note {
		margin-top: 5px;
	}
}

.recognize-send-email-panel {
	margin-bottom: 20px;
	.full-rectangular-card {
		.pd-card-header {
			margin-bottom: 20px;
		}
		.pd-setting-card-actions {
			margin-top: 20px;
			@include flex;
			@include justify-content(space-between);
		}
		.btn-link {
			margin-right: 0px;
			height: auto;
			font-size: $font-size-h5;
			line-height: $line-height4;
		}
	}
}
