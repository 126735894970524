@import '../../sass-utils/variables';

/**drawer outer css start here**/
.main-drawer-panel.main-drawer-titlepanel.ok-cfr-panel {
	.drawer-area {
		.drawer-content {
			.drawer-inner-content {
				.drawer-content-panel {
					min-height: 100vh;
					max-height: 100vh;
					height: 100vh;
					padding: 0;
					@include flex;
					@include flex-direction(column);
				}
				.MuiLinearProgress-colorPrimary {
					position: absolute;
					width: 100%;
					left: 0;
					top: 0;
					z-index: 9;
				}
			}
		}
		.drawer-footer-actions {
			display: none;
		}
	}
}
/**cfr head css start here**/
.cfr-head {
	padding: 18px 60px 0;
	position: relative;
	margin-bottom: 40px;
	&::after {
		content: '';
		position: absolute;
		left: 0px;
		bottom: 0px;
		height: 1.4px;
		background: rgba($color-primary2, 0.2);
		opacity: 0.5;
		width: 100%;
	}
	.okr-drawer-tabs {
		.MuiTabs-flexContainer {
			.drawer-conversation-tab {
				margin-right: 2.5em;
			}
		}
	}
	.okr-name {
		border: 0px;
		padding-bottom: 0;
		margin-bottom: 12px;
	}
	.title-name {
		margin-top: 20px;
		@include flex;
		@include align-items(flex-start);
		svg {
			width: 16px;
			height: 16px;
			margin-right: 10px;
			margin-top: 2px;
		}
	}
}

.overlayTask {
	z-index: 9;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.cfr-tab-inner-head {
	padding: 0 60px 1.125em;
	@include box-shadow(0px 2px 5px -2px rgba($color-primary2, 0.1));

	.count-head {
		p {
			color: $color-primary2;
		}
	}
}
.add-cfr-text {
	@include flex;
	@include align-items(flex-start);
	margin-bottom: 1.25em;
	position: relative;
	width: 100%;
	&.editor-visible {
		margin-bottom: 2.5em;
		.ck.ck-editor__main > .ck-content {
			@include border-radius(0px 0px 0px 6px !important);
		}
	}
	.MuiInputBase-root {
		@include align-items(flex-start);
		.MuiInput-input {
			padding-right: 10px;
			overflow-y: overlay;
		}
		.MuiInputAdornment-root {
			margin-top: 0.69em;
			svg {
				width: 12px !important;
				height: 12px !important;
				path {
					fill: $color-primary1;
					stroke: $color-primary1;
					stroke-width: 1;
				}
			}
		}
	}
	.MuiInputBase-root {
		// padding: 0em 1em;
		// border: 1.4px solid rgba($color-input-field, 0.5);
		// height: auto;
		// @include border-radius(6px);
		// @include transition(0.25s);
		// &:before,
		// &:after {
		// 	display: none;
		// }
		// .MuiInputBase-input {
		// 	padding-top: 10px;
		// 	padding-bottom: 10px;
		// 	font-weight: $font-weight-regular;
		// 	font-size: $font-size-h5;
		// 	line-height: $line-height5;
		// 	color: $color-primary2;
		// 	padding-left: 3px;
		// }
		// &.MuiInputBase-adornedStart {
		// 	//padding-left: 5px;
		// 	.MuiInputAdornment-positionStart {
		// 		//margin-right: 0;
		// 		svg {
		// 			//width: 10px;
		// 			//height: 10px;
		// 			path {
		// 				fill: $color-primary1;
		// 				stroke: $color-primary1;
		// 			}
		// 		}
		// 	}
		// }
		#comment-text-field {
			font-size: $font-size-h5;
			line-height: $line-height2;
			color: $color-primary2;
			padding: 6px 0;
			min-height: 25px;
		}
	}
	.MuiAvatar-root {
		margin-right: 1.28em;
		margin-top: 6px;
	}
	.add-cfr-actions {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 1;
		width: 100%;
		@include flex;
		@include align-items(flex-start);
		@include justify-content(flex-end);
		button {
			padding: 0.2em;
			min-width: auto;
			border-radius: 0px 0px 4px 4px;
			vertical-align: top;
			// svg {
			// 	width: 0.875em;
			// 	height: 0.875em;
			// 	path {
			// 		fill: $color-white;
			// 	}
			// }
			&.add-cfr-cancel {
				// margin-right: 2px;
				// color: rgba($color-input-field, 0.5);
				// svg {
				// 	path {
				// 		fill: rgba($color-input-field, 0.5);
				// 		stroke: rgba($color-input-field, 0.5);
				// 	}
				// }
				// &:hover {
				// 	background-color: transparent;
				// 	svg {
				// 		path {
				// 			fill: $color-primary2;
				// 			stroke: $color-primary2;
				// 		}
				// 	}
				// }
			}
			&.add-cfr-save {
				//border-radius: 0px 4px 4px 4px;
				// background: $color-primary1;
				&.btn-disabled {
					//background: rgba($color-input-field, 0.5);
					opacity: 0.5;
					cursor: default;
				}
			}
		}
		.error-text {
			font-weight: $font-weight-regular;
			font-size: $font-size-h6;
			color: $color-text-info;
		}
		.help-text {
			flex: 1 1;
			text-align: left;
			.notes-help-text {
				font-size: 14px;
				line-height: 20px;
				font-weight: $font-weight-bold;
				font-style: italic;
				color: $color-primary2;
				opacity: 0.5;
			}
		}
	}
}
/**okr name**/
.okr-name {
	border-bottom: 1px solid rgba($color-input-field, 0.2);
	padding-bottom: 0.5em;
	margin-bottom: 0.5em;
	h4 {
		opacity: 0.7;
		font-weight: normal !important;
		margin-bottom: 0.42em;
	}
	p {
		font-weight: $font-weight-bold !important;
	}
}
/**okr drawer inner tabs css start here**/
.okr-drawer-tabs {
	min-height: 40px !important;
	margin-bottom: 0;
	.MuiTab-root {
		min-width: auto;
		min-height: 22px;
		opacity: 1;
		font-size: $font-size-h4;
		font-weight: $font-weight-bold;
		color: $color-primary2;
		padding: 8px 0 15px;
		margin-right: 20px;
		.MuiTab-wrapper {
			@include flex;
			@include align-items(center);
			@include flex-direction(unset);
			svg {
				width: 1em;
				height: 1em;
				margin: 0 0 0 0;
			}
			span.count-text {
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				margin-left: 2px;
			}
			.dot-bubble {
				margin: 0 0.3125em 0 0;
				.MuiBadge-anchorOriginTopRightRectangle {
					top: 7px;
				}
			}
		}
		svg {
			path,
			rect,
			circle {
				fill: $color-primary2;
			}
		}
		&.Mui-selected {
			color: $color-primary1;
			svg {
				path,
				rect,
				circle {
					fill: $color-primary1;
				}
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.MuiTabs-indicator {
		background-color: $color-primary1;
	}
}
.tabs-panel-content {
	//padding: 2em 5em;
	flex: 1 1;
	[role='tabpanel'] {
		height: 100%;
		> div {
			height: 100%;
		}
	}
	.tab-panel-inner {
		padding: 2em 60px;
		&.no-record-available {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			height: 77%;
		}
	}
	.conversation-tab {
		.cfr-tab-inner-head {
			.add-cfr-text {
				.add-cfr-actions {
					padding-left: 50px;
				}
				.editior-pane {
					width: calc(100% - 45px);
				}
			}
		}
	}
}

/**no record message css start here**/
.cfr-no-record {
	text-align: center;
	.no-record-area {
		object {
			margin-bottom: 20px;
		}
		h2 {
			text-transform: capitalize;
			margin-bottom: 5px;
		}
	}
}
/**Task list css start here**/
.task-lists {
	padding: 0;
	li {
		@include flex;
		padding: 0;
		.task-box {
			padding: 0.25em 0;
			position: relative;
			width: calc(100% - 30px);
			@include flex;
			@include align-items(flex-start);
			@include transition-default;
			margin-bottom: 0.125em;
			@include flex-direction(column);
			border: 1.4px solid transparent;
			@include border-radius(6px);
			.task-text-field {
				position: relative;
				width: 100%;
				@include transition-default;
				p {
					padding: 0.25em 1.142em 0.3em 2.285em;
					@include transition-default;
					font-size: $font-size-h5;
					line-height: 1.25;
					border: 1.4px solid transparent;
					@include border-radius(6px);
					// &:hover {
					// 	border: 1.4px solid rgba($color-input-field, 0.5);
					// }
				}
			}
			.quick-hover {
				p {
					&:hover {
						border: 1.4px solid rgba($color-input-field, 0.5);
					}
				}
			}
			.task-checkbox {
				position: absolute;
				left: 0;
				top: -1px;
			}
			.MuiFormControl-root {
				min-width: 100%;
			}
			.MuiInputBase-root {
				padding: 0.25em 1em 0.25em 2em;
				border: 1.4px solid rgba($color-input-field, 0.5);
				@include border-radius(6px);
				@include transition-default;
				@include default-shadow;
				&:before,
				&:after {
					display: none;
				}
				textarea {
					@include transition-default;
					height: auto;
					padding: 0;
					font-size: $font-size-h5;
					line-height: 1.25;
				}
			}
			.MuiCheckbox-root {
				padding: 0.5em;
				&:hover {
					background-color: transparent;
				}
				svg {
					width: 14px;
					height: 14px;
				}
			}
			.task-edit-actions {
				@include flex;
				position: absolute;
				right: 0;
				top: 100%;
				z-index: 1;
				background: $color-white;
				border-radius: 0 0 4px 4px;
				button {
					padding: 0.1em;
					min-width: auto;
					border-radius: 0px 0px 4px 4px;
					svg {
						width: 12px;
						height: 12px;
						path {
							fill: $color-primary1;
						}
					}
					&.task-edit-save {
						background: $color-primary1;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-white;
							}
						}
					}
					&.task-edit-cancel {
						margin-right: 2px;
						color: rgba($color-input-field, 0.5);
						svg {
							path {
								fill: rgba($color-input-field, 0.5);
								stroke: rgba($color-input-field, 0.5);
							}
						}
						&:hover {
							background-color: transparent;
							svg {
								path {
									fill: $color-primary2;
									stroke: $color-primary2;
								}
							}
						}
					}
				}
			}
			&.task-view-only {
				.task-checkbox {
					&.Mui-checked {
						&.Mui-disabled {
							svg {
								path {
									fill: rgba($color-primary2, 0.5);
								}
							}
						}
					}
				}
				&:hover {
					.MuiInputBase-root {
						border: 1.4px solid transparent;
					}
				}
			}
			.timestamp {
				margin: 0.125em 0 0 2em;
				p {
					color: rgba($color-primary2, 0.5);
				}
			}
		}
		.delete-btn {
			margin-bottom: 1em;
			color: $color-primary1;
			width: 2em;
			height: 2em;
			@include transition-default;
			opacity: 0;
			@include border-radius(50%);
			svg {
				path {
					fill: $color-primary1;
				}
			}
			&:hover {
				background-color: transparent;
			}
		}
		&:hover {
			.delete-btn {
				opacity: 1;
			}
		}
		&.completed-task {
			.task-box {
				.MuiTypography-body1 {
					color: rgba($color-primary2, 1);
					//text-decoration: line-through;
				}
				.MuiInputBase-root {
					textarea {
						color: rgba($color-primary2, 1);
						//text-decoration: line-through;
					}
				}
			}
			.task-edit-actions {
				display: none;
			}
		}
		&.disabled {
			cursor: default;
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
.task-help-text {
	margin-top: -1em;
	margin-bottom: 1em;
	flex: 1 1;
	text-align: left;
	.helper-text {
		font-size: $font-size-h6;
		line-height: $line-height4;
		font-weight: $font-weight-bold;
		font-style: italic;
		color: $color-primary2;
		opacity: 0.5;
		strong {
			margin-right: 0.25em;
			font-weight: $font-weight-bold;
		}
	}
}

/**notes list css start here**/
.long-text {
	color: $color-primary2;
	font-weight: normal;
	line-height: $line-height2;
	font-size: $font-size-h5;
	font-weight: $font-weight-regular;
	word-break: break-word;
	//margin-bottom: 0.5em;
	p {
		line-height: $line-height2;
		font-size: $font-size-h5;
	}
	h1 {
		color: $color-primary2;
	}
	a,
	.tagged-name {
		color: $color-primary1;
		cursor: pointer;
	}
	strong {
		font-weight: $font-weight-bold;
	}
	em,
	i {
		font-style: italic;
	}
	ul,
	ol {
		margin: 0 0 0 1.07em;
		li {
			//margin: 0 0 0.35em 0;
			//list-style-type: disc;
			padding-left: 0;
			font-weight: $font-weight-regular;
		}
	}
	ul {
		list-style-type: circle;
	}
	ol {
		list-style-type: decimal;
	}
	blockquote {
		border-left: 5px solid rgba($color-primary2, 0.2);
		margin: 5px 0;
		padding: 5px;
	}
	table {
		font-family: $font-family-lato;
		border-collapse: collapse;
		width: 100%;
	}

	table td,
	table th,
	table {
		border: 1px solid rgba($color-primary2, 0.5);
		padding: 8px;
		border-collapse: collapse;
	}
	table tr {
		background-color: $color-hover;
	}
	table tr:nth-child(even) {
		background-color: rgba($color-hover, 0.5);
	}
	table th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		font-weight: $font-weight-bold;
	}
	.table {
		width: auto !important;
	}
}
.cfr-list {
	padding: 0;
	> li {
		padding: 0;
		border-bottom: 1px solid rgba($color-input-field, 0.5);
		display: block;
		&:last-child {
			border-bottom: 0px;
		}
		.cfr-list-inner {
			@include flex;
			@include align-items(flex-start);
			padding: 0.75em 0.625em 0.875em;
			.cfr-list-content {
				width: calc(100% - 25px);
			}
			.add-cfr-text {
				&.editor-visible {
					margin-bottom: 1.2em;
				}
			}
		}
		&.highlight {
			.cfr-list-inner {
				background: $color-okr-highlighter !important;
			}
		}
		p {
			color: $color-primary2;
			font-weight: normal;
		}

		.collapse-content {
			margin-bottom: 0.5em;
		}
		.timestamp {
			@include flex;
			@include align-items(center);
			p {
				color: rgba($color-primary2, 0.5);
				span {
					margin-left: 3px;
				}
				&:nth-child(2) {
					margin-left: 5px;
					padding-left: 6px;
					position: relative;

					&::before {
						content: '';
						background: rgba($color-primary2, 0.5);
						width: 1px;
						height: 10px;
						margin-right: 5px;
						display: inline-block;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -5px;
					}
				}
			}
		}
		button.menu-btn {
			@include border-radius(50%);
			width: 30px;
			height: 30px;
			margin-right: -4px;
			opacity: 0;
			pointer-events: none;
			svg {
				path {
					fill: $color-primary2;
				}
			}
			&.active {
				opacity: 1;
				pointer-events: initial;
			}
		}
		button.btn2 {
			margin: 0;
			height: auto;
			font-size: $font-size-subtitle2;
			text-transform: uppercase !important;
			font-weight: bold;
			text-decoration: none;
		}
		&:hover {
			button.menu-btn {
				opacity: 1;
				pointer-events: initial;
			}
		}
	}
	.truncate {
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		overflow-wrap: break-word;
	}
	&.comment-list {
		> li {
			border-bottom: 0;
			margin-bottom: 1.5625em;
			.conversation-list-content {
				@include flex;
				@include align-items(flex-start);
			}
			.cfr-reply-list-info {
				margin-top: 15px;
				padding-left: 40px;
				@include flex;
				@include flex-direction(column);
				.cfr-reply-list-content {
					@include flex;
					@include justify-content(space-between);
					.MuiAvatar-root {
						margin-top: 5px;
					}
				}
				.cfr-add-post-action {
					margin-top: 15px;
					@include flex;
					@include justify-content(flex-end);
				}
				.editor-pane {
					width: 100%;
				}
				.ck-editor-box {
					//	width: 648px;
					width: 100%;
				}
				&.highlight {
					.cfr-reply-list-content {
						.cfr-list-inner {
							background: $color-okr-highlighter !important;
						}
					}
				}
			}
			.cfr-replies-trail {
				padding-left: 40px;
				.no-of-replies {
					margin-bottom: 15px;
					.no-of-replies-btn {
						@include flex;
						@include align-items(center);
						padding: 0;
						color: $color-primary1;
						font-weight: $font-weight-bold;
						font-size: $font-size-h6;
						svg {
							width: 14px;
							height: 14px;
							margin-right: 5px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiAvatar-root {
							margin: 0 3px 0 0;
						}
					}
				}
			}
			.comment-section {
				width: 100%;
			}
			.MuiAvatar-root {
				margin-right: 1.125em;
				margin-top: 1.25em;
			}
			.author-name {
				margin-bottom: 2px;
			}
			.timestamp {
				p {
					color: rgba($color-primary2, 0.5);
					&:nth-child(2) {
						&::before {
							width: 1px;
						}
					}
				}
			}
			.cfr-list-inner {
				margin-bottom: 10px;
				background: $color-bg-form;
				@include border-radius(6px);
				padding: 0.9375em 0.625em 1.25em 1.25em;
				.collapse-content {
					margin: 1.125em 0 0 0;
				}
			}
			button.menu-btn {
				margin-top: -2px;
			}
			// .like-btn {
			// 	margin-top: 0.625em;
			// 	border: 1.4px solid rgba($color-input-field, 0.5);
			// 	background: $color-white;
			// 	height: auto;
			// 	min-height: 26px;
			// 	min-width: 54px;
			// 	@include border-radius(100px);
			// 	padding: 0px 0.78em;
			// 	line-height: 26px;
			// 	color: $color-primary1;
			// 	font-size: $font-size-h5;
			// 	font-weight: $font-weight-bold;
			// 	svg {
			// 		width: 16px;
			// 		height: 16px;
			// 		margin-right: 5px;
			// 		path {
			// 			fill: $color-primary1;
			// 		}
			// 	}
			// }
			.progress-notes {
				background: rgba($color-primary2, 0.1);
				@include border-radius(6px);
				height: 1.5em;
				margin-top: 0.83em;

				.MuiChip-label {
					line-height: $line-height5;
					font-weight: $font-weight-bold;
					padding-left: 0.58em;
					padding-right: 0.58em;
					opacity: 0.5;
					letter-spacing: 0.1px;
				}
			}
			.load-more-area {
				position: relative;
				@include flex;
				@include justify-content(space-between);
				margin: 25px 0;
				&:after {
					position: absolute;
					top: 13px;
					content: '';
					height: 1.4px;
					width: 100%;
					background: $color-hover;
				}
				.load-more,
				.view-less {
					position: relative;
					z-index: 1;
					background: $color-white;
					.btn-small-text {
						text-transform: unset !important;
					}
				}
				.load-more {
					padding-right: 10px;
				}
				.view-less {
					padding-left: 10px;
				}
			}
			.cfr-reply-action {
				margin: 5px 0 15px;
				@include flex;
				@include justify-content(space-between);
				.cfr-reply-btm-left {
					@include flex;
					@include align-items(center);
					margin-left: 10px;
					.like-filled-btn {
						@include flex;
						.MuiButton-root {
							position: relative;
							// .icon {
							// 	position: relative;
							// 	z-index: 0;
							// }
							// .remove-img {
							// 	position: relative;
							// 	z-index: 1;
							// }
						}
						svg {
							margin-right: 0;
						}
					}
					.MuiButton-root {
						padding: 0;
						margin: 0 10px;
						position: relative;
						font-size: $font-size-h6;
						color: $color-primary1;
						font-weight: $font-weight-bold;
						border: 1.4px solid transparent;
						background: transparent;
						&.like-border-btn,
						&.like-btn {
							.like-animation-img {
								position: absolute;
								bottom: -8px;
								left: -14px;
								z-index: -1;
							}
							&:after {
								margin-right: -12px;
								position: absolute;
								right: 0;
								content: '';
								width: 3px;
								height: 3px;
								background: rgba($color-secondary1, 0.5);
								border-radius: 100%;
							}
						}
						&.like-border-btn,
						&.comment-border-btn {
							font-size: $font-size-h6;
							font-weight: $font-weight-bold;
							padding: 5px 10px;
							color: $color-primary1;
							background: $color-bg-form;
							border: 1.4px solid rgba($color-input-field, 0.5);
							@include border-radius(20px);
							min-height: 29px;
							&.like-border-btn {
								margin-right: 10px;
								svg {
									margin-right: 0;
								}
							}
							svg {
								margin-right: 5px;
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
						&:hover {
							background: transparent;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
				.cfr-reply-btm-right {
					> .MuiList-root {
						@include flex;
						> .MuiListItem-root {
							width: auto;
							padding: 0;
							margin: 0 10px;
							&.active {
								cursor: pointer;
								> .MuiListItemText-root {
									> .MuiTypography-body1 {
										color: $color-primary1;
										cursor: pointer;
									}
								}
							}
							> .MuiListItemText-root {
								> .MuiTypography-body1 {
									font-size: $font-size-p-body2;
									color: $color-secondary1;
								}
							}
							&:after {
								margin-right: -12px;
								position: absolute;
								right: 0;
								content: '';
								width: 3px;
								height: 3px;
								background: rgba($color-secondary1, 0.5);
								border-radius: 100%;
							}
							&:last-child {
								margin: 0 0 0 10px;
								&:after {
									display: none;
								}
							}
							button {
								padding: 0;
								font-size: $font-size-h6;
								color: $color-primary1;
								background: transparent;
								cursor: pointer;
								&:hover {
									background: transparent;
								}
								.MuiTouchRipple-root {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		.cfr-conversation-action {
			margin: 5px 0 15px;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			.cfr-conversation-btm-right {
				> .MuiList-root {
					@include flex;
					> .MuiListItem-root {
						width: auto;
						padding: 0;
						margin: 0 10px;
						&.active {
							cursor: pointer;
							> .MuiListItemText-root {
								> .MuiTypography-body1 {
									color: $color-primary1;
									cursor: pointer;
								}
							}
						}
						> .MuiListItemText-root {
							> .MuiTypography-body1 {
								font-size: $font-size-p-body2;
								color: $color-secondary1;
							}
						}
						&:after {
							margin-right: -12px;
							position: absolute;
							right: 0;
							content: '';
							width: 3px;
							height: 3px;
							background: rgba($color-secondary1, 0.5);
							border-radius: 100%;
						}
						&:last-child {
							margin: 0 0 0 10px;
							&:after {
								display: none;
							}
						}
						button {
							padding: 0;
							font-size: $font-size-h6;
							color: $color-primary1;
							background: transparent;
							cursor: pointer;
							&:hover {
								background: transparent;
							}
							.MuiTouchRipple-root {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.load-more-ul {
	.load-more {
		@include flex;
		@include align-items(flex-start);
		border-bottom: 0;
		margin-top: 0.9375em;
		margin-bottom: 1.5625em;
		@include justify-content(center);
		padding: 0;
		.load-more-btn {
			color: $color-primary1;
			padding: 0 1.5625em;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			background: $color-white;
			&:hover {
				background: $color-white;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
		&::before {
			content: '';
			//width: calc(100% - 48px);
			width: 100%;
			height: 1.4px;
			background: $color-hover;
			position: absolute;
			//left: 48px;
			left: 0;
			top: 50%;
			@include transform(translate(0px, -50%));
		}
	}
	&.comment-load-more {
		padding: 0 0px 0 48px;
		.load-more {
			&::before {
				//width: calc(100% - 48px);
				//left: 48px;
			}
		}
	}
}
//cfr popper menu
.popper-menu {
	&.popper-cfr-menu {
		z-index: 9999;
		max-width: 180px !important;
		min-width: 180px;
		.MuiPaper-root {
			@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			margin: 0;
			ul {
				li {
					padding-left: 1.25em;
					padding-right: 1.25em;
					.MuiListItemAvatar-root {
						svg {
							min-width: 1em;
							min-height: 1em;
							path {
								fill: $color-primary2;
							}
						}
					}
				}
			}
		}
	}
}
