@import '../../../styles/sass-utils/variables';

.pip-view-mode {
	.pip-view-sub-head {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 0px 0 20px;
		.pip-view-sub-head-left {
			@include flex;
			@include align-items(center);
			.back-to-main {
				padding-bottom: 0;
				margin-right: 10px;
			}
			.user-info {
				@include align-items(center);
				.user-img {
					margin-right: 10px;
					.MuiAvatar-root {
						margin-top: 0px;
					}
				}
			}
			.MuiTypography-body2 {
				color: rgba($color-primary2, 0.5);
			}
		}
		.pip-view-sub-head-right {
			@include flex;
			@include align-items(center);
			button {
				margin-right: 15px;
				&:last-child {
					margin-right: 0px;
				}
			}
			.user-menu-btn {
				width: 24px;
				height: 24px;
				@include border-radius(100%);
				svg {
					width: 14px;
					height: 14px;
					min-width: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
			.btn-link-type1 {
				margin-right: 15px;
			}
			.extend-btn-disabled {
				margin-right: 15px;
			}
		}
	}
	.pip-view-content {
		@include flex;
		@include justify-content(space-between);
		.pip-view-details {
			.pip-status {
				margin-bottom: 5px;
				@include flex;
				@include align-items(center);
				.MuiChip-root {
					margin-right: 5px;
					&:last-child {
						margin-right: 0px;
					}
				}
				.timeframe-animation {
					@include flex;
					.MuiChip-root {
						margin-right: 5px;
					}
				}
			}
			.feedback-date-range {
				margin-top: 10px;
				//margin-bottom: 20px;
			}
			.long-text {
				margin-top: 15px;
				&.truncate {
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
					overflow-wrap: break-word;
				}
			}

			.btn-link {
				height: auto;
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				color: $color-primary1;
				text-decoration: none !important;
				margin: 0px;
				&.disabled,
				&.Mui-disabled {
					pointer-events: none;
					opacity: 0.8;
					color: rgba($color-primary2, 0.5);
					svg {
						opacity: 0.6;
						path {
							fill: rgba($color-primary2, 0.7);
						}
					}
				}
			}
			.feedback-type-chip {
				@include flex;
				.review-chip {
					.MuiChip-label {
						@include flex;
						@include align-items(center);
						em {
							margin: 0 3px;
							width: 3px;
							height: 3px;
							@include border-radius(3px);
							background: rgba($color-secondary1, 0.5);
						}
					}
				}
			}
		}
		.pip-view-content-left {
			width: calc(100% - 380px);
		}
		.pip-view-content-right {
			width: 350px;
			.full-rectangular-card {
				margin-bottom: 10px;
				&.pip-reviewers-box {
				}
				&.pip-linked-feedback-box {
					padding-bottom: 10px;
					.pip-status {
						margin-bottom: 5px;
						.MuiChip-root {
							margin-right: 5px;
						}
					}
					.pip-feedback-list {
						ul {
							width: calc(100% + 20px);
							margin-left: -10px;
							li {
								padding: 10px;
								position: relative;
								cursor: pointer;
								@include border-radius(6px);
								margin-bottom: 20px;
								&:last-child {
									border-bottom: none;
									margin-bottom: 0px;
									&::after {
										display: none;
									}
								}
								&::after {
									content: '';
									width: calc(100% - 20px);
									height: 1.4px;
									border-bottom: 1.4px solid $color-hover;
									position: absolute;
									bottom: -10px;
									left: 10px;
								}
								&:hover {
									background: $color-hover;
								}
								.MuiTypography-h4 {
									padding-bottom: 0;
								}
								.feedback-date-range {
									margin-top: 10px;
								}
							}
						}
					}
				}
				&.pip-attachments-box {
					.upload-attachments-list {
						ul {
							width: calc(100% + 20px);
							margin-left: -10px;
							li {
								padding: 10px;
								cursor: pointer;
								&:hover {
									background: $color-hover;
								}
							}
						}
					}
				}
			}
			.user-no-record {
				padding: 0px;
				.MuiTypography-h2 {
					margin-bottom: 0px;
					text-transform: capitalize;
				}
			}
		}
		.pip-view-tabs {
			.pip-view-tab-head {
				padding: 10px 0;
				.okr-tab-button {
					min-height: auto !important;
					.MuiTab-root {
						min-width: auto;
						&.arrow-tab {
							.MuiBadge-dot {
								top: -8px;
							}
							.arrowIcon {
								margin-left: 5px;
							}
						}
						&.Mui-selected {
							svg {
								circle,
								rect {
									fill: $color-primary1;
								}
							}
						}
					}
				}
			}
			.pip-view-tab-content {
				.pip-section-panel {
					.pip-editor-panel {
						margin-bottom: 20px;
						.long-text {
							padding: 10px;
							@include border-radius(6px);
							border: 1.4px solid rgba($color-input-field, 0.5);
						}
						&.last-child {
							margin-bottom: 0;
						}
						.editor-view-label {
							padding-bottom: 10px;
							@include flex;
							@include align-items(center);
							text-transform: capitalize;
							svg {
								margin-right: 5px;
								width: 14px;
								height: 14px;
							}
						}
					}
				}
				.pip-improvements-area {
					.long-text {
						padding: 10px;
						@include border-radius(6px);
						border: 1.4px solid rgba($color-input-field, 0.5);
					}
				}
				.pip-view-resolve {
					.form-label {
						svg {
							width: 14px;
							min-width: 14px;
							height: 14px;
							margin-right: 5px;
						}
					}
					.custom-type-dropdown-top {
						max-width: 355px;
						margin-bottom: 30px;
					}
					.action-btn {
						margin-top: 10px;
						@include flex;
						@include justify-content(flex-end);
					}
					.resolve-read-mode {
						.user-info {
							@include flex;
							margin-bottom: 15px;
							.MuiTypography-h6 {
								margin-top: 5px;
							}
						}
					}
				}
				.welcome-content {
					padding-top: 40px;
				}
			}
		}
	}
}
.pip-tabs-dropdown {
	margin: 0px !important;
	max-width: 213px !important;
	.MuiPaper-root {
		margin-top: 0px;
		&::before,
		&::after {
			right: 10px !important;
		}
		ul {
			li {
				.MuiTypography-root {
					font-weight: normal;
					@include flex;
					@include align-items(center);
					@include justify-content(space-between);
					.MuiBadge-root {
						margin-right: 10px;
					}
				}
				.dot-bubble {
					.MuiBadge-badge {
						right: 0;
					}
				}
			}
		}
	}
}
/**reviewers comment css start here**/
.reviewers-comment {
	.reviewer-info {
		margin-bottom: 30px;
		.pip-reviewers-list {
			margin-top: 5px;
		}
	}
	.reviewers-post-comment {
		.reviewers-comment-top {
			@include flex;
			margin-bottom: 20px;
			.user-info {
				margin-top: 0px;
			}
			.add-comment-field {
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
			.reviewer-post-editor-box {
				flex: 1 1;
				.reviewer-post-action {
					width: 100%;
					margin-top: 10px;
					@include flex;
					@include justify-content(flex-end);
					@include align-items(center);
					.btn-link {
						margin: 0px;
					}
				}
			}
		}
		.cmt-count {
			margin-bottom: 5px;
		}
		.reviewers-post-comment-list {
			.reviewers-post-comment-row {
				@include flex;
				margin-bottom: 10px;
				&.highlight-row {
					background-color: $color-okr-highlighter;
				}
				.user-info {
					.user-img {
						margin-top: 15px;
					}
				}
				.reviewers-post-box {
					background: $color-bg-form;
					padding: 20px;
					@include border-radius(6px);
					flex: 1 1;
					.post-content-head {
						@include flex;
						@include justify-content(space-between);

						margin-bottom: 15px;
						.MuiTypography-h6 {
							margin-top: 5px;
						}
						.user-menu-btn {
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							opacity: 1;
							svg {
								width: 14px;
								height: 14px;
								min-width: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
					&:hover {
						.post-content-head {
							.user-menu-btn {
								opacity: 1;
							}
						}
					}
				}
				.reviewer-post-editor-box {
					flex: 1 1;
					.reviewer-post-action {
						width: 100%;
						margin-top: 10px;
						@include flex;
						@include justify-content(flex-end);
						@include align-items(center);
						.btn-link {
							margin: 0px;
						}
					}
				}
			}
		}
	}
}
/**timeframe tooltip css start here**/
.timeframe-tooltip {
	.MuiTooltip-tooltip {
		min-width: auto;
		max-width: max-content !important;
	}
	ul {
		padding: 0;
		li {
			@include flex;
			padding: 0;
			span {
				min-width: 40px;
				margin-right: 15px;
				&:nth-child(2) {
					min-width: 160px;
				}
				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}
}
.timeframe-animation {
	position: relative;
	object {
		width: 150px;
		margin-left: -75px;
		position: absolute;
		left: 50%;
		margin-top: -40px;
	}
}
