.user-info {
	@include flex;
	@include align-items(flex-start);
	.user-img {
		margin-right: 10px;
		.MuiAvatar-root {
			width: 30px;
			height: 30px;
		}
	}
	.user-info-list {
		@include flex;
		.MuiTypography-root {
			&:last-child {
				margin-left: 8px;
				&::before {
					content: '';
					background: $color-bg-dot;
					width: 4px;
					height: 4px;
					@include border-radius(5px);
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
				}
			}
		}
	}
	&.user-info-ellipsis {
		.user-info-details {
			h4,
			.MuiTypography-subtitle2 {
				max-width: 190px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
	&.user-team-info {
		.MuiAvatar-root {
			width: 36px;
			height: 30px;
			@include border-radius(168px);
			font-size: $font-size-h5;
			img {
				@include border-radius(168px);
			}
		}
	}
	&.v-center {
		@include align-items(center);
		.user-img {
			.MuiAvatar-root {
				margin-top: 0;
			}
		}
	}
}
