@import '../../sass-utils/variables';
/**my-teams-assessment-head css start here**/
.my-teams-assessment-head {
	padding: 20px 0;
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	.my-teams-assessment-head-left {
		.my-teams-assessment-head-left-inner {
			@include flex;
		}
	}
	.MuiTypography-root.alert-text {
		margin-right: 40px;
		svg {
			width: 14px;
			height: 14px;
		}
	}
	ul {
		@include flex;
		li {
			padding: 0px;
			width: auto;
			.filter-grey-btn {
				margin-right: 10px;
				padding: 7px 19px;
				min-width: 110px;
				min-height: 34px;
				height: 34px;
				line-height: 34px;
				opacity: 1;
				text-align: left;
				@include transition-default;
				border: 1.4px solid rgba($color-input-field, 0.5);
				background: $color-white;
				color: $color-primary2;
				font-size: $font-size-base;
				@include border-radius(6px);
				// .count {
				// 	padding: 5px 10px;
				// 	border-left: 1px solid rgba($color-secondary2, 0.5);
				// 	margin-left: 10px;
				// }
				&.active {
					color: $color-primary1;
					background: $color-secondary2;
					border: 1.4px solid $color-primary1;
				}
			}
		}
	}
}
/**alert text css start here**/
.alert-text {
	@include flex;
	@include align-items(center);
	color: $color-tags !important;

	svg {
		margin-right: 5px;
	}
}

/**team assessment listing css start here**/
.team-assessment-listing {
	box-shadow: none;
	background: none;
	@include custom-scrollbar-small-height;
	padding-bottom: 20px;
	//max-height: calc(100vh - 150px);
	max-width: 1221px;
	@media screen and (max-width: 1282px) {
		max-width: 1150px;
	}
	table {
		position: relative;
		border-collapse: separate; /* Don't collapse */
		border-spacing: 0;
		.MuiTableCell-root {
			min-width: 200px;
			padding: 0px;
			border: 0px;
			&:first-child {
				min-width: 230px;
				left: 0;
				position: -webkit-sticky;
				position: sticky;
				z-index: 9;
				width: 230px;
			}
			&:last-child {
				min-width: 100px;
				width: 100px;
				.cell-wrap {
					@include justify-content(center);
				}
			}
			&.manage-assessment-col {
				&:hover {
					.edit-button {
						opacity: 1;
					}
				}
			}
			&.three-sixty-column {
				.view-button {
					margin-left: 10px !important;
					margin-right: 0px !important;
				}
				&:hover {
					.edit-button {
						opacity: 1;
					}
				}
			}
			.clickable-text {
				color: $color-primary1;
			}
		}
		.cell-wrap {
			@include flex;
			@include align-items(center);
			@include justify-content(flex-start);
			.sort-title {
				.sortable-label {
					@include flex;
					@include align-items(center);
					cursor: pointer;
					> span {
						padding-right: 4px;
					}
					svg {
						width: 10px;
						height: 10px;
						margin-top: 0px;
						@include transition-rotate;
					}
				}
				&.asc {
					.sortable-label {
						svg {
							@include transform(rotate(-180deg));
						}
					}
				}
				&.desc {
					.sortable-label {
						svg {
							@include transform(rotate(0deg));
						}
					}
				}
			}
			.txt-wrap {
				font-weight: $font-weight-bold;
				color: $color-secondary1;
				font-size: $font-size-h6;
				line-height: $line-height3;
				text-transform: uppercase;
			}
			.MuiFormControlLabel-root {
				margin-right: 5px;
				margin-left: -5px;
			}
			.MuiTypography-body1 {
				font-weight: $font-weight-bold;
				font-size: $font-size-h4;
				line-height: $line-height2;
			}
			.blank-dash {
				opacity: 0.3;
				font-size: $font-size-h4;
				line-height: $line-height2;
				font-weight: $font-weight-bold;
			}
			.icon-btn {
				margin-right: 5px;
				&:last-child {
					margin-right: 0px;
				}
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
			.short-text {
				color: $color-secondary1;
				font-size: $font-size-h6;
				line-height: $line-height5;
				//font-weight: $font-weight-bold;
				margin-left: 5px;
			}
			.btn-link {
				height: auto;
				font-size: $font-size-h6;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
				&.edit-button {
					margin-left: 10px !important;
					margin-right: 10px !important;
					opacity: 0;
					@include transition(0.3s);
				}
			}

			.user-info {
				@include align-items(center);
				.user-img {
					.MuiAvatar-root {
						margin-top: 0px;
					}
				}
				.MuiTypography-body1 {
					font-weight: normal;
				}
				.user-info-details {
					width: 100%;
					.MuiTypography-root {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						max-width: 100%;
						// @media screen and (max-width: 1282px) {
						// 	max-width: 77px;
						// }
					}
				}
			}
			.inner-wrap,
			.text-wrap-box {
				@include flex;
				width: 100%;
				.btn-wrap-box {
					width: 50%;
					margin-bottom: 0px !important;
				}
			}
			.text-wrap-box {
				.btn-wrap-box {
					width: 40%;
					.btn-link {
						margin-right: 0px;
					}
					&:last-child {
						width: 60%;
					}
					&.full-width {
						width: 100%;
					}
				}
			}
			.btn-txt {
				font-size: $font-size-h4;
				line-height: $line-height2;
				font-weight: normal;
				color: $color-primary1;
				cursor: pointer;
			}
		}
		/**table head  css start here**/
		.MuiTableCell-root.MuiTableCell-head {
			padding: 8px 10px;
			border: 1.4px solid transparent;
			border-bottom: 0px;
			border-left: 0px;
			background: $color-secondary2;
			left: 0;
			top: 0px;
			position: sticky;
			position: -webkit-sticky;
			z-index: 2;
			&:first-child {
				border-top: 0px;
				border-left: 1.4px solid transparent;
				z-index: 99;
			}
		}

		/**table body row css start here**/
		.team-assessment-row {
			.MuiTableCell-root {
				padding: 8px 10px;
				border: 1.4px solid $color-border-tile;
				border-bottom: 0px;
				border-left: 0px;
				min-height: 62px;
				background: $color-white;
				&:first-child {
					@include border-radius(6px 0 0 6px);
					border-left: 1.4px solid $color-border-tile;
				}

				&:last-child {
					@include border-radius(0px 6px 6px 0px);
				}
				&.blue-color-column {
					background: rgba($color-white, 0.5);
				}
			}
			&:nth-last-child(1) {
				.MuiTableCell-root {
					border-bottom: 1.4px solid $color-border-tile;
				}
			}
			&.highlight-column {
				.MuiTableCell-root {
					background-color: $color-okr-highlighter;
				}
			}
			&.disable-row {
				opacity: 0.4;
				.cell-wrap {
					.MuiTypography-body1 {
						color: rgba($color-primary2, 0.6);
					}
					.btn-link {
						color: rgba($color-primary2, 0.6);
					}
					.icon-btn {
						svg {
							path {
								fill: rgba($color-primary2, 0.6);
							}
						}
					}
					.user-info {
						h4 {
							color: rgba($color-primary2, 0.6);
						}
					}
				}
			}
		}
	}

	/** final assessment css start here**/
	&.assessment-listing-grey-bg {
		padding-bottom: 2em;
		table {
			.cell-wrap {
				display: block;
				.custom-type-dropdown-bottom {
					margin-top: 10px;
					.ques-type-dropdown {
						.MuiList-root {
							max-height: 158px;
						}
					}
				}
			}
			.MuiTableCell-root {
				&:first-child {
					width: 293px;
				}
				&:last-child {
					width: auto;
				}
			}
			.MuiTableCell-root {
				&.MuiTableCell-head {
					background-color: $color-white;
					padding-top: 0px;
					padding-bottom: 15px;
				}
			}
			.team-assessment-row {
				.MuiTableCell-root {
					border: 1px solid $color-white;
					background: $color-bg-form;
				}
			}
		}
	}
	&.my-team-assessment-listing {
		table {
			.MuiTableCell-root {
				min-width: 168px;
				width: 168px;
				&.user-col1 {
					min-width: 230px;
					width: 230px;
					.user-info {
						width: calc(100% - 40px);
					}
					// @media screen and (max-width: 1282px) {
					// 	width: 130px;
					// }
					// min-width: 130px;
				}
				&.manage-assessment-col {
					min-width: 170px;
					width: 170px;
					padding: 8px;
				}
				// &.user-col5 {
				// 	min-width: 198px;
				// 	width: 198px;
				// 	@media screen and (max-width: 1282px) {
				// 		width: 178px;
				// 		min-width: 178px;
				// 	}
				// }
				&:last-child {
					min-width: 60px;
					width: 60px;
				}
				// &.col-small {
				// 	min-width: 155px;
				// }
				.btn-wrap-box {
					@include flex;
					@include align-items(center);
					margin-bottom: 3px;
					&:last-child {
						margin-bottom: 0px;
					}
					button {
						&:first-child {
							margin-left: 0px !important;
						}
					}
				}
				.text-wrap-box {
					span {
						margin: 0px;
					}
				}
			}
		}
	}
}

/**assessment footer-action css start here**/
.assessment-table-footer {
	padding-top: 30px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(flex-start);
	.assessment-notes-txt {
		//padding-left: 42px;
		max-width: 402px;
	}
}

.chartContainer {
	.highcharts-container {
		height: 100% !important;
		width: 100% !important;
	}
}

/**nominate drawer css start here**/
.nominate-drawer-panel {
	padding-top: 25px;
	/**information message css start here**/
	.information-message {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		padding: 3px 3.75em;
		background: $color-bg-info;
		@include animation(slideUp 0.8s);
		.MuiTypography-h6 {
			@include inline-flex;
			@include align-items(center);
			color: $color-text-info;
			text-transform: initial;
			line-height: 14px;
			svg {
				width: 14px;
				height: 14px;
				min-width: 14px;
				margin-right: 5px;
				path {
					fill: $color-text-info;
				}
			}
		}
	}
	.blank-dash {
		opacity: 0.3;
		font-weight: bold;
	}
	.users-listing {
		&.users-listing-three-col {
			margin: 0 -10px;
			padding: 25px 0;
			.userCount {
				padding-top: 0px;
			}
			.okr-list-panel-head {
				.MuiList-root {
					.MuiListItem-gutters {
						&.user-col2 {
							width: 20%;
							min-width: 20%;
						}
						&.user-col3 {
							width: 42%;
							min-width: 42%;
						}
					}
				}
			}
			.users-list-content {
				.users-list-row {
					.MuiList-root {
						.MuiListItem-gutters {
							&.user-col2 {
								width: 20%;
								min-width: 20%;
							}
							&.user-col3 {
								width: 40%;
								min-width: 40%;
								h4 {
									padding-right: 10px;
								}
							}
							.MuiChip-root {
								background: $color-bg-tag;
								min-height: 16px;
								min-width: 3.0625em;
								@include border-radius(12px);
								padding: 2px 7px 2px;
								height: auto;
								color: $color-primary2;
								.MuiChip-label {
									font-size: $font-size-subtitle2;
									font-weight: $font-weight-bold;
									line-height: $line-height3;
									padding: 0px;
									display: block;
									text-align: center;
									text-transform: uppercase;
									max-width: 111px;
								}
							}
							.btn-cross {
								right: 0px;
								margin-top: 5px;
								.MuiTouchRipple-root {
									display: none;
								}
								&:hover {
									background: none;
								}
								svg {
									width: 14px;
									height: 14px;
									path {
										stroke: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.add-assessor {
		@include flex;
		@include align-items(center);
		cursor: pointer;
	}
	.selected-user-info {
		@include align-items(center);
		.user-img {
			.MuiAvatar-root {
				margin-top: 0px;
				width: 60px;
				height: 60px;
				font-size: $font-size-h4;
			}
		}
	}
	.nominate-search-active {
		margin-bottom: 30px;
		.select-search-icon {
			.react-select__control {
				@include border-radius(6px 6px 0 6px);
			}
		}
	}
	.select-search-icon {
		.react-select__dropdown-indicator {
			display: none;
		}
		.react-select__control {
			max-height: 96px;
		}
	}

	.nominate-actions {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 1;
		@include flex;
		@include align-items(flex-start);
		button {
			padding: 0;
			&.save-btn {
				&:hover {
					background-color: $color-primary1;
				}
			}

			&.nominate-save-btn {
				@include border-radius(0 0 6px 6px);
				background-color: $color-primary1;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-white;
					}
				}
			}
			&.nominate-cancel-btn {
				//background: $color-bg-form;
				margin-right: 3px;
				svg {
					width: 12px;
					height: 14px;
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
					}
				}
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}

/**360 perform css start here**/
.three-sixty-perform {
	.assessment-no-record {
		img {
			margin-top: 30px;
		}
	}
	.team-assessment-listing {
		max-width: 1011px;
		table {
			.MuiTableCell-root {
				&.user-col1,
				&.user-col2 {
					max-width: 270px;
					width: 270px;
					min-width: 270px;
				}
				&.user-col3,
				&.user-col4 {
					max-width: 150px;
					width: 150px;
					min-width: 150px;
				}
				&.user-col5 {
					max-width: 50px;
					width: 50px;
					min-width: 50px;
				}
			}
			.team-assessment-row {
				.MuiTableCell-root {
					&.sixty-column {
						&:hover {
							.edit-button {
								opacity: 1;
							}
						}
					}
				}
			}
			.cell-wrap {
				.user-info {
					width: calc(100% - 40px);
					.user-info-details {
						width: calc(100% - 40px);
						max-width: calc(100% - 40px);
						.MuiTypography-root {
							max-width: 100%;
						}
					}
				}
			}
			.MuiTableCell-root {
				&.user-col2 {
					.cell-wrap {
						.user-info {
							width: 100%;
							// .user-info-details {
							// 	.MuiTypography-root {
							// 		max-width: 100%;
							// 	}
							// }
						}
					}
				}
			}
		}
	}
	.my-teams-assessment-head {
		.my-teams-assessment-head-left {
			.my-teams-assessment-head-left-inner {
				@include align-items(center);
			}
			.alert-text {
				@include align-items(flex-start);
				svg {
					width: 14px;
					height: 14px;
				}
			}
			.days-remaining {
				margin-right: 40px;
				.alert-text {
					@include flex;
					@include align-items(center);
					margin: 0px !important;
				}
				&.alert-with-due-text {
					.due-date {
						padding-left: 20px;
					}
				}
			}
		}
	}
}
.grid-note {
	padding-top: 10px;
	.note-box {
		padding-top: 10px;
	}
}
.team-assessment-note {
	@include flex;
	padding-top: 10px;
	ul {
		padding: 0px;
		margin-left: 14px;
		list-style-type: disc;
		color: $color-secondary1;
		li {
			padding: 0px;
			display: list-item;
			.team-assessment-note {
				padding-top: 0px;
			}
		}
	}
	.note-list {
		@include flex;
		margin-left: 3px;
		.MuiTypography-h6 {
			margin-right: 0.5em;
			@include flex;
			@include align-items(center);
			position: relative;
			padding-right: 0.8em;
			.MuiTypography-h6 {
				padding: 0;
				margin: 0;
				&::after {
					display: none;
				}
			}
			span.dash {
				padding: 0 2px;
			}
			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 3px;
				height: 3px;
				@include border-radius(3px);
				background-color: $color-bg-dot;
				opacity: 0.5;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}
}
