@import '../../../sass-utils/variables';
.add-perform-review {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			@include justify-content(flex-start);
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			.back-to-main {
				padding-bottom: 0px;
				a {
					margin-right: 14px;
				}
			}
		}
	}
}
/**perform card css start here**/
.perform-card {
	background: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	padding: 30px;
	margin-bottom: 10px;
	&.general-settings {
		.col-full {
			@include align-items(flex-start);
		}
		.setting-blue-box {
			min-height: 191px;
		}
	}
	.perform-card-head {
		margin-bottom: 20px;
		@include flex;
		@include align-items(flex-start);
		@include justify-content(space-between);
		.btn-link {
			margin-right: 0px;
			height: auto;
			font-size: $font-size-h5;
			line-height: $line-height4;
		}
		h6 {
			text-transform: initial !important;
		}
		.form-label-required {
			sup {
				margin-left: 2px;
			}
		}
	}
	.col-full {
		width: 100%;
		@include flex;

		.col-half {
			width: calc(50% - 40px);
			&:first-child {
				margin-right: 40px;
			}
		}
	}
	&.perform-input-stream {
		position: relative;
		.setting-blue-box {
			margin-right: 5px;
			min-width: auto;
			flex: 1 1;
			&:last-child {
				margin-right: 0px;
			}
		}
		.information-message {
			top: 66px;
			padding: 5px 30px;
			@include flex;
			@include align-items(center);
		}
		&.iserror {
			.perform-card-head {
				margin-bottom: 60px;
			}
		}
		.perform-score-switch {
			margin-bottom: 10px;
			.MuiFormControlLabel-root {
				margin-left: 0;
				.MuiFormControlLabel-label {
					font-size: $font-size-h5;
					margin-left: 10px;
				}
			}
		}
		.perform-score-radio {
			margin-bottom: 20px;
			.MuiFormControl-root {
				@include flex;
				@include flex-direction(row);
				@include align-items(center);
				.MuiFormLabel-root {
					padding: 0;
				}
				.MuiFormGroup-root {
					@include flex-direction(row);
				}
			}
			label {
				margin: 0 10px 0 0;
				.MuiButtonBase-root {
					display: none;
					&.Mui-checked {
						& + .MuiFormControlLabel-label {
							background: $color-secondary2;
							border-color: $color-primary1;
							color: $color-primary1;
						}
					}
				}
				.MuiFormControlLabel-label {
					font-size: $font-size-h6;
					line-height: $line-height5;
					color: $color-primary2;
					font-weight: $font-weight-bold;
					border: 1.4px solid rgba($color-input-field, 0.5);
					@include border-radius(100px);
					min-width: 38px;
					padding: 3px 16px;
					margin: 0;
					text-align: center;
					background-color: $color-white;
					&.Mui-disabled {
						border-color: rgba($color-input-field, 0.5);
						color: rgba($color-primary2, 0.5);
						box-shadow: none;
					}
				}
				&.Mui-disabled {
					opacity: 1 !important;
				}
			}
			.other-score {
				.MuiFormControl-root {
					.MuiInputBase-root {
						max-width: 73px;
						padding: 6px 10px;
					}
				}
				.MuiFormHelperText-root {
					top: 0px !important;
					min-width: 274px;
				}
			}
		}
	}
	.perform-visibility-settings {
		.setting-row {
			@include flex;
			margin: 0px 0 8px;
			&:last-child {
				margin-bottom: 0px;
			}
		}

		.visibility-settings-blue-box {
			background: $color-bg-form;
			padding: 15px;
			@include border-radius(6px);
		}
		.is-visible-three-sixty {
			padding: 15px;
		}
	}
	&.perform-visibility {
		.perform-card-head {
			margin-bottom: 50px;
		}
		.information-message {
			top: 72px;
		}
		&.information-selected {
			.perform-card-head {
				margin-bottom: 50px;
			}
		}
	}
}
/**perform text field css start here**/
.perform-txt-field {
	@include flex;
	@include justify-content(space-between);
	margin-bottom: 25px;
	.full-width-field {
		width: 100%;
	}
	input[disabled] {
		background: $color-white;
		opacity: 0.7;
		color: rgba($color-primary2, 0.5);
	}
	.MuiFormLabel-root {
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			vertical-align: middle;
			min-width: 14px;
		}
		sup {
			margin-left: 2px;
		}
	}
	.field-col-2 {
		width: calc(100% - 30px);
		margin-right: 30px;
		&:last-child {
			margin-right: 0px;
		}
	}
	&:last-child {
		margin-bottom: 0px;
	}
	&.drawer-date-field {
		input.form-control {
			width: 100%;
		}
		// .error-field {
		// 	bottom: 0px !important;
		// }
	}
}
/**setting blue box css start here**/
.setting-blue-box {
	background: $color-bg-form;
	padding: 20px 15px;
	@include border-radius(6px);
	width: 25%;
	min-width: 25%;
	margin-right: 5px;
	.setting-blue-box-head {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		margin-bottom: 20px;
		@media screen and (max-width: 1250px) {
			@include align-items(flex-start);
			@include flex-direction(column);
		}
		svg {
			width: 14px;
			height: 14px;
			min-width: 14px;
			margin-right: 10px;
		}
		.head-left {
			@include flex;
			@include align-items(center);
		}
		.MuiFormControlLabel-root {
			margin: 0px;
			@media screen and (max-width: 1250px) {
				padding-left: 24px;
				padding-top: 5px;
			}
		}
	}
	&:last-child {
		margin-right: 0px;
	}
	.setting-user-action {
		h6 {
			padding-left: 33px;
		}
		.setting-des {
			//padding-left: 34px;
			h6 {
				padding: 0px 0 15px 0;
			}
			.MuiTextField-root {
				.MuiInput-root {
					background: $color-white;
				}
			}
		}
		.Mui-disabled {
		}
	}
	.perform-txt-field {
		margin-top: 15px;
		display: block;
		&.drawer-date-field {
			.error-field {
				min-width: 311px;
				z-index: 2;
				margin-left: -155px;
				left: 50%;
				&.final-error-field {
					min-width: 264px;
					margin-left: -134px;
				}
			}
			.disable-label {
				color: rgba($color-primary2, 0.5);
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
					}
				}
			}
		}
	}
	&.final-rating-box {
		.setting-user-action {
			padding-left: 24px;
			position: relative;
			.equal {
				position: absolute;
				left: 0px;
				top: 29px;
				font-size: $font-size-h1;
				line-height: $line-height1;
			}
			.MuiFormControlLabel-root {
				cursor: default;
				.MuiFormControlLabel-label {
					margin-left: 0px;
				}
			}
			.setting-des {
				padding-left: 0px;
			}
		}
	}
	&.committed-okr-box {
		.committed-okr-des-box {
			margin-top: 50px;
		}
	}
}
.settings-tab-panel-wrap {
	margin-top: 10px;
}
.add-perform-action {
	@include flex;
	@include justify-content(flex-end);
	@include align-items(center);
	margin-top: 10px;
	.btn-primary {
		margin-left: 0px;
	}
	.btn-link {
		height: auto;
		line-height: 22px;
		margin-right: 30px;
		svg {
			margin-right: 10px;
		}
	}
	.btn-section {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 15px;
			width: 3px;
			height: 3px;
			border-radius: 3px;
			background-color: $color-bg-dot;
			opacity: 0.5;
			top: 12px;
		}
		button {
			position: relative;
		}
	}
}
.perform-review-role-listing {
	.perform-role-item {
		min-height: 78px;
		position: relative;
		padding: 1em 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(flex-start);
			padding: 0;
			li {
				padding: 0 0.75em 0 1.25em;
				flex: auto;
				width: 33.33%;
				@include align-items(flex-start);

				p {
					font-size: $font-size-h5;
					line-height: $line-height2;
				}
				h6 {
					font-weight: normal;
				}
				.rating-form-name {
					width: 65%;
					margin-right: 10px;
				}
				.btn-link {
					margin-right: 0px;
					height: auto;
					font-size: $font-size-h6;
					line-height: $line-height2;
				}
				.action-btn-wrap {
					opacity: 0;
					@include transition(0.3s);
					button {
						width: 24px;
						height: 24px;
						@include border-radius(100%);
						padding: 0px;
						margin-right: 5px;
						svg {
							width: 14px;
							height: 14px;
							path {
								fill: $color-primary1;
							}
						}
						&:hover {
							//background: $color-secondary2;
						}
						&:last-child {
							margin-right: 0px;
						}
						&.Mui-disabled {
							svg {
								path {
									fill: rgba($color-primary2, 0.5);
								}
							}
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
			.action-btn-wrap {
				opacity: 1;
			}
		}
		&.before-selected-form {
			ul {
				li {
					&.col-2 {
						width: 66.33%;
					}
				}
			}
		}
	}
}

/**exclude manage user drawer css start here**/
.exclude-mng-user {
	padding-top: 10px;
	.userCount {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.user-list-pagination {
			margin: 0px;
			.MuiTablePagination-toolbar {
				margin-top: 0px;
			}
		}
	}
}
.load-more-box {
	@include flex;
	@include justify-content(center);
	width: 100%;
	margin-top: 20px;
}

/**assessment add form css start here**/
.assessment-add-form {
	.short-des-text {
		font-weight: normal !important;
	}
	.drawer-blue-bg-full {
		h5 {
			margin-bottom: 20px;
			color: $color-secondary1;
		}
		.short-des-text {
			font-weight: normal;
			margin-bottom: 40px;
		}
	}
	.ques-row {
		margin-bottom: 20px;
		.helperText-bottom {
			.MuiFormHelperText-root {
				bottom: auto !important;
			}
		}
		.delete-icon {
			@include border-radius(100%);
			padding: 4px;
			margin-left: 10px;
			svg {
				margin: 0px;
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.ques-type {
		margin-bottom: 40px;
		&.active {
			.MuiFormLabel-root {
				color: $color-primary1;
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
		}
	}
}
.custom-error-field {
	.MuiFormLabel-root {
		color: $color-text-info;
		svg {
			path {
				fill: $color-text-info;
			}
		}
		sup {
			color: $color-text-info;
		}
	}
	.form-control {
		border-color: $color-text-info !important;
	}
}
.setting-box-error {
	.MuiTypography-root {
		color: $color-text-info;
		margin-top: 10px;
	}
}
.assessment-form-role-settings {
	.okr-list-panel-head,
	.assessment-form-role-listing {
		ul {
			li {
				&.assessment-form-role-col1,
				&.assessment-form-role-col2,
				&.assessment-form-role-col3,
				&.assessment-form-role-col4 {
					width: 25%;
				}
			}
		}
	}
	.assessment-form-role-listing {
		.assessment-form-role-item {
			min-height: 78px;
			position: relative;
			padding: 1em 0;
			margin-bottom: -1px;
			transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			background-color: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			&.error-row {
				border-color: $color-text-info;
				z-index: 1;
				.error-btn {
					.MuiButtonBase-root {
						color: $color-text-info;
						svg {
							path {
								fill: $color-text-info;
							}
						}
					}
					sup {
						color: $color-text-info;
					}
					.error-field {
						bottom: auto;
						top: -37px;
						min-width: 190px;
						left: -20px;
						font-size: $font-size-h6;

						&::before,
						&::after {
							top: 97%;
							left: 43px;
							@include transform(rotate(270deg));
						}
					}
				}
			}
			.final-rt-comment-lbl {
				margin-left: 20px;
			}
			ul {
				@include flex;
				@include align-items(flex-start);
				padding: 0;
				li {
					padding: 0 20px;
					flex: auto;
					@include align-items(flex-start);
					p {
						font-size: $font-size-h5;
						line-height: $line-height2;
						&.error-field {
							top: 32px;
							z-index: 9;
						}
					}
					h6 {
						font-weight: normal;
					}
					.rating-form-name {
						width: 65%;
						margin-right: 10px;
					}
					.btn-link {
						margin-right: 0px;
						height: auto;
						font-size: $font-size-h6;
						line-height: $line-height2;
						sup {
							margin-left: 2px;
							color: $color-primary2;
						}
					}
					.action-btn-wrap {
						opacity: 0;
						@include transition(0.3s);
						button {
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							padding: 0px;
							margin-right: 5px;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								//background: $color-secondary2;
							}
							&:last-child {
								margin-right: 0px;
							}
							&.Mui-disabled {
								svg {
									path {
										fill: rgba($color-primary2, 0.5);
									}
								}
							}
						}
					}
				}
			}
			&:hover {
				@include default-shadow;
				z-index: 1;
				background: rgba($color-hover, 0.5);
				.action-btn-wrap {
					opacity: 1;
				}
			}
			.drawer-date-field {
				width: 100%;
				input.form-control {
					width: 100%;
				}
			}
		}
	}
	.show-rating-field {
		.show-rating-switch-btn {
			margin-top: 20px;
			margin-left: 0;
		}
	}
}
.disable-label-txt {
	color: rgba($color-primary2, 0.5) !important;
	svg {
		path {
			fill: rgba($color-primary2, 0.5) !important;
		}
	}
}
