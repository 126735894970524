@import '../../../styles/sass-utils/variables';
.admin-body-class {
	.org-listing-content {
		min-height: 92px;
		position: relative;
		padding: 1em 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		.org-details {
			@include flex;
			overflow: hidden;
			.org-details-content {
				overflow: hidden;
				p {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			span {
				font-size: 0.75em;
				font-weight: $font-weight-thin;
			}
		}
		ul {
			@include flex;
			@include align-items(center);
			padding: 0;
			li {
				padding: 0 0.75em 0 1.25em;
				flex: auto;
				&.org-col1,
				&.org-col3 {
					.org-team-chip {
						position: relative;
						//max-width: 12em;
						padding: 0 8px 0 38px;
						@include border-radius(12px);
						font-size: $font-size-h6;
						min-height: 22px;
						.MuiAvatar-root {
							position: absolute;
							left: 0;
							top: -1.4px;
							margin-top: 0;
							margin-right: 0.5em;
							width: 34px;
							height: 24.8px;
							margin-left: 0;
							@include border-radius(12px);
							border: 1.4px solid $color-white;
							font-size: $font-size-h6;
							img {
								@include border-radius(12px);
							}
						}
						.MuiChip-label {
							font-size: $font-size-h6;
							line-height: $line-height2;
						}
					}
					.org-team-col {
						margin: -10px 0 0 -5px;
						position: relative;
						@include flex;
						@include justify-content(space-between);
						flex: 1;
						.org-team-item {
							margin: 10px 0 0 5px;
						}
					}
				}
				&.org-col1 {
					.org-actions {
						@include flex;
						@include align-items(center);
						button {
							padding: 0;
							min-width: auto;
							width: 24px;
							height: 24px;
							@include border-radius(100%);
							margin-left: 0.5em;
							@include transition(0.25s);
							opacity: 0;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-primary1;
								}
							}
						}
					}
				}
				&.org-col2 {
					.org-user-info {
						@include flex;
						overflow: hidden;
						.org-user-img {
							margin-right: 10px;
						}
						.org-user-info-details {
							overflow: hidden;
							p {
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
						span {
							font-size: 0.75em;
							font-weight: $font-weight-thin;
						}
					}
				}
			}
			&:hover {
				li {
					&.org-col1 {
						.org-actions {
							button {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
		}
	}
	.okr-list-panel-head,
	.org-listing-content {
		ul {
			li {
				&.org-col1 {
					width: 26%;
				}
				&.org-col2 {
					width: 17%;
				}
				&.org-col3 {
					width: 17%;
				}
				&.org-col4 {
					width: 12%;
				}
				&.org-col5 {
					width: 14%;
				}
				&.org-col6 {
					width: 14%;
				}
			}
		}
	}
	/* User List pagination CSS*/
	.org-list-pagination {
		overflow: inherit !important;
		@include flex;
		@include align-items(center);
		margin-right: 20px;
		.MuiInputBase-root {
			border-bottom: 0px;
			border: 0;
			margin-right: 5px;
			padding: 0;
			&.Mui-focused {
				border: 0;
				box-shadow: none;
			}
		}
		.MuiTypography-body2 {
			color: $color-secondary1 !important;
			line-height: $line-height2;
			//text-transform: capitalize;
		}
		.MuiSelect-select {
			min-height: 20px;
			padding: 0 23px 0 0px;
			color: $color-secondary1;
			font-size: $font-size-h6;
			line-height: $line-height2;
			font-weight: $font-weight-bold;
			&:focus {
				background-color: transparent;
			}
		}
		.MuiSelect-icon {
			color: $color-primary2;
			&.MuiTablePagination-selectIcon {
				background: url(../../../images/down-arrow.svg) no-repeat center center;
				path {
					display: none;
				}
			}
		}
		.MuiTablePagination-actions {
			@include flex;
			margin-left: 3px;
			button {
				padding: 0px;
				.MuiIconButton-label {
					width: 18px;
					height: 18px;
				}
				&.Mui-disabled {
					opacity: 0.5;
					.MuiIconButton-label {
						background: url(../../../images/down-arrow.svg) no-repeat center center;
						@include transform(rotate(90deg));
					}
				}
				svg {
					display: none;
				}
				&:hover {
					background: $color-white;
					@include box-shadow(2px 4px 7px rgba($color-primary2, 0.1));
				}
			}
		}
		button[aria-label='Previous page'] {
			margin-right: 2px;
			color: $color-primary1;
			.MuiIconButton-label {
				background: url(../../../images/down-arrow-blue.svg) no-repeat 6px center;
				@include transform(rotate(180deg));
			}
		}
		button[aria-label='Next page'] {
			color: $color-primary1;
			.MuiIconButton-label {
				background: url(../../../images/down-arrow-blue.svg) no-repeat 6px center;
			}
			&.Mui-disabled {
				.MuiIconButton-label {
					@include transform(rotate(-90deg));
				}
			}
		}
		.MuiTablePagination-toolbar {
			min-height: auto;
			margin-top: 0.45em;
		}
	}

	/**manage org team box css start here**/
	.manage-org-team-box {
		@include flex;
		@include align-items(center);
		margin-bottom: 25px;
		.manage-org-team-column {
			width: 265px;
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			padding: 24px 24px 12px 24px;
			margin-right: 20px;
			display: block;
			.mng-org-head {
				@include flex;
				@include align-items(center);
				margin-bottom: 16px;
				font-size: $font-size-h1;
				line-height: $line-height1;
				font-weight: $font-weight-black;
				svg {
					margin-right: 10px;
					width: 24px;
					height: 24px;
				}
			}
			.mng-org-middle {
				margin-bottom: 10px;
				font-weight: normal;
			}
			.mng-org-bottom {
				font-weight: normal;
			}
		}
	}
}
/*User Teams Search Filter*/
.org-search-filter-popover {
	width: 360px;
	margin-top: 0.5em;
	margin-left: -1em;
	z-index: 99;
	position: absolute;
	left: -3em;
	top: 2em;
	.MuiPaper-elevation1 {
		@include default-shadow;
		@include border-radius(6px);
		&:before {
			width: 0.75em;
			height: 0.75em;
			content: '';
			position: absolute;
			left: 6.8em;
			top: -0.3em;
			background: $color-white;
			transform: rotate(45deg);
		}
		.team-search-bar {
			padding: 0.875em 0;
			position: relative;
			border-bottom: 1.4px solid rgba($color-primary2, 0.12);
			.MuiInputAdornment-root {
				svg {
					max-width: 14px;
					max-height: 14px;
				}
			}

			.team-search-field {
				width: 100%;
				.MuiInputBase-root {
					border: none;
					box-shadow: none;
					padding-left: 1em;
					&:before,
					&:after {
						display: none;
					}
					.MuiInputBase-input {
						padding: 0;
						font-weight: $font-weight-regular;
						font-size: $font-size-h5;
						line-height: 2;
						border: none;
						background-color: transparent;
						color: $color-primary2;
						box-shadow: none;
					}
					&.Mui-focused {
						svg {
							path {
								fill: $color-primary1;
								//stroke: $color-primary1;
							}
						}
					}
				}
			}
		}
		.team-list-actions {
			padding: 0.5em 1em;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			.MuiCheckbox-root {
				margin-left: -0.5em;
			}
			.search-btn-dummy {
				padding: 0 0.75em;
				font-size: $font-size-subtitle2;
				line-height: $line-height3;
				font-weight: $font-weight-bold;
				color: $color-white;
				background-color: $color-primary1;
				@include border-radius(6px);
				text-transform: uppercase !important;
			}
			.cancle-btn {
				padding: 0;
				color: $color-primary1;
				font-size: $font-size-subtitle2;
				line-height: $line-height3;
				font-weight: $font-weight-bold;
				text-transform: uppercase !important;
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					text-decoration: underline;
					background: transparent;
				}
			}
		}
		.org-team-lists {
			margin-bottom: 0.5em;
			height: 260px;
			.org-team-lists-inner {
				max-height: 252px;
				overflow-y: auto;
				margin-right: 0.25em;
				@include custom-scrollbar;
				.MuiListItem-root {
					padding: 0.425em 1em;
				}
				.MuiListItemIcon-root {
					margin-left: -0.5em;
				}
				.admin-search-no-record {
					object {
						width: 205px !important;
					}
				}
			}
		}
	}
	.sorting-options {
		padding-top: 0.5em;
		border-bottom: 1.4px solid rgba($color-primary2, 0.12);
		.MuiListItem-root {
			@include flex;
			@include align-items(center);
			cursor: pointer;
			.MuiTypography-body1 {
				font-size: $font-size-h5;
			}
			.MuiListItemIcon-root {
				svg {
					width: 16px;
					height: 16px;
					margin-right: 1em;
				}
			}
		}
	}
}
/**user no record**/
.org-no-record {
	text-align: center;
	padding: 1.875em 0 1.875em;
	.MuiTypography-h4 {
		margin-bottom: 10px;
	}
	object {
		margin-top: 1.875em;
	}
}

/** Disable for status*/

.disableStatus {
	cursor: not-allowed;
}

.org-loading-txt {
	text-align: center;
	margin: 3em 0;
}
