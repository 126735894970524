@import '../../sass-utils/variables';

.org-chart-wrapper {
	padding: 0 20px;
	.org-chart-head {
		padding: 0 10px 3px;
		@include flex;
		@include align-items(flex-start);
		@include justify-content(space-between);
		position: relative;
	}
	&.org-chart-dashboard {
		padding: 0;
		.org-chart-head {
			padding: 0.5em 0 3px;
		}
	}
}
/**org chart card css start here**/
.org-chart-card {
	padding: 10px;
	@include border-radius(6px);
	&.highlight {
		background: $color-okr-highlighter;
	}

	.team-name {
		text-align: center;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		&.team-name-link {
			cursor: pointer;
			&:hover {
				color: $color-primary1;
				text-decoration: underline;
			}
		}
	}
	.team-leader-name {
		font-weight: normal;
		text-align: center;
		margin-top: 5px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		svg {
			width: 14px;
			height: 14px;
			min-width: 14px;
			min-height: 14px;
			margin-right: 10px;
		}
		.MuiTypography-h5 {
			font-weight: normal;
			max-width: calc(100% - 30px);
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.team-info {
		@include flex;
		@include justify-content(space-between);
		margin-top: 6px;
		svg {
			width: 14px;
			height: 14px;
			min-width: 14px;
			min-height: 14px;
			margin-right: 10px;
		}
		.MuiTypography-h5 {
			font-weight: normal;
			@include flex;
			@include align-items(center);
		}
	}
	.no-data {
		color: rgba($color-primary2, 0.5);
		svg {
			path {
				fill: rgba($color-primary2, 0.5);
			}
		}
		h5 {
			color: rgba($color-primary2, 0.5);
		}
	}
}
.org-chart-main {
	.page-container {
		padding-bottom: 0px !important;
		min-height: 0px;
	}
	.wrapper {
		padding-bottom: 0px;
	}
}
.chart-zoom-buttons {
	position: absolute;
	//top: 0px;
	top: 100%;
	left: auto;
	right: 0px;
	margin-top: 1em;
	//right: 60px;
	background: $color-expanded-tiles;
	padding: 5px;
	@include border-radius(100px);
	display: block;
	width: 38px;
	text-align: center;
	button {
		width: 28px !important;
		height: 28px !important;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		@include border-radius(100%);
		background: $color-white;
		border: 0px;
		margin: 0 0 10px 0 !important;
		border: 1px solid $color-border-tile;
		padding: 0px;
		&:last-child {
			margin-bottom: 0px !important;
		}
		svg {
			width: 12px;
			height: 12px;
			path {
				fill: $color-primary2;
			}
		}
		&:hover {
			background: $color-expanded-tiles;
			border-color: $color-primary1;
		}
		&.reset-btn {
			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}
