/**tab css start here**/
.okr-tabs {
	margin-right: 16px;
	min-height: 60px !important;
	position: relative;
	.MuiTab-root {
		min-width: auto;
		min-height: 60px;
		opacity: 1;
		font-size: $font-size-h4;
		font-weight: $font-weight-bold;
		line-height: 27px;
		margin: 0 8px;
		.MuiTab-wrapper {
			@include flex;
			@include align-items(center);
			@include flex-direction(unset);
			svg {
				width: 16px;
				height: 16px;
				margin: 0 10px 0 0;
			}
		}
		&.Mui-selected {
			color: $color-primary1;
			&:not(.not-filled-icon) {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}

			&.my-okr-tab.selected {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
		}

		&.btn-circle-white {
			margin-top: 10px;
			min-width: auto;
			max-width: 40px;
			min-height: auto !important;
			svg {
				margin: 0;
			}
		}
	}
	.MuiTabs-indicator {
		height: 3px;
		background-color: $color-primary1;
	}
	/**drawer sub tabs css start here**/
	&.drawer-tabs {
		padding: 0 48px;
		margin: 0px;
		&::after {
			content: '';
			border-bottom: 1px solid rgba($color-primary2, 0.2);
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
		}
		.MuiTab-root {
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			&.Mui-selected {
				color: $color-primary1;
			}
		}
		.MuiTabs-indicator {
			height: 2px;
		}
		.MuiTabs-fixed {
			margin-left: -7px;
		}
	}

	/**Myokrs tab dashbord & org-tab css start here**/
	.my-okr-tab,
	.arrow-tab {
		.arrowIcon {
			padding: 0 5px;
			display: block;
			svg {
				width: 10px;
				height: 8px;
				margin: 3px 0 0 0px;
				path {
					stroke: $color-primary2;
					stroke-width: 2px;
				}
			}
		}
		&.Mui-selected {
			.arrowIcon {
				svg {
					path {
						stroke: $color-primary1;
						stroke-width: 3px;
					}
				}
			}
		}
	}
	.arrow-tab {
		&.Mui-selected {
			svg {
				circle {
					stroke: $color-primary1;
				}
			}
		}
	}
	.btn-text-wrap {
		position: relative;
	}
}
.okr-oval-tabs {
	padding: 20px 60px;
	margin: 0px;
	.MuiTabs-indicator {
		display: none;
	}
	.MuiTab-root {
		background-color: transparent;
		border: 1px solid rgba($color-input-field, 0.5) !important;
		color: $color-primary2;
		@include border-radius(48px);
		font-weight: $font-weight-regular;
		@include transition-default;
		min-width: 190px;
		min-height: 36px;
		height: 36px;
		line-height: 36px;
		opacity: 1;
		.MuiTab-wrapper {
			@include flex-direction(inherit);
			> *:first-child {
				margin-bottom: 0;
			}
		}
		&.MuiTab-labelIcon {
			padding-top: 6px;
		}
		&.Mui-selected {
			background-color: $color-secondary2;
			border-color: $color-primary1 !important;
		}
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		svg {
			width: 16px;
			height: 16px;
			margin-right: 0.5em;
		}
	}
}

.okr-tab-button {
	margin: 0px;
	min-height: 44px !important;
	.MuiTabs-indicator {
		display: none;
	}
	.MuiTab-root {
		background-color: $color-white;
		border: 1.4px solid rgba($color-input-field, 0.5) !important;
		color: $color-primary2;
		@include border-radius(6px);
		@include transition-default;
		font-weight: $font-weight-regular;
		min-width: 136px;
		min-height: 34px;
		height: 34px;
		line-height: 34px;
		opacity: 1;
		text-align: left;
		margin-right: 10px;
		.MuiTab-wrapper {
			@include flex-direction(inherit);
			@include justify-content(flex-start);
			> *:first-child {
				margin-bottom: 0;
			}
		}
		&.MuiTab-labelIcon {
			padding: 7px 19px;
		}
		&.Mui-selected {
			//background-color: $color-secondary2;
			border-color: $color-primary1 !important;
			@include default-shadow;
			color: $color-primary1;
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
		svg {
			width: 14px;
			height: 14px;
			margin-right: 10px;
		}
	}
	&.okr-tab-white-button {
		.MuiTab-root {
			background: $color-white;
			font-size: $font-size-base;
			line-height: $line-height4;
			padding-left: 20px;
			padding-right: 20px;
			max-width: 100%;
			.user-info {
				.user-img {
					.MuiAvatar-root {
						width: 24px;
						height: 24px;
						font-size: $font-size-subtitle2;
					}
				}
			}
			.MuiTypography-h6 {
				color: $color-primary2;
				font-size: $font-size-base;
				font-weight: normal;
			}
			&.Mui-selected {
				@include box-shadow(2px 0px 10px 0px rgba($color-primary2, 0.12));
			}
		}
	}

	.arrow-tab {
		.arrowIcon {
			padding: 0 5px;
			display: inline-block;
			margin-left: 2px;
			svg {
				width: 12x;
				height: 12px;
				margin: 3px 0 0 0px;
				path {
					stroke: $color-primary2;
				}
			}
		}
		&.Mui-selected {
			.arrowIcon {
				svg {
					path {
						stroke: $color-primary1;
					}
				}
			}
		}
	}
	.arrow-tab {
		&.Mui-selected {
			svg {
				circle {
					stroke: $color-primary1;
				}
			}
		}
	}
}
@media screen and (max-width: 1300px) {
	.page-subheader-home {
		.okr-tabs {
			.MuiTab-root {
				margin-left: 4px;
				margin-right: 4px;
			}
		}
	}
}
