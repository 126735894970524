@import '../../../sass-utils/variables';

.add-assessment-form {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			@include justify-content(flex-start);
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			.back-to-main {
				padding-bottom: 0px;
				a {
					margin-right: 14px;
				}
			}
		}
	}
	.input-label-pad {
		padding: 0 8.4px 0;
	}
}
/**assessment card css start here**/
.assessment-card {
	background: $color-white;
	border: 1.4px solid $color-border-tile;
	@include border-radius(6px);
	padding: 20px;
	margin-bottom: 10px;
	&.active {
		@include box-shadow(0px 10px 15px 0px rgba($color-primary2, 0.3));
	}
	.assessment-form-title {
		width: 40%;
		.MuiTextField-root {
			width: 100%;
			margin-bottom: 5px;
		}
	}
}
.error-top-pos {
	.MuiFormHelperText-root {
		top: 0px;
	}
}
.title-read-mode {
	border: 1.4px solid transparent;
	padding: 4px 7px;
	@include border-radius(6px);
	&:hover {
		border: 1.4px solid rgba($color-input-field, 0.5);
	}
}
.title-edit-mode {
	.MuiInputBase-root {
		padding: 4px 7px;
	}
	.MuiInput-input {
		line-height: $line-height2;
		font-size: $font-size-base;
		font-weight: $font-weight-bold;
	}
	.disable-state {
		.MuiInputBase-root {
			border-color: transparent;
			pointer-events: none;
		}
	}
}

.assessment-section-list-row {
	.assessment-form-collapse {
		padding: 0;
		position: relative;
		> .drag-icon {
			position: absolute;
			left: -21px;
			top: 40px;
			width: 14px;
			height: 14px;
			@include flex;
			svg {
				width: 14px;
				height: 14px;
			}
		}
		&.expand-row {
			@include box-shadow(0px 10px 15px 0px rgba($color-primary2, 0.3));
			.assessment-section-head {
				.rotating-arrow {
					svg {
						@include transform(rotate(90deg));
					}
				}
			}
		}
		&.show-error {
			border-color: $color-text-info;
		}
		.assessment-section-head {
			position: relative;
			padding: 20px 20px 20px;
			cursor: pointer;
			.assessment-section-inner {
				position: relative;
			}
			.rotating-arrow {
				position: absolute;
				left: -11px;
				top: 22px;
				width: 10px;
				height: 10px;
				@include transition(0.5s);
				svg {
					@include transition(0.5s);
					width: 10px;
					height: 10px;
					display: block;
					path {
						fill: $color-primary2;
						stroke: $color-primary2;
					}
				}
			}
			.section-count {
				line-height: 10px;
				color: rgba($color-threshold, 0.7);
				&.disable-state {
					opacity: 0.5;
				}
			}
		}

		/**assessment section title css start here**/
		.assessment-section-title {
			.section-title-wrap {
				@include flex;
				@include justify-content(space-between);
				.title-read-mode {
					width: 48%;
				}
				.MuiButtonBase-root {
					margin-top: 4px;
				}
				.error-top-pos {
					.MuiFormHelperText-root {
						min-width: 195px;
					}
				}
				.input-label-pad {
					margin-top: 5px;
				}
			}
			.title-edit-mode {
				width: 48%;
				.MuiTextField-root {
					width: 100%;
				}
				.MuiInput-input {
					font-weight: normal;
				}
			}
		}

		/**assessment section des css start here**/
		.assessment-section-des {
			width: 100%;
			margin-top: 5px;
			.MuiTypography-h5 {
				font-weight: normal;
				line-height: 22px;
			}
			.title-edit-mode {
				.MuiTextField-root {
					width: 100%;
				}
				.MuiInput-input {
					font-size: $font-size-h5;
					font-weight: normal;
				}
			}
			.input-label-pad {
				margin-top: 5px;
			}
		}
		.assessment-section-content {
			.assessment-section-content-inner {
				padding: 0px 20px 20px;
				&.disable-state {
					.assessment-question-row {
						opacity: 0.5;
						mix-blend-mode: luminosity;
						pointer-events: none;
						.question-info {
							.editIcon {
								svg {
									path {
										fill: rgba($color-primary2, 0.7);
									}
								}
							}
						}
					}
				}
			}
		}
		.section-actions {
			margin-top: 20px;
			.btn-text {
				margin-left: 14px;
				color: $color-primary1;
				font-size: $font-size-base;
			}
			&.disable-state {
				opacity: 0.5;
				mix-blend-mode: luminosity;
				.btn-add-key {
					color: rgba($color-primary2, 0.8);
					pointer-events: none;
					.MuiButton-label {
						span {
							background: rgba($color-primary2, 0.8);
						}
					}
				}
				.btn-text {
					pointer-events: none;
					color: rgba($color-primary2, 0.8);
				}
			}
		}

		/**question row css start here**/
		.assessment-question-row {
			background: $color-bg-form;
			padding: 20px;
			margin-bottom: 10px;
			position: relative;
			> .drag-icon {
				position: absolute;
				left: -17px;
				top: 55px;
				width: 14px;
				height: 14px;
				@include flex;
				svg {
					width: 14px;
					height: 14px;
				}
			}
			&:last-child {
				margin-bottom: 0px;
			}
			@include border-radius(6px);
			.okr-team-dropdown {
				width: 100%;
				min-width: 100%;
				max-width: 100%;
				background: $color-white;
				.dropdown-button {
					padding: 4px 10px;
					width: 100%;
					min-width: 100%;
					max-width: 100%;
					span {
						&.MuiButton-label {
							min-height: 22px;
						}
					}
				}
				.team-dropdown {
					padding-top: 30px;
					.MuiList-root {
						.MuiListItem-root {
							padding: 10px 7px;
						}
					}
				}
			}
			.MuiFormLabel-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
					vertical-align: middle;
				}
			}
			.question-info {
				@include flex;
				@include justify-content(space-between);

				.col-1 {
					width: 66.66%;
				}
				.col-2 {
					width: 33.33%;
					padding-left: 20px;
					@include flex;
					@include align-items(center);
					.helperText-bottom {
						width: calc(100% - 40px);
					}
					.editIcon {
						margin-left: 20px !important;
						margin-top: 28px;
					}
				}
				.MuiFormControl-root {
					width: 100%;
				}
				.MuiInputBase-root {
					padding: 4px 7px;
					width: 100%;
					background: $color-white;
					.MuiInput-input {
						line-height: 22px;
					}
				}
				.error-text-field {
					.MuiFormLabel-root {
						color: $color-text-info;
						svg {
							path {
								fill: $color-text-info;
							}
						}
					}
					.okr-team-dropdown {
						.dropdown-button {
							border-color: $color-text-info;
						}
					}
				}
			}
			.scale-info {
				@include flex;
				@include align-items(center);
				margin-top: 15px;
				.helperText-bottom {
					width: 25%;
					margin-right: 30px;
				}
				.btn-add-type1 {
					margin-top: 28px;
				}
			}
			.rating-type-listing {
				width: 60%;
				padding-top: 10px;
				.rating-row {
					margin-bottom: 5px;
					&:last-child {
						margin-bottom: 0px;
					}
				}
				&.short-answer-type {
					width: 100%;
					.rating-row {
						width: calc(100% - 44px);
						.MuiFormControl-root,
						.MuiInputBase-root {
							width: 100%;
						}
					}
				}
			}
			.question-action {
				@include flex;
				.col {
					margin-right: 30px;
					.MuiGrid-container {
						@include align-items(center);
						.MuiGrid-item {
							font-size: $font-size-h5;
							line-height: $line-height4;
							&:last-child {
								padding-left: 5px;
							}
						}
					}
					.MuiSwitch-root {
						.MuiSwitch-switchBase {
							padding: 3px;
							.MuiSwitch-thumb {
								width: 8px;
								height: 8px;
							}
							&.Mui-checked + .MuiSwitch-track {
								background: $color-completed;
							}
						}
						.MuiSwitch-track {
							background: rgba($color-primary2, 0.5);
						}
					}
					&.disable-radio {
						.MuiGrid-item {
							opacity: 0.5;
						}
						.MuiSwitch-root {
							.MuiSwitch-track {
								background: $color-primary2;
							}
						}
					}
				}
			}
		}
	}
}
.add-assessment-action {
	margin-top: 20px;
	@include flex;
	@include justify-content(flex-end);
	@include align-items(center);
	.dot-wrap {
		position: relative;
		padding-right: 17px;
		margin-right: 17px;
		@include flex;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			width: 3px;
			height: 3px;
			border-radius: 3px;
			background-color: $color-bg-dot;
			opacity: 0.5;
			top: 11px;
		}
		.btn-link {
			margin-right: 0px !important;
		}
	}
	.btn-link {
		height: auto !important;
		line-height: 20px !important;
	}
}
