@import '../../sass-utils/variables';

.performance-team-goals {
	.team-goals-card-area {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(calc(33% - 15px), 1fr));
		grid-gap: 15px;
		.team-goals-card {
			cursor: pointer;
			@include border-radius(6px);
			border: 1.4px solid $color-border-tile;
			@include transition(0.5s!important);
			background: $color-white;
			&:hover {
				border: 1.4px solid $color-primary1;
				@include box-shadow(2px 0px 10px 0px rgba($color-primary2, 0.12));
			}
			&.cursor-default {
				cursor: default;
			}
		}
	}
}
.team-goals-card {
	padding: 20px;
	min-height: 128px;
	.team-goals-card-top {
		@include flex;
		@include justify-content(space-between);
		.team-goals-status {
			@include flex;
			@include align-items(center);
			svg {
				margin-right: 5px;
				width: 14px;
				height: 14px;
			}
		}
	}
	.team-goals-card-mid {
		padding: 20px 0 0;
		@include flex;
		@include align-items(center);
		svg {
			width: 16px;
			height: 16px;
		}
		.category-duration {
			padding-right: 15px;
		}
		.category-count {
			padding: 0 15px;
			border-left: 1.4px solid $color-hover;
			border-right: 1.4px solid $color-hover;
		}
		.goals-count {
			padding-left: 15px;
		}
		&.team-goal-card-mid-eql {
			> .MuiBox-root {
				flex: 1;
			}
		}
	}
	.team-goals-card-bottom {
		padding: 20px 0 0;
		border-top: 1.4px solid $color-hover;
		@include flex;
		.user-team-chip {
			margin-right: 5px;
			position: relative;
			padding: 0 8px 0 27px;
			@include border-radius(12px);
			font-size: $font-size-subtitle2;
			min-height: 16px;
			.MuiAvatar-root {
				position: absolute;
				left: 0;
				top: -1.4px;
				margin-top: 0;
				margin-right: 7px;
				width: 20px;
				height: 18.8px;
				margin-left: 0;
				@include border-radius(12px);
				border: 1.4px solid $color-white;
				font-size: $font-size-subtitle2;
				img {
					@include border-radius(12px);
				}
			}
			.MuiChip-label {
				font-size: $font-size-subtitle2;
				line-height: $line-height5;
			}
		}
		.other-user-team-chip {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			min-width: 16px;
			height: 16px;
			background-color: $color-primary1;
			border: none;
			padding: 0 3px;
			@include border-radius(20px);
			.MuiTypography-root {
				color: $color-white;
				font-size: $font-size-subtitle2;
				line-height: $line-height5;
				font-weight: $font-weight-bold;
			}
		}
	}
	&.team-goals-card-skeleton {
		cursor: default;
		border: 1.4px solid rgba($color-border-tile, 0.3);
		background: rgba($color-white, 0.3);
		.MuiSkeleton-root {
			background: $color-secondary2;
			&.MuiSkeleton-text {
				transform: scale(1);
				@include border-radius(10px);
			}
			&.MuiAvatarGroup-avatar {
				border-color: $color-secondary2;
			}
		}
		.pip-card-content {
			border-color: rgba($color-border-tile, 0.5);
		}
		&:hover {
			border: 1.4px solid rgba($color-border-tile, 0.3);
			@include box-shadow(none);
		}
	}
}
@media screen and (min-width: $desktop-breakpoint3) {
	.performance-team-goals {
		.team-goals-card-area {
			grid-template-columns: repeat(auto-fill, minmax(calc(25% - 15px), 1fr));
		}
	}
}
