@import '../../sass-utils/variables';
.assign-contributor-panel-wrap {
	padding: 0px !important;
}
.assign-contributor-panel {
	//padding: 15px 0;
	.drawer-search-field {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		padding: 2.5em 3.75em 0;
		background: $color-white;
		z-index: 1;
	}
	.teams-nested-list {
		padding: 0 3.75em 0;
		.assigned-team-list {
			padding: 1.875em 0;
			.assigned-team {
				padding: 0.625em 0;
			}
			.MuiListItemIcon-root {
				min-width: 26px;
			}

			.assigned-team-members {
				.MuiListItem-root {
					padding: 0.75em 2em;
				}
				.already-aligned-child {
					.already-aligned-tag {
						margin-left: 0.5em;
						padding: 0 0.5em;
						font-size: $font-size-subtitle2;
						font-weight: $font-weight-bold;
						background: $color-hover;
						@include border-radius(3px);
					}
					.MuiCheckbox-root.Mui-disabled {
						svg {
							path {
								//fill: rgba($color-primary2, 0.2) !important;
							}
						}
					}
				}
			}
			.MuiListItem-button {
				cursor: default;
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
	}
}
.search-user-close-icon {
	cursor: pointer;
}
