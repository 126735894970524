@import '../../sass-utils/variables';
.assign-target-panel {
	.kr-contributor-listing {
		.kr-contributor-list-head {
			.MuiList-root {
				.MuiListItem-gutters {
					padding: 0 1em;
				}
				.user-col1 {
					width: 33%;
					padding: 0 0 0 1em;
				}
				.user-col2 {
					width: 40%;
				}
				.user-col3 {
					width: 27%;
				}
			}
		}
		.kr-contributor-list-content {
			.kr-contributor-list-row {
				.MuiList-root {
					padding: 1em 0;
					.MuiListItem-gutters {
						padding: 0 1em;
					}
					.user-col1 {
						width: 33%;
						padding: 0 0 0 1em;
						.user-info {
							.user-info-details {
								h4 {
									@include flex;
									@include align-items(center);
								}
							}
						}
						.MuiBadge-root.okr-badge-icon {
							right: auto;
							left: 33px;
							top: -5px;
						}
					}
					.user-col2 {
						width: 40%;
						.MuiTypography-root {
							.currency-span {
								@include flex;
								@include align-items(circle);
							}
						}
						.edit-btn {
							opacity: 0;
							padding: 3px 8px;
							@include transition-default;
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: none;
							}
						}
						.target-btn {
							padding: 0;
							color: $color-primary1;
							.icon {
								margin-right: 0.5em;
							}
							.MuiTouchRipple-root {
								display: none;
							}
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
						}
					}
					.user-col3 {
						width: 27%;
						@include justify-content(space-between);
						.set-timeline-area {
							margin-left: 0;
							max-width: 124px;
							.timeline-field {
								.calendar-btn {
									button {
										.MuiButton-label {
											min-width: 115px;
										}
									}
								}
							}
						}
						.MuiInputLabel-root {
							display: none;
						}
						.MuiInputBase-input {
							padding: 0;
							width: 3.2em;
						}
						button {
							padding: 0;
						}
						.assign-target-delete {
							@include border-radius(100%);
							padding: 0px;
							opacity: 0;
							//@include transition;
							svg {
								width: 16px;
								height: 16px;
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: none;
							}
						}
					}
				}

				&:hover {
					.MuiList-root {
						.user-col2 {
							.edit-btn {
								opacity: 1;
							}
						}
					}
					.MuiList-root {
						.user-col3 {
							.assign-target-delete {
								opacity: 1;
							}
						}
					}
				}
				.calender-date {
					&.helperText-bottom {
						.MuiInputBase-root {
							border: 0px !important;
						}
						.MuiFormHelperText-root {
							width: 150px;
							bottom: auto;
							left: -8px;
						}
					}
				}
				&.disabled {
					.user-info,
					.date {
						.MuiTypography-h4,
						.MuiTypography-subtitle2,
						p,
						h6,
						.MuiTypography-h6,
						.MuiTypography-body1 {
							color: rgba($color-primary2, 0.6);
						}
						.teams-chips {
							opacity: 0.6;
						}
					}
					.matrics-field-seprator {
						color: rgba($color-primary2, 0.6);
					}
					.MuiAvatar-root {
						background: $color-border-krscore;
						color: rgba($color-primary2, 0.9);
						&::before {
							content: '';
							position: absolute;
							top: 0px;
							left: 0px;
							background: rgba($color-border-krscore, 0.5);
							width: 100%;
							height: 100%;
						}
					}
					.set-timeline-area {
						.timeline-field {
							.calendar-btn {
								button {
									svg {
										path {
											fill: rgba($color-primary2, 0.6);
										}
									}
									.MuiButton-label {
										color: rgba($color-primary2, 0.6);
									}
								}
							}
						}
					}
				}
			}
		}

		.assign-target-metric-field {
			.kr-metrics-fileds {
				.MuiInputBase-root {
					padding-left: 0.5em !important;
					padding-right: 0.5em !important;
				}
				.matrics-field-seprator {
					margin: 0 0.5em;
				}
				.kr-metrics-fileds-inner {
					.MuiFormHelperText-root {
						&.Mui-error {
							padding: 0.417em 0.5em !important;
							min-width: 220px;
							top: 100%;
							bottom: auto;
							margin-top: -10px;
							left: -55px;
							line-height: 14px;
							z-index: 9;
							svg {
								path {
									fill: $color-text-info !important;
									stroke: none !important;
								}
							}
						}
					}
				}
			}
		}
		&.group-contributor-listing {
			.kr-contributor-list-head,
			.kr-contributor-list-content {
				.MuiList-root {
					.user-col1 {
						width: 73%;
					}
					.user-col3 {
						width: 27%;
					}
				}
			}
		}
	}
	.group-target-switch {
		padding: 0px 15px 10px 28px;
	}
}
