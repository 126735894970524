@import '../../sass-utils/variables';
.assessment-popup {
	z-index: 9999 !important;
	.close-assessment-popup {
		position: absolute;
		right: 20px;
		top: 13px;
		padding: 0px;
		width: 30px;
		height: 30px;
		@include border-radius(100%);
		z-index: 9;
		svg {
			width: 12px;
			height: 12px;
			path {
				fill: $color-primary1;
				stroke: $color-primary1;
			}
		}
	}
	.dialog-title {
		background: $color-okr-highlighter;
		padding: 18px 30px;
		@include flex;
		@include align-items(center);
		.title-left {
			@include flex;
			@include align-items(center);
			width: 100%;
			.MuiList-root {
				padding: 0px;
				@include flex;
				margin-left: 30px;
				.MuiListItem-root {
					width: auto;
					padding: 0 10px;
					.MuiTypography-body1 {
						font-size: $font-size-h4;
					}
					&::after {
						content: '';
						position: absolute;
						right: -1px;
						width: 3px;
						height: 3px;
						border-radius: 3px;
						background-color: $color-bg-dot;
						opacity: 0.5;
						top: 50%;
						margin-top: -1px;
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
	.MuiDialog-container {
		.MuiDialog-paper {
			min-width: 95%;
			overflow: hidden;
			// background-color: $color-white;
			// @include custom-scrollbar;
		}
		.MuiDialogContent-root {
			@include custom-scrollbar;
			padding: 30px;
			border: 0;
			min-height: 472px;
		}
	}
	.user-assessment-section-area {
		.user-assessment-ques-area {
			margin: 0 0 20px 0;
		}
	}
	.preview-overlay {
		position: relative;
		&::before {
			position: absolute;
			top: 0px;
			left: 0px;
			z-index: 9999;
			content: '';
			width: 100%;
			height: 100%;
		}
	}
	.assessment-slider-box.show-slider-label {
		.MuiSlider-root {
			.MuiSlider-markLabel {
				opacity: 1;
			}
		}
	}
}
