.search-action-field {
	.src-field {
		.MuiInputBase-root.MuiInputBase-adornedStart {
			.MuiInputAdornment-positionStart {
				margin-right: 13px;
				margin-left: 5px;
			}
			.delete-icon {
				cursor: pointer;
				&.MuiInputAdornment-positionStart {
					margin-right: 4px;
					margin-left: 5px;
				}
				&:hover {
					svg {
						path {
						}
					}
				}
			}
			&.Mui-focused {
				.src-icon {
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
			}
		}
	}
}
