.calender-control-popover {
	.MuiPopover-paper {
		@include default-shadow;
		@include custom-scrollbar;
	}
	.date-range-header {
		padding: 10px 10px 10px 20px;
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		border-bottom: 1.4px solid $color-hover;
		.MuiTypography-body1 {
			font-size: $font-size-h5;
		}
	}
	.calender-toolbar {
		max-width: 630px;
		button {
			flex: 0 1 auto;
		}
	}
	.calender-control-area {
		&.not-range-cal {
			.DateRangePicker__Date.DateRangePicker__Date--today {
				&.DateRangePicker__Date--today--is-selected {
					.DateRangePicker__FullDateStates {
						border: 0px solid $color-primary1;
					}
				}
				.DateRangePicker__FullDateStates {
					border: 1.4px solid $color-primary1;
				}
			}
		}
	}
	.DateRangePicker {
		* {
			font-family: $font-family-lato;
		}
		.DateRangePicker__Month {
			width: 245px;
			margin: 0 18px;
		}
		.DateRangePicker__MonthHeader {
			text-transform: uppercase;
			font-size: $font-size-subtitle2;
			font-weight: $font-weight-bold;
			color: $color-primary2;
		}
		.DateRangePicker__MonthDates {
			.DateRangePicker__WeekdayHeading {
				abbr[title] {
					font-size: $font-size-h6;
					font-weight: $font-weight-regular;
					color: $color-secondary1;
				}
			}
			.DateRangePicker__Date {
				font-size: $font-size-h5;
				padding: 0.75em 0;
				border: 0 solid $color-white;
				border-right-width: 0;
				&.DateRangePicker__Date--is-selected {
					color: $color-primary2;
					.DateRangePicker__CalendarSelection {
						left: 0;
						right: 0;
						bottom: 0;
						top: 0;
						background-color: rgba($color-text-selection, 0.3);
						border: none;
						&.DateRangePicker__CalendarSelection--start {
							background-color: $color-text-selection;
							border-radius: 100%;
							&:after {
								content: '';
								position: absolute;
								top: 0;
								left: 50%;
								width: 50%;
								height: 100%;
								background-color: rgba($color-text-selection, 0.3);
							}
						}
						&.DateRangePicker__CalendarSelection--end {
							background-color: $color-text-selection;
							border-radius: 100%;
							&::before {
								content: '';
								position: absolute;
								top: 0;
								right: 50%;
								width: 50%;
								height: 100%;
								background-color: rgba($color-text-selection, 0.3);
							}
						}
						&.DateRangePicker__CalendarSelection--single {
							background-color: $color-text-selection;
						}
					}
					.DateRangePicker__CalendarSelection--single {
						@include border-radius(100%);
					}
				}
				&.DateRangePicker__Date--weekend {
					background-color: transparent;
				}
				&.DateRangePicker__Date--is-highlighted {
					.DateRangePicker__CalendarHighlight {
						left: 0;
						right: 0;
						bottom: 0;
						top: 0;
						background-color: $color-text-selection;
						border: none;
						border-radius: 100%;
					}
				}
				&.DateRangePicker__Date--today {
					&.DateRangePicker__Date--today--is-selected {
						.DateRangePicker__CalendarSelection {
							border: 1.4px solid $color-primary1;
						}
					}
					.DateRangePicker__FullDateStates {
						@include border-radius(35px);
					}
				}
			}
		}
		.DateRangePicker__MonthHeaderLabel {
			background: url(../../images/arrow-2-blue.svg) no-repeat right center;
			background-size: 9px;
			padding-right: 12px;
			margin: 0 4px;
			font-size: $font-size-subtitle2;
			font-family: $font-family-lato !important;
			font-weight: $font-weight-bold;
			color: $color-primary1;
		}
		.DateRangePicker__MonthHeaderSelect {
			-webkit-appearance: none; /*Removes default chrome and safari style*/
			-moz-appearance: none; /* Removes Default Firefox style*/
			appearance: none;
			background: $color-white;
			font-size: $font-size-h6;
			line-height: 25px;
			border: 0 !important;
			text-indent: 0.01px; /* Removes default arrow from firefox*/
			text-overflow: ''; /*Removes default arrow from firefox*/ /*My custom style for fonts*/
			padding: 0px 3px;
			outline: none;
			min-width: 50px;
			left: -5px;
		}
	}
	.calender-footer-bar {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		padding: 0.75em 1.25em;
		.MuiTypography-body1 {
			font-size: $font-size-h5;
			&.selection-mssg {
				color: $color-text-info;
			}
		}
	}
	&.calender-global-popover {
		// .MuiPopover-paper {
		// 	margin-top: 20px;
		// 	position: relative;
		// 	overflow: initial;
		// 	max-width: 281px;
		// 	&::after,
		// 	&:before {
		// 		content: '';
		// 		position: absolute;
		// 		width: 0;
		// 		height: 0;
		// 		margin-left: 0;
		// 		bottom: 0;
		// 		top: 0px;
		// 		left: 50px;
		// 		box-sizing: border-box;
		// 		transform-origin: 0 0;
		// 		@include transform(rotate(133deg));
		// 	}
		// 	&::after {
		// 		z-index: -1;
		// 		border: 8px solid $color-white;
		// 		border-color: transparent transparent $color-white $color-white;
		// 		@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		// 	}
		// 	&:before {
		// 		z-index: 10;
		// 		border: 8px solid rgba($color-primary2, 0.12);
		// 		border-color: transparent transparent $color-white $color-white;
		// 	}
		// 	.calender-control-inner {
		// 		max-height: calc(100% - 32px);
		// 		min-height: 16px;
		// 		overflow-x: hidden;
		// 		overflow-y: auto;
		// 		@include custom-scrollbar;
		// 	}
		// }
	}
	&.calender-checkins-popover {
		.DateRangePicker {
			.DateRangePicker__MonthDates {
				.DateRangePicker__Date.DateRangePicker__Date--today {
					.DateRangePicker__FullDateStates {
						@include border-radius(0px);
						border: 0px;
					}
				}
			}
		}
		.date-range-header {
			padding: 5px;
			border-bottom: none;
			.MuiTypography-body1 {
				display: none;
			}
		}
	}

	.DateRangePicker__HalfDateStates {
		transform: none;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
	}
	.DateRangePicker__CalendarDatePeriod--am {
		left: 0;
		right: 0;
		border-radius: 0px 50% 50% 0px;
	}
	.DateRangePicker__CalendarDatePeriod--pm {
		left: 0;
		right: 0;
		border-radius: 50% 0px 0px 50%;
	}
}
.set-timeline-area {
	max-width: 120px;
	@include animation(fade-in 1.1s);
	margin-left: 0.75em;
	.timeline-field {
		.calendar-btn {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			> span {
				@include flex;
			}
			button {
				padding: 0;
				.MuiButton-label {
					min-width: 95px;
					@include flex;
					@include justify-content(space-between);
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					color: $color-primary1;
					svg {
						width: 14px;
						height: 14px;
						margin-right: 5px;
						path {
							fill: $color-primary1;
						}
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
				&:hover {
					background: transparent;
				}
				&.Mui-disabled {
					.MuiButton-label {
						color: $color-secondary1;
					}
					.icon {
						svg {
							path {
								fill: $color-secondary1;
							}
						}
					}
				}
			}
			&.wt-calendar-btn {
				@include flex;
			}
		}
	}
	.MuiInputBase-root {
		padding: 0;
		border: 0;
		margin: 0;
	}
}
.wt-timeline-field {
	.calendar-btn {
		@include flex;
		@include align-items(center);
		.wt-date-btn {
			margin: 0 5px;
			padding: 1px 6px;
			font-size: $font-size-subtitle2;
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			line-height: 1.5;
			text-transform: uppercase !important;
			border: 1.4px solid $color-secondary1;
			@include border-radius(20px);
		}
	}
	svg {
		width: 10px;
		height: 8px;
		path {
			stroke: $color-secondary1;
		}
	}
}
