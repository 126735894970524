@import '../../../styles/sass-utils/variables';

.task-list-area {
	.task-list-head {
		margin-top: 25px;
		@include flex;
		@include justify-content(space-between);
		@include align-items(flex-start);
		.task-list-head-left {
			@include flex;
			@include align-items(center);

			.is-complted-switch {
				margin-left: 25px;
				.MuiTypography-body1 {
					font-size: $font-size-h5;
				}
			}
		}
		.group-by-dropdown,
		.sorting-dropdown {
			@include flex;
			@include align-items(center);
			min-width: auto;
			max-width: none;
			.MuiTypography-h5 {
				color: rgba($color-primary2, 0.7);
			}
			.dropdown-button {
				padding: 0 0 0 5px;
				width: auto;
				border: none;
				.icon {
					margin-top: 5px;
					margin-left: 5px;
				}
			}
			.group-by-dropdown-list,
			.task-sorting-list {
				min-width: 248px;
				max-width: 248px;
				position: absolute;
				top: 35px;
				left: 0px;
				z-index: 9;
				@include border-radius(6px);
				background: $color-white;
				@include box-shadow(2px 0 10px rgba($color-primary2, 0.12));
				border: 0px;
				&::after,
				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					margin-left: 0;
					bottom: 0;
					top: 0px;
					left: 90px;
					box-sizing: border-box;
					transform-origin: 0 0;
					@include transform(rotate(133deg));
				}
				&::after {
					z-index: -1;
					border: 6px solid $color-white;
					border-color: transparent transparent $color-white $color-white;
				}
				&:before {
					z-index: 10;
					border: 6px solid rgba($color-primary2, 0.12);
					border-color: transparent transparent $color-white $color-white;
				}
				.group-by-dropdown-list-inner {
					background: $color-white;
					@include border-radius(6px);
				}
				.MuiList-root {
					@include border-radius(0 0 6px 6px);
					overflow-y: auto;
					max-height: 242px;
					@include custom-scrollbar;
					.MuiListItem-root {
						border-bottom: 1px solid rgba($color-input-field, 0.2);
						cursor: pointer;
						&:hover {
							background-color: $color-hover;
						}
						&.active {
							background-color: $color-secondary2;
						}
					}
				}
			}
			.task-sorting-list {
				top: 41px;
				&::after,
				&:before {
					left: 70px;
				}
				svg {
					width: 14px;
					height: 14px;
				}
				.sorting-created-on {
					.MuiListItemIcon-root {
						@include transform(rotate(360deg));
					}
				}
			}
		}
		.task-list-head-right {
			@include flex;
			@include align-items(flex-start);
			.range-selector-calender {
				margin: 0 10px;
				position: relative;
				display: flex;
				align-items: center;
				.form-control {
					width: 205px;
					cursor: pointer;
					height: 32px;
					padding: 0 10px 0 28px;
					font-size: $font-size-h5;
					border: 1.4px solid $color-border-tile;
					@include border-radius(30px);
					background-color: rgba($color-white, 0.5);
					background-image: url(../../../images/calendarSmall.svg);
					background-repeat: no-repeat;
					background-position: 10px center;
					background-size: 13px;
				}
			}
			.goal-okr-filter {
				.filter-chip {
					ul {
						li {
							&.clear-all {
								margin-right: 0.35em;
							}
						}
					}
				}
			}
			.list-filter-btn,
			.task-sorting-btn {
				padding: 0.375em 0.5em 0.375em 1em;
				font-size: $font-size-h5;
				background: $color-bg-form;
				border: 1.4px solid $color-border-tile;
				@include border-radius(43px);
				order: 2;
				margin-right: 15px;
				svg {
					width: 12px;
					height: 12px;
					margin-right: 10px;
				}
				&:hover,
				&.active {
					background: $color-white;
					@include default-shadow;
				}
				.filter-txt {
					@include flex;
					@include align-items(center);
					svg {
						margin-left: 10px;
					}
				}
			}

			.user-search {
				.MuiInputBase-root {
					height: 31px;
				}
				.user-search-chip {
					position: static;
				}
			}
		}
	}
	.task-list-panel-area {
		margin-top: 10px;
		padding: 25px 20px;
	}
}

.task-list-panel-head {
	@include flex;
	@include align-items(center);
	padding-left: 10px;
	margin-bottom: 8px;
	.MuiTypography-h4 {
		margin-right: 10px;
	}
	.MuiTypography-h6 {
		text-transform: uppercase;
		font-size: $font-size-h6;
		color: $color-secondary1;
	}
	.user-info {
		margin-right: 10px;
		@include align-items(center);
		.user-img {
			.MuiAvatar-root {
				margin-top: 0;
			}
		}
		.user-info-details {
			.MuiTypography-h4 {
				font-size: $font-size-h6;
				margin-right: 0;
			}
		}
		.owner-chip {
			background: $color-bg-tag;
			@include border-radius(100px);
			color: $color-primary2 !important;
			text-transform: uppercase;
			margin: 0 0 0 5px;
			padding: 0 5px;
		}
	}
	.priority-chip {
		@include flex;
		@include align-items(center);
		.MuiChip-root {
			height: auto;
			@include border-radius(20px);
			padding: 2px 10px;
			font-size: $font-size-subtitle2;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			text-transform: uppercase !important;
			min-height: 22px;
			@include flex;
			@include align-items(center);
			margin-right: 10px;
			color: $color-white;
			svg {
				width: 14px;
				height: 14px;
				margin-right: 5px;
				path {
					fill: $color-white !important;
				}
			}
			&.high-priority {
				background: $color-high-priority;
				border: 1px solid $color-high-priority;
			}
			&.low-priority {
				background: $color-low-priority;
				border: 1px solid $color-low-priority;
			}
			&.medium-priority {
				background: $color-medium-priority;
				border: 1px solid$color-medium-priority;
			}
		}
		.MuiChip-label {
			padding: 0;
		}
	}
}
.task-list-panel {
	position: relative;
	padding: 11px 0 20px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	@include border-radius(6px);
	margin-left: -10px;
	.task-list-panel-left {
		min-width: calc(100% - 190px);
		max-width: calc(100% - 190px);
		.task-name-box {
			padding-left: 36px;
			position: relative;
			.task-checkbox {
				position: absolute;
				left: 10px;
				top: 9px;
				padding: 0;
				&:hover {
					background-color: transparent !important;
				}
				&.Mui-checked {
					svg {
						path {
							fill: $color-white;
						}
					}
				}
			}
			.task-name {
				width: 100%;
				margin-left: -7px;
				.task-after-edit {
					.MuiTypography-body1 {
						border: 1.4px solid transparent;
						padding: 4px 7px;
					}
				}
				&.edit-mode-on {
					.edit-task-field {
						.MuiInputBase-root {
							@include border-radius(6px 6px 0px 6px);
							padding: 4px 7px;
							.MuiInput-input {
								//height: 22px !important;
								line-height: $line-height2;
								font-size: $font-size-h4;
							}
						}
					}
				}
				&.task-hover-active {
					.task-after-edit {
						&:hover {
							.MuiTypography-body1 {
								border: 1.4px solid rgba($color-input-field, 0.5);
								@include border-radius(6px);
							}
						}
					}
				}
			}
			.task-checkbox-animation {
				object {
					position: absolute;
					left: -6px;
					top: -7px;
				}
			}
		}
		.quick-links-btn {
			margin-top: 14px;
			min-height: auto;
		}
		.textfield-action-btn {
			margin-right: 7px;
		}
	}
	.task-list-panel-right {
		min-width: 140px;
		.user-avatar-group {
			.task-aligned-users {
				@include flex;
				@include align-items(center);
				.MuiAvatar-root {
					width: 40px;
					height: 40px;
					margin-left: 5px;
				}
				.task-assigned-by {
					position: relative;
					.delegated-badge-icon {
						position: absolute;
						left: 5px;
						top: 6px;
						padding: 0px;
						.MuiBadge-badge {
							padding: 0;
							width: 16px;
							height: 16px;
							svg {
								width: 14px;
								height: 14px;
							}
						}
					}
					.align-arrow {
						position: absolute;
						right: -7px;
						bottom: 0;
						margin: 0 5px;
						width: 14px;
						height: 14px;
						@include border-radius(100%);
						background: $color-border-tile;
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						@include transform(rotate(180deg));
						svg {
							width: 10px;
							height: 10px;
							path {
								fill: $color-secondary1;
							}
						}
					}
				}
				.task-assigned-to {
					.delegated-badge-icon {
						position: absolute;
						left: 5px;
						top: 6px;
						padding: 0px;
						.MuiBadge-badge {
							padding: 0;
							width: 16px;
							height: 16px;
							svg {
								width: 14px;
								height: 14px;
							}
						}
					}
					.MuiAvatar-root {
						width: 30px;
						height: 30px;
					}
					.blue-btn {
						width: 16px;
						height: 16px;
						margin-left: 5px;
						svg {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
		}
		.selected-item {
			position: relative;
			@include flex;
			@include align-items(center);
			.cancel-btn {
				width: 14px;
				height: 14px;
				border: 1.4px solid $color-bg-form;
				background: $color-primary1;
				@include border-radius(100%);
				position: absolute;
				right: -10px;
				top: -6px;
				padding: 0px;
				opacity: 0;
				svg {
					width: 6px;
					height: 6px;
					path {
						fill: $color-white;
						stroke: $color-white;
						stroke-width: 3;
					}
				}
			}
			&.active-popup {
				.cancel-btn {
					opacity: 1;
				}
			}
			&:hover {
				.cancel-btn {
					opacity: 1;
				}
			}
			&.selected-delegate {
				.cancel-btn {
					right: -10px;
					top: 0px;
				}
				.MuiAvatar-root {
					cursor: pointer;
				}
			}
		}
	}
	.task-list-actions {
		position: absolute;
		right: 10px;
		top: 11px;
		width: 30px;
		height: 30px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		opacity: 0;
		@include transition(0.15s);
		@include border-radius(24px);
		.delete-btn {
			@include border-radius(24px);
			svg {
				path {
					fill: $color-primary1;
				}
			}
			&:hover {
				background-color: transparent;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
	&:hover {
		background-color: $color-bg-teamstag;
		.task-list-actions {
			opacity: 1;
			&:hover {
				background: $color-expanded-tiles;
			}
		}
	}
	&:after {
		position: absolute;
		left: 36px;
		bottom: 0;
		content: '';
		width: calc(100% - 36px);
		height: 1px;
		background: $color-hover;
	}
	&:last-child {
		&:after {
			display: none;
		}
	}
	&.highlight-task {
		background-color: $color-okr-highlighter !important;
	}
	&.task-list-panel-grey-out {
		opacity: 0.5;
		.quick-links-btn {
			.links-col {
				.btn-link-type1,
				.link-icon-btn {
					color: rgba($color-primary2, 0.5) !important;
					svg {
						path {
							fill: rgba($color-primary2, 0.5) !important;
						}
					}
					.alert-due-on {
						svg {
							path {
								fill: $color-white !important;
							}
						}
					}
				}
			}
		}
	}
}
.load-more-area {
	.btn-small-text {
		text-transform: unset !important;
		margin-left: 10px;
	}
}
.tasks-collapsible {
	margin-top: 10px;
	.tasks-collapsible-body {
		.tasks-collapsible-head {
			.tasks-collapsible-head-content {
				position: relative;
				.rotating-arrow {
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					width: 24px;
					height: 24px;
					position: absolute;
					left: -16px;
					top: 50%;
					margin-top: -12px;
					opacity: 0;
					@include transition(0.15s);
					@include border-radius(24px);
					svg {
						width: 10px;
						height: 10px;
						path {
							stroke: $color-primary2;
						}
					}
					&:hover {
						background: $color-expanded-tiles;
					}
				}
				&:hover {
					.rotating-arrow {
						opacity: 1;
					}
				}
				.task-list-panel-head {
					margin-bottom: 0;
				}
			}
		}
		&.expand-row {
			.rotating-arrow {
				svg {
					@include transform(rotate(90deg));
				}
			}
		}
	}
	&.full-rectangular-card {
		padding: 30px 20px;
	}
}
.task-filter-popup {
	margin-top: 0px;
	.MuiPaper-elevation1 {
		margin-top: 10px;
		@include border-radius(6px);
		@include default-shadow;
		position: relative;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 70px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 8px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 9;
			border: 8px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
	}
	.task-filter-top {
		margin: 10px 10px -15px 0;
		@include flex;
		@include justify-content(flex-end);
		.apply-btn {
			padding: 0 0.75em;
			font-size: $font-size-subtitle2;
			line-height: $line-height3;
			font-weight: $font-weight-bold;
			color: $color-white;
			background-color: $color-primary1;
			@include border-radius(6px);
			text-transform: uppercase !important;
		}
	}
	.task-filter-wrap {
		position: relative;
		padding-bottom: 60px;
		.MuiList-root {
			.MuiListItem-root {
				padding: 3px 0;
				.MuiIconButton-root {
					font-size: $font-size-base;
				}
				.MuiTypography-body1 {
					text-transform: capitalize;
					font-size: $font-size-h5;
				}
			}
		}
		.task-filter-wrap-inner {
			max-height: 392px;
			overflow-y: auto;
			@include custom-scrollbar;
		}
		.task-filter-actions {
			padding: 10px 20px;
			position: absolute;
			width: 100%;
			height: 60px;
			@include flex;
			@include justify-content(flex-end);
			background: rgba($color-hover, 0.5);
		}
		.task-priority-filter {
			ul {
				li {
					.MuiFormControlLabel-label {
						span {
							@include flex;
							@include align-items(center);
						}
						svg {
							margin-right: 5px;
							width: 14px;
							height: 14px;
						}
					}
					&.low-priority {
						.MuiFormControlLabel-label {
							color: $color-low-priority;
						}
					}
					&.medium-priority {
						.MuiFormControlLabel-label {
							color: $color-medium-priority;
						}
					}
					&.high-priority {
						.MuiFormControlLabel-label {
							color: $color-high-priority;
						}
					}
				}
			}
		}
	}
	&.task-drawer-filter-popup {
		margin-left: -43px;
		.task-filter-wrap {
			.task-filter-wrap-inner {
				max-height: 200px;
			}
		}
	}
}
.MuiAvatar-root {
	&.disable-user {
		background: $color-avatar-disable;
		color: rgba($color-primary2, 0.5);
		img {
			filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
			opacity: 0.7;
		}
	}
}
/* 1-on-1 CSS */
.one-on-one-task {
	.task-list-area {
		.full-rectangular-card {
			padding-left: 0;
			padding-right: 0;
			background: transparent;
			border: 0px;
		}
		.task-list-panel {
			&:after {
				background-color: $color-border-tile;
			}
		}
		.task-list-panel-head {
			padding-left: 0;
			.task-list-panel-inner {
				margin-left: -10px;
			}
		}
	}
}
