@import '../../../../sass-utils/variables';

.workflow-reversal-listing {
	.okr-list-panel-head,
	.workflow-reversal-list-content {
		ul {
			li {
				&.workflow-reversal-col1 {
					width: 12%;
				}
				&.workflow-reversal-col2 {
					width: 28%;
				}
				&.workflow-reversal-col3 {
					width: 15%;
				}
				&.workflow-reversal-col4 {
					width: 15%;
				}
				&.workflow-reversal-col5 {
					width: 15%;
				}
				&.workflow-reversal-col6 {
					width: 15%;
				}
			}
		}
	}
	.okr-list-panel-head {
		ul {
			li {
				svg {
					width: 10px;
					height: 10px;
				}
			}
		}
	}
	.workflow-reversal-list-content {
		min-height: 65px;
		position: relative;
		padding: 15px 0;
		margin-bottom: -1px;
		transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
		transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
		background-color: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		ul {
			@include flex;
			@include align-items(center);
			padding: 0;
			li {
				padding: 0 12px 0 20px;
				flex: auto;
				&.workflow-reversal-col2 {
					display: block;
					@include flex-direction(column);
				}
				&.workflow-reversal-col4,
				&.workflow-reversal-col5 {
					.workflow-reversal-status {
						margin-left: 0;
					}
				}
				&.workflow-reversal-col5,
				&.workflow-reversal-col6 {
					.MuiTypography-h4 {
						font-weight: $font-weight-regular;
					}
					.MuiTypography-body2 {
						color: $color-secondary1;
						font-weight: $font-weight-regular;
						line-height: $line-height2;
					}
					svg {
						path {
							stroke: $color-primary2;
						}
					}
				}
			}
		}
		&:hover {
			@include default-shadow;
			z-index: 1;
		}
		&.heighlight-row {
			background-color: $color-okr-highlighter !important;
		}
	}
}
.workflow-reversal-status {
	margin-left: 10px;
	.MuiChip-root {
		&.draft-chip {
			color: $color-tags;
			border: 1px solid $color-tags;
			background-color: $color-okr-highlighter;
		}
		&.lagging-chip {
			background: $color-lagging;
			border: 1px solid $color-lagging;
			.MuiChip-label {
				color: $color-white;
			}
		}
		&.on-track-chip {
			background: $color-on-track;
			border: 1px solid $color-on-track;
			.MuiChip-label {
				color: $color-white;
			}
		}
	}
}
