/**team dropdown button css start here**/
.okr-team-dropdown {
	position: relative;
	min-width: 366px;
	max-width: 366px;
	.dropdown-button {
		width: 366px;
		padding: 4px 10px;
		font-size: $font-size-h5;
		line-height: $line-height2;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		@include justify-content(flex-start);
		.icon {
			order: 2;
			@include transition-default;
			svg {
				width: 10px;
				height: 10px;
				path {
					stroke: $color-primary2;
				}
			}
		}
		span.MuiButton-label {
			@include justify-content(space-between);
			@include align-items(center);
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: none;
		}
	}
	.dropdown-head {
		background: $color-bg-form;
		border-top: 1.4px solid $color-hover;
		border-bottom: 1.4px solid $color-hover;
		padding: 5px 1em;
		p {
			color: $color-primary1;
			font-size: $font-size-h5;
			line-height: $line-height3;
		}
	}
	.team-dropdown {
		background: $color-white;
		@include border-radius(6px);
		border: 1.4px solid $color-primary1;
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		z-index: 9;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		padding-top: 38px;
		.MuiList-root {
			@include border-radius(0 0 6px 6px);
			overflow-y: auto;
			max-height: 232px;
			@include custom-scrollbar;
			.MuiListItem-root {
				cursor: pointer;
				&:hover {
					background-color: $color-hover;
				}
				&.active {
					background-color: $color-secondary2;
				}
			}
		}
	}
	&.active {
		.dropdown-button {
			border-color: transparent;
			@include border-radius(4px);
			z-index: 99;
			.icon {
				@include transform(rotate(180deg));
			}
		}
	}
	&.dropdown-list-item,
	&.dropdown-multilist-item {
		min-width: 268px;
		max-width: 268px;
		.dropdown-button {
			width: 100%;
		}
		.team-dropdown {
			.team-dropdown-inner {
				background: $color-white;
				@include border-radius(6px);
			}
			.MuiList-root {
				border-top: 1.4px solid $color-hover;
				@include border-radius(0px);
				max-height: 200px;
				.MuiListItem-root {
					border-bottom: 1px solid rgba($color-input-field, 0.2);
					padding: 0 10px 0 0.9375em;
					min-height: 40px;
					cursor: pointer;
					.MuiTypography-body1 {
						font-size: $font-size-h5;
						line-height: $line-height4;
					}
					.editIcon {
						opacity: 0;
					}
					&:hover {
						.editIcon {
							opacity: 1;
						}
					}
				}
			}
			.dropdown-bottom-link {
				padding: 0;
				min-height: 40px;
				@include flex;
				@include align-items(center);
				@include border-radius(0 0 6px 6px);
				cursor: pointer;
				width: 100%;
				.btn-link-type1 {
					font-size: $font-size-h5;
					line-height: $line-height4;
					font-weight: normal;
					width: 100%;
					padding: 0 0.9375em;
					min-height: 40px;
					@include justify-content(flex-start);
				}
				&:hover {
					background-color: $color-hover;
				}
			}
		}
		.dropdown-multi-list-sub-panel {
			.back-to-main {
				padding: 0.675em 0.9375em;
			}
		}
	}
	&.dropdown-list-item {
		.back-to-main {
			border-top: 1.4px solid $color-hover;
		}
	}
	&.dropdown-multilist-item {
		min-width: auto;
		max-width: none;
		.dropdown-button {
			width: auto;
			border: 0px;
			padding: 4px 10px;
		}
		.team-dropdown {
			padding: 0px;
			top: 37px;
			min-width: 268px;
			max-width: 268px;
			@include box-shadow(2px 0 10px rgba($color-primary2, 0.12));
			border: 0px;
			left: auto;
			right: -24px;
			&::after,
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				margin-left: 0;
				bottom: 0;
				top: 0px;
				right: 12px;
				box-sizing: border-box;
				transform-origin: 0 0;
				@include transform(rotate(133deg));
			}
			&::after {
				z-index: -1;
				border: 8px solid $color-white;
				border-color: transparent transparent $color-white $color-white;
				@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
			}
			&:before {
				z-index: 10;
				border: 8px solid rgba($color-primary2, 0.12);
				border-color: transparent transparent $color-white $color-white;
			}
			.MuiList-root {
				border-top: 0;
			}
		}
	}
	.bottom-links {
		border-top: 0px !important;
		@include border-radius(0px 0 6px 6px!important);
		.MuiListItem-root {
			padding: 0px !important;
		}
	}
}
