body {
	//button default css
	.MuiButton-root {
		letter-spacing: unset;
		font-size: $font-size-h4;
		text-transform: unset;
		min-width: auto;
		line-height: 1.35;
		.MuiButton-label {
			span {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
			}
		}
	}

	//button primary,secondary
	.btn-primary {
		margin-left: 1em;
		min-width: 6.6875em;
		min-height: 40px;
		@include border-radius(100px);
		color: $color-white;
		background: $color-primary1;
		padding: 0.375em 1.09375em;
		@include transition(0.5s);
		@include button-shadow;
		&:hover {
			background: $color-primary1;
			color: $color-white;
			@include button-shadow;
		}
		&.Mui-disabled {
			background-color: $color-hover;
			color: $color-connected-line;
			box-shadow: none !important;
		}
	}
	.btn-secondary {
		margin-left: 1em;
		min-width: 4.6875em;
		min-height: 35px;
		@include border-radius(100px);
		color: $color-white;
		background: $color-primary1;
		padding: 0.275em 1.09375em 0.375em;
		@include transition(0.5s);
		&:hover {
			background: $color-primary1;
			color: $color-white;
			box-shadow: button-shadow;
		}
		&.Mui-disabled {
			background-color: $color-hover;
			color: $color-connected-line;
			box-shadow: none !important;
		}
	}

	.btn-link {
		min-width: auto;
		height: 40px;
		line-height: 40px;
		color: $color-primary1;
		padding: 0;
		margin-right: 1em;
		@include transition(all ease-in-out 0.25s);
		&:hover {
			background: none;
			//text-decoration: underline;
		}
		.MuiTouchRipple-root {
			display: none;
		}
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.btn-link-type1 {
		@extend .btn-link;
		height: auto;
		font-size: $font-size-h6;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		color: $color-primary1;
		text-decoration: none !important;
		margin: 0px;
		&.disabled,
		&.Mui-disabled {
			pointer-events: none;
			opacity: 0.8;
			color: rgba($color-primary2, 0.5);
			svg {
				opacity: 0.6;
				path {
					fill: rgba($color-primary2, 0.7);
				}
			}
		}
	}
	.btn-link-type2 {
		@extend .btn-link-type1;
		color: $color-secondary1;
		svg {
			path {
				fill: $color-secondary1;
			}
		}
	}
	.btn-link-type3 {
		@extend .btn-link-type1;
		font-size: $font-size-h4;
		line-height: $line-height2;
		font-weight: normal;
	}
	.btn-small-text {
		padding: 0;
		@include transition(0.25s);
		height: auto;
		line-height: inherit;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		color: $color-primary1;
		text-transform: uppercase !important;
		&:hover {
			background: none;
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
	.btn-close {
		border: 1px solid $color-primary2;
		width: 5em;
		min-width: 5em;
		height: 2em;
		@include border-radius(50px);
		color: $color-primary2;
		font-size: $font-size-h6;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		text-transform: uppercase !important;
		padding: 0.375em 1.4375em;
		opacity: 0.5;
		@include transition(0.5s);
		&:hover {
			opacity: 1;
		}
	}
	.btn-cross {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		svg {
			width: 12px;
			height: 12px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.btn-text {
		min-width: auto;
		height: 22px;
		line-height: 22px;
		color: rgba($color-primary2, 0.7);
		padding: 0;
		margin-right: 1em;
		@include transition(all ease-in-out 0.25s);
		font-size: 14px;
		&:hover {
			background: none;
			text-decoration: underline;
			color: $color-primary2;
			svg {
				path {
					fill: $color-primary2;
				}
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
		svg {
			width: 14px;
			height: 14px;
			margin-right: 0px;
			path {
				fill: rgba($color-primary2, 0.7);
			}
		}
	}
	.btn-circle-white {
		@include flex;
		@include justify-content(center);
		@include align-items(center);
		padding: 0;
		width: 40px;
		height: 40px;
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(100%);
		svg {
			width: 16px;
			height: 16px;
		}
		&:hover,
		&.Mui-selected {
			background-color: $color-expanded-tiles;
			border-color: $color-primary1;
			svg {
				path {
					fill: $color-primary1;
				}
				circle {
					stroke: $color-primary1;
				}
			}
		}
	}
	.add-btn {
		min-width: auto;
		width: 50px;
		height: 50px;
		background: $color-primary1;
		@include border-radius(100%);
		@include transition(all ease-in-out 0.25s);
		@include button-shadow;
		@include animation(pulse 2s infinite);
		&:hover {
			background: $color-primary1;
		}
		svg {
			@include transition(0.5s all);
			max-width: 14px;
		}
		&.active {
			background: $color-white;
			@include default-shadow;
			animation: none;
			svg {
				@include transform(rotate(45deg));
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.add-btn2 {
		min-width: auto;
		width: 30px;
		height: 30px;
		background: $color-primary1;
		@include border-radius(100%);
		border: 1.6px solid $color-primary1;
		@include transition(all ease-in-out 0.25s);
		@include button-shadow;
		@include animation(pulse 2s infinite);
		&:hover {
			background: $color-primary1;
			@include button-shadow;
		}
		svg {
			width: 14px;
		}
		&.Mui-disabled {
			animation: none;
			opacity: 0.6;
			svg {
				path {
					fill: $color-white;
				}
			}
		}
	}
	///////  from old base.scss
	.drawer-close-btn {
		width: 24px;
		height: 24px;
		position: absolute;
		left: -12px;
		top: 42px;
		background: $color-white;
		z-index: 1;
		@include box-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
		font-size: $font-size-h4;
		padding: 0.5em;
		@include border-radius(50%);
		svg {
			width: 10px;
			min-width: 10px;
			path {
				fill: $color-primary2;
			}
		}
		&:hover {
			background: $color-white;
		}
	}
	.search-btn {
		position: relative;
		background: $color-white;
		@include border-radius(40px);
		.MuiInputBase-root {
			input {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				padding-left: 40px;
			}
			&.Mui-focused {
				input {
					width: 15em;
				}
			}
		}
		.search-icon {
			width: 40px;
			height: 40px;
			position: absolute;
			left: 0;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			cursor: pointer;
			svg {
				width: $font-size-h4;
			}
		}
	}
	.lock-btn {
		min-width: auto;
		width: 50px;
		height: 50px;
		background: $color-white;
		@include border-radius(100%);
		@include box-shadow(6px 10px 30px rgba(98, 98, 98, 0.2));

		&:hover {
			background: $color-white;
		}
	}
	.filter-btn {
		padding: 0.375em;
		min-width: auto;
		width: 32px;
		height: 32px;
		background: $color-white;
		@include border-radius(100%);
		border: 1px solid rgba($color-input-field, 0.4);
		&:hover {
			background: $color-secondary2;
		}
	}

	.btn-blue-chip {
		padding: 0 12px 0 10px;
		color: $color-white;
		background: $color-primary1;
		font-size: $font-size-h6;
		line-height: 20px;
		font-weight: $font-weight-bold;
		box-shadow: none;
		border-radius: 0.1875em;
		@include transition-default;
		&:hover {
			background: $color-primary1;
			@include button-shadow;
		}
		svg {
			@include transition-rotate;
			margin-right: 0.25em;
			width: 10px;
			height: 10px;
			path {
				fill: $color-white;
				stroke: $color-white;
			}
		}
		&.expand {
			svg {
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
	}
	.btn-gray-chip {
		padding: 0 10px 0 10px;
		color: $color-primary2;
		background: rgba($color-primary2, 0.1);
		font-size: $font-size-h6;
		line-height: 20px;
		font-weight: $font-weight-bold;
		box-shadow: none;
		border-radius: 0.1875em;
		@include transition-default;
		&:hover {
			background: rgba($color-primary2, 0.1);
		}
		svg {
			margin-right: 0.375em;
		}
		.teams-chips {
			margin: 0 5px;
		}
	}
	.btn-accept {
		height: auto;
		width: auto;
		line-height: 1.8em;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		padding: 0px 0.833em;
		text-align: center;
		background-color: transparent;
		border: 1px solid $color-completed;
		color: $color-completed;
		border-radius: 1em;
		@include transition-default;
		&.Mui-disabled {
			border: 1px solid $color-hover;
		}
		&:hover {
			@include box-shadow(6px 9px 26px rgba(12, 188, 166, 0.3));
		}
	}
	.btn-decline {
		height: auto;
		width: auto;
		line-height: 1.8em;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		padding: 0px 0.833em;
		text-align: center;
		background-color: transparent;
		border: 1px solid $color-text-info;
		color: $color-text-info;
		border-radius: 1em;
		@include transition-default;
		&.Mui-disabled {
			border: 1px solid $color-hover;
		}
		&:hover {
			@include box-shadow(6px 9px 26px rgba(255, 0, 0, 0.3));
		}
	}
	.button-blue-border {
		@include border-radius(30px);
		color: $color-primary1;
		background: $color-white;
		padding: 0.25em 1.09375em;
		@include transition-default;
		border: 1px solid $color-primary1;
		&:hover {
			background-color: $color-white;
			@include button-shadow;
		}
		&.Mui-disabled {
			background-color: $color-white;
			color: $color-hover;
			border: 1px solid $color-hover;
		}
	}
	.btn-add-key {
		border: 1px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		padding: 9px 18px;
		color: $color-primary1;
		min-width: 145px;
		@include justify-content(flex-start);
		.MuiButton-label {
			span {
				width: 30px;
				height: 30px;
				background: $color-primary1;
				border-radius: 100%;
				@include flex;
				@include justify-content(center);
				@include align-items(center);
				margin-right: 0.9375em;
				svg {
					width: 12px;
					path {
						stroke: $color-white;
					}
				}
			}
		}
	}
	//kebab menu
	.kebab-icon {
		width: 30px;
		height: 30px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		margin-top: -0.25em;
		@include border-radius(100%);
		cursor: pointer;
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
		}
		svg {
			path {
				stroke: none;
			}
		}
	}
	.request-btn {
		padding-left: 2.5em;
		padding-right: 1em;
		margin-right: 2.125em;
		background-color: $color-white;
		border-radius: 2em;
		border: 1px solid rgba($color-input-field, 0.5);
		background-image: url(../../images/request-one-2-one.svg);
		background-position: 1em center;
		background-repeat: no-repeat;
		background-size: 18px;
	}
	.add-btn-white {
		min-width: auto;
		width: 50px;
		height: 50px;
		border: 1.4px solid $color-primary1;
		@include border-radius(100%);
		@include transition(all ease-in-out 0.25s);
		@include button-shadow;
		@include animation(pulse 2s infinite);
		&:hover {
			background: $color-primary1;
			svg {
				path {
					fill: $color-white;
				}
			}
		}
		svg {
			@include transition(0.5s all);
			max-width: 14px;
			path {
				fill: $color-primary1;
			}
		}
		&.active {
			background: $color-white;
			@include default-shadow;
			animation: none;
			svg {
				@include transform(rotate(45deg));
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.cycle-dropdown-btn {
		padding: 0.25em 1.125em;
		font-size: $font-size-h5;
		line-height: $line-height2;
		color: $color-primary1;

		@include border-radius(100px);
		&:hover,
		&.open {
			background: $color-hover;
		}
		.icon {
			order: 2;
			svg {
				width: 14px;
				margin-left: 5px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
		}
		&.not-current-cycle {
			background-color: $color-primary1;
			color: $color-white;
			&:hover {
				background-color: $color-primary1;
			}
			.icon {
				svg {
					path {
						fill: $color-white;
					}
				}
			}
		}
		&.Mui-disabled {
			.icon {
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
					}
				}
			}
		}
	}

	.btn-oval-shape {
		font-size: $font-size-subtitle2;
		line-height: $line-height5;
		font-weight: $font-weight-bold;
		color: $color-primary1;
		border: 1.4px solid $color-primary1;
		@include border-radius(100px);
		min-width: 26px;
		min-height: 20px;
		padding: 0;
		margin: 0;
		text-align: center;
		@include flex;
		@include align-items(center);
		background: none !important;
		.MuiTouchRipple-root {
			display: none;
		}
		.MuiButton-label {
			padding: 0 0.625em;
		}
		&:hover {
			background: none;
			border-color: $color-primary1;
			color: $color-primary1;
		}
		&.Mui-disabled {
			border-color: $color-secondary1;
			color: $color-secondary1 !important;
			//cursor: text;
			.MuiButton-label {
				//cursor: text;
				//pointer-events: auto;
			}
		}
	}

	.btn-count {
		background: $color-primary1;
		color: $color-white;
		width: auto;
		min-width: 18px;
		min-height: 18px;
		height: 18px;
		padding: 0 3px;
		font-size: $font-size-subtitle2;
		font-weight: $font-weight-bold;
		line-height: $line-height5;
		@include border-radius(100px);

		position: relative;
		&:hover {
			background: $color-primary1;
		}
	}
	.btn-circle-blue {
		@include flex;
		@include justify-content(center);
		@include align-items(center);
		padding: 0;
		width: 30px;
		height: 30px;
		background: none;
		border: 0px solid $color-border-tile;
		@include border-radius(100%);
		svg {
			width: 16px;
			height: 16px;
			path {
				fill: $color-primary1;
			}
			circle {
				stroke: $color-primary1;
			}
		}

		&:hover,
		&.Mui-selected {
			background-color: $color-hover;
		}
	}
	.editIcon {
		width: 24px;
		height: 24px;
		margin-left: 5px !important;
		padding: 0px !important;
		@include border-radius(100%);
		svg {
			width: 14px;
			height: 14px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.btn-toggle {
		padding: 0px;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary1;
		.icon {
			svg {
				width: 0.75em;
				height: 0.75em;
				margin-right: 0.325em;
				path {
					fill: $color-primary1;
				}
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: none;
		}
		&.btn-collapsed {
			svg {
				@include transform(rotate(90deg));
			}
		}
	}
	.blue-filled-btn {
		width: 30px;
		height: 30px;
		@include border-radius(100%);
		padding: 0px;
		border: 1.4px solid $color-primary1;
		background: $color-primary1;
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: $color-primary1;
		}
		svg {
			width: 16px;
			height: 16px;
			path {
				fill: $color-white;
			}
		}
	}
	.generate-okr-btn {
		width: 30px;
		height: 30px;
		@include border-radius(100%);
		padding: 0px;
		background: $color-border-tile;
		cursor: pointer;
		border: 1.4px solid $color-white;
		.MuiTouchRipple-root {
			display: none;
		}
		svg {
			width: 20px;
			height: 20px;
		}
		&:hover {
			background: $color-border-tile;
		}
	}
	.blue-btn {
		width: 14px;
		height: 14px;
		//margin-right: 15px !important;
		padding: 0px !important;
		@include border-radius(100%);
		svg {
			width: 14px;
			height: 14px;
			path {
				fill: $color-primary1;
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			background: none;
		}
		&.link-icon-btn {
			svg {
				path {
					//stroke: $color-primary1;
					//stroke-width: 0.3;
				}
			}
		}
	}

	.low-priority,
	.low-priority.Mui-disabled,
	.quick-links-btn .links-col .low-priority.Mui-disabled {
		color: $color-low-priority;
		svg {
			path {
				fill: $color-low-priority !important;
			}
		}
		&.active {
			background: $color-low-priority;
			color: $color-white;
			border-color: $color-low-priority !important;
			svg {
				path {
					fill: $color-white !important;
				}
			}
		}
	}
	.high-priority,
	.high-priority.Mui-disabled,
	.quick-links-btn .links-col .high-priority.Mui-disabled {
		color: $color-high-priority;
		svg {
			path {
				fill: $color-high-priority !important;
			}
		}
		&.active {
			background: $color-high-priority;
			color: $color-white;
			border-color: $color-high-priority !important;
			svg {
				path {
					fill: $color-white !important;
				}
			}
		}
	}
	.medium-priority,
	.medium-priority.Mui-disabled,
	.quick-links-btn .links-col .medium-priority.Mui-disabled {
		color: $color-medium-priority;
		svg {
			path {
				fill: $color-medium-priority !important;
			}
		}
		&.active {
			background: $color-medium-priority;
			border-color: $color-medium-priority !important;
			color: $color-white;
			svg {
				path {
					fill: $color-white !important;
				}
			}
		}
	}
	.btn-add-type1 {
		height: auto;
		font-size: $font-size-h5;
		line-height: $line-height5;
		font-weight: normal;
		color: $color-primary1;
		text-decoration: none !important;
		margin: 0px;
		padding: 0;
		&.disabled,
		&.Mui-disabled {
			pointer-events: none;
			opacity: 0.8;
			color: rgba($color-primary2, 0.5);
			svg {
				opacity: 0.6;
				path {
					fill: rgba($color-primary2, 0.7);
				}
			}
		}
		&:hover {
			background: none;
			text-decoration: underline;
		}
		.MuiTouchRipple-root {
			display: none;
		}
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			path {
				fill: $color-primary1;
				stroke: $color-primary1;
			}
		}
	}
}
