@import '../../../../sass-utils/variables';

.goal-workflow-settings-area {
	.admin-tabpanel-inner {
		.admin-tabpanel-head {
			padding-bottom: 20px;
			padding-top: 10px;
			min-height: auto;
			@include justify-content(flex-start);
			.back-to-main {
				margin-right: 15px;
				padding-bottom: 0;
			}
			.MuiTypography-h2 {
				text-transform: capitalize;
			}
		}
	}
	.goal-workflow-type {
		padding-bottom: 15px;
		.settings-fields {
			.settings-fields-col {
				.dropdown-default-wrapper {
					.dropdown-default__menu {
						width: 100%;
						padding-top: 34px;
					}
				}
				&.select-error {
					.dropdown-default__control {
						border: 1.4px solid $color-text-info;
					}
				}
			}
		}
	}

	.goal-workflow-radio-box {
		padding-bottom: 15px;
		.MuiFormControlLabel-root {
			margin-left: -8px;
		}
		.MuiRadio-root {
			padding: 6px;
			color: $color-new-common4;
			svg {
				width: 16px;
				height: 16px;
				path {
					fill: rgba($color-new-common3, 0.5);
				}
			}
			&:hover {
				background: transparent;
				svg {
					path {
						fill: $color-new-common4;
					}
				}
			}
			&.Mui-checked {
				svg {
					path {
						fill: $color-new-common4;
					}
				}
			}
		}
	}
	.goal-workflow-search {
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
		.search-text-field {
			width: calc(33.333% - 15px);
			.MuiInputAdornment-positionStart {
				margin-right: 4px;
			}
		}
		.select-search-dropdown {
			.react-select__control {
				.react-select__dropdown-indicator {
					display: none;
				}
			}
		}
		&.helperText-bottom {
			.select-search-dropdown {
				.react-select__control {
					border: 1.4px solid $color-text-info;
				}
			}
			&.select-error {
				.select-search-icon + .MuiFormHelperText-root {
					bottom: auto !important;
					top: 100%;
				}
			}
		}
	}
	.settings-fields {
		width: 100%;
		@include flex;
		@include justify-content(space-between);
		margin-bottom: 20px;
		.settings-fields-col {
			width: calc(33.333% - 15px);
			max-width: calc(33.333% - 15px);
			.MuiFormLabel-root {
				svg {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}
				&.disable-label {
					color: rgba($color-primary2, 0.5);
					svg {
						path {
							fill: rgba($color-primary2, 0.5);
						}
					}
				}
			}
			&.current-stage-col {
				@include flex;
				@include align-items(center);
				.MuiFormLabel-root {
					padding-bottom: 0;
				}
			}
			.okr-team-dropdown {
				&.response-type-dropdown {
					min-width: 100%;
					max-width: 100%;
					.dropdown-button {
						width: 100%;
					}
				}
				&.helperText-bottom {
					.dropdown-button {
						border: 1.4px solid $color-text-info;
					}
				}
			}
		}
	}
	.goal-stage-status {
		margin-left: 10px;
		.MuiChip-root {
			&.draft-chip {
				color: $color-tags;
				border: 1px solid $color-tags;
				background-color: $color-okr-highlighter;
			}
			&.lagging-chip {
				background: $color-lagging;
				border: 1px solid $color-lagging;
				.MuiChip-label {
					color: $color-white;
				}
			}
			&.on-track-chip {
				background: $color-on-track;
				border: 1px solid $color-on-track;
				.MuiChip-label {
					color: $color-white;
				}
			}
		}
	}
	.goal-settings-actions {
		@include flex;
		@include justify-content(flex-end);
	}
	.helperText-bottom {
		.MuiFormHelperText-root {
			z-index: 1;
		}
	}
	.response-type-dropdown {
		&.helperText-bottom {
			.MuiFormHelperText-root {
				top: 32px;
			}
		}
	}
}
/* Workflow Reversal Filter popup */
.filter-list-dropdown {
	@include flex;
	@include align-items(center);
	.list-filter-btn {
		padding: 5px 5px 5px 15px;
		font-size: $font-size-h5;
		background: $color-bg-form;
		border: 1.4px solid $color-border-tile;
		@include border-radius(43px);
		order: 2;
		margin-right: 15px;
		svg {
			width: 12px;
			height: 12px;
			margin-right: 10px;
		}
		&:hover,
		&.active {
			background: $color-white;
			@include default-shadow;
		}
		.filter-txt {
			@include flex;
			@include align-items(center);
			svg {
				margin-left: 10px;
			}
		}
	}
	.filter-chip {
		ul {
			@include flex;
			flex-wrap: wrap;
			padding: 0;
			&:before {
				display: none;
			}
			li {
				padding: 0.3em 0.75em 0.3em 0.875em;
				background: $color-white;
				width: auto;
				border-radius: 40px;
				overflow: hidden;
				margin: 0 0 0 0.5em;
				@include flex;
				&:before {
					display: none;
				}
				.MuiAvatar-root {
					margin: 2px 0.5em 2px 2px;
					width: 30px;
					height: 30px;
				}
				button {
					margin-left: 0.25em;
					padding: 0;
					min-width: auto;
					border-radius: 100%;
					&:hover {
						background: none;
					}
					.MuiTouchRipple-root {
						display: none;
					}
				}
				.cancel-btn {
					svg {
						max-width: 0.75em;
					}
				}
				&.clear-all {
					padding: 0 1em;
					a {
						font-size: $font-size-h5;
						color: $color-primary1;
						text-decoration: none;
						font-weight: $font-weight-bold;
					}
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
				}
			}
		}
		&.dashboard-filter-chip {
			width: 200px;
			position: absolute;
			left: 100%;
		}
	}
}
.filter-list-popup {
	width: 208px;
	z-index: 9999;
	.MuiPaper-elevation1 {
		margin-top: 10px;
		@include border-radius(6px);
		@include box-shadow(2px 2px 10px rgba(41, 41, 41, 0.12));
		position: relative;
		&::after,
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			bottom: 0;
			top: 0px;
			left: 60px;
			box-sizing: border-box;
			transform-origin: 0 0;
			@include transform(rotate(133deg));
		}
		&::after {
			z-index: -1;
			border: 6px solid $color-white;
			border-color: transparent transparent $color-white $color-white;
			@include box-shadow(0px 3px 10px 0 rgba($color-primary2, 0.12));
		}
		&:before {
			z-index: 9;
			border: 6px solid rgba($color-primary2, 0.12);
			border-color: transparent transparent $color-white $color-white;
		}
	}
	.task-priority-filter {
		.MuiTypography-body2 {
			padding: 18px 18px 0 18px;
		}
	}
	ul {
		li {
			padding: 5px 10px;
			border-bottom: 1px solid rgba($color-input-field, 0.2);
			cursor: pointer;
			&:hover {
				background: $color-hover;
			}
			// &.active {
			// 	background: $color-secondary2;
			// }
			label {
				width: 100%;
				margin: 0;
				position: relative;
				z-index: 9;
			}
		}
	}
	.filter-list-actions {
		padding: 10px;
		@include flex;
		@include justify-content(flex-end);
	}
	.list-filter-wrap {
		.list-filter-wrap-inner {
			max-height: 230px;
			overflow-y: auto;
			@include custom-scrollbar;
		}
	}
}
