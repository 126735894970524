$color_1: #416390;


.letter-listing-content {
	min-height: 92px;
	position: relative;
	padding: 1em 0;
	margin-bottom: -1px;
	transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	transition: box-shadow 0.4s, -webkit-transform 0.2s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-transform 0.2s, -webkit-filter 0.2s;
	transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
	background-color: $color-white;
	border: 1.4px solid $color-border-tile;
	border-radius: 6px;

	&:hover {
		box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
		z-index: 1;
	}

	ul {
		display: flex;
		padding: 0px;

		li.user-col1 {
			display: flex;
			width: 28%;
			padding-left: 1.5em;
		}
	}


	.MuiChip-sizeSmall {
		background: rgba($color-secondary2, 0.7);
		margin-right: 5px;

		.MuiChip-labelSmall {
			@include border-radius(20px);
			padding: 0px 7px;
			line-height: 16px;
			height: auto;
			font-size: 10px;
			text-transform: uppercase;
			color: $color-secondary1;
			letter-spacing: 0.00714em;
		}
	}

}

.column-listing-content {
	@extend .letter-listing-content;
	min-height: auto;
}

.letter-col1-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.letter-info {
	padding: 0 30px 0 0;
	align-items: center;
	display: flex;

	.user-img {
		padding-right: 10px;
	}
}

.letter-container {
	padding: 25px;

	.reports-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.txt-wrap {
	font-weight: 700;
	color: $color_1;
	font-size: 12px !important;
}

.select-template {
	margin-top: 0px !important;

	.select-template_inner {
		display: flex;
		justify-content: flex-start;
		align-content: center;
		padding-top: 20px;

		.MuiSelect-selectMenu {
			width: 170px;
		}
	}
}

.letter-action-btn {
	.btn-outline {
		font-size: 12px;
		padding: 5px;
		margin: 0 5px;
	}
}

.form-outer {
	form {
		display: flex;
		justify-content: flex-start;
		margin: 20px 0 30px 0;
	}

	.MuiBox-root {
		flex-basis: 35%;
		margin-right: 20px;
	}
}

.MuiBox-root-27.select-template {
	margin-top: 0px;
}

.select_field {
	padding: 0.69em 1em;
	border: 1.4px solid rgba(161, 171, 185, 0.5);
	background: transparent;
	border-radius: 4px;
}

.letter-actions {
	display: flex;
	align-items: center;

	button svg {
		width: 14px;
		height: 24px;
	}
}

.letter-actions {
	opacity: 0;
}

.letter-listing-content:hover .letter-actions {
	opacity: 1;
}

.letter-head-top {
	@include flex;
	justify-content: space-between;

	.letter-head-right {
		@include flex;
		align-items: center;
	}
}

.letter-head-right {
	.user-search {
		margin-left: 15px;

		.user-search .MuiInputBase-root {
			max-width: 255px;
		}

	}

	.btn-circle-white {
		margin-left: 15px;
	}
}

.icon-btn.disabled {
	color: #aaa;
	/* Dimmed text color */
	cursor: not-allowed;
	pointer-events: none;
	/* Prevent clicking */
}

.select-template-content {
	@include flex;
	@include justify-content(space-between);
	@include align-items(flex-end);

	.select-template-field,
	.new-template-field {
		width: 90%
	}

	.add-btn2 {
		margin-bottom: 5px
	}
}

.letter-filter {

	.action-btn {
		@include flex;
		@include justify-content(flex-end);
		padding: 1em;
	}
}


.letter-drawer.main-drawer-panel {
	.drawer-area {
		.drawer-footer-actions {
			min-height: 6.6em;
		}
	}
}


.letter-column-content {
	.drawer-blue-bg-full {
		background: #f4f8ff;

		.letter-column-listing {
			padding: 0 3.75em 1.875em;

			.MuiTableCell-root.MuiTableCell-head {
				background: #f4f8ff;
			}

		}
	}
}

.popper-menu-temp {
	z-index: 9999;

	.popper-menu-temp-inner {
		box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
		border-radius: 6px;
		padding: 0px;

		&::before {
			width: 0.75em;
			height: 0.75em;
			content: '';
			position: absolute;
			right: 0.6em;
			top: 0.5em;
			background: #ffffff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		ul {
			padding: 0px;

			li {
				padding: 0.625em;
				border-bottom: 1.4px solid #efefef;
				cursor: pointer;
			}
		}
	}
}

.select-template-box {
	.MuiFormControl-root {
		width: 50%;
	}
}

.disabled-upload-file {
	opacity: 0.5;
	pointer-events: none;
}


.lg-okr-filter {
	margin-right: 10px;
}

.lg-area-cal {
	// display: none;
	margin-right: 10px;

	.range-selector-calender {
		.form-control {
			width: 207px;
			cursor: pointer;
			height: 32px;
			padding: 0 7px 0 30px;
			font-size: 14px;
			transition: 0.25s;
			-webkit-transition: 0.25s;
			-moz-transition: 0.25s;
			-o-transition: 0.25s;
			border: 1.4px solid #cee3ff;
			border-radius: 30px;
			background-color: #deecfe;
			background-image: url(/static/media/calendar-small-black.5249058a.svg);
			background-repeat: no-repeat;
			background-position: 10px center;
			background-size: 13px;
		}
	}
}

.lg-listing table {
	.cell-wrap {
		.user-info {
			justify-content: space-between;
			width: 100%;

			.user-info-inner {
				display: flex;
				align-items: center;
			}

			.user-info-details {
				.MuiTypography-root {
					max-width: 180px;
				}
			}
		}
	}
}


.team-assessment-listing {
	&.lg-listing {
		table {
			.MuiTableCell-root {
				&:first-child {
					width: 320px;
					min-width: 320px;
				}

				&:last-child {
					border-right: 1.4px solid #cee3ff !important;
				}

				&:nth-child(2) {
					width: 12%;
					min-width: 12%;
				}

				&:nth-child(3) {
					width: 12%;
					min-width: 12%;
				}

				&:nth-child(4) {
					width: 20%;
					min-width: 20%;
				}

				&:nth-child(5) {
					width: 20%;
					min-width: 20%;
				}

				&:nth-child(6) {
					width: 10%;
					min-width: 10%;
				}
			}

			.cell-wrap {
				.letter-tag {
					font-size: 10px !important;
					text-transform: uppercase;
					background: #E5F0FF;
					border-radius: 30px;
					height: 16px;
					line-height: 14px !important;
					padding: 1px 7px;
					font-weight: 700 !important;
					color: #416390;
				}
				.MuiTypography-body1 {
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					display: block;
				}
			}

			.team-assessment-row {
				.MuiTableCell-root {
					border-right: 0 none;
					border-top: 1.4px solid #cee3ff;
				}
			}
		}
	}
}

.team-assessment-listing {
	&.lg-listing {
		table {
			.MuiTableCell-head {
				&:last-child {
					border-right: 0px none !important;
				}

			}
		}
	}
}

.lg-feedback-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	max-width: 800px;
	height: 90%;
	background-color: white;
	border: 1.4px solid #CEE3FF;
	box-shadow: 24px;
	display: flex;
	flex-direction: column;
	border-radius: 6px;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;

		&:hover {
			background: #555;
		}
	}
}

.lg-feedback-heading {
	background: #feede3;
	color: #292929;
	font-size: 20px;
	padding: 15px 20px;
	margin: 0px;
	display: flex;
	height: 70px;
	align-items: center;
	justify-content: space-between;
}

.lg-feedback-inner-wrapper {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.iframe-wrapper {
	flex: 1;

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
}

#custom-iframe {
	border: 0;
}

.lettergen-wrapper {
	.app-header {
		z-index: 9;
	}
}

.close-modal {
	svg {
		cursor: pointer;
	}
}

body {
	.lg-listing {

		.MuiTypography-subtitle2,
		.MuiTypography-root.subtitle3 {
			font-size: 10px;
			line-height: 16px;
			font-weight: 700 !important;
		}

		.MuiTypography-subtitle2.email-text-wrap {
			text-transform: lowercase;
		}
	}
}

.salary-info-detail {
	display: block;
}
.width-detail{
	width: 250px;
}

.letter-cell {
	display: block !important;
}

.letter-action {
	display: none;
}

.letter-gen-row:hover {
	.letter-action {
		display: block;
	}
}


.email-cell {
	text-overflow: ellipsis;
	width: 120px;
	overflow: hidden;
}