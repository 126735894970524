.user-quick-search-popover {
	z-index: 9999;
	.MuiPopover-paper,
	.MuiPaper-root {
		width: 360px;
		min-height: 88px;
		max-height: unset;
		padding: 1.5625em 0 0 0;
		@include default-shadow;
		@include border-radius(6px);
		overflow: hidden;
		position: relative;
		.search-user-inner {
			.MuiButton-root {
				position: absolute;
				left: 0.875em;
				top: 1.875em;
				-webkit-animation: none;
				animation: none;
				width: 30px;
				height: 26px;
				transition: none;
				line-height: 26px;
				svg {
					max-width: 14px;
					max-height: 14px;
					path {
						fill: $color-primary1;
						//stroke: $color-primary1;
					}
				}
			}
		}
	}
	.rbt-input-main {
		font-weight: $font-weight-regular;
		font-size: $font-size-h5;
		line-height: 2.5em;
		padding-left: 3.857em;
		border: 0;
		background-color: initial;
		width: calc(100% - 1em);
		color: $color-primary2;
		font-family: $font-family-lato;
	}
	/**dropdown menu**/
	.rbt-menu.dropdown-menu#async-pagination {
		width: 360px !important;
		max-height: 236px !important;
		background-color: transparent;
		box-shadow: none;
		border-top: 1.4px solid $color-hover;
		margin-top: 1.5em;
		@include custom-scrollbar;
		border-radius: 0px;
		&:before {
			position: absolute;
			top: 0;
			left: 5em;
			width: calc(100% - 5em);
			height: 1.4px;
			content: '';
		}
	}

	&.search-add-contributor-popup {
		.MuiPopover-paper {
			margin-left: -26px;
			margin-top: 10px;
		}
		.rbt-menu {
			&.dropdown-menu {
				&#async-pagination {
					max-height: 192px !important;
				}
			}
		}
	}
	&.popperPosition {
		.MuiPopover-paper {
			margin-left: 12px;
			margin-top: 35px;
		}
		.rbt-menu {
			&.dropdown-menu {
				&#async-pagination {
					max-height: 192px !important;
				}
			}
		}
	}
	&.quick-add-contributor-popup {
		.rbt-menu {
			&.dropdown-menu {
				&#async-pagination {
					max-height: 192px !important;
				}
			}
		}
	}
}
.user-quick-search-type1 {
	position: relative;
	.user-quick-search-popover {
		background: $color-white;
		@include box-shadow(2px 2px 30px rgba($color-primary2, 0.12));
		@include border-radius(6px);
		position: absolute !important;
		width: 360px;
		min-height: 55px;
		padding: 0.9em 0 0 0;
		z-index: 9999;
		margin-top: 0.5em;
		.connnector-icon {
			width: 0.6875em;
			height: 8px;
			background: $color-white;
			position: absolute;
			top: -8px;
			left: 40px;
			@include box-shadow(31px 42px 25px 0px rgba($color-primary2, 0.12));
			&::before,
			&:after {
				width: 1em;
				height: 8px;
				content: '';
				position: absolute;
				right: -1em;
				top: 0px;
				@include border-radius(50%);
				border: 0 solid $color-white;
				border-width: 0 8px 0 0;
				@include transform(rotate(180deg));
			}
			&:after {
				transform: none;
				right: 0;
				left: -1em;
			}
		}

		.search-user-inner {
			.rbt {
				> div {
					&:first-child {
						padding: 0 1.25em;
						z-index: 9;
						background: $color-white;
					}
				}
			}
			.rbt-input-main {
				background: url(../../images/search-icon-blue.svg) no-repeat 0 center;
				width: 100%;
				padding-left: 2em;
				font-size: $font-size-h4;
				line-height: $line-height2;
				margin-bottom: 0.8125em;
			}
			.rbt-menu.dropdown-menu#async-pagination {
				background: $color-white !important;
				@include box-shadow(2px 2px 30px rgba($color-primary2, 0.12));
				margin-top: 0.8125em !important;
				margin-left: -1.25em;
			}
		}
	}
}
.search-menu-active {
	.user-quick-search-type2 {
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		@include border-radius(6px);
		.rbt-input-main {
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.user-quick-search-type2 {
	max-width: 100%;
	min-width: 370px;
	width: 100%;

	&.rbt {
		> div:first-child {
			background: $color-white !important;
			position: relative;
			z-index: 2;
			@include border-radius(6px);
		}
	}
	.rbt-aux {
		display: none;
	}
	.rbt-input-main {
		padding: 0.69em 3em;
		//height: 50px;
		font-weight: $font-weight-regular;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary2;
		max-width: 100%;
		min-width: 350px;
		width: 100%;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		background: url(../../images/search-sprite.svg) no-repeat 0 0;
		background-position: 15px 12px;
		font-family: $font-family-lato;
		background-color: $color-white;
		position: relative;
		z-index: 2;
		//min-height: 52px;

		&:focus {
			background-position: 15px -102px;
			border-color: $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		&[aria-expanded='true'] {
			@include border-radius(6px 6px 0 0);
			border-color: $color-primary1;
			border-bottom: 0px;
			box-shadow: none;
		}
	}
	.rbt-menu#async-pagination {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 370px !important;
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		//margin-top: -3.125em;
		//padding: 3.125em 0 0 0;
		@include custom-scrollbar;
		@include border-radius(0 0 6px 6px);
		z-index: 1;
		max-height: 300px !important;
		border: 1.4px solid $color-primary1;
		border-top-color: rgba($color-input-field, 0.5);
		margin-top: 0px;
		a {
			&.disabled {
				cursor: default;
			}
		}
	}
	.search-no-record {
		object {
			max-width: 245px;
		}
	}
}
.error-search {
	//max-width: 370px;
	.rbt-input-main {
		border-color: $color-text-info;
	}
	.error-field {
		z-index: 9;
	}
	.search-menu-active {
		& + .error-field {
			z-index: initial;
		}
		& + .contributors-chip {
			& + .error-field {
				z-index: initial;
			}
		}
	}
}
